import React, {Fragment, useEffect} from 'react'

const Support = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <h1>Support Japanible</h1>
    <p>You can support Japanible at Kofi.</p>
    <a href="https://ko-fi.com/japanible">Ko-Fi.com</a>

    <h1>What are you using donations for?</h1>
    <p>We are using donations to keep the website on - developing new features, writing content and keeping the website on.</p>

    </>
  )
}

export default Support