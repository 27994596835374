import React, {Fragment} from 'react'
import Helmet from 'react-helmet'

const RequestInterview = () => {
  return (
    <>
        <Helmet>
        <title>Request An Interview | Platform for people who love Japan</title>

        <meta name="description" content="Japanible is a platform for people who love Japan. Sharing Awesomeness from Japan. Writing articles about Japan, japanese culture and language." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="noindex"/>
      </Helmet>
    <h1>Request An Interview</h1>
    <p>Would you like to share something with community in a form a of an article or video?</p>
    <a href="#">Connect with us!</a>
    </>
  )
}

export default RequestInterview