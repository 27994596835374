// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getPerformance } from "firebase/performance";


const firebaseConfig = {
  apiKey: "AIzaSyCg7K_HX1o_WHxkpn1M7yL-E9YJBql4Lcw",
  authDomain: "japaniblemedia.firebaseapp.com",
  projectId: "japaniblemedia",
  storageBucket: "japaniblemedia.appspot.com",
  messagingSenderId: "51257982118",
  appId: "1:51257982118:web:f3970b94137bf73b218867",
  measurementId: "G-2H6JMGKTKM",
  automaticDataCollectionEnabled: false,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const perf = getPerformance(app);

const analytics = getAnalytics(app);

export const db = getFirestore(app)
export const storage = getStorage(app);
export const auth = getAuth(app);
