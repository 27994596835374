import React, { useState, useEffect, Fragment } from 'react'
import { useParams, useHref } from 'react-router-dom'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import { db } from '../Firebase/firebase'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { UserAuth } from '../context/AuthContext';

import { confirmAlert } from "react-confirm-alert"; // Import

import uuid from 'react-uuid';
import TipTapRTE from '../components/TipTapRTE';
import sanitizeHtml from 'sanitize-html-react';
import ReactTooltip from 'react-tooltip';
import { AiFillInfoCircle } from "react-icons/ai";

import ERRORBOUNDARY from '../components/ERRORBOUNDARY'


/* STYLES */
const FormRow = styled.div`
    margin: 20px 0px;
    h2 {margin-bottom: 0px;}`

const FormTextArea = styled.textarea`
    margin-top: 5px;
    width: 100%;
    height: 50px;
    overflow-y: scroll;
    resize: none;
    font-size: 16px;
    padding: 5px 5px;
    `
const FormInput = styled.input`
    margin-top: 5px;
    padding: 5px 5px;
    width: 100%;
    font-size: 16px;`

const CTA = styled.button`
cursor: pointer; 
    background-color: red;
    color: white;
    border-radius: 3px;
    border: transparent 0px solid;
    padding: 9px;
    font-size: 16px;`

const Highlight = styled.div`
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 3px;
    text-align: center;`

const UploadPhotoFormRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      background-color: red;
      color: white;
      border-radius: 3px;
      border: transparent 0px solid;
      font-size: 16px;
      margin-left: 9px;

    }`

const MethodButton = styled.div`
  color: blue;
  font-size: 16px;
  cursor: pointer;
  padding: 6px;
  margin-bottom: 3px;
  text-decoration: underline;
  max-width: 20%;
`

const UploadPhotoFormRowSub = styled.div`
    display: flex;
    flex-direction: column;
    
    margin-top: 12px;

    

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3px;
    }

    div > button {
      background-color: red;
      color: white;
      border-radius: 3px;
      border: transparent 0px solid;
      font-size: 16px;
      cursor: pointer;
      padding: 6px;

      &:disabled {background-color: lightgray;
        pointer-events: none;}
      
    }

        div > label > input[type=file] {
      display:none;    
    }

    div > label {
      background-color: white;
      color: black;
      border-radius: 3px;
      border: red 3px solid;
      font-size: 16px;
      padding: 6px;
      cursor: pointer;
    }

    div > span {
      background-color: white;
      color: red;
      border-radius: 3px;
      font-size: 16px;
      padding: 6px;
      cursor: pointer;
      text-align: center;
      text-decoration: underline;
    }
    `

const HashTagRow = styled.div`
  margin-top: 8px;
  span {border: 1px solid lightgray;
  padding: 3px;
  border-radius: 3px;
  margin-right: 3px;
  cursor: pointer;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;}`

const HashTag = styled.span`
 color: ${props => props.included ? 'white' : 'black'};
 background-color: ${props => props.included ? 'red' : 'white'};

 &::before {content: '#';}`

/* MAIN PART */
const Share = () => {
  const { draftId } = useParams();
  const postUuid = uuid();

  useEffect(() => { window.scrollTo(0, 0) }, [])
  const { user } = UserAuth();

  const storage = getStorage();
  const [retrievedDraft, setRetrievedDraft] = useState([])
  const [post, setPost] = useState([])
  const [dataState, setDataState] = useState('loading')
  const [hashtagsList, setHashtagsList] = useState(['culture', 'travel', 'food', 'craftart', 'language', 'history', 'visa']);

  const [pictureUploadMethod, setPictureUploadMethod] = useState('undefined')

  const getPostId = async () => {
    const q = query(collection(db, "drafts"), where("draftId", "==", draftId))
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        setRetrievedDraft(doc.id)
        console.info(retrievedDraft)
      });

      const getPostValues = async () => {
        // console.log('Fetch start by ' + user.uid)
        const currentPost = await getDocs(query(collection(db, "drafts"), where("draftId", "==", draftId)))
        if (!currentPost.empty) {
          await currentPost.forEach(async (doc) => {
            const DATA = doc.data()
            const load = async () => {
              setPostTitle(DATA.title)
              setPostUrlToImage(DATA.urlToImage)
              setPostOriginalUrl(DATA.postOriginalPostUrl)
              setPostDescription(DATA.description)
              setFileToBeUploaded(DATA.urlToImage)
              setImageUploaded(true)
              setRTEBody(DATA.body)
              setPercent(100)
              setPost(DATA)
            }
            load();
            setDataState('success');
            // console.log(post)
            // console.log(retrievedDraft)
          })
        } else { setDataState('error') }

      }
      getPostValues()
    } else { setDataState('error') }
  };

  // Get content of the post by quering by slug
  useEffect(() => {
    getPostId()
  }, [])


  const sanitizationRules = {
    allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img'],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src']
    },
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {}
  }



  const sharePostAction = async () => {
    // Overview: Simple validation => Post send to Firebase API => Clearing inputs => Redirecting to the new post.

    // Simple validation && post send to Firebase API
    if (slug && postTitle && postUrlToImage && postDescription && RTEBody.length > 100 && checkboxIAmAuthor) {
      await setDoc(doc(collection(db, "posts")), {
        author: user.uid.toString(),
        slug: slug.toString(),
        title: sanitizeHtml(postTitle.toString(), sanitizationRules),
        urlToImage: sanitizeHtml(postUrlToImage.toString(), sanitizationRules),
        body: sanitizeHtml(RTEBody, sanitizationRules),
        description: sanitizeHtml(postDescription, sanitizationRules),
        publishedAt: new Date().toISOString(),
        type: "community",
        originalUrl: sanitizeHtml(postOriginalPostUrl, sanitizationRules) || "",
        likes: [],
        hashtags: hashtags
      })
      
      successfulMessage()

      // Clearing inputs
      setPostTitle('')
      setPostUrlToImage('')
      setPostDescription('')
      setPostFullText('')
      //Redirecting to the new post
      window.location.href = `https://japanible.com/originals/${slug}`
    }
    // Simple validation
    if (postTitle.length < 5) { validationStatePopup("title") }
    if (!postUrlToImage) { validationStatePopup("featured image") }
    if (!postDescription) { validationStatePopup("annotation") }
    if (RTEBody.length < 100) { validationStatePopup("article main text") }
  }

  const saveDraft = async () => {
      // Simple validation => Post send to Firebase API => Clearing inputs => Redirecting to the new post.
    if (retrievedDraft.length > 3) {
      await setDoc(doc(db, "drafts", retrievedDraft), {
        author: user.uid.toString(),
        draftId: draftId,
        title: sanitizeHtml(postTitle.toString(), sanitizationRules),
        urlToImage: sanitizeHtml(postUrlToImage.toString(), sanitizationRules),
        body: sanitizeHtml(RTEBody, sanitizationRules),
        description: sanitizeHtml(postDescription, sanitizationRules),
        publishedAt: new Date().toISOString(),
        type: "community",
        originalUrl: sanitizeHtml(postOriginalPostUrl, sanitizationRules) || ""
      })
      alert('Saved the Draft! All good.')
      window.location.href = `https://japanible.com/user/${user.uid}`
    }
    if (retrievedDraft.length === 0 && postTitle !== "") {
      await setDoc(doc(collection(db, "drafts")), {
        author: user.uid.toString(),
        draftId: draftId,
        title: sanitizeHtml(postTitle.toString(), sanitizationRules) || "",
        urlToImage: sanitizeHtml(postUrlToImage.toString(), sanitizationRules) || "",
        body: sanitizeHtml(RTEBody, sanitizationRules),
        description: sanitizeHtml(postDescription, sanitizationRules) || "",
        publishedAt: new Date().toISOString() || "",
        type: "community",
        originalUrl: sanitizeHtml(postOriginalPostUrl, sanitizationRules) || ""
      })
      alert('Saved the Draft! All good.')
      window.location.href = `https://japanible.com/user/${user.uid}`
    } else { alert("Fill in the title.") }
  }

  // States
  const [postTitle, setPostTitle] = useState('')
  const [slug, setSlug] = useState('')
  const [postUrlToImage, setPostUrlToImage] = useState('')
  const [postOriginalPostUrl, setPostOriginalUrl] = useState('')
  const [postDescription, setPostDescription] = useState('')
  const [postFullText, setPostFullText] = useState('')
  const [checkboxIAmAuthor, setCheckboxIAmAuthor] = useState(false)
  const [checkboxIsOriginal, setCheckboxIsOriginal] = useState(false)
  const [fileToBeUploaded, setFileToBeUploaded] = useState([])

  const [fileToBeUploadedInText, setFileToBeUploadedInText] = useState([])
  const [percent, setPercent] = useState(0);
  const [imageUploaded, setImageUploaded] = useState(false)
  const [RTEBody, setRTEBody] = useState("")

  const [hashtags, setHashtags] = useState([])


  const handleChange = event => { setPostTitle(event.target.value) }


  useEffect(() => {
    // Slug generation
    const sluggedTitle = `${postTitle.trim().replace(/\W+/g, '-').toLowerCase()}-${uuid().split("-")[0]}`;
    // console.log(sluggedTitle)
    setSlug(sluggedTitle)
  },
    [postTitle])

  const handleFileSelection = (e) => {
    setFileToBeUploaded(e.target.files[0]);
    setDisableUrlInput(!disableUrlInput)
  }

  const handleUploadFile = () => {
    if (fileToBeUploaded.length < 1) {
      alert('Please select a file.');
    }

    // const storageRef = ref(storage, `/files/${fileToBeUploaded.name}`)
    const storageRef = ref(storage, `/${user.uid}/upload/${fileToBeUploaded.name}`)

    const uploadTask = uploadBytesResumable(storageRef, fileToBeUploaded);
    uploadTask.on("state_changed", (snapshot) => {
      const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setPercent(percent);
    },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setPostUrlToImage(url);
        });
      }
    );
    setImageUploaded(true)
  }

  const handlerDeleteUploadedImage = () => {
    setPostUrlToImage("")
    setFileToBeUploaded([])
    setImageUploaded(false)
    setDisableUrlInput(false)
    setPictureUploadMethod('undefined')
  }

  const [disableUrlInput, setDisableUrlInput] = useState(false)


  const validationStatePopup = (missingData) => {
    confirmAlert({
      title: 'We need you to fill ' + missingData + '.',
      message: "We need you to fill " + missingData + '.' ,
      buttons: [
        {
          label: "Ok, understood.",
          onClick: () => {
            return null;
          },
        },
        
      ],
    });
  };

  const successfulMessage = () => {
    confirmAlert({
      title: 'Successfully published.',
      message: "You will be redirected to your new post.",
      buttons: [
        {
          label: "I understand.",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  return (
    <div style={{ width: '800px', margin: '0px auto' }}>
        <Helmet>
        
        <meta name="description" content="Japanible is a platform for people who love Japan. Sharing articles about Japan, japanese culture and language." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="noindex"/>

        <meta name="author" content="Japanible"/>

      </Helmet>


    <script> window.prerenderReady=false</script>

      <ERRORBOUNDARY>
        <h1>Share Your Story!</h1>
        <p>As a contributor, you have the opportunity to publish articles on our platform. Please note that all submissions will be subject to review before being published to ensure quality and appropriateness.</p>





        <FormRow>
          <h2 style={{ display: "flex", alignItems: 'flex-start' }}>Featured image <AiFillInfoCircle style={{ color: 'gray', marginLeft: 3, fontSize: 18 }} data-tip="You may add a main image to your post by either pasting a URL or uploading a picture from your device. We recommend choosing an eye-catching and relevant photo. Please ensure that you have the legal right to post the selected image." /></h2>

          <UploadPhotoFormRowSub>
            {/* Select File buttons */}
            {pictureUploadMethod != 'undefined' && <MethodButton href="#" onClick={handlerDeleteUploadedImage}>Change the method</MethodButton>}
            {pictureUploadMethod === 'undefined' && <MethodButton href="#" onClick={() => { setPictureUploadMethod('URL') }}>Paste URL</MethodButton>}
            {pictureUploadMethod === 'undefined' && <MethodButton href="#" onClick={() => { setPictureUploadMethod('UPLOAD') }}>Upload picture</MethodButton>}


            {!imageUploaded ? <>
              {pictureUploadMethod === 'URL' && <span>Type or paste your URL here, then press the preview button.</span>}
              <UploadPhotoFormRow>

                {pictureUploadMethod === 'URL' && <FormInput style={{ maxWidth: '50%' }} type="url" disabled={disableUrlInput} placeholder="https://example.com" pattern="https://.*" onChange={event => { setPostUrlToImage(event.target.value) }} value={postUrlToImage} />}
                {pictureUploadMethod === 'URL' && <button onClick={handleUploadFile} disabled={!postUrlToImage}>Preview the File from the URL</button>}
              </UploadPhotoFormRow>




              {/* Select File buttons */}
              <div>
                {pictureUploadMethod === 'UPLOAD' && <label className="custom-file-upload">
                  <input type="file" name="" id="" onChange={handleFileSelection} />
                  {!fileToBeUploaded.name ? "Select a File from Your Device" : <span>Selected File: <b>{fileToBeUploaded.name.toString()}</b></span>}
                </label>}



                {pictureUploadMethod === 'UPLOAD' && <button onClick={handleUploadFile} disabled={!fileToBeUploaded.name}>Upload and Preview Selected File</button>}

              </div></> :


              <div alt="PERCENTAGE AND PREVIEW" style={{ display: "flex", flexDirection: "column" }}>

                {percent < 100 ? <p>Done {percent}%.</p> :
                  <>
                    <h2>Image Preview</h2>
                    <img src={postUrlToImage} style={{ maxWidth: "100%", objectFit: "cover", borderRadius: "3px" }} alt="Preview of your photo." srcset="" />
                    <br />
                    <span onClick={handlerDeleteUploadedImage}>Remove the Image</span>
                  </>}

              </div>}
          </UploadPhotoFormRowSub>
        </FormRow>


        <FormRow key="wow">
          <h2 style={{ display: "flex", alignItems: 'flex-start' }}>Title <AiFillInfoCircle style={{ color: 'gray', marginLeft: 3, fontSize: 18 }} data-tip="Titles should be a minimum of five characters in length." /></h2>
          <label key="wow2" htmlFor=""></label>


          <ReactTooltip />

          <FormInput onChange={handleChange} value={postTitle} type="text" />
        </FormRow>

        <FormRow>
          <h2>Article's Main Text</h2>
          <TipTapRTE setRTEBody={setRTEBody} />
        </FormRow>

        <FormRow>
          <h2 style={{ display: "flex", alignItems: 'flex-start' }}>Annotation <AiFillInfoCircle style={{ color: 'gray', marginLeft: 3, fontSize: 18 }} data-tip="Please provide a short description of your post, which will be displayed in the list of articles. If left blank, the first paragraph of the article will be used as the description." /></h2>
          <ReactTooltip />
          {/* <label htmlFor=""></label> */}
          <FormTextArea type="text" onChange={event => { setPostDescription(event.target.value) }} value={postDescription} />
        </FormRow>

        <FormRow>
          <h2>Hashtags</h2>
          {/* {hashtags ? (hashtags.map((e)=>(<span>{e} </span>))) : ''} */}
          <HashTagRow>
            {hashtagsList.map((e) => (
              <HashTag
                included={hashtags.indexOf(e) > -1}
                onClick={() => {
                  if (hashtags.indexOf(e) < 0) {
                    setHashtags([...hashtags, e])
                  } else { setHashtags(hashtags.filter((y) => (y != e))) }
                }}>

                {e}

              </HashTag>))
            }
          </HashTagRow>
        </FormRow>

        <Highlight>
          <FormRow>
            <input type="checkbox" name="author" id="author" checked={checkboxIAmAuthor} onChange={e => setCheckboxIAmAuthor(e.target.checked)} />
            <label htmlFor="author">I confirm that I am the author of this text, or have obtained written permission to repost this article.</label>
          </FormRow>
          {checkboxIsOriginal ? <FormRow>
            <label htmlFor="">Link to original article.</label>
            <FormInput type="url" placeholder="https://example.com" pattern="https://.*" onChange={event => { setPostOriginalUrl(event.target.value) }} value={postOriginalPostUrl} />
          </FormRow> : ""}
          <CTA onClick={sharePostAction}>Post</CTA> <a href="#" rel="nofollow" onClick={saveDraft}>Save Draft</a>
        </Highlight>
      </ERRORBOUNDARY>
    </div>
  )
}

export default Share