import React, {Fragment, useEffect} from 'react'
import { Helmet } from 'react-helmet'

const AboutJapanible = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>About Japanible | Platform for people who love Japan</title>

        <meta name="description" content="Learn about Japanible, a community platform for people who love Japan. Discover curated articles about Japanese culture, study methodology for Japanese language learners, job offers and more. Join our community of enthusiasts and share your experiences and knowledge about all things Japan." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="index follow"/>
      </Helmet>
    <h1>About Japanible</h1>
    <p>Japanible is a community platform for people who love Japan.</p>
    <h1>Q&A</h1>
    <h2>Who is Japanible?</h2>
    <p>You guys. Japanible is a community, family, you and us.</p>
    <h2>Founders</h2>
    <p>We are a group of like-minded people, who love Japanese culture and language. Some of us studied Japanese at school, went to working holidays, some of us are native Japanese. We all have in common, that we want connect people.</p>
    <h2>What are you using donations for?</h2>
    <p>We are using donations to keep the website on - developing new features, writing content and keeping the website on.</p>
    </>
    )
}

export default AboutJapanible