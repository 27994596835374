import React from 'react';
import styled, {keyframes} from 'styled-components';

const loading = keyframes`
  0%{
    left: -45%;
  }
  100%{
    left: 100%;
}`

const Item = styled.div`
    display: inline-block;
    position: relative;
    border-radius: 3px;
    width: 100%;
    height: ${props => props.height};
    width: ${props => props.width};
    margin-bottom: ${props => props.bottom};

      background-color: rgb(211,211,211);
      z-index: 44;
      overflow: hidden;

      div {
        position: absolute;
        left: -45%;
        height: 100%;
        width: 45%;
        background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        background-image: -moz-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        background-image: -webkit-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        animation: ${loading} 1s infinite;
        z-index: 45;
      }
    ` 
export const LoadingPlaceholderItemRound = (props) => {

      return (
            <Item height={props.height} width={props.width} bottom={props.bottom} style={{borderRadius: '500px'}}>
              <div/>
            </Item>
      )
    }

const LoadingPlaceholderItem = (props) => {

  return (
        <Item height={props.height} width={props.width} bottom={props.bottom}>
          <div/>
        </Item>
  )
}

export default LoadingPlaceholderItem