import React, { useState, useEffect } from 'react'
import ArticleCard from '../components/ArticleCard'
import ArticleCardPlaceholder from '../components/ArticleCardPlaceholder'
import ArticleCardFiller from '../components/ArticleCardFiller'
import styled from 'styled-components';
import { collection, getDocs, where, query } from "firebase/firestore"; 
import {db} from '../Firebase/firebase'
import {useLocation} from 'react-router-dom'
import Helmet from 'react-helmet'

import dayjs from 'dayjs'
// import articleData from '../data/articleData'
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
const ArticlesWrapper = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
min-width: 100%;
`

const Language = () => {

  const location = useLocation().pathname;
  const [articles, setArticles] = useState([]);
  const [dataLoadingState, setDataLoadingState] = useState('loading')
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // useEffect(() => {
  //   const getData = async () => {
  //       let events = []
  //         const q = query(collection(db, "posts"), where("hashtags", "array-contains", "language"))
  //         const postsSnapshot = await getDocs(q);
  //         await postsSnapshot.forEach((doc) => {
  //           events.push(doc.data());
  //         }
  //         )
  //            setArticles(events.sort((a, b)=>(dayjs(a.publishedAt) > (dayjs(b.publishedAt)) ? -1 : 1)))
  //       setDataLoadingState('loaded')
  //   }
  //   getData()
  // }, [])

  const { isLoading, error, data, isFetching, isFetched } = useQuery({
    queryKey: ["allarticles"],
    cacheTime: 15 * (60 * 1000), // 15 mins 
    queryFn: async () =>{
      let _resultArray = []
      const postsSnapshot = await getDocs(collection(db, "posts"));
      await postsSnapshot.forEach((doc) => {
        _resultArray.push(doc.data());
    })
      return _resultArray.sort((a, b)=>(dayjs(a.publishedAt) > (dayjs(b.publishedAt)) ? -1 : 1))
    }
  });

  if (isLoading) return (<>
  <h1>All Language Articles</h1>
  <ArticlesWrapper>
    <ArticleCardPlaceholder/>
    <ArticleCardPlaceholder/>
    <ArticleCardPlaceholder/>
    </ArticlesWrapper></>)
  if (isFetched) return (
    <>
    <Helmet>
        <title>All Language Articles | Japanible</title>

        <meta name="description" content="Learn the Japanese language with Japanible's language articles. From grammar tips to vocabulary building, our articles provide insights and strategies for learners of all levels. Start your journey with Japanible today." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="index, follow"/>
        <link rel="canonical" href="https://japanible.com/language"/>

        <meta property="og:title" content="Discover all articles on Japanible."/>
        <meta property="og:description" content="Master grammar and vocabulary with tips and strategies for learners of all levels."/>
        <meta property="og:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta property="og:url" content="https://japanible.com/language"/>
        <meta property="og:type" content="website"/>

        <meta name="twitter:title" content="Discover all articles on Japanible."/>
        <meta name="twitter:description" content="Master grammar and vocabulary with tips and strategies for learners of all levels."/>
        <meta name="twitter:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@japanible"/>

        <meta name="author" content="Japanible"/>

        </Helmet>
      {data.filter(e=>(e.hashtags.includes('language'))) && data.filter(e=>(e.hashtags.includes('language')))[0] ? <script> window.prerenderReady=true</script> : <script> window.prerenderReady=false</script>}
        <h1>All Language Articles</h1>
        {/* <h2>{location.slice(1)}</h2> */}
        {data.filter(e=>(e.hashtags.includes('language'))).length === 0 ? "No articles written yet or haven't loaded yet." : 
        <ArticlesWrapper>
        {data.filter(e=>(e.hashtags.includes('language'))).length%3==1 && <ArticlesWrapper>{data.filter(e=>(e.hashtags.includes('language'))).map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}<ArticleCardFiller /><ArticleCardFiller /></ArticlesWrapper>}
        {data.filter(e=>(e.hashtags.includes('language'))).length%3==2 && <ArticlesWrapper>{data.filter(e=>(e.hashtags.includes('language'))).map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}<ArticleCardFiller /></ArticlesWrapper>}
        {data.filter(e=>(e.hashtags.includes('language'))).length%3==0 && <ArticlesWrapper>{data.filter(e=>(e.hashtags.includes('language'))).map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}</ArticlesWrapper>}
      </ArticlesWrapper>}
      </>
    )
  }

export default Language