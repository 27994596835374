import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  };
  
  const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  };

const Wrapper = styled.div`
@media ${devices.mobileM} {
    width: 100%;
}
  @media ${devices.mobileS} {
    width: 100%;}  
    @media ${devices.mobileL} {
     width: 100%;}
     @media ${devices.tablet} {
        width: 45%;}
  @media ${devices.laptop} {
    width: 30%;
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;}
    
    margin-bottom: 150px;`

const CoverImage = styled.img`
    border-radius: 3px;
    height: 300px;
    width: 100%;
    object-fit: cover;` 

const SubtitleBox = styled.div`
    font-size: 14px;
    color: lightgray;
    display: flex;
    justify-content: space-between;`
    
const Title = styled.h2`
    font-size: 26px;
    color: red;
    font-weight: 400;`

const ArticleBodyPretext = styled.p`
    line-height: 2;
    `

const WatchMore = styled.p`
    text-align: right;
    color: red;`

const VideoCard = (props) => {
  if (props) {
  return (
    <Wrapper>
        <CoverImage src={props.data?.snippet.thumbnails.high.url}>
        </CoverImage>
        <Title>{props.data?.snippet.title}</Title>
        <SubtitleBox>
            <span>{new Date(props.data?.snippet.publishedAt).toString()} by {props.data?.snippet.channelTitle}</span>
            {/* <span>#culture #bilingual</span> */}
        </SubtitleBox>
        <ArticleBodyPretext>{props.data?.snippet.description}</ArticleBodyPretext>
        <Link to={"/videos/"+props.data?.snippet.channelId+"/"+props.data?.id.videoId}><WatchMore>Watch video</WatchMore></Link>
    </Wrapper>
  )
} else {
  return (<>Hello</>)
}
}

export default VideoCard