import React, {useEffect, useState} from 'react'
import { supermemo } from "supermemo";
import dayjs from "dayjs";

import { Helmet } from 'react-helmet'
// import { UserAuth } from '../context/AuthContext';
// import { doc, setDoc, getDoc} from "firebase/firestore";
import { db } from '../Firebase/firebase'


// import ReactTooltip from 'react-tooltip';
import {dataN5} from '../media/data'
import {BsThreeDotsVertical} from 'react-icons/bs'

import styled from "styled-components";


const sectionViewPort = 900 * (100 / document.documentElement.clientWidth);

const Wrapper = styled.div`
min-height: ${100-sectionViewPort}vh;`

const ExerciseAndMenuWrapper = styled.div`
display: flex;`

const ExerciseWrapper=styled.div`
// padding-left: 32px;
width: 100%;`

const CardWrapper = styled.div`
text-align: center;
background-color: transparent;
border: 1px solid #f1f1f1;
border-radius: 3px;
padding: 9px;
height: 400px;
margin-bottom: 9px;`

const Tag = styled.span`
background-color: #f1f1f1;
border-radius: 3px;
font-size: 9px;
color: #000;
padding: 3px;`

const ButtonLabel = styled.div`
display: flex;
flex-direction: row;
align-items: center;`

const KeyTag = styled.span`
background: linear-gradient(239deg, rgba(241,241,241,1) 0%, rgba(134,134,134,0.5) 100%);
border-radius: 3px;
font-size: 9px;
color: #000;
padding: 3px;
min-width: 14px;
height: 14px;
margin-right: 3px;

span {border: solid 1px white;
  background: linear-gradient(239deg, rgba(241,241,241,1) 100%, rgba(134,134,134,0.5) 0%);
  border-radius: 2px;
padding: 0px 3px;
margin-bottom: 2px;
color: gray;}`

const ProgressBar = styled.div`
min-width: 6px;
height: 6px;
margin-bottom: 16px;
background-color: red;
border-radius: 3px
`
const CardBarRow = styled.div`
height: 100px;
display: flex;
flex-direction: row;
`
const CardBarRowIcon = styled.span`font-size: 32px;`
const CardBarRowButton = styled.button`
background-color: #fff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
font-size: 16px;
border-radius: 3px;
margin: 3px;
border: 1px solid black;
padding: 9px;`
const CardBody = styled.div``
const CardTopBar = styled.div`
span {
  flex: 1;
  display: flex;
}

display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 32px 

`
const CTA = styled.button`
background-color: red;
color: white;
border-radius: 3px;
border: transparent 0px solid;
padding: 9px;
font-size: 16px;`

const HiddenCardDetailsBox = styled.div`
position: absolute;
display: flex;
align-items: flex-start;
flex-direction: column;
padding-top: 50px;
left: 210px;
opacity: 0.3;`

const Learn = (props) => {
//USER ADMINISTRATION STATES
// const {user} = UserAuth()

// const [userStudyData, setUserStudyData] = useState(null)
const [fetchDone, setFetchDone] = useState(true)

//BASIC CARD STATES
const [flipped, setFlipped] = useState(false)
const [selectedTen, setSelectedTen] = useState(10)

const [sessionInfo, setSessionInfo] = useState({})
const [sessionUsersCard, setSessionUsersCard] = useState([])
const [sessionUsersCardFlipped, setSessionUsersCardsFlipped] = useState([])
const [currentlySelectedFlashCard, setCurrentlySelectedFlashCard] = useState({
  id: 1,
  front: "programer (Example)",
  back: "an organism that turns caffeine in software (Example)",
  interval: 0,
  repetition: 0,
  efactor: 2.5,
  dueDate: dayjs().add(1, 'day')
})

const [sessionDataSet, setSessionDataSet] = useState([])
useEffect(() => {setSessionDataSet(dataN5)}, [])

// Scroll Up
useEffect(() => {
  window.scrollTo(0, 0);
}, [])

// Get Session Info
useEffect(() => {
  setSessionInfo({
    selectedLevel: "N5",
    levelCardNumber: sessionDataSet.length,
    sessionCardNumber: sessionUsersCard.length
  })
}, [sessionDataSet])

// Set Cards for Session
useEffect(() => {
  getAndSetCards()
}, [sessionDataSet])


//Get Cards if users card are equal to 0 or lesser than 10
const getAndSetCards = () => {
  if (sessionDataSet.length > 0 && sessionUsersCard.length < 1) {
    const _stackToBePushed = []

    for (let i = 0; i < sessionDataSet.length; i++) {
      _stackToBePushed.push({
        ...sessionDataSet[i],
        interval: 0,
        repetition: 0,
        efactor: 2.5,
        dueDate: dayjs().add(i, 'hour')
      }) 
    }
    setSessionUsersCard(_stackToBePushed)
  }
}

const setNext = () => {
  const candidate = sessionUsersCard.sort((a, b) => (dayjs(a.dueDate).isAfter(dayjs(b.dueDate)) ? 1 : -1))[0]
  const secondCandidate = sessionUsersCard.sort((a, b) => (dayjs(a.dueDate).isAfter(dayjs(b.dueDate)) ? 1 : -1))[1]

  if (currentlySelectedFlashCard === candidate) {setCurrentlySelectedFlashCard(secondCandidate)
  } else {setCurrentlySelectedFlashCard(candidate)}

}

useEffect(()=>{
  setNext();
},[sessionUsersCard.length > 0])




  //Valuate card
  const practice = (currentlySelectedFlashCard, grade) => {
    const { interval, repetition, efactor } = supermemo(currentlySelectedFlashCard, grade);
    const dueDate = dayjs().add(interval, 'day')
    setSessionUsersCard(sessionUsersCard.map((e) => (e.id === currentlySelectedFlashCard.id ? { ...e, interval, repetition, efactor, dueDate, tested: true } : (e))));
   
    shuffle()
  } 

//Flip view {FRONT OR BACK}
const clicked = () => {setFlipped(!flipped)}


//CARD BASIC FUNCTION
const shuffle = () => {
  if (selectedTen > 0) {
  setSelectedTen(selectedTen-1)
  clicked();

  setNext();
  return}
  else alert('Reset the deck first.')
}

const resetDeck = () => {
  setSelectedTen(10)
  clicked();
  setNext();
}




  return (
    <>

<Wrapper>
<h1>Study Cards: {sessionInfo?.selectedLevel} {sessionInfo && <>All cards: {sessionInfo?.levelCardNumber}</>} {sessionUsersCard && <>New cards: {sessionUsersCard.filter((e)=>(e.interval === 0)).length}</>}</h1>

{sessionUsersCard.length > 0 ? (<>

  <ExerciseAndMenuWrapper>
    {/* {JSON.stringify(currentlySelectedFlashCard)} */}
  <ExerciseWrapper>

  {currentlySelectedFlashCard ?
        <CardWrapper key={'WRAPPER'}>
        <ProgressBar style={{width: 100 - (selectedTen * 10 ) + "%"}}/>

        {!flipped && selectedTen > 0 ?
        <>
        <div style={{height: "95%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>

            <CardTopBar>
                <span>Front Side - Question</span>
                <span style={{display: 'flex', justifyContent: 'flex-end'}}><BsThreeDotsVertical/></span>
            </CardTopBar>
          
          <CardBody>
          <h2>{currentlySelectedFlashCard.kanji}</h2>
          <h3>{currentlySelectedFlashCard.kana}</h3>
          

          </CardBody>

          <CardBarRow>
                {/* <CardBarRowButton onClick={clicked}>
                <CardBarRowIcon>👀</CardBarRowIcon>
                <ButtonLabel>
                <KeyTag><span>Q</span></KeyTag>
                Details
                </ButtonLabel>
                </CardBarRowButton> */}
              <CardBarRowButton onClick={clicked}>
              <CardBarRowIcon>✨</CardBarRowIcon>
                <ButtonLabel>
                <KeyTag><span>Space</span></KeyTag>
                Show Answer
                </ButtonLabel>
                </CardBarRowButton>
                {/* <CardBarRowButton onClick={clicked}>
                <CardBarRowIcon>💨</CardBarRowIcon>
                <ButtonLabel>
                <KeyTag><span>R</span></KeyTag>
                Skip
                </ButtonLabel>
                </CardBarRowButton> */}
              </CardBarRow>       
              </div></> : ''}


          {flipped && selectedTen > 0 ? <>
            <div style={{height: "95%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>


          <CardTopBar>
                <span>Back Side - Answer</span>
                <span style={{display: 'flex', justifyContent: 'flex-end'}}><BsThreeDotsVertical/></span>

            </CardTopBar>

          <CardBody>
            <h2>{currentlySelectedFlashCard.kanji}</h2>
            <h3>{currentlySelectedFlashCard.kana}</h3>  
            <h2>{currentlySelectedFlashCard.meaning}</h2>
  
          </CardBody>


            <CardBarRow style={{display: "flex", flexDirection: "row"}}>
              <CardBarRowButton onClick={()=>{practice(currentlySelectedFlashCard, 5)}} style={{border: "1px solid green"}}>
              <CardBarRowIcon>👌</CardBarRowIcon>
                <ButtonLabel>
                <KeyTag><span>1</span></KeyTag>
                Very Easy
                </ButtonLabel></CardBarRowButton>
              <CardBarRowButton onClick={()=>{practice(currentlySelectedFlashCard, 3)}} style={{border: "1px solid blue"}}>
              <CardBarRowIcon>🤔</CardBarRowIcon>
                <ButtonLabel>
                <KeyTag><span>2</span></KeyTag>
                Got it</ButtonLabel>
                </CardBarRowButton>
              <CardBarRowButton onClick={()=>{practice(currentlySelectedFlashCard, 2)}} style={{border: "1px solid orange"}}>
                <CardBarRowIcon>😬</CardBarRowIcon>
                <ButtonLabel>
                <KeyTag><span>3</span></KeyTag>
                 Partially
                 </ButtonLabel>
                 </CardBarRowButton>
              <CardBarRowButton onClick={()=>{practice(currentlySelectedFlashCard, 0)}} style={{border: "1px solid red"}}>
                <CardBarRowIcon>😰</CardBarRowIcon>
                <ButtonLabel>
                <KeyTag><span>4</span></KeyTag>
                Forgotten
                </ButtonLabel>
                </CardBarRowButton>
              </CardBarRow>
              </div></> : ''}



          {selectedTen === 0 && <>
          <h1>Well done!</h1>
          <h2>You are out of cards.</h2>

          <CTA onClick={()=>{resetDeck()}}>Learn new cards.</CTA> <CTA>Practice cards.</CTA> <CTA>Quit learning for now.</CTA></>}
        </CardWrapper>
  : 'ERROR UNDEFINED DECK'}

          {/* <pre>
            {JSON.stringify(sessionUsersCard.sort((a, b) => (dayjs(a.dueDate).isAfter(dayjs(b.dueDate)) ? 1 : -1)).map(e=>e.kana), null, 0)}
          </pre> */}

          </ExerciseWrapper>
          </ExerciseAndMenuWrapper>
          </>) : 'X'}
  
        </Wrapper>
    </>
  )
}

export default Learn




//USER DATA ADMINISTRATION => SAVE/GET USERS DATA

// const setNewUserDataStudy = async (number) => {
//   await setDoc(doc(db, "study", user.uid), {
//     progress: {learntCardsNumber: number}
//   })
//   console.log(new Date(), 'Successfully registered new data.')
// }

// const getUserDataStudy = async () => {

//     const docSnap = await getDoc(doc(db, "study", user.uid))
//     if (docSnap.exists()) {
//       const fetchedUserStudyData = []
//       fetchedUserStudyData.push(docSnap.data())
//       await setUserStudyData(fetchedUserStudyData)
//       setFetchDone(true)
//       console.log(new Date(), 'Successfully fetched data for user', user.displayName)
//       console.log(userStudyData)
//     } else {
//       setFetchDone(true)
//       console.log(new Date(), 'No study data for user', user.uid)
//     }
//     }

// useEffect(()=>{
//   getUserDataStudy()
// },[])