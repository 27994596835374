import React, {useState, useEffect, Fragment} from 'react'
import {useParams} from 'react-router-dom'
import {getDoc, getDocs, where, doc , collection, query} from "firebase/firestore"; 
import {db} from '../Firebase/firebase'
import styled from 'styled-components';
import { UserAuth } from '../context/AuthContext';


import ArticleCard from '../components/ArticleCard'
import DraftCard from '../components/DraftCard'

import ArticleCardFiller from '../components/ArticleCardFiller'
import LoadingPlaceholderItem from '../components/LoadingPlaceholderItem';


const ArticlesWrapper = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
min-width: 100%;
`




const PublicUserProfile = () => {
  useEffect(() => {window.scrollTo(0, 0)}, [])
  const {uid} = useParams();
  const {user} = UserAuth();
  const [dataState, setDataState] = useState('loading')


  const [fetchedUser, setFetchedUser] = useState([]);
  const [articles, setArticles] = useState([])
  const [drafts, setDrafts] = useState([])

  useEffect(()=>{getUserById()},[user])
  useEffect(()=>{getDraftsById()},[user])

  const getUserById = async () => {
    const docSnap = await getDoc(doc(db, "users", uid))
          if (docSnap.exists()) {
             const results = []
             await setFetchedUser(docSnap.data())
             const q = query(collection(db, "posts"), where('author',"==", uid));
             const articlesByFetchedUser = await getDocs(q);
             articlesByFetchedUser.forEach((doc)=>{results.push(doc.data())})
             await setArticles(results)
             setDataState('success')
            } else {
            setDataState('error')
          }}


const getDraftsById = async () => {
  if (user) {
    if (user.uid === uid) {
    const docSnap = await getDoc(doc(db, "users", uid))

    if (docSnap.exists()) {
       const results = []
       await setFetchedUser(docSnap.data())
       const q = query(collection(db, "drafts"), where('author',"==", uid));
       const articlesByFetchedUser = await getDocs(q);
       articlesByFetchedUser.forEach((doc)=>{results.push(doc.data())})
       await setDrafts(results)
    } else {
      // console.log("No such document!");
    }}} else return}


  if (dataState === 'success') return (
    <>
    <h1>Profile Page</h1>
    <img src={fetchedUser.photoURL} alt={`${fetchedUser.userName}'s Profile Picture`}/>
    <h2>{fetchedUser.userName ? fetchedUser.userName : fetchedUser.fullName}</h2>
    <h4>@{uid}</h4>
    <p>Joined at {new Date().toLocaleString()}</p>
    <h2>List of articles</h2>
    {articles.length === 0 ? "No articles yet." : 
      <ArticlesWrapper>
      {articles.length%2==0 || articles.length%5==0 ?
      <ArticlesWrapper>{articles.map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}<ArticleCardFiller /></ArticlesWrapper> :
      <ArticlesWrapper>{articles.map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}</ArticlesWrapper>}
    </ArticlesWrapper>}

    

    {user && uid === user.uid && (
    <>
    <h2>List of drafts</h2>
    {drafts.length === 0 && "No drafts yet. Write something."}
    {drafts.length > 0 && (
      <ArticlesWrapper>
      {drafts.length%2==0 || drafts.length%5==0 ?
        <ArticlesWrapper>{drafts.map((e, i) => { return <DraftCard key={i} number={e.slug} article={e} /> })}<ArticleCardFiller /></ArticlesWrapper> :
        <ArticlesWrapper>{drafts.map((e, i) => { return <DraftCard key={i} number={e.slug} article={e} /> })}</ArticlesWrapper>}
      </ArticlesWrapper>)}
      </>)}

    </>
  )
  
  if (dataState === 'error') return (
  <>
  <h1>No profile has been found.</h1>
  <p>Possible reasons include:
    <ul>
      <li>User making account before we have implemented user profiles system.</li>
      <li>Connection has no reached database.</li>
      <li>User has been banned or permanently deleted (extremelly rare case).</li>
    </ul>
  </p>
  </>)


if (dataState === 'loading') return (
  <>
    <>
    <h1>Profile Page</h1>
    <LoadingPlaceholderItem width="96px" height="96px"/>
    <div style={{margin: '20px 0px', display: 'block'}}>
    <LoadingPlaceholderItem width="300px" height="90px" bottom="38px"/>
    </div>
    <h2>List of articles</h2>
    <LoadingPlaceholderItem width="30%" height="300px"/>
    <div style={{margin: '20px 0px', display: 'block'}}>
    <LoadingPlaceholderItem width="30%" height="100px"/>
    </div>
    <LoadingPlaceholderItem width="30%" height="50px"/>
    </>
  </>)
}

export default PublicUserProfile