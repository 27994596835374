import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components';
import { InstagramEmbed } from 'react-social-media-embed';

import VideoCard from '../components/VideoCard'
import ArticleCardFiller from '../components/ArticleCardFiller'

const ArticlesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`

const Videos = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [fetchedVideos, setFetchedVideos] = useState(["CfRMUNklcsk", "Cf02RuvFDer", "Cf6ZZMhlnPl", "CgCKytMFV1K", "CgGBmZnFxFZ"])

  // useEffect(() => {
  //   const fetchVideos = async () => {
  //     const result = await fetch("https://www.googleapis.com/youtube/v3/search?key="+YOUTUBEAPIKEY+"&channelId=UCKVzPeUvluKR9EDTn8Q-bkw&part=snippet,id&order=date&maxResults=20")
  //       .then(response => response.json())
  //       .then(data => setFetchedVideos(data.items))
  //   }
  //   fetchVideos()
  // }, [])

  return (
    <>
      <h1>Check Out Our IG Reels!</h1>
      {/* {fetchedVideos.snippet !== undefined ? fetchedVideos.length % 4 === 0 ?
      <ArticlesWrapper>{fetchedVideos.map((e,i)=>{ return <VideoCard key={i} data={e}/>})}<ArticleCardFiller /></ArticlesWrapper>:
      <ArticlesWrapper>{fetchedVideos.map((e,i)=>{ return <VideoCard key={i} data={e}/>})}</ArticlesWrapper>
        :
      "Taking in progress!!"} */}

      {fetchedVideos !== undefined ? fetchedVideos.length % 4 === 0 ?
      <ArticlesWrapper>{fetchedVideos.reverse().map((e,i)=>{ return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <InstagramEmbed url={"https://www.instagram.com/reel/"+e+"/"} width={"100%"} />
    </div>})}<ArticleCardFiller /></ArticlesWrapper>:
      <ArticlesWrapper>{fetchedVideos.reverse().map((e,i)=>{ return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <InstagramEmbed url={"https://www.instagram.com/reel/"+e+"/"} width={"100%"} />
    </div>})}</ArticlesWrapper>
        :
      "Taking in progress!!"}

    </>
  );}

export default Videos;