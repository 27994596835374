import React, { useState, useEffect } from 'react'
import ArticleCard from '../components/ArticleCard'
import ArticleCardFiller from '../components/ArticleCardFiller'
import ArticleCardPlaceholder from '../components/ArticleCardPlaceholder'
import styled from 'styled-components';
import { collection, getDocs, where, query } from "firebase/firestore"; 
import {db} from '../Firebase/firebase'
import {useLocation} from 'react-router-dom'
import dayjs from 'dayjs'
// import articleData from '../data/articleData'

const ListArticles = () => {
  const [articles, setArticles] = useState([]);
  const [dataLoadingState, setDataLoadingState] = useState('loading')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const getData = async () => {
        let events = []
          const q = query(collection(db, "posts"))
          const postsSnapshot = await getDocs(q);
          await postsSnapshot.forEach((doc) => {
            events.push(doc.data());
          }
          )
             setArticles(events.sort((a, b)=>(dayjs(a.publishedAt) > (dayjs(b.publishedAt)) ? -1 : 1)))
        setDataLoadingState('loaded')
    }
    getData()
  }, [])


  if (dataLoadingState === "loading") return (<><h1>Loading</h1></>)
    
if (dataLoadingState === "loaded") return (
      <>
        <h1>All Articles - Table</h1>
        <table>
          <thead>
            <tr>
              <th>Slug</th>
              <th>Title</th>
              <th>Published At</th>
              <th>Updated At</th>
              {/* <th>Description</th> */}
              <th>Hashtags</th>
              {/* <th>Image URL</th> */}
              <th>Author</th>
            </tr>
          </thead>
          <tbody>
          {articles.map((e)=>(<tr>
            <td>{e.slug}</td>
            <td>{e.title}</td>
            <td>{e.publishedAt}</td>
            <td>{e.updatedAt}</td>
            {/* <td>{e.description.substring(0, 30)}</td> */}
            <td>{e.hashtags}</td>
            {/* <td>{e.urlToImage.substring(0, 30)}</td> */}
            <td>{e.author}</td>
          </tr>))}
          </tbody>

        </table>
        <h1>All Articles - Sitemap Ready</h1>


        {articles.map((e)=>(<div>{'<url><loc>https://japanible.com/originals/'}{e.slug}{'</loc><lastmod>'}{e.updatedAt ? e.updatedAt : e.publishedAt}{'</lastmod><priority>0.70</priority></url>'}</div>))}
        </>)}

export default ListArticles