import React from 'react'
import { Navigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'

const ProtectedToSignIn = ({children}) => {
  const {user }=UserAuth();
  if(!user){
      return (<Navigate to="/signin"/>)
  } else return children
}

export default ProtectedToSignIn