import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import styled, {createGlobalStyle} from 'styled-components';
import { Helmet } from 'react-helmet';

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";


import { AuthContextProvider } from "./context/AuthContext";

//Resources
import {devices} from './styles/mediaQueries'
import './App.css';
import FavIcon from './media/siteicon/favicon-32x32.png'

//Components
import ProtectedToRoot from './components/ProtectedToRoot'
import ProtectedToSignIn from './components/ProtectedToSignIn'
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import Announcements from './components/Announcements';


//Pages
import SignIn from './pages/SignIn';
import Account from './pages/Account'

import WelcomePage from './pages/WelcomePage'

import ShowArticlesList from './pages/ShowArticlesList';
import Videos from './pages/Videos'
import Jobs from './pages/Jobs'
import Originals from './pages/Originals'
import Community from './pages/Community'
import Food from './pages/Food'
import Travel from './pages/Travel'
import CraftsArt from './pages/CraftsArt'

import Language from './pages/Language'
import Culture from './pages/Culture'


import ListArticles from './pages/ListArticles'


import Learn from './pages/Learn'
import Share from './pages/Share'
import ShareEdit from './pages/ShareEdit'
import AboutJapanible from './pages/AboutJapanible'
import PostJobOffer from './pages/PostJobOffer'
import Privacy from './pages/Privacy'
import PublicUserProfile from './pages/PublicUserProfile'
import RequestInterview from './pages/RequestInterview'
import Roadmap from './pages/Roadmap'
import Support from './pages/Support'
import FullArticlePage from "./pages/FullArticlePage";
import FullVideoPage from "./pages/FullVideoPage";

const queryClient = new QueryClient();


//Space footer
const sectionViewPort = 900 * (100 / document.documentElement.clientWidth);

//Styles
const GlobalStyle = createGlobalStyle`
      html {
        scroll-behavior: smooth;
      }`

const Wrapper = styled.div`
min-height: ${100-sectionViewPort}vh;
@media ${devices.mobileM} {
  padding: 0px 10px;}
@media ${devices.mobileS} {
  padding: 0px 10px;}  
  @media ${devices.mobileL} {
    padding: 0px 20px;}
@media ${devices.laptop} {
     padding: 0px 200px;}
      `
function App() {
  return (
    <AuthContextProvider>
      <Helmet>
        <title>Japanible | Platform for people who love Japan</title>

        <meta name="description" content="Japanible is a platform for people who love Japan. Sharing Awesomeness from Japan. Writing articles about Japan, japanese culture and language." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="index, follow"/>
        <link rel="icon" type="image/png" href={FavIcon} sizes="32x32"/>
        <link rel="canonical" href="https://japanible.com"/>

        <meta property="og:title" content="Japanible - Community Platform for Japan Lovers"/>
        <meta property="og:description" content="Discover curated articles about Japanese culture, study methodology for Japanese language learners, job offers and more on Japanible."/>
        <meta property="og:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta property="og:url" content="https://japanible.com"/>
        <meta property="og:type" content="website"/>

        <meta name="twitter:title" content="Japanible - Community Platform for Japan Lovers"/>
        <meta name="twitter:description" content="Discover curated articles about Japanese culture, study methodology for Japanese language learners, job offers and more on Japanible."/>
        <meta name="twitter:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@japanible"/>

        <meta name="author" content="Japanible"/>

      </Helmet>
    <Router>
    <Wrapper>        
      <TopBar></TopBar>

      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools/> */}

      <Routes>
        {/* Sitemap */}
          <Route path="/listarticles" element={<ListArticles/>}></Route>

        {/* Main Navigation */}
          <Route path="/" element={<WelcomePage/>}></Route>
          <Route path="/signin" element={<SignIn/>}></Route>
          <Route path="/account" element={<ProtectedToRoot><Account/></ProtectedToRoot>}></Route>

          <Route path="/user/:uid" element={<PublicUserProfile/>}></Route>
          <Route path="/user/:uid/drafts/:draftId" element={<Share/>}></Route>

          <Route path="/aboutjapanible" element={<AboutJapanible/>}></Route>
          <Route path="/privacy" element={<Privacy/>}></Route>
          <Route path="/roadmap" element={<Roadmap/>}></Route>

        {/* Categories */}
          <Route path="/news" element={<ShowArticlesList/>}></Route>
          <Route path="/originals" element={<Community/>}></Route>
          <Route path="/community" element={<Community/>}></Route>      
          <Route path="/travel" element={<Travel/>}></Route>
          <Route path="/food" element={<Food/>}></Route>
          <Route path="/language" element={<Language/>}></Route>
          <Route path="/craft-art" element={<CraftsArt/>}></Route>
          <Route path="/culture" element={<Culture/>}></Route>

          {/* Full Article Page*/}
          <Route path="/originals/:slug" element={<FullArticlePage/>}></Route>

          {/* Edit an Article */}
          <Route path="/originals/:slug/edit" element={<ProtectedToSignIn><ShareEdit/></ProtectedToSignIn>}></Route>  
          <Route path="/share" element={<ProtectedToSignIn><Share/></ProtectedToSignIn>}></Route>        

          {/* Abandoned */}
          <Route path="/learn" element={
          // <ProtectedToSignIn>
            <Learn/>
            // </ProtectedToSignIn>
          }></Route>
          <Route path="/videos" element={<Videos/>}></Route>
          <Route path="/jobs" element={<Jobs/>}></Route>
          <Route path="/jobs/new" element={<ProtectedToSignIn><PostJobOffer/></ProtectedToSignIn>}></Route>
          <Route path="/videos/:channelId/:videoId" element={<FullVideoPage/>}></Route>
          <Route path="/requestinterview" element={<ProtectedToSignIn><RequestInterview/></ProtectedToSignIn>}></Route>
          <Route path="/support" element={<Support/>}></Route>

          <Route path="*" element={<><h1>We apologize.</h1>
          <p>Oops! Looks like the page you were looking for doesn't exist.</p><p> We apologize for any inconvenience. Please try using our navigation menu to find what you're looking for, or return to our homepage to start exploring Japanible's content about Japan.</p>
          <p>Thank you for visiting Japanible.</p></>}></Route>
        </Routes>
        </QueryClientProvider>
    </Wrapper>
    <Footer/>

    </Router>
    </AuthContextProvider>
  );
}

export default App;
