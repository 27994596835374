import React, { useEffect } from 'react'
import {GoogleButton} from 'react-google-button';
import styled from 'styled-components';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';


const Wrapper = styled.div`
    padding: 100px 0px 200px 0px;
    display: flex;
    justify-content: center;`
  
const SignIn = () => {

  const {googleSignIn, user} = UserAuth();
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(()=>{
    if(user != null) {
      navigate('/account')
    }
  },[user])

    return (
      <Wrapper>
    <GoogleButton onClick={handleGoogleSignIn}>Sign In with Google</GoogleButton>
    </Wrapper>
  )}

export default SignIn