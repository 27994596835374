import React, {useState, useEffect, Fragment} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {getDoc, getDocs, where, doc , collection, query} from "firebase/firestore"; 
import {db} from '../Firebase/firebase'
import ReactMarkdown from 'react-markdown'
import {BsClockHistory, BsChatSquareDots} from 'react-icons/bs'
import LoadingPlaceholderItem from './LoadingPlaceholderItem';

import dayjs from 'dayjs'

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  };
  
  const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  };

const Wrapper = styled.div`
@media ${devices.mobileM} {
    width: 100%;
}
  @media ${devices.mobileS} {
    width: 100%;}  
    @media ${devices.mobileL} {
     width: 100%;}
     @media ${devices.tablet} {
        width: 45%;}
  @media ${devices.laptop} {
    width: 30%;
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;}
    
    margin-bottom: 150px;`

const CoverImage = styled.img`
    border-radius: 3px;
    height: 300px;
    width: 100%;
    object-fit: cover;` 

const SubtitleBox = styled.div`
    font-size: 14px;
    color: lightgray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;`
    
const Title = styled.h2`
    font-size: 26px;
    color: red;
    font-weight: 400;`

const ArticleBodyPretext = styled.p`
    line-height: 2;
    `

const ReadMore = styled.p`
    text-align: right;
    color: red;`

const Tag = styled.span`
    color: lightgray;
    background-color: transparent;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 0px 10px;
    margin-right: 10px;`

const ArticleCard = (props) => {

const [author, setAuthor] = useState("")

const getAuthor = async () => {
  const docSnap = await getDoc(doc(db, "users", props.article?.author))
  if (docSnap.exists()) {
     await setAuthor(docSnap.data())
    } else {console.log('403')}}

useEffect(()=>{getAuthor()},[])

  return (
    <Wrapper>
        <Link to={"/originals/"+props.number}>
        <CoverImage src={props.article?.urlToImage} alt={props.article?.title}>
        </CoverImage>
        <Title>{props.article?.title}</Title></Link>
        <SubtitleBox>
          <>
            <span style={{display: 'flex', alignItems: 'center'}}>
              {props.article && props.article?.publishedAt.length > 10 && new Date(props.article?.publishedAt).toDateString()} <span style={{margin: '0px 6px'}}> by </span>
                {!author.userName ? <LoadingPlaceholderItem width="90px" height="14px" bottom="0px"/> : <><Link to={"/user/"+props.article?.author}>{author.userName || author.fullName || props.article?.author}</Link></>}
              </span>
            </>
        </SubtitleBox>
        <ArticleBodyPretext><ReactMarkdown children={props.article?.description}/></ArticleBodyPretext>
        {/* {props.number?<Link to={"/originals/"+props.number}><ReadMore>Read more</ReadMore></Link>:""} */}
    </Wrapper>
  )
}

export default ArticleCard