import React from 'react';
import styled, {keyframes} from 'styled-components';

const loading = keyframes`
  0%{
    left: -45%;
  }
  100%{
    left: 100%;
}`

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  };
  
  const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  };

const Wrapper = styled.div`
@media ${devices.mobileM} {
    width: 100%;
}
  @media ${devices.mobileS} {
    width: 100%;}  
    @media ${devices.mobileL} {
     width: 100%;}
     @media ${devices.tablet} {
        width: 45%;}
  @media ${devices.laptop} {
    width: 30%;
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;}
    
    margin-bottom: 150px;`

const CoverImage = styled.div`
    border-radius: 3px;
    height: 300px;
    width: 100%;
    background-color: pink;
      position: relative;
      background-color: rgb(211,211,211);
      z-index: 44;
      overflow: hidden;

      div {
        position: absolute;
        left: -45%;
        height: 100%;
        width: 45%;
        background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        background-image: -moz-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        background-image: -webkit-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        animation: ${loading} 1s infinite;
        z-index: 45;
      }
    ` 

const Body = styled(CoverImage)`
    margin-top: 25px;
    height: 100px;
    `

    const Title = styled(CoverImage)`
    margin-top: 25px;
    height: 50px;
    `

const ArticleCardPlaceholder = (props) => {

  return (
    <Wrapper>
        <CoverImage>
        <div/>
        </CoverImage>
        
        <Title>
        <div/>
        </Title>

        <Body>
        <div/>
        </Body>


    </Wrapper>
  )
}

export default ArticleCardPlaceholder