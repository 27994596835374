import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import ArticleCard from '../components/ArticleCard'
import ArticleCardFiller from '../components/ArticleCardFiller'
import ArticleCardPlaceholder from '../components/ArticleCardPlaceholder'

import { collection, getDocs } from "firebase/firestore"; 
import {db} from '../Firebase/firebase'

import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

const HeroImage = styled.img`
object-fit: cover;
width: 33%;
height: 30vh;
border-radius: 3px;
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,9,9,1) 35%, rgba(0,212,255,1) 100%);`

const ArticlesWrapper = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
min-width: 100%;
`
const WelcomePage = () => {
  const [dataLoadingState, setDataLoadingState] = useState('loading')
  const [articles, setArticles] = useState([]);

  const { isLoading, error, data, isFetching, isFetched } = useQuery({
    queryKey: ["allarticles"],
    cacheTime: 15 * (60 * 1000), // 15 mins 
    queryFn: async () =>{
      let _resultArray = []
      const postsSnapshot = await getDocs(collection(db, "posts"));
      await postsSnapshot.forEach((doc) => {
        _resultArray.push(doc.data());
    })
      return _resultArray.sort((a, b)=>(dayjs(a.publishedAt) > (dayjs(b.publishedAt)) ? -1 : 1))
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // useEffect(() => {
  //   const getData = async () => {
  //     let events = []
  //     const postsSnapshot = await getDocs(collection(db, "posts"));
  //     await postsSnapshot.forEach((doc) => {
  //       events.push(doc.data());
  //     }
  //     )
  //          setArticles(events.sort((a, b)=>(dayjs(a.publishedAt) > (dayjs(b.publishedAt)) ? -1 : 1)).slice(0,3));
  //     setDataLoadingState('loaded');

  //   }
  //   getData()
  // }, [])

  return (
    <>

<Helmet>
        <title>Japanible | Platform for people who love Japan</title>

        <meta name="description" content="Japanible is a platform for people who love Japan. Sharing Awesomeness from Japan. Writing articles about Japan, japanese culture and language." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="index, follow"/>
        <link rel="canonical" href="https://japanible.com"/>

        <meta property="og:title" content="Japanible - Community Platform for Japan Lovers"/>
        <meta property="og:description" content="Discover curated articles about Japanese culture, study methodology for Japanese language learners, job offers and more on Japanible."/>
        <meta property="og:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta property="og:url" content="https://japanible.com"/>
        <meta property="og:type" content="website"/>

        <meta name="twitter:title" content="Japanible - Community Platform for Japan Lovers"/>
        <meta name="twitter:description" content="Discover curated articles about Japanese culture, study methodology for Japanese language learners, job offers and more on Japanible."/>
        <meta name="twitter:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@japanible"/>

        <meta name="author" content="Japanible"/>

      </Helmet>

    {isFetched ? <script> window.prerenderReady=true</script> : <script> window.prerenderReady=false</script>}

    <div>
      <h1>Hello from Japanible!</h1>
      <p>We are creating this platform for people who love Japan, we are creating this for you.</p>
      
      <h2>Japanese? History? Studying in Japan? Visas?</h2>
      <p>We write about all the important stuff! Check out <Link to="/community">community articles in Read All Articles section</Link> or write your own after signing up!</p>
    
      <h2>Latest Articles</h2>
      {(isLoading) && (<>
   
    <ArticlesWrapper>
      <ArticleCardPlaceholder/>
      <ArticleCardPlaceholder/>
      <ArticleCardPlaceholder/>
      </ArticlesWrapper></>)}
    {(isFetched) && (
      <>
        
        {/* <h2>{location.slice(1)}</h2> */}
        {data.length === 0 ? "No articles written yet or haven't loaded yet." : 
        <ArticlesWrapper>
          {data.slice(0, 3).map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}
        </ArticlesWrapper>}
      </>
    )}

      <h2>Stay with us!</h2>
      <p>Check out where are heading from now on! Read more in the <Link to="/roadmap">roadmap</Link>.</p>
      <p>We also post awesome video reels from Japan on our <a href="https://instagram.com/japanible" target="_blank" rel="noopener noreferrer">Instagram</a>. Leave us there a message. 🌟</p>


    </div></>

  )



  
}

export default WelcomePage