import { useContext, createContext, useEffect,useState } from "react";
import { GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged, signInWithRedirect, getAdditionalUserInfo } from "firebase/auth";
import { auth } from "../Firebase/firebase";
import {db} from '../Firebase/firebase'
import {doc, setDoc} from "firebase/firestore";


const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {

    //Track User
    const [user, setUser] = useState({});

    //Make Login Possible
    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
        .then(async (result) => {
            const user = result.user;
            const { isNewUser } = getAdditionalUserInfo(result)
            if (isNewUser) {
            //   console.log("Adding new user to the database.")
              await addUser(user.uid, user.displayName, user.photoURL)
              .then(()=>(console.log('Registered.'))); 
            } else {
            //   console.log("User already exists.")
            }
        })

        const addUser = async (userId, displayName, photoURL) => {
          const userRef = doc(db, "users", userId);
          return await setDoc(userRef, {fullName: displayName, photoURL: photoURL, userName: ""});
        };
    }

    //Make Logout Possible
    const logOut = () => {
        signOut(auth);
    }

    //Switch States ???
    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth, (currentUser)=>{
           setUser(currentUser);
           console.log('Via @AuthContex: Google user', currentUser);
        });
        return () => {
            unsubscribe();
        }
    }, [])


    return (
        <AuthContext.Provider value={{googleSignIn, logOut, user}}>
                {children}
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext)
}