import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  };
  
  const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  };

const Wrapper = styled.div`
@media ${devices.mobileM} {
    width: 100%;
}
  @media ${devices.mobileS} {
    width: 100%;}  
    @media ${devices.mobileL} {
     width: 100%;}
     @media ${devices.tablet} {
        width: 45%;}
  @media ${devices.laptop} {
    width: 30%;
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;}`

const CoverImage = styled.span`
    height: 1px;
    width: 100%;`


const ArticleCardFiller = (props) => {
  return (
    <Wrapper>
        <CoverImage></CoverImage>
    </Wrapper>
  )
}

export default ArticleCardFiller