import React, {Fragment, useEffect, useState} from 'react'
import styled from 'styled-components'
import { collection, getDocs, setDoc, doc } from "firebase/firestore"; 
import {db} from '../Firebase/firebase'
import {Link} from 'react-router-dom'
import Helmet from 'react-helmet'



const Table = styled.table`
  background-color: #f1f1f1;
  border-radius: 3px;
  width: 100%;
  padding: 12px;
  margin-bottom: 6px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
  }

  div > div {
    display: flex; flex-direction: column; align-items: flex-start;
  }`

const Tag = styled.span`
color: white; background-color: transparent; border: solid 1px white; margin: 2px; padding: 2px; border-radius: 3px;`

const RedTag = styled.span`
color: red; background-color: transparent; border: solid 1px red; margin: 2px; padding: 2px; border-radius: 3px;`

const CTA = styled.button`
background-color: red;
color: white;
border-radius: 3px;
border: transparent 0px solid;
padding: 9px;
font-size: 16px;`

const Jobs = () => {
  const [jobList, setJobList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const getJobs = async () => {
      let data = []
      const postsSnapshot = await getDocs(collection(db, "jobs"));
      await postsSnapshot.forEach((doc) => {
        data.push(doc.data());
      }
      )
      setJobList(data)
    }
    getJobs()
  }, [])

const postJob = async () => {
  await setDoc(doc(collection(db, "jobs")), {
    name: "Hotel",
    description: "Best Hotel. Best Service.",
    locationSmall: "A",
    locationBig: "B",
    tags: ["Japanese N1", "Hotel"],
    date: "now",
  })}

  // postJob()

  return (
    <>
     <Helmet>
        <title>Jobs | Platform for people who love Japan</title>

        <meta name="description" content="Post your Japan-related job openings on Japanible for our community to explore." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="noindex"/>
      </Helmet>

    <h1>Jobs</h1>
    <p>At Japanible, we believe in supporting our community by providing a space where anyone can freely post job openings related to Japan and we will display them for our audience of Japan enthusiasts to explore. Let us know!</p>
     
    <h2>
        Featured Job Listings
    </h2>


    {jobList ? jobList.map((e, i)=>(
      
    <Table key={`entry-${i}`}>
          <div>
        <div style={{flexBasis: "05%"}}>#{i+1}</div>
        <div style={{flexBasis: "45%"}}><b>{e.name}</b> {e.description}</div>
        <div style={{flexBasis: "20%"}}>📌 {e.locationSmall},<br/>{e.locationBig}</div>
        <div style={{flexBasis: "20%"}}>{e.tags.map((e, i)=>(<RedTag>{e}</RedTag>))}</div>
        <div style={{flexBasis: "10%"}}><Tag>See More</Tag>Posted {e.date}</div>
      </div>
    </Table>

    )) : "Currently no jobs have been shared yet."}
    {/* <Link to="/jobs/new">        <CTA>Post a New Job Listing</CTA></Link> */}

   </>
  )
}

export default Jobs