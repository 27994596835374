import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext';
import {BiMenu} from 'react-icons/bi';
import { Offline, Online } from "react-detect-offline";

import Cookies from './Cookies'


import {devices} from '../styles/mediaQueries'
import uuid from 'react-uuid';


const Logo = styled.span`
    font-weight: 700;
    cursor: pointer;
    font-size: 32px;
    color: red;
    display: block;
    margin-block-start: 21.44px;
    margin-block-end: 21.44px;

    
    `

const TopPart = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    align-items: center;
    `

const MenuBar = styled.ul`
    color: black;
    font-size: 16px;
    display: flex;
    list-style-type: none;
    margin-bottom: 75px;

    @media ${devices.mobileS} {
        background-color: #f1f1f1;
        border-radius: 3px;
        padding: 16px 16px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;}
        
    @media ${devices.laptop} {
        padding: 16px 0px;
        flex-direction: row;
        line-height: 1;
        background-color: white;
        justify-content: space-between;
        align-items: center;}
        `

const CTA = styled.button`
    background-color: red;
    color: white;
    border-radius: 3px;
    border: transparent 0px solid;
    padding: 9px;
    font-size: 16px;`

const ProfilePicture = styled.img`
    width: 32px;
    height: 32px;
    background-color: lightgray;
    border-radius: 100px;
    `
const AccountBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    `

const StyledLink = styled(NavLink)`
    text-decoration: none;`

const Admin = styled.span`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;`

const ConnectionBar = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 32px;
    background: pink;
    display: flex;
    justify-content: center;
    align-items: center;
`

const style = ({ isActive }) =>  isActive ? {color: 'black',  textDecoration: "red solid underline", textDecorationThickness: "3px"} : { color: "black", textDecoration: "none"}

const TopBar = () => {
    const [isToggled, setIsToggled] = useState(true)
    const { user, logOut } = UserAuth();
    return (
        <div>
            <TopPart>
                
                {/* <Offline>
                <ConnectionBar>
                    Your connection is offline or unstable. Your progress might get lost.
                </ConnectionBar>
                </Offline>
                <Online>
                </Online> */}
                
                <StyledLink to="/">
                    <Logo>
                        <img alt="Japanese Shinto Shrine Torii as Japanible Logo" src={"https://japanible.com/shinto-shrine.png"} style={{maxHeight: "32px"}}></img> Japanible</Logo>
                        </StyledLink>

                {user?.displayName
                        ?
                        <Admin>
                           
                            <StyledLink to="/account">
                                <AccountBox>
                                    <ProfilePicture src={user.photoURL} />
                                </AccountBox>
                                </StyledLink>
                                {'　'}
                                <NavLink style={({ isActive }) =>  isActive ?
                                {textDecoration: "none", backgroundColor: "white", border: "solid red 3px", color: "red", borderRadius: "3px", padding: "6px"} :
                                {textDecoration: "red solid underline", backgroundColor: "red", color: "white", borderRadius: "3px", padding: "9px"}} to={'/user/'+user.uid+'/drafts/'+uuid()}>Write Article ✍ </NavLink>
                                {/* {'　'} */}
                                {/* <NavLink style={({ isActive }) =>  isActive ?
                                {textDecoration: "none", backgroundColor: "white", border: "solid red 3px", color: "red", borderRadius: "3px", padding: "6px"} :
                                {textDecoration: "red solid underline", backgroundColor: "red", color: "white", borderRadius: "3px", padding: "9px"}} to="/learn">Study Cards 🎴</NavLink> */}
                                {/* <BiMenu onClick={()=>{setIsToggled(!isToggled)}}/> */}
                        </Admin>
                        :
                    <StyledLink to="/signin">
                        Sign In
                    </StyledLink>}

            </TopPart>
            
            {isToggled && <MenuBar>
            <NavLink style={style} to="/originals"><li>Read All Articles</li></NavLink>
                <NavLink style={style} to="/culture"><li>Culture</li></NavLink>
                <NavLink style={style} to="/travel"><li>Travel</li></NavLink>
                <NavLink style={style} to="/food"><li>Food</li></NavLink>
                <NavLink style={style} to="/craft-art"><li>Craft & Art</li></NavLink>
                <NavLink style={style} to="/language"><li>Language</li></NavLink>
                {/* <NavLink style={style} to="/news"><li>News 🔗</li></NavLink> */}
                {/* <NavLink style={style} to="/videos"><li>Videos</li></NavLink> */}
                {/* <NavLink style={style} to="/jobs"><li>Jobs in Japan</li></NavLink> */}
                
                
               
            </MenuBar>}
            <Cookies/>
        </div>
    )
}

export default TopBar