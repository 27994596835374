import React, { useEffect, useState } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../Firebase/firebase";
import styled from "styled-components";
import { UserAuth } from "../context/AuthContext";
import { doc, deleteDoc } from "firebase/firestore";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import {
  BsTwitter,
  BsFacebook,
  BsLine,
  BsReddit,
  BsTelegram,
  BsWhatsapp,
  BsLinkedin,
  BsLink45Deg,
  BsBookmarkPlusFill,
  BsHeart,
} from "react-icons/bs";

import {FiPocket} from 'react-icons/fi'
import parser from "html-react-parser";
import { Helmet } from "react-helmet";
import LoadingPlaceholderItem, {LoadingPlaceholderItemRound} from "../components/LoadingPlaceholderItem";

import ReactTooltip from "react-tooltip";
import { devices } from "../styles/mediaQueries";

import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";

const WeighedParagraphs = styled.div`
line-height: 1.6;
max-width: 800px;
margin: 0px auto;}`;

const HeroImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 70vh;
  border-radius: 3px;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(121, 9, 9, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
`;

const AdminPanel = styled.div`
  background-color: #f1f1f1;
  padding: 18px;
  padding-top: 0px;
  border-radius: 3px;
  margin: 0px auto;
  margin-bottom: 32px;
  position: absolute;

  @media ${devices.mobileS} {
    position: relative;
    max-width: 800px;
    padding-top: 3px;
  }

  @media ${devices.laptopXL} {
    position: absolute;
    width: 300px;
  }

  button {
    margin-bottom: 6px;
  }
`;

const FormRow = styled.div`
  margin: 20px 0px;
`;

const FormTextArea = styled.textarea`
  margin-top: 5px;
  width: 100%;
  height: 50px;
  overflow-y: scroll;
  resize: none;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
`;

const CTA = styled.button`
  background-color: red;
  color: white;
  border-radius: 3px;
  border: transparent 0px solid;
  padding: 9px;
  font-size: 16px;
  cursor: pointer;
  &:disabled {
    background-color: lightgray;
    pointer-events: none;
  }
`;

const CommentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  margin: 0px auto;
  button {
    margin-top: 6px;
  }
`;

const SingleComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 21px;

  div {
    display: flex;
    flex-direction: column;
    div {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;
const IconBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  

  div {
    display: flex;
    
  }

  div > div {
    border: 1px solid lightgray;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    width: 42px;
    height: 42px;
  }
`;

const LIKEBUTTON = styled.div`
color: ${props => props.included ? "red" : "black"};
`

const IconBarWrapperActive = styled(IconBarWrapper)`
div > div {
  cursor: pointer;
  

  &:hover {
    color: red;
    width: 40px;
    height: 40px;
    border: 2px solid red;
  }
  &:disabled {
    pointer-events: none;
  opacity: 0.5}
}
`

const FullArticlePage = () => {
  const { user } = UserAuth();
  const { slug } = useParams();

  const [postLikedBy, setPostLikedBy] = useState([])

  const [post, setPost] = useState({
    title: "Looking for the post in the database.",
    description:
      "If it takes too long, then the post doesn't exist or the connection is unstable, sorry.",
    slug: null,
    author: null,
    urlToImage: null,
    publishedAt: null,
    body: null,
  });
  const [postFullText, setPostFullText] = useState("");
  const [fetchedUser, setFetchedUser] = useState([]);
  const [renderedComment, setRenderedComment] = useState({});
  const [dataLoadingState, setDataLoadingState] = useState("loading");

  const retrievedPostId = [];

  //Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Get username from users collection
  const getUserById = async () => {
    const docSnap = await getDoc(doc(db, "users", post.author));
    if (docSnap.exists()) {
      await setFetchedUser(docSnap.data());
    } else {
      console.log("No such document!");
    }
  };
  useEffect(() => {
    getUserById();
  }, [post]);

  // Get Post ID for deleting
  const getPostId = async () => {
    const q = query(collection(db, "posts"), where("slug", "==", slug));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      retrievedPostId.push(doc.id);
    });
  };
  getPostId();

  const getPost = async () => {
    try {
      const currentPost = await getDocs(
        query(collection(db, "posts"), where("slug", "==", slug))
      );
      currentPost.forEach((doc) => {
        setPost(doc.data());
        setPostLikedBy(post.likes)
        setDataLoadingState("loaded");
      });
    } catch (error) {
      setDataLoadingState("error");
    }
  };

  // Get content of the post by quering by slug
  useEffect(() => {
    getPost();
  }, []);

  const AskToDeleteThisPost = () => {
    confirmAlert({
      title: '"' + post.title + '"' + " will be deleted.",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "No, keep the article.",
          onClick: () => {
            return null;
          },
        },
        {
          label: "Yes, delete the article.",
          onClick: () => DeleteThisPost(),
        },
      ],
    });
  };

  const InfoPostDeletedMessage = () => {
    confirmAlert({
      title: 'Your post was deleted successfully.',
      message: "You will be redirected to the top page now.",
      buttons: [
        {
          label: "I understand.",
          onClick: () => {return null},
        },
      ],
    });
  };

  // Delete post
  const DeleteThisPost = async () => {
    await deleteDoc(doc(db, "posts", retrievedPostId[0]));
    InfoPostDeletedMessage()
    window.location.href = "https://japanible.com/originals/";
  };

  //Like post or unlike post
  //  const likePost = async () => {
  //     const likePostRef = doc(db, "posts", (retrievedPostId[0]))
  //     if (post.likes === undefined || post.likes.indexOf(user.uid) === -1) {
  //     await updateDoc(likePostRef, {
  //       likes: arrayUnion(user.uid)
  //   })} else {
  //     await updateDoc(likePostRef, {
  //       likes: arrayRemove(user.uid)
  //   })
  //   }
  //  }

  //Post Comment and Delete Comment
  const postComment = async () => {
    const likePostRef = doc(db, "posts", retrievedPostId[0]);
    if (postFullText !== "") {
      await updateDoc(likePostRef, {
        comments: arrayUnion({
          author: user.uid,
          userPicture: user.photoURL,
          text: postFullText,
          date: new Date(),
        }),
      });
      setRenderedComment({
        author: user.uid,
        userPicture: user.photoURL,
        text: postFullText,
        date: new Date(),
      });
      setPostFullText("");
      getPost();
    } else {
      alert("Nothing to post.");
    }
  };

  const deleteComment = async (e) => {
    const likePostRef = doc(db, "posts", retrievedPostId[0]);
    await updateDoc(likePostRef, {
      comments: arrayRemove(e),
    });
    getPost();
  };

  const [fetchedUsersDictionary, setFetchedUsersDictionary] = useState(
    []
    )

  const getCommentUserById = async (REF) => {
    const LOOKINGFORID = REF;
    const RESULT = fetchedUsersDictionary.find(o => o.useruid === LOOKINGFORID)
    if (RESULT) {console.log('Already added.');
    // console.log(fetchedUsersDictionary)
  }
    if (RESULT === undefined) {
      const docSnap = await getDoc(doc(db, "users", REF))
    if (docSnap.exists()) {
      const DATASAFE = await docSnap.data()
      const newValue = {
        useruid: LOOKINGFORID,
        username: DATASAFE.userName || 'Usernameless User',
        photoURL: DATASAFE.photoURL
        }
      await setFetchedUsersDictionary([...fetchedUsersDictionary, newValue])
      // console.log(fetchedUsersDictionary)
      // console.log(fetchedUsersDictionary)
      } else {
        console.log('Error')
    }}
    }

const [likeButtonPressed, setLikeButtonPressed] = useState(false)

const handleLike = async () => {
  if (!user) return;
  setLikeButtonPressed(true)
  await getPostId();
  const likePostRef = doc(db, "posts", retrievedPostId[0]);
  // console.log(post.likes)
  
  const alreadyLiked = ((post.likes !== undefined && post.likes.indexOf(user.uid) > -1) || false)

  if (alreadyLiked) {
      await updateDoc(likePostRef, {
      likes: arrayRemove(user.uid),
    });
    getPost();
    setLikeButtonPressed(false);
  }
  if (!alreadyLiked) {
  await updateDoc(likePostRef, {
      likes: arrayUnion(user.uid),
    });
    getPost();
    setLikeButtonPressed(false);
  }
  //   // setPostLikedBy(postLikedBy.filter((e)=>!(e===user.uid)));
  //   // setPostLikedBy([...postLikedBy, user.uid])
}

  if (dataLoadingState === "loading")
    return (
      <>
        <WeighedParagraphs>
          <LoadingPlaceholderItem
            bottom={"24px"}
            width={"80%"}
            height={"50px"}
          />
          <IconBarWrapper>
            <div>
              <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          </div>
            <div>
              <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          
          <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          <LoadingPlaceholderItemRound
          bottom={"24px"}
          width={"42px"}
          height={"42px"}
          />
          </div>
          </IconBarWrapper>
          
          {/* <h1>Loading.</h1> */}
          <LoadingPlaceholderItem
            bottom={"24px"}
            width={"90%"}
            height={"100px"}
          />
          <LoadingPlaceholderItem
            bottom={"24px"}
            width={"100%"}
            height={"660px"}
          />
          <LoadingPlaceholderItem
            bottom={"24px"}
            width={"100%"}
            height={"100px"}
          />
          <LoadingPlaceholderItem
            bottom={"24px"}
            width={"100%"}
            height={"50px"}
          />
          <LoadingPlaceholderItem
            bottom={"24px"}
            width={"100%"}
            height={"100px"}
          />
        </WeighedParagraphs>
      </>
    );

  if (dataLoadingState === "loaded")
    return (
      <div>
        <Helmet>
        <title>{post.title} | Japanible</title>
        <meta name="description" content={post.description || "Japanible is a platform for people who love Japan. Sharing Awesomeness from Japan. Writing articles about Japan, japanese culture and language."} />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="index, follow"/>
        <link rel="canonical" href={"https://japanible.com/originals/"+post.slug}/>

        <meta property="og:title" content={post && post.title + " | Japanible"}/>
        <meta property="og:description" content={post.description || "Japanible is a platform for people who love Japan. Sharing Awesomeness from Japan. Writing articles about Japan, japanese culture and language."}/>
        <meta property="og:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta property="og:url" content={"https://japanible.com/originals/"+post.slug}/>
        <meta property="og:type" content="website"/>

        <meta name="twitter:title" content={post && post.title + " | Japanible"}/>
        <meta name="twitter:description" content={post.description || "Japanible is a platform for people who love Japan. Sharing Awesomeness from Japan. Writing articles about Japan, japanese culture and language."}/>
        <meta name="twitter:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@japanible"/>

        <meta name="author" content="Japanible"/>

      </Helmet>


    {dataLoadingState === "loaded" ? <script> window.prerenderReady=true</script> : <script> window.prerenderReady=false</script>}

        {user && post.author === user.uid && (
          <AdminPanel>
            <h2>Creator's dashboard</h2>
            <h3>Active Hashtags</h3>
            {post.hashtags
              ? post.hashtags.map((e) => (
                  <span style={{ marginRight: 3 }}>#{e}</span>
                ))
              : ""}
            <h3>Published On</h3>

            <div>{Date(post.publishedAt).toString().substring(0, 15)}</div>
            <h3>Updated On</h3>
            <div>
              {" "}
              {post.updatedAt
                ? Date(post.updatedAt).toString().substring(0, 15)
                : "Original without updates."}
            </div>

            <h3>Creator's Tools</h3>
            {fetchedUsersDictionary["3YXVhOAkq1ZEz6OcgaDV2IUvffn2"]}
            <Link to="./edit">
              <CTA style={{ backgroundColor: "black", marginRight: "9px" }}>
                Edit Article
              </CTA>
            </Link>
            <Link to="./edit">
              <CTA style={{ backgroundColor: "black", marginRight: "9px" }}>
                Unpublish and Move to Drafts
              </CTA>
            </Link>
            <CTA onClick={AskToDeleteThisPost}>Fully Delete Article</CTA>
          </AdminPanel>
        )}

        <WeighedParagraphs>
          <h1 style={{ wordBreak: "break-word" }}>{post.title}</h1>
          <IconBarWrapperActive>
            <div>
              <LIKEBUTTON data-tip="Like" onClick={handleLike} included={user && post.likes?.indexOf(user?.uid) > -1}>
                <BsHeart style={{marginRight: 3}}/> {post.likes ? post.likes.length : '0'}
              </LIKEBUTTON>

              {/* <div data-tip="Save to my list">
                <BsBookmarkPlusFill />
              </div> */}

            </div>
            <div>
              <div data-tip="Share to Facebook">
                <FacebookShareButton
                  url={"https://japanible.com/originals/" + slug}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsFacebook />
                </FacebookShareButton>
              </div>
              <div data-tip="Share to Twitter">
                <TwitterShareButton
                  url={"https://japanible.com/originals/" + slug}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsTwitter />
                </TwitterShareButton>
              </div>

              <div data-tip="Share to Linkedin">
                <LinkedinShareButton
                  url={"https://japanible.com/originals/" + slug}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsLinkedin />
                </LinkedinShareButton>
              </div>

              <div data-tip="Save to Pocket">
                <PocketShareButton
                  url={"https://japanible.com/originals/" + slug}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FiPocket/>
                </PocketShareButton>
              </div>
              

              <div data-tip="Share via Line">
                <LineShareButton
                  url={"https://japanible.com/originals/" + slug}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsLine />
                </LineShareButton>
              </div>

              <div data-tip="Share via Reddit">
                <RedditShareButton
                  url={"https://japanible.com/originals/" + slug}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsReddit />
                </RedditShareButton>
              </div>

              <div data-tip="Share via WhatsApp">
                <WhatsappShareButton
                  url={"https://japanible.com/originals/" + slug}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsWhatsapp />
                </WhatsappShareButton>
              </div>

              <div data-tip="Share via Telegram">
                <TelegramShareButton
                  url={"https://japanible.com/originals/" + slug}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsTelegram />
                </TelegramShareButton>
              </div>

              {/* <div data-tip="Copy the article address" onClick={()=>{
                  const url = 'https://japanible.com/originals/' + slug
                  function setClipboard(url) {
                    const type = "text/plain";
                    const blob = new Blob([url], { type });
                    const data = [new ClipboardItem({ [type]: blob })];
                
                    navigator.clipboard.write(data).then(
                        () => {alert('Copied.')},
                        () => {}
                    );
                }
                }}><BsLink45Deg/></div> */}
            </div>
          </IconBarWrapperActive>
          <ReactTooltip />
          <p>
            Published by{" "}
            <Link to={"/user/" + post.author}>
              {fetchedUser.userName && fetchedUser.userName
                ? fetchedUser.userName
                : fetchedUser.fullName}
            </Link>{" "}
            on {Date(post.publishedAt).toString().substring(0, 15)}
            {/* {post.updatedAt ? ', updated on ' + Date(post.updatedAt).toString().substring(0, 15) : '' } */}
          </p>

          <div
            style={{ marginBottom: "32px", wordBreak: "break-word" }}
            children={post.description.toString()}
          ></div>
          <HeroImage src={post.urlToImage} alt={post?.title} srcSet="" />
          <div style={{ wordBreak: "break-word" }}>
            {post.body && parser(post.body)}
          </div>
        </WeighedParagraphs>

        <div>
          <hr
            style={{
              marginTop: 50,
              marginBottom: 50,
              border: "#ececec 1px solid",
            }}
          />

          <CommentSection>
            <h2>Comments</h2>
            {user ? (
              <FormRow>
                <label htmlFor="">Your Message</label>
                <FormTextArea
                  placeholder="Share you thoughts!"
                  type="text"
                  onChange={(event) => {
                    setPostFullText(event.target.value);
                  }}
                  value={postFullText}
                />
                <CTA onClick={postComment} disabled={!postFullText}>
                  Post the comment
                </CTA>
              </FormRow>
            ): ''}

            <p style={{marginBottom: 32}}>{!post.comments && user ? (
              "Be the first one to write a comment!"
            ) : (
              <></>
            )}
            {!user ? "Login to leave a comment!" : <></>}</p>

            {post.comments &&
              post.comments.map((e, i) => {
                return (
                  <>
                  
                    <SingleComment key={`comment-${i}`}>
                      <div>
                        {e.userPicture && (
                          <img
                            style={{
                              borderRadius: "500px",
                              maxWidth: "48px",
                              marginRight: "8px",
                            }}
                            src={e.userPicture}
                            alt={`${i} Profile Picture`}
                            srcSet=""
                          />
                        )}
                      </div>
                      
                      <div style={{ width: "100%" }} onBeforeInput={getCommentUserById(e.author)}>
                        
                                {!fetchedUsersDictionary.find(o => o.useruid === e.author) ? 
                                          <div style={{ width: "400px" }}>
                                            <LoadingPlaceholderItem width="10px" height="19px" style={{maxWidth: '10px', marginRight: 'auto'}}/>
                                        </div> 
                                        :
                                         <div style={{ width: "100%", flexDirection: "row" }}>
                                          <strong style={{ marginRight: 6 }}>
                                            
                                            <Link to={"/user/" + e.author}>
                                                                 
                                              {fetchedUsersDictionary.find(o => o.useruid === e.author) 
                                              ? fetchedUsersDictionary.find(o => o.useruid === e.author).username :
                                              e.author}
                                              
                                            </Link>

                                          </strong>{" "}
                                          {new Date(e.date.seconds * 1000).toDateString()}
                                          </div>}
                        

                        <div>{e.text}</div>
                      </div>
                      <div>
                        {user && e.author === user.uid ? (
                          <CTA
                            onClick={() => {
                              deleteComment(e);
                            }}
                          >
                            Remove Your Comment
                          </CTA>
                        ) : (
                          ""
                        )}
                      </div>
                    </SingleComment>
                  </>
                );
              })}
          </CommentSection>

          <div></div>
        </div>
      </div>
    );
};

export default FullArticlePage;
