import React from 'react'
import styled from 'styled-components'

const FormRow = styled.div`
    margin: 20px 0px;`

  const FormTextArea = styled.textarea`
    margin-top: 5px;
    width: 100%;
    height: 100px;
    overflow-y: scroll;
    resize: none;
    font-size: 16px;
    padding: 5px 5px;
    `
  const FormInput = styled.input`
    margin-top: 5px;
    padding: 5px 5px;
    width: 100%;
    font-size: 16px;`

  const CTA = styled.button`
    background-color: red;
    color: white;
    border-radius: 3px;
    border: transparent 0px solid;
    padding: 9px;
    font-size: 16px;`

  const Highlight = styled.div`
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 3px;
    text-align: center;`

    const UploadPhotoFormRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      background-color: red;
      color: white;
      border-radius: 3px;
      border: transparent 0px solid;
      font-size: 16px;
      margin-left: 9px;
    }`

const PostJobOffer = () => {

  return (
    <div>
      <h1>Post Job Offer</h1>
      <FormRow>
      <label htmlFor="jobTitle">Job Title</label>
      <FormInput type="text" name="jobTitle" id="jobTitle" />
      </FormRow>
      <FormRow>
      <label htmlFor="jobDescription">Job Description</label>
      <FormInput type="text" name="jobDescription" id="jobDescription" />
      </FormRow>
      <FormRow>
      <label htmlFor="jobUrl">Link for more information</label>
      <FormInput type="text" name="jobUrl" id="jobUrl" />
      </FormRow>

      <FormRow>
      <label htmlFor="date">Valid Until Date</label>
      <FormInput type="text" name="date" id="date" />
      </FormRow>

      <FormRow>
      <label htmlFor="jobPlace">Location</label>
      <FormInput type="text" name="jobPlace" id="jobPlace" />
      </FormRow>
      <FormRow>
      <label htmlFor="jobTags">Tags</label>
      <FormInput type="text" name="jobTags" id="jobTags" />
      </FormRow>
      <FormRow>
      <label htmlFor="jobSuitedFor">What level of fluency is needed?</label>
      <FormInput type="text" name="jobSuitedFor" id="jobSuitedFor" />
      </FormRow>
      <FormRow>
      <label htmlFor="otherRequirements">otherRequirements</label>
      <FormInput type="text" name="otherRequirements" id="otherRequirements" />
      </FormRow>
      <FormRow>
      <label htmlFor="jobVisaSupport">Does the employer support visas?</label>
      <FormInput type="text" name="jobVisaSupport" id="jobVisaSupport" />
      </FormRow>

      <Highlight>
      <FormRow>
          <input type="checkbox" name="author" id="author" />
          <label htmlFor="author">These details are valid.</label>
        </FormRow>
      <CTA>Send</CTA>
      </Highlight>

    </div>
  )
}

export default PostJobOffer