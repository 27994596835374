export const dataN5 = [
    {
      "id": 0,
      "kana": "ああ ",
      "kanji": "",
      "type": "int ",
      "meaning": "Ah!, Oh!"
    },
    {
      "id": 1,
      "kana": "あう ",
      "kanji": "会う ",
      "type": "u-v ",
      "meaning": "to meet, to see"
    },
    {
      "id": 2,
      "kana": "あお ",
      "kanji": "青 ",
      "type": "n ",
      "meaning": "blue"
    },
    {
      "id": 3,
      "kana": "あおい ",
      "kanji": "青い ",
      "type": "adj ",
      "meaning": "blue"
    },
    {
      "id": 4,
      "kana": "あか ",
      "kanji": "赤 ",
      "type": "n ",
      "meaning": "red"
    },
    {
      "id": 5,
      "kana": "あかい ",
      "kanji": "赤い ",
      "type": "adj ",
      "meaning": "red"
    },
    {
      "id": 6,
      "kana": "あかるい ",
      "kanji": "明るい ",
      "type": "adj ",
      "meaning": "bright, cheerful"
    },
    {
      "id": 7,
      "kana": "あき ",
      "kanji": "秋 ",
      "type": "n-adv ",
      "meaning": "autumn, fall"
    },
    {
      "id": 8,
      "kana": "あく ",
      "kanji": "開く ",
      "type": "u-v,vi ",
      "meaning": "to open, to become open"
    },
    {
      "id": 9,
      "kana": "あける ",
      "kanji": "開ける ",
      "type": "ru-v ",
      "meaning": "to open"
    },
    {
      "id": 10,
      "kana": "あげる ",
      "kanji": "上げる ",
      "type": "ru-v ",
      "meaning": "to give"
    },
    {
      "id": 11,
      "kana": "あさ ",
      "kanji": "朝 ",
      "type": "n-adv,n-t ",
      "meaning": "morning"
    },
    {
      "id": 12,
      "kana": "あさごはん ",
      "kanji": "朝御飯 ",
      "type": "n ",
      "meaning": "breakfast"
    },
    {
      "id": 13,
      "kana": "あさって ",
      "kanji": "",
      "type": "n-adv,n-t ",
      "meaning": "day after tomorrow"
    },
    {
      "id": 14,
      "kana": "あし ",
      "kanji": "足 ",
      "type": "n ",
      "meaning": "foot, leg"
    },
    {
      "id": 15,
      "kana": "あした ",
      "kanji": "",
      "type": "n-t ",
      "meaning": "tomorrow"
    },
    {
      "id": 16,
      "kana": "あそこ ",
      "kanji": "",
      "type": "n ",
      "meaning": "there, over there, that place"
    },
    {
      "id": 17,
      "kana": "あそぶ ",
      "kanji": "遊ぶ ",
      "type": "u-v ",
      "meaning": "to play, to enjoy oneself"
    },
    {
      "id": 18,
      "kana": "あたたかい ",
      "kanji": "暖かい ",
      "type": "adj ",
      "meaning": "warm, mild"
    },
    {
      "id": 19,
      "kana": "あたま ",
      "kanji": "頭 ",
      "type": "n ",
      "meaning": "head"
    },
    {
      "id": 20,
      "kana": "あたらしい ",
      "kanji": "新しい ",
      "type": "adj ",
      "meaning": "new"
    },
    {
      "id": 21,
      "kana": "あちら ",
      "kanji": "",
      "type": "n ",
      "meaning": "there, yonder, that"
    },
    {
      "id": 22,
      "kana": "あつい ",
      "kanji": "暑い ",
      "type": "adj ",
      "meaning": "hot, warm"
    },
    {
      "id": 23,
      "kana": "あつい ",
      "kanji": "熱い ",
      "type": "adj ",
      "meaning": "hot (thing)"
    },
    {
      "id": 24,
      "kana": "あつい ",
      "kanji": "厚い ",
      "type": "adj ",
      "meaning": "kind, warm(hearted), thick, deep"
    },
    {
      "id": 25,
      "kana": "あっち ",
      "kanji": "",
      "type": "n col ",
      "meaning": "over there"
    },
    {
      "id": 26,
      "kana": "あと ",
      "kanji": "後 ",
      "type": "adj-no,n ",
      "meaning": "afterwards, since then, in the future"
    },
    {
      "id": 27,
      "kana": "あなた ",
      "kanji": "",
      "type": "n ",
      "meaning": "you"
    },
    {
      "id": 28,
      "kana": "あに ",
      "kanji": "兄 ",
      "type": "n ",
      "meaning": "(hum) older brother"
    },
    {
      "id": 29,
      "kana": "あね ",
      "kanji": "姉 ",
      "type": "n ",
      "meaning": "(hum) older sister"
    },
    {
      "id": 30,
      "kana": "あの ",
      "kanji": "",
      "type": "pren-adj ",
      "meaning": "that over there"
    },
    {
      "id": 31,
      "kana": "あの ",
      "kanji": "",
      "type": "int ",
      "meaning": "um..."
    },
    {
      "id": 32,
      "kana": "アパート ",
      "kanji": "",
      "type": "n,adv ",
      "meaning": "apartment (abbr)"
    },
    {
      "id": 33,
      "kana": "あびる ",
      "kanji": "",
      "type": "ru-v ",
      "meaning": "to bathe, to shower"
    },
    {
      "id": 34,
      "kana": "あぶない ",
      "kanji": "危ない ",
      "type": "adj ",
      "meaning": "dangerous, critical, watch out!"
    },
    {
      "id": 35,
      "kana": "あまい ",
      "kanji": "甘い ",
      "type": "adj,expr ",
      "meaning": "generous, sweet"
    },
    {
      "id": 36,
      "kana": "あまり ",
      "kanji": "",
      "type": "na-adj,adv,n,n-suf ",
      "meaning": "not very, not much"
    },
    {
      "id": 37,
      "kana": "あめ ",
      "kanji": "雨 ",
      "type": "n ",
      "meaning": "rain"
    },
    {
      "id": 38,
      "kana": "あめ ",
      "kanji": "飴 ",
      "type": "n ",
      "meaning": "(hard) candy, toffee"
    },
    {
      "id": 39,
      "kana": "あらう ",
      "kanji": "洗う ",
      "type": "u-v ",
      "meaning": "to wash"
    },
    {
      "id": 40,
      "kana": "ある [存在] ",
      "kanji": "",
      "type": "u-v-i ",
      "meaning": "to be"
    },
    {
      "id": 41,
      "kana": "ある [所有] ",
      "kanji": "",
      "type": "u-v-i ",
      "meaning": "to have"
    },
    {
      "id": 42,
      "kana": "あるく ",
      "kanji": "歩く ",
      "type": "u-v ",
      "meaning": "to walk"
    },
    {
      "id": 43,
      "kana": "あれ ",
      "kanji": "",
      "type": "int,n ",
      "meaning": "that, that thing"
    },
    {
      "id": 44,
      "kana": "いい/よい ",
      "kanji": "",
      "type": "adj ",
      "meaning": "good"
    },
    {
      "id": 45,
      "kana": "いいえ ",
      "kanji": "",
      "type": "int,n ",
      "meaning": "no, not at all"
    },
    {
      "id": 46,
      "kana": "いう ",
      "kanji": "言う ",
      "type": "u-v ",
      "meaning": "to say"
    },
    {
      "id": 47,
      "kana": "いえ ",
      "kanji": "家 ",
      "type": "suf ",
      "meaning": "house, family"
    },
    {
      "id": 48,
      "kana": "いかが ",
      "kanji": "",
      "type": "na-adj,adv,n ",
      "meaning": "how, in what way"
    },
    {
      "id": 49,
      "kana": "いく/ゆく ",
      "kanji": "行く ",
      "type": "u-v ",
      "meaning": "to go"
    },
    {
      "id": 50,
      "kana": "いくつ ",
      "kanji": "",
      "type": "n ",
      "meaning": "how many?, how old?"
    },
    {
      "id": 51,
      "kana": "いくら ",
      "kanji": "",
      "type": "adv,n ",
      "meaning": "how much?, how many?"
    },
    {
      "id": 52,
      "kana": "いけ ",
      "kanji": "池 ",
      "type": "n ",
      "meaning": "pond"
    },
    {
      "id": 53,
      "kana": "いしゃ ",
      "kanji": "医者 ",
      "type": "n ",
      "meaning": "doctor (medical)"
    },
    {
      "id": 54,
      "kana": "いす ",
      "kanji": "",
      "type": "n ",
      "meaning": "chair"
    },
    {
      "id": 55,
      "kana": "いそがしい ",
      "kanji": "忙しい ",
      "type": "adj ",
      "meaning": "busy, irritated"
    },
    {
      "id": 56,
      "kana": "いたい ",
      "kanji": "痛い ",
      "type": "adj ",
      "meaning": "painful"
    },
    {
      "id": 57,
      "kana": "いち ",
      "kanji": "一 ",
      "type": "num ",
      "meaning": "one"
    },
    {
      "id": 58,
      "kana": "いちにち ",
      "kanji": "一日 ",
      "type": "n ",
      "meaning": "1 day (duration)"
    },
    {
      "id": 59,
      "kana": "いちばん ",
      "kanji": "",
      "type": "n-adv ",
      "meaning": "best, first, number one"
    },
    {
      "id": 60,
      "kana": "いつ ",
      "kanji": "",
      "type": "gn-adv ",
      "meaning": "when"
    },
    {
      "id": 61,
      "kana": "いつか ",
      "kanji": "五日 ",
      "type": "n ",
      "meaning": "five days, the fifth day (of the month)"
    },
    {
      "id": 62,
      "kana": "いっしょ ",
      "kanji": "一緒 ",
      "type": "adv,n ",
      "meaning": "together"
    },
    {
      "id": 63,
      "kana": "いつつ ",
      "kanji": "五つ ",
      "type": "n ",
      "meaning": "five"
    },
    {
      "id": 64,
      "kana": "いつも ",
      "kanji": "",
      "type": "adv,n ",
      "meaning": "always, every time"
    },
    {
      "id": 65,
      "kana": "いぬ ",
      "kanji": "犬 ",
      "type": "",
      "meaning": "dog"
    },
    {
      "id": 66,
      "kana": "いま ",
      "kanji": "今 ",
      "type": "",
      "meaning": "now"
    },
    {
      "id": 67,
      "kana": "いみ ",
      "kanji": "意味 ",
      "type": "n,vs ",
      "meaning": "meaning"
    },
    {
      "id": 68,
      "kana": "いもうと ",
      "kanji": "妹 ",
      "type": "n ",
      "meaning": "younger sister"
    },
    {
      "id": 69,
      "kana": "いや ",
      "kanji": "嫌 ",
      "type": "na-adj,n ",
      "meaning": "disagreeable, no"
    },
    {
      "id": 70,
      "kana": "いりぐち ",
      "kanji": "入口 ",
      "type": "n ",
      "meaning": "entrance, gate"
    },
    {
      "id": 71,
      "kana": "いる ",
      "kanji": "居る ",
      "type": "u-v ",
      "meaning": "(hum) to be (animate), to exist"
    },
    {
      "id": 72,
      "kana": "いる ",
      "kanji": "要る ",
      "type": "u-v ",
      "meaning": "to need"
    },
    {
      "id": 73,
      "kana": "いれる ",
      "kanji": "入れる ",
      "type": "ru-v ",
      "meaning": "to put in"
    },
    {
      "id": 74,
      "kana": "いろ ",
      "kanji": "色 ",
      "type": "n ",
      "meaning": "colour"
    },
    {
      "id": 75,
      "kana": "いろいろ ",
      "kanji": "",
      "type": "na-adj,adj-no,adv,n ",
      "meaning": "various"
    },
    {
      "id": 76,
      "kana": "うえ ",
      "kanji": "上 ",
      "type": "no-adj,n-adv,n,n-suf ",
      "meaning": "above, on top of"
    },
    {
      "id": 77,
      "kana": "うしろ ",
      "kanji": "後ろ ",
      "type": "n ",
      "meaning": "behind, rear"
    },
    {
      "id": 78,
      "kana": "うすい ",
      "kanji": "薄い ",
      "type": "adj ",
      "meaning": "thin, weak"
    },
    {
      "id": 79,
      "kana": "うた ",
      "kanji": "歌 ",
      "type": "n ",
      "meaning": "song"
    },
    {
      "id": 80,
      "kana": "うたう ",
      "kanji": "歌う ",
      "type": "u-v ",
      "meaning": "to sing"
    },
    {
      "id": 81,
      "kana": "うち ",
      "kanji": "",
      "type": "n ",
      "meaning": "house (one's own)"
    },
    {
      "id": 82,
      "kana": "うまれる ",
      "kanji": "生まれる ",
      "type": "ru-v ",
      "meaning": "to be born"
    },
    {
      "id": 83,
      "kana": "うみ ",
      "kanji": "海 ",
      "type": "n ",
      "meaning": "sea, beach"
    },
    {
      "id": 84,
      "kana": "うる ",
      "kanji": "売る ",
      "type": "u-v ",
      "meaning": "to sell"
    },
    {
      "id": 85,
      "kana": "うるさい ",
      "kanji": "",
      "type": "adj ",
      "meaning": "noisy, loud, annoying"
    },
    {
      "id": 86,
      "kana": "うわぎ ",
      "kanji": "上着 ",
      "type": "n ",
      "meaning": "coat, jacket"
    },
    {
      "id": 87,
      "kana": "え ",
      "kanji": "絵 ",
      "type": "n,n-suf ",
      "meaning": "picture, drawing, painting, sketch"
    },
    {
      "id": 88,
      "kana": "えいが ",
      "kanji": "映画 ",
      "type": "n ",
      "meaning": "movie, film"
    },
    {
      "id": 89,
      "kana": "えいがかん ",
      "kanji": "映画館 ",
      "type": "n ",
      "meaning": "movie theatre (theater), cinema"
    },
    {
      "id": 90,
      "kana": "えいご ",
      "kanji": "英語 ",
      "type": "n ",
      "meaning": "the English language"
    },
    {
      "id": 91,
      "kana": "ええ ",
      "kanji": "",
      "type": "conj,int,n ",
      "meaning": "yes"
    },
    {
      "id": 92,
      "kana": "えき ",
      "kanji": "駅 ",
      "type": "n ",
      "meaning": "station"
    },
    {
      "id": 93,
      "kana": "エレベーター ",
      "kanji": "",
      "type": "n ",
      "meaning": "elevator"
    },
    {
      "id": 94,
      "kana": "～えん ",
      "kanji": "～円 ",
      "type": "n ",
      "meaning": "yen (currency)"
    },
    {
      "id": 95,
      "kana": "えんぴつ ",
      "kanji": "鉛筆 ",
      "type": "n ",
      "meaning": "pencil"
    },
    {
      "id": 96,
      "kana": "お～ ",
      "kanji": "",
      "type": "prefix ",
      "meaning": "honourable ～ (honorific)"
    },
    {
      "id": 97,
      "kana": "おいしい ",
      "kanji": "",
      "type": "adj ",
      "meaning": "delicious, tasty"
    },
    {
      "id": 98,
      "kana": "おおい ",
      "kanji": "多い ",
      "type": "adj ",
      "meaning": "many"
    },
    {
      "id": 99,
      "kana": "おおきい ",
      "kanji": "大きい ",
      "type": "adj ",
      "meaning": "big"
    },
    {
      "id": 100,
      "kana": "おおきな ",
      "kanji": "大きな ",
      "type": "na-adj ",
      "meaning": "big"
    },
    {
      "id": 101,
      "kana": "おおぜい ",
      "kanji": "大勢 ",
      "type": "n ",
      "meaning": "great number of people"
    },
    {
      "id": 102,
      "kana": "おかあさん ",
      "kanji": "お母さん ",
      "type": "n ",
      "meaning": "(hon) mother"
    },
    {
      "id": 103,
      "kana": "おかし ",
      "kanji": "お菓子 ",
      "type": "n ",
      "meaning": "confections, sweets, candy"
    },
    {
      "id": 104,
      "kana": "おかね ",
      "kanji": "お金 ",
      "type": "n ",
      "meaning": "money"
    },
    {
      "id": 105,
      "kana": "おきる ",
      "kanji": "起きる ",
      "type": "ru-v ",
      "meaning": "to get up, to rise"
    },
    {
      "id": 106,
      "kana": "おく ",
      "kanji": "置く ",
      "type": "u-v ",
      "meaning": "to put, to place"
    },
    {
      "id": 107,
      "kana": "おくさん ",
      "kanji": "奥さん ",
      "type": "n ",
      "meaning": "(hon) wife"
    },
    {
      "id": 108,
      "kana": "おさけ ",
      "kanji": "お酒 ",
      "type": "n ",
      "meaning": "alcohol, sake (rice wine)"
    },
    {
      "id": 109,
      "kana": "おさら ",
      "kanji": "お皿 ",
      "type": "n ",
      "meaning": "plate, dish"
    },
    {
      "id": 110,
      "kana": "おじ・さん ",
      "kanji": "伯父/叔父・さん ",
      "type": "n ",
      "meaning": "uncle, middle aged man"
    },
    {
      "id": 111,
      "kana": "おじいさん ",
      "kanji": "",
      "type": "n ",
      "meaning": "grandfather, male senior citizen"
    },
    {
      "id": 112,
      "kana": "おしえる ",
      "kanji": "教える ",
      "type": "ru-v ",
      "meaning": "to teach, to inform"
    },
    {
      "id": 113,
      "kana": "おす ",
      "kanji": "押す ",
      "type": "u-v,vt ",
      "meaning": "to push, to press"
    },
    {
      "id": 114,
      "kana": "おそい ",
      "kanji": "遅い ",
      "type": "adj ",
      "meaning": "late, slow"
    },
    {
      "id": 115,
      "kana": "おちゃ ",
      "kanji": "お茶 ",
      "type": "n ",
      "meaning": "tea (green)"
    },
    {
      "id": 116,
      "kana": "おてあらい ",
      "kanji": "お手洗い ",
      "type": "",
      "meaning": "toilet, lavatory, bathroom"
    },
    {
      "id": 117,
      "kana": "おとうさん ",
      "kanji": "お父さん ",
      "type": "n ",
      "meaning": "(hon) father"
    },
    {
      "id": 118,
      "kana": "おとうと ",
      "kanji": "弟 ",
      "type": "n ",
      "meaning": "younger brother"
    },
    {
      "id": 119,
      "kana": "おとこ ",
      "kanji": "男 ",
      "type": "n ",
      "meaning": "man"
    },
    {
      "id": 120,
      "kana": "おとこのこ ",
      "kanji": "男の子 ",
      "type": "n ",
      "meaning": "boy"
    },
    {
      "id": 121,
      "kana": "おととい ",
      "kanji": "",
      "type": "n-adv,n-t ",
      "meaning": "day before yesterday"
    },
    {
      "id": 122,
      "kana": "おととし ",
      "kanji": "",
      "type": "n-adv,n-t ",
      "meaning": "year before last"
    },
    {
      "id": 123,
      "kana": "おとな ",
      "kanji": "大人 ",
      "type": "n ",
      "meaning": "adult"
    },
    {
      "id": 124,
      "kana": "おなか ",
      "kanji": "",
      "type": "n ",
      "meaning": "stomach"
    },
    {
      "id": 125,
      "kana": "おなじ ",
      "kanji": "同じ ",
      "type": "na-adj,n ",
      "meaning": "same, identical, similar"
    },
    {
      "id": 126,
      "kana": "おにいさん ",
      "kanji": "お兄さん ",
      "type": "n ",
      "meaning": "(hon) older brother"
    },
    {
      "id": 127,
      "kana": "おねえさん ",
      "kanji": "お姉さん ",
      "type": "n ",
      "meaning": "(hon) older sister"
    },
    {
      "id": 128,
      "kana": "おばさん ",
      "kanji": "伯母さん/叔母さん ",
      "type": "n ",
      "meaning": "aunt"
    },
    {
      "id": 129,
      "kana": "おばあさん ",
      "kanji": "",
      "type": "n ",
      "meaning": "grandmother, female senior-citizen"
    },
    {
      "id": 130,
      "kana": "おふろ ",
      "kanji": "お風呂 ",
      "type": "n ",
      "meaning": "bath"
    },
    {
      "id": 131,
      "kana": "おべんとう ",
      "kanji": "お弁当 ",
      "type": "n ",
      "meaning": "boxed lunch"
    },
    {
      "id": 132,
      "kana": "おぼえる ",
      "kanji": "覚える ",
      "type": "ru-v ",
      "meaning": "to remember, to memorize"
    },
    {
      "id": 133,
      "kana": "おまわりさん ",
      "kanji": "",
      "type": "n ",
      "meaning": "policeman (friendly term)"
    },
    {
      "id": 134,
      "kana": "おもい ",
      "kanji": "重い ",
      "type": "adj ",
      "meaning": "heavy"
    },
    {
      "id": 135,
      "kana": "おもしろい ",
      "kanji": "",
      "type": "adj ",
      "meaning": "interesting, amusing"
    },
    {
      "id": 136,
      "kana": "およぐ ",
      "kanji": "泳ぐ ",
      "type": "u-v ",
      "meaning": "to swim"
    },
    {
      "id": 137,
      "kana": "おりる ",
      "kanji": "降りる ",
      "type": "ru-v ",
      "meaning": "to alight (eg from bus), to get off"
    },
    {
      "id": 138,
      "kana": "おわる ",
      "kanji": "終る ",
      "type": "u-v ",
      "meaning": "to finish, to close"
    },
    {
      "id": 139,
      "kana": "おんがく ",
      "kanji": "音楽 ",
      "type": "n ",
      "meaning": "music"
    },
    {
      "id": 140,
      "kana": "おんな ",
      "kanji": "女 ",
      "type": "n ",
      "meaning": "woman, girl, daughter"
    },
    {
      "id": 141,
      "kana": "おんなのこ ",
      "kanji": "女の子 ",
      "type": "n ",
      "meaning": "girl"
    },
    {
      "id": 142,
      "kana": "～かい ",
      "kanji": "～回 ",
      "type": "n ",
      "meaning": "counter for occurrences"
    },
    {
      "id": 143,
      "kana": "～かい ",
      "kanji": "～階 ",
      "type": "n ",
      "meaning": "counter for storeys of a building"
    },
    {
      "id": 144,
      "kana": "がいこく ",
      "kanji": "外国 ",
      "type": "n ",
      "meaning": "foreign country"
    },
    {
      "id": 145,
      "kana": "がいこくじん ",
      "kanji": "外国人 ",
      "type": "n ",
      "meaning": "foreigner"
    },
    {
      "id": 146,
      "kana": "かいしゃ ",
      "kanji": "会社 ",
      "type": "n ",
      "meaning": "company, corporation"
    },
    {
      "id": 147,
      "kana": "かいだん ",
      "kanji": "階段 ",
      "type": "n ",
      "meaning": "stairs"
    },
    {
      "id": 148,
      "kana": "かいもの ",
      "kanji": "買い物 ",
      "type": "n ",
      "meaning": "shopping"
    },
    {
      "id": 149,
      "kana": "かう ",
      "kanji": "買う ",
      "type": "u-v ",
      "meaning": "to buy"
    },
    {
      "id": 150,
      "kana": "かえす ",
      "kanji": "返す ",
      "type": "u-v,vt ",
      "meaning": "to return something"
    },
    {
      "id": 151,
      "kana": "かえる ",
      "kanji": "帰る ",
      "type": "u-v ",
      "meaning": "to go home, to return"
    },
    {
      "id": 152,
      "kana": "かお ",
      "kanji": "顔 ",
      "type": "n ",
      "meaning": "face (person)"
    },
    {
      "id": 153,
      "kana": "かかる ",
      "kanji": "",
      "type": "u-v,vi ",
      "meaning": "to take (eg time, money)"
    },
    {
      "id": 154,
      "kana": "かぎ ",
      "kanji": "",
      "type": "n ",
      "meaning": "key/s"
    },
    {
      "id": 155,
      "kana": "かく ",
      "kanji": "書く ",
      "type": "u-v ",
      "meaning": "to write"
    },
    {
      "id": 156,
      "kana": "がくせい ",
      "kanji": "学生 ",
      "type": "n ",
      "meaning": "student"
    },
    {
      "id": 157,
      "kana": "～かげつ ",
      "kanji": "～か月 ",
      "type": "suf ",
      "meaning": "(number of) months"
    },
    {
      "id": 158,
      "kana": "かける ",
      "kanji": "",
      "type": "ru-v ",
      "meaning": "to put on (eg glasses)"
    },
    {
      "id": 159,
      "kana": "かける ",
      "kanji": "",
      "type": "ru-v ",
      "meaning": "to dial/call (eg phone)"
    },
    {
      "id": 160,
      "kana": "かさ ",
      "kanji": "傘 ",
      "type": "n ",
      "meaning": "umbrella"
    },
    {
      "id": 161,
      "kana": "かす ",
      "kanji": "貸す ",
      "type": "u-v ",
      "meaning": "to lend"
    },
    {
      "id": 162,
      "kana": "かぜ ",
      "kanji": "風 ",
      "type": "n ",
      "meaning": "wind, breeze"
    },
    {
      "id": 163,
      "kana": "かぜ ",
      "kanji": "風邪 ",
      "type": "n ",
      "meaning": "cold, illness"
    },
    {
      "id": 164,
      "kana": "かた ",
      "kanji": "方 ",
      "type": "n ",
      "meaning": "person"
    },
    {
      "id": 165,
      "kana": "かぞく ",
      "kanji": "家族 ",
      "type": "n ",
      "meaning": "family"
    },
    {
      "id": 166,
      "kana": "かたかな ",
      "kanji": "片仮名 ",
      "type": "n ",
      "meaning": "katakana"
    },
    {
      "id": 167,
      "kana": "～がつ ",
      "kanji": "～月 ",
      "type": "suf ",
      "meaning": "month of year"
    },
    {
      "id": 168,
      "kana": "がっこう ",
      "kanji": "学校 ",
      "type": "n ",
      "meaning": "school"
    },
    {
      "id": 169,
      "kana": "カップ ",
      "kanji": "",
      "type": "n ",
      "meaning": "cup"
    },
    {
      "id": 170,
      "kana": "かてい ",
      "kanji": "家庭 ",
      "type": "n ",
      "meaning": "home, household"
    },
    {
      "id": 171,
      "kana": "かど ",
      "kanji": "角 ",
      "type": "n ",
      "meaning": "corner (e.g. desk)"
    },
    {
      "id": 172,
      "kana": "かばん ",
      "kanji": "",
      "type": "n ",
      "meaning": "bag, basket"
    },
    {
      "id": 173,
      "kana": "かびん ",
      "kanji": "花瓶 ",
      "type": "n ",
      "meaning": "(flower) vase"
    },
    {
      "id": 174,
      "kana": "かぶる ",
      "kanji": "",
      "type": "u-v ",
      "meaning": "to wear, to put on (head)"
    },
    {
      "id": 175,
      "kana": "かみ ",
      "kanji": "紙 ",
      "type": "n ",
      "meaning": "paper"
    },
    {
      "id": 176,
      "kana": "カメラ ",
      "kanji": "",
      "type": "n ",
      "meaning": "camera"
    },
    {
      "id": 177,
      "kana": "かようび ",
      "kanji": "火曜日 ",
      "type": "n-adv,n ",
      "meaning": "Tuesday"
    },
    {
      "id": 178,
      "kana": "からい ",
      "kanji": "辛い ",
      "type": "adj ",
      "meaning": "spicy, salty"
    },
    {
      "id": 179,
      "kana": "からだ ",
      "kanji": "体 ",
      "type": "n ",
      "meaning": "body"
    },
    {
      "id": 180,
      "kana": "かりる ",
      "kanji": "借りる ",
      "type": "ru-v ",
      "meaning": "to borrow, to have a loan"
    },
    {
      "id": 181,
      "kana": "～がる ",
      "kanji": "",
      "type": "suf ",
      "meaning": "feel"
    },
    {
      "id": 182,
      "kana": "かるい ",
      "kanji": "軽い ",
      "type": "adj ",
      "meaning": "light, non-serious, minor"
    },
    {
      "id": 183,
      "kana": "カレー ",
      "kanji": "",
      "type": "n ",
      "meaning": "curry"
    },
    {
      "id": 184,
      "kana": "カレンダー ",
      "kanji": "",
      "type": "n ",
      "meaning": "calendar"
    },
    {
      "id": 185,
      "kana": "かわ ",
      "kanji": "川/河 ",
      "type": "n ",
      "meaning": "river"
    },
    {
      "id": 186,
      "kana": "～がわ ",
      "kanji": "側 ",
      "type": "n-suf ",
      "meaning": "～ side"
    },
    {
      "id": 187,
      "kana": "かわいい ",
      "kanji": "",
      "type": "adj ",
      "meaning": "cute, charming"
    },
    {
      "id": 188,
      "kana": "かんじ ",
      "kanji": "漢字 ",
      "type": "n ",
      "meaning": "kanji, Chinese character"
    },
    {
      "id": 189,
      "kana": "き ",
      "kanji": "木 ",
      "type": "n ",
      "meaning": "tree, wood"
    },
    {
      "id": 190,
      "kana": "きいろ ",
      "kanji": "黄色 ",
      "type": "n ",
      "meaning": "yellow"
    },
    {
      "id": 191,
      "kana": "きいろい ",
      "kanji": "黄色い ",
      "type": "adj ",
      "meaning": "yellow"
    },
    {
      "id": 192,
      "kana": "きえる ",
      "kanji": "消える ",
      "type": "ru-v ",
      "meaning": "to go out, to vanish"
    },
    {
      "id": 193,
      "kana": "きく ",
      "kanji": "聞く ",
      "type": "u-v ",
      "meaning": "to hear, to listen, to ask"
    },
    {
      "id": 194,
      "kana": "きた ",
      "kanji": "北 ",
      "type": "",
      "meaning": "north"
    },
    {
      "id": 195,
      "kana": "ギター ",
      "kanji": "",
      "type": "n ",
      "meaning": "guitar"
    },
    {
      "id": 196,
      "kana": "きたない ",
      "kanji": "汚い ",
      "type": "adj ",
      "meaning": "dirty, messy"
    },
    {
      "id": 197,
      "kana": "きっさてん ",
      "kanji": "喫茶店 ",
      "type": "n ",
      "meaning": "coffee lounge"
    },
    {
      "id": 198,
      "kana": "きって ",
      "kanji": "切手 ",
      "type": "n ",
      "meaning": "stamp (eg. postage)"
    },
    {
      "id": 199,
      "kana": "きっぷ ",
      "kanji": "切符 ",
      "type": "n ",
      "meaning": "ticket"
    },
    {
      "id": 200,
      "kana": "きのう ",
      "kanji": "昨日 ",
      "type": "n-adv,n-t ",
      "meaning": "yesterday"
    },
    {
      "id": 201,
      "kana": "きゅう ",
      "kanji": "九 ",
      "type": "num ",
      "meaning": "nine"
    },
    {
      "id": 202,
      "kana": "ぎゅうにく ",
      "kanji": "牛肉 ",
      "type": "n ",
      "meaning": "beef"
    },
    {
      "id": 203,
      "kana": "ぎゅうにゅう ",
      "kanji": "牛乳 ",
      "type": "n ",
      "meaning": "milk"
    },
    {
      "id": 204,
      "kana": "きょう ",
      "kanji": "今日 ",
      "type": "n-t ",
      "meaning": "today, this day"
    },
    {
      "id": 205,
      "kana": "きょうしつ ",
      "kanji": "教室 ",
      "type": "n ",
      "meaning": "classroom"
    },
    {
      "id": 206,
      "kana": "きょうだい ",
      "kanji": "兄弟 ",
      "type": "n ",
      "meaning": "siblings (brothers and sisters)"
    },
    {
      "id": 207,
      "kana": "きょねん ",
      "kanji": "去年 ",
      "type": "n-adv,n-t ",
      "meaning": "last year"
    },
    {
      "id": 208,
      "kana": "きらい ",
      "kanji": "嫌い ",
      "type": "na-adj,n ",
      "meaning": "dislike, hate"
    },
    {
      "id": 209,
      "kana": "きる ",
      "kanji": "切る ",
      "type": "suf,u-v ",
      "meaning": "to cut, to chop"
    },
    {
      "id": 210,
      "kana": "きる ",
      "kanji": "着る ",
      "type": "ru-v ",
      "meaning": "to wear, to put on (from shoulders down)"
    },
    {
      "id": 211,
      "kana": "きれい ",
      "kanji": "",
      "type": "na-adj ",
      "meaning": "pretty, clean, nice, tidy"
    },
    {
      "id": 212,
      "kana": "キロ/キログラム ",
      "kanji": "",
      "type": "n,pref ",
      "meaning": "kilo (kilogram)"
    },
    {
      "id": 213,
      "kana": "キロ/キロメートル ",
      "kanji": "",
      "type": "n,pref ",
      "meaning": "kilo (kilometre)"
    },
    {
      "id": 214,
      "kana": "ぎんこう ",
      "kanji": "銀行 ",
      "type": "n ",
      "meaning": "bank"
    },
    {
      "id": 215,
      "kana": "きんようび ",
      "kanji": "金曜日 ",
      "type": "n-adv,n ",
      "meaning": "Friday"
    },
    {
      "id": 216,
      "kana": "く ",
      "kanji": "九 ",
      "type": "number ",
      "meaning": "nine"
    },
    {
      "id": 217,
      "kana": "くすり ",
      "kanji": "薬 ",
      "type": "n ",
      "meaning": "medicine"
    },
    {
      "id": 218,
      "kana": "ください ",
      "kanji": "",
      "type": "kana only ",
      "meaning": "(with te-form verb) please do for me"
    },
    {
      "id": 219,
      "kana": "くだもの ",
      "kanji": "果物 ",
      "type": "n ",
      "meaning": "fruit"
    },
    {
      "id": 220,
      "kana": "くち ",
      "kanji": "口 ",
      "type": "n ",
      "meaning": "mouth, orifice, opening"
    },
    {
      "id": 221,
      "kana": "くつ ",
      "kanji": "靴 ",
      "type": "n ",
      "meaning": "shoes, footwear"
    },
    {
      "id": 222,
      "kana": "くつした ",
      "kanji": "靴下 ",
      "type": "n ",
      "meaning": "socks"
    },
    {
      "id": 223,
      "kana": "くに ",
      "kanji": "国 ",
      "type": "n ",
      "meaning": "country"
    },
    {
      "id": 224,
      "kana": "くもり ",
      "kanji": "曇り ",
      "type": "adv ",
      "meaning": "cloudiness, cloudy weather"
    },
    {
      "id": 225,
      "kana": "くもる ",
      "kanji": "曇る ",
      "type": "u-v ",
      "meaning": "to become cloudy, to become dim"
    },
    {
      "id": 226,
      "kana": "くらい ",
      "kanji": "暗い ",
      "type": "adj ",
      "meaning": "dark, gloomy"
    },
    {
      "id": 227,
      "kana": "～くらい/ぐらい ",
      "kanji": "",
      "type": "suf ",
      "meaning": "approximate (quantity)"
    },
    {
      "id": 228,
      "kana": "クラス ",
      "kanji": "",
      "type": "n ",
      "meaning": "class"
    },
    {
      "id": 229,
      "kana": "グラム ",
      "kanji": "",
      "type": "n ",
      "meaning": "gram"
    },
    {
      "id": 230,
      "kana": "くる ",
      "kanji": "来る ",
      "type": "vk ",
      "meaning": "to come"
    },
    {
      "id": 231,
      "kana": "くるま ",
      "kanji": "車 ",
      "type": "n ",
      "meaning": "car, vehicle"
    },
    {
      "id": 232,
      "kana": "くろ ",
      "kanji": "黒 ",
      "type": "n ",
      "meaning": "black"
    },
    {
      "id": 233,
      "kana": "くろい ",
      "kanji": "黒い ",
      "type": "adj ",
      "meaning": "black"
    },
    {
      "id": 234,
      "kana": "けいかん ",
      "kanji": "警官 ",
      "type": "n ",
      "meaning": "policeman"
    },
    {
      "id": 235,
      "kana": "けさ ",
      "kanji": "今朝 ",
      "type": "n-temp ",
      "meaning": "this morning"
    },
    {
      "id": 236,
      "kana": "けす ",
      "kanji": "消す ",
      "type": "u-v ",
      "meaning": "to erase, to delete, to turn off power"
    },
    {
      "id": 237,
      "kana": "けっこう ",
      "kanji": "結構 ",
      "type": "na-adj,n-adv,n ",
      "meaning": "nice, enough"
    },
    {
      "id": 238,
      "kana": "けっこん（する） ",
      "kanji": "結婚 ",
      "type": "adj-no,n,vs ",
      "meaning": "marriage"
    },
    {
      "id": 239,
      "kana": "げつようび ",
      "kanji": "月曜日 ",
      "type": "n-adv,n ",
      "meaning": "Monday"
    },
    {
      "id": 240,
      "kana": "げんかん ",
      "kanji": "玄関 ",
      "type": "n ",
      "meaning": "entrance-way, entry hall"
    },
    {
      "id": 241,
      "kana": "げんき ",
      "kanji": "元気 ",
      "type": "na-adj,n ",
      "meaning": "health"
    },
    {
      "id": 242,
      "kana": "～こ ",
      "kanji": "～個 ",
      "type": "n,suf ",
      "meaning": "counter for small items"
    },
    {
      "id": 243,
      "kana": "ご ",
      "kanji": "五 ",
      "type": "num ",
      "meaning": "five"
    },
    {
      "id": 244,
      "kana": "～ご ",
      "kanji": "～語 ",
      "type": "n,n-suf ",
      "meaning": "word, language"
    },
    {
      "id": 245,
      "kana": "こうえん ",
      "kanji": "公園 ",
      "type": "n ",
      "meaning": "(public) park"
    },
    {
      "id": 246,
      "kana": "こうさてん ",
      "kanji": "交差点 ",
      "type": "n ",
      "meaning": "intersection"
    },
    {
      "id": 247,
      "kana": "こうちゃ ",
      "kanji": "紅茶 ",
      "type": "n ",
      "meaning": "black tea"
    },
    {
      "id": 248,
      "kana": "こうばん ",
      "kanji": "交番 ",
      "type": "n ",
      "meaning": "police box"
    },
    {
      "id": 249,
      "kana": "こえ ",
      "kanji": "声 ",
      "type": "n ",
      "meaning": "voice"
    },
    {
      "id": 250,
      "kana": "コート ",
      "kanji": "",
      "type": "n ",
      "meaning": "coat"
    },
    {
      "id": 251,
      "kana": "コーヒー ",
      "kanji": "",
      "type": "n ",
      "meaning": "coffee"
    },
    {
      "id": 252,
      "kana": "ここ ",
      "kanji": "",
      "type": "n ",
      "meaning": "here, this place"
    },
    {
      "id": 253,
      "kana": "ごご ",
      "kanji": "午後 ",
      "type": "n-adv,n-t ",
      "meaning": "afternoon, P.M."
    },
    {
      "id": 254,
      "kana": "ここのか ",
      "kanji": "九日 ",
      "type": "",
      "meaning": "nine days, the ninth day (of the month)"
    },
    {
      "id": 255,
      "kana": "ここのつ ",
      "kanji": "九つ ",
      "type": "n ",
      "meaning": "nine"
    },
    {
      "id": 256,
      "kana": "ごぜん ",
      "kanji": "午前 ",
      "type": "n-adv,n-t ",
      "meaning": "morning, A.M."
    },
    {
      "id": 257,
      "kana": "こたえる ",
      "kanji": "答える ",
      "type": "ru-v ",
      "meaning": "to answer, to reply"
    },
    {
      "id": 258,
      "kana": "こちら ",
      "kanji": "",
      "type": "n ",
      "meaning": "this eg person, way"
    },
    {
      "id": 259,
      "kana": "こっち ",
      "kanji": "",
      "type": "n ",
      "meaning": "this eg person, way"
    },
    {
      "id": 260,
      "kana": "コップ ",
      "kanji": "",
      "type": "n ",
      "meaning": "cup"
    },
    {
      "id": 261,
      "kana": "ことし ",
      "kanji": "今年 ",
      "type": "n-adv,n-t ",
      "meaning": "this year"
    },
    {
      "id": 262,
      "kana": "ことば ",
      "kanji": "言葉 ",
      "type": "n ",
      "meaning": "word"
    },
    {
      "id": 263,
      "kana": "こども ",
      "kanji": "子供 ",
      "type": "n ",
      "meaning": "child, children"
    },
    {
      "id": 264,
      "kana": "この ",
      "kanji": "",
      "type": "adj-pn,int ",
      "meaning": "this"
    },
    {
      "id": 265,
      "kana": "ごはん ",
      "kanji": "御飯 ",
      "type": "n ",
      "meaning": "rice (cooked), meal"
    },
    {
      "id": 266,
      "kana": "コピー・する ",
      "kanji": "",
      "type": "n,p-suru ",
      "meaning": "to copy"
    },
    {
      "id": 267,
      "kana": "こまる ",
      "kanji": "困る ",
      "type": "u-v ",
      "meaning": "to be worried, to be bothered"
    },
    {
      "id": 268,
      "kana": "これ ",
      "kanji": "",
      "type": "int,n ",
      "meaning": "this"
    },
    {
      "id": 269,
      "kana": "～ころ/～ごろ ",
      "kanji": "",
      "type": "n-suf ",
      "meaning": "about, approximately (time)"
    },
    {
      "id": 270,
      "kana": "こんげつ ",
      "kanji": "今月 ",
      "type": "n-adv,n-t ",
      "meaning": "this month"
    },
    {
      "id": 271,
      "kana": "こんしゅう ",
      "kanji": "今週 ",
      "type": "n-adv,n-t ",
      "meaning": "this week"
    },
    {
      "id": 272,
      "kana": "こんな ",
      "kanji": "",
      "type": "na-adj,adj-pn,adv,n ",
      "meaning": "such, like this"
    },
    {
      "id": 273,
      "kana": "こんばん ",
      "kanji": "今晩 ",
      "type": "n-adv,n-t ",
      "meaning": "tonight, this evening"
    },
    {
      "id": 274,
      "kana": "さあ ",
      "kanji": "",
      "type": "conj,int ",
      "meaning": "come now, well"
    },
    {
      "id": 275,
      "kana": "～さい ",
      "kanji": "～歳 ",
      "type": "suf ",
      "meaning": "～years-old"
    },
    {
      "id": 276,
      "kana": "さいふ ",
      "kanji": "財布 ",
      "type": "n ",
      "meaning": "wallet"
    },
    {
      "id": 277,
      "kana": "さかな ",
      "kanji": "魚 ",
      "type": "n ",
      "meaning": "fish"
    },
    {
      "id": 278,
      "kana": "さき ",
      "kanji": "先 ",
      "type": "adj-no,n,n-suf,pref ",
      "meaning": "the future, former, previous"
    },
    {
      "id": 279,
      "kana": "さく ",
      "kanji": "咲く ",
      "type": "u-v ",
      "meaning": "to bloom"
    },
    {
      "id": 280,
      "kana": "さくぶん ",
      "kanji": "作文 ",
      "type": "n ",
      "meaning": "composition, writing"
    },
    {
      "id": 281,
      "kana": "さす ",
      "kanji": "差す ",
      "type": "u-v ",
      "meaning": "to raise (stretch out) hands, to raise (eg umbrella)"
    },
    {
      "id": 282,
      "kana": "～さつ ",
      "kanji": "～冊 ",
      "type": "n ",
      "meaning": "counter for books"
    },
    {
      "id": 283,
      "kana": "ざっし ",
      "kanji": "雑誌 ",
      "type": "n ",
      "meaning": "magazine"
    },
    {
      "id": 284,
      "kana": "さとう ",
      "kanji": "砂糖 ",
      "type": "n ",
      "meaning": "sugar"
    },
    {
      "id": 285,
      "kana": "さむい ",
      "kanji": "寒い ",
      "type": "adj ",
      "meaning": "cold (e.g. weather)"
    },
    {
      "id": 286,
      "kana": "さらいねん ",
      "kanji": "さ来年 ",
      "type": "n-adv,n-t ",
      "meaning": "year after next"
    },
    {
      "id": 287,
      "kana": "～さん ",
      "kanji": "",
      "type": "suf ",
      "meaning": "Mr or Mrs"
    },
    {
      "id": 288,
      "kana": "さん ",
      "kanji": "三 ",
      "type": "num ",
      "meaning": "three"
    },
    {
      "id": 289,
      "kana": "さんぽ（する） ",
      "kanji": "散歩 ",
      "type": "n,vs ",
      "meaning": "walk, stroll"
    },
    {
      "id": 290,
      "kana": "し ",
      "kanji": "四 ",
      "type": "num ",
      "meaning": "four"
    },
    {
      "id": 291,
      "kana": "～じ ",
      "kanji": "～時 ",
      "type": "suf ",
      "meaning": "time (～ O'clock)"
    },
    {
      "id": 292,
      "kana": "しお ",
      "kanji": "塩 ",
      "type": "n ",
      "meaning": "salt"
    },
    {
      "id": 293,
      "kana": "しかし ",
      "kanji": "",
      "type": "conj ",
      "meaning": "however, but"
    },
    {
      "id": 294,
      "kana": "じかん ",
      "kanji": "時間 ",
      "type": "n-adv,n ",
      "meaning": "time"
    },
    {
      "id": 295,
      "kana": "～じかん ",
      "kanji": "～時間 ",
      "type": "suf ",
      "meaning": "～hours"
    },
    {
      "id": 296,
      "kana": "しごと ",
      "kanji": "仕事 ",
      "type": "adj-no,n ",
      "meaning": "work, occupation, employment"
    },
    {
      "id": 297,
      "kana": "じしょ ",
      "kanji": "辞書 ",
      "type": "n ",
      "meaning": "dictionary"
    },
    {
      "id": 298,
      "kana": "しずか ",
      "kanji": "静か ",
      "type": "na-adj ",
      "meaning": "quiet, peaceful"
    },
    {
      "id": 299,
      "kana": "した ",
      "kanji": "下 ",
      "type": "n ",
      "meaning": "under, below, beneath"
    },
    {
      "id": 300,
      "kana": "しち ",
      "kanji": "七 ",
      "type": "num ",
      "meaning": "seven"
    },
    {
      "id": 301,
      "kana": "しつもん ",
      "kanji": "質問 ",
      "type": "n,vs ",
      "meaning": "question, inquiry"
    },
    {
      "id": 302,
      "kana": "じてんしゃ ",
      "kanji": "自転車 ",
      "type": "n ",
      "meaning": "bicycle"
    },
    {
      "id": 303,
      "kana": "じどうしゃ ",
      "kanji": "自動車 ",
      "type": "n ",
      "meaning": "automobile"
    },
    {
      "id": 304,
      "kana": "しぬ ",
      "kanji": "死ぬ ",
      "type": "v5n ",
      "meaning": "to die"
    },
    {
      "id": 305,
      "kana": "じびき ",
      "kanji": "字引 ",
      "type": "n ",
      "meaning": "dictionary"
    },
    {
      "id": 306,
      "kana": "じぶん ",
      "kanji": "自分 ",
      "type": "n ",
      "meaning": "myself, oneself"
    },
    {
      "id": 307,
      "kana": "しまる ",
      "kanji": "閉まる ",
      "type": "u-v,vi ",
      "meaning": "to close, to be closed"
    },
    {
      "id": 308,
      "kana": "しめる ",
      "kanji": "閉める ",
      "type": "ru-v,vt ",
      "meaning": "to close, to shut"
    },
    {
      "id": 309,
      "kana": "しめる ",
      "kanji": "締める ",
      "type": "ru-v ",
      "meaning": "to tie, to fasten"
    },
    {
      "id": 310,
      "kana": "じゃ/じゃあ ",
      "kanji": "",
      "type": "conj,int ",
      "meaning": "well, well then"
    },
    {
      "id": 311,
      "kana": "しゃしん ",
      "kanji": "写真 ",
      "type": "n ",
      "meaning": "photograph"
    },
    {
      "id": 312,
      "kana": "シャツ ",
      "kanji": "",
      "type": "n ",
      "meaning": "shirt, singlet"
    },
    {
      "id": 313,
      "kana": "シャワー ",
      "kanji": "",
      "type": "n ",
      "meaning": "shower"
    },
    {
      "id": 314,
      "kana": "じゅう ",
      "kanji": "十 ",
      "type": "num ",
      "meaning": "ten"
    },
    {
      "id": 315,
      "kana": "～じゅう ",
      "kanji": "～中 ",
      "type": "suf ",
      "meaning": "during, while"
    },
    {
      "id": 316,
      "kana": "～しゅうかん ",
      "kanji": "～週間 ",
      "type": "suf ",
      "meaning": "～weeks"
    },
    {
      "id": 317,
      "kana": "じゅぎょう ",
      "kanji": "授業 ",
      "type": "n,vs ",
      "meaning": "lesson, class work"
    },
    {
      "id": 318,
      "kana": "しゅくだい ",
      "kanji": "宿題 ",
      "type": "n ",
      "meaning": "homework"
    },
    {
      "id": 319,
      "kana": "じょうず ",
      "kanji": "上手 ",
      "type": "na-adj,n ",
      "meaning": "skill, skillful, dexterity"
    },
    {
      "id": 320,
      "kana": "じょうぶ ",
      "kanji": "丈夫 ",
      "type": "na-adj,n ",
      "meaning": "strong, solid, durable"
    },
    {
      "id": 321,
      "kana": "しょうゆ ",
      "kanji": "",
      "type": "n ",
      "meaning": "soy sauce"
    },
    {
      "id": 322,
      "kana": "しょくどう ",
      "kanji": "食堂 ",
      "type": "n ",
      "meaning": "cafeteria, dining hall"
    },
    {
      "id": 323,
      "kana": "しる ",
      "kanji": "知る ",
      "type": "u-v ",
      "meaning": "to know, to understand"
    },
    {
      "id": 324,
      "kana": "しろ ",
      "kanji": "白 ",
      "type": "n ",
      "meaning": "white"
    },
    {
      "id": 325,
      "kana": "しろい ",
      "kanji": "白い ",
      "type": "adj ",
      "meaning": "white"
    },
    {
      "id": 326,
      "kana": "～にん ",
      "kanji": "～人 ",
      "type": "n,suf ",
      "meaning": "counter for people"
    },
    {
      "id": 327,
      "kana": "しんぶん ",
      "kanji": "新聞 ",
      "type": "n ",
      "meaning": "newspaper"
    },
    {
      "id": 328,
      "kana": "すいようび ",
      "kanji": "水曜日 ",
      "type": "n-adv,n ",
      "meaning": "Wednesday"
    },
    {
      "id": 329,
      "kana": "すう ",
      "kanji": "吸う ",
      "type": "u-v ",
      "meaning": "to smoke, to breathe in, to suck"
    },
    {
      "id": 330,
      "kana": "スカート ",
      "kanji": "",
      "type": "n ",
      "meaning": "skirt"
    },
    {
      "id": 331,
      "kana": "すき ",
      "kanji": "好き ",
      "type": "na-adj,n ",
      "meaning": "liking, fondness, love"
    },
    {
      "id": 332,
      "kana": "～すぎ ",
      "kanji": "",
      "type": "suf ",
      "meaning": "to exceed, ～ too much"
    },
    {
      "id": 333,
      "kana": "すくない ",
      "kanji": "少ない ",
      "type": "adj ",
      "meaning": "a few, scarce"
    },
    {
      "id": 334,
      "kana": "すぐ・に ",
      "kanji": "",
      "type": "adv ",
      "meaning": "immediately, instantly"
    },
    {
      "id": 335,
      "kana": "すこし ",
      "kanji": "少し ",
      "type": "adv,n ",
      "meaning": "little, few"
    },
    {
      "id": 336,
      "kana": "すずしい ",
      "kanji": "涼しい ",
      "type": "adj ",
      "meaning": "cool, refreshing"
    },
    {
      "id": 337,
      "kana": "～ずつ ",
      "kanji": "",
      "type": "suf ",
      "meaning": "at a time, gradually"
    },
    {
      "id": 338,
      "kana": "ストーブ ",
      "kanji": "",
      "type": "n ",
      "meaning": "heater (lit: stove)"
    },
    {
      "id": 339,
      "kana": "スプーン ",
      "kanji": "",
      "type": "n ",
      "meaning": "spoon"
    },
    {
      "id": 340,
      "kana": "スポーツ ",
      "kanji": "",
      "type": "n ",
      "meaning": "sport"
    },
    {
      "id": 341,
      "kana": "ズボン ",
      "kanji": "",
      "type": "n ",
      "meaning": "trousers (fr: jupon)"
    },
    {
      "id": 342,
      "kana": "すむ ",
      "kanji": "住む ",
      "type": "u-v ",
      "meaning": "to reside, to live in"
    },
    {
      "id": 343,
      "kana": "スリッパ ",
      "kanji": "",
      "type": "n ",
      "meaning": "slippers"
    },
    {
      "id": 344,
      "kana": "する ",
      "kanji": "",
      "type": "vs-i ",
      "meaning": "to do, to try"
    },
    {
      "id": 345,
      "kana": "すわる ",
      "kanji": "座る ",
      "type": "u-v ",
      "meaning": "to sit"
    },
    {
      "id": 346,
      "kana": "せい ",
      "kanji": "背 ",
      "type": "n ",
      "meaning": "height, stature"
    },
    {
      "id": 347,
      "kana": "せいと ",
      "kanji": "生徒 ",
      "type": "n ",
      "meaning": "pupil"
    },
    {
      "id": 348,
      "kana": "セーター ",
      "kanji": "",
      "type": "n ",
      "meaning": "sweater, jumper"
    },
    {
      "id": 349,
      "kana": "せっけん ",
      "kanji": "石鹸 ",
      "type": "n ",
      "meaning": "soap"
    },
    {
      "id": 350,
      "kana": "せびろ ",
      "kanji": "背広 ",
      "type": "n ",
      "meaning": "business suit"
    },
    {
      "id": 351,
      "kana": "せまい ",
      "kanji": "狭い ",
      "type": "adj ",
      "meaning": "narrow, confined, small"
    },
    {
      "id": 352,
      "kana": "ゼロ ",
      "kanji": "",
      "type": "n ",
      "meaning": "zero"
    },
    {
      "id": 353,
      "kana": "せん ",
      "kanji": "千 ",
      "type": "",
      "meaning": "thousand, many"
    },
    {
      "id": 354,
      "kana": "せんげつ ",
      "kanji": "先月 ",
      "type": "n-adv,n-t ",
      "meaning": "last month"
    },
    {
      "id": 355,
      "kana": "せんしゅう ",
      "kanji": "先週 ",
      "type": "n-adv,n-t ",
      "meaning": "last week, the week before"
    },
    {
      "id": 356,
      "kana": "せんせい ",
      "kanji": "先生 ",
      "type": "n ",
      "meaning": "teacher, master, doctor"
    },
    {
      "id": 357,
      "kana": "せんたく ",
      "kanji": "洗濯 ",
      "type": "n,vs ",
      "meaning": "washing, laundry"
    },
    {
      "id": 358,
      "kana": "ぜんぶ ",
      "kanji": "全部 ",
      "type": "n-adv,n-t ",
      "meaning": "all, entire, whole"
    },
    {
      "id": 359,
      "kana": "そう/そうです ",
      "kanji": "",
      "type": "",
      "meaning": "appears, to be the case"
    },
    {
      "id": 360,
      "kana": "そうじ（する） ",
      "kanji": "掃除 ",
      "type": "n,vs ",
      "meaning": "cleaning, sweeping"
    },
    {
      "id": 361,
      "kana": "そうして/そして ",
      "kanji": "",
      "type": "conj ",
      "meaning": "and, like that"
    },
    {
      "id": 362,
      "kana": "そこ ",
      "kanji": "",
      "type": "n ",
      "meaning": "that place, there"
    },
    {
      "id": 363,
      "kana": "そちら ",
      "kanji": "",
      "type": "n ",
      "meaning": "over there"
    },
    {
      "id": 364,
      "kana": "そっち ",
      "kanji": "",
      "type": "n ",
      "meaning": "over there"
    },
    {
      "id": 365,
      "kana": "そと ",
      "kanji": "外 ",
      "type": "n ",
      "meaning": "outside, exterior"
    },
    {
      "id": 366,
      "kana": "その ",
      "kanji": "",
      "type": "adj-pn ",
      "meaning": "that"
    },
    {
      "id": 367,
      "kana": "そば ",
      "kanji": "",
      "type": "n ",
      "meaning": "near, close, beside"
    },
    {
      "id": 368,
      "kana": "そら ",
      "kanji": "空 ",
      "type": "n ",
      "meaning": "sky"
    },
    {
      "id": 369,
      "kana": "それ ",
      "kanji": "",
      "type": "n ",
      "meaning": "it, that"
    },
    {
      "id": 370,
      "kana": "それから ",
      "kanji": "",
      "type": "uk ",
      "meaning": "and then, after that"
    },
    {
      "id": 371,
      "kana": "それでは ",
      "kanji": "",
      "type": "exp ",
      "meaning": "in that situation, well then..."
    },
    {
      "id": 372,
      "kana": "～だい ",
      "kanji": "～台 ",
      "type": "suf ",
      "meaning": "counter for vehicles/machines"
    },
    {
      "id": 373,
      "kana": "だいがく ",
      "kanji": "大学 ",
      "type": "n ",
      "meaning": "university"
    },
    {
      "id": 374,
      "kana": "たいしかん ",
      "kanji": "大使館 ",
      "type": "n ",
      "meaning": "embassy"
    },
    {
      "id": 375,
      "kana": "だいじょうぶ ",
      "kanji": "大丈夫 ",
      "type": "na-adj,adv,n ",
      "meaning": "safe, all right, O.K."
    },
    {
      "id": 376,
      "kana": "だいすき ",
      "kanji": "大好き ",
      "type": "na-adj,n ",
      "meaning": "very likeable, like very much"
    },
    {
      "id": 377,
      "kana": "たいせつ ",
      "kanji": "大切 ",
      "type": "na-adj,n ",
      "meaning": "important"
    },
    {
      "id": 378,
      "kana": "だいどころ ",
      "kanji": "台所 ",
      "type": "n ",
      "meaning": "kitchen"
    },
    {
      "id": 379,
      "kana": "たいへん ",
      "kanji": "",
      "type": "na-adj,adv,n ",
      "meaning": "very"
    },
    {
      "id": 380,
      "kana": "たいへん ",
      "kanji": "",
      "type": "na-adj,adv,n ",
      "meaning": "difficult situation, tough situation"
    },
    {
      "id": 381,
      "kana": "たかい ",
      "kanji": "高い ",
      "type": "adj ",
      "meaning": "tall, high"
    },
    {
      "id": 382,
      "kana": "たかい ",
      "kanji": "高い ",
      "type": "adj ",
      "meaning": "expensive"
    },
    {
      "id": 383,
      "kana": "～だけ ",
      "kanji": "",
      "type": "prt ",
      "meaning": "only ～, just ～"
    },
    {
      "id": 384,
      "kana": "たくさん ",
      "kanji": "",
      "type": "na-adj,adv,n ",
      "meaning": "many, a lot, much"
    },
    {
      "id": 385,
      "kana": "タクシー ",
      "kanji": "",
      "type": "n ",
      "meaning": "taxi"
    },
    {
      "id": 386,
      "kana": "だす ",
      "kanji": "出す ",
      "type": "u-v ",
      "meaning": "to put out, to send"
    },
    {
      "id": 387,
      "kana": "～たち ",
      "kanji": "",
      "type": "n-suf ",
      "meaning": "plural suffix"
    },
    {
      "id": 388,
      "kana": "たつ ",
      "kanji": "立つ ",
      "type": "u-v ",
      "meaning": "to stand"
    },
    {
      "id": 389,
      "kana": "たて ",
      "kanji": "",
      "type": "n ",
      "meaning": "length, height"
    },
    {
      "id": 390,
      "kana": "たてもの ",
      "kanji": "建物 ",
      "type": "n ",
      "meaning": "building"
    },
    {
      "id": 391,
      "kana": "たのしい ",
      "kanji": "楽しい ",
      "type": "adj ",
      "meaning": "enjoyable, fun"
    },
    {
      "id": 392,
      "kana": "たのむ ",
      "kanji": "頼む ",
      "type": "u-v ",
      "meaning": "to request, to ask"
    },
    {
      "id": 393,
      "kana": "たばこ ",
      "kanji": "",
      "type": "n ",
      "meaning": "tobacco, cigarettes"
    },
    {
      "id": 394,
      "kana": "たぶん ",
      "kanji": "",
      "type": "adv,n ",
      "meaning": "perhaps, probably"
    },
    {
      "id": 395,
      "kana": "たべもの ",
      "kanji": "食べ物 ",
      "type": "n ",
      "meaning": "food"
    },
    {
      "id": 396,
      "kana": "たべる ",
      "kanji": "食べる ",
      "type": "ru-v ",
      "meaning": "to eat"
    },
    {
      "id": 397,
      "kana": "たまご ",
      "kanji": "卵 ",
      "type": "n ",
      "meaning": "egg(s)"
    },
    {
      "id": 398,
      "kana": "だれ ",
      "kanji": "誰 ",
      "type": "n ",
      "meaning": "who"
    },
    {
      "id": 399,
      "kana": "だれか ",
      "kanji": "誰か ",
      "type": "n ",
      "meaning": "someone, somebody"
    },
    {
      "id": 400,
      "kana": "たんじょうび ",
      "kanji": "誕生日 ",
      "type": "n ",
      "meaning": "birthday"
    },
    {
      "id": 401,
      "kana": "だんだん ",
      "kanji": "",
      "type": "adv,n ",
      "meaning": "gradually, by degrees"
    },
    {
      "id": 402,
      "kana": "ちいさい ",
      "kanji": "小さい ",
      "type": "adj ",
      "meaning": "small, little"
    },
    {
      "id": 403,
      "kana": "ちいさな ",
      "kanji": "小さな ",
      "type": "na-adj ",
      "meaning": "small, little"
    },
    {
      "id": 404,
      "kana": "ちかい ",
      "kanji": "近い ",
      "type": "adj,suf ",
      "meaning": "near, close by, short"
    },
    {
      "id": 405,
      "kana": "ちがう ",
      "kanji": "違う ",
      "type": "u-v ",
      "meaning": "to differ (from)"
    },
    {
      "id": 406,
      "kana": "ちかく ",
      "kanji": "近く ",
      "type": "n-adv,n ",
      "meaning": "near"
    },
    {
      "id": 407,
      "kana": "ちかてつ ",
      "kanji": "地下鉄 ",
      "type": "n ",
      "meaning": "underground train, subway"
    },
    {
      "id": 408,
      "kana": "ちず ",
      "kanji": "地図 ",
      "type": "n ",
      "meaning": "map"
    },
    {
      "id": 409,
      "kana": "ちち ",
      "kanji": "父 ",
      "type": "n ",
      "meaning": "(one's own) father"
    },
    {
      "id": 410,
      "kana": "ちゃいろ ",
      "kanji": "茶色 ",
      "type": "n ",
      "meaning": "brown"
    },
    {
      "id": 411,
      "kana": "ちゃわん ",
      "kanji": "",
      "type": "n ",
      "meaning": "rice bowl"
    },
    {
      "id": 412,
      "kana": "～ちゅう ",
      "kanji": "～中 ",
      "type": "suf ",
      "meaning": "during, while ～ing"
    },
    {
      "id": 413,
      "kana": "ちょうど ",
      "kanji": "",
      "type": "gn ",
      "meaning": "just, right, exactly"
    },
    {
      "id": 414,
      "kana": "ちょっと ",
      "kanji": "",
      "type": "adv,int ",
      "meaning": "a little, somewhat"
    },
    {
      "id": 415,
      "kana": "ついたち ",
      "kanji": "一日 ",
      "type": "n ",
      "meaning": "for one day, first of month"
    },
    {
      "id": 416,
      "kana": "つかう ",
      "kanji": "使う ",
      "type": "u-v ",
      "meaning": "to use"
    },
    {
      "id": 417,
      "kana": "つかれる ",
      "kanji": "疲れる ",
      "type": "ru-v ",
      "meaning": "to get tired, to tire"
    },
    {
      "id": 418,
      "kana": "つぎ ",
      "kanji": "次 ",
      "type": "n ",
      "meaning": "next"
    },
    {
      "id": 419,
      "kana": "つく ",
      "kanji": "着く ",
      "type": "u-v ",
      "meaning": "to arrive at, to reach"
    },
    {
      "id": 420,
      "kana": "つくえ ",
      "kanji": "机 ",
      "type": "n ",
      "meaning": "desk"
    },
    {
      "id": 421,
      "kana": "つくる ",
      "kanji": "作る ",
      "type": "u-v ",
      "meaning": "to make, to create"
    },
    {
      "id": 422,
      "kana": "つける ",
      "kanji": "",
      "type": "ru-v,vt ",
      "meaning": "to turn on (eg a light)"
    },
    {
      "id": 423,
      "kana": "つとめる ",
      "kanji": "勤める ",
      "type": "ru-v ",
      "meaning": "to serve, to work (for)"
    },
    {
      "id": 424,
      "kana": "つまらない ",
      "kanji": "",
      "type": "adj ",
      "meaning": "insignificant, boring"
    },
    {
      "id": 425,
      "kana": "つめたい ",
      "kanji": "冷たい ",
      "type": "adj ",
      "meaning": "cold (to the touch)"
    },
    {
      "id": 426,
      "kana": "つよい ",
      "kanji": "強い ",
      "type": "adj ",
      "meaning": "strong, powerful"
    },
    {
      "id": 427,
      "kana": "て ",
      "kanji": "手 ",
      "type": "n ",
      "meaning": "hand"
    },
    {
      "id": 428,
      "kana": "テープ ",
      "kanji": "",
      "type": "n ",
      "meaning": "tape"
    },
    {
      "id": 429,
      "kana": "テープレコーダー ",
      "kanji": "",
      "type": "n ",
      "meaning": "tape recorder"
    },
    {
      "id": 430,
      "kana": "テーブル ",
      "kanji": "",
      "type": "n ",
      "meaning": "table"
    },
    {
      "id": 431,
      "kana": "でかける ",
      "kanji": "出かける ",
      "type": "ru-v ",
      "meaning": "to depart, to go out"
    },
    {
      "id": 432,
      "kana": "てがみ ",
      "kanji": "手紙 ",
      "type": "n ",
      "meaning": "letter"
    },
    {
      "id": 433,
      "kana": "できる ",
      "kanji": "",
      "type": "ru-v ",
      "meaning": "to be able to"
    },
    {
      "id": 434,
      "kana": "でぐち ",
      "kanji": "出口 ",
      "type": "n ",
      "meaning": "exit"
    },
    {
      "id": 435,
      "kana": "テスト ",
      "kanji": "",
      "type": "n,vs ",
      "meaning": "test"
    },
    {
      "id": 436,
      "kana": "では ",
      "kanji": "",
      "type": "expr ",
      "meaning": "with that..."
    },
    {
      "id": 437,
      "kana": "デパート ",
      "kanji": "",
      "type": "n ",
      "meaning": "department store"
    },
    {
      "id": 438,
      "kana": "でも ",
      "kanji": "",
      "type": "conj,prt ",
      "meaning": "but, however"
    },
    {
      "id": 439,
      "kana": "でる ",
      "kanji": "出る ",
      "type": "ru-v ",
      "meaning": "to appear, to leave"
    },
    {
      "id": 440,
      "kana": "テレビ ",
      "kanji": "",
      "type": "n ",
      "meaning": "television, TV"
    },
    {
      "id": 441,
      "kana": "てんき ",
      "kanji": "天気 ",
      "type": "n ",
      "meaning": "weather"
    },
    {
      "id": 442,
      "kana": "でんき ",
      "kanji": "電気 ",
      "type": "n ",
      "meaning": "electricity, (electric) light"
    },
    {
      "id": 443,
      "kana": "でんしゃ ",
      "kanji": "電車 ",
      "type": "n ",
      "meaning": "electric train"
    },
    {
      "id": 444,
      "kana": "でんわ ",
      "kanji": "電話 ",
      "type": "n,vs ",
      "meaning": "telephone"
    },
    {
      "id": 445,
      "kana": "と ",
      "kanji": "戸 ",
      "type": "n ",
      "meaning": "door (Japanese style)"
    },
    {
      "id": 446,
      "kana": "～ど ",
      "kanji": "～度 ",
      "type": "n-suf ",
      "meaning": "counter for occurrences"
    },
    {
      "id": 447,
      "kana": "ドア ",
      "kanji": "",
      "type": "n ",
      "meaning": "door (Western style)"
    },
    {
      "id": 448,
      "kana": "トイレ ",
      "kanji": "",
      "type": "n ",
      "meaning": "toilet"
    },
    {
      "id": 449,
      "kana": "どう ",
      "kanji": "",
      "type": "adv ",
      "meaning": "how, in what way"
    },
    {
      "id": 450,
      "kana": "どうして ",
      "kanji": "",
      "type": "adv,int ",
      "meaning": "why?, for what reason"
    },
    {
      "id": 451,
      "kana": "どうぞ ",
      "kanji": "",
      "type": "adv ",
      "meaning": "please, kindly, by all means"
    },
    {
      "id": 452,
      "kana": "どうぶつ ",
      "kanji": "動物 ",
      "type": "n ",
      "meaning": "animal"
    },
    {
      "id": 453,
      "kana": "どうも ",
      "kanji": "",
      "type": "adv,int ",
      "meaning": "thanks, very"
    },
    {
      "id": 454,
      "kana": "とお ",
      "kanji": "十 ",
      "type": "num ",
      "meaning": "ten"
    },
    {
      "id": 455,
      "kana": "とおい ",
      "kanji": "遠い ",
      "type": "adj ",
      "meaning": "far, distant"
    },
    {
      "id": 456,
      "kana": "とおか ",
      "kanji": "十日 ",
      "type": "",
      "meaning": "ten days, the tenth (day of the month)"
    },
    {
      "id": 457,
      "kana": "～とき ",
      "kanji": "～時 ",
      "type": "adv,n ",
      "meaning": "at the time of ～"
    },
    {
      "id": 458,
      "kana": "ときどき ",
      "kanji": "時々 ",
      "type": "adv,n ",
      "meaning": "sometimes"
    },
    {
      "id": 459,
      "kana": "とけい ",
      "kanji": "時計 ",
      "type": "n ",
      "meaning": "watch, clock"
    },
    {
      "id": 460,
      "kana": "どこ ",
      "kanji": "",
      "type": "n ",
      "meaning": "where, what place"
    },
    {
      "id": 461,
      "kana": "ところ ",
      "kanji": "所 ",
      "type": "n ",
      "meaning": "place"
    },
    {
      "id": 462,
      "kana": "とし ",
      "kanji": "年 ",
      "type": "n ",
      "meaning": "year, age"
    },
    {
      "id": 463,
      "kana": "としょかん ",
      "kanji": "図書館 ",
      "type": "n ",
      "meaning": "library"
    },
    {
      "id": 464,
      "kana": "どちら ",
      "kanji": "",
      "type": "n ",
      "meaning": "which, which way"
    },
    {
      "id": 465,
      "kana": "どっち ",
      "kanji": "",
      "type": "n ",
      "meaning": "which one, which way"
    },
    {
      "id": 466,
      "kana": "とても ",
      "kanji": "",
      "type": "adv ",
      "meaning": "very"
    },
    {
      "id": 467,
      "kana": "どなた ",
      "kanji": "",
      "type": "n ",
      "meaning": "who"
    },
    {
      "id": 468,
      "kana": "となり ",
      "kanji": "隣 ",
      "type": "n ",
      "meaning": "next to, next door to"
    },
    {
      "id": 469,
      "kana": "どの ",
      "kanji": "",
      "type": "pren-adj ",
      "meaning": "which"
    },
    {
      "id": 470,
      "kana": "とぶ ",
      "kanji": "飛ぶ ",
      "type": "u-v ",
      "meaning": "to fly, to hop"
    },
    {
      "id": 471,
      "kana": "とまる ",
      "kanji": "止まる ",
      "type": "u-v ",
      "meaning": "to come to a halt"
    },
    {
      "id": 472,
      "kana": "ともだち ",
      "kanji": "友達 ",
      "type": "n ",
      "meaning": "friend"
    },
    {
      "id": 473,
      "kana": "どようび ",
      "kanji": "土曜日 ",
      "type": "n-adv,n ",
      "meaning": "Saturday"
    },
    {
      "id": 474,
      "kana": "とり ",
      "kanji": "鳥 ",
      "type": "n ",
      "meaning": "bird"
    },
    {
      "id": 475,
      "kana": "とりにく ",
      "kanji": "とり肉 ",
      "type": "n ",
      "meaning": "chicken meat"
    },
    {
      "id": 476,
      "kana": "とる ",
      "kanji": "取る ",
      "type": "u-v ",
      "meaning": "to take"
    },
    {
      "id": 477,
      "kana": "とる ",
      "kanji": "撮る ",
      "type": "u-v ",
      "meaning": "to take (a photo)"
    },
    {
      "id": 478,
      "kana": "どれ ",
      "kanji": "",
      "type": "n ",
      "meaning": "which (of three or more)"
    },
    {
      "id": 479,
      "kana": "どんな ",
      "kanji": "",
      "type": "na-adj,adj-pn,n ",
      "meaning": "what, what kind of"
    },
    {
      "id": 480,
      "kana": "ない ",
      "kanji": "",
      "type": "adj ",
      "meaning": "there isn't, doesn't have"
    },
    {
      "id": 481,
      "kana": "ナイフ ",
      "kanji": "",
      "type": "n ",
      "meaning": "knife"
    },
    {
      "id": 482,
      "kana": "なか ",
      "kanji": "中 ",
      "type": "n ",
      "meaning": "inside, middle, among"
    },
    {
      "id": 483,
      "kana": "ながい ",
      "kanji": "長い ",
      "type": "adj ",
      "meaning": "long"
    },
    {
      "id": 484,
      "kana": "～ながら ",
      "kanji": "",
      "type": "prt ",
      "meaning": "while ~ing, during, although"
    },
    {
      "id": 485,
      "kana": "なく ",
      "kanji": "鳴く ",
      "type": "u-v ",
      "meaning": "to sing (bird), to make sound (animal)"
    },
    {
      "id": 486,
      "kana": "なくす ",
      "kanji": "無くす ",
      "type": "u-v ",
      "meaning": "to lose something"
    },
    {
      "id": 487,
      "kana": "なぜ ",
      "kanji": "",
      "type": "adv ",
      "meaning": "why"
    },
    {
      "id": 488,
      "kana": "なつ ",
      "kanji": "夏 ",
      "type": "n-adv,n-t ",
      "meaning": "summer"
    },
    {
      "id": 489,
      "kana": "なつやすみ ",
      "kanji": "夏休み ",
      "type": "n ",
      "meaning": "summer vacation, summer holiday"
    },
    {
      "id": 490,
      "kana": "～など ",
      "kanji": "",
      "type": "n,n-suf,prt ",
      "meaning": "et cetera"
    },
    {
      "id": 491,
      "kana": "ななつ ",
      "kanji": "七つ ",
      "type": "n ",
      "meaning": "seven"
    },
    {
      "id": 492,
      "kana": "なん/なに ",
      "kanji": "何 ",
      "type": "int,n ",
      "meaning": "what"
    },
    {
      "id": 493,
      "kana": "なのか ",
      "kanji": "七日 ",
      "type": "n-adv ",
      "meaning": "seven days, the seventh day (of the month)"
    },
    {
      "id": 494,
      "kana": "なまえ ",
      "kanji": "名前 ",
      "type": "n ",
      "meaning": "name"
    },
    {
      "id": 495,
      "kana": "ならう ",
      "kanji": "習う ",
      "type": "u-v ",
      "meaning": "to learn"
    },
    {
      "id": 496,
      "kana": "ならぶ ",
      "kanji": "並ぶ ",
      "type": "u-v,vi ",
      "meaning": "to line up, to stand in a line"
    },
    {
      "id": 497,
      "kana": "ならべる ",
      "kanji": "並べる ",
      "type": "ru-v,vt ",
      "meaning": "to line up, to set up"
    },
    {
      "id": 498,
      "kana": "なる ",
      "kanji": "",
      "type": "u-v ",
      "meaning": "to become"
    },
    {
      "id": 499,
      "kana": "なん～ ",
      "kanji": "何～ ",
      "type": "",
      "meaning": "what sort of ～"
    },
    {
      "id": 500,
      "kana": "に ",
      "kanji": "二 ",
      "type": "num ",
      "meaning": "two"
    },
    {
      "id": 501,
      "kana": "にぎやか ",
      "kanji": "",
      "type": "na-adj,n ",
      "meaning": "bustling, busy"
    },
    {
      "id": 502,
      "kana": "にく ",
      "kanji": "肉 ",
      "type": "n ",
      "meaning": "meat"
    },
    {
      "id": 503,
      "kana": "にし ",
      "kanji": "西 ",
      "type": "n ",
      "meaning": "west"
    },
    {
      "id": 504,
      "kana": "～にち ",
      "kanji": "～日 ",
      "type": "",
      "meaning": "～ day of the month, for ～ days"
    },
    {
      "id": 505,
      "kana": "にちようび ",
      "kanji": "日曜日 ",
      "type": "n-adv,n ",
      "meaning": "Sunday"
    },
    {
      "id": 506,
      "kana": "にもつ ",
      "kanji": "荷物 ",
      "type": "n ",
      "meaning": "luggage"
    },
    {
      "id": 507,
      "kana": "ニュース ",
      "kanji": "",
      "type": "n ",
      "meaning": "news"
    },
    {
      "id": 508,
      "kana": "にわ ",
      "kanji": "庭 ",
      "type": "n ",
      "meaning": "garden"
    },
    {
      "id": 509,
      "kana": "～にん ",
      "kanji": "～人 ",
      "type": "",
      "meaning": "counter for people"
    },
    {
      "id": 510,
      "kana": "ぬぐ ",
      "kanji": "脱ぐ ",
      "type": "u-v ",
      "meaning": "to take off clothes"
    },
    {
      "id": 511,
      "kana": "ぬるい ",
      "kanji": "温い ",
      "type": "adj ",
      "meaning": "lukewarm"
    },
    {
      "id": 512,
      "kana": "ネクタイ ",
      "kanji": "",
      "type": "n ",
      "meaning": "tie, necktie"
    },
    {
      "id": 513,
      "kana": "ねこ ",
      "kanji": "猫 ",
      "type": "n ",
      "meaning": "cat"
    },
    {
      "id": 514,
      "kana": "ねる ",
      "kanji": "寝る ",
      "type": "ru-v ",
      "meaning": "to go to bed, to sleep"
    },
    {
      "id": 515,
      "kana": "～ねん ",
      "kanji": "～年 ",
      "type": "",
      "meaning": "～ years"
    },
    {
      "id": 516,
      "kana": "ノート ",
      "kanji": "",
      "type": "n ",
      "meaning": "notebook, exercise book"
    },
    {
      "id": 517,
      "kana": "のぼる ",
      "kanji": "登る ",
      "type": "u-v ",
      "meaning": "to climb"
    },
    {
      "id": 518,
      "kana": "のみもの ",
      "kanji": "飲み物 ",
      "type": "n ",
      "meaning": "drink, beverage"
    },
    {
      "id": 519,
      "kana": "のむ ",
      "kanji": "飲む ",
      "type": "u-v ",
      "meaning": "to drink"
    },
    {
      "id": 520,
      "kana": "のる ",
      "kanji": "乗る ",
      "type": "u-v ",
      "meaning": "to get on, to ride in, to board"
    },
    {
      "id": 521,
      "kana": "は ",
      "kanji": "歯 ",
      "type": "n ",
      "meaning": "tooth"
    },
    {
      "id": 522,
      "kana": "パーティー ",
      "kanji": "",
      "type": "n ",
      "meaning": "party"
    },
    {
      "id": 523,
      "kana": "はい ",
      "kanji": "",
      "type": "conj,int ",
      "meaning": "yes"
    },
    {
      "id": 524,
      "kana": "～はい ",
      "kanji": "～杯 ",
      "type": "n ",
      "meaning": "counter for cupfuls"
    },
    {
      "id": 525,
      "kana": "はいざら ",
      "kanji": "灰皿 ",
      "type": "n ",
      "meaning": "ashtray"
    },
    {
      "id": 526,
      "kana": "はいる ",
      "kanji": "入る ",
      "type": "u-v ",
      "meaning": "to enter, to contain, to hold"
    },
    {
      "id": 527,
      "kana": "はがき ",
      "kanji": "葉書 ",
      "type": "n ",
      "meaning": "postcard"
    },
    {
      "id": 528,
      "kana": "はく ",
      "kanji": "",
      "type": "u-v ",
      "meaning": "to wear, to put on (trousers)"
    },
    {
      "id": 529,
      "kana": "はこ ",
      "kanji": "箱 ",
      "type": "n ",
      "meaning": "box"
    },
    {
      "id": 530,
      "kana": "はし ",
      "kanji": "橋 ",
      "type": "n ",
      "meaning": "bridge"
    },
    {
      "id": 531,
      "kana": "はし ",
      "kanji": "",
      "type": "n ",
      "meaning": "chopsticks"
    },
    {
      "id": 532,
      "kana": "はじまる ",
      "kanji": "始まる ",
      "type": "u-v,vi ",
      "meaning": "to begin"
    },
    {
      "id": 533,
      "kana": "はじめ ",
      "kanji": "初め/始め ",
      "type": "expr ",
      "meaning": "beginning, start"
    },
    {
      "id": 534,
      "kana": "はじめて ",
      "kanji": "初めて ",
      "type": "adv,n ",
      "meaning": "for the first time"
    },
    {
      "id": 535,
      "kana": "はしる ",
      "kanji": "走る ",
      "type": "u-v ",
      "meaning": "to run"
    },
    {
      "id": 536,
      "kana": "バス ",
      "kanji": "",
      "type": "n ",
      "meaning": "bus"
    },
    {
      "id": 537,
      "kana": "バター ",
      "kanji": "",
      "type": "n ",
      "meaning": "butter"
    },
    {
      "id": 538,
      "kana": "はたち ",
      "kanji": "二十歳 ",
      "type": "n ",
      "meaning": "20 years old, 20th year"
    },
    {
      "id": 539,
      "kana": "はたらく ",
      "kanji": "働く ",
      "type": "u-v ",
      "meaning": "to work"
    },
    {
      "id": 540,
      "kana": "はち ",
      "kanji": "八 ",
      "type": "num ",
      "meaning": "eight"
    },
    {
      "id": 541,
      "kana": "はつか ",
      "kanji": "二十日 ",
      "type": "n ",
      "meaning": "twenty days, twentieth (day of the month)"
    },
    {
      "id": 542,
      "kana": "はな ",
      "kanji": "花 ",
      "type": "n ",
      "meaning": "flower"
    },
    {
      "id": 543,
      "kana": "はな ",
      "kanji": "鼻 ",
      "type": "n ",
      "meaning": "nose"
    },
    {
      "id": 544,
      "kana": "はなし ",
      "kanji": "話 ",
      "type": "n ",
      "meaning": "talk, story"
    },
    {
      "id": 545,
      "kana": "はなす ",
      "kanji": "話す ",
      "type": "u-v ",
      "meaning": "to speak"
    },
    {
      "id": 546,
      "kana": "はは ",
      "kanji": "母 ",
      "type": "n ",
      "meaning": "(one's own) mother"
    },
    {
      "id": 547,
      "kana": "はやい ",
      "kanji": "早い ",
      "type": "adj ",
      "meaning": "early"
    },
    {
      "id": 548,
      "kana": "はやい ",
      "kanji": "速い ",
      "type": "adj ",
      "meaning": "quick, fast"
    },
    {
      "id": 549,
      "kana": "はる ",
      "kanji": "春 ",
      "type": "n-adv,n-t ",
      "meaning": "spring"
    },
    {
      "id": 550,
      "kana": "はる ",
      "kanji": "貼る ",
      "type": "u-v ",
      "meaning": "to stick, to paste"
    },
    {
      "id": 551,
      "kana": "はれ ",
      "kanji": "晴れ ",
      "type": "ru-v ",
      "meaning": "clear weather"
    },
    {
      "id": 552,
      "kana": "はれる ",
      "kanji": "晴れる ",
      "type": "ru-v ",
      "meaning": "to be sunny"
    },
    {
      "id": 553,
      "kana": "はん ",
      "kanji": "半 ",
      "type": "n-adv,n,n-suf ",
      "meaning": "half"
    },
    {
      "id": 554,
      "kana": "ばん ",
      "kanji": "晩 ",
      "type": "n-adv,n-t ",
      "meaning": "evening"
    },
    {
      "id": 555,
      "kana": "～ばん ",
      "kanji": "～番 ",
      "type": "n-adv,n-t ",
      "meaning": "～st/th best"
    },
    {
      "id": 556,
      "kana": "パン ",
      "kanji": "",
      "type": "n ",
      "meaning": "bread"
    },
    {
      "id": 557,
      "kana": "ハンカチ ",
      "kanji": "",
      "type": "n ",
      "meaning": "handkerchief"
    },
    {
      "id": 558,
      "kana": "ばんごう ",
      "kanji": "番号 ",
      "type": "n ",
      "meaning": "number"
    },
    {
      "id": 559,
      "kana": "ばんごはん ",
      "kanji": "晩御飯 ",
      "type": "n ",
      "meaning": "dinner, evening meal"
    },
    {
      "id": 560,
      "kana": "はんぶん ",
      "kanji": "半分 ",
      "type": "",
      "meaning": "half"
    },
    {
      "id": 561,
      "kana": "ひがし ",
      "kanji": "東 ",
      "type": "n ",
      "meaning": "east"
    },
    {
      "id": 562,
      "kana": "～ひき ",
      "kanji": "～匹 ",
      "type": "",
      "meaning": "counter for small animals"
    },
    {
      "id": 563,
      "kana": "ひく ",
      "kanji": "引く ",
      "type": "u-v ",
      "meaning": "to pull"
    },
    {
      "id": 564,
      "kana": "ひく ",
      "kanji": "弾く ",
      "type": "u-v ",
      "meaning": "to play (piano, guitar)"
    },
    {
      "id": 565,
      "kana": "ひくい ",
      "kanji": "低い ",
      "type": "adj ",
      "meaning": "short, low"
    },
    {
      "id": 566,
      "kana": "ひこうき ",
      "kanji": "飛行機 ",
      "type": "n ",
      "meaning": "aeroplane, airplane"
    },
    {
      "id": 567,
      "kana": "ひだり ",
      "kanji": "左 ",
      "type": "n ",
      "meaning": "left hand side"
    },
    {
      "id": 568,
      "kana": "ひと ",
      "kanji": "人 ",
      "type": "n ",
      "meaning": "man, person"
    },
    {
      "id": 569,
      "kana": "ひとつ ",
      "kanji": "一つ ",
      "type": "n ",
      "meaning": "one"
    },
    {
      "id": 570,
      "kana": "ひとつき ",
      "kanji": "一月 ",
      "type": "",
      "meaning": "one month"
    },
    {
      "id": 571,
      "kana": "ひとり ",
      "kanji": "一人 ",
      "type": "n ",
      "meaning": "one person"
    },
    {
      "id": 572,
      "kana": "ひま ",
      "kanji": "暇 ",
      "type": "na-adj,n ",
      "meaning": "free time, leisure"
    },
    {
      "id": 573,
      "kana": "ひゃく ",
      "kanji": "百 ",
      "type": "num ",
      "meaning": "100, hundred"
    },
    {
      "id": 574,
      "kana": "びょういん ",
      "kanji": "病院 ",
      "type": "n ",
      "meaning": "hospital"
    },
    {
      "id": 575,
      "kana": "びょうき ",
      "kanji": "病気 ",
      "type": "n ",
      "meaning": "illness, disease, sickness"
    },
    {
      "id": 576,
      "kana": "ひらがな ",
      "kanji": "平仮名 ",
      "type": "n ",
      "meaning": "hiragana"
    },
    {
      "id": 577,
      "kana": "ひる ",
      "kanji": "昼 ",
      "type": "n-adv,n-t ",
      "meaning": "noon, daytime"
    },
    {
      "id": 578,
      "kana": "ひるごはん ",
      "kanji": "昼御飯 ",
      "type": "n ",
      "meaning": "lunch, midday meal"
    },
    {
      "id": 579,
      "kana": "ひろい ",
      "kanji": "広い ",
      "type": "adj ",
      "meaning": "spacious, wide"
    },
    {
      "id": 580,
      "kana": "フィルム ",
      "kanji": "",
      "type": "n ",
      "meaning": "film (roll of)"
    },
    {
      "id": 581,
      "kana": "ふうとう ",
      "kanji": "封筒 ",
      "type": "n ",
      "meaning": "envelope"
    },
    {
      "id": 582,
      "kana": "プール ",
      "kanji": "",
      "type": "n ",
      "meaning": "swimming pool"
    },
    {
      "id": 583,
      "kana": "フォーク ",
      "kanji": "",
      "type": "n ",
      "meaning": "fork"
    },
    {
      "id": 584,
      "kana": "ふく ",
      "kanji": "吹く ",
      "type": "u-v ",
      "meaning": "to blow (wind, etc)"
    },
    {
      "id": 585,
      "kana": "ふく ",
      "kanji": "服 ",
      "type": "n,n-suf ",
      "meaning": "clothes"
    },
    {
      "id": 586,
      "kana": "ふたつ ",
      "kanji": "二つ ",
      "type": "n ",
      "meaning": "two"
    },
    {
      "id": 587,
      "kana": "ぶたにく ",
      "kanji": "豚肉 ",
      "type": "n ",
      "meaning": "pork"
    },
    {
      "id": 588,
      "kana": "ふたり ",
      "kanji": "二人 ",
      "type": "n ",
      "meaning": "two people"
    },
    {
      "id": 589,
      "kana": "ふつか ",
      "kanji": "二日 ",
      "type": "n ",
      "meaning": "second day of the month, two days"
    },
    {
      "id": 590,
      "kana": "ふとい ",
      "kanji": "太い ",
      "type": "adj ",
      "meaning": "fat, thick"
    },
    {
      "id": 591,
      "kana": "ふゆ ",
      "kanji": "冬 ",
      "type": "n-adv,n-t ",
      "meaning": "winter"
    },
    {
      "id": 592,
      "kana": "ふる ",
      "kanji": "降る ",
      "type": "u-v ",
      "meaning": "to precipitate, to fall (e.g. rain)"
    },
    {
      "id": 593,
      "kana": "ふるい ",
      "kanji": "古い ",
      "type": "adj ",
      "meaning": "old (not person), aged, ancient"
    },
    {
      "id": 594,
      "kana": "ふろ ",
      "kanji": "",
      "type": "n ",
      "meaning": "bath"
    },
    {
      "id": 595,
      "kana": "～ふん ",
      "kanji": "～分 ",
      "type": "suf ",
      "meaning": "～minutes"
    },
    {
      "id": 596,
      "kana": "ぶんしょう ",
      "kanji": "文章 ",
      "type": "suf ",
      "meaning": "sentence, text"
    },
    {
      "id": 597,
      "kana": "ページ ",
      "kanji": "",
      "type": "n ",
      "meaning": "page"
    },
    {
      "id": 598,
      "kana": "へた ",
      "kanji": "下手 ",
      "type": "na-adj,n ",
      "meaning": "unskillful, poor"
    },
    {
      "id": 599,
      "kana": "ベッド ",
      "kanji": "",
      "type": "n ",
      "meaning": "bed"
    },
    {
      "id": 600,
      "kana": "ペット ",
      "kanji": "",
      "type": "n ",
      "meaning": "pet"
    },
    {
      "id": 601,
      "kana": "へや ",
      "kanji": "部屋 ",
      "type": "n ",
      "meaning": "room"
    },
    {
      "id": 602,
      "kana": "へん ",
      "kanji": "辺 ",
      "type": "n ",
      "meaning": "area, vicinity"
    },
    {
      "id": 603,
      "kana": "ペン ",
      "kanji": "",
      "type": "n ",
      "meaning": "pen"
    },
    {
      "id": 604,
      "kana": "べんきょう（する） ",
      "kanji": "勉強 ",
      "type": "n,vs ",
      "meaning": "study, diligence"
    },
    {
      "id": 605,
      "kana": "べんり ",
      "kanji": "便利 ",
      "type": "na-adj,n ",
      "meaning": "convenient, handy"
    },
    {
      "id": 606,
      "kana": "ほう ",
      "kanji": "",
      "type": "",
      "meaning": "way"
    },
    {
      "id": 607,
      "kana": "ぼうし ",
      "kanji": "帽子 ",
      "type": "n ",
      "meaning": "hat"
    },
    {
      "id": 608,
      "kana": "ボールペン ",
      "kanji": "",
      "type": "n ",
      "meaning": "ball-point pen"
    },
    {
      "id": 609,
      "kana": "ほか ",
      "kanji": "外 ",
      "type": "n ",
      "meaning": "other place, the rest"
    },
    {
      "id": 610,
      "kana": "ポケット ",
      "kanji": "",
      "type": "n ",
      "meaning": "pocket"
    },
    {
      "id": 611,
      "kana": "ほしい ",
      "kanji": "欲しい ",
      "type": "adj ",
      "meaning": "want, in need of, desire"
    },
    {
      "id": 612,
      "kana": "ポスト ",
      "kanji": "",
      "type": "n ",
      "meaning": "post"
    },
    {
      "id": 613,
      "kana": "ほそい ",
      "kanji": "細い ",
      "type": "adj ",
      "meaning": "thin, slender, fine"
    },
    {
      "id": 614,
      "kana": "ボタン ",
      "kanji": "",
      "type": "n ",
      "meaning": "button"
    },
    {
      "id": 615,
      "kana": "ホテル ",
      "kanji": "",
      "type": "n ",
      "meaning": "hotel"
    },
    {
      "id": 616,
      "kana": "ほん ",
      "kanji": "本 ",
      "type": "n ",
      "meaning": "book"
    },
    {
      "id": 617,
      "kana": "～ほん ",
      "kanji": "～本 ",
      "type": "",
      "meaning": "counter for long cylindrical things"
    },
    {
      "id": 618,
      "kana": "ほんだな ",
      "kanji": "本棚 ",
      "type": "n ",
      "meaning": "bookshelves"
    },
    {
      "id": 619,
      "kana": "ほんとう ",
      "kanji": "",
      "type": "adv ",
      "meaning": "reality, truth"
    },
    {
      "id": 620,
      "kana": "～まい ",
      "kanji": "～枚 ",
      "type": "",
      "meaning": "counter for flat things"
    },
    {
      "id": 621,
      "kana": "まいあさ ",
      "kanji": "毎朝 ",
      "type": "n-adv,n-t ",
      "meaning": "every morning"
    },
    {
      "id": 622,
      "kana": "まいげつ/まいつき ",
      "kanji": "毎月 ",
      "type": "n-adv,n ",
      "meaning": "every month, monthly"
    },
    {
      "id": 623,
      "kana": "まいしゅう ",
      "kanji": "毎週 ",
      "type": "n-adv,n-t ",
      "meaning": "every week"
    },
    {
      "id": 624,
      "kana": "まいにち ",
      "kanji": "毎日 ",
      "type": "n-adv,n-t ",
      "meaning": "every day"
    },
    {
      "id": 625,
      "kana": "まいねん/まいとし ",
      "kanji": "毎年 ",
      "type": "n-t ",
      "meaning": "every year, annually"
    },
    {
      "id": 626,
      "kana": "まいばん ",
      "kanji": "毎晩 ",
      "type": "n-adv,n-t ",
      "meaning": "every night"
    },
    {
      "id": 627,
      "kana": "まえ ",
      "kanji": "前 ",
      "type": "n-adv,n-t,suf ",
      "meaning": "before, in front"
    },
    {
      "id": 628,
      "kana": "～まえ ",
      "kanji": "～前 ",
      "type": "suf ",
      "meaning": "in front of ～"
    },
    {
      "id": 629,
      "kana": "まがる ",
      "kanji": "曲る ",
      "type": "u-v ",
      "meaning": "to turn, to bend"
    },
    {
      "id": 630,
      "kana": "まずい ",
      "kanji": "",
      "type": "adj ",
      "meaning": "unappetising, unpleasant (taste)"
    },
    {
      "id": 631,
      "kana": "また ",
      "kanji": "",
      "type": "adv,conj,n ",
      "meaning": "again, and"
    },
    {
      "id": 632,
      "kana": "まだ ",
      "kanji": "",
      "type": "adv ",
      "meaning": "yet, still, besides"
    },
    {
      "id": 633,
      "kana": "まち ",
      "kanji": "町 ",
      "type": "n ",
      "meaning": "town, city"
    },
    {
      "id": 634,
      "kana": "まつ ",
      "kanji": "待つ ",
      "type": "u-v ",
      "meaning": "to wait"
    },
    {
      "id": 635,
      "kana": "まっすぐ ",
      "kanji": "",
      "type": "na-adj,adv,n ",
      "meaning": "straight (ahead), direct"
    },
    {
      "id": 636,
      "kana": "マッチ ",
      "kanji": "",
      "type": "n ",
      "meaning": "match"
    },
    {
      "id": 637,
      "kana": "まど ",
      "kanji": "窓 ",
      "type": "n ",
      "meaning": "window"
    },
    {
      "id": 638,
      "kana": "まるい ",
      "kanji": "丸い/円い ",
      "type": "adj ",
      "meaning": "round, circular"
    },
    {
      "id": 639,
      "kana": "まん ",
      "kanji": "万 ",
      "type": "adv,num ",
      "meaning": "ten thousand, everything"
    },
    {
      "id": 640,
      "kana": "まんねんひつ ",
      "kanji": "万年筆 ",
      "type": "n ",
      "meaning": "fountain pen"
    },
    {
      "id": 641,
      "kana": "みがく ",
      "kanji": "磨く ",
      "type": "u-v ",
      "meaning": "to brush (teeth)"
    },
    {
      "id": 642,
      "kana": "みぎ ",
      "kanji": "右 ",
      "type": "n ",
      "meaning": "right hand side"
    },
    {
      "id": 643,
      "kana": "みじかい ",
      "kanji": "短い ",
      "type": "adj ",
      "meaning": "short"
    },
    {
      "id": 644,
      "kana": "みず ",
      "kanji": "水 ",
      "type": "n ",
      "meaning": "water"
    },
    {
      "id": 645,
      "kana": "みせ ",
      "kanji": "店 ",
      "type": "n,n-suf ",
      "meaning": "store, shop, establishment"
    },
    {
      "id": 646,
      "kana": "みせる ",
      "kanji": "見せる ",
      "type": "ru-v ",
      "meaning": "to show, to display"
    },
    {
      "id": 647,
      "kana": "みち ",
      "kanji": "道 ",
      "type": "n ",
      "meaning": "road, street"
    },
    {
      "id": 648,
      "kana": "みっか ",
      "kanji": "三日 ",
      "type": "n ",
      "meaning": "three days, the third day (of the month)"
    },
    {
      "id": 649,
      "kana": "みっつ ",
      "kanji": "三つ ",
      "type": "n ",
      "meaning": "three"
    },
    {
      "id": 650,
      "kana": "みどり ",
      "kanji": "緑 ",
      "type": "n ",
      "meaning": "green"
    },
    {
      "id": 651,
      "kana": "みなさん ",
      "kanji": "皆さん ",
      "type": "n ",
      "meaning": "everyone"
    },
    {
      "id": 652,
      "kana": "みなみ ",
      "kanji": "南 ",
      "type": "n,vs ",
      "meaning": "South, proceeding south"
    },
    {
      "id": 653,
      "kana": "みみ ",
      "kanji": "耳 ",
      "type": "n ",
      "meaning": "ear"
    },
    {
      "id": 654,
      "kana": "みる ",
      "kanji": "見る ",
      "type": "ru-v ",
      "meaning": "to see, to watch"
    },
    {
      "id": 655,
      "kana": "みんな ",
      "kanji": "",
      "type": "adv,n ",
      "meaning": "all, everyone, everybody"
    },
    {
      "id": 656,
      "kana": "むいか ",
      "kanji": "六日 ",
      "type": "",
      "meaning": "six days, sixth (day of month)"
    },
    {
      "id": 657,
      "kana": "むこう ",
      "kanji": "向こう ",
      "type": "n ",
      "meaning": "beyond, over there"
    },
    {
      "id": 658,
      "kana": "むずかしい ",
      "kanji": "難しい ",
      "type": "adj ",
      "meaning": "difficult"
    },
    {
      "id": 659,
      "kana": "むっつ ",
      "kanji": "六つ ",
      "type": "num ",
      "meaning": "six"
    },
    {
      "id": 660,
      "kana": "むら ",
      "kanji": "村 ",
      "type": "num ",
      "meaning": "village"
    },
    {
      "id": 661,
      "kana": "め ",
      "kanji": "目 ",
      "type": "n ",
      "meaning": "eye, eyeball"
    },
    {
      "id": 662,
      "kana": "メートル ",
      "kanji": "",
      "type": "n ",
      "meaning": "metre, meter"
    },
    {
      "id": 663,
      "kana": "めがね ",
      "kanji": "眼鏡 ",
      "type": "n ",
      "meaning": "spectacles, glasses"
    },
    {
      "id": 664,
      "kana": "もう ",
      "kanji": "",
      "type": "adv,int ",
      "meaning": "already"
    },
    {
      "id": 665,
      "kana": "もう ",
      "kanji": "",
      "type": "adv,int ",
      "meaning": "again"
    },
    {
      "id": 666,
      "kana": "もくようび ",
      "kanji": "木曜日 ",
      "type": "n-adv,n ",
      "meaning": "Thursday"
    },
    {
      "id": 667,
      "kana": "もしもし ",
      "kanji": "",
      "type": "conj,int ",
      "meaning": "hello (on phone)"
    },
    {
      "id": 668,
      "kana": "もつ ",
      "kanji": "持つ ",
      "type": "u-v ",
      "meaning": "(1) to hold, to carry, (2) to possess"
    },
    {
      "id": 669,
      "kana": "もっと ",
      "kanji": "",
      "type": "adv ",
      "meaning": "more, longer, farther"
    },
    {
      "id": 670,
      "kana": "もの ",
      "kanji": "物 ",
      "type": "n ",
      "meaning": "thing, object"
    },
    {
      "id": 671,
      "kana": "もん ",
      "kanji": "門 ",
      "type": "n,n-suf ",
      "meaning": "gate"
    },
    {
      "id": 672,
      "kana": "もんだい ",
      "kanji": "問題 ",
      "type": "n ",
      "meaning": "problem, question"
    },
    {
      "id": 673,
      "kana": "～や ",
      "kanji": "屋 ",
      "type": "n ",
      "meaning": "～ shop"
    },
    {
      "id": 674,
      "kana": "やおや ",
      "kanji": "八百屋 ",
      "type": "n ",
      "meaning": "greengrocer"
    },
    {
      "id": 675,
      "kana": "やさい ",
      "kanji": "野菜 ",
      "type": "n ",
      "meaning": "vegetable"
    },
    {
      "id": 676,
      "kana": "やさしい ",
      "kanji": "易しい ",
      "type": "adj ",
      "meaning": "easy, plain, simple"
    },
    {
      "id": 677,
      "kana": "やすい ",
      "kanji": "安い ",
      "type": "adj ",
      "meaning": "cheap, inexpensive"
    },
    {
      "id": 678,
      "kana": "やすみ ",
      "kanji": "休み ",
      "type": "n ",
      "meaning": "rest, vacation, holiday"
    },
    {
      "id": 679,
      "kana": "やすむ ",
      "kanji": "休む ",
      "type": "u-v,vi ",
      "meaning": "to rest, to have a break, to take a day off"
    },
    {
      "id": 680,
      "kana": "やっつ ",
      "kanji": "八つ ",
      "type": "num ",
      "meaning": "eight"
    },
    {
      "id": 681,
      "kana": "やま ",
      "kanji": "山 ",
      "type": "n ",
      "meaning": "mountain"
    },
    {
      "id": 682,
      "kana": "やる ",
      "kanji": "",
      "type": "u-v ",
      "meaning": "to do"
    },
    {
      "id": 683,
      "kana": "ゆうがた ",
      "kanji": "夕方 ",
      "type": "n-adv,n-t ",
      "meaning": "evening"
    },
    {
      "id": 684,
      "kana": "ゆうはん ",
      "kanji": "夕飯 ",
      "type": "n ",
      "meaning": "dinner"
    },
    {
      "id": 685,
      "kana": "ゆうびんきょく ",
      "kanji": "郵便局 ",
      "type": "n ",
      "meaning": "post office"
    },
    {
      "id": 686,
      "kana": "ゆうべ ",
      "kanji": "昨夜 ",
      "type": "n ",
      "meaning": "last night"
    },
    {
      "id": 687,
      "kana": "ゆうめい ",
      "kanji": "有名 ",
      "type": "na-adj,n ",
      "meaning": "famous"
    },
    {
      "id": 688,
      "kana": "ゆき ",
      "kanji": "雪 ",
      "type": "n ",
      "meaning": "snow"
    },
    {
      "id": 689,
      "kana": "ゆっくり・と ",
      "kanji": "",
      "type": "adv,n ",
      "meaning": "slowly, at ease"
    },
    {
      "id": 690,
      "kana": "ようか ",
      "kanji": "八日 ",
      "type": "n ",
      "meaning": "eight days, the eighth (day of the month)"
    },
    {
      "id": 691,
      "kana": "ようふく ",
      "kanji": "洋服 ",
      "type": "n ",
      "meaning": "Western-style clothes"
    },
    {
      "id": 692,
      "kana": "よく ",
      "kanji": "",
      "type": "adv ",
      "meaning": "frequently, often"
    },
    {
      "id": 693,
      "kana": "よく ",
      "kanji": "",
      "type": "adv ",
      "meaning": "well, skillfully"
    },
    {
      "id": 694,
      "kana": "よこ ",
      "kanji": "横 ",
      "type": "n ",
      "meaning": "beside, side, width"
    },
    {
      "id": 695,
      "kana": "よっか ",
      "kanji": "四日 ",
      "type": "n ",
      "meaning": "(1) 4th day of month, (2) four days"
    },
    {
      "id": 696,
      "kana": "よっつ ",
      "kanji": "四つ ",
      "type": "n ",
      "meaning": "four"
    },
    {
      "id": 697,
      "kana": "よぶ ",
      "kanji": "呼ぶ ",
      "type": "u-v ",
      "meaning": "to call out, to invite"
    },
    {
      "id": 698,
      "kana": "よむ ",
      "kanji": "読む ",
      "type": "u-v ",
      "meaning": "to read"
    },
    {
      "id": 699,
      "kana": "よる ",
      "kanji": "夜 ",
      "type": "n ",
      "meaning": "evening, night"
    },
    {
      "id": 700,
      "kana": "よわい ",
      "kanji": "弱い ",
      "type": "adj ",
      "meaning": "weak, feable"
    },
    {
      "id": 701,
      "kana": "らいげつ ",
      "kanji": "来月 ",
      "type": "n-adv,n-t ",
      "meaning": "next month"
    },
    {
      "id": 702,
      "kana": "らいしゅう ",
      "kanji": "来週 ",
      "type": "",
      "meaning": "next week"
    },
    {
      "id": 703,
      "kana": "らいねん ",
      "kanji": "来年 ",
      "type": "n-adv,n-t ",
      "meaning": "next year"
    },
    {
      "id": 704,
      "kana": "ラジオ ",
      "kanji": "",
      "type": "n ",
      "meaning": "radio"
    },
    {
      "id": 705,
      "kana": "ラジカセ ",
      "kanji": "",
      "type": "n ",
      "meaning": "radio cassette player"
    },
    {
      "id": 706,
      "kana": "りっぱ ",
      "kanji": "",
      "type": "na-adj,n ",
      "meaning": "splendid, fine"
    },
    {
      "id": 707,
      "kana": "りゅうがくせい ",
      "kanji": "留学生 ",
      "type": "n ",
      "meaning": "overseas student"
    },
    {
      "id": 708,
      "kana": "りょうしん ",
      "kanji": "両親 ",
      "type": "n ",
      "meaning": "parents, both parents"
    },
    {
      "id": 709,
      "kana": "りょうり ",
      "kanji": "料理 ",
      "type": "n,vs ",
      "meaning": "cooking, cuisine"
    },
    {
      "id": 710,
      "kana": "りょこう ",
      "kanji": "旅行 ",
      "type": "n,vs ",
      "meaning": "travel, trip"
    },
    {
      "id": 711,
      "kana": "れい ",
      "kanji": "零 ",
      "type": "n ",
      "meaning": "zero, nought"
    },
    {
      "id": 712,
      "kana": "れいぞうこ ",
      "kanji": "冷蔵庫 ",
      "type": "n ",
      "meaning": "refrigerator"
    },
    {
      "id": 713,
      "kana": "レコード ",
      "kanji": "",
      "type": "n ",
      "meaning": "record"
    },
    {
      "id": 714,
      "kana": "レストラン ",
      "kanji": "",
      "type": "n ",
      "meaning": "restaurant"
    },
    {
      "id": 715,
      "kana": "れんしゅう（する） ",
      "kanji": "練習 ",
      "type": "n,vs ",
      "meaning": "(to) practice"
    },
    {
      "id": 716,
      "kana": "ろうか ",
      "kanji": "廊下 ",
      "type": "n ",
      "meaning": "corridor"
    },
    {
      "id": 717,
      "kana": "ろく ",
      "kanji": "六 ",
      "type": "num ",
      "meaning": "six"
    },
    {
      "id": 718,
      "kana": "ワイシャツ ",
      "kanji": "",
      "type": "n ",
      "meaning": "shirt (lit: white shirt), business shirt"
    },
    {
      "id": 719,
      "kana": "わかい ",
      "kanji": "若い ",
      "type": "adj ",
      "meaning": "young"
    },
    {
      "id": 720,
      "kana": "わかる ",
      "kanji": "分かる ",
      "type": "u-v ",
      "meaning": "to understand"
    },
    {
      "id": 721,
      "kana": "わすれる ",
      "kanji": "忘れる ",
      "type": "ru-v ",
      "meaning": "to forget"
    },
    {
      "id": 722,
      "kana": "わたし ",
      "kanji": "私 ",
      "type": "adj-no,n ",
      "meaning": "I, myself"
    },
    {
      "id": 723,
      "kana": "わたくし ",
      "kanji": "私 ",
      "type": "adj-no,n ",
      "meaning": "I, myself"
    },
    {
      "id": 724,
      "kana": "わたす ",
      "kanji": "渡す ",
      "type": "u-v ",
      "meaning": "to pass over, to hand over"
    },
    {
      "id": 725,
      "kana": "わたる ",
      "kanji": "渡る ",
      "type": "u-v ",
      "meaning": "to cross over, to go across"
    },
    {
      "id": 726,
      "kana": "わるい ",
      "kanji": "悪い ",
      "type": "adj ",
      "meaning": "bad, inferior "
    }
   ]
export const dataN4 = [
    {
      "id": 0,
      "kana": "あ",
      "kanji": "",
      "type": "int",
      "meaning": "Ah"
    },
    {
      "id": 1,
      "kana": "ああ",
      "kanji": "",
      "type": "int",
      "meaning": "like that, that way"
    },
    {
      "id": 2,
      "kana": "あいさつ・する",
      "kanji": "",
      "type": "n,vs",
      "meaning": "greeting"
    },
    {
      "id": 3,
      "kana": "あいだ",
      "kanji": "間",
      "type": "n",
      "meaning": "space, interval"
    },
    {
      "id": 4,
      "kana": "あう",
      "kanji": "合う",
      "type": "u-v",
      "meaning": "to fit, to match"
    },
    {
      "id": 5,
      "kana": "あかちゃん",
      "kanji": "",
      "type": "n",
      "meaning": "baby, infant"
    },
    {
      "id": 6,
      "kana": "あがる",
      "kanji": "上がる",
      "type": "u-v",
      "meaning": "to rise, to go up"
    },
    {
      "id": 7,
      "kana": "あかんぼう",
      "kanji": "赤ん坊",
      "type": "n",
      "meaning": "baby"
    },
    {
      "id": 8,
      "kana": "あく",
      "kanji": "空く",
      "type": "u-v,vi",
      "meaning": "to open, to become empty"
    },
    {
      "id": 9,
      "kana": "アクセサリー",
      "kanji": "",
      "type": "n",
      "meaning": "accessory"
    },
    {
      "id": 10,
      "kana": "あげる",
      "kanji": "",
      "type": "ru-v",
      "meaning": "to give"
    },
    {
      "id": 11,
      "kana": "あさい",
      "kanji": "浅い",
      "type": "adj",
      "meaning": "shallow, superficial"
    },
    {
      "id": 12,
      "kana": "あじ",
      "kanji": "味",
      "type": "adj-na,n",
      "meaning": "flavor, taste"
    },
    {
      "id": 13,
      "kana": "アジア",
      "kanji": "",
      "type": "n",
      "meaning": "Asia"
    },
    {
      "id": 14,
      "kana": "あす",
      "kanji": "明日",
      "type": "n-t",
      "meaning": "tomorrow"
    },
    {
      "id": 15,
      "kana": "あそび",
      "kanji": "遊び",
      "type": "n,n-suf",
      "meaning": "play"
    },
    {
      "id": 16,
      "kana": "あつまる",
      "kanji": "集る",
      "type": "u-v",
      "meaning": "to gather, to collect"
    },
    {
      "id": 17,
      "kana": "あつめる",
      "kanji": "集める",
      "type": "ru-v,vt",
      "meaning": "to collect, to assemble"
    },
    {
      "id": 18,
      "kana": "アナウンサー",
      "kanji": "",
      "type": "n",
      "meaning": "announcer"
    },
    {
      "id": 19,
      "kana": "アフリカ",
      "kanji": "",
      "type": "n",
      "meaning": "Africa"
    },
    {
      "id": 20,
      "kana": "アメリカ",
      "kanji": "",
      "type": "n",
      "meaning": "America"
    },
    {
      "id": 21,
      "kana": "あやまる",
      "kanji": "謝る",
      "type": "u-v",
      "meaning": "to apologize"
    },
    {
      "id": 22,
      "kana": "アルコール",
      "kanji": "",
      "type": "n",
      "meaning": "alcohol"
    },
    {
      "id": 23,
      "kana": "アルバイト",
      "kanji": "",
      "type": "n",
      "meaning": "part-time job"
    },
    {
      "id": 24,
      "kana": "あんしん",
      "kanji": "安心",
      "type": "adj-na,n, vs",
      "meaning": "peace of mind, relief"
    },
    {
      "id": 25,
      "kana": "あんぜん",
      "kanji": "安全",
      "type": "adj-na,n",
      "meaning": "safety, security"
    },
    {
      "id": 26,
      "kana": "あんな",
      "kanji": "",
      "type": "adj-na,adj-pn",
      "meaning": "such, like that"
    },
    {
      "id": 27,
      "kana": "あんない・する",
      "kanji": "案内",
      "type": "n,vs",
      "meaning": "information, guidance"
    },
    {
      "id": 28,
      "kana": "いか",
      "kanji": "以下",
      "type": "n",
      "meaning": "less than, below"
    },
    {
      "id": 29,
      "kana": "いがい",
      "kanji": "以外",
      "type": "n",
      "meaning": "with the exception of, excepting"
    },
    {
      "id": 30,
      "kana": "いがく",
      "kanji": "医学",
      "type": "n",
      "meaning": "medical science"
    },
    {
      "id": 31,
      "kana": "いきる",
      "kanji": "生きる",
      "type": "ru-v",
      "meaning": "to live, to exist"
    },
    {
      "id": 32,
      "kana": "いくら～ても",
      "kanji": "",
      "type": "",
      "meaning": "however much one may ～"
    },
    {
      "id": 33,
      "kana": "いけん",
      "kanji": "意見",
      "type": "n",
      "meaning": "opinion, view"
    },
    {
      "id": 34,
      "kana": "いし",
      "kanji": "石",
      "type": "n",
      "meaning": "stone"
    },
    {
      "id": 35,
      "kana": "いじめる",
      "kanji": "",
      "type": "ru-v",
      "meaning": "to tease, to torment"
    },
    {
      "id": 36,
      "kana": "いじょう",
      "kanji": "以上",
      "type": "n-adv,n-t",
      "meaning": "more than, this is all"
    },
    {
      "id": 37,
      "kana": "いそぐ",
      "kanji": "急ぐ",
      "type": "u-v",
      "meaning": "to hurry, to rush"
    },
    {
      "id": 38,
      "kana": "いたす",
      "kanji": "致す",
      "type": "u-v",
      "meaning": "(hum) to do"
    },
    {
      "id": 39,
      "kana": "いただく",
      "kanji": "",
      "type": "u-v",
      "meaning": "to receive, to take food or drink (hum)"
    },
    {
      "id": 40,
      "kana": "いちど",
      "kanji": "一度",
      "type": "n-adv",
      "meaning": "once, one time"
    },
    {
      "id": 41,
      "kana": "いっしょうけんめい",
      "kanji": "一生懸命",
      "type": "adj-na,n-adv,n",
      "meaning": "as well as one can, with utmost effort"
    },
    {
      "id": 42,
      "kana": "いっぱい",
      "kanji": "",
      "type": "adv",
      "meaning": "full, to the utmost"
    },
    {
      "id": 43,
      "kana": "いと",
      "kanji": "糸",
      "type": "n,n-suf",
      "meaning": "thread"
    },
    {
      "id": 44,
      "kana": "いない",
      "kanji": "以内",
      "type": "n,n-suf",
      "meaning": "within, inside of"
    },
    {
      "id": 45,
      "kana": "いなか",
      "kanji": "田舎",
      "type": "giku n",
      "meaning": "rural, countryside"
    },
    {
      "id": 46,
      "kana": "いのる",
      "kanji": "祈る",
      "type": "u-v,vi",
      "meaning": "to pray, to wish"
    },
    {
      "id": 47,
      "kana": "いらっしゃる",
      "kanji": "",
      "type": "aru-v",
      "meaning": "(hon) to be, to come, to go"
    },
    {
      "id": 48,
      "kana": "～いん",
      "kanji": "～員",
      "type": "n-suf",
      "meaning": "member of ～"
    },
    {
      "id": 49,
      "kana": "うえる",
      "kanji": "植える",
      "type": "ru-v",
      "meaning": "to plant, to grow"
    },
    {
      "id": 50,
      "kana": "うかがう",
      "kanji": "",
      "type": "u-v,vi,vt",
      "meaning": "to visit"
    },
    {
      "id": 51,
      "kana": "うかがう",
      "kanji": "",
      "type": "u-v,vi,vt",
      "meaning": "to ask"
    },
    {
      "id": 52,
      "kana": "うけつけ",
      "kanji": "受付",
      "type": "n",
      "meaning": "reception (desk), information ガス"
    },
    {
      "id": 53,
      "kana": "うける",
      "kanji": "受ける",
      "type": "ru-v",
      "meaning": "to take (lesson, test), to undergo"
    },
    {
      "id": 54,
      "kana": "うごく",
      "kanji": "動く",
      "type": "u-v,vi",
      "meaning": "to move"
    },
    {
      "id": 55,
      "kana": "うそ",
      "kanji": "",
      "type": "n",
      "meaning": "lie"
    },
    {
      "id": 56,
      "kana": "うち",
      "kanji": "",
      "type": "n",
      "meaning": "within"
    },
    {
      "id": 57,
      "kana": "うつ",
      "kanji": "打つ",
      "type": "u-v",
      "meaning": "to hit, to strike"
    },
    {
      "id": 58,
      "kana": "うつくしい",
      "kanji": "美しい",
      "type": "adj",
      "meaning": "beautiful, lovely"
    },
    {
      "id": 59,
      "kana": "うつす",
      "kanji": "写す",
      "type": "u-v",
      "meaning": "copy, to photograph, to film"
    },
    {
      "id": 60,
      "kana": "うつる",
      "kanji": "移る",
      "type": "u-v",
      "meaning": "to move (house), to transfer (department)"
    },
    {
      "id": 61,
      "kana": "うで",
      "kanji": "腕",
      "type": "n",
      "meaning": "arm"
    },
    {
      "id": 62,
      "kana": "うまい",
      "kanji": "",
      "type": "adj",
      "meaning": "delicious"
    },
    {
      "id": 63,
      "kana": "うら",
      "kanji": "裏",
      "type": "n",
      "meaning": "reverse side, wrong side, back"
    },
    {
      "id": 64,
      "kana": "うりば",
      "kanji": "売り場",
      "type": "adj-no,n",
      "meaning": "place where things are sold"
    },
    {
      "id": 65,
      "kana": "うれしい",
      "kanji": "",
      "type": "adj",
      "meaning": "happy, glad"
    },
    {
      "id": 66,
      "kana": "うん",
      "kanji": "",
      "type": "n",
      "meaning": "yes (informal), alright"
    },
    {
      "id": 67,
      "kana": "うんてんしゅ",
      "kanji": "運転手",
      "type": "n",
      "meaning": "driver, chauffeur"
    },
    {
      "id": 68,
      "kana": "うんてん・する",
      "kanji": "運転",
      "type": "n,vs",
      "meaning": "driving"
    },
    {
      "id": 69,
      "kana": "うんどう・する",
      "kanji": "運動",
      "type": "n,vs",
      "meaning": "exercise"
    },
    {
      "id": 70,
      "kana": "エスカレーター",
      "kanji": "",
      "type": "n",
      "meaning": "escalator"
    },
    {
      "id": 71,
      "kana": "えだ",
      "kanji": "枝",
      "type": "n",
      "meaning": "branch, twig"
    },
    {
      "id": 72,
      "kana": "えらぶ",
      "kanji": "選ぶ",
      "type": "v5b",
      "meaning": "to choose, to select"
    },
    {
      "id": 73,
      "kana": "えんりょ・する",
      "kanji": "遠慮",
      "type": "adj-na,n,vs",
      "meaning": "restraint, reserve"
    },
    {
      "id": 74,
      "kana": "おいでになる",
      "kanji": "",
      "type": "u-v",
      "meaning": "(hon)to be"
    },
    {
      "id": 75,
      "kana": "おいわい",
      "kanji": "お祝い",
      "type": "n",
      "meaning": "congratulation, celebration"
    },
    {
      "id": 76,
      "kana": "オートバイ",
      "kanji": "",
      "type": "n",
      "meaning": "motorcycle (lit: auto-bi(ke))"
    },
    {
      "id": 77,
      "kana": "オーバー",
      "kanji": "",
      "type": "adj-na,n,vs",
      "meaning": "overcoat"
    },
    {
      "id": 78,
      "kana": "おかげ",
      "kanji": "",
      "type": "n",
      "meaning": "thanks or owing to"
    },
    {
      "id": 79,
      "kana": "おかしい",
      "kanji": "",
      "type": "adj",
      "meaning": "strange, funny"
    },
    {
      "id": 80,
      "kana": "～おき",
      "kanji": "",
      "type": "",
      "meaning": "after every ～"
    },
    {
      "id": 81,
      "kana": "おく",
      "kanji": "億",
      "type": "num",
      "meaning": "100,000,000, hundred million"
    },
    {
      "id": 82,
      "kana": "おくじょう",
      "kanji": "屋上",
      "type": "n",
      "meaning": "rooftop"
    },
    {
      "id": 83,
      "kana": "おくりもの",
      "kanji": "贈り物",
      "type": "n",
      "meaning": "present, gift"
    },
    {
      "id": 84,
      "kana": "おくる",
      "kanji": "送る",
      "type": "u-v",
      "meaning": "to send (a thing), to dispatch"
    },
    {
      "id": 85,
      "kana": "おくれる",
      "kanji": "遅れる",
      "type": "ru-v",
      "meaning": "to be late, to be delayed"
    },
    {
      "id": 86,
      "kana": "おこさん",
      "kanji": "お子さん",
      "type": "n",
      "meaning": "(someone else's) child"
    },
    {
      "id": 87,
      "kana": "おこす",
      "kanji": "起こす",
      "type": "u-v",
      "meaning": "to wake someone"
    },
    {
      "id": 88,
      "kana": "おこなう",
      "kanji": "行う",
      "type": "u-v",
      "meaning": "to perform, to do, to carry out"
    },
    {
      "id": 89,
      "kana": "おこる",
      "kanji": "怒る",
      "type": "u-v",
      "meaning": "to get angry, to be angry"
    },
    {
      "id": 90,
      "kana": "おしいれ",
      "kanji": "押し入れ",
      "type": "n",
      "meaning": "closet"
    },
    {
      "id": 91,
      "kana": "おじょうさん",
      "kanji": "お嬢さん",
      "type": "n",
      "meaning": "(1) (hon) daughter, (2) young lady"
    },
    {
      "id": 92,
      "kana": "おたく",
      "kanji": "お宅",
      "type": "n",
      "meaning": "(pol) your house, your home"
    },
    {
      "id": 93,
      "kana": "おちる",
      "kanji": "落ちる",
      "type": "ru-v",
      "meaning": "to fall, to drop"
    },
    {
      "id": 94,
      "kana": "おっしゃる",
      "kanji": "",
      "type": "aru-v",
      "meaning": "(hon) to say, to speak"
    },
    {
      "id": 95,
      "kana": "おっと",
      "kanji": "夫",
      "type": "n",
      "meaning": "husband"
    },
    {
      "id": 96,
      "kana": "おつり",
      "kanji": "",
      "type": "n",
      "meaning": "change (money), balance"
    },
    {
      "id": 97,
      "kana": "おと",
      "kanji": "音",
      "type": "n,n-suf",
      "meaning": "sound, note"
    },
    {
      "id": 98,
      "kana": "おとす",
      "kanji": "落す",
      "type": "u-v",
      "meaning": "to drop, to lose"
    },
    {
      "id": 99,
      "kana": "おどり",
      "kanji": "踊り",
      "type": "n",
      "meaning": "dance"
    },
    {
      "id": 100,
      "kana": "おどる",
      "kanji": "踊る",
      "type": "u-v",
      "meaning": "to dance"
    },
    {
      "id": 101,
      "kana": "おどろく",
      "kanji": "驚く",
      "type": "u-v",
      "meaning": "to be surprised, to be astonished"
    },
    {
      "id": 102,
      "kana": "おまつり",
      "kanji": "お祭り",
      "type": "",
      "meaning": "festival"
    },
    {
      "id": 103,
      "kana": "おみまい",
      "kanji": "お見舞い",
      "type": "n",
      "meaning": "calling on someone who is ill, enquiry"
    },
    {
      "id": 104,
      "kana": "おみやげ",
      "kanji": "お土産",
      "type": "n",
      "meaning": "souvenir"
    },
    {
      "id": 105,
      "kana": "おもいだす",
      "kanji": "思い出す",
      "type": "u-v",
      "meaning": "to recall, to remember"
    },
    {
      "id": 106,
      "kana": "おもう",
      "kanji": "思う",
      "type": "u-v",
      "meaning": "to think, to feel"
    },
    {
      "id": 107,
      "kana": "おもちゃ",
      "kanji": "",
      "type": "n",
      "meaning": "toy"
    },
    {
      "id": 108,
      "kana": "おもて",
      "kanji": "表",
      "type": "n,n-suf",
      "meaning": "the front, surface, exterior"
    },
    {
      "id": 109,
      "kana": "おや",
      "kanji": "親",
      "type": "n",
      "meaning": "parents"
    },
    {
      "id": 110,
      "kana": "おりる",
      "kanji": "下りる",
      "type": "ru-v",
      "meaning": "to descend a mountain, to (descend) go down stairs"
    },
    {
      "id": 111,
      "kana": "おる",
      "kanji": "",
      "type": "u-v",
      "meaning": "to be (polite version of いる)"
    },
    {
      "id": 112,
      "kana": "おる",
      "kanji": "折る",
      "type": "u-v",
      "meaning": "to break, to fold, to pick a flower"
    },
    {
      "id": 113,
      "kana": "おれい",
      "kanji": "お礼",
      "type": "n",
      "meaning": "thanking, expression of gratitude"
    },
    {
      "id": 114,
      "kana": "おれる",
      "kanji": "折れる",
      "type": "ru-v,vi,vt",
      "meaning": "to break, to be folded, to give in, to turn (a corner)"
    },
    {
      "id": 115,
      "kana": "おわり",
      "kanji": "終わり",
      "type": "n",
      "meaning": "the end"
    },
    {
      "id": 116,
      "kana": "～おわる",
      "kanji": "",
      "type": "suf",
      "meaning": "end of ～"
    },
    {
      "id": 117,
      "kana": "～か",
      "kanji": "～家",
      "type": "",
      "meaning": "professional ～"
    },
    {
      "id": 118,
      "kana": "カーテン",
      "kanji": "",
      "type": "n",
      "meaning": "curtain"
    },
    {
      "id": 119,
      "kana": "～かい",
      "kanji": "～会",
      "type": "",
      "meaning": "～ meeting"
    },
    {
      "id": 120,
      "kana": "かいがん",
      "kanji": "海岸",
      "type": "n",
      "meaning": "coast, beach"
    },
    {
      "id": 121,
      "kana": "かいぎ",
      "kanji": "会議",
      "type": "n",
      "meaning": "meeting, conference"
    },
    {
      "id": 122,
      "kana": "かいぎしつ",
      "kanji": "会議室",
      "type": "n",
      "meaning": "conference room"
    },
    {
      "id": 123,
      "kana": "かいじょう",
      "kanji": "会場",
      "type": "n",
      "meaning": "assembly hall, meeting place, the grounds"
    },
    {
      "id": 124,
      "kana": "かいわ",
      "kanji": "会話",
      "type": "n",
      "meaning": "conversation"
    },
    {
      "id": 125,
      "kana": "かえり",
      "kanji": "帰り",
      "type": "n",
      "meaning": "return, coming back"
    },
    {
      "id": 126,
      "kana": "かえる",
      "kanji": "変える",
      "type": "ru-v,vt",
      "meaning": "to change, to alter, to vary"
    },
    {
      "id": 127,
      "kana": "かがく",
      "kanji": "科学",
      "type": "n",
      "meaning": "science"
    },
    {
      "id": 128,
      "kana": "かがみ",
      "kanji": "鏡",
      "type": "n",
      "meaning": "mirror"
    },
    {
      "id": 129,
      "kana": "～がくぶ",
      "kanji": "～学部",
      "type": "",
      "meaning": "department of a university"
    },
    {
      "id": 130,
      "kana": "かける",
      "kanji": "掛ける",
      "type": "",
      "meaning": "to hang, to put on (eg wall)"
    },
    {
      "id": 131,
      "kana": "かける",
      "kanji": "",
      "type": "",
      "meaning": "to sit down"
    },
    {
      "id": 132,
      "kana": "かける",
      "kanji": "",
      "type": "",
      "meaning": "to begin to"
    },
    {
      "id": 133,
      "kana": "かざる",
      "kanji": "飾る",
      "type": "u-v",
      "meaning": "to decorate, to ornament, to adorn"
    },
    {
      "id": 134,
      "kana": "かじ",
      "kanji": "火事",
      "type": "n",
      "meaning": "fire"
    },
    {
      "id": 135,
      "kana": "ガス",
      "kanji": "",
      "type": "n",
      "meaning": "gas"
    },
    {
      "id": 136,
      "kana": "ガソリン",
      "kanji": "",
      "type": "n",
      "meaning": "gasoline, petrol"
    },
    {
      "id": 137,
      "kana": "ガソリンスタンド",
      "kanji": "",
      "type": "n",
      "meaning": "gasoline stand, gas station, petrol station"
    },
    {
      "id": 138,
      "kana": "かた",
      "kanji": "方",
      "type": "n-suf",
      "meaning": "way of doing"
    },
    {
      "id": 139,
      "kana": "かたい",
      "kanji": "堅/硬/固い",
      "type": "adj",
      "meaning": "solid, hard, firm"
    },
    {
      "id": 140,
      "kana": "かたち",
      "kanji": "形",
      "type": "n",
      "meaning": "shape"
    },
    {
      "id": 141,
      "kana": "かたづける",
      "kanji": "片付ける",
      "type": "ru-v",
      "meaning": "to tidy up, to put in order, to put away"
    },
    {
      "id": 142,
      "kana": "かちょう",
      "kanji": "課長",
      "type": "n",
      "meaning": "section manager"
    },
    {
      "id": 143,
      "kana": "かつ",
      "kanji": "勝つ",
      "type": "u-v",
      "meaning": "to win, to gain victory"
    },
    {
      "id": 144,
      "kana": "かっこう",
      "kanji": "",
      "type": "no--adj, na-adj",
      "meaning": "appearance, manner"
    },
    {
      "id": 145,
      "kana": "かない",
      "kanji": "家内",
      "type": "n",
      "meaning": "housewife"
    },
    {
      "id": 146,
      "kana": "かなしい",
      "kanji": "悲しい",
      "type": "adj",
      "meaning": "sad, sorrowful"
    },
    {
      "id": 147,
      "kana": "かならず",
      "kanji": "必ず",
      "type": "adv",
      "meaning": "certainly, necessarily, invariably"
    },
    {
      "id": 148,
      "kana": "かねもち/おかねもち",
      "kanji": "お・金持ち",
      "type": "n",
      "meaning": "rich man"
    },
    {
      "id": 149,
      "kana": "かのじょ",
      "kanji": "彼女",
      "type": "n",
      "meaning": "she, girl-friend"
    },
    {
      "id": 150,
      "kana": "かべ",
      "kanji": "壁",
      "type": "n",
      "meaning": "wall"
    },
    {
      "id": 151,
      "kana": "かまう",
      "kanji": "",
      "type": "u-v",
      "meaning": "to mind, to care about"
    },
    {
      "id": 152,
      "kana": "かみ",
      "kanji": "髪",
      "type": "n",
      "meaning": "hair"
    },
    {
      "id": 153,
      "kana": "かむ",
      "kanji": "噛む",
      "type": "u-v",
      "meaning": "to bite, to chew"
    },
    {
      "id": 154,
      "kana": "かよう",
      "kanji": "通う",
      "type": "u-v",
      "meaning": "(1) to go back and forth, (2) to commute"
    },
    {
      "id": 155,
      "kana": "ガラス",
      "kanji": "",
      "type": "n",
      "meaning": "glass, pane"
    },
    {
      "id": 156,
      "kana": "かれ",
      "kanji": "彼",
      "type": "n",
      "meaning": "he, boyfriend"
    },
    {
      "id": 157,
      "kana": "かれら",
      "kanji": "彼ら",
      "type": "n",
      "meaning": "they (usually male)"
    },
    {
      "id": 158,
      "kana": "かわく",
      "kanji": "乾く",
      "type": "u-v,vi",
      "meaning": "to get dry"
    },
    {
      "id": 159,
      "kana": "かわり",
      "kanji": "代わり",
      "type": "n",
      "meaning": "substitute, alternate"
    },
    {
      "id": 160,
      "kana": "かわる",
      "kanji": "変わる",
      "type": "u-v,vi",
      "meaning": "to change, to be transformed, to vary"
    },
    {
      "id": 161,
      "kana": "かんがえる",
      "kanji": "考える",
      "type": "ru-v",
      "meaning": "to consider"
    },
    {
      "id": 162,
      "kana": "かんけい",
      "kanji": "関係",
      "type": "n,vs",
      "meaning": "relation, connection"
    },
    {
      "id": 163,
      "kana": "かんごふ",
      "kanji": "看護婦",
      "type": "n",
      "meaning": "(female) nurse"
    },
    {
      "id": 164,
      "kana": "かんたん",
      "kanji": "簡単",
      "type": "adj-na,n",
      "meaning": "simple"
    },
    {
      "id": 165,
      "kana": "がんばる",
      "kanji": "頑張る",
      "type": "u-v",
      "meaning": "to try one's best, to persist"
    },
    {
      "id": 166,
      "kana": "き",
      "kanji": "気",
      "type": "n",
      "meaning": "spirit, mood"
    },
    {
      "id": 167,
      "kana": "きかい",
      "kanji": "機会",
      "type": "n",
      "meaning": "chance, opportunity"
    },
    {
      "id": 168,
      "kana": "きけん",
      "kanji": "危険",
      "type": "adj-na,n",
      "meaning": "danger, peril, hazard"
    },
    {
      "id": 169,
      "kana": "きこえる",
      "kanji": "聞こえる",
      "type": "ru-v",
      "meaning": "to be heard, to be audible"
    },
    {
      "id": 170,
      "kana": "きしゃ",
      "kanji": "汽車",
      "type": "n",
      "meaning": "train (steam)"
    },
    {
      "id": 171,
      "kana": "ぎじゅつ",
      "kanji": "技術",
      "type": "n",
      "meaning": "art, technique, technology, skill"
    },
    {
      "id": 172,
      "kana": "きせつ",
      "kanji": "季節",
      "type": "n",
      "meaning": "season"
    },
    {
      "id": 173,
      "kana": "きそく",
      "kanji": "規則",
      "type": "n",
      "meaning": "rule, regulations"
    },
    {
      "id": 174,
      "kana": "きっと",
      "kanji": "",
      "type": "adv,n",
      "meaning": "surely, definitely"
    },
    {
      "id": 175,
      "kana": "きぬ",
      "kanji": "絹",
      "type": "n",
      "meaning": "silk"
    },
    {
      "id": 176,
      "kana": "きびしい",
      "kanji": "厳しい",
      "type": "adj",
      "meaning": "strict"
    },
    {
      "id": 177,
      "kana": "きぶん",
      "kanji": "気分",
      "type": "n",
      "meaning": "feeling, mood"
    },
    {
      "id": 178,
      "kana": "きまる",
      "kanji": "決る",
      "type": "u-v",
      "meaning": "to be decided, to be settled"
    },
    {
      "id": 179,
      "kana": "きみ",
      "kanji": "君",
      "type": "n,suf",
      "meaning": "You (masculine term for female)"
    },
    {
      "id": 180,
      "kana": "きめる",
      "kanji": "決める",
      "type": "ru-v",
      "meaning": "to decide"
    },
    {
      "id": 181,
      "kana": "きもち",
      "kanji": "気持ち",
      "type": "n",
      "meaning": "feeling, sensation, mood"
    },
    {
      "id": 182,
      "kana": "きもの",
      "kanji": "着物",
      "type": "n",
      "meaning": "kimono"
    },
    {
      "id": 183,
      "kana": "きゃく",
      "kanji": "客",
      "type": "n",
      "meaning": "guest, customer"
    },
    {
      "id": 184,
      "kana": "きゅう",
      "kanji": "急",
      "type": "adj-na,n",
      "meaning": "(1) urgent, sudden, (2) steep"
    },
    {
      "id": 185,
      "kana": "きゅうこう",
      "kanji": "急行",
      "type": "n",
      "meaning": "express (eg train that bypasses stations)"
    },
    {
      "id": 186,
      "kana": "きょういく",
      "kanji": "教育",
      "type": "adj-no,n,vs",
      "meaning": "training, education"
    },
    {
      "id": 187,
      "kana": "きょうかい",
      "kanji": "教会",
      "type": "n",
      "meaning": "church"
    },
    {
      "id": 188,
      "kana": "きょうそう",
      "kanji": "競争",
      "type": "n,vs",
      "meaning": "competition, contest"
    },
    {
      "id": 189,
      "kana": "きょうみ",
      "kanji": "興味",
      "type": "n",
      "meaning": "interest (in something)"
    },
    {
      "id": 190,
      "kana": "きんじょ",
      "kanji": "近所",
      "type": "n",
      "meaning": "neighbourhood"
    },
    {
      "id": 191,
      "kana": "～く",
      "kanji": "～区",
      "type": "n",
      "meaning": "district ～"
    },
    {
      "id": 192,
      "kana": "ぐあい",
      "kanji": "具合",
      "type": "n",
      "meaning": "condition, state, health"
    },
    {
      "id": 193,
      "kana": "くうき",
      "kanji": "空気",
      "type": "n",
      "meaning": "air, atmosphere"
    },
    {
      "id": 194,
      "kana": "くうこう",
      "kanji": "空港",
      "type": "n",
      "meaning": "airport"
    },
    {
      "id": 195,
      "kana": "くさ",
      "kanji": "草",
      "type": "n",
      "meaning": "grass"
    },
    {
      "id": 196,
      "kana": "くださる",
      "kanji": "",
      "type": "aru-v",
      "meaning": "(hon) to give, to confer"
    },
    {
      "id": 197,
      "kana": "くび",
      "kanji": "首",
      "type": "n",
      "meaning": "neck"
    },
    {
      "id": 198,
      "kana": "くも",
      "kanji": "雲",
      "type": "n",
      "meaning": "cloud"
    },
    {
      "id": 199,
      "kana": "くらべる",
      "kanji": "比べる",
      "type": "ru-v",
      "meaning": "to compare"
    },
    {
      "id": 200,
      "kana": "くれる",
      "kanji": "",
      "type": "ru-v",
      "meaning": "to give, to do for"
    },
    {
      "id": 201,
      "kana": "くれる",
      "kanji": "暮れる",
      "type": "ru-v",
      "meaning": "to get dark, to come to an end"
    },
    {
      "id": 202,
      "kana": "～くん",
      "kanji": "～君",
      "type": "n-suf",
      "meaning": "Mr (junior) ～, master ～"
    },
    {
      "id": 203,
      "kana": "け",
      "kanji": "毛",
      "type": "n",
      "meaning": "hair"
    },
    {
      "id": 204,
      "kana": "け",
      "kanji": "毛",
      "type": "n",
      "meaning": "fur"
    },
    {
      "id": 205,
      "kana": "けいかく・する",
      "kanji": "計画",
      "type": "n,vs",
      "meaning": "plan, project, schedule"
    },
    {
      "id": 206,
      "kana": "けいけん・する",
      "kanji": "経験",
      "type": "n,vs",
      "meaning": "experience"
    },
    {
      "id": 207,
      "kana": "けいざい",
      "kanji": "経済",
      "type": "n",
      "meaning": "economics, finance, economy"
    },
    {
      "id": 208,
      "kana": "けいさつ",
      "kanji": "警察",
      "type": "n",
      "meaning": "police"
    },
    {
      "id": 209,
      "kana": "けが・する",
      "kanji": "",
      "type": "n,vs",
      "meaning": "injury (to animate object), hurt"
    },
    {
      "id": 210,
      "kana": "ケーキ",
      "kanji": "",
      "type": "n",
      "meaning": "cake"
    },
    {
      "id": 211,
      "kana": "けしき",
      "kanji": "景色",
      "type": "n",
      "meaning": "scenery, scene, landscape"
    },
    {
      "id": 212,
      "kana": "けしゴム",
      "kanji": "消しゴム",
      "type": "n",
      "meaning": "eraser"
    },
    {
      "id": 213,
      "kana": "げしゅく",
      "kanji": "下宿",
      "type": "n,vs",
      "meaning": "boarding, lodging, boarding house"
    },
    {
      "id": 214,
      "kana": "けっして",
      "kanji": "決して",
      "type": "adv",
      "meaning": "never"
    },
    {
      "id": 215,
      "kana": "けれど/けれども",
      "kanji": "",
      "type": "conj",
      "meaning": "but, however"
    },
    {
      "id": 216,
      "kana": "～けん",
      "kanji": "～軒",
      "type": "",
      "meaning": "counter for houses"
    },
    {
      "id": 217,
      "kana": "げんいん",
      "kanji": "原因",
      "type": "n",
      "meaning": "cause, origin, source"
    },
    {
      "id": 218,
      "kana": "けんか・する",
      "kanji": "",
      "type": "n",
      "meaning": "quarrel"
    },
    {
      "id": 219,
      "kana": "げんかん",
      "kanji": "玄関",
      "type": "n",
      "meaning": "entrance way (in Japanese house)"
    },
    {
      "id": 220,
      "kana": "けんきゅう",
      "kanji": "研究",
      "type": "n,vs",
      "meaning": "study, research, investigation"
    },
    {
      "id": 221,
      "kana": "けんきゅうしつ",
      "kanji": "研究室",
      "type": "n",
      "meaning": "study room, laboratory"
    },
    {
      "id": 222,
      "kana": "けんぶつ",
      "kanji": "見物",
      "type": "n",
      "meaning": "a sight, an attraction"
    },
    {
      "id": 223,
      "kana": "こ",
      "kanji": "子",
      "type": "n",
      "meaning": "child"
    },
    {
      "id": 224,
      "kana": "ご～",
      "kanji": "御～",
      "type": "prefix",
      "meaning": "honourable ～"
    },
    {
      "id": 225,
      "kana": "こう",
      "kanji": "",
      "type": "adv,int-n",
      "meaning": "like this, this way"
    },
    {
      "id": 226,
      "kana": "こうがい",
      "kanji": "郊外",
      "type": "n",
      "meaning": "suburb, outskirts"
    },
    {
      "id": 227,
      "kana": "こうぎ",
      "kanji": "講義",
      "type": "n,vs",
      "meaning": "lecture"
    },
    {
      "id": 228,
      "kana": "こうぎょう",
      "kanji": "工業",
      "type": "n",
      "meaning": "(manufacturing) industry"
    },
    {
      "id": 229,
      "kana": "こうこう/こうとうがっこう",
      "kanji": "高校/高等学校",
      "type": "n",
      "meaning": "high school/senior high school"
    },
    {
      "id": 230,
      "kana": "こうこうせい",
      "kanji": "高校生",
      "type": "n",
      "meaning": "high school student"
    },
    {
      "id": 231,
      "kana": "こうじょう",
      "kanji": "工場",
      "type": "n",
      "meaning": "factory"
    },
    {
      "id": 232,
      "kana": "こうちょう",
      "kanji": "校長",
      "type": "n",
      "meaning": "principal, headmaster"
    },
    {
      "id": 233,
      "kana": "こうつう",
      "kanji": "交通",
      "type": "n",
      "meaning": "traffic, transportation"
    },
    {
      "id": 234,
      "kana": "こうどう",
      "kanji": "講堂",
      "type": "n",
      "meaning": "auditorium"
    },
    {
      "id": 235,
      "kana": "こうむいん",
      "kanji": "公務員",
      "type": "n",
      "meaning": "government worker, public servant"
    },
    {
      "id": 236,
      "kana": "こくさい",
      "kanji": "国際",
      "type": "n",
      "meaning": "international"
    },
    {
      "id": 237,
      "kana": "こころ",
      "kanji": "心",
      "type": "n",
      "meaning": "core, heart"
    },
    {
      "id": 238,
      "kana": "～ございます",
      "kanji": "",
      "type": "expr",
      "meaning": "to be (polite), to exist"
    },
    {
      "id": 239,
      "kana": "ごしゅじん",
      "kanji": "ご主人",
      "type": "n",
      "meaning": "your husband, her husband"
    },
    {
      "id": 240,
      "kana": "こしょう・する",
      "kanji": "故障",
      "type": "n,vs",
      "meaning": "break-down"
    },
    {
      "id": 241,
      "kana": "ごぞんじ",
      "kanji": "ご存じ",
      "type": "n",
      "meaning": "knowing, acquaintance"
    },
    {
      "id": 242,
      "kana": "こたえ",
      "kanji": "答",
      "type": "n",
      "meaning": "answer, response"
    },
    {
      "id": 243,
      "kana": "ごちそう",
      "kanji": "",
      "type": "n,vs",
      "meaning": "feast, treating (someone)"
    },
    {
      "id": 244,
      "kana": "こっち",
      "kanji": "",
      "type": "n",
      "meaning": "(1) this person, (2) this direction, (3) this side, (4) thereafter"
    },
    {
      "id": 245,
      "kana": "こと",
      "kanji": "",
      "type": "n",
      "meaning": "thing, matter, fact"
    },
    {
      "id": 246,
      "kana": "ことり",
      "kanji": "小鳥",
      "type": "n",
      "meaning": "small bird"
    },
    {
      "id": 247,
      "kana": "このあいだ",
      "kanji": "",
      "type": "n-adv",
      "meaning": "the other day, recently"
    },
    {
      "id": 248,
      "kana": "このごろ",
      "kanji": "",
      "type": "",
      "meaning": "these days, nowadays"
    },
    {
      "id": 249,
      "kana": "こまかい",
      "kanji": "細かい",
      "type": "adj",
      "meaning": "(1) small, (2) fine, minute"
    },
    {
      "id": 250,
      "kana": "ごみ",
      "kanji": "",
      "type": "n",
      "meaning": "rubbish, trash, garbage"
    },
    {
      "id": 251,
      "kana": "こむ",
      "kanji": "込む",
      "type": "u-v",
      "meaning": "to be crowded"
    },
    {
      "id": 252,
      "kana": "こめ",
      "kanji": "米",
      "type": "n",
      "meaning": "uncooked rice"
    },
    {
      "id": 253,
      "kana": "ごらんになる",
      "kanji": "",
      "type": "",
      "meaning": "(hon) to see"
    },
    {
      "id": 254,
      "kana": "これから",
      "kanji": "",
      "type": "n-t",
      "meaning": "after this"
    },
    {
      "id": 255,
      "kana": "こわい",
      "kanji": "怖い",
      "type": "adj",
      "meaning": "scary, frightening"
    },
    {
      "id": 256,
      "kana": "こわす",
      "kanji": "壊す",
      "type": "u-v,vt",
      "meaning": "to break, to break down"
    },
    {
      "id": 257,
      "kana": "こわれる",
      "kanji": "壊れる",
      "type": "ru-v,vi",
      "meaning": "to be broken, to break"
    },
    {
      "id": 258,
      "kana": "コンサート",
      "kanji": "",
      "type": "n",
      "meaning": "concert"
    },
    {
      "id": 259,
      "kana": "こんど",
      "kanji": "今度",
      "type": "n-adv,n-t",
      "meaning": "now, next time"
    },
    {
      "id": 260,
      "kana": "コンピュータ/コンピューター",
      "kanji": "",
      "type": "n",
      "meaning": "computer"
    },
    {
      "id": 261,
      "kana": "こんや",
      "kanji": "今夜",
      "type": "n-adv,n-t",
      "meaning": "this evening, tonight"
    },
    {
      "id": 262,
      "kana": "さいきん",
      "kanji": "最近",
      "type": "adj-no,n-adv,n-t",
      "meaning": "latest, most recent, nowadays"
    },
    {
      "id": 263,
      "kana": "さいご",
      "kanji": "最後",
      "type": "n",
      "meaning": "last, end, conclusion"
    },
    {
      "id": 264,
      "kana": "さいしょ",
      "kanji": "最初",
      "type": "adj-no,n-adv,n-t",
      "meaning": "beginning, first"
    },
    {
      "id": 265,
      "kana": "さか",
      "kanji": "坂",
      "type": "n",
      "meaning": "slope, hill"
    },
    {
      "id": 266,
      "kana": "さがす",
      "kanji": "探す",
      "type": "u-v",
      "meaning": "to search, to seek, to look for"
    },
    {
      "id": 267,
      "kana": "さがる",
      "kanji": "下る",
      "type": "u-v",
      "meaning": "to get down, to descend"
    },
    {
      "id": 268,
      "kana": "さかん",
      "kanji": "盛ん",
      "type": "adj-na,n",
      "meaning": "popularity, prosperous"
    },
    {
      "id": 269,
      "kana": "さげる",
      "kanji": "下げる",
      "type": "ru-v",
      "meaning": "to hang, to lower, to move back"
    },
    {
      "id": 270,
      "kana": "さしあげる",
      "kanji": "差し上げる",
      "type": "ru-v",
      "meaning": "(polite) to give, to offer"
    },
    {
      "id": 271,
      "kana": "さっき",
      "kanji": "",
      "type": "n",
      "meaning": "some time ago"
    },
    {
      "id": 272,
      "kana": "さびしい",
      "kanji": "寂しい",
      "type": "adj",
      "meaning": "lonely, lonesome"
    },
    {
      "id": 273,
      "kana": "～さま",
      "kanji": "～様",
      "type": "n-suf",
      "meaning": "Mr or Mrs ～"
    },
    {
      "id": 274,
      "kana": "さらいげつ",
      "kanji": "さ来月",
      "type": "",
      "meaning": "the month after next"
    },
    {
      "id": 275,
      "kana": "さらいしゅう",
      "kanji": "さ来週",
      "type": "n-adv,n-t",
      "meaning": "the week after next"
    },
    {
      "id": 276,
      "kana": "サラダ",
      "kanji": "",
      "type": "n",
      "meaning": "salad"
    },
    {
      "id": 277,
      "kana": "さわぐ",
      "kanji": "騒ぐ",
      "type": "u-v",
      "meaning": "to make noise, to clamor, to be excited"
    },
    {
      "id": 278,
      "kana": "さわる",
      "kanji": "触る",
      "type": "u-v",
      "meaning": "to touch, to feel"
    },
    {
      "id": 279,
      "kana": "さんぎょう",
      "kanji": "産業",
      "type": "n",
      "meaning": "industry"
    },
    {
      "id": 280,
      "kana": "サンダル",
      "kanji": "",
      "type": "n",
      "meaning": "sandal"
    },
    {
      "id": 281,
      "kana": "サンドイッチ",
      "kanji": "",
      "type": "n",
      "meaning": "sandwich"
    },
    {
      "id": 282,
      "kana": "ざんねん",
      "kanji": "残念",
      "type": "adj-na,n",
      "meaning": "bad luck, disappointment"
    },
    {
      "id": 283,
      "kana": "し",
      "kanji": "市",
      "type": "n",
      "meaning": "city"
    },
    {
      "id": 284,
      "kana": "じ",
      "kanji": "字",
      "type": "n",
      "meaning": "character, hand-writing"
    },
    {
      "id": 285,
      "kana": "しあい",
      "kanji": "試合",
      "type": "n",
      "meaning": "match, game, contest"
    },
    {
      "id": 286,
      "kana": "しかた",
      "kanji": "仕方",
      "type": "n",
      "meaning": "way, method"
    },
    {
      "id": 287,
      "kana": "しかる",
      "kanji": "",
      "type": "u-v",
      "meaning": "to scold"
    },
    {
      "id": 288,
      "kana": "～しき",
      "kanji": "～式",
      "type": "",
      "meaning": "ceremony"
    },
    {
      "id": 289,
      "kana": "しけん",
      "kanji": "試験",
      "type": "n,vs",
      "meaning": "examination, test, study"
    },
    {
      "id": 290,
      "kana": "じこ",
      "kanji": "事故",
      "type": "n",
      "meaning": "accident"
    },
    {
      "id": 291,
      "kana": "じしん",
      "kanji": "地震",
      "type": "n",
      "meaning": "earthquake"
    },
    {
      "id": 292,
      "kana": "じだい",
      "kanji": "時代",
      "type": "n-t",
      "meaning": "period, epoch, era"
    },
    {
      "id": 293,
      "kana": "したぎ",
      "kanji": "下着",
      "type": "n",
      "meaning": "underwear"
    },
    {
      "id": 294,
      "kana": "したく・する",
      "kanji": "支度",
      "type": "n,vs",
      "meaning": "preparation"
    },
    {
      "id": 295,
      "kana": "しっかり",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "firmly, tightly, steady"
    },
    {
      "id": 296,
      "kana": "しっぱい",
      "kanji": "失敗",
      "type": "adj-no,n,vs",
      "meaning": "failure, mistake, blunder"
    },
    {
      "id": 297,
      "kana": "しつれい",
      "kanji": "失礼",
      "type": "adj-na,int,n,vs,exp",
      "meaning": "(1) discourtesy, impoliteness, (2) Excuse me, Goodbye"
    },
    {
      "id": 298,
      "kana": "じてん",
      "kanji": "辞典",
      "type": "n",
      "meaning": "dictionary"
    },
    {
      "id": 299,
      "kana": "しなもの",
      "kanji": "品物",
      "type": "n",
      "meaning": "goods"
    },
    {
      "id": 300,
      "kana": "しばらく",
      "kanji": "",
      "type": "adv,int",
      "meaning": "little while"
    },
    {
      "id": 301,
      "kana": "しま",
      "kanji": "島",
      "type": "n",
      "meaning": "island"
    },
    {
      "id": 302,
      "kana": "（～て）しまう",
      "kanji": "",
      "type": "u-v",
      "meaning": "to end up ～"
    },
    {
      "id": 303,
      "kana": "しみん",
      "kanji": "市民",
      "type": "n",
      "meaning": "citizen"
    },
    {
      "id": 304,
      "kana": "じむしょ",
      "kanji": "事務所",
      "type": "n",
      "meaning": "office"
    },
    {
      "id": 305,
      "kana": "しゃかい",
      "kanji": "社会",
      "type": "n",
      "meaning": "society, public"
    },
    {
      "id": 306,
      "kana": "しゃちょう",
      "kanji": "社長",
      "type": "n",
      "meaning": "company president, manager, director"
    },
    {
      "id": 307,
      "kana": "じゃま",
      "kanji": "",
      "type": "adj-na,n,vs",
      "meaning": "hindrance, intrusion"
    },
    {
      "id": 308,
      "kana": "ジャム",
      "kanji": "",
      "type": "n",
      "meaning": "jam"
    },
    {
      "id": 309,
      "kana": "じゆう",
      "kanji": "自由",
      "type": "adj-na,exp,n",
      "meaning": "freedom"
    },
    {
      "id": 310,
      "kana": "しゅうかん",
      "kanji": "習慣",
      "type": "n",
      "meaning": "custom, habit, manners"
    },
    {
      "id": 311,
      "kana": "じゅうしょ",
      "kanji": "住所",
      "type": "n",
      "meaning": "address (eg of house), residence, domicile"
    },
    {
      "id": 312,
      "kana": "じゅうどう",
      "kanji": "柔道",
      "type": "n",
      "meaning": "judo"
    },
    {
      "id": 313,
      "kana": "じゅうぶん",
      "kanji": "十分",
      "type": "",
      "meaning": "enough , sufficient"
    },
    {
      "id": 314,
      "kana": "しゅっせき・する",
      "kanji": "出席",
      "type": "n,vs",
      "meaning": "attendance, presence"
    },
    {
      "id": 315,
      "kana": "しゅっぱつ・する",
      "kanji": "出発",
      "type": "n,vs",
      "meaning": "departure"
    },
    {
      "id": 316,
      "kana": "しゅみ",
      "kanji": "趣味",
      "type": "n",
      "meaning": "hobby"
    },
    {
      "id": 317,
      "kana": "じゅんび・する",
      "kanji": "準備",
      "type": "n",
      "meaning": "prepare"
    },
    {
      "id": 318,
      "kana": "しょうかい",
      "kanji": "紹介",
      "type": "n,vs",
      "meaning": "introduction"
    },
    {
      "id": 319,
      "kana": "しょうがつ",
      "kanji": "正月",
      "type": "n",
      "meaning": "New Year, New Year's Day"
    },
    {
      "id": 320,
      "kana": "しょうがっこう",
      "kanji": "小学校",
      "type": "n",
      "meaning": "primary school, elementary school"
    },
    {
      "id": 321,
      "kana": "しょうせつ",
      "kanji": "小説",
      "type": "n",
      "meaning": "novel, story"
    },
    {
      "id": 322,
      "kana": "しょうたい・する",
      "kanji": "招待",
      "type": "n,vs",
      "meaning": "invitation"
    },
    {
      "id": 323,
      "kana": "しょうち・する",
      "kanji": "承知",
      "type": "n,vs",
      "meaning": "consent, acceptance"
    },
    {
      "id": 324,
      "kana": "しょうらい",
      "kanji": "将来",
      "type": "n-adv,n-t",
      "meaning": "future, prospects"
    },
    {
      "id": 325,
      "kana": "しょくじ・する",
      "kanji": "食事",
      "type": "n,vs",
      "meaning": "meal"
    },
    {
      "id": 326,
      "kana": "しょくりょうひん",
      "kanji": "食料品",
      "type": "n",
      "meaning": "foodstuff, groceries"
    },
    {
      "id": 327,
      "kana": "じょせい",
      "kanji": "女性",
      "type": "n",
      "meaning": "woman"
    },
    {
      "id": 328,
      "kana": "しらせる",
      "kanji": "知らせる",
      "type": "ru-v",
      "meaning": "to notify, to advise"
    },
    {
      "id": 329,
      "kana": "しらべる",
      "kanji": "調べる",
      "type": "ru-v,vt",
      "meaning": "to investigate, to check up"
    },
    {
      "id": 330,
      "kana": "じんこう",
      "kanji": "人口",
      "type": "n",
      "meaning": "population"
    },
    {
      "id": 331,
      "kana": "じんじゃ",
      "kanji": "神社",
      "type": "n",
      "meaning": "Shinto shrine"
    },
    {
      "id": 332,
      "kana": "しんせつ",
      "kanji": "親切",
      "type": "adj-na,n",
      "meaning": "kindness, gentleness"
    },
    {
      "id": 333,
      "kana": "しんぱい・する",
      "kanji": "心配",
      "type": "adj-na,n,vs",
      "meaning": "worry, concern"
    },
    {
      "id": 334,
      "kana": "しんぶんしゃ",
      "kanji": "新聞社",
      "type": "n",
      "meaning": "newspaper company"
    },
    {
      "id": 335,
      "kana": "すいえい",
      "kanji": "水泳",
      "type": "n,vs",
      "meaning": "swimming"
    },
    {
      "id": 336,
      "kana": "すいどう",
      "kanji": "水道",
      "type": "n",
      "meaning": "water service, water supply"
    },
    {
      "id": 337,
      "kana": "ずいぶん",
      "kanji": "",
      "type": "adj-na,n-adv",
      "meaning": "extremely"
    },
    {
      "id": 338,
      "kana": "すうがく",
      "kanji": "数学",
      "type": "n",
      "meaning": "mathematics, arithmetic"
    },
    {
      "id": 339,
      "kana": "スーツ",
      "kanji": "",
      "type": "n",
      "meaning": "suit"
    },
    {
      "id": 340,
      "kana": "スーツケース",
      "kanji": "",
      "type": "n",
      "meaning": "suitcase"
    },
    {
      "id": 341,
      "kana": "スーパー（マーケット）",
      "kanji": "",
      "type": "n",
      "meaning": "super (market)"
    },
    {
      "id": 342,
      "kana": "すぎる",
      "kanji": "過ぎる",
      "type": "ru-v,vi",
      "meaning": "to exceed, to go beyond"
    },
    {
      "id": 343,
      "kana": "すく",
      "kanji": "",
      "type": "u-v,vi",
      "meaning": "to become empty"
    },
    {
      "id": 344,
      "kana": "すく",
      "kanji": "",
      "type": "u-v,vi",
      "meaning": "to be less crowded"
    },
    {
      "id": 345,
      "kana": "スクリーン",
      "kanji": "",
      "type": "n",
      "meaning": "screen"
    },
    {
      "id": 346,
      "kana": "すごい",
      "kanji": "凄い",
      "type": "adj",
      "meaning": "terrific, amazing, to a great extent"
    },
    {
      "id": 347,
      "kana": "すすむ",
      "kanji": "進む",
      "type": "u-v",
      "meaning": "to make progress, to advance, to proceed"
    },
    {
      "id": 348,
      "kana": "すっかり",
      "kanji": "",
      "type": "adv",
      "meaning": "all, completely, thoroughly"
    },
    {
      "id": 349,
      "kana": "ずっと",
      "kanji": "",
      "type": "adv,n",
      "meaning": "straight, quickly, all of a sudden"
    },
    {
      "id": 350,
      "kana": "ステーキ",
      "kanji": "",
      "type": "n",
      "meaning": "steak"
    },
    {
      "id": 351,
      "kana": "ステレオ",
      "kanji": "",
      "type": "n",
      "meaning": "stereo"
    },
    {
      "id": 352,
      "kana": "すてる",
      "kanji": "捨てる",
      "type": "ru-v",
      "meaning": "to throw away"
    },
    {
      "id": 353,
      "kana": "すな",
      "kanji": "砂",
      "type": "n",
      "meaning": "sand"
    },
    {
      "id": 354,
      "kana": "すばらしい",
      "kanji": "",
      "type": "adj",
      "meaning": "wonderful, splendid"
    },
    {
      "id": 355,
      "kana": "すべる",
      "kanji": "滑る",
      "type": "u-v",
      "meaning": "to slide, to slip"
    },
    {
      "id": 356,
      "kana": "すみ",
      "kanji": "隅",
      "type": "n,n-suf",
      "meaning": "corner, nook"
    },
    {
      "id": 357,
      "kana": "すむ",
      "kanji": "済む",
      "type": "u-v",
      "meaning": "to finish, to end"
    },
    {
      "id": 358,
      "kana": "すり",
      "kanji": "",
      "type": "n",
      "meaning": "pickpocket"
    },
    {
      "id": 359,
      "kana": "すると",
      "kanji": "",
      "type": "conj",
      "meaning": "and, then"
    },
    {
      "id": 360,
      "kana": "～せい",
      "kanji": "～製",
      "type": "",
      "meaning": "made in　～"
    },
    {
      "id": 361,
      "kana": "せいかつ・する",
      "kanji": "生活",
      "type": "n,vs",
      "meaning": "living, life (one's daily existence), livelihood"
    },
    {
      "id": 362,
      "kana": "せいさん・する",
      "kanji": "生産",
      "type": "ru-v,vi",
      "meaning": "production, manufacture"
    },
    {
      "id": 363,
      "kana": "せいじ",
      "kanji": "政治",
      "type": "n",
      "meaning": "politics, government"
    },
    {
      "id": 364,
      "kana": "せいよう",
      "kanji": "西洋",
      "type": "n",
      "meaning": "the west, Western countries"
    },
    {
      "id": 365,
      "kana": "せかい",
      "kanji": "世界",
      "type": "n",
      "meaning": "the world"
    },
    {
      "id": 366,
      "kana": "せき",
      "kanji": "席",
      "type": "n",
      "meaning": "seat"
    },
    {
      "id": 367,
      "kana": "せつめい",
      "kanji": "説明",
      "type": "n,vs",
      "meaning": "explanation"
    },
    {
      "id": 368,
      "kana": "せなか",
      "kanji": "背中",
      "type": "n",
      "meaning": "back (of body)"
    },
    {
      "id": 369,
      "kana": "ぜひ",
      "kanji": "",
      "type": "adv,n",
      "meaning": "certainly, without fail"
    },
    {
      "id": 370,
      "kana": "せわ・する",
      "kanji": "世話",
      "type": "n,vs",
      "meaning": "looking after, help"
    },
    {
      "id": 371,
      "kana": "せん",
      "kanji": "線",
      "type": "n,n-suf",
      "meaning": "line, wire"
    },
    {
      "id": 372,
      "kana": "ぜんぜん",
      "kanji": "",
      "type": "adv",
      "meaning": "wholly, entirely"
    },
    {
      "id": 373,
      "kana": "せんそう",
      "kanji": "戦争",
      "type": "n,vs",
      "meaning": "war"
    },
    {
      "id": 374,
      "kana": "せんぱい",
      "kanji": "先輩",
      "type": "n",
      "meaning": "senior, superior, elder"
    },
    {
      "id": 375,
      "kana": "せんもん",
      "kanji": "専門",
      "type": "n",
      "meaning": "speciality, study major"
    },
    {
      "id": 376,
      "kana": "そう",
      "kanji": "",
      "type": "adv",
      "meaning": "really"
    },
    {
      "id": 377,
      "kana": "そうだん・する",
      "kanji": "相談",
      "type": "n,vs",
      "meaning": "consultation, discussion"
    },
    {
      "id": 378,
      "kana": "そだてる",
      "kanji": "育てる",
      "type": "ru-v,vt",
      "meaning": "to raise, to rear, to bring up"
    },
    {
      "id": 379,
      "kana": "そつぎょう",
      "kanji": "卒業",
      "type": "n,vs",
      "meaning": "graduation"
    },
    {
      "id": 380,
      "kana": "そふ",
      "kanji": "祖父",
      "type": "n",
      "meaning": "grandfather"
    },
    {
      "id": 381,
      "kana": "ソフト",
      "kanji": "",
      "type": "n",
      "meaning": "soft"
    },
    {
      "id": 382,
      "kana": "そぼ",
      "kanji": "祖母",
      "type": "n",
      "meaning": "grandmother"
    },
    {
      "id": 383,
      "kana": "それで",
      "kanji": "",
      "type": "conj",
      "meaning": "and, thereupon, because of that"
    },
    {
      "id": 384,
      "kana": "それに",
      "kanji": "",
      "type": "conj",
      "meaning": "besides, moreover"
    },
    {
      "id": 385,
      "kana": "それほど",
      "kanji": "",
      "type": "adv",
      "meaning": "to that degree, extent"
    },
    {
      "id": 386,
      "kana": "そろそろ",
      "kanji": "",
      "type": "n",
      "meaning": "gradually, steadily, soon"
    },
    {
      "id": 387,
      "kana": "そんな",
      "kanji": "",
      "type": "adj-na,adj-pn,adv,n",
      "meaning": "such, like that, that sort of"
    },
    {
      "id": 388,
      "kana": "そんなに",
      "kanji": "",
      "type": "uk",
      "meaning": "so much, like that"
    },
    {
      "id": 389,
      "kana": "～だい",
      "kanji": "～代",
      "type": "",
      "meaning": "～ age/period"
    },
    {
      "id": 390,
      "kana": "たいいん・する",
      "kanji": "退院",
      "type": "n,vs",
      "meaning": "leaving hospital"
    },
    {
      "id": 391,
      "kana": "だいがくせい",
      "kanji": "大学生",
      "type": "n",
      "meaning": "college student, university student"
    },
    {
      "id": 392,
      "kana": "だいじ",
      "kanji": "大事",
      "type": "adj-na,n",
      "meaning": "important, valuable, serious matter"
    },
    {
      "id": 393,
      "kana": "だいたい",
      "kanji": "大体",
      "type": "n",
      "meaning": "generally, substantially"
    },
    {
      "id": 394,
      "kana": "たいてい",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "generally, usually"
    },
    {
      "id": 395,
      "kana": "タイプ",
      "kanji": "",
      "type": "n",
      "meaning": "type, style"
    },
    {
      "id": 396,
      "kana": "だいぶ",
      "kanji": "大分",
      "type": "adv",
      "meaning": "considerably, greatly, a lot"
    },
    {
      "id": 397,
      "kana": "たいふう",
      "kanji": "台風",
      "type": "n",
      "meaning": "typhoon"
    },
    {
      "id": 398,
      "kana": "たおれる",
      "kanji": "倒れる",
      "type": "ru-v,vi",
      "meaning": "to collapse, to break down"
    },
    {
      "id": 399,
      "kana": "だから",
      "kanji": "",
      "type": "conj,n",
      "meaning": "so, therefore"
    },
    {
      "id": 400,
      "kana": "たしか",
      "kanji": "確か",
      "type": "adj-na,adv,exp,n",
      "meaning": "certain, sure, definite"
    },
    {
      "id": 401,
      "kana": "たす",
      "kanji": "足す",
      "type": "u-v",
      "meaning": "to add (numbers), to do (eg. one's business)"
    },
    {
      "id": 402,
      "kana": "～だす",
      "kanji": "",
      "type": "",
      "meaning": "start to ～"
    },
    {
      "id": 403,
      "kana": "たずねる",
      "kanji": "訪ねる",
      "type": "ru-v",
      "meaning": "to visit"
    },
    {
      "id": 404,
      "kana": "たずねる",
      "kanji": "尋ねる",
      "type": "ru-v",
      "meaning": "to ask"
    },
    {
      "id": 405,
      "kana": "ただしい",
      "kanji": "正しい",
      "type": "adj",
      "meaning": "right, just, correct"
    },
    {
      "id": 406,
      "kana": "たたみ",
      "kanji": "畳",
      "type": "n",
      "meaning": "tatami mat (Japanese straw mat)"
    },
    {
      "id": 407,
      "kana": "～だて",
      "kanji": "建て",
      "type": "n",
      "meaning": "two storied, separate housing"
    },
    {
      "id": 408,
      "kana": "たてる",
      "kanji": "立てる",
      "type": "ru-v",
      "meaning": "to stand (something) up, to erect (something)"
    },
    {
      "id": 409,
      "kana": "たてる",
      "kanji": "建てる",
      "type": "ru-v",
      "meaning": "to build, to construct"
    },
    {
      "id": 410,
      "kana": "たとえば",
      "kanji": "例えば",
      "type": "adv",
      "meaning": "for example, eg."
    },
    {
      "id": 411,
      "kana": "たな",
      "kanji": "棚",
      "type": "n",
      "meaning": "shelves, rack"
    },
    {
      "id": 412,
      "kana": "たのしむ",
      "kanji": "楽む",
      "type": "u-v",
      "meaning": "to enjoy oneself"
    },
    {
      "id": 413,
      "kana": "たのしみ",
      "kanji": "楽しみ",
      "type": "n",
      "meaning": "pleasure, joy"
    },
    {
      "id": 414,
      "kana": "たまに",
      "kanji": "",
      "type": "adv,suf",
      "meaning": "occasionally, once in a while"
    },
    {
      "id": 415,
      "kana": "ため",
      "kanji": "為",
      "type": "n",
      "meaning": "good, advantage, in order to"
    },
    {
      "id": 416,
      "kana": "だめ",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "useless, no good, hopeless"
    },
    {
      "id": 417,
      "kana": "たりる",
      "kanji": "足りる",
      "type": "ru-v",
      "meaning": "to be sufficient, to be enough"
    },
    {
      "id": 418,
      "kana": "だんせい",
      "kanji": "男性",
      "type": "n",
      "meaning": "male, man"
    },
    {
      "id": 419,
      "kana": "だんぼう",
      "kanji": "暖房",
      "type": "n",
      "meaning": "heating"
    },
    {
      "id": 420,
      "kana": "ち",
      "kanji": "血",
      "type": "n",
      "meaning": "blood"
    },
    {
      "id": 421,
      "kana": "チェック・する",
      "kanji": "",
      "type": "n",
      "meaning": "check"
    },
    {
      "id": 422,
      "kana": "ちから",
      "kanji": "力",
      "type": "n-suf",
      "meaning": "strength, power"
    },
    {
      "id": 423,
      "kana": "ちっとも",
      "kanji": "",
      "type": "adv",
      "meaning": "not at all (neg. verb)"
    },
    {
      "id": 424,
      "kana": "～ちゃん",
      "kanji": "",
      "type": "n",
      "meaning": "suffix for familiar (female) person"
    },
    {
      "id": 425,
      "kana": "ちゅうい",
      "kanji": "注意",
      "type": "n,vs",
      "meaning": "caution, being careful, attention (heed)"
    },
    {
      "id": 426,
      "kana": "ちゅうがっこう",
      "kanji": "中学校",
      "type": "n",
      "meaning": "junior high school, middle school pupil"
    },
    {
      "id": 427,
      "kana": "ちゅうしゃ",
      "kanji": "注射",
      "type": "n,vs",
      "meaning": "injection"
    },
    {
      "id": 428,
      "kana": "ちゅうしゃじょう",
      "kanji": "駐車場",
      "type": "n",
      "meaning": "parking lot, parking place"
    },
    {
      "id": 429,
      "kana": "～ちょう",
      "kanji": "～町",
      "type": "n",
      "meaning": "～ of the town"
    },
    {
      "id": 430,
      "kana": "ちり",
      "kanji": "地理",
      "type": "n",
      "meaning": "geography"
    },
    {
      "id": 431,
      "kana": "～（に）ついて",
      "kanji": "",
      "type": "expr",
      "meaning": "about, concerning, regarding"
    },
    {
      "id": 432,
      "kana": "つかまえる",
      "kanji": "捕まえる",
      "type": "ru-v",
      "meaning": "to catch, to arrest, to seize"
    },
    {
      "id": 433,
      "kana": "つき",
      "kanji": "",
      "type": "n-suf",
      "meaning": "moon"
    },
    {
      "id": 434,
      "kana": "～つき",
      "kanji": "～月",
      "type": "n-temp",
      "meaning": "month"
    },
    {
      "id": 435,
      "kana": "つく",
      "kanji": "点く",
      "type": "u-v, intrans-verb",
      "meaning": "to be started, to be switched on"
    },
    {
      "id": 436,
      "kana": "つける",
      "kanji": "",
      "type": "ru-v",
      "meaning": "to take"
    },
    {
      "id": 437,
      "kana": "つける",
      "kanji": "漬ける",
      "type": "ru-v",
      "meaning": "to soak, to moisten, to pickle"
    },
    {
      "id": 438,
      "kana": "つごう",
      "kanji": "都合",
      "type": "adv,n",
      "meaning": "circumstances, condition, convenience"
    },
    {
      "id": 439,
      "kana": "つたえる",
      "kanji": "伝える",
      "type": "ru-v",
      "meaning": "to tell, to report"
    },
    {
      "id": 440,
      "kana": "つづく",
      "kanji": "続く",
      "type": "u-v,vi",
      "meaning": "to be continued"
    },
    {
      "id": 441,
      "kana": "～つづける",
      "kanji": "～続ける",
      "type": "ru-v,vt",
      "meaning": "to continue doing ～"
    },
    {
      "id": 442,
      "kana": "つづける",
      "kanji": "続ける",
      "type": "ru-v,vt",
      "meaning": "to continue, to keep up, to keep on"
    },
    {
      "id": 443,
      "kana": "つつむ",
      "kanji": "包む",
      "type": "u-v",
      "meaning": "wrap, pack"
    },
    {
      "id": 444,
      "kana": "つま",
      "kanji": "妻",
      "type": "n",
      "meaning": "(hum) wife"
    },
    {
      "id": 445,
      "kana": "つもり",
      "kanji": "",
      "type": "n",
      "meaning": "intention, plan"
    },
    {
      "id": 446,
      "kana": "つる",
      "kanji": "釣る",
      "type": "u-v",
      "meaning": "to fish"
    },
    {
      "id": 447,
      "kana": "つれる",
      "kanji": "連れる",
      "type": "ru-v",
      "meaning": "to lead, to take (a person)"
    },
    {
      "id": 448,
      "kana": "ていねい",
      "kanji": "丁寧",
      "type": "adj-na,n",
      "meaning": "polite, courteous"
    },
    {
      "id": 449,
      "kana": "テキスト",
      "kanji": "",
      "type": "n",
      "meaning": "(1)text, (2)text book"
    },
    {
      "id": 450,
      "kana": "てきとう",
      "kanji": "適当",
      "type": "adj-na,n",
      "meaning": "fitness, suitability"
    },
    {
      "id": 451,
      "kana": "できる",
      "kanji": "",
      "type": "ru-v",
      "meaning": "to be able to do"
    },
    {
      "id": 452,
      "kana": "できるだけ",
      "kanji": "",
      "type": "",
      "meaning": "if at all possible, as much as possible"
    },
    {
      "id": 453,
      "kana": "てつだう",
      "kanji": "手伝う",
      "type": "u-v",
      "meaning": "to help, to assist, to take part in"
    },
    {
      "id": 454,
      "kana": "テニス",
      "kanji": "",
      "type": "n",
      "meaning": "tennis"
    },
    {
      "id": 455,
      "kana": "てぶくろ",
      "kanji": "手袋",
      "type": "n",
      "meaning": "glove"
    },
    {
      "id": 456,
      "kana": "てら",
      "kanji": "寺",
      "type": "n",
      "meaning": "temple"
    },
    {
      "id": 457,
      "kana": "てん",
      "kanji": "点",
      "type": "n,n-suf",
      "meaning": "spot, mark, point, dot"
    },
    {
      "id": 458,
      "kana": "てんいん",
      "kanji": "店員",
      "type": "n",
      "meaning": "shop assistant, employee, clerk, salesperson"
    },
    {
      "id": 459,
      "kana": "てんきよほう",
      "kanji": "天気予報",
      "type": "",
      "meaning": "weather forecast, weather report"
    },
    {
      "id": 460,
      "kana": "でんとう",
      "kanji": "電灯",
      "type": "n",
      "meaning": "electric light"
    },
    {
      "id": 461,
      "kana": "でんぽう",
      "kanji": "電報",
      "type": "n",
      "meaning": "telegram"
    },
    {
      "id": 462,
      "kana": "てんらんかい",
      "kanji": "展覧会",
      "type": "n",
      "meaning": "exhibition"
    },
    {
      "id": 463,
      "kana": "と",
      "kanji": "都",
      "type": "n",
      "meaning": "metroplitan, municipal"
    },
    {
      "id": 464,
      "kana": "どうぐ",
      "kanji": "道具",
      "type": "n",
      "meaning": "tool, means"
    },
    {
      "id": 465,
      "kana": "とうとう",
      "kanji": "",
      "type": "",
      "meaning": "finally, at last"
    },
    {
      "id": 466,
      "kana": "どうぶつえん",
      "kanji": "動物園",
      "type": "n",
      "meaning": "zoo"
    },
    {
      "id": 467,
      "kana": "とおく",
      "kanji": "遠く",
      "type": "adj-no,n-adv,n",
      "meaning": "far away, distant"
    },
    {
      "id": 468,
      "kana": "とおり",
      "kanji": "通り",
      "type": "n-suf",
      "meaning": "～ Street, ～ Avenue"
    },
    {
      "id": 469,
      "kana": "とおる",
      "kanji": "通る",
      "type": "u-v",
      "meaning": "to pass (by), to go through"
    },
    {
      "id": 470,
      "kana": "とくに",
      "kanji": "特に",
      "type": "adv",
      "meaning": "particularly, especially"
    },
    {
      "id": 471,
      "kana": "とくべつ",
      "kanji": "特別",
      "type": "adj-na,adv,n",
      "meaning": "special"
    },
    {
      "id": 472,
      "kana": "とこや",
      "kanji": "",
      "type": "n",
      "meaning": "barber"
    },
    {
      "id": 473,
      "kana": "とちゅう",
      "kanji": "途中",
      "type": "n-adv,n-t",
      "meaning": "on the way, en route, midway"
    },
    {
      "id": 474,
      "kana": "とっきゅう",
      "kanji": "特急",
      "type": "n",
      "meaning": "limited express (train, faster than an express)"
    },
    {
      "id": 475,
      "kana": "とどける",
      "kanji": "届ける",
      "type": "ru-v",
      "meaning": "to reach"
    },
    {
      "id": 476,
      "kana": "とまる",
      "kanji": "泊まる",
      "type": "u-v",
      "meaning": "to stay at (eg. hotel)"
    },
    {
      "id": 477,
      "kana": "とめる",
      "kanji": "止める",
      "type": "ru-v,vt",
      "meaning": "to stop (something)"
    },
    {
      "id": 478,
      "kana": "とりかえる",
      "kanji": "取り替える",
      "type": "ru-v",
      "meaning": "to exchange, to replace"
    },
    {
      "id": 479,
      "kana": "どろぼう",
      "kanji": "泥棒",
      "type": "n,vs",
      "meaning": "thief, burglar, robber"
    },
    {
      "id": 480,
      "kana": "どんどん",
      "kanji": "",
      "type": "adv",
      "meaning": "steadily, little by little"
    },
    {
      "id": 481,
      "kana": "なおす",
      "kanji": "直す",
      "type": "u-v,vt",
      "meaning": "to fix, to repair"
    },
    {
      "id": 482,
      "kana": "なおる",
      "kanji": "直る",
      "type": "u-v,vi",
      "meaning": "to be fixed, to be repaired"
    },
    {
      "id": 483,
      "kana": "なおる",
      "kanji": "治る",
      "type": "u-v,vi",
      "meaning": "to be cured, to heal"
    },
    {
      "id": 484,
      "kana": "なかなか",
      "kanji": "中々",
      "type": "adv",
      "meaning": "very, considerably, quite"
    },
    {
      "id": 485,
      "kana": "なく",
      "kanji": "泣く",
      "type": "u-v",
      "meaning": "to cry, to weep"
    },
    {
      "id": 486,
      "kana": "なくなる",
      "kanji": "無くなる",
      "type": "u-v",
      "meaning": "to disappear, to get lost"
    },
    {
      "id": 487,
      "kana": "なくなる",
      "kanji": "亡くなる",
      "type": "u-v",
      "meaning": "to die"
    },
    {
      "id": 488,
      "kana": "なげる",
      "kanji": "投げる",
      "type": "ru-v",
      "meaning": "to throw, to cast away"
    },
    {
      "id": 489,
      "kana": "なさる",
      "kanji": "",
      "type": "aru-v",
      "meaning": "(hon) to do"
    },
    {
      "id": 490,
      "kana": "なる",
      "kanji": "鳴る",
      "type": "u-v",
      "meaning": "to sound, to ring, to resound"
    },
    {
      "id": 491,
      "kana": "なるべく",
      "kanji": "",
      "type": "adv",
      "meaning": "as much as possible"
    },
    {
      "id": 492,
      "kana": "なるほど",
      "kanji": "",
      "type": "adv,exp",
      "meaning": "I see, now I understand"
    },
    {
      "id": 493,
      "kana": "なれる",
      "kanji": "慣れる",
      "type": "ru-v",
      "meaning": "to grow accustomed to"
    },
    {
      "id": 494,
      "kana": "におい",
      "kanji": "",
      "type": "ru-v",
      "meaning": "odour, scent, smell"
    },
    {
      "id": 495,
      "kana": "にがい",
      "kanji": "苦い",
      "type": "adj",
      "meaning": "bitter"
    },
    {
      "id": 496,
      "kana": "～にくい",
      "kanji": "",
      "type": "suf",
      "meaning": "difficult to do ～"
    },
    {
      "id": 497,
      "kana": "にげる",
      "kanji": "逃げる",
      "type": "ru-v",
      "meaning": "to escape, to run away"
    },
    {
      "id": 498,
      "kana": "にっき",
      "kanji": "日記",
      "type": "n",
      "meaning": "diary, journal"
    },
    {
      "id": 499,
      "kana": "にゅういん・する",
      "kanji": "入院",
      "type": "n,vs",
      "meaning": "hospitalization"
    },
    {
      "id": 500,
      "kana": "にゅうがく・する",
      "kanji": "入学",
      "type": "n",
      "meaning": "entry to school or university, matriculation"
    },
    {
      "id": 501,
      "kana": "にる",
      "kanji": "似る",
      "type": "ru-v",
      "meaning": "to resemble, to be similar"
    },
    {
      "id": 502,
      "kana": "にんぎょう",
      "kanji": "人形",
      "type": "n",
      "meaning": "doll, puppet, figure"
    },
    {
      "id": 503,
      "kana": "ぬすむ",
      "kanji": "盗む",
      "type": "u-v",
      "meaning": "to steal"
    },
    {
      "id": 504,
      "kana": "ぬる",
      "kanji": "塗る",
      "type": "u-v",
      "meaning": "to paint, to plaster"
    },
    {
      "id": 505,
      "kana": "ぬれる",
      "kanji": "",
      "type": "ru-v",
      "meaning": "to get wet"
    },
    {
      "id": 506,
      "kana": "ねだん",
      "kanji": "",
      "type": "n",
      "meaning": "price, cost"
    },
    {
      "id": 507,
      "kana": "ねつ",
      "kanji": "熱",
      "type": "n,n-suf",
      "meaning": "fever, temperature"
    },
    {
      "id": 508,
      "kana": "ねっしん",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "zeal, enthusiasm"
    },
    {
      "id": 509,
      "kana": "ねぼう",
      "kanji": "寝坊",
      "type": "n,vs",
      "meaning": "sleeping in late"
    },
    {
      "id": 510,
      "kana": "ねむい",
      "kanji": "眠い",
      "type": "adj",
      "meaning": "sleepy, drowsy"
    },
    {
      "id": 511,
      "kana": "ねむる",
      "kanji": "眠る",
      "type": "u-v",
      "meaning": "to sleep"
    },
    {
      "id": 512,
      "kana": "のこる",
      "kanji": "残る",
      "type": "u-v",
      "meaning": "to remain, to be left"
    },
    {
      "id": 513,
      "kana": "のど",
      "kanji": "",
      "type": "n",
      "meaning": "throat"
    },
    {
      "id": 514,
      "kana": "のりかえる",
      "kanji": "乗り換える",
      "type": "ru-v",
      "meaning": "to transfer (trains), to change (bus, train)"
    },
    {
      "id": 515,
      "kana": "のりもの",
      "kanji": "乗り物",
      "type": "n",
      "meaning": "vehicle"
    },
    {
      "id": 516,
      "kana": "は",
      "kanji": "葉",
      "type": "n",
      "meaning": "leaf"
    },
    {
      "id": 517,
      "kana": "ばあい",
      "kanji": "場合",
      "type": "n-adv,n",
      "meaning": "case, situation"
    },
    {
      "id": 518,
      "kana": "パート（タイム）",
      "kanji": "",
      "type": "n-adv,n",
      "meaning": "part time (esp female part time employees)"
    },
    {
      "id": 519,
      "kana": "ばい",
      "kanji": "倍",
      "type": "n,vi,vs,vt",
      "meaning": "twice, double"
    },
    {
      "id": 520,
      "kana": "はいけん・する",
      "kanji": "拝見",
      "type": "n,vs",
      "meaning": "(hum) (pol) seeing, look at"
    },
    {
      "id": 521,
      "kana": "はいしゃ",
      "kanji": "歯医者",
      "type": "n",
      "meaning": "dentist"
    },
    {
      "id": 522,
      "kana": "～ばかり",
      "kanji": "",
      "type": "suf",
      "meaning": "just did ～, only"
    },
    {
      "id": 523,
      "kana": "はこぶ",
      "kanji": "運ぶ",
      "type": "v5b",
      "meaning": "to transport, to carry"
    },
    {
      "id": 524,
      "kana": "～はじめる",
      "kanji": "～始める",
      "type": "ru-v,vt",
      "meaning": "to start doing　～, the beginning of ～"
    },
    {
      "id": 525,
      "kana": "はじめる",
      "kanji": "始める",
      "type": "ru-v,vt",
      "meaning": "to start, to begin"
    },
    {
      "id": 526,
      "kana": "ばしょ",
      "kanji": "場所",
      "type": "n",
      "meaning": "place, location"
    },
    {
      "id": 527,
      "kana": "はず",
      "kanji": "",
      "type": "n",
      "meaning": "it should be so"
    },
    {
      "id": 528,
      "kana": "はずかしい",
      "kanji": "恥ずかしい",
      "type": "adj",
      "meaning": "shy, ashamed, embarrassed"
    },
    {
      "id": 529,
      "kana": "パソコン",
      "kanji": "",
      "type": "n",
      "meaning": "(personal) computer"
    },
    {
      "id": 530,
      "kana": "はつおん",
      "kanji": "発音",
      "type": "n,vs",
      "meaning": "pronunciation"
    },
    {
      "id": 531,
      "kana": "はっきり",
      "kanji": "",
      "type": "adv,n",
      "meaning": "clearly, plainly, distinctly"
    },
    {
      "id": 532,
      "kana": "はなみ",
      "kanji": "花見",
      "type": "n,vs",
      "meaning": "cherry-blossom viewing, flower viewing"
    },
    {
      "id": 533,
      "kana": "パパ",
      "kanji": "",
      "type": "n",
      "meaning": "papa, father"
    },
    {
      "id": 534,
      "kana": "はやし",
      "kanji": "林",
      "type": "n",
      "meaning": "woods, forester"
    },
    {
      "id": 535,
      "kana": "はらう",
      "kanji": "払う",
      "type": "u-v",
      "meaning": "to pay"
    },
    {
      "id": 536,
      "kana": "ばんぐみ",
      "kanji": "番組",
      "type": "n",
      "meaning": "program (eg. TV)"
    },
    {
      "id": 537,
      "kana": "はんたい",
      "kanji": "反対",
      "type": "adj-na,n,vs",
      "meaning": "oppose, opposition, resistance"
    },
    {
      "id": 538,
      "kana": "ハンバーグ",
      "kanji": "",
      "type": "n",
      "meaning": "hamburger (meat, no bun)"
    },
    {
      "id": 539,
      "kana": "ひ",
      "kanji": "日",
      "type": "n-adv,n-t",
      "meaning": "sun, sunshine, day"
    },
    {
      "id": 540,
      "kana": "ひ",
      "kanji": "火",
      "type": "n,n-suf",
      "meaning": "fire, flame, blaze"
    },
    {
      "id": 541,
      "kana": "ピアノ",
      "kanji": "",
      "type": "n",
      "meaning": "piano"
    },
    {
      "id": 542,
      "kana": "ひえる",
      "kanji": "冷える",
      "type": "ru-v,vi",
      "meaning": "to grow cold, to get chilly, to cool down"
    },
    {
      "id": 543,
      "kana": "ひかる",
      "kanji": "光る",
      "type": "ru-v,vi",
      "meaning": "to shine, to glitter"
    },
    {
      "id": 544,
      "kana": "ひかり",
      "kanji": "光",
      "type": "n",
      "meaning": "light"
    },
    {
      "id": 545,
      "kana": "ひきだし",
      "kanji": "引き出し",
      "type": "n",
      "meaning": "drawer, drawing out"
    },
    {
      "id": 546,
      "kana": "ひげ",
      "kanji": "",
      "type": "n",
      "meaning": "beard"
    },
    {
      "id": 547,
      "kana": "ひこうじょう",
      "kanji": "飛行場",
      "type": "n",
      "meaning": "airport"
    },
    {
      "id": 548,
      "kana": "ひさしぶり",
      "kanji": "久しぶり",
      "type": "exp",
      "meaning": "after a long time"
    },
    {
      "id": 549,
      "kana": "びじゅつかん",
      "kanji": "美術館",
      "type": "n",
      "meaning": "art gallery, art museum"
    },
    {
      "id": 550,
      "kana": "ひじょうに",
      "kanji": "非常に",
      "type": "adv",
      "meaning": "very, extremely, exceedingly"
    },
    {
      "id": 551,
      "kana": "びっくりする",
      "kanji": "",
      "type": "vs",
      "meaning": "to be surprised"
    },
    {
      "id": 552,
      "kana": "ひっこす",
      "kanji": "引っ越す",
      "type": "u-v",
      "meaning": "to move, to change residence"
    },
    {
      "id": 553,
      "kana": "ひつよう",
      "kanji": "必要",
      "type": "adj-na,n",
      "meaning": "necessary, essential"
    },
    {
      "id": 554,
      "kana": "ひどい",
      "kanji": "",
      "type": "adj",
      "meaning": "cruel, awful"
    },
    {
      "id": 555,
      "kana": "ひらく",
      "kanji": "開く",
      "type": "u-v",
      "meaning": "to open (eg. a festival)"
    },
    {
      "id": 556,
      "kana": "ビル",
      "kanji": "",
      "type": "n",
      "meaning": "(abbr) building, bill"
    },
    {
      "id": 557,
      "kana": "ひるま",
      "kanji": "昼間",
      "type": "n-adv,n-t",
      "meaning": "daytime, during the day"
    },
    {
      "id": 558,
      "kana": "ひるやすみ",
      "kanji": "昼休み",
      "type": "n-adv,n-t",
      "meaning": "lunch break, noon recess, noon rest period"
    },
    {
      "id": 559,
      "kana": "ひろう",
      "kanji": "拾う",
      "type": "u-v",
      "meaning": "to pick up, to find, to gather"
    },
    {
      "id": 560,
      "kana": "ファックス",
      "kanji": "",
      "type": "ru-v,vi",
      "meaning": "fax"
    },
    {
      "id": 561,
      "kana": "ふえる",
      "kanji": "増える",
      "type": "ru-v,vi",
      "meaning": "to increase, to multiply"
    },
    {
      "id": 562,
      "kana": "ふかい",
      "kanji": "深い",
      "type": "adj",
      "meaning": "deep, profound, thick"
    },
    {
      "id": 563,
      "kana": "ふくざつ",
      "kanji": "複雑",
      "type": "adj-na,n",
      "meaning": "complexity, complication"
    },
    {
      "id": 564,
      "kana": "ふくしゅう",
      "kanji": "復習",
      "type": "n,vs",
      "meaning": "review, revision"
    },
    {
      "id": 565,
      "kana": "ぶちょう",
      "kanji": "部長",
      "type": "adjn",
      "meaning": "head of a section"
    },
    {
      "id": 566,
      "kana": "ふつう",
      "kanji": "普通",
      "type": "adj-na,adj-no,adv,n",
      "meaning": "(1) generally, ordinarily, usually, (2) train that stops at every station"
    },
    {
      "id": 567,
      "kana": "ぶどう",
      "kanji": "",
      "type": "n",
      "meaning": "grapes"
    },
    {
      "id": 568,
      "kana": "ふとる",
      "kanji": "太る",
      "type": "u-v",
      "meaning": "to grow fat (stout, plump), to become fat"
    },
    {
      "id": 569,
      "kana": "ふとん",
      "kanji": "布団",
      "type": "n",
      "meaning": "bedding (Japanese style), futon"
    },
    {
      "id": 570,
      "kana": "ふね",
      "kanji": "舟",
      "type": "n",
      "meaning": "ship, boat"
    },
    {
      "id": 571,
      "kana": "ふべん",
      "kanji": "不便",
      "type": "adj-na,n",
      "meaning": "inconvenience"
    },
    {
      "id": 572,
      "kana": "ふむ",
      "kanji": "踏む",
      "type": "u-v",
      "meaning": "to step on, to tread on"
    },
    {
      "id": 573,
      "kana": "プレゼント",
      "kanji": "",
      "type": "n",
      "meaning": "present, gift"
    },
    {
      "id": 574,
      "kana": "ぶんか",
      "kanji": "文化",
      "type": "n",
      "meaning": "culture, civilization"
    },
    {
      "id": 575,
      "kana": "ぶんがく",
      "kanji": "文学",
      "type": "n",
      "meaning": "literature"
    },
    {
      "id": 576,
      "kana": "ぶんぽう",
      "kanji": "文法",
      "type": "n",
      "meaning": "grammar"
    },
    {
      "id": 577,
      "kana": "べつ",
      "kanji": "別",
      "type": "adj-na,n,n-suf",
      "meaning": "distinction, different"
    },
    {
      "id": 578,
      "kana": "ベル",
      "kanji": "",
      "type": "n",
      "meaning": "bell"
    },
    {
      "id": 579,
      "kana": "へん",
      "kanji": "変",
      "type": "adj-na,n",
      "meaning": "strange, odd, peculiar"
    },
    {
      "id": 580,
      "kana": "へんじ",
      "kanji": "返事",
      "type": "n,vs",
      "meaning": "reply, answer"
    },
    {
      "id": 581,
      "kana": "ぼうえき",
      "kanji": "貿易",
      "type": "n",
      "meaning": "trade (foreign)"
    },
    {
      "id": 582,
      "kana": "ほうそう・する",
      "kanji": "放送",
      "type": "n,vs",
      "meaning": "broadcast, broadcasting"
    },
    {
      "id": 583,
      "kana": "ほうりつ",
      "kanji": "法律",
      "type": "n",
      "meaning": "law"
    },
    {
      "id": 584,
      "kana": "ぼく",
      "kanji": "僕",
      "type": "n",
      "meaning": "(male) I, manservant"
    },
    {
      "id": 585,
      "kana": "ほし",
      "kanji": "星",
      "type": "n",
      "meaning": "star"
    },
    {
      "id": 586,
      "kana": "ほど",
      "kanji": "",
      "type": "n",
      "meaning": "degree, extent"
    },
    {
      "id": 587,
      "kana": "ほとんど",
      "kanji": "",
      "type": "n-adv,n-t",
      "meaning": "mostly, almost"
    },
    {
      "id": 588,
      "kana": "ほめる",
      "kanji": "",
      "type": "ru-v",
      "meaning": "to praise"
    },
    {
      "id": 589,
      "kana": "ほんやく",
      "kanji": "翻訳",
      "type": "n,vs",
      "meaning": "translation (written)"
    },
    {
      "id": 590,
      "kana": "まいる",
      "kanji": "参る",
      "type": "u-v",
      "meaning": "(1) (hum) to go, to come"
    },
    {
      "id": 591,
      "kana": "まける",
      "kanji": "負ける",
      "type": "ru-v",
      "meaning": "to lose, to be defeated"
    },
    {
      "id": 592,
      "kana": "まじめ",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "diligent, serious"
    },
    {
      "id": 593,
      "kana": "まず",
      "kanji": "",
      "type": "adv",
      "meaning": "first (of all), to start with"
    },
    {
      "id": 594,
      "kana": "または",
      "kanji": "",
      "type": "conj,exp",
      "meaning": "or, otherwise"
    },
    {
      "id": 595,
      "kana": "まちがえる",
      "kanji": "間違える",
      "type": "ru-v",
      "meaning": "to err, to make a mistake"
    },
    {
      "id": 596,
      "kana": "まにあう",
      "kanji": "間に合う",
      "type": "u-v",
      "meaning": "(1) to be in time for"
    },
    {
      "id": 597,
      "kana": "～まま",
      "kanji": "",
      "type": "n",
      "meaning": "as it is"
    },
    {
      "id": 598,
      "kana": "まわり",
      "kanji": "周り",
      "type": "n,n-suf",
      "meaning": "surroundings, circulation"
    },
    {
      "id": 599,
      "kana": "まわる",
      "kanji": "回る",
      "type": "u-v",
      "meaning": "to go around, to revolve"
    },
    {
      "id": 600,
      "kana": "まんが",
      "kanji": "漫画",
      "type": "n",
      "meaning": "comic, cartoon"
    },
    {
      "id": 601,
      "kana": "まんなか",
      "kanji": "真中",
      "type": "n",
      "meaning": "middle, centre"
    },
    {
      "id": 602,
      "kana": "みえる",
      "kanji": "見える",
      "type": "ru-v",
      "meaning": "to be seen, to be in sight"
    },
    {
      "id": 603,
      "kana": "みずうみ",
      "kanji": "湖",
      "type": "n",
      "meaning": "lake"
    },
    {
      "id": 604,
      "kana": "みそ",
      "kanji": "味噌",
      "type": "n",
      "meaning": "miso, bean paste"
    },
    {
      "id": 605,
      "kana": "みつかる",
      "kanji": "見つかる",
      "type": "u-v",
      "meaning": "(uk) to be found, to be discovered"
    },
    {
      "id": 606,
      "kana": "みつける",
      "kanji": "見つける",
      "type": "ru-v",
      "meaning": "to discover, to find"
    },
    {
      "id": 607,
      "kana": "みな",
      "kanji": "皆",
      "type": "adv,n",
      "meaning": "everyone, everybody"
    },
    {
      "id": 608,
      "kana": "みなと",
      "kanji": "港",
      "type": "n",
      "meaning": "harbour, port"
    },
    {
      "id": 609,
      "kana": "むかえる",
      "kanji": "迎える",
      "type": "u-v",
      "meaning": "to go out to meet"
    },
    {
      "id": 610,
      "kana": "むかう",
      "kanji": "向かう",
      "type": "u-v",
      "meaning": "to face, to go towards"
    },
    {
      "id": 611,
      "kana": "むかし",
      "kanji": "昔",
      "type": "adj-no,n-adv,n-t",
      "meaning": "olden days, former"
    },
    {
      "id": 612,
      "kana": "むし",
      "kanji": "虫",
      "type": "n",
      "meaning": "insect"
    },
    {
      "id": 613,
      "kana": "むすこ",
      "kanji": "息子",
      "type": "n",
      "meaning": "(hum) son"
    },
    {
      "id": 614,
      "kana": "むすめ",
      "kanji": "娘",
      "type": "n",
      "meaning": "(hum) daughter"
    },
    {
      "id": 615,
      "kana": "むり",
      "kanji": "無理",
      "type": "adj-na,n,vs",
      "meaning": "unreasonable, impossible"
    },
    {
      "id": 616,
      "kana": "～め",
      "kanji": "～目",
      "type": "suf",
      "meaning": "number ～ sequence, ～nd/th"
    },
    {
      "id": 617,
      "kana": "めしあがる",
      "kanji": "召し上がる",
      "type": "u-v",
      "meaning": "(pol) to eat"
    },
    {
      "id": 618,
      "kana": "めずらしい",
      "kanji": "珍しい",
      "type": "adj",
      "meaning": "unusual, rare"
    },
    {
      "id": 619,
      "kana": "もうしあげる",
      "kanji": "申し上げる",
      "type": "ru-v",
      "meaning": "to say, to tell"
    },
    {
      "id": 620,
      "kana": "もうす",
      "kanji": "申す",
      "type": "u-v",
      "meaning": "(hum) to be called, to say"
    },
    {
      "id": 621,
      "kana": "もうすぐ",
      "kanji": "",
      "type": "expr",
      "meaning": "soon, very soon"
    },
    {
      "id": 622,
      "kana": "もし",
      "kanji": "",
      "type": "adv",
      "meaning": "if"
    },
    {
      "id": 623,
      "kana": "もちろん",
      "kanji": "",
      "type": "adv",
      "meaning": "certainly, of course"
    },
    {
      "id": 624,
      "kana": "もっとも",
      "kanji": "",
      "type": "adv",
      "meaning": "most, extremely"
    },
    {
      "id": 625,
      "kana": "もどる",
      "kanji": "戻る",
      "type": "u-v",
      "meaning": "to turn back, to return"
    },
    {
      "id": 626,
      "kana": "もめん",
      "kanji": "木綿",
      "type": "n",
      "meaning": "cotton"
    },
    {
      "id": 627,
      "kana": "もらう",
      "kanji": "",
      "type": "u-v",
      "meaning": "to receive"
    },
    {
      "id": 628,
      "kana": "もり",
      "kanji": "森",
      "type": "n",
      "meaning": "forest"
    },
    {
      "id": 629,
      "kana": "やく",
      "kanji": "焼く",
      "type": "u-v",
      "meaning": "to bake, to grill"
    },
    {
      "id": 630,
      "kana": "やくにたつ",
      "kanji": "役に立つ",
      "type": "u-v",
      "meaning": "to be helpful, to be useful"
    },
    {
      "id": 631,
      "kana": "やくそく",
      "kanji": "約束",
      "type": "n,vs",
      "meaning": "arrangement, promise"
    },
    {
      "id": 632,
      "kana": "やける",
      "kanji": "焼ける",
      "type": "ru-v,vi",
      "meaning": "to burn, to be roasted, to be sunburnt"
    },
    {
      "id": 633,
      "kana": "やさしい",
      "kanji": "優しい",
      "type": "adj",
      "meaning": "kind, gentle"
    },
    {
      "id": 634,
      "kana": "～やすい",
      "kanji": "",
      "type": "",
      "meaning": "easy to do ～"
    },
    {
      "id": 635,
      "kana": "やせる",
      "kanji": "",
      "type": "ru-v",
      "meaning": "to become thin, to lose weight"
    },
    {
      "id": 636,
      "kana": "やっと",
      "kanji": "",
      "type": "adv",
      "meaning": "at last, at length"
    },
    {
      "id": 637,
      "kana": "やはり/やっぱり",
      "kanji": "",
      "type": "adv,exp",
      "meaning": "as I thought, absolutely"
    },
    {
      "id": 638,
      "kana": "やむ",
      "kanji": "",
      "type": "u-v,vi",
      "meaning": "to cease, to stop"
    },
    {
      "id": 639,
      "kana": "やめる",
      "kanji": "止める",
      "type": "ru-v",
      "meaning": "to end, to stop, to resign"
    },
    {
      "id": 640,
      "kana": "やる",
      "kanji": "",
      "type": "u-v",
      "meaning": "(col)to do"
    },
    {
      "id": 641,
      "kana": "やわらかい",
      "kanji": "柔らかい",
      "type": "adj",
      "meaning": "soft, tender"
    },
    {
      "id": 642,
      "kana": "ゆ",
      "kanji": "湯",
      "type": "n",
      "meaning": "hot water"
    },
    {
      "id": 643,
      "kana": "ゆうはん",
      "kanji": "夕飯",
      "type": "n",
      "meaning": "evening meal"
    },
    {
      "id": 644,
      "kana": "ゆしゅつ・する",
      "kanji": "輸出",
      "type": "n,vs",
      "meaning": "export"
    },
    {
      "id": 645,
      "kana": "ゆにゅう・する",
      "kanji": "輸入",
      "type": "n,vs",
      "meaning": "import"
    },
    {
      "id": 646,
      "kana": "ゆび",
      "kanji": "指",
      "type": "n",
      "meaning": "finger"
    },
    {
      "id": 647,
      "kana": "ゆびわ",
      "kanji": "指輪",
      "type": "n",
      "meaning": "(finger) ring"
    },
    {
      "id": 648,
      "kana": "ゆめ",
      "kanji": "夢",
      "type": "n",
      "meaning": "dream"
    },
    {
      "id": 649,
      "kana": "ゆれる",
      "kanji": "揺れる",
      "type": "ru-v",
      "meaning": "to shake, to sway"
    },
    {
      "id": 650,
      "kana": "よう",
      "kanji": "様",
      "type": "adj-na",
      "meaning": "way, manner, kind"
    },
    {
      "id": 651,
      "kana": "よう",
      "kanji": "用",
      "type": "n,n-suf",
      "meaning": "task, business, use"
    },
    {
      "id": 652,
      "kana": "ようい",
      "kanji": "用意",
      "type": "n,vs",
      "meaning": "preparation"
    },
    {
      "id": 653,
      "kana": "ようじ",
      "kanji": "用事",
      "type": "n",
      "meaning": "tasks, chores"
    },
    {
      "id": 654,
      "kana": "よごれる",
      "kanji": "汚れる",
      "type": "ru-v",
      "meaning": "to get dirty, to become dirty"
    },
    {
      "id": 655,
      "kana": "よしゅう",
      "kanji": "予習",
      "type": "n,vs",
      "meaning": "preparation for a lesson"
    },
    {
      "id": 656,
      "kana": "よてい",
      "kanji": "予定",
      "type": "n,vs",
      "meaning": "plans, arrangement, schedule"
    },
    {
      "id": 657,
      "kana": "よやく",
      "kanji": "予約",
      "type": "n,vs",
      "meaning": "reservation, booking"
    },
    {
      "id": 658,
      "kana": "よる",
      "kanji": "寄る",
      "type": "u-v",
      "meaning": "to visit, to drop in"
    },
    {
      "id": 659,
      "kana": "（～に）よると",
      "kanji": "",
      "type": "",
      "meaning": "according to ～"
    },
    {
      "id": 660,
      "kana": "よろこぶ",
      "kanji": "喜ぶ",
      "type": "v5b",
      "meaning": "to be delighted, to be glad"
    },
    {
      "id": 661,
      "kana": "よろしい",
      "kanji": "",
      "type": "adj",
      "meaning": "(hon) good, OK, all right"
    },
    {
      "id": 662,
      "kana": "りゆう",
      "kanji": "理由",
      "type": "n",
      "meaning": "reason, pretext, motive"
    },
    {
      "id": 663,
      "kana": "りよう",
      "kanji": "利用",
      "type": "n,vs",
      "meaning": "use, utilization"
    },
    {
      "id": 664,
      "kana": "りょうほう",
      "kanji": "両方",
      "type": "n",
      "meaning": "both sides, both parties"
    },
    {
      "id": 665,
      "kana": "りょかん",
      "kanji": "旅館",
      "type": "n",
      "meaning": "Japanese hotel, inn"
    },
    {
      "id": 666,
      "kana": "るす",
      "kanji": "留守",
      "type": "n",
      "meaning": "absence, being away from home"
    },
    {
      "id": 667,
      "kana": "れいぼう",
      "kanji": "冷房",
      "type": "n",
      "meaning": "cooling, air conditioning"
    },
    {
      "id": 668,
      "kana": "れきし",
      "kanji": "歴史",
      "type": "n",
      "meaning": "history"
    },
    {
      "id": 669,
      "kana": "レジ",
      "kanji": "",
      "type": "n",
      "meaning": "register"
    },
    {
      "id": 670,
      "kana": "レポート/リポート",
      "kanji": "",
      "type": "n",
      "meaning": "report, essay, assignment (aus)"
    },
    {
      "id": 671,
      "kana": "れんらく",
      "kanji": "連絡",
      "type": "n,vs",
      "meaning": "communication, contact, connection"
    },
    {
      "id": 672,
      "kana": "ワープロ",
      "kanji": "",
      "type": "n",
      "meaning": "word processor"
    },
    {
      "id": 673,
      "kana": "わかす",
      "kanji": "沸かす",
      "type": "u-v,vt",
      "meaning": "to boil, to heat"
    },
    {
      "id": 674,
      "kana": "わかれる",
      "kanji": "別れる",
      "type": "ru-v",
      "meaning": "to be divided, to part from, to separate"
    },
    {
      "id": 675,
      "kana": "わく",
      "kanji": "沸く",
      "type": "u-v",
      "meaning": "to boil, to grow hot, to get excited"
    },
    {
      "id": 676,
      "kana": "わけ",
      "kanji": "訳",
      "type": "n",
      "meaning": "meaning, reason"
    },
    {
      "id": 677,
      "kana": "わすれもの",
      "kanji": "忘れ物",
      "type": "n",
      "meaning": "lost article, something forgotten"
    },
    {
      "id": 678,
      "kana": "わらう",
      "kanji": "笑う",
      "type": "u-v",
      "meaning": "to laugh, to smile"
    },
    {
      "id": 679,
      "kana": "わりあい",
      "kanji": "割合",
      "type": "adv,n",
      "meaning": "rate, ratio, percentage"
    },
    {
      "id": 680,
      "kana": "われる",
      "kanji": "割れる",
      "type": "ru-v,vi",
      "meaning": "to break"
    }
  ]
export const dataN2 = [
    {
      "id": 0,
      "kana": "あっ",
      "kanji": "",
      "type": "int",
      "meaning": "Ah!, Oh!"
    },
    {
      "id": 1,
      "kana": "ああ",
      "kanji": "",
      "type": "int",
      "meaning": "like that, that way"
    },
    {
      "id": 2,
      "kana": "あい",
      "kanji": "愛",
      "type": "n,n-suf,vs",
      "meaning": "love"
    },
    {
      "id": 3,
      "kana": "あいかわらず",
      "kanji": "",
      "type": "adv,n",
      "meaning": "as ever, as usual, the same"
    },
    {
      "id": 4,
      "kana": "あいさつ",
      "kanji": "挨拶",
      "type": "n,vs",
      "meaning": "greeting, salutation"
    },
    {
      "id": 5,
      "kana": "あいじょう",
      "kanji": "愛情",
      "type": "n",
      "meaning": "love, affection"
    },
    {
      "id": 6,
      "kana": "あいず",
      "kanji": "合図",
      "type": "n,vs",
      "meaning": "sign, signal"
    },
    {
      "id": 7,
      "kana": "アイスクリーム",
      "kanji": "",
      "type": "n",
      "meaning": "ice cream"
    },
    {
      "id": 8,
      "kana": "あいする",
      "kanji": "愛する",
      "type": "vs-s",
      "meaning": "to love"
    },
    {
      "id": 9,
      "kana": "あいだ",
      "kanji": "間",
      "type": "n",
      "meaning": "space, room, time"
    },
    {
      "id": 10,
      "kana": "あいて",
      "kanji": "相手",
      "type": "n",
      "meaning": "companion, partner"
    },
    {
      "id": 11,
      "kana": "アイデア/アイディア",
      "kanji": "",
      "type": "n",
      "meaning": "idea"
    },
    {
      "id": 12,
      "kana": "あいにく",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "unfortunately, Sorry, but...."
    },
    {
      "id": 13,
      "kana": "あいまい",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "vague, ambiguous"
    },
    {
      "id": 14,
      "kana": "アイロン",
      "kanji": "",
      "type": "n",
      "meaning": "(electric) iron"
    },
    {
      "id": 15,
      "kana": "あう",
      "kanji": "合う",
      "type": "u-v",
      "meaning": "to fit, to suit"
    },
    {
      "id": 16,
      "kana": "あう",
      "kanji": "会う",
      "type": "u-v",
      "meaning": "to meet, to interview"
    },
    {
      "id": 17,
      "kana": "あう",
      "kanji": "遇う",
      "type": "u-v",
      "meaning": "to meet, to encounter"
    },
    {
      "id": 18,
      "kana": "アウト",
      "kanji": "",
      "type": "n",
      "meaning": "out"
    },
    {
      "id": 19,
      "kana": "あお",
      "kanji": "青",
      "type": "n",
      "meaning": "blue, green, green light"
    },
    {
      "id": 20,
      "kana": "あおい",
      "kanji": "青い",
      "type": "adj",
      "meaning": "blue, pale, inexperienced"
    },
    {
      "id": 21,
      "kana": "あおぐ",
      "kanji": "扇ぐ",
      "type": "v5g",
      "meaning": "to fan, to flap"
    },
    {
      "id": 22,
      "kana": "あおじろい",
      "kanji": "青白い",
      "type": "adj",
      "meaning": "pale, pallid"
    },
    {
      "id": 23,
      "kana": "あか",
      "kanji": "赤",
      "type": "n",
      "meaning": "the colour red"
    },
    {
      "id": 24,
      "kana": "あかい",
      "kanji": "赤い",
      "type": "adj",
      "meaning": "red"
    },
    {
      "id": 25,
      "kana": "あかちゃん",
      "kanji": "",
      "type": "n",
      "meaning": "baby, infant"
    },
    {
      "id": 26,
      "kana": "あかり",
      "kanji": "明かり",
      "type": "n",
      "meaning": "lamplight, light (in general), brightness"
    },
    {
      "id": 27,
      "kana": "あがる",
      "kanji": "上る",
      "type": "v5r",
      "meaning": "to rise, to ascend, to go up, to climb"
    },
    {
      "id": 28,
      "kana": "あかるい",
      "kanji": "明い",
      "type": "",
      "meaning": "bright, cheerful"
    },
    {
      "id": 29,
      "kana": "あかんぼう",
      "kanji": "",
      "type": "n",
      "meaning": "baby"
    },
    {
      "id": 30,
      "kana": "あき",
      "kanji": "明き",
      "type": "n",
      "meaning": "room, time to spare, emptiness"
    },
    {
      "id": 31,
      "kana": "あき",
      "kanji": "空き",
      "type": "n",
      "meaning": "room, time to spare, emptiness"
    },
    {
      "id": 32,
      "kana": "あき",
      "kanji": "秋",
      "type": "n-adv",
      "meaning": "autumn, fall"
    },
    {
      "id": 33,
      "kana": "あきらか",
      "kanji": "明らか",
      "type": "adj-na,n",
      "meaning": "obvious, evident, clear"
    },
    {
      "id": 34,
      "kana": "あきらめる",
      "kanji": "諦める",
      "type": "u-v",
      "meaning": "to give up, to abandon"
    },
    {
      "id": 35,
      "kana": "あきる",
      "kanji": "飽きる",
      "type": "u-v",
      "meaning": "to get tired of, to lose interest in"
    },
    {
      "id": 36,
      "kana": "あきれる",
      "kanji": "",
      "type": "u-v",
      "meaning": "to be amazed, to be shocked"
    },
    {
      "id": 37,
      "kana": "あく",
      "kanji": "開く",
      "type": "v5k",
      "meaning": "to open (eg a festival)"
    },
    {
      "id": 38,
      "kana": "あく",
      "kanji": "空く",
      "type": "v5k,vi",
      "meaning": "to open, to become open, to become empty"
    },
    {
      "id": 39,
      "kana": "あくしゅ",
      "kanji": "握手",
      "type": "n,vs",
      "meaning": "handshake"
    },
    {
      "id": 40,
      "kana": "アクセサリー",
      "kanji": "",
      "type": "n",
      "meaning": "accessory"
    },
    {
      "id": 41,
      "kana": "アクセント",
      "kanji": "",
      "type": "n",
      "meaning": "accent"
    },
    {
      "id": 42,
      "kana": "あくび",
      "kanji": "",
      "type": "n",
      "meaning": "yawn"
    },
    {
      "id": 43,
      "kana": "あくま",
      "kanji": "悪魔",
      "type": "n",
      "meaning": "devil, demon, evil spirit"
    },
    {
      "id": 44,
      "kana": "あくまで",
      "kanji": "飽くまで",
      "type": "adv",
      "meaning": "to the end, to the last, stubbornly"
    },
    {
      "id": 45,
      "kana": "あくる~",
      "kanji": "明くる~",
      "type": "",
      "meaning": "next, following"
    },
    {
      "id": 46,
      "kana": "あけがた",
      "kanji": "明け方",
      "type": "n-adv,n-t",
      "meaning": "dawn"
    },
    {
      "id": 47,
      "kana": "あける",
      "kanji": "開ける",
      "type": "v1",
      "meaning": "to become opened up"
    },
    {
      "id": 48,
      "kana": "あける",
      "kanji": "明ける",
      "type": "v1",
      "meaning": "to dawn, to become daylight"
    },
    {
      "id": 49,
      "kana": "あげる",
      "kanji": "上げる",
      "type": "v1",
      "meaning": "to give, to raise"
    },
    {
      "id": 50,
      "kana": "あげる",
      "kanji": "揚げる",
      "type": "v1",
      "meaning": "to lift, to fry"
    },
    {
      "id": 51,
      "kana": "あげる",
      "kanji": "挙げる",
      "type": "v1",
      "meaning": "to raise, to fly"
    },
    {
      "id": 52,
      "kana": "あげる (=やる)",
      "kanji": "",
      "type": "v1",
      "meaning": "to do for"
    },
    {
      "id": 53,
      "kana": "あこがれる",
      "kanji": "憧れる",
      "type": "v1",
      "meaning": "to long for, to yearn after, to admire"
    },
    {
      "id": 54,
      "kana": "あさ",
      "kanji": "朝",
      "type": "n-adv,n-t",
      "meaning": "morning"
    },
    {
      "id": 55,
      "kana": "あさい",
      "kanji": "浅い",
      "type": "adj",
      "meaning": "shallow, superficial"
    },
    {
      "id": 56,
      "kana": "あさって",
      "kanji": "",
      "type": "n-adv,n-t",
      "meaning": "day after tomorrow"
    },
    {
      "id": 57,
      "kana": "あし",
      "kanji": "足",
      "type": "n",
      "meaning": "foot, leg"
    },
    {
      "id": 58,
      "kana": "あじ",
      "kanji": "味",
      "type": "adj-na,n",
      "meaning": "flavor, taste"
    },
    {
      "id": 59,
      "kana": "アジア",
      "kanji": "",
      "type": "n",
      "meaning": "Asia"
    },
    {
      "id": 60,
      "kana": "あしあと",
      "kanji": "足跡",
      "type": "n",
      "meaning": "footprints"
    },
    {
      "id": 61,
      "kana": "あした",
      "kanji": "",
      "type": "n-t",
      "meaning": "tomorrow"
    },
    {
      "id": 62,
      "kana": "あしもと",
      "kanji": "足元",
      "type": "n",
      "meaning": "at one's feet, underfoot"
    },
    {
      "id": 63,
      "kana": "あじわう",
      "kanji": "味わう",
      "type": "v5u",
      "meaning": "to taste, to savor, to relish"
    },
    {
      "id": 64,
      "kana": "あす",
      "kanji": "明日",
      "type": "n-t",
      "meaning": "tomorrow"
    },
    {
      "id": 65,
      "kana": "あずかる",
      "kanji": "預かる",
      "type": "v5r,vt",
      "meaning": "to keep in custody, to receive on deposit, to take charge of"
    },
    {
      "id": 66,
      "kana": "あずける",
      "kanji": "預ける",
      "type": "v1,vt",
      "meaning": "to give into custody, to entrust, to deposit"
    },
    {
      "id": 67,
      "kana": "あせ",
      "kanji": "汗",
      "type": "n",
      "meaning": "sweat, perspiration"
    },
    {
      "id": 68,
      "kana": "あそこ",
      "kanji": "",
      "type": "n",
      "meaning": "there, over there"
    },
    {
      "id": 69,
      "kana": "あそび",
      "kanji": "遊び",
      "type": "n,n-suf",
      "meaning": "play"
    },
    {
      "id": 70,
      "kana": "あそぶ",
      "kanji": "遊ぶ",
      "type": "u-v",
      "meaning": "to play, to make a visit"
    },
    {
      "id": 71,
      "kana": "あたえる",
      "kanji": "与える",
      "type": "v1",
      "meaning": "to give, to present, to award"
    },
    {
      "id": 72,
      "kana": "あたたか(い)",
      "kanji": "暖かい",
      "type": "adj",
      "meaning": "warm, mild"
    },
    {
      "id": 73,
      "kana": "あたたまる",
      "kanji": "暖まる",
      "type": "v5r",
      "meaning": "to warm up, to get warm"
    },
    {
      "id": 74,
      "kana": "あたためる",
      "kanji": "暖める",
      "type": "v1",
      "meaning": "to warm, to heat"
    },
    {
      "id": 75,
      "kana": "あたま",
      "kanji": "頭",
      "type": "",
      "meaning": "head"
    },
    {
      "id": 76,
      "kana": "あたらしい",
      "kanji": "新しい",
      "type": "adj",
      "meaning": "new"
    },
    {
      "id": 77,
      "kana": "あたり",
      "kanji": "辺り",
      "type": "n",
      "meaning": "vicinity, nearby"
    },
    {
      "id": 78,
      "kana": "あたりまえ",
      "kanji": "",
      "type": "adj-na,adj-no,n",
      "meaning": "usual, common, ordinary"
    },
    {
      "id": 79,
      "kana": "あたる",
      "kanji": "当たる",
      "type": "v5r",
      "meaning": "to be hit, to be successful, to be equivalent to"
    },
    {
      "id": 80,
      "kana": "あちこち",
      "kanji": "",
      "type": "adv,n",
      "meaning": "here and there"
    },
    {
      "id": 81,
      "kana": "あひら",
      "kanji": "あひら",
      "type": "",
      "meaning": ""
    },
    {
      "id": 82,
      "kana": "あっち",
      "kanji": "",
      "type": "n",
      "meaning": "(col) over there"
    },
    {
      "id": 83,
      "kana": "あちらこちら",
      "kanji": "",
      "type": "adv,n",
      "meaning": "here and there"
    },
    {
      "id": 84,
      "kana": "あつい",
      "kanji": "厚い",
      "type": "adj",
      "meaning": "cordial, kind, warm(hearted), thick, deep"
    },
    {
      "id": 85,
      "kana": "あつい",
      "kanji": "暑い",
      "type": "adj",
      "meaning": "hot, warm"
    },
    {
      "id": 86,
      "kana": "あつい",
      "kanji": "熱い",
      "type": "adj",
      "meaning": "hot (thing)"
    },
    {
      "id": 87,
      "kana": "あつかう",
      "kanji": "扱う",
      "type": "v5u",
      "meaning": "to handle, to deal with, to treat"
    },
    {
      "id": 88,
      "kana": "あつかましい",
      "kanji": "厚かましい",
      "type": "adj",
      "meaning": "impudent, shameless, brazen"
    },
    {
      "id": 89,
      "kana": "あっしゅく",
      "kanji": "圧縮",
      "type": "n,vs",
      "meaning": "compression, condensation, pressure"
    },
    {
      "id": 90,
      "kana": "あつまり",
      "kanji": "集まり",
      "type": "n",
      "meaning": "gathering, meeting, assembly, collection"
    },
    {
      "id": 91,
      "kana": "あつまる",
      "kanji": "集る",
      "type": "v5r",
      "meaning": "(1) to gather, to crowd round, to swarm, to flock, (2) to extort from, to sponge off"
    },
    {
      "id": 92,
      "kana": "あつめる",
      "kanji": "集める",
      "type": "v1,vt",
      "meaning": "to collect, to assemble"
    },
    {
      "id": 93,
      "kana": "あてな",
      "kanji": "宛名",
      "type": "n",
      "meaning": "address, direction"
    },
    {
      "id": 94,
      "kana": "あてはまる",
      "kanji": "",
      "type": "v5r,vi",
      "meaning": "to be applicable, to come under (a category), to fulfill"
    },
    {
      "id": 95,
      "kana": "あてはめる",
      "kanji": "",
      "type": "v1,vt",
      "meaning": "to apply, to adapt"
    },
    {
      "id": 96,
      "kana": "あてる",
      "kanji": "当てる",
      "type": "v1",
      "meaning": "to hit, to apply a patch"
    },
    {
      "id": 97,
      "kana": "あと",
      "kanji": "後",
      "type": "adj-no,n",
      "meaning": "afterwards, since then, in the future"
    },
    {
      "id": 98,
      "kana": "あと",
      "kanji": "跡",
      "type": "n",
      "meaning": "(1) trace, tracks, mark, sign, (2) remains, ruins, (3) scar"
    },
    {
      "id": 99,
      "kana": "あと",
      "kanji": "",
      "type": "n",
      "meaning": "(1) trace, tracks, (2) remains, ruins, (3) scar"
    },
    {
      "id": 100,
      "kana": "あな",
      "kanji": "穴",
      "type": "n",
      "meaning": "hole"
    },
    {
      "id": 101,
      "kana": "アナウンサー",
      "kanji": "",
      "type": "n",
      "meaning": "announcer"
    },
    {
      "id": 102,
      "kana": "あなた",
      "kanji": "",
      "type": "n",
      "meaning": "(1) the other, the other side, (2) there, yonder, that"
    },
    {
      "id": 103,
      "kana": "あに",
      "kanji": "兄",
      "type": "n",
      "meaning": "(hum) older brother"
    },
    {
      "id": 104,
      "kana": "あね",
      "kanji": "姉",
      "type": "n",
      "meaning": "(hum) older sister"
    },
    {
      "id": 105,
      "kana": "あの",
      "kanji": "",
      "type": "adj-pn",
      "meaning": "that over there"
    },
    {
      "id": 106,
      "kana": "アパート",
      "kanji": "",
      "type": "n,adv",
      "meaning": "(1) apartment (abbr), (2) apartment building (abbr), (3) apart"
    },
    {
      "id": 107,
      "kana": "あばれる",
      "kanji": "暴れる",
      "type": "v1",
      "meaning": "to act violently, to rage, to struggle, to be riotous"
    },
    {
      "id": 108,
      "kana": "あびる",
      "kanji": "浴びる",
      "type": "v1",
      "meaning": "to bathe, to bask in the sun, to shower"
    },
    {
      "id": 109,
      "kana": "あぶない",
      "kanji": "危ない",
      "type": "adj",
      "meaning": "dangerous, critical, watch out!"
    },
    {
      "id": 110,
      "kana": "あぶら",
      "kanji": "油",
      "type": "n",
      "meaning": "oil"
    },
    {
      "id": 111,
      "kana": "あぶら",
      "kanji": "脂",
      "type": "n",
      "meaning": "fat, tallow, lard"
    },
    {
      "id": 112,
      "kana": "アフリカ",
      "kanji": "",
      "type": "n",
      "meaning": "Africa"
    },
    {
      "id": 113,
      "kana": "あぶる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to scorch"
    },
    {
      "id": 114,
      "kana": "あふれる",
      "kanji": "",
      "type": "v1",
      "meaning": "to flood, to overflow, to brim over"
    },
    {
      "id": 115,
      "kana": "あまい",
      "kanji": "甘い",
      "type": "adj",
      "meaning": "delicious, sweet, naive"
    },
    {
      "id": 116,
      "kana": "あまど",
      "kanji": "雨戸",
      "type": "n",
      "meaning": "sliding storm door"
    },
    {
      "id": 117,
      "kana": "あまやかす",
      "kanji": "甘やかす",
      "type": "v5s",
      "meaning": "to pamper, to spoil"
    },
    {
      "id": 118,
      "kana": "あまり",
      "kanji": "余り",
      "type": "adj-na,adv,n,n-suf",
      "meaning": "not very (used as adverb), not much"
    },
    {
      "id": 119,
      "kana": "あまり",
      "kanji": "余り",
      "type": "adj-na,adv,n,n-suf",
      "meaning": "not very (only used as adverb), not much, remainder, rest"
    },
    {
      "id": 120,
      "kana": "あまる",
      "kanji": "余る",
      "type": "v5r",
      "meaning": "to remain, to be left over, to be in excess, to be too many"
    },
    {
      "id": 121,
      "kana": "あみもの",
      "kanji": "編物",
      "type": "n",
      "meaning": "knitting, web"
    },
    {
      "id": 122,
      "kana": "あむ",
      "kanji": "編む",
      "type": "v5m",
      "meaning": "to knit"
    },
    {
      "id": 123,
      "kana": "あめ",
      "kanji": "雨",
      "type": "n",
      "meaning": "rain"
    },
    {
      "id": 124,
      "kana": "あめ",
      "kanji": "飴",
      "type": "n",
      "meaning": "(hard) candy"
    },
    {
      "id": 125,
      "kana": "アメリカ",
      "kanji": "",
      "type": "n",
      "meaning": "America"
    },
    {
      "id": 126,
      "kana": "あやうい",
      "kanji": "危うい",
      "type": "adj",
      "meaning": "dangerous, critical, grave"
    },
    {
      "id": 127,
      "kana": "あやしい",
      "kanji": "怪しい",
      "type": "adj",
      "meaning": "suspicious, dubious, doubtful"
    },
    {
      "id": 128,
      "kana": "あやまり",
      "kanji": "誤り",
      "type": "n",
      "meaning": "error"
    },
    {
      "id": 129,
      "kana": "あやまる",
      "kanji": "謝る",
      "type": "v5r",
      "meaning": "to apologize"
    },
    {
      "id": 130,
      "kana": "あら",
      "kanji": "粗",
      "type": "n",
      "meaning": "defect, flaw, blemish, weak point"
    },
    {
      "id": 131,
      "kana": "あらい",
      "kanji": "荒い",
      "type": "adj",
      "meaning": "rough, rude, wild"
    },
    {
      "id": 132,
      "kana": "あらい",
      "kanji": "粗い",
      "type": "adj",
      "meaning": "coarse, rough"
    },
    {
      "id": 133,
      "kana": "あらう",
      "kanji": "洗う",
      "type": "v5u",
      "meaning": "to wash"
    },
    {
      "id": 134,
      "kana": "あらし",
      "kanji": "嵐",
      "type": "n",
      "meaning": "storm, tempest"
    },
    {
      "id": 135,
      "kana": "あらすじ",
      "kanji": "",
      "type": "n",
      "meaning": "outline, summary"
    },
    {
      "id": 136,
      "kana": "あらそう",
      "kanji": "争う",
      "type": "v5u",
      "meaning": "to dispute, to argue, to be at variance, to compete"
    },
    {
      "id": 137,
      "kana": "あらた",
      "kanji": "新た",
      "type": "adj-na,n",
      "meaning": "new, fresh, novel"
    },
    {
      "id": 138,
      "kana": "あらためて",
      "kanji": "改めて",
      "type": "adv",
      "meaning": "another time, again, over again, anew, formally"
    },
    {
      "id": 139,
      "kana": "あらためる",
      "kanji": "改める",
      "type": "v1",
      "meaning": "to change, to alter, to reform, to revise"
    },
    {
      "id": 140,
      "kana": "あらゆる",
      "kanji": "",
      "type": "adj-pn",
      "meaning": "all, every"
    },
    {
      "id": 141,
      "kana": "あらわす",
      "kanji": "表す",
      "type": "v5s",
      "meaning": "to express, to show, to reveal"
    },
    {
      "id": 142,
      "kana": "あらわす",
      "kanji": "現す",
      "type": "v5s",
      "meaning": "to show, to indicate, to display"
    },
    {
      "id": 143,
      "kana": "あらわす",
      "kanji": "著す",
      "type": "v5s",
      "meaning": "to write, to publish"
    },
    {
      "id": 144,
      "kana": "あらわれ",
      "kanji": "現れ",
      "type": "n",
      "meaning": "embodiment, materialization"
    },
    {
      "id": 145,
      "kana": "あらわれる",
      "kanji": "現れる",
      "type": "v1",
      "meaning": "(1) to appear, to come in sight, to become visible, (2) to express oneself"
    },
    {
      "id": 146,
      "kana": "ありがたい",
      "kanji": "有難い",
      "type": "adj",
      "meaning": "grateful, thankful, welcome, appreciated, evoking gratitude"
    },
    {
      "id": 147,
      "kana": "ありがとう",
      "kanji": "",
      "type": "io",
      "meaning": "(conj,exp,int) Thank you"
    },
    {
      "id": 148,
      "kana": "ある",
      "kanji": "有る",
      "type": "v5r-i",
      "meaning": "to be, to have"
    },
    {
      "id": 149,
      "kana": "ある",
      "kanji": "在る",
      "type": "v5r-i",
      "meaning": "to live, to be"
    },
    {
      "id": 150,
      "kana": "ある",
      "kanji": "或",
      "type": "adj-pn",
      "meaning": "a certain..., some..."
    },
    {
      "id": 151,
      "kana": "あるいは",
      "kanji": "",
      "type": "adv,conj,exp",
      "meaning": "or, possibly"
    },
    {
      "id": 152,
      "kana": "あるく",
      "kanji": "歩く",
      "type": "v5k",
      "meaning": "to walk"
    },
    {
      "id": 153,
      "kana": "アルバイト",
      "kanji": "",
      "type": "n",
      "meaning": "part-time job"
    },
    {
      "id": 154,
      "kana": "アルバム",
      "kanji": "",
      "type": "n",
      "meaning": "album"
    },
    {
      "id": 155,
      "kana": "あれ",
      "kanji": "",
      "type": "n",
      "meaning": "that, over there"
    },
    {
      "id": 156,
      "kana": "あれこれ",
      "kanji": "",
      "type": "adv,n",
      "meaning": "one thing or another, this and that, this or that"
    },
    {
      "id": 157,
      "kana": "あれる",
      "kanji": "荒れる",
      "type": "v1",
      "meaning": "to be stormy, to be rough, to lose one's temper"
    },
    {
      "id": 158,
      "kana": "あわ",
      "kanji": "泡",
      "type": "n",
      "meaning": "bubble, foam, froth, head on beer"
    },
    {
      "id": 159,
      "kana": "あわせる",
      "kanji": "合わせる",
      "type": "v1",
      "meaning": "to join together, to be opposite, to face, to unite"
    },
    {
      "id": 160,
      "kana": "あわただしい",
      "kanji": "",
      "type": "adj",
      "meaning": "busy, hurried, confused, flurried"
    },
    {
      "id": 161,
      "kana": "あわてる",
      "kanji": "",
      "type": "v1",
      "meaning": "to become confused"
    },
    {
      "id": 162,
      "kana": "あわれ",
      "kanji": "哀れ",
      "type": "adj-na,int,n",
      "meaning": "helpless, pity, sorrow, grief"
    },
    {
      "id": 163,
      "kana": "あん",
      "kanji": "案",
      "type": "n,n-suf",
      "meaning": "plan, suffix meaning draft"
    },
    {
      "id": 164,
      "kana": "あんい",
      "kanji": "安易",
      "type": "adj-na,n",
      "meaning": "easy-going"
    },
    {
      "id": 165,
      "kana": "あんがい",
      "kanji": "案外",
      "type": "adj-na,n-adv",
      "meaning": "unexpectedly"
    },
    {
      "id": 166,
      "kana": "あんき",
      "kanji": "暗記",
      "type": "n,vs",
      "meaning": "memorization, learning by heart"
    },
    {
      "id": 167,
      "kana": "あんしん",
      "kanji": "安心",
      "type": "n",
      "meaning": "peace of mind, relief"
    },
    {
      "id": 168,
      "kana": "あんぜん",
      "kanji": "安全",
      "type": "adj-na,n",
      "meaning": "safety, security"
    },
    {
      "id": 169,
      "kana": "あんてい",
      "kanji": "安定",
      "type": "adj-na,n,vs",
      "meaning": "stability, equilibrium"
    },
    {
      "id": 170,
      "kana": "アンテナ",
      "kanji": "",
      "type": "n",
      "meaning": "antenna"
    },
    {
      "id": 171,
      "kana": "あんな",
      "kanji": "",
      "type": "adj-na,adj-pn",
      "meaning": "such, so, that, sort of"
    },
    {
      "id": 172,
      "kana": "あんない",
      "kanji": "案内",
      "type": "n,vs",
      "meaning": "information, guidance, leading"
    },
    {
      "id": 173,
      "kana": "あんなに",
      "kanji": "",
      "type": "exp",
      "meaning": "to that extent, to that degree"
    },
    {
      "id": 174,
      "kana": "あんまり",
      "kanji": "",
      "type": "adj-na,adv,n,n-suf",
      "meaning": "not very, not much, remainder, rest"
    },
    {
      "id": 175,
      "kana": "い",
      "kanji": "胃",
      "type": "n",
      "meaning": "stomach"
    },
    {
      "id": 176,
      "kana": "~い",
      "kanji": "~位",
      "type": "",
      "meaning": "place/rank"
    },
    {
      "id": 177,
      "kana": "いい",
      "kanji": "",
      "type": "adj",
      "meaning": "good"
    },
    {
      "id": 178,
      "kana": "よい",
      "kanji": "",
      "type": "adj",
      "meaning": "good, nice, pleasant, ok"
    },
    {
      "id": 179,
      "kana": "いいえ",
      "kanji": "",
      "type": "int,n",
      "meaning": "no, not at all"
    },
    {
      "id": 180,
      "kana": "いえ",
      "kanji": "いえ",
      "type": "int,n",
      "meaning": "TODO same as いいえ?"
    },
    {
      "id": 181,
      "kana": "いいだす",
      "kanji": "言い出す",
      "type": "v5s",
      "meaning": "to start talking, to speak, to tell, to propose, to suggest, to break the ice"
    },
    {
      "id": 182,
      "kana": "いいつける",
      "kanji": "言い付ける",
      "type": "v1",
      "meaning": "to tell, to tell on (someone), to order"
    },
    {
      "id": 183,
      "kana": "いいん",
      "kanji": "委員",
      "type": "n",
      "meaning": "committee member"
    },
    {
      "id": 184,
      "kana": "いう",
      "kanji": "言う",
      "type": "v5u",
      "meaning": "to say"
    },
    {
      "id": 185,
      "kana": "ゆう",
      "kanji": "言う",
      "type": "v5u",
      "meaning": "to say"
    },
    {
      "id": 186,
      "kana": "いえ",
      "kanji": "家",
      "type": "suf",
      "meaning": "house, family"
    },
    {
      "id": 187,
      "kana": "いか",
      "kanji": "以下",
      "type": "n",
      "meaning": "less than, up to, below, under"
    },
    {
      "id": 188,
      "kana": "いがい",
      "kanji": "以外",
      "type": "n-adv",
      "meaning": "with the exception of, excepting"
    },
    {
      "id": 189,
      "kana": "いがい",
      "kanji": "意外",
      "type": "adj-na,n",
      "meaning": "unexpected, surprising"
    },
    {
      "id": 190,
      "kana": "いかが",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "how, in what way"
    },
    {
      "id": 191,
      "kana": "いがく",
      "kanji": "医学",
      "type": "n",
      "meaning": "medical science, medicine"
    },
    {
      "id": 192,
      "kana": "いき",
      "kanji": "行き",
      "type": "n",
      "meaning": "going"
    },
    {
      "id": 193,
      "kana": "ゆき",
      "kanji": "行き",
      "type": "n",
      "meaning": "going"
    },
    {
      "id": 194,
      "kana": "いき",
      "kanji": "息",
      "type": "n",
      "meaning": "breath, tone"
    },
    {
      "id": 195,
      "kana": "いぎ",
      "kanji": "意義",
      "type": "n",
      "meaning": "meaning, significance"
    },
    {
      "id": 196,
      "kana": "いきいき",
      "kanji": "生き生き",
      "type": "adv,n",
      "meaning": "vividly, lively"
    },
    {
      "id": 197,
      "kana": "いきおい",
      "kanji": "勢い",
      "type": "adv,n",
      "meaning": "force, vigor, energy, spirit"
    },
    {
      "id": 198,
      "kana": "いきなり",
      "kanji": "",
      "type": "adv",
      "meaning": "(uk) abruptly, suddenly, all of a sudden, without warning"
    },
    {
      "id": 199,
      "kana": "いきもの",
      "kanji": "生き物",
      "type": "n",
      "meaning": "living thing, animal"
    },
    {
      "id": 200,
      "kana": "いきる",
      "kanji": "生きる",
      "type": "v1",
      "meaning": "to live, to exist"
    },
    {
      "id": 201,
      "kana": "いく~",
      "kanji": "幾~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 202,
      "kana": "いく",
      "kanji": "行く",
      "type": "v5k-s",
      "meaning": "to go"
    },
    {
      "id": 203,
      "kana": "ゆく",
      "kanji": "行く",
      "type": "v5k-s",
      "meaning": "to go"
    },
    {
      "id": 204,
      "kana": "いくじ",
      "kanji": "育児",
      "type": "n",
      "meaning": "childcare, nursing, upbringing"
    },
    {
      "id": 205,
      "kana": "いくつ",
      "kanji": "幾つ",
      "type": "n",
      "meaning": "how many?, how old?"
    },
    {
      "id": 206,
      "kana": "いくぶん",
      "kanji": "幾分",
      "type": "adv,n",
      "meaning": "somewhat"
    },
    {
      "id": 207,
      "kana": "いくら",
      "kanji": "幾ら",
      "type": "adv,n",
      "meaning": "how much?, how many?"
    },
    {
      "id": 208,
      "kana": "いくら~ても",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 209,
      "kana": "いけ",
      "kanji": "池",
      "type": "n",
      "meaning": "pond"
    },
    {
      "id": 210,
      "kana": "いけない",
      "kanji": "",
      "type": "adj,exp",
      "meaning": "must not do, bad, wrong, not good"
    },
    {
      "id": 211,
      "kana": "いけばな",
      "kanji": "生け花",
      "type": "n",
      "meaning": "(1) flower arrangement"
    },
    {
      "id": 212,
      "kana": "いけん",
      "kanji": "意見",
      "type": "n",
      "meaning": "opinion, view"
    },
    {
      "id": 213,
      "kana": "いご",
      "kanji": "以後",
      "type": "n-adv,n-t",
      "meaning": "after this, from now on, hereafter, thereafter"
    },
    {
      "id": 214,
      "kana": "いこう",
      "kanji": "以降",
      "type": "n-adv,n-t",
      "meaning": "on and after, hereafter, thereafter"
    },
    {
      "id": 215,
      "kana": "イコール",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "equal"
    },
    {
      "id": 216,
      "kana": "いさましい",
      "kanji": "勇ましい",
      "type": "adj",
      "meaning": "brave, valiant, gallant, courageous"
    },
    {
      "id": 217,
      "kana": "いし",
      "kanji": "石",
      "type": "n",
      "meaning": "volume measure (approx. 180l, 5 bushels, 10 cub.ft)"
    },
    {
      "id": 218,
      "kana": "いし",
      "kanji": "医師",
      "type": "n",
      "meaning": "doctor, physician"
    },
    {
      "id": 219,
      "kana": "いし",
      "kanji": "意思",
      "type": "n",
      "meaning": "intention, purpose"
    },
    {
      "id": 220,
      "kana": "いし",
      "kanji": "意志",
      "type": "n",
      "meaning": "will, volition"
    },
    {
      "id": 221,
      "kana": "いじ",
      "kanji": "維持",
      "type": "n,vs",
      "meaning": "maintenance, preservation"
    },
    {
      "id": 222,
      "kana": "いしき",
      "kanji": "意識",
      "type": "n,vs",
      "meaning": "consciousness, senses"
    },
    {
      "id": 223,
      "kana": "いじめる",
      "kanji": "",
      "type": "v1",
      "meaning": "to tease, to torment"
    },
    {
      "id": 224,
      "kana": "いしゃ",
      "kanji": "医者",
      "type": "n",
      "meaning": "doctor (medical)"
    },
    {
      "id": 225,
      "kana": "いじょう",
      "kanji": "以上",
      "type": "n-adv,n-t",
      "meaning": "more than, exceeding, greater than"
    },
    {
      "id": 226,
      "kana": "いじょう",
      "kanji": "異常",
      "type": "adj-na,n",
      "meaning": "strangeness, abnormality, disorder"
    },
    {
      "id": 227,
      "kana": "いしょくじゅう",
      "kanji": "衣食住",
      "type": "n",
      "meaning": "necessities of life (food, clothing, etc.)"
    },
    {
      "id": 228,
      "kana": "いじわる",
      "kanji": "意地悪",
      "type": "adj-na,n",
      "meaning": "malicious, ill-tempered, unkind"
    },
    {
      "id": 229,
      "kana": "いす",
      "kanji": "椅子",
      "type": "n",
      "meaning": "chair"
    },
    {
      "id": 230,
      "kana": "いずみ",
      "kanji": "泉",
      "type": "n",
      "meaning": "spring, fountain"
    },
    {
      "id": 231,
      "kana": "いずれ",
      "kanji": "",
      "type": "adv,n",
      "meaning": "where, which, who"
    },
    {
      "id": 232,
      "kana": "いぜん",
      "kanji": "以前",
      "type": "n-adv,n-t",
      "meaning": "ago, since, before, previous"
    },
    {
      "id": 233,
      "kana": "いそがしい",
      "kanji": "忙しい",
      "type": "adj",
      "meaning": "busy, irritated"
    },
    {
      "id": 234,
      "kana": "いそぐ",
      "kanji": "急ぐ",
      "type": "v5g",
      "meaning": "to hurry, to rush"
    },
    {
      "id": 235,
      "kana": "いた",
      "kanji": "板",
      "type": "n",
      "meaning": "board, plank"
    },
    {
      "id": 236,
      "kana": "いたい",
      "kanji": "痛い",
      "type": "adj",
      "meaning": "painful"
    },
    {
      "id": 237,
      "kana": "いだい",
      "kanji": "偉大",
      "type": "adj-na,n",
      "meaning": "greatness"
    },
    {
      "id": 238,
      "kana": "いだく",
      "kanji": "抱く",
      "type": "v5k",
      "meaning": "(sl) to embrace, to hug, to harbour, to entertain"
    },
    {
      "id": 239,
      "kana": "いたす",
      "kanji": "致す",
      "type": "v5s",
      "meaning": "(hum) to do"
    },
    {
      "id": 240,
      "kana": "いたずら",
      "kanji": "",
      "type": "adj-na,n,vs",
      "meaning": "tease, prank, trick, practical joke"
    },
    {
      "id": 241,
      "kana": "いただきます",
      "kanji": "",
      "type": "exp",
      "meaning": "expression of gratitude before meals"
    },
    {
      "id": 242,
      "kana": "いただく",
      "kanji": "頂く",
      "type": "v5k",
      "meaning": "to receive, to take food or drink (hum)"
    },
    {
      "id": 243,
      "kana": "いたみ",
      "kanji": "痛み",
      "type": "n",
      "meaning": "pain, ache, sore, grief, distress"
    },
    {
      "id": 244,
      "kana": "いたる",
      "kanji": "至る",
      "type": "v5r",
      "meaning": "to come, to arrive"
    },
    {
      "id": 245,
      "kana": "いち",
      "kanji": "",
      "type": "n",
      "meaning": "market, fair"
    },
    {
      "id": 246,
      "kana": "（日本一）",
      "kanji": "~いち",
      "type": "",
      "meaning": ""
    },
    {
      "id": 247,
      "kana": "いち",
      "kanji": "位置",
      "type": "n,vs",
      "meaning": "place, situation, position, location"
    },
    {
      "id": 248,
      "kana": "いちいち",
      "kanji": "",
      "type": "adv,n",
      "meaning": "one by one, separately"
    },
    {
      "id": 249,
      "kana": "いちおう",
      "kanji": "一応",
      "type": "adv",
      "meaning": "once, tentatively, in outline, for the time being"
    },
    {
      "id": 250,
      "kana": "いちじ",
      "kanji": "一時",
      "type": "n-adv,n",
      "meaning": "moment, time"
    },
    {
      "id": 251,
      "kana": "いちだんと",
      "kanji": "一段と",
      "type": "adv",
      "meaning": "greater, more, further, still more"
    },
    {
      "id": 252,
      "kana": "いちど",
      "kanji": "一度",
      "type": "n-adv",
      "meaning": "once, one time, on one occasion"
    },
    {
      "id": 253,
      "kana": "いちどに",
      "kanji": "一度に",
      "type": "adv",
      "meaning": "all at once"
    },
    {
      "id": 254,
      "kana": "いちば",
      "kanji": "市場",
      "type": "n",
      "meaning": "(the) market (as a concept)"
    },
    {
      "id": 255,
      "kana": "いちばん",
      "kanji": "一番",
      "type": "n-adv",
      "meaning": "best, first, number one"
    },
    {
      "id": 256,
      "kana": "いちりゅう",
      "kanji": "一流",
      "type": "n",
      "meaning": "first class, top grade, foremost, top-notch"
    },
    {
      "id": 257,
      "kana": "いつ",
      "kanji": "佚",
      "type": "n",
      "meaning": "be lost, peace, hide, mistake, beautiful, in turn"
    },
    {
      "id": 258,
      "kana": "いつか",
      "kanji": "五日",
      "type": "n",
      "meaning": "five days, the fifth day (of the month)"
    },
    {
      "id": 259,
      "kana": "いつか",
      "kanji": "",
      "type": "adv",
      "meaning": "sometime, someday, one day"
    },
    {
      "id": 260,
      "kana": "いっか",
      "kanji": "一家",
      "type": "n",
      "meaning": "a house, a home, a family, a household"
    },
    {
      "id": 261,
      "kana": "いっさくじつ",
      "kanji": "一昨日",
      "type": "n-adv,n-t",
      "meaning": "day before yesterday"
    },
    {
      "id": 262,
      "kana": "いっさくねん",
      "kanji": "一昨年",
      "type": "n-adv,n-t",
      "meaning": "year before last"
    },
    {
      "id": 263,
      "kana": "いっしゅ",
      "kanji": "一種",
      "type": "adv,n",
      "meaning": "a species, a kind, a variety"
    },
    {
      "id": 264,
      "kana": "いっしゅん",
      "kanji": "一瞬",
      "type": "n-adv,n-t",
      "meaning": "a moment, an instant"
    },
    {
      "id": 265,
      "kana": "いっしょ",
      "kanji": "一緒",
      "type": "adv,n",
      "meaning": "together, meeting, company"
    },
    {
      "id": 266,
      "kana": "いっしょう",
      "kanji": "一生",
      "type": "n-adv,n-t",
      "meaning": "whole life, a lifetime, all through life"
    },
    {
      "id": 267,
      "kana": "いっしょうけんめい",
      "kanji": "一生懸命",
      "type": "adj-na,n-adv,n",
      "meaning": "very hard, with utmost effort, with all one's might"
    },
    {
      "id": 268,
      "kana": "いっせい",
      "kanji": "一斉",
      "type": "n-adv,n",
      "meaning": "simultaneous, all at once"
    },
    {
      "id": 269,
      "kana": "いっそう",
      "kanji": "一層",
      "type": "n-adv,n",
      "meaning": "much more, still more, all the more"
    },
    {
      "id": 270,
      "kana": "いったい",
      "kanji": "一体",
      "type": "adv,n",
      "meaning": "(1) one object, one body, (2) what on earth?, really?, (3) generally"
    },
    {
      "id": 271,
      "kana": "いったん",
      "kanji": "一旦",
      "type": "adv",
      "meaning": "once, for a moment, one morning, temporarily"
    },
    {
      "id": 272,
      "kana": "いっち",
      "kanji": "一致",
      "type": "n,vs",
      "meaning": "(1) coincidence, agreement, (2) conformity"
    },
    {
      "id": 273,
      "kana": "いつつ",
      "kanji": "五つ",
      "type": "n",
      "meaning": "five"
    },
    {
      "id": 274,
      "kana": "いってい",
      "kanji": "一定",
      "type": "adj-no,n,vs",
      "meaning": "fixed, settled, definite"
    },
    {
      "id": 275,
      "kana": "いっていらっしゃい",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 276,
      "kana": "いってらっしゃい",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 277,
      "kana": "いってまいります",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 278,
      "kana": "いってきます",
      "kanji": "",
      "type": "",
      "meaning": "Lit. I'll go and come back, \"I'm going, see you later"
    },
    {
      "id": 279,
      "kana": "いつでも",
      "kanji": "",
      "type": "adv",
      "meaning": "(at) any time, always, at all times, never (neg)"
    },
    {
      "id": 280,
      "kana": "いつのまにか",
      "kanji": "",
      "type": "adv",
      "meaning": "before one knows, unnoticed, unawares"
    },
    {
      "id": 281,
      "kana": "いっぱい",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "(1) a cup of, a drink, (2) full"
    },
    {
      "id": 282,
      "kana": "いっぱん",
      "kanji": "一般",
      "type": "adj-no,n",
      "meaning": "general, liberal, universal, ordinary, average"
    },
    {
      "id": 283,
      "kana": "いっぽう",
      "kanji": "一方",
      "type": "conj,n-adv,n",
      "meaning": "(1) on the other hand, (2) meanwhile, (3) only, simple, in turn"
    },
    {
      "id": 284,
      "kana": "いつまでも",
      "kanji": "",
      "type": "adv",
      "meaning": "forever, for good, eternally, as long as one likes, indefinitely"
    },
    {
      "id": 285,
      "kana": "いつも",
      "kanji": "いつも",
      "type": "adv,n",
      "meaning": "always, usually, every time, never (with neg. verb)"
    },
    {
      "id": 286,
      "kana": "いてん",
      "kanji": "移転",
      "type": "n,vs",
      "meaning": "moving, transfer, demise"
    },
    {
      "id": 287,
      "kana": "いと",
      "kanji": "糸",
      "type": "n,n-suf",
      "meaning": "thread, yarn, string"
    },
    {
      "id": 288,
      "kana": "いど",
      "kanji": "井戸",
      "type": "n",
      "meaning": "water well"
    },
    {
      "id": 289,
      "kana": "いど",
      "kanji": "緯度",
      "type": "n",
      "meaning": "latitude (nav.)"
    },
    {
      "id": 290,
      "kana": "いどう",
      "kanji": "移動",
      "type": "n,vs",
      "meaning": "removal, migration, movement"
    },
    {
      "id": 291,
      "kana": "いとこ",
      "kanji": "従兄弟",
      "type": "n",
      "meaning": "cousin (male)"
    },
    {
      "id": 292,
      "kana": "いとこ",
      "kanji": "従姉妹",
      "type": "n",
      "meaning": "cousin (female)"
    },
    {
      "id": 293,
      "kana": "いない",
      "kanji": "以内",
      "type": "n,n-suf",
      "meaning": "within, inside of, less than"
    },
    {
      "id": 294,
      "kana": "いなか",
      "kanji": "田舎",
      "type": "gikun",
      "meaning": "(n) rural, not particularly urban, countryside, suburb"
    },
    {
      "id": 295,
      "kana": "いぬ",
      "kanji": "犬",
      "type": "n",
      "meaning": "dog"
    },
    {
      "id": 296,
      "kana": "いね",
      "kanji": "稲",
      "type": "n",
      "meaning": "rice-plant"
    },
    {
      "id": 297,
      "kana": "いねむり",
      "kanji": "居眠り",
      "type": "n,vs",
      "meaning": "dozing, nodding off"
    },
    {
      "id": 298,
      "kana": "いのち",
      "kanji": "命",
      "type": "n",
      "meaning": "command, decree, life, destiny"
    },
    {
      "id": 299,
      "kana": "いのる",
      "kanji": "祈る",
      "type": "v5r,vi",
      "meaning": "to pray, to wish"
    },
    {
      "id": 300,
      "kana": "いばる",
      "kanji": "威張る",
      "type": "v5r",
      "meaning": "to be proud, to swagger"
    },
    {
      "id": 301,
      "kana": "いはん",
      "kanji": "違反",
      "type": "n",
      "meaning": "violation (of law), transgression, infringement, breach"
    },
    {
      "id": 302,
      "kana": "いふく",
      "kanji": "衣服",
      "type": "n",
      "meaning": "clothes"
    },
    {
      "id": 303,
      "kana": "いま",
      "kanji": "今",
      "type": "",
      "meaning": "this, now"
    },
    {
      "id": 304,
      "kana": "いま",
      "kanji": "居間",
      "type": "n",
      "meaning": "living room (western style)"
    },
    {
      "id": 305,
      "kana": "いまに",
      "kanji": "今に",
      "type": "adv",
      "meaning": "before long, even now"
    },
    {
      "id": 306,
      "kana": "いまにも",
      "kanji": "今にも",
      "type": "adv",
      "meaning": "at any time, soon"
    },
    {
      "id": 307,
      "kana": "いみ",
      "kanji": "意味",
      "type": "n,vs",
      "meaning": "meaning, significance"
    },
    {
      "id": 308,
      "kana": "イメージ",
      "kanji": "",
      "type": "n",
      "meaning": "one's image"
    },
    {
      "id": 309,
      "kana": "いもうと",
      "kanji": "妹",
      "type": "n",
      "meaning": "(hum) younger sister"
    },
    {
      "id": 310,
      "kana": "いや",
      "kanji": "嫌",
      "type": "adj-na,n",
      "meaning": "disagreeable, detestable, unpleasant, reluctant"
    },
    {
      "id": 311,
      "kana": "いや",
      "kanji": "否",
      "type": "n",
      "meaning": "no, the noes"
    },
    {
      "id": 312,
      "kana": "いやがる",
      "kanji": "嫌がる",
      "type": "v5r",
      "meaning": "to hate, to dislike"
    },
    {
      "id": 313,
      "kana": "いよいよ",
      "kanji": "",
      "type": "adv",
      "meaning": "more and more, all the more, increasingly, at last, beyond doubt"
    },
    {
      "id": 314,
      "kana": "いらい",
      "kanji": "以来",
      "type": "n-adv,n-t",
      "meaning": "since, henceforth"
    },
    {
      "id": 315,
      "kana": "いらい",
      "kanji": "依頼",
      "type": "n,vs",
      "meaning": "(1) request, commission, dispatch, (2) dependence, trust"
    },
    {
      "id": 316,
      "kana": "いらいら",
      "kanji": "",
      "type": "oK(adv,n,vs) (uk)",
      "meaning": "getting nervous, irritation"
    },
    {
      "id": 317,
      "kana": "いらっしゃい",
      "kanji": "",
      "type": "int,n",
      "meaning": "welcome"
    },
    {
      "id": 318,
      "kana": "いらっしゃる",
      "kanji": "",
      "type": "v5aru",
      "meaning": "(hon) to be, to come, to go"
    },
    {
      "id": 319,
      "kana": "いりぐち",
      "kanji": "入口",
      "type": "n",
      "meaning": "entrance, gate, approach, mouth"
    },
    {
      "id": 320,
      "kana": "いりょう",
      "kanji": "医療",
      "type": "n",
      "meaning": "medical care, medical treatment"
    },
    {
      "id": 321,
      "kana": "いる",
      "kanji": "居る",
      "type": "v5r",
      "meaning": "to be (animate), to be, to exist"
    },
    {
      "id": 322,
      "kana": "いる",
      "kanji": "要る",
      "type": "v5r",
      "meaning": "to need"
    },
    {
      "id": 323,
      "kana": "いる",
      "kanji": "煎る",
      "type": "v5r",
      "meaning": "to parch, to fry"
    },
    {
      "id": 324,
      "kana": "いる",
      "kanji": "炒る",
      "type": "",
      "meaning": ""
    },
    {
      "id": 325,
      "kana": "いれもの",
      "kanji": "入れ物",
      "type": "n",
      "meaning": "container, case, receptacle"
    },
    {
      "id": 326,
      "kana": "いれる",
      "kanji": "入れる",
      "type": "v1",
      "meaning": "to put in, to take in"
    },
    {
      "id": 327,
      "kana": "いろ",
      "kanji": "色",
      "type": "n",
      "meaning": "(1) colour, (2) sensuality, lust"
    },
    {
      "id": 328,
      "kana": "いわ",
      "kanji": "岩",
      "type": "n",
      "meaning": "rock, crag"
    },
    {
      "id": 329,
      "kana": "いわい",
      "kanji": "祝い",
      "type": "n",
      "meaning": "celebration, festival"
    },
    {
      "id": 330,
      "kana": "いわう",
      "kanji": "祝う",
      "type": "v5u",
      "meaning": "to congratulate, to celebrate"
    },
    {
      "id": 331,
      "kana": "いわば",
      "kanji": "言わば",
      "type": "adv",
      "meaning": "so to speak"
    },
    {
      "id": 332,
      "kana": "いわゆる",
      "kanji": "",
      "type": "adj-pn,adv",
      "meaning": "the so-called, so to speak"
    },
    {
      "id": 333,
      "kana": "~いん",
      "kanji": "~員",
      "type": "",
      "meaning": ""
    },
    {
      "id": 334,
      "kana": "インク",
      "kanji": "",
      "type": "n",
      "meaning": "ink"
    },
    {
      "id": 335,
      "kana": "インキ",
      "kanji": "",
      "type": "n",
      "meaning": "ink"
    },
    {
      "id": 336,
      "kana": "いんさつ",
      "kanji": "印刷",
      "type": "n,vs",
      "meaning": "printing"
    },
    {
      "id": 337,
      "kana": "いんしょう",
      "kanji": "印象",
      "type": "n",
      "meaning": "impression"
    },
    {
      "id": 338,
      "kana": "いんたい",
      "kanji": "引退",
      "type": "n,vs",
      "meaning": "retire"
    },
    {
      "id": 339,
      "kana": "インタビュー",
      "kanji": "",
      "type": "n",
      "meaning": "interview"
    },
    {
      "id": 340,
      "kana": "いんよう",
      "kanji": "引用",
      "type": "n",
      "meaning": "quotation, citation"
    },
    {
      "id": 341,
      "kana": "いんりょく",
      "kanji": "引力",
      "type": "n",
      "meaning": "gravity"
    },
    {
      "id": 342,
      "kana": "ウイスキー",
      "kanji": "",
      "type": "n",
      "meaning": "whisky"
    },
    {
      "id": 343,
      "kana": "ウーマン",
      "kanji": "",
      "type": "n",
      "meaning": "woman"
    },
    {
      "id": 344,
      "kana": "ウール",
      "kanji": "",
      "type": "n",
      "meaning": "wool"
    },
    {
      "id": 345,
      "kana": "うえ",
      "kanji": "上",
      "type": "n,pref,suf",
      "meaning": "top, best, high class, going up"
    },
    {
      "id": 346,
      "kana": "うわ",
      "kanji": "上",
      "type": "n,pref,suf",
      "meaning": "upper, outer, surface"
    },
    {
      "id": 347,
      "kana": "ウエートレス",
      "kanji": "",
      "type": "n",
      "meaning": "waitress"
    },
    {
      "id": 348,
      "kana": "うえき",
      "kanji": "植木",
      "type": "n",
      "meaning": "garden shrubs, trees, potted plant"
    },
    {
      "id": 349,
      "kana": "うえる",
      "kanji": "植える",
      "type": "v1",
      "meaning": "to plant, to grow"
    },
    {
      "id": 350,
      "kana": "うえる",
      "kanji": "飢える",
      "type": "v1",
      "meaning": "to starve"
    },
    {
      "id": 351,
      "kana": "うお",
      "kanji": "魚",
      "type": "n",
      "meaning": "fish"
    },
    {
      "id": 352,
      "kana": "うがい",
      "kanji": "",
      "type": "n,vs",
      "meaning": "gargle, rinse mouth"
    },
    {
      "id": 353,
      "kana": "うかがう",
      "kanji": "伺う",
      "type": "v5u,vi,vt",
      "meaning": "(hon) to visit, to ask, to inquire"
    },
    {
      "id": 354,
      "kana": "うかぶ",
      "kanji": "浮ぶ",
      "type": "v5b",
      "meaning": "to float, to rise to surface, to come to mind"
    },
    {
      "id": 355,
      "kana": "うかべる",
      "kanji": "浮かべる",
      "type": "v1",
      "meaning": "to float, to express, to look (sad, glad)"
    },
    {
      "id": 356,
      "kana": "うく",
      "kanji": "浮く",
      "type": "v5k",
      "meaning": "to float, to become merry, to become loose"
    },
    {
      "id": 357,
      "kana": "うけたまわる",
      "kanji": "承る",
      "type": "v5r",
      "meaning": "(hum) to hear, to be told, to know"
    },
    {
      "id": 358,
      "kana": "うけつけ",
      "kanji": "受付",
      "type": "n",
      "meaning": "receipt, acceptance, reception (desk), information desk"
    },
    {
      "id": 359,
      "kana": "うけとり",
      "kanji": "受取",
      "type": "n",
      "meaning": "receipt"
    },
    {
      "id": 360,
      "kana": "うけとる",
      "kanji": "受け取る",
      "type": "v5r",
      "meaning": "to receive, to get, to accept, to take"
    },
    {
      "id": 361,
      "kana": "うけもつ",
      "kanji": "受け持つ",
      "type": "v5t",
      "meaning": "to take (be in) charge of"
    },
    {
      "id": 362,
      "kana": "うける",
      "kanji": "受ける",
      "type": "v1",
      "meaning": "to undertake, to accept"
    },
    {
      "id": 363,
      "kana": "うごかす",
      "kanji": "動かす",
      "type": "v5s,vt",
      "meaning": "to move, to shift"
    },
    {
      "id": 364,
      "kana": "うごく",
      "kanji": "動く",
      "type": "v5k,vi",
      "meaning": "to move, to work, to be touched, to change"
    },
    {
      "id": 365,
      "kana": "うさぎ",
      "kanji": "兎",
      "type": "n",
      "meaning": "rabbit, hare, cony"
    },
    {
      "id": 366,
      "kana": "うし",
      "kanji": "牛",
      "type": "n",
      "meaning": "cattle, cow"
    },
    {
      "id": 367,
      "kana": "うしなう",
      "kanji": "失う",
      "type": "v5u",
      "meaning": "to lose, to part with"
    },
    {
      "id": 368,
      "kana": "うしろ",
      "kanji": "後",
      "type": "adj-no,n",
      "meaning": "afterwards, since then, in the future"
    },
    {
      "id": 369,
      "kana": "うすい",
      "kanji": "薄い",
      "type": "adj",
      "meaning": "thin, weak, watery, diluted"
    },
    {
      "id": 370,
      "kana": "うすぐらい",
      "kanji": "薄暗い",
      "type": "adj",
      "meaning": "dim, gloomy"
    },
    {
      "id": 371,
      "kana": "うすめる",
      "kanji": "薄める",
      "type": "v1",
      "meaning": "to dilute, to water down"
    },
    {
      "id": 372,
      "kana": "うそ",
      "kanji": "嘘",
      "type": "n",
      "meaning": "lie, falsehood, incorrect fact, inappropriate"
    },
    {
      "id": 373,
      "kana": "うた",
      "kanji": "歌",
      "type": "n",
      "meaning": "song, poetry"
    },
    {
      "id": 374,
      "kana": "うたう",
      "kanji": "歌う",
      "type": "v5u",
      "meaning": "to sing"
    },
    {
      "id": 375,
      "kana": "うたがう",
      "kanji": "疑う",
      "type": "v5u",
      "meaning": "to doubt, to distrust, to be suspicious of, to suspect"
    },
    {
      "id": 376,
      "kana": "うち",
      "kanji": "内",
      "type": "n",
      "meaning": "inside"
    },
    {
      "id": 377,
      "kana": "うち",
      "kanji": "家",
      "type": "suf",
      "meaning": "house, family"
    },
    {
      "id": 378,
      "kana": "（三つの~）",
      "kanji": "うち",
      "type": "n",
      "meaning": "inside"
    },
    {
      "id": 379,
      "kana": "うちあわせ",
      "kanji": "打合せ",
      "type": "n",
      "meaning": "business meeting, previous arrangement, appointment"
    },
    {
      "id": 380,
      "kana": "うちけす",
      "kanji": "打ち消す",
      "type": "v5s",
      "meaning": "to deny, to negate, to contradict"
    },
    {
      "id": 381,
      "kana": "うちゅう",
      "kanji": "宇宙",
      "type": "n",
      "meaning": "universe, cosmos, space"
    },
    {
      "id": 382,
      "kana": "うつ",
      "kanji": "打つ",
      "type": "v5t",
      "meaning": "to hit, to strike"
    },
    {
      "id": 383,
      "kana": "うつ",
      "kanji": "討つ",
      "type": "v5t",
      "meaning": "to attack, to avenge"
    },
    {
      "id": 384,
      "kana": "うつ",
      "kanji": "撃つ",
      "type": "v5t",
      "meaning": "to attack, to defeat, to destroy"
    },
    {
      "id": 385,
      "kana": "うっかり",
      "kanji": "",
      "type": "adv,n",
      "meaning": "carelessly, thoughtlessly, inadvertently"
    },
    {
      "id": 386,
      "kana": "うつくしい",
      "kanji": "美しい",
      "type": "adj",
      "meaning": "beautiful, lovely"
    },
    {
      "id": 387,
      "kana": "うつす",
      "kanji": "写す",
      "type": "v5s",
      "meaning": "to copy, to transcribe, to duplicate"
    },
    {
      "id": 388,
      "kana": "うつす",
      "kanji": "映す",
      "type": "v5s",
      "meaning": "to project, to reflect, to cast (shadow)"
    },
    {
      "id": 389,
      "kana": "うつす",
      "kanji": "移す",
      "type": "v5s",
      "meaning": "to remove, to transfer, to infect"
    },
    {
      "id": 390,
      "kana": "うったえる",
      "kanji": "訴える",
      "type": "v1",
      "meaning": "to sue (a person), to resort to, to appeal to"
    },
    {
      "id": 391,
      "kana": "うつる",
      "kanji": "写る",
      "type": "v5r",
      "meaning": "to be photographed, to be projected"
    },
    {
      "id": 392,
      "kana": "うつる",
      "kanji": "映る",
      "type": "v5r",
      "meaning": "to be reflected, to harmonize with, to come out (photo)"
    },
    {
      "id": 393,
      "kana": "うつる",
      "kanji": "移る",
      "type": "v5r",
      "meaning": "to move (house), to transfer (department)"
    },
    {
      "id": 394,
      "kana": "うで",
      "kanji": "腕",
      "type": "n",
      "meaning": "(1) arm, (2) skill"
    },
    {
      "id": 395,
      "kana": "うどん",
      "kanji": "",
      "type": "n",
      "meaning": "noodles (Japanese)"
    },
    {
      "id": 396,
      "kana": "うなずく",
      "kanji": "",
      "type": "v5k",
      "meaning": "to nod, to bow one's head in assent"
    },
    {
      "id": 397,
      "kana": "うなる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to groan, to moan"
    },
    {
      "id": 398,
      "kana": "うばう",
      "kanji": "奪う",
      "type": "v5u",
      "meaning": "to snatch away"
    },
    {
      "id": 399,
      "kana": "うま",
      "kanji": "馬",
      "type": "n",
      "meaning": "(1) horse, (2) promoted bishop (shogi)"
    },
    {
      "id": 400,
      "kana": "うまい",
      "kanji": "うまい",
      "type": "adj",
      "meaning": "delicious"
    },
    {
      "id": 401,
      "kana": "うまれ",
      "kanji": "生まれ",
      "type": "n",
      "meaning": "birth, birth-place"
    },
    {
      "id": 402,
      "kana": "うまれる",
      "kanji": "生まれる",
      "type": "v1",
      "meaning": "to be born"
    },
    {
      "id": 403,
      "kana": "うみ",
      "kanji": "海",
      "type": "n",
      "meaning": "sea, beach"
    },
    {
      "id": 404,
      "kana": "うむ",
      "kanji": "有無",
      "type": "n",
      "meaning": "yes or no, existence, flag indicator (comp), presence or absence marker"
    },
    {
      "id": 405,
      "kana": "うめ",
      "kanji": "梅",
      "type": "n",
      "meaning": "plum, plum-tree, lowest (of a three-tier ranking system)"
    },
    {
      "id": 406,
      "kana": "うめる",
      "kanji": "埋める",
      "type": "v1",
      "meaning": "to bury, to fill up, to fill (a seat, a vacant position)"
    },
    {
      "id": 407,
      "kana": "うやまう",
      "kanji": "敬う",
      "type": "v5u",
      "meaning": "to show respect, to honour"
    },
    {
      "id": 408,
      "kana": "うら",
      "kanji": "裏",
      "type": "n",
      "meaning": "reverse side, wrong side, back"
    },
    {
      "id": 409,
      "kana": "うらがえす",
      "kanji": "裏返す",
      "type": "v5s",
      "meaning": "to turn inside out, to turn (something) over"
    },
    {
      "id": 410,
      "kana": "うらぎる",
      "kanji": "裏切る",
      "type": "v5r",
      "meaning": "to betray, to turn traitor to, to double-cross"
    },
    {
      "id": 411,
      "kana": "うらぐち",
      "kanji": "裏口",
      "type": "n",
      "meaning": "backdoor, rear entrance"
    },
    {
      "id": 412,
      "kana": "うらなう",
      "kanji": "占う",
      "type": "v5u",
      "meaning": "to forecast, to predict"
    },
    {
      "id": 413,
      "kana": "うらみ",
      "kanji": "恨み",
      "type": "n",
      "meaning": "resentment"
    },
    {
      "id": 414,
      "kana": "うらむ",
      "kanji": "恨む",
      "type": "v5m",
      "meaning": "to curse, to feel bitter"
    },
    {
      "id": 415,
      "kana": "うらやましい",
      "kanji": "羨ましい",
      "type": "adj",
      "meaning": "envious, enviable"
    },
    {
      "id": 416,
      "kana": "うらやむ",
      "kanji": "羨む",
      "type": "v5m",
      "meaning": "to envy"
    },
    {
      "id": 417,
      "kana": "うりあげ",
      "kanji": "売上",
      "type": "n",
      "meaning": "amount sold, proceeds"
    },
    {
      "id": 418,
      "kana": "うりきれ",
      "kanji": "売り切れ",
      "type": "n",
      "meaning": "sold-out"
    },
    {
      "id": 419,
      "kana": "うりきれる",
      "kanji": "売り切れる",
      "type": "v1",
      "meaning": "to be sold out"
    },
    {
      "id": 420,
      "kana": "うりば",
      "kanji": "売り場",
      "type": "adj-no,n",
      "meaning": "place where things are sold, salesfloor, counter (in shop)"
    },
    {
      "id": 421,
      "kana": "うる",
      "kanji": "売る",
      "type": "v5r",
      "meaning": "to sell"
    },
    {
      "id": 422,
      "kana": "うるさい",
      "kanji": "",
      "type": "adj",
      "meaning": "noisy, loud, fussy"
    },
    {
      "id": 423,
      "kana": "うれしい",
      "kanji": "嬉しい",
      "type": "adj",
      "meaning": "happy, glad, pleasant"
    },
    {
      "id": 424,
      "kana": "うれゆき",
      "kanji": "売行き",
      "type": "n",
      "meaning": "sales"
    },
    {
      "id": 425,
      "kana": "うれる",
      "kanji": "売れる",
      "type": "v1",
      "meaning": "to be sold"
    },
    {
      "id": 426,
      "kana": "うろうろ",
      "kanji": "",
      "type": "adv,n,vs",
      "meaning": "loiteringly, aimless wandering"
    },
    {
      "id": 427,
      "kana": "うわ~",
      "kanji": "上~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 428,
      "kana": "うわぎ",
      "kanji": "上着",
      "type": "n",
      "meaning": "coat, tunic, jacket, outer garment"
    },
    {
      "id": 429,
      "kana": "うわさ",
      "kanji": "噂",
      "type": "n",
      "meaning": "rumour, report, gossip, common talk"
    },
    {
      "id": 430,
      "kana": "（感）",
      "kanji": "うん",
      "type": "n",
      "meaning": "fortune, luck"
    },
    {
      "id": 431,
      "kana": "うん",
      "kanji": "運",
      "type": "n",
      "meaning": "fortune, luck"
    },
    {
      "id": 432,
      "kana": "うんが",
      "kanji": "運河",
      "type": "n",
      "meaning": "canal, waterway"
    },
    {
      "id": 433,
      "kana": "うんてん",
      "kanji": "運転",
      "type": "n,vs",
      "meaning": "operation, motion, driving"
    },
    {
      "id": 434,
      "kana": "うんと",
      "kanji": "",
      "type": "",
      "meaning": "a great deal, very much"
    },
    {
      "id": 435,
      "kana": "うんどう",
      "kanji": "運動",
      "type": "n,vs",
      "meaning": "motion, exercise"
    },
    {
      "id": 436,
      "kana": "え",
      "kanji": "柄",
      "type": "n",
      "meaning": "handle, grip"
    },
    {
      "id": 437,
      "kana": "え",
      "kanji": "絵",
      "type": "n,n-suf",
      "meaning": "picture, drawing, painting, sketch"
    },
    {
      "id": 438,
      "kana": "えいえん",
      "kanji": "永遠",
      "type": "adj-na,n",
      "meaning": "eternity, perpetuity, immortality, permanence"
    },
    {
      "id": 439,
      "kana": "えいが",
      "kanji": "映画",
      "type": "n",
      "meaning": "movie, film"
    },
    {
      "id": 440,
      "kana": "えいきゅう",
      "kanji": "永久",
      "type": "adj-na,n",
      "meaning": "eternity, perpetuity, immortality"
    },
    {
      "id": 441,
      "kana": "えいきょう",
      "kanji": "影響",
      "type": "n,vs",
      "meaning": "influence, effect"
    },
    {
      "id": 442,
      "kana": "えいぎょう",
      "kanji": "営業",
      "type": "n",
      "meaning": "business, trade, management"
    },
    {
      "id": 443,
      "kana": "えいご",
      "kanji": "英語",
      "type": "n",
      "meaning": "the English language"
    },
    {
      "id": 444,
      "kana": "えいせい",
      "kanji": "衛星",
      "type": "n",
      "meaning": "satellite"
    },
    {
      "id": 445,
      "kana": "えいぶん",
      "kanji": "英文",
      "type": "n",
      "meaning": "sentence in English"
    },
    {
      "id": 446,
      "kana": "えいよう",
      "kanji": "栄養",
      "type": "n",
      "meaning": "nutrition, nourishment"
    },
    {
      "id": 447,
      "kana": "えいわ",
      "kanji": "英和",
      "type": "n",
      "meaning": "English-Japanese (e.g. dictionary)"
    },
    {
      "id": 448,
      "kana": "ええ",
      "kanji": "",
      "type": "conj,int,n",
      "meaning": "yes"
    },
    {
      "id": 449,
      "kana": "ええと",
      "kanji": "",
      "type": "int",
      "meaning": "let me see, well, er...."
    },
    {
      "id": 450,
      "kana": "えがお",
      "kanji": "笑顔",
      "type": "n",
      "meaning": "smiling face"
    },
    {
      "id": 451,
      "kana": "えがく",
      "kanji": "描く",
      "type": "v5k",
      "meaning": "to draw, to paint, to sketch, to depict, to describe"
    },
    {
      "id": 452,
      "kana": "えき",
      "kanji": "駅",
      "type": "n",
      "meaning": "station"
    },
    {
      "id": 453,
      "kana": "えきたい",
      "kanji": "液体",
      "type": "n",
      "meaning": "liquid, fluid"
    },
    {
      "id": 454,
      "kana": "えさ",
      "kanji": "餌",
      "type": "n",
      "meaning": "feed, bait"
    },
    {
      "id": 455,
      "kana": "エスカレーター",
      "kanji": "",
      "type": "n",
      "meaning": "escalator"
    },
    {
      "id": 456,
      "kana": "えだ",
      "kanji": "枝",
      "type": "n",
      "meaning": "branch, bow, twig, limb"
    },
    {
      "id": 457,
      "kana": "エチケット",
      "kanji": "",
      "type": "n",
      "meaning": "etiquette"
    },
    {
      "id": 458,
      "kana": "エネルギー",
      "kanji": "",
      "type": "de:",
      "meaning": "(n) energy (de: Energie)"
    },
    {
      "id": 459,
      "kana": "えのぐ",
      "kanji": "絵の具",
      "type": "n",
      "meaning": "colors, paints"
    },
    {
      "id": 460,
      "kana": "エプロン",
      "kanji": "",
      "type": "n",
      "meaning": "apron"
    },
    {
      "id": 461,
      "kana": "えらい",
      "kanji": "偉い",
      "type": "adj",
      "meaning": "great, celebrated, eminent, terrible, awful, famous, remarkable, excellent"
    },
    {
      "id": 462,
      "kana": "えらぶ",
      "kanji": "選ぶ",
      "type": "v5b",
      "meaning": "to choose, to select"
    },
    {
      "id": 463,
      "kana": "える",
      "kanji": "得る",
      "type": "v1",
      "meaning": "to get, to gain, to win"
    },
    {
      "id": 464,
      "kana": "うる",
      "kanji": "得る",
      "type": "v1",
      "meaning": "to get, to gain, to win"
    },
    {
      "id": 465,
      "kana": "エレベーター",
      "kanji": "",
      "type": "n",
      "meaning": "elevator"
    },
    {
      "id": 466,
      "kana": "えん",
      "kanji": "円",
      "type": "n",
      "meaning": "circle, money"
    },
    {
      "id": 467,
      "kana": "~えん",
      "kanji": "~円",
      "type": "",
      "meaning": "~ yen"
    },
    {
      "id": 468,
      "kana": "~えん",
      "kanji": "~園",
      "type": "",
      "meaning": "~ garden (especially man made)"
    },
    {
      "id": 469,
      "kana": "えんかい",
      "kanji": "宴会",
      "type": "n",
      "meaning": "party, banquet"
    },
    {
      "id": 470,
      "kana": "えんき",
      "kanji": "延期",
      "type": "n,vs",
      "meaning": "postponement, adjournment"
    },
    {
      "id": 471,
      "kana": "えんぎ",
      "kanji": "演技",
      "type": "n",
      "meaning": "acting, performance"
    },
    {
      "id": 472,
      "kana": "えんげい",
      "kanji": "園芸",
      "type": "n",
      "meaning": "horticulture, gardening"
    },
    {
      "id": 473,
      "kana": "えんげき",
      "kanji": "演劇",
      "type": "n",
      "meaning": "play (theatrical)"
    },
    {
      "id": 474,
      "kana": "えんしゅう",
      "kanji": "円周",
      "type": "n",
      "meaning": "circumference"
    },
    {
      "id": 475,
      "kana": "えんじょ",
      "kanji": "援助",
      "type": "n,vs",
      "meaning": "assistance, aid, support"
    },
    {
      "id": 476,
      "kana": "エンジン",
      "kanji": "",
      "type": "n",
      "meaning": "engine"
    },
    {
      "id": 477,
      "kana": "えんぜつ",
      "kanji": "演説",
      "type": "n",
      "meaning": "speech, address"
    },
    {
      "id": 478,
      "kana": "えんそう",
      "kanji": "演奏",
      "type": "n,vs",
      "meaning": "musical performance"
    },
    {
      "id": 479,
      "kana": "えんそく",
      "kanji": "遠足",
      "type": "n",
      "meaning": "trip, hike, picnic"
    },
    {
      "id": 480,
      "kana": "えんちょう",
      "kanji": "延長",
      "type": "n,vs",
      "meaning": "extension, elongation, prolongation, lengthening"
    },
    {
      "id": 481,
      "kana": "えんとつ",
      "kanji": "煙突",
      "type": "n",
      "meaning": "chimney"
    },
    {
      "id": 482,
      "kana": "えんぴつ",
      "kanji": "鉛筆",
      "type": "n",
      "meaning": "pencil"
    },
    {
      "id": 483,
      "kana": "えんりょ",
      "kanji": "遠慮",
      "type": "adj-na,n,vs",
      "meaning": "diffidence, restraint, reserve"
    },
    {
      "id": 484,
      "kana": "お",
      "kanji": "御",
      "type": "pref",
      "meaning": "honourable"
    },
    {
      "id": 485,
      "kana": "おん~",
      "kanji": "御~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 486,
      "kana": "おい",
      "kanji": "老い",
      "type": "n",
      "meaning": "old age, old person, the old, the aged"
    },
    {
      "id": 487,
      "kana": "おいかける",
      "kanji": "追い掛ける",
      "type": "v1",
      "meaning": "to chase or run after someone, to run down, to pursue"
    },
    {
      "id": 488,
      "kana": "おいこす",
      "kanji": "追い越す",
      "type": "v5s",
      "meaning": "to pass (e.g. car), to outdistance, to outstrip"
    },
    {
      "id": 489,
      "kana": "おいしい",
      "kanji": "",
      "type": "adj",
      "meaning": "delicious, tasty"
    },
    {
      "id": 490,
      "kana": "おいつく",
      "kanji": "追い付く",
      "type": "v5k",
      "meaning": "to overtake, to catch up (with)"
    },
    {
      "id": 491,
      "kana": "おいでになる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to be"
    },
    {
      "id": 492,
      "kana": "オイル",
      "kanji": "",
      "type": "n",
      "meaning": "oil, engine oil, kerosene"
    },
    {
      "id": 493,
      "kana": "おう",
      "kanji": "王",
      "type": "n,n-suf",
      "meaning": "king, ruler, sovereign, monarch"
    },
    {
      "id": 494,
      "kana": "おう",
      "kanji": "追う",
      "type": "v5u",
      "meaning": "to chase, to run after"
    },
    {
      "id": 495,
      "kana": "おうえん",
      "kanji": "応援",
      "type": "n,vs",
      "meaning": "aid, assistance, help, reinforcement"
    },
    {
      "id": 496,
      "kana": "おうさま",
      "kanji": "王様",
      "type": "n",
      "meaning": "king"
    },
    {
      "id": 497,
      "kana": "おうじ",
      "kanji": "王子",
      "type": "n",
      "meaning": "prince"
    },
    {
      "id": 498,
      "kana": "おうじょ",
      "kanji": "王女",
      "type": "n",
      "meaning": "princess"
    },
    {
      "id": 499,
      "kana": "おうじる",
      "kanji": "応じる",
      "type": "v1",
      "meaning": "to respond, to satisfy, to accept, to comply with, to apply for"
    },
    {
      "id": 500,
      "kana": "おうずる",
      "kanji": "応ずる",
      "type": "v5z",
      "meaning": "to answer, to respond, to meet, to satisfy, to accept"
    },
    {
      "id": 501,
      "kana": "おうせつ",
      "kanji": "応接",
      "type": "n",
      "meaning": "reception"
    },
    {
      "id": 502,
      "kana": "おうたい",
      "kanji": "応対",
      "type": "n,vs",
      "meaning": "receiving, dealing with"
    },
    {
      "id": 503,
      "kana": "おうだん",
      "kanji": "横断",
      "type": "n,vs",
      "meaning": "crossing"
    },
    {
      "id": 504,
      "kana": "おうふく",
      "kanji": "往復",
      "type": "n,vs",
      "meaning": "(col) round trip, coming and going, return ticket"
    },
    {
      "id": 505,
      "kana": "おうべい",
      "kanji": "欧米",
      "type": "",
      "meaning": "Europe and America, the West"
    },
    {
      "id": 506,
      "kana": "おうよう",
      "kanji": "応用",
      "type": "n,vs",
      "meaning": "application, put to practical use"
    },
    {
      "id": 507,
      "kana": "おえる",
      "kanji": "終える",
      "type": "v1",
      "meaning": "to finish"
    },
    {
      "id": 508,
      "kana": "大~",
      "kanji": "おお~",
      "type": "",
      "meaning": "big ~"
    },
    {
      "id": 509,
      "kana": "おおい",
      "kanji": "多い",
      "type": "adj",
      "meaning": "many, numerous"
    },
    {
      "id": 510,
      "kana": "おおいに",
      "kanji": "大いに",
      "type": "adv",
      "meaning": "very, much, greatly"
    },
    {
      "id": 511,
      "kana": "おおう",
      "kanji": "覆う",
      "type": "v5u",
      "meaning": "to cover, to hide, to conceal, to wrap, to disguise"
    },
    {
      "id": 512,
      "kana": "おおきい",
      "kanji": "大きい",
      "type": "adj",
      "meaning": "big, large, great"
    },
    {
      "id": 513,
      "kana": "オーケストラ",
      "kanji": "",
      "type": "n",
      "meaning": "orchestra"
    },
    {
      "id": 514,
      "kana": "おおざっぱ",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "rough (not precise), broad, sketchy"
    },
    {
      "id": 515,
      "kana": "おおぜい",
      "kanji": "大勢",
      "type": "n",
      "meaning": "great number of people"
    },
    {
      "id": 516,
      "kana": "おおどおり",
      "kanji": "大通り",
      "type": "n",
      "meaning": "main street"
    },
    {
      "id": 517,
      "kana": "オートメーション",
      "kanji": "",
      "type": "n",
      "meaning": "automation"
    },
    {
      "id": 518,
      "kana": "オートバイ",
      "kanji": "",
      "type": "n",
      "meaning": "motorcycle (lit: auto-bi(ke))"
    },
    {
      "id": 519,
      "kana": "オーバー",
      "kanji": "",
      "type": "adj-na,n,vs",
      "meaning": "(1) overcoat, (2) over"
    },
    {
      "id": 520,
      "kana": "オーバーコート",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 521,
      "kana": "おおや",
      "kanji": "大家",
      "type": "n",
      "meaning": "rich family, distinguished family"
    },
    {
      "id": 522,
      "kana": "おおよそ",
      "kanji": "大凡",
      "type": "adv,n",
      "meaning": "about, roughly, as a rule, approximately"
    },
    {
      "id": 523,
      "kana": "おか",
      "kanji": "丘",
      "type": "n",
      "meaning": "hill, height, knoll, rising ground"
    },
    {
      "id": 524,
      "kana": "おかあさん",
      "kanji": "お母さん",
      "type": "n",
      "meaning": "(hon) mother"
    },
    {
      "id": 525,
      "kana": "おかえり",
      "kanji": "お帰り",
      "type": "expr",
      "meaning": "return, welcome"
    },
    {
      "id": 526,
      "kana": "おかげ",
      "kanji": "",
      "type": "n",
      "meaning": "thanks or owing to"
    },
    {
      "id": 527,
      "kana": "おかけください",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 528,
      "kana": "おかげさまで",
      "kanji": "",
      "type": "exp",
      "meaning": "Thanks to god, thanks to you"
    },
    {
      "id": 529,
      "kana": "おかしい",
      "kanji": "",
      "type": "adj",
      "meaning": "strange, funny, amusing, ridiculous"
    },
    {
      "id": 530,
      "kana": "おかず",
      "kanji": "",
      "type": "n",
      "meaning": "side dish, accompaniment for rice dishes"
    },
    {
      "id": 531,
      "kana": "おかまいなく",
      "kanji": "",
      "type": "exp,n,pol",
      "meaning": "please don't fuss over me"
    },
    {
      "id": 532,
      "kana": "おがむ",
      "kanji": "拝む",
      "type": "v5m",
      "meaning": "to worship, to beg, to make a supplication"
    },
    {
      "id": 533,
      "kana": "おかわり",
      "kanji": "お代わり",
      "type": "n",
      "meaning": "second helping, another cup"
    },
    {
      "id": 534,
      "kana": "おき",
      "kanji": "沖",
      "type": "n",
      "meaning": "open sea"
    },
    {
      "id": 535,
      "kana": "~おき",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 536,
      "kana": "おぎなう",
      "kanji": "補う",
      "type": "v5u",
      "meaning": "to compensate for"
    },
    {
      "id": 537,
      "kana": "おきる",
      "kanji": "起きる",
      "type": "v1",
      "meaning": "to get up, to rise"
    },
    {
      "id": 538,
      "kana": "おきのどくに",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 539,
      "kana": "おく",
      "kanji": "奥",
      "type": "n",
      "meaning": "interior, inner part"
    },
    {
      "id": 540,
      "kana": "おく",
      "kanji": "置く",
      "type": "v5k",
      "meaning": "to put, to place"
    },
    {
      "id": 541,
      "kana": "おく",
      "kanji": "億",
      "type": "num",
      "meaning": "100,000,000, hundred million"
    },
    {
      "id": 542,
      "kana": "おくがい",
      "kanji": "屋外",
      "type": "n",
      "meaning": "outdoors"
    },
    {
      "id": 543,
      "kana": "おくさん",
      "kanji": "",
      "type": "n",
      "meaning": "(hon) wife, your wife, madam"
    },
    {
      "id": 544,
      "kana": "おくじょう",
      "kanji": "屋上",
      "type": "n",
      "meaning": "rooftop"
    },
    {
      "id": 545,
      "kana": "おくりがな",
      "kanji": "送り仮名",
      "type": "",
      "meaning": "part of word written in kana"
    },
    {
      "id": 546,
      "kana": "おくりもの",
      "kanji": "贈り物",
      "type": "n",
      "meaning": "present, gift"
    },
    {
      "id": 547,
      "kana": "おくる",
      "kanji": "送る",
      "type": "v5r",
      "meaning": "to send (a thing), to dispatch"
    },
    {
      "id": 548,
      "kana": "おくる",
      "kanji": "贈る",
      "type": "v5r",
      "meaning": "to send, to give to, to award to, to confer on"
    },
    {
      "id": 549,
      "kana": "おくれる",
      "kanji": "遅れる",
      "type": "v1",
      "meaning": "to be late, to be delayed, to fall behind schedule"
    },
    {
      "id": 550,
      "kana": "おげんきで",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 551,
      "kana": "おこさん",
      "kanji": "お子さん",
      "type": "n",
      "meaning": "(someone else's) child"
    },
    {
      "id": 552,
      "kana": "おこす",
      "kanji": "起す",
      "type": "io",
      "meaning": "(v5s) to raise, to cause, to wake someone"
    },
    {
      "id": 553,
      "kana": "おこたる",
      "kanji": "怠る",
      "type": "v5r",
      "meaning": "to neglect, to be off guard, to be feeling better"
    },
    {
      "id": 554,
      "kana": "おこなう",
      "kanji": "行う",
      "type": "v5u",
      "meaning": "to perform, to do, to conduct oneself, to carry out"
    },
    {
      "id": 555,
      "kana": "おこる",
      "kanji": "起こる",
      "type": "v5r",
      "meaning": "to occur, to happen"
    },
    {
      "id": 556,
      "kana": "おこる",
      "kanji": "怒る",
      "type": "v5r",
      "meaning": "(1) to get angry, to get mad, (2) to be angry"
    },
    {
      "id": 557,
      "kana": "おさえる",
      "kanji": "押える",
      "type": "v1",
      "meaning": "to stop, to restrain, to seize, to repress, to suppress, to press down"
    },
    {
      "id": 558,
      "kana": "おさきに",
      "kanji": "",
      "type": "adv",
      "meaning": "before, ahead, previously"
    },
    {
      "id": 559,
      "kana": "おさない",
      "kanji": "幼い",
      "type": "adj",
      "meaning": "very young, childish"
    },
    {
      "id": 560,
      "kana": "おさめる",
      "kanji": "収める",
      "type": "v1",
      "meaning": "to obtain, to reap, to pay, to supply, to accept"
    },
    {
      "id": 561,
      "kana": "おさめる",
      "kanji": "納める",
      "type": "v1",
      "meaning": "to obtain, to reap, to pay, to supply, to accept"
    },
    {
      "id": 562,
      "kana": "おさめる",
      "kanji": "治める",
      "type": "v1",
      "meaning": "(1) to govern, to manage, (2) to subdue"
    },
    {
      "id": 563,
      "kana": "おじ",
      "kanji": "伯父",
      "type": "n",
      "meaning": "(hum) uncle (older than one's parent)"
    },
    {
      "id": 564,
      "kana": "おじ",
      "kanji": "叔父",
      "type": "n",
      "meaning": "uncle (younger than one's parent)"
    },
    {
      "id": 565,
      "kana": "おしい",
      "kanji": "惜しい",
      "type": "adj",
      "meaning": "regrettable, disappointing, precious"
    },
    {
      "id": 566,
      "kana": "おじいさん",
      "kanji": "",
      "type": "n",
      "meaning": "grandfather, male senior-citizen"
    },
    {
      "id": 567,
      "kana": "おしいれ",
      "kanji": "押し入れ",
      "type": "n",
      "meaning": "closet"
    },
    {
      "id": 568,
      "kana": "おしえる",
      "kanji": "教える",
      "type": "v1",
      "meaning": "to teach, to inform, to instruct"
    },
    {
      "id": 569,
      "kana": "おじぎ",
      "kanji": "御辞儀",
      "type": "n,vs",
      "meaning": "bow"
    },
    {
      "id": 570,
      "kana": "おじさん",
      "kanji": "伯父さん",
      "type": "n",
      "meaning": "middle-aged gentleman, uncle"
    },
    {
      "id": 571,
      "kana": "おじさん",
      "kanji": "小父さん",
      "type": "n",
      "meaning": "middle-aged gentleman, uncle"
    },
    {
      "id": 572,
      "kana": "おじさん",
      "kanji": "叔父さん",
      "type": "n",
      "meaning": "middle-aged gentleman, uncle"
    },
    {
      "id": 573,
      "kana": "おしゃれ",
      "kanji": "",
      "type": "adj-na,adj-no,n",
      "meaning": "smartly dressed, someone smartly dressed, fashion-conscious"
    },
    {
      "id": 574,
      "kana": "おしゃべり",
      "kanji": "",
      "type": "adj-na,adj-no,n,vs",
      "meaning": "chattering, talk, idle talk"
    },
    {
      "id": 575,
      "kana": "おじゃまします",
      "kanji": "",
      "type": "exp",
      "meaning": "Excuse me for disturbing you"
    },
    {
      "id": 576,
      "kana": "おじょうさん",
      "kanji": "お嬢さん",
      "type": "n",
      "meaning": "(1) (hon) daughter, (2) young lady"
    },
    {
      "id": 577,
      "kana": "おす",
      "kanji": "押す",
      "type": "v5s,vt",
      "meaning": "to push, to press, to stamp (i.e. a passport)"
    },
    {
      "id": 578,
      "kana": "おせわになりました",
      "kanji": "",
      "type": "",
      "meaning": "I've been in your care"
    },
    {
      "id": 579,
      "kana": "おせん",
      "kanji": "汚染",
      "type": "n",
      "meaning": "pollution, contamination"
    },
    {
      "id": 580,
      "kana": "おそい",
      "kanji": "遅い",
      "type": "adj",
      "meaning": "late, slow"
    },
    {
      "id": 581,
      "kana": "おそらく",
      "kanji": "",
      "type": "adv",
      "meaning": "perhaps"
    },
    {
      "id": 582,
      "kana": "おそれる",
      "kanji": "恐れる",
      "type": "v1",
      "meaning": "to fear, to be afraid of"
    },
    {
      "id": 583,
      "kana": "おそろしい",
      "kanji": "恐ろしい",
      "type": "adj",
      "meaning": "terrible, dreadful"
    },
    {
      "id": 584,
      "kana": "おそわる",
      "kanji": "教わる",
      "type": "v5r",
      "meaning": "to be taught"
    },
    {
      "id": 585,
      "kana": "おだいじに",
      "kanji": "",
      "type": "exp",
      "meaning": "Take care of yourself"
    },
    {
      "id": 586,
      "kana": "おたがい",
      "kanji": "お互い",
      "type": "n",
      "meaning": "mutual, reciprocal, each other"
    },
    {
      "id": 587,
      "kana": "おだやか",
      "kanji": "穏やか",
      "type": "adj-na,n",
      "meaning": "calm, gentle, quiet"
    },
    {
      "id": 588,
      "kana": "おちつく",
      "kanji": "落着く",
      "type": "v5k",
      "meaning": "to calm down, to settle down"
    },
    {
      "id": 589,
      "kana": "おちる",
      "kanji": "落る",
      "type": "",
      "meaning": ""
    },
    {
      "id": 590,
      "kana": "おっしゃる",
      "kanji": "",
      "type": "v5aru",
      "meaning": "(hon) to say, to speak, to tell, to talk"
    },
    {
      "id": 591,
      "kana": "おっと",
      "kanji": "夫",
      "type": "n",
      "meaning": "(hum) (my) husband"
    },
    {
      "id": 592,
      "kana": "おてあらい",
      "kanji": "御手洗",
      "type": "n",
      "meaning": "font of purifying water placed at entrance of shrine"
    },
    {
      "id": 593,
      "kana": "おでかけ",
      "kanji": "お出掛け",
      "type": "",
      "meaning": ""
    },
    {
      "id": 594,
      "kana": "おてつだいさん",
      "kanji": "お手伝いさん",
      "type": "n",
      "meaning": "maid"
    },
    {
      "id": 595,
      "kana": "おと",
      "kanji": "音",
      "type": "n,n-suf",
      "meaning": "sound, note"
    },
    {
      "id": 596,
      "kana": "おとうさん",
      "kanji": "お父さん",
      "type": "n",
      "meaning": "(hon) father"
    },
    {
      "id": 597,
      "kana": "おとうと",
      "kanji": "弟",
      "type": "n",
      "meaning": "younger brother, faithful service to those older, brotherly affection"
    },
    {
      "id": 598,
      "kana": "おどかす",
      "kanji": "",
      "type": "v5s",
      "meaning": "to threaten, to coerce"
    },
    {
      "id": 599,
      "kana": "おとこ",
      "kanji": "男",
      "type": "n",
      "meaning": "man"
    },
    {
      "id": 600,
      "kana": "おとこのこ",
      "kanji": "男の子",
      "type": "n",
      "meaning": "boy"
    },
    {
      "id": 601,
      "kana": "おとこのひと",
      "kanji": "男の人",
      "type": "",
      "meaning": "man"
    },
    {
      "id": 602,
      "kana": "おとしもの",
      "kanji": "落し物",
      "type": "n",
      "meaning": "lost property"
    },
    {
      "id": 603,
      "kana": "おとす",
      "kanji": "落す",
      "type": "io",
      "meaning": "(v5s) to drop, to lose, to let fall"
    },
    {
      "id": 604,
      "kana": "おととい",
      "kanji": "",
      "type": "n-adv,n-t",
      "meaning": "day before yesterday"
    },
    {
      "id": 605,
      "kana": "おととし",
      "kanji": "",
      "type": "n-adv,n-t",
      "meaning": "year before last"
    },
    {
      "id": 606,
      "kana": "おとな",
      "kanji": "大人",
      "type": "n",
      "meaning": "adult"
    },
    {
      "id": 607,
      "kana": "おとなしい",
      "kanji": "",
      "type": "adj",
      "meaning": "obedient, docile, quiet"
    },
    {
      "id": 608,
      "kana": "おどり",
      "kanji": "踊り",
      "type": "n",
      "meaning": "dance"
    },
    {
      "id": 609,
      "kana": "おとる",
      "kanji": "劣る",
      "type": "v5r",
      "meaning": "to fall behind, to be inferior to"
    },
    {
      "id": 610,
      "kana": "おどる",
      "kanji": "踊る",
      "type": "v5r",
      "meaning": "to dance"
    },
    {
      "id": 611,
      "kana": "おどろかす",
      "kanji": "驚かす",
      "type": "v5s",
      "meaning": "to surprise, to frighten, to create a stir"
    },
    {
      "id": 612,
      "kana": "おどろく",
      "kanji": "驚く",
      "type": "v5k",
      "meaning": "to be surprised, to be astonished"
    },
    {
      "id": 613,
      "kana": "おなか",
      "kanji": "お腹",
      "type": "n",
      "meaning": "stomach"
    },
    {
      "id": 614,
      "kana": "おなじ",
      "kanji": "同じ",
      "type": "adj-na,n",
      "meaning": "same, identical, equal, similar"
    },
    {
      "id": 615,
      "kana": "おに",
      "kanji": "鬼",
      "type": "n",
      "meaning": "ogre, demon, \"it\" (i.e., in a game of tag)"
    },
    {
      "id": 616,
      "kana": "おにいさん",
      "kanji": "お兄さん",
      "type": "n",
      "meaning": "(hon) older brother, (vocative) \"Mister?"
    },
    {
      "id": 617,
      "kana": "おねえさん",
      "kanji": "お姉さん",
      "type": "n",
      "meaning": "(hon) older sister, (vocative) \"Miss?"
    },
    {
      "id": 618,
      "kana": "おねがいします",
      "kanji": "",
      "type": "hon",
      "meaning": "please"
    },
    {
      "id": 619,
      "kana": "おのおの",
      "kanji": "各々",
      "type": "",
      "meaning": "each, every, either, respectively, severally"
    },
    {
      "id": 620,
      "kana": "おば",
      "kanji": "伯母",
      "type": "n",
      "meaning": "(hum) aunt (older than one's parent)"
    },
    {
      "id": 621,
      "kana": "おば",
      "kanji": "叔母",
      "type": "n",
      "meaning": "aunt (younger than one's parent)"
    },
    {
      "id": 622,
      "kana": "おばあさん",
      "kanji": "",
      "type": "n",
      "meaning": "grandmother, female senior-citizen"
    },
    {
      "id": 623,
      "kana": "おばさん",
      "kanji": "伯母さん",
      "type": "n",
      "meaning": "(hon) aunt"
    },
    {
      "id": 624,
      "kana": "おばさん",
      "kanji": "叔母さん",
      "type": "n",
      "meaning": "(1) aunt, (2) middle-aged lady"
    },
    {
      "id": 625,
      "kana": "おばさん",
      "kanji": "小母さん",
      "type": "n",
      "meaning": "lady, woman, ma'am"
    },
    {
      "id": 626,
      "kana": "おはよう",
      "kanji": "",
      "type": "int,n",
      "meaning": "(abbr) Good morning"
    },
    {
      "id": 627,
      "kana": "おび",
      "kanji": "帯",
      "type": "n-suf",
      "meaning": "band (e.g. conduction, valence)"
    },
    {
      "id": 628,
      "kana": "おひる",
      "kanji": "お昼",
      "type": "n-adv,n",
      "meaning": "lunch, noon"
    },
    {
      "id": 629,
      "kana": "オフィス",
      "kanji": "",
      "type": "n",
      "meaning": "office"
    },
    {
      "id": 630,
      "kana": "おぼえる",
      "kanji": "覚える",
      "type": "v1",
      "meaning": "to remember, to memorize"
    },
    {
      "id": 631,
      "kana": "おぼれる",
      "kanji": "溺れる",
      "type": "v1",
      "meaning": "to be drowned, to indulge in"
    },
    {
      "id": 632,
      "kana": "おまいり",
      "kanji": "お参り",
      "type": "n,vs",
      "meaning": "worship, shrine visit"
    },
    {
      "id": 633,
      "kana": "おまえ",
      "kanji": "",
      "type": "n",
      "meaning": "(fam) you (sing), presence (of a high personage)"
    },
    {
      "id": 634,
      "kana": "おまたせしました",
      "kanji": "",
      "type": "",
      "meaning": "Sorry to have kept you waiting"
    },
    {
      "id": 635,
      "kana": "おまちください",
      "kanji": "",
      "type": "",
      "meaning": "Please wait a moment"
    },
    {
      "id": 636,
      "kana": "おまちどおさま",
      "kanji": "",
      "type": "exp,int,n",
      "meaning": "Sorry to have kept you waiting"
    },
    {
      "id": 637,
      "kana": "おまわりさん",
      "kanji": "",
      "type": "n",
      "meaning": "policeman (friendly term)"
    },
    {
      "id": 638,
      "kana": "おめでたい",
      "kanji": "",
      "type": "n",
      "meaning": "happy event, matter for congratulation, auspicious event, pregnancy"
    },
    {
      "id": 639,
      "kana": "おめでとう",
      "kanji": "",
      "type": "int",
      "meaning": "Congratulations!, an auspicious occasion!"
    },
    {
      "id": 640,
      "kana": "おめにかかる",
      "kanji": "お目に掛かる",
      "type": "",
      "meaning": ""
    },
    {
      "id": 641,
      "kana": "おもい",
      "kanji": "重い",
      "type": "adj",
      "meaning": "(1) heavy, massive, (2) serious, important"
    },
    {
      "id": 642,
      "kana": "おもいがけない",
      "kanji": "思い掛けない",
      "type": "adj",
      "meaning": "unexpected, casual"
    },
    {
      "id": 643,
      "kana": "おもいきり",
      "kanji": "思い切り",
      "type": "adv,n",
      "meaning": "with all one's strength (heart), resignation, resolution"
    },
    {
      "id": 644,
      "kana": "おもいこむ",
      "kanji": "思い込む",
      "type": "v5m",
      "meaning": "to be under impression that, to be convinced that"
    },
    {
      "id": 645,
      "kana": "おもいだす",
      "kanji": "思い出す",
      "type": "v5s",
      "meaning": "to recall, to remember"
    },
    {
      "id": 646,
      "kana": "おもいっきり",
      "kanji": "思いっ切り",
      "type": "",
      "meaning": ""
    },
    {
      "id": 647,
      "kana": "おもいつく",
      "kanji": "思い付く",
      "type": "v5k",
      "meaning": "to think of, to hit upon"
    },
    {
      "id": 648,
      "kana": "おもいで",
      "kanji": "思い出",
      "type": "n",
      "meaning": "memories, recollections, reminiscence"
    },
    {
      "id": 649,
      "kana": "おもう",
      "kanji": "思う",
      "type": "v5u",
      "meaning": "to think, to feel"
    },
    {
      "id": 650,
      "kana": "おもしろい",
      "kanji": "",
      "type": "adj",
      "meaning": "interesting, amusing"
    },
    {
      "id": 651,
      "kana": "おもたい",
      "kanji": "重たい",
      "type": "adj",
      "meaning": "heavy, massive, serious"
    },
    {
      "id": 652,
      "kana": "おもちゃ",
      "kanji": "",
      "type": "n",
      "meaning": "toy"
    },
    {
      "id": 653,
      "kana": "おもて",
      "kanji": "表",
      "type": "n,n-suf",
      "meaning": "table (e.g. Tab 1), chart, list"
    },
    {
      "id": 654,
      "kana": "おもに",
      "kanji": "主に",
      "type": "adv",
      "meaning": "mainly, primarily"
    },
    {
      "id": 655,
      "kana": "おもわず",
      "kanji": "思わず",
      "type": "adv",
      "meaning": "unintentional, spontaneous"
    },
    {
      "id": 656,
      "kana": "おや",
      "kanji": "",
      "type": "n",
      "meaning": "parents"
    },
    {
      "id": 657,
      "kana": "おや",
      "kanji": "親",
      "type": "n",
      "meaning": "parents"
    },
    {
      "id": 658,
      "kana": "おやすみ",
      "kanji": "",
      "type": "n",
      "meaning": "(1) holiday, absence, rest, (2) (exp) Good night"
    },
    {
      "id": 659,
      "kana": "おやつ",
      "kanji": "",
      "type": "n",
      "meaning": "(1) between meal snack, afternoon refreshment"
    },
    {
      "id": 660,
      "kana": "おやゆび",
      "kanji": "親指",
      "type": "n",
      "meaning": "thumb"
    },
    {
      "id": 661,
      "kana": "およぎ",
      "kanji": "泳ぎ",
      "type": "n",
      "meaning": "swimming"
    },
    {
      "id": 662,
      "kana": "およぐ",
      "kanji": "泳ぐ",
      "type": "v5g",
      "meaning": "to swim"
    },
    {
      "id": 663,
      "kana": "およそ",
      "kanji": "",
      "type": "adv,n",
      "meaning": "about, roughly, as a rule, approximately"
    },
    {
      "id": 664,
      "kana": "およぼす",
      "kanji": "及ぼす",
      "type": "v5s",
      "meaning": "to exert, to cause, to exercise"
    },
    {
      "id": 665,
      "kana": "おりる",
      "kanji": "下りる",
      "type": "v1",
      "meaning": "to alight (e.g. from bus), to get off"
    },
    {
      "id": 666,
      "kana": "おりる",
      "kanji": "降りる",
      "type": "v1",
      "meaning": "to alight (e.g. from bus), to get off"
    },
    {
      "id": 667,
      "kana": "おる",
      "kanji": "居る",
      "type": "v5r",
      "meaning": "to be (animate), to be, to exist"
    },
    {
      "id": 668,
      "kana": "おる",
      "kanji": "折る",
      "type": "v5r",
      "meaning": "to break, to fold, to pick flower"
    },
    {
      "id": 669,
      "kana": "オルガン",
      "kanji": "",
      "type": "n",
      "meaning": "organ"
    },
    {
      "id": 670,
      "kana": "おれる",
      "kanji": "折れる",
      "type": "v1,vi,vt",
      "meaning": "to break, to be folded, to give in"
    },
    {
      "id": 671,
      "kana": "おろす",
      "kanji": "下す",
      "type": "v5s",
      "meaning": "to lower, to let go down"
    },
    {
      "id": 672,
      "kana": "おろす",
      "kanji": "降ろす",
      "type": "v5s",
      "meaning": "to take down, to launch, to drop"
    },
    {
      "id": 673,
      "kana": "おろす",
      "kanji": "卸す",
      "type": "v5s",
      "meaning": "to sell wholesale, grated (vegetables)"
    },
    {
      "id": 674,
      "kana": "おわり",
      "kanji": "終",
      "type": "n",
      "meaning": "the end"
    },
    {
      "id": 675,
      "kana": "おわる",
      "kanji": "終る",
      "type": "io",
      "meaning": "(v5r) to finish, to close"
    },
    {
      "id": 676,
      "kana": "~おわる",
      "kanji": "~終る",
      "type": "",
      "meaning": ""
    },
    {
      "id": 677,
      "kana": "おん",
      "kanji": "音",
      "type": "n,n-suf",
      "meaning": "sound, note"
    },
    {
      "id": 678,
      "kana": "おん",
      "kanji": "恩",
      "type": "n",
      "meaning": "favour, obligation, debt of gratitude"
    },
    {
      "id": 679,
      "kana": "おんがく",
      "kanji": "音楽",
      "type": "n",
      "meaning": "music, musical movement"
    },
    {
      "id": 680,
      "kana": "おんけい",
      "kanji": "恩恵",
      "type": "n",
      "meaning": "grace, favor, blessing, benefit"
    },
    {
      "id": 681,
      "kana": "おんしつ",
      "kanji": "温室",
      "type": "n",
      "meaning": "greenhouse"
    },
    {
      "id": 682,
      "kana": "おんせん",
      "kanji": "温泉",
      "type": "n",
      "meaning": "spa, hot spring, onsen"
    },
    {
      "id": 683,
      "kana": "おんたい",
      "kanji": "温帯",
      "type": "n",
      "meaning": "temperate zone"
    },
    {
      "id": 684,
      "kana": "おんだん",
      "kanji": "温暖",
      "type": "adj-na,n",
      "meaning": "warmth"
    },
    {
      "id": 685,
      "kana": "おんちゅう",
      "kanji": "御中",
      "type": "n",
      "meaning": "and Company, Messrs."
    },
    {
      "id": 686,
      "kana": "おんど",
      "kanji": "温度",
      "type": "n",
      "meaning": "temperature"
    },
    {
      "id": 687,
      "kana": "おんな",
      "kanji": "女",
      "type": "n",
      "meaning": "woman, girl, daughter"
    },
    {
      "id": 688,
      "kana": "おんなのこ",
      "kanji": "女の子",
      "type": "n",
      "meaning": "girl"
    },
    {
      "id": 689,
      "kana": "おんなのひと",
      "kanji": "女の人",
      "type": "",
      "meaning": "woman"
    },
    {
      "id": 690,
      "kana": "か",
      "kanji": "可",
      "type": "n,n-suf",
      "meaning": "passable"
    },
    {
      "id": 691,
      "kana": "か",
      "kanji": "蚊",
      "type": "n",
      "meaning": "mosquito"
    },
    {
      "id": 692,
      "kana": "か",
      "kanji": "課",
      "type": "n,n-suf",
      "meaning": "counter for chapters (of a book)"
    },
    {
      "id": 693,
      "kana": "~か",
      "kanji": "~日",
      "type": "",
      "meaning": ""
    },
    {
      "id": 694,
      "kana": "~か",
      "kanji": "~下",
      "type": "",
      "meaning": ""
    },
    {
      "id": 695,
      "kana": "~か",
      "kanji": "~化",
      "type": "",
      "meaning": ""
    },
    {
      "id": 696,
      "kana": "~か",
      "kanji": "~科",
      "type": "",
      "meaning": ""
    },
    {
      "id": 697,
      "kana": "~か",
      "kanji": "~家",
      "type": "",
      "meaning": ""
    },
    {
      "id": 698,
      "kana": "~か",
      "kanji": "~歌",
      "type": "",
      "meaning": ""
    },
    {
      "id": 699,
      "kana": "が~",
      "kanji": "画~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 700,
      "kana": "~が",
      "kanji": "~画",
      "type": "",
      "meaning": ""
    },
    {
      "id": 701,
      "kana": "カー",
      "kanji": "",
      "type": "n",
      "meaning": "car"
    },
    {
      "id": 702,
      "kana": "カーテン",
      "kanji": "",
      "type": "n",
      "meaning": "curtain, carton"
    },
    {
      "id": 703,
      "kana": "カード",
      "kanji": "",
      "type": "n",
      "meaning": "card, curd"
    },
    {
      "id": 704,
      "kana": "カーブ",
      "kanji": "",
      "type": "n",
      "meaning": "(1) curve, (2) curve ball (baseball)"
    },
    {
      "id": 705,
      "kana": "かい",
      "kanji": "会",
      "type": "n,n-suf,vs",
      "meaning": "meeting, assembly, party, association, club"
    },
    {
      "id": 706,
      "kana": "かい",
      "kanji": "回",
      "type": "n",
      "meaning": "counter for occurrences"
    },
    {
      "id": 707,
      "kana": "かい",
      "kanji": "貝",
      "type": "n",
      "meaning": "shell, shellfish"
    },
    {
      "id": 708,
      "kana": "~かい",
      "kanji": "~階",
      "type": "",
      "meaning": ""
    },
    {
      "id": 709,
      "kana": "がい",
      "kanji": "害",
      "type": "n,vs",
      "meaning": "injury, harm, evil influence, damage"
    },
    {
      "id": 710,
      "kana": "がい~",
      "kanji": "外~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 711,
      "kana": "~がい",
      "kanji": "~外",
      "type": "",
      "meaning": ""
    },
    {
      "id": 712,
      "kana": "かいいん",
      "kanji": "会員",
      "type": "n",
      "meaning": "member, the membership"
    },
    {
      "id": 713,
      "kana": "かいが",
      "kanji": "絵画",
      "type": "n",
      "meaning": "picture"
    },
    {
      "id": 714,
      "kana": "かいかい",
      "kanji": "開会",
      "type": "n",
      "meaning": "opening of a meeting"
    },
    {
      "id": 715,
      "kana": "かいがい",
      "kanji": "海外",
      "type": "n",
      "meaning": "foreign, abroad, overseas"
    },
    {
      "id": 716,
      "kana": "かいかん",
      "kanji": "会館",
      "type": "n",
      "meaning": "meeting hall, assembly hall"
    },
    {
      "id": 717,
      "kana": "かいがん",
      "kanji": "海岸",
      "type": "n",
      "meaning": "coast, beach"
    },
    {
      "id": 718,
      "kana": "かいぎ",
      "kanji": "会議",
      "type": "n",
      "meaning": "meeting, conference"
    },
    {
      "id": 719,
      "kana": "かいけい",
      "kanji": "会計",
      "type": "n",
      "meaning": "account, finance, accountant"
    },
    {
      "id": 720,
      "kana": "かいけつ",
      "kanji": "解決",
      "type": "n,vs",
      "meaning": "settlement, solution, resolution"
    },
    {
      "id": 721,
      "kana": "かいごう",
      "kanji": "会合",
      "type": "n",
      "meaning": "meeting, assembly"
    },
    {
      "id": 722,
      "kana": "がいこう",
      "kanji": "外交",
      "type": "n",
      "meaning": "diplomacy"
    },
    {
      "id": 723,
      "kana": "がいこく",
      "kanji": "外国",
      "type": "n",
      "meaning": "foreign country"
    },
    {
      "id": 724,
      "kana": "かいさつ",
      "kanji": "改札",
      "type": "n,vs",
      "meaning": "examination of tickets"
    },
    {
      "id": 725,
      "kana": "かいさん",
      "kanji": "解散",
      "type": "n,vs",
      "meaning": "breakup, dissolution"
    },
    {
      "id": 726,
      "kana": "かいし",
      "kanji": "開始",
      "type": "n,vs",
      "meaning": "start, commencement, beginning"
    },
    {
      "id": 727,
      "kana": "かいしゃ",
      "kanji": "会社",
      "type": "n",
      "meaning": "company, corporation"
    },
    {
      "id": 728,
      "kana": "かいしゃく",
      "kanji": "解釈",
      "type": "n,vs",
      "meaning": "explanation, interpretation"
    },
    {
      "id": 729,
      "kana": "がいしゅつ",
      "kanji": "外出",
      "type": "n,vs",
      "meaning": "outing, going out"
    },
    {
      "id": 730,
      "kana": "かいじょう",
      "kanji": "会場",
      "type": "n",
      "meaning": "assembly hall, meeting place, the grounds"
    },
    {
      "id": 731,
      "kana": "かいすいよく",
      "kanji": "海水浴",
      "type": "n",
      "meaning": "sea bathing, seawater bath"
    },
    {
      "id": 732,
      "kana": "かいすう",
      "kanji": "回数",
      "type": "n",
      "meaning": "number of times, frequency"
    },
    {
      "id": 733,
      "kana": "かいすうけん",
      "kanji": "回数券",
      "type": "n",
      "meaning": "book of tickets"
    },
    {
      "id": 734,
      "kana": "かいせい",
      "kanji": "改正",
      "type": "n,vs",
      "meaning": "revision, amendment, alteration"
    },
    {
      "id": 735,
      "kana": "かいせい",
      "kanji": "快晴",
      "type": "n",
      "meaning": "good weather"
    },
    {
      "id": 736,
      "kana": "かいせつ",
      "kanji": "解説",
      "type": "n,vs",
      "meaning": "explanation, commentary"
    },
    {
      "id": 737,
      "kana": "かいぜん",
      "kanji": "改善",
      "type": "n,vs",
      "meaning": "betterment, improvement"
    },
    {
      "id": 738,
      "kana": "かいぞう",
      "kanji": "改造",
      "type": "n,vs",
      "meaning": "(1) remodeling, (2) modding (comp)"
    },
    {
      "id": 739,
      "kana": "かいだん",
      "kanji": "階段",
      "type": "n",
      "meaning": "stairs"
    },
    {
      "id": 740,
      "kana": "かいつう",
      "kanji": "開通",
      "type": "n,vs",
      "meaning": "opening, open"
    },
    {
      "id": 741,
      "kana": "かいてき",
      "kanji": "快適",
      "type": "adj-na,n",
      "meaning": "pleasant, agreeable, comfortable"
    },
    {
      "id": 742,
      "kana": "かいてん",
      "kanji": "回転",
      "type": "n,vs",
      "meaning": "rotation, revolution, turning"
    },
    {
      "id": 743,
      "kana": "かいとう",
      "kanji": "解答",
      "type": "n,vs",
      "meaning": "answer, solution"
    },
    {
      "id": 744,
      "kana": "かいとう",
      "kanji": "回答",
      "type": "n",
      "meaning": "reply, answer"
    },
    {
      "id": 745,
      "kana": "がいぶ",
      "kanji": "外部",
      "type": "n",
      "meaning": "the outside, external"
    },
    {
      "id": 746,
      "kana": "かいふく",
      "kanji": "回復",
      "type": "n,vs",
      "meaning": "recovery (from illness), improvement, rehabilitation, restoration"
    },
    {
      "id": 747,
      "kana": "かいほう",
      "kanji": "解放",
      "type": "n,vs",
      "meaning": "release, liberation, emancipation"
    },
    {
      "id": 748,
      "kana": "かいほう",
      "kanji": "開放",
      "type": "n,vs",
      "meaning": "open, throw open, liberalization"
    },
    {
      "id": 749,
      "kana": "かいもの",
      "kanji": "買い物",
      "type": "n",
      "meaning": "shopping"
    },
    {
      "id": 750,
      "kana": "かいよう",
      "kanji": "海洋",
      "type": "n",
      "meaning": "ocean"
    },
    {
      "id": 751,
      "kana": "がいろん",
      "kanji": "概論",
      "type": "n",
      "meaning": "intro, outline, general remarks"
    },
    {
      "id": 752,
      "kana": "かいわ",
      "kanji": "会話",
      "type": "n",
      "meaning": "conversation"
    },
    {
      "id": 753,
      "kana": "かう",
      "kanji": "買う",
      "type": "v5u",
      "meaning": "to buy"
    },
    {
      "id": 754,
      "kana": "かう",
      "kanji": "飼う",
      "type": "v5u",
      "meaning": "to keep, to raise, to feed"
    },
    {
      "id": 755,
      "kana": "かえす",
      "kanji": "返す",
      "type": "v5s,vt",
      "meaning": "to return something"
    },
    {
      "id": 756,
      "kana": "かえす",
      "kanji": "帰す",
      "type": "v5s",
      "meaning": "to send back"
    },
    {
      "id": 757,
      "kana": "かえって",
      "kanji": "却って",
      "type": "adv",
      "meaning": "on the contrary, rather, all the more, instead"
    },
    {
      "id": 758,
      "kana": "かえり",
      "kanji": "帰り",
      "type": "n",
      "meaning": "return, coming back"
    },
    {
      "id": 759,
      "kana": "かえる",
      "kanji": "代える",
      "type": "v1",
      "meaning": "to exchange, to interchange, to substitute, to replace"
    },
    {
      "id": 760,
      "kana": "かえる",
      "kanji": "替える",
      "type": "v1",
      "meaning": "to exchange, to interchange, to substitute, to replace"
    },
    {
      "id": 761,
      "kana": "かえる",
      "kanji": "換える",
      "type": "v1",
      "meaning": "to exchange, to interchange, to substitute, to replace"
    },
    {
      "id": 762,
      "kana": "かえる",
      "kanji": "変える",
      "type": "v1,vt",
      "meaning": "to change, to alter, to vary"
    },
    {
      "id": 763,
      "kana": "かえる",
      "kanji": "反る",
      "type": "v5r",
      "meaning": "to warp, to be warped, to curve"
    },
    {
      "id": 764,
      "kana": "かえる",
      "kanji": "帰る",
      "type": "v5r",
      "meaning": "to go back, to go home, to come home, to return"
    },
    {
      "id": 765,
      "kana": "かお",
      "kanji": "顔",
      "type": "n",
      "meaning": "face (person)"
    },
    {
      "id": 766,
      "kana": "かおく",
      "kanji": "家屋",
      "type": "n",
      "meaning": "house, building"
    },
    {
      "id": 767,
      "kana": "かおり",
      "kanji": "香り",
      "type": "n",
      "meaning": "aroma, fragrance, scent, smell"
    },
    {
      "id": 768,
      "kana": "がか",
      "kanji": "画家",
      "type": "n",
      "meaning": "painter, artist"
    },
    {
      "id": 769,
      "kana": "かかえる",
      "kanji": "抱える",
      "type": "v1",
      "meaning": "to hold or carry under or in the arms"
    },
    {
      "id": 770,
      "kana": "かかく",
      "kanji": "価格",
      "type": "n",
      "meaning": "price, value, cost"
    },
    {
      "id": 771,
      "kana": "かがく",
      "kanji": "科学",
      "type": "n",
      "meaning": "science"
    },
    {
      "id": 772,
      "kana": "かがく",
      "kanji": "化学",
      "type": "n",
      "meaning": "chemistry"
    },
    {
      "id": 773,
      "kana": "かがみ",
      "kanji": "鏡",
      "type": "n",
      "meaning": "mirror"
    },
    {
      "id": 774,
      "kana": "かがやく",
      "kanji": "輝く",
      "type": "v5k",
      "meaning": "to shine, to glitter, to sparkle"
    },
    {
      "id": 775,
      "kana": "かかり",
      "kanji": "係",
      "type": "n",
      "meaning": "official, duty, person in charge"
    },
    {
      "id": 776,
      "kana": "かかる",
      "kanji": "掛かる",
      "type": "v5r,vi",
      "meaning": "to take (e.g. time, money, etc), to hang"
    },
    {
      "id": 777,
      "kana": "かかる",
      "kanji": "罹る",
      "type": "v5r",
      "meaning": "to suffer from"
    },
    {
      "id": 778,
      "kana": "かかわる",
      "kanji": "係わる",
      "type": "v5r",
      "meaning": "to concern oneself in, to have to do with, to affect, to influence, to stick to (opinions)"
    },
    {
      "id": 779,
      "kana": "かぎ",
      "kanji": "鍵",
      "type": "n",
      "meaning": "key"
    },
    {
      "id": 780,
      "kana": "かきとめ",
      "kanji": "書留",
      "type": "n,vs",
      "meaning": "writing down, putting on record, recording, making a note of, registration (of mail)"
    },
    {
      "id": 781,
      "kana": "かきとり",
      "kanji": "書取",
      "type": "n",
      "meaning": "dictation"
    },
    {
      "id": 782,
      "kana": "かきね",
      "kanji": "垣根",
      "type": "n",
      "meaning": "hedge"
    },
    {
      "id": 783,
      "kana": "かぎり",
      "kanji": "限り",
      "type": "n-adv,n",
      "meaning": "limit(s), as far as possible, as much as possible, to the best of one's ability"
    },
    {
      "id": 784,
      "kana": "かぎる",
      "kanji": "限る",
      "type": "v5r",
      "meaning": "to restrict, to limit, to confine"
    },
    {
      "id": 785,
      "kana": "かく",
      "kanji": "書く",
      "type": "v5k",
      "meaning": "to write"
    },
    {
      "id": 786,
      "kana": "かく",
      "kanji": "掻く",
      "type": "v5k",
      "meaning": "to scratch, to perspire"
    },
    {
      "id": 787,
      "kana": "かく",
      "kanji": "描く",
      "type": "v5k",
      "meaning": "to draw, to paint, to sketch, to depict, to describe"
    },
    {
      "id": 788,
      "kana": "かく~",
      "kanji": "各~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 789,
      "kana": "かぐ",
      "kanji": "嗅ぐ",
      "type": "v5g,vt",
      "meaning": "to sniff, to smell"
    },
    {
      "id": 790,
      "kana": "かぐ",
      "kanji": "家具",
      "type": "n",
      "meaning": "furniture"
    },
    {
      "id": 791,
      "kana": "がく",
      "kanji": "学",
      "type": "n",
      "meaning": "learning, scholarship, erudition, knowledge"
    },
    {
      "id": 792,
      "kana": "がく",
      "kanji": "額",
      "type": "n",
      "meaning": "picture (framed)"
    },
    {
      "id": 793,
      "kana": "かくう",
      "kanji": "架空",
      "type": "adj-na,adj-no,n",
      "meaning": "aerial, overhead, fiction"
    },
    {
      "id": 794,
      "kana": "かくご",
      "kanji": "覚悟",
      "type": "n,vs",
      "meaning": "resolution, resignation, readiness"
    },
    {
      "id": 795,
      "kana": "かくじ",
      "kanji": "各自",
      "type": "n-adv,n-t",
      "meaning": "individual, each"
    },
    {
      "id": 796,
      "kana": "かくじつ",
      "kanji": "確実",
      "type": "adj-na,n",
      "meaning": "certainty, reliability, soundness"
    },
    {
      "id": 797,
      "kana": "がくしゃ",
      "kanji": "学者",
      "type": "n",
      "meaning": "scholar"
    },
    {
      "id": 798,
      "kana": "かくじゅう",
      "kanji": "拡充",
      "type": "n",
      "meaning": "expansion"
    },
    {
      "id": 799,
      "kana": "がくしゅう",
      "kanji": "学習",
      "type": "n,vs",
      "meaning": "study, learning"
    },
    {
      "id": 800,
      "kana": "がくじゅつ",
      "kanji": "学術",
      "type": "n",
      "meaning": "science, learning, scholarship"
    },
    {
      "id": 801,
      "kana": "かくす",
      "kanji": "隠す",
      "type": "v5s,vt",
      "meaning": "to hide, to conceal"
    },
    {
      "id": 802,
      "kana": "がくせい",
      "kanji": "学生",
      "type": "n",
      "meaning": "student"
    },
    {
      "id": 803,
      "kana": "かくだい",
      "kanji": "拡大",
      "type": "n,vs",
      "meaning": "magnification, enlargement"
    },
    {
      "id": 804,
      "kana": "かくち",
      "kanji": "各地",
      "type": "n",
      "meaning": "every place, various places"
    },
    {
      "id": 805,
      "kana": "かくちょう",
      "kanji": "拡張",
      "type": "n,vs",
      "meaning": "expansion, extension, enlargement, escape (ESC)"
    },
    {
      "id": 806,
      "kana": "かくど",
      "kanji": "角度",
      "type": "n",
      "meaning": "angle"
    },
    {
      "id": 807,
      "kana": "かくにん",
      "kanji": "確認",
      "type": "n,vs",
      "meaning": "affirmation, confirmation"
    },
    {
      "id": 808,
      "kana": "がくねん",
      "kanji": "学年",
      "type": "n",
      "meaning": "year in school, grade in school"
    },
    {
      "id": 809,
      "kana": "がくぶ",
      "kanji": "学部",
      "type": "n",
      "meaning": "department of a university, undergraduate"
    },
    {
      "id": 810,
      "kana": "かくべつ",
      "kanji": "格別",
      "type": "adj-na,adv,n",
      "meaning": "exceptional"
    },
    {
      "id": 811,
      "kana": "がくもん",
      "kanji": "学問",
      "type": "n",
      "meaning": "scholarship, study, learning"
    },
    {
      "id": 812,
      "kana": "かくりつ",
      "kanji": "確率",
      "type": "n",
      "meaning": "probability"
    },
    {
      "id": 813,
      "kana": "がくりょく",
      "kanji": "学力",
      "type": "n",
      "meaning": "scholarship, knowledge, literary ability"
    },
    {
      "id": 814,
      "kana": "かくれる",
      "kanji": "隠れる",
      "type": "v1,vi",
      "meaning": "to hide, to be hidden, to conceal oneself, to disappear"
    },
    {
      "id": 815,
      "kana": "かげ",
      "kanji": "影",
      "type": "n",
      "meaning": "shade, shadow, other side"
    },
    {
      "id": 816,
      "kana": "かげ",
      "kanji": "陰",
      "type": "n",
      "meaning": "shade, shadow, other side"
    },
    {
      "id": 817,
      "kana": "かけざん",
      "kanji": "掛け算",
      "type": "n",
      "meaning": "multiplication"
    },
    {
      "id": 818,
      "kana": "かけつ",
      "kanji": "可決",
      "type": "n,vs",
      "meaning": "approval, adoption (e.g. motion, bill), passage"
    },
    {
      "id": 819,
      "kana": "~かげつ",
      "kanji": "~ヶ月",
      "type": "",
      "meaning": "for ~ months"
    },
    {
      "id": 820,
      "kana": "かける",
      "kanji": "掛ける",
      "type": "aux-v,v1",
      "meaning": "(1) to wear, to put on, (2) to sit down, (3) to make a phone call"
    },
    {
      "id": 821,
      "kana": "かける",
      "kanji": "欠ける",
      "type": "v1",
      "meaning": "to be lacking"
    },
    {
      "id": 822,
      "kana": "かげん",
      "kanji": "加減",
      "type": "n",
      "meaning": "addition and subtraction, allowance for"
    },
    {
      "id": 823,
      "kana": "かこ",
      "kanji": "過去",
      "type": "n-adv,n",
      "meaning": "the past, bygone days, the previous"
    },
    {
      "id": 824,
      "kana": "かご",
      "kanji": "籠",
      "type": "n",
      "meaning": "basket, cage"
    },
    {
      "id": 825,
      "kana": "かこう",
      "kanji": "火口",
      "type": "n",
      "meaning": "a burner, origin of a fire"
    },
    {
      "id": 826,
      "kana": "かこう",
      "kanji": "下降",
      "type": "n",
      "meaning": "downward, descent, fall, drop, subsidence"
    },
    {
      "id": 827,
      "kana": "かこむ",
      "kanji": "囲む",
      "type": "v5m",
      "meaning": "to surround, to encircle"
    },
    {
      "id": 828,
      "kana": "かさ",
      "kanji": "傘",
      "type": "n",
      "meaning": "umbrella, parasol"
    },
    {
      "id": 829,
      "kana": "かさい",
      "kanji": "火災",
      "type": "n",
      "meaning": "conflagration, fire"
    },
    {
      "id": 830,
      "kana": "かさなる",
      "kanji": "重なる",
      "type": "v5r",
      "meaning": "to be piled up, lie on top of one another, overlap each other"
    },
    {
      "id": 831,
      "kana": "かさねる",
      "kanji": "重ねる",
      "type": "v1",
      "meaning": "to pile up, to put something on another, to heap up, to add, to repeat"
    },
    {
      "id": 832,
      "kana": "かざり",
      "kanji": "飾り",
      "type": "n,n-suf",
      "meaning": "decoration"
    },
    {
      "id": 833,
      "kana": "かざる",
      "kanji": "飾る",
      "type": "v5r",
      "meaning": "to decorate, to ornament, to adorn"
    },
    {
      "id": 834,
      "kana": "かざん",
      "kanji": "火山",
      "type": "n",
      "meaning": "volcano"
    },
    {
      "id": 835,
      "kana": "かし",
      "kanji": "貸し",
      "type": "n",
      "meaning": "loan, lending"
    },
    {
      "id": 836,
      "kana": "かし",
      "kanji": "菓子",
      "type": "n",
      "meaning": "pastry"
    },
    {
      "id": 837,
      "kana": "かじ",
      "kanji": "火事",
      "type": "n",
      "meaning": "fire, conflagration"
    },
    {
      "id": 838,
      "kana": "かじ",
      "kanji": "家事",
      "type": "n",
      "meaning": "housework, domestic chores"
    },
    {
      "id": 839,
      "kana": "かしこい",
      "kanji": "賢い",
      "type": "adj",
      "meaning": "wise, clever, smart"
    },
    {
      "id": 840,
      "kana": "かしこまりました",
      "kanji": "",
      "type": "exp",
      "meaning": "certainly!"
    },
    {
      "id": 841,
      "kana": "かしだし",
      "kanji": "貸し出し",
      "type": "n,vs",
      "meaning": "lending, loaning"
    },
    {
      "id": 842,
      "kana": "かしつ",
      "kanji": "過失",
      "type": "n",
      "meaning": "error, blunder, accident"
    },
    {
      "id": 843,
      "kana": "かじつ",
      "kanji": "果実",
      "type": "n",
      "meaning": "fruit, nut, berry."
    },
    {
      "id": 844,
      "kana": "かしま",
      "kanji": "貸間",
      "type": "n",
      "meaning": "room to let"
    },
    {
      "id": 845,
      "kana": "かしや",
      "kanji": "貸家",
      "type": "n",
      "meaning": "house for rent"
    },
    {
      "id": 846,
      "kana": "かしゅ",
      "kanji": "歌手",
      "type": "n",
      "meaning": "singer"
    },
    {
      "id": 847,
      "kana": "かしょ",
      "kanji": "個所",
      "type": "n",
      "meaning": "passage, place, point, part"
    },
    {
      "id": 848,
      "kana": "かじょう",
      "kanji": "過剰",
      "type": "adj-na,n",
      "meaning": "excess, over-"
    },
    {
      "id": 849,
      "kana": "かじる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to chew, to bite (at), to gnaw, to nibble"
    },
    {
      "id": 850,
      "kana": "かす",
      "kanji": "貸す",
      "type": "v5s",
      "meaning": "to lend"
    },
    {
      "id": 851,
      "kana": "かず",
      "kanji": "数",
      "type": "n,n-suf",
      "meaning": "number, figure"
    },
    {
      "id": 852,
      "kana": "ガス",
      "kanji": "",
      "type": "n",
      "meaning": "gas"
    },
    {
      "id": 853,
      "kana": "かぜ",
      "kanji": "風",
      "type": "adj-na,n,n-suf",
      "meaning": "method, manner, way"
    },
    {
      "id": 854,
      "kana": "かぜ",
      "kanji": "風邪",
      "type": "n",
      "meaning": "cold (illness), common cold"
    },
    {
      "id": 855,
      "kana": "かぜい",
      "kanji": "課税",
      "type": "n,vs",
      "meaning": "taxation"
    },
    {
      "id": 856,
      "kana": "かせん",
      "kanji": "下線",
      "type": "n",
      "meaning": "underline, underscore"
    },
    {
      "id": 857,
      "kana": "かせぐ",
      "kanji": "稼ぐ",
      "type": "v5g",
      "meaning": "to earn income, to labor"
    },
    {
      "id": 858,
      "kana": "カセット",
      "kanji": "",
      "type": "n",
      "meaning": "cassette (tape)"
    },
    {
      "id": 859,
      "kana": "かぞえる",
      "kanji": "数える",
      "type": "v1",
      "meaning": "to count"
    },
    {
      "id": 860,
      "kana": "かそく",
      "kanji": "加速",
      "type": "n,vs",
      "meaning": "acceleration"
    },
    {
      "id": 861,
      "kana": "かぞく",
      "kanji": "家族",
      "type": "n",
      "meaning": "family, members of a family"
    },
    {
      "id": 862,
      "kana": "かそくど",
      "kanji": "加速度",
      "type": "n",
      "meaning": "acceleration"
    },
    {
      "id": 863,
      "kana": "ガソリン",
      "kanji": "",
      "type": "n",
      "meaning": "gasoline, petrol"
    },
    {
      "id": 864,
      "kana": "ガソリンスタンド",
      "kanji": "",
      "type": "n",
      "meaning": "gasoline stand, gas station"
    },
    {
      "id": 865,
      "kana": "かた",
      "kanji": "方",
      "type": "n-adv,n",
      "meaning": "side"
    },
    {
      "id": 866,
      "kana": "かた",
      "kanji": "型",
      "type": "n",
      "meaning": "mold, model, style, shape, data-type"
    },
    {
      "id": 867,
      "kana": "かた",
      "kanji": "肩",
      "type": "n",
      "meaning": "shoulder"
    },
    {
      "id": 868,
      "kana": "~かた",
      "kanji": "~方",
      "type": "",
      "meaning": ""
    },
    {
      "id": 869,
      "kana": "かたい",
      "kanji": "固い",
      "type": "adj",
      "meaning": "stubborn, firm (not viscous or easily moved)"
    },
    {
      "id": 870,
      "kana": "かたい",
      "kanji": "堅い",
      "type": "adj",
      "meaning": "hard (esp. wood), steadfast, honorable, stuffy writing"
    },
    {
      "id": 871,
      "kana": "かたい",
      "kanji": "硬い",
      "type": "adj",
      "meaning": "solid, hard (esp. metal, stone), unpolished writing"
    },
    {
      "id": 872,
      "kana": "かたがた",
      "kanji": "方々",
      "type": "adv,conj,n-adv,n-t",
      "meaning": "persons, this and that, here and there, everywhere"
    },
    {
      "id": 873,
      "kana": "かたかな",
      "kanji": "片仮名",
      "type": "n",
      "meaning": "katakana"
    },
    {
      "id": 874,
      "kana": "かたち",
      "kanji": "形",
      "type": "n",
      "meaning": "form, shape, figure, type"
    },
    {
      "id": 875,
      "kana": "かたづく",
      "kanji": "片付く",
      "type": "v5k",
      "meaning": "to put in order, to dispose of, to solve"
    },
    {
      "id": 876,
      "kana": "かたづける",
      "kanji": "片付ける",
      "type": "v1",
      "meaning": "to tidy up, to put in order, to straighten up, to put away"
    },
    {
      "id": 877,
      "kana": "かたな",
      "kanji": "刀",
      "type": "n",
      "meaning": "sword, saber, knife, engraving tool"
    },
    {
      "id": 878,
      "kana": "かたまり",
      "kanji": "塊",
      "type": "n",
      "meaning": "lump, mass, clod, cluster"
    },
    {
      "id": 879,
      "kana": "かたまる",
      "kanji": "固まる",
      "type": "v5r,vi",
      "meaning": "to harden, to solidify, to become firm, to become certain"
    },
    {
      "id": 880,
      "kana": "かたみち",
      "kanji": "片道",
      "type": "n",
      "meaning": "one-way (trip)"
    },
    {
      "id": 881,
      "kana": "かたむく",
      "kanji": "傾く",
      "type": "v5k",
      "meaning": "to incline toward, to slant, to lurch"
    },
    {
      "id": 882,
      "kana": "かたよる",
      "kanji": "片寄る",
      "type": "v5r",
      "meaning": "to be one-sided, to incline, to be partial"
    },
    {
      "id": 883,
      "kana": "かたる",
      "kanji": "語る",
      "type": "v5r",
      "meaning": "to talk, to tell, to recite"
    },
    {
      "id": 884,
      "kana": "かち",
      "kanji": "勝ち",
      "type": "n",
      "meaning": "win, victory"
    },
    {
      "id": 885,
      "kana": "かち",
      "kanji": "価値",
      "type": "n",
      "meaning": "value, worth, merit"
    },
    {
      "id": 886,
      "kana": "~がち",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 887,
      "kana": "かつ",
      "kanji": "勝つ",
      "type": "v5t",
      "meaning": "to win, to gain victory"
    },
    {
      "id": 888,
      "kana": "~がつ",
      "kanji": "~月",
      "type": "",
      "meaning": ""
    },
    {
      "id": 889,
      "kana": "がっか",
      "kanji": "学科",
      "type": "n",
      "meaning": "study subject, course of study"
    },
    {
      "id": 890,
      "kana": "がっかい",
      "kanji": "学会",
      "type": "n",
      "meaning": "scientific society, academic meeting"
    },
    {
      "id": 891,
      "kana": "がっかり",
      "kanji": "",
      "type": "adv,n,vs",
      "meaning": "feel disappointed, be dejected, lose heart"
    },
    {
      "id": 892,
      "kana": "かっき",
      "kanji": "活気",
      "type": "n",
      "meaning": "energy, liveliness"
    },
    {
      "id": 893,
      "kana": "がっき",
      "kanji": "楽器",
      "type": "n",
      "meaning": "musical instrument"
    },
    {
      "id": 894,
      "kana": "がっき",
      "kanji": "学期",
      "type": "n",
      "meaning": "term (school)"
    },
    {
      "id": 895,
      "kana": "がっきゅう",
      "kanji": "学級",
      "type": "n",
      "meaning": "grade in school"
    },
    {
      "id": 896,
      "kana": "かつぐ",
      "kanji": "担ぐ",
      "type": "v5g",
      "meaning": "to shoulder, to carry on shoulder"
    },
    {
      "id": 897,
      "kana": "かっこ",
      "kanji": "括弧",
      "type": "n",
      "meaning": "parenthesis, brackets"
    },
    {
      "id": 898,
      "kana": "かっこう",
      "kanji": "格好",
      "type": "adj-no,adj-na,n",
      "meaning": "shape, form, posture, suitability"
    },
    {
      "id": 899,
      "kana": "がっこう",
      "kanji": "学校",
      "type": "n",
      "meaning": "school"
    },
    {
      "id": 900,
      "kana": "かつじ",
      "kanji": "活字",
      "type": "n",
      "meaning": "printing type"
    },
    {
      "id": 901,
      "kana": "かってに",
      "kanji": "勝手に",
      "type": "",
      "meaning": "arbitrarily, of it's own accord, involuntarily"
    },
    {
      "id": 902,
      "kana": "かつどう",
      "kanji": "活動",
      "type": "n,vs",
      "meaning": "action, activity"
    },
    {
      "id": 903,
      "kana": "かつやく",
      "kanji": "活躍",
      "type": "n",
      "meaning": "activity"
    },
    {
      "id": 904,
      "kana": "かつよう",
      "kanji": "活用",
      "type": "n,vs",
      "meaning": "conjugation, practical use"
    },
    {
      "id": 905,
      "kana": "かつりょく",
      "kanji": "活力",
      "type": "n",
      "meaning": "vitality, energy"
    },
    {
      "id": 906,
      "kana": "かてい",
      "kanji": "仮定",
      "type": "n,vs",
      "meaning": "assumption, supposition, hypothesis"
    },
    {
      "id": 907,
      "kana": "かてい",
      "kanji": "家庭",
      "type": "n",
      "meaning": "home, family, household"
    },
    {
      "id": 908,
      "kana": "かてい",
      "kanji": "過程",
      "type": "n",
      "meaning": "process"
    },
    {
      "id": 909,
      "kana": "かてい",
      "kanji": "課程",
      "type": "n",
      "meaning": "course, curriculum"
    },
    {
      "id": 910,
      "kana": "かど",
      "kanji": "角",
      "type": "n",
      "meaning": "horn"
    },
    {
      "id": 911,
      "kana": "かな",
      "kanji": "仮名",
      "type": "ok",
      "meaning": "(n) alias, pseudonym, pen name"
    },
    {
      "id": 912,
      "kana": "かない",
      "kanji": "家内",
      "type": "n",
      "meaning": "(hum) wife"
    },
    {
      "id": 913,
      "kana": "かなしい",
      "kanji": "悲しい",
      "type": "adj",
      "meaning": "sad, sorrowful"
    },
    {
      "id": 914,
      "kana": "かなしむ",
      "kanji": "悲しむ",
      "type": "v5m",
      "meaning": "to be sad, to mourn for, to regret"
    },
    {
      "id": 915,
      "kana": "かなづかい",
      "kanji": "仮名遣い",
      "type": "n",
      "meaning": "kana orthography, syllabary spelling"
    },
    {
      "id": 916,
      "kana": "かならず",
      "kanji": "必ず",
      "type": "adv",
      "meaning": "necessarily, certainly"
    },
    {
      "id": 917,
      "kana": "かならずしも",
      "kanji": "必ずしも",
      "type": "adv",
      "meaning": "(not) always, (not) necessarily, (not) all, (not) entirely"
    },
    {
      "id": 918,
      "kana": "かなり",
      "kanji": "",
      "type": "adj-na,adv",
      "meaning": "considerably, fairly, quite"
    },
    {
      "id": 919,
      "kana": "かね",
      "kanji": "金",
      "type": "n,n-suf",
      "meaning": "(1) gold, (2) gold general (shogi) (abbr)"
    },
    {
      "id": 920,
      "kana": "かね",
      "kanji": "鐘",
      "type": "n",
      "meaning": "bell, chime"
    },
    {
      "id": 921,
      "kana": "かねつ",
      "kanji": "加熱",
      "type": "n,vs",
      "meaning": "heating"
    },
    {
      "id": 922,
      "kana": "かねもち",
      "kanji": "金持ち",
      "type": "n",
      "meaning": "rich man"
    },
    {
      "id": 923,
      "kana": "かねる",
      "kanji": "兼ねる",
      "type": "suf,v1",
      "meaning": "to hold (position), to serve, to be unable"
    },
    {
      "id": 924,
      "kana": "かのう",
      "kanji": "可能",
      "type": "adj-na,n",
      "meaning": "possible, practicable, feasible"
    },
    {
      "id": 925,
      "kana": "かのじょ",
      "kanji": "彼女",
      "type": "n",
      "meaning": "she, girl friend, sweetheart"
    },
    {
      "id": 926,
      "kana": "カバー",
      "kanji": "",
      "type": "n",
      "meaning": "cover (ex. book)"
    },
    {
      "id": 927,
      "kana": "かばん",
      "kanji": "",
      "type": "n",
      "meaning": "bag, satchel"
    },
    {
      "id": 928,
      "kana": "かはんすう",
      "kanji": "過半数",
      "type": "n",
      "meaning": "majority"
    },
    {
      "id": 929,
      "kana": "（~がはえる）",
      "kanji": "かび",
      "type": "n",
      "meaning": "mold, mildew"
    },
    {
      "id": 930,
      "kana": "かびん",
      "kanji": "花瓶",
      "type": "n",
      "meaning": "(flower) vase"
    },
    {
      "id": 931,
      "kana": "かぶ",
      "kanji": "株",
      "type": "n",
      "meaning": "share, stock, stump (of tree)"
    },
    {
      "id": 932,
      "kana": "かぶせる",
      "kanji": "被せる",
      "type": "v1",
      "meaning": "to cover (with something)"
    },
    {
      "id": 933,
      "kana": "かぶる",
      "kanji": "被る",
      "type": "v5r",
      "meaning": "to suffer"
    },
    {
      "id": 934,
      "kana": "かべ",
      "kanji": "壁",
      "type": "n",
      "meaning": "wall"
    },
    {
      "id": 935,
      "kana": "かま",
      "kanji": "釜",
      "type": "n",
      "meaning": "iron pot, kettle"
    },
    {
      "id": 936,
      "kana": "かまいません",
      "kanji": "",
      "type": "",
      "meaning": "it doesn't matter"
    },
    {
      "id": 937,
      "kana": "かまう",
      "kanji": "構う",
      "type": "v5u",
      "meaning": "to mind, to care about, to be concerned about"
    },
    {
      "id": 938,
      "kana": "がまん",
      "kanji": "我慢",
      "type": "n,vs",
      "meaning": "patience, endurance, perseverance"
    },
    {
      "id": 939,
      "kana": "かみ",
      "kanji": "上",
      "type": "n,pref,suf",
      "meaning": "(1) first volume, (2) superior quality, (3) governmental"
    },
    {
      "id": 940,
      "kana": "かみ",
      "kanji": "神",
      "type": "n",
      "meaning": "god"
    },
    {
      "id": 941,
      "kana": "かみ",
      "kanji": "髪",
      "type": "n",
      "meaning": "hair"
    },
    {
      "id": 942,
      "kana": "かみくず",
      "kanji": "紙屑",
      "type": "n",
      "meaning": "wastepaper"
    },
    {
      "id": 943,
      "kana": "かみさま",
      "kanji": "神様",
      "type": "n",
      "meaning": "god"
    },
    {
      "id": 944,
      "kana": "かみそり",
      "kanji": "剃刀",
      "type": "n",
      "meaning": "razor"
    },
    {
      "id": 945,
      "kana": "かみなり",
      "kanji": "雷",
      "type": "n",
      "meaning": "thunder"
    },
    {
      "id": 946,
      "kana": "かみのけ",
      "kanji": "髪の毛",
      "type": "n",
      "meaning": "hair (head)"
    },
    {
      "id": 947,
      "kana": "かむ",
      "kanji": "噛む",
      "type": "v5m",
      "meaning": "to bite, to chew, to gnaw"
    },
    {
      "id": 948,
      "kana": "ガム",
      "kanji": "",
      "type": "n",
      "meaning": "chewing gum"
    },
    {
      "id": 949,
      "kana": "カメラ",
      "kanji": "",
      "type": "n",
      "meaning": "camera"
    },
    {
      "id": 950,
      "kana": "かもく",
      "kanji": "科目",
      "type": "n",
      "meaning": "(school) subject, curriculum, course"
    },
    {
      "id": 951,
      "kana": "かもしれない",
      "kanji": "",
      "type": "exp",
      "meaning": "may, might, perhaps, may be, possibly"
    },
    {
      "id": 952,
      "kana": "かもつ",
      "kanji": "貨物",
      "type": "n",
      "meaning": "cargo, freight"
    },
    {
      "id": 953,
      "kana": "かゆい",
      "kanji": "",
      "type": "adj",
      "meaning": "itchy, itching"
    },
    {
      "id": 954,
      "kana": "かよう",
      "kanji": "通う",
      "type": "v5u",
      "meaning": "(1) to go back and forth, to ply between, (2) to commute, to attend (school, church, etc.)"
    },
    {
      "id": 955,
      "kana": "かよう",
      "kanji": "火曜",
      "type": "n-adv,n",
      "meaning": "(abbr) Tuesday"
    },
    {
      "id": 956,
      "kana": "かよう",
      "kanji": "歌謡",
      "type": "n",
      "meaning": "song, ballad"
    },
    {
      "id": 957,
      "kana": "から",
      "kanji": "空",
      "type": "n",
      "meaning": "sky"
    },
    {
      "id": 958,
      "kana": "から",
      "kanji": "殻",
      "type": "n",
      "meaning": "shell, husk, hull, chaff"
    },
    {
      "id": 959,
      "kana": "がら",
      "kanji": "柄",
      "type": "n",
      "meaning": "hilt (of a sword), haft (of a dagger), handgrip"
    },
    {
      "id": 960,
      "kana": "カラー",
      "kanji": "",
      "type": "n",
      "meaning": "collar, color, colour"
    },
    {
      "id": 961,
      "kana": "からい",
      "kanji": "辛い",
      "type": "adj",
      "meaning": "painful, heart-breaking"
    },
    {
      "id": 962,
      "kana": "からかう",
      "kanji": "",
      "type": "v5u",
      "meaning": "to ridicule, to tease, to banter with, to make fun of"
    },
    {
      "id": 963,
      "kana": "ガラス",
      "kanji": "",
      "type": "n",
      "meaning": "glass, pane"
    },
    {
      "id": 964,
      "kana": "からだ",
      "kanji": "体",
      "type": "n",
      "meaning": "appearance, air, condition, state, form"
    },
    {
      "id": 965,
      "kana": "からっぽ",
      "kanji": "空っぽ",
      "type": "adj-na,n",
      "meaning": "empty, vacant, hollow"
    },
    {
      "id": 966,
      "kana": "かりる",
      "kanji": "借りる",
      "type": "v1",
      "meaning": "to borrow, to have a loan, to hire, to rent, to buy on credit"
    },
    {
      "id": 967,
      "kana": "かる",
      "kanji": "刈る",
      "type": "v5r",
      "meaning": "to cut (hair), to mow (grass), to harvest"
    },
    {
      "id": 968,
      "kana": "~がる",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 969,
      "kana": "かるい",
      "kanji": "軽い",
      "type": "adj",
      "meaning": "light, non-serious, minor"
    },
    {
      "id": 970,
      "kana": "かるた",
      "kanji": "",
      "type": "pt:",
      "meaning": "(n) playing cards (pt: carta)"
    },
    {
      "id": 971,
      "kana": "かれ",
      "kanji": "彼",
      "type": "n",
      "meaning": "he, boyfriend"
    },
    {
      "id": 972,
      "kana": "かれら",
      "kanji": "彼等",
      "type": "n",
      "meaning": "they (usually male)"
    },
    {
      "id": 973,
      "kana": "かれる",
      "kanji": "枯れる",
      "type": "v1",
      "meaning": "to wither, to die (plant), to be blasted (plant)"
    },
    {
      "id": 974,
      "kana": "カレンダー",
      "kanji": "",
      "type": "n",
      "meaning": "calendar"
    },
    {
      "id": 975,
      "kana": "カロリー",
      "kanji": "",
      "type": "n",
      "meaning": "calorie"
    },
    {
      "id": 976,
      "kana": "かわ",
      "kanji": "川",
      "type": "n",
      "meaning": "river"
    },
    {
      "id": 977,
      "kana": "かわ",
      "kanji": "河",
      "type": "n",
      "meaning": "river, stream"
    },
    {
      "id": 978,
      "kana": "かわ",
      "kanji": "皮",
      "type": "n",
      "meaning": "skin, hide, leather, fur, pelt, bark, shell"
    },
    {
      "id": 979,
      "kana": "かわ",
      "kanji": "革",
      "type": "n",
      "meaning": "leather"
    },
    {
      "id": 980,
      "kana": "~がわ",
      "kanji": "~側",
      "type": "",
      "meaning": ""
    },
    {
      "id": 981,
      "kana": "かわいい",
      "kanji": "",
      "type": "adj",
      "meaning": "(sl) pretty, cute, lovely"
    },
    {
      "id": 982,
      "kana": "かわいがる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to love, to be affectionate"
    },
    {
      "id": 983,
      "kana": "かわいそう",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "poor, pitiable, pathetic"
    },
    {
      "id": 984,
      "kana": "かわいらしい",
      "kanji": "",
      "type": "adj",
      "meaning": "lovely, sweet"
    },
    {
      "id": 985,
      "kana": "かわかす",
      "kanji": "乾かす",
      "type": "v5s,vt",
      "meaning": "to dry (clothes, etc.), to desiccate"
    },
    {
      "id": 986,
      "kana": "かわく",
      "kanji": "乾く",
      "type": "v5k,vi",
      "meaning": "to get dry"
    },
    {
      "id": 987,
      "kana": "かわく",
      "kanji": "渇く",
      "type": "v5k",
      "meaning": "to be thirsty"
    },
    {
      "id": 988,
      "kana": "かわせ",
      "kanji": "為替",
      "type": "n",
      "meaning": "money order, exchange"
    },
    {
      "id": 989,
      "kana": "かわら",
      "kanji": "瓦",
      "type": "n",
      "meaning": "roof tile"
    },
    {
      "id": 990,
      "kana": "かわり",
      "kanji": "代わり",
      "type": "n",
      "meaning": "substitute, deputy, proxy"
    },
    {
      "id": 991,
      "kana": "かわる",
      "kanji": "代る",
      "type": "io",
      "meaning": "(v5r,vi) to take the place of, to relieve, to be substituted for"
    },
    {
      "id": 992,
      "kana": "かわる",
      "kanji": "変わる",
      "type": "v5r,vi",
      "meaning": "to change, to be transformed, to vary"
    },
    {
      "id": 993,
      "kana": "かん",
      "kanji": "缶",
      "type": "n",
      "meaning": "can, tin"
    },
    {
      "id": 994,
      "kana": "かん",
      "kanji": "勘",
      "type": "n",
      "meaning": "perception, intuition, the sixth sense"
    },
    {
      "id": 995,
      "kana": "~かん",
      "kanji": "~刊",
      "type": "",
      "meaning": ""
    },
    {
      "id": 996,
      "kana": "~かん",
      "kanji": "~間",
      "type": "",
      "meaning": ""
    },
    {
      "id": 997,
      "kana": "~かん",
      "kanji": "~巻",
      "type": "",
      "meaning": ""
    },
    {
      "id": 998,
      "kana": "~かん",
      "kanji": "~館",
      "type": "",
      "meaning": ""
    },
    {
      "id": 999,
      "kana": "~かん",
      "kanji": "~感",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1000,
      "kana": "かんがえ",
      "kanji": "考え",
      "type": "n",
      "meaning": "thinking, thought, ideas, intention"
    },
    {
      "id": 1001,
      "kana": "かんがえる",
      "kanji": "考える",
      "type": "v1",
      "meaning": "to consider"
    },
    {
      "id": 1002,
      "kana": "かんかく",
      "kanji": "感覚",
      "type": "n",
      "meaning": "sense, sensation"
    },
    {
      "id": 1003,
      "kana": "かんかく",
      "kanji": "間隔",
      "type": "n",
      "meaning": "space, interval, SPC"
    },
    {
      "id": 1004,
      "kana": "かんき",
      "kanji": "換気",
      "type": "n",
      "meaning": "ventilation"
    },
    {
      "id": 1005,
      "kana": "かんきゃく",
      "kanji": "観客",
      "type": "n",
      "meaning": "audience, spectator(s)"
    },
    {
      "id": 1006,
      "kana": "かんきょう",
      "kanji": "環境",
      "type": "n",
      "meaning": "environment, circumstance"
    },
    {
      "id": 1007,
      "kana": "かんけい",
      "kanji": "関係",
      "type": "n,vs",
      "meaning": "relation, connection"
    },
    {
      "id": 1008,
      "kana": "かんげい",
      "kanji": "歓迎",
      "type": "n,vs",
      "meaning": "welcome, reception"
    },
    {
      "id": 1009,
      "kana": "かんげき",
      "kanji": "感激",
      "type": "n",
      "meaning": "deep emotion, impression, inspiration"
    },
    {
      "id": 1010,
      "kana": "かんこう",
      "kanji": "観光",
      "type": "n,vs",
      "meaning": "sightseeing"
    },
    {
      "id": 1011,
      "kana": "かんごふ",
      "kanji": "看護婦",
      "type": "n",
      "meaning": "(female) nurse"
    },
    {
      "id": 1012,
      "kana": "かんさい",
      "kanji": "関西",
      "type": "south-western half of Japan, including Osaka",
      "meaning": ""
    },
    {
      "id": 1013,
      "kana": "かんさつ",
      "kanji": "観察",
      "type": "n,vs",
      "meaning": "observation, survey"
    },
    {
      "id": 1014,
      "kana": "かんじ",
      "kanji": "感じ",
      "type": "n",
      "meaning": "feeling, sense, impression"
    },
    {
      "id": 1015,
      "kana": "かんじ",
      "kanji": "漢字",
      "type": "n",
      "meaning": "Chinese characters, kanji"
    },
    {
      "id": 1016,
      "kana": "がんじつ",
      "kanji": "元日",
      "type": "n",
      "meaning": "New Year's Day"
    },
    {
      "id": 1017,
      "kana": "かんしゃ",
      "kanji": "感謝",
      "type": "adj-na,n,vs",
      "meaning": "thanks, gratitude"
    },
    {
      "id": 1018,
      "kana": "かんじゃ",
      "kanji": "患者",
      "type": "n",
      "meaning": "a patient"
    },
    {
      "id": 1019,
      "kana": "かんしょう",
      "kanji": "鑑賞",
      "type": "n,vs",
      "meaning": "appreciation"
    },
    {
      "id": 1020,
      "kana": "かんじょう",
      "kanji": "勘定",
      "type": "n,vs",
      "meaning": "calculation, counting, consideration"
    },
    {
      "id": 1021,
      "kana": "かんじょう",
      "kanji": "感情",
      "type": "n",
      "meaning": "emotion(s), feeling(s), sentiment"
    },
    {
      "id": 1022,
      "kana": "かんじる",
      "kanji": "感じる",
      "type": "v1",
      "meaning": "to feel, to sense, to experience"
    },
    {
      "id": 1023,
      "kana": "かんずる",
      "kanji": "感ずる",
      "type": "v5z",
      "meaning": "to feel, to sense"
    },
    {
      "id": 1024,
      "kana": "かんしん",
      "kanji": "感心",
      "type": "adj-na,n,vs",
      "meaning": "admiration, Well done!"
    },
    {
      "id": 1025,
      "kana": "かんしん",
      "kanji": "関心",
      "type": "n",
      "meaning": "concern, interest"
    },
    {
      "id": 1026,
      "kana": "かんする",
      "kanji": "関する",
      "type": "vs-s",
      "meaning": "to concern, to be related"
    },
    {
      "id": 1027,
      "kana": "かんせい",
      "kanji": "完成",
      "type": "n,vs",
      "meaning": "(1) complete, completion, (2) perfection, accomplishment"
    },
    {
      "id": 1028,
      "kana": "かんせつ",
      "kanji": "間接",
      "type": "n",
      "meaning": "indirection, indirectness"
    },
    {
      "id": 1029,
      "kana": "かんぜん",
      "kanji": "完全",
      "type": "adj-na,n",
      "meaning": "perfection, completeness"
    },
    {
      "id": 1030,
      "kana": "かんそう",
      "kanji": "乾燥",
      "type": "n,vs",
      "meaning": "dry, arid, insipid, dehydrated"
    },
    {
      "id": 1031,
      "kana": "かんそう",
      "kanji": "感想",
      "type": "n",
      "meaning": "impressions, thoughts"
    },
    {
      "id": 1032,
      "kana": "かんそく",
      "kanji": "観測",
      "type": "n,vs",
      "meaning": "observation"
    },
    {
      "id": 1033,
      "kana": "かんたい",
      "kanji": "寒帯",
      "type": "n",
      "meaning": "frigid zone"
    },
    {
      "id": 1034,
      "kana": "かんたん",
      "kanji": "簡単",
      "type": "adj-na,n",
      "meaning": "simple"
    },
    {
      "id": 1035,
      "kana": "かんちょう",
      "kanji": "官庁",
      "type": "n",
      "meaning": "government office, authorities"
    },
    {
      "id": 1036,
      "kana": "かんちがい",
      "kanji": "勘違い",
      "type": "n,vs",
      "meaning": "misunderstanding, wrong guess"
    },
    {
      "id": 1037,
      "kana": "かんづめ",
      "kanji": "缶詰",
      "type": "n",
      "meaning": "packing (in cans), canning, canned goods, tin can"
    },
    {
      "id": 1038,
      "kana": "かんでんち",
      "kanji": "乾電池",
      "type": "n",
      "meaning": "dry cell, battery"
    },
    {
      "id": 1039,
      "kana": "かんとう",
      "kanji": "関東",
      "type": "n",
      "meaning": "eastern half of Japan, including Tokyo"
    },
    {
      "id": 1040,
      "kana": "かんどう",
      "kanji": "感動",
      "type": "n,vs",
      "meaning": "being deeply moved, excitement, impression, deep emotion"
    },
    {
      "id": 1041,
      "kana": "かんとく",
      "kanji": "監督",
      "type": "n,vs",
      "meaning": "supervision, control, superintendence"
    },
    {
      "id": 1042,
      "kana": "かんねん",
      "kanji": "観念",
      "type": "n,vs",
      "meaning": "(1) idea, notion, conception, (2) sense (e.g. of duty)"
    },
    {
      "id": 1043,
      "kana": "かんぱい",
      "kanji": "乾杯",
      "type": "n,vs",
      "meaning": "toast (drink)"
    },
    {
      "id": 1044,
      "kana": "がんばる",
      "kanji": "頑張る",
      "type": "v5r",
      "meaning": "to persist, to insist on, to stand firm, to try one's best"
    },
    {
      "id": 1045,
      "kana": "かんばん",
      "kanji": "看板",
      "type": "n",
      "meaning": "sign, signboard, doorplate, poster"
    },
    {
      "id": 1046,
      "kana": "かんびょう",
      "kanji": "看病",
      "type": "n",
      "meaning": "nursing (a patient)"
    },
    {
      "id": 1047,
      "kana": "かんむり",
      "kanji": "冠",
      "type": "n,vs",
      "meaning": "crown, diadem, first, best"
    },
    {
      "id": 1048,
      "kana": "かんり",
      "kanji": "管理",
      "type": "n,vs",
      "meaning": "control, management (e.g. of a business)"
    },
    {
      "id": 1049,
      "kana": "かんりょう",
      "kanji": "完了",
      "type": "n,vs",
      "meaning": "completion, conclusion"
    },
    {
      "id": 1050,
      "kana": "かんれん",
      "kanji": "関連",
      "type": "n",
      "meaning": "relation, connection, relevance"
    },
    {
      "id": 1051,
      "kana": "かんわ",
      "kanji": "漢和",
      "type": "n",
      "meaning": "Chinese Character-Japanese (e.g. dictionary)"
    },
    {
      "id": 1052,
      "kana": "き",
      "kanji": "木",
      "type": "n",
      "meaning": "tree, wood, timber"
    },
    {
      "id": 1053,
      "kana": "き",
      "kanji": "気",
      "type": "n",
      "meaning": "spirit, mood"
    },
    {
      "id": 1054,
      "kana": "~き",
      "kanji": "~期",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1055,
      "kana": "~き",
      "kanji": "~器",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1056,
      "kana": "~き",
      "kanji": "~機",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1057,
      "kana": "きあつ",
      "kanji": "気圧",
      "type": "n",
      "meaning": "atmospheric pressure"
    },
    {
      "id": 1058,
      "kana": "きいろ",
      "kanji": "黄色",
      "type": "adj-na,n",
      "meaning": "yellow"
    },
    {
      "id": 1059,
      "kana": "きいろい",
      "kanji": "黄色い",
      "type": "adj",
      "meaning": "yellow"
    },
    {
      "id": 1060,
      "kana": "ぎいん",
      "kanji": "議員",
      "type": "n",
      "meaning": "member of the Diet, congress or parliament"
    },
    {
      "id": 1061,
      "kana": "きえる",
      "kanji": "消える",
      "type": "v1",
      "meaning": "to go out, to vanish, to disappear"
    },
    {
      "id": 1062,
      "kana": "きおく",
      "kanji": "記憶",
      "type": "n,vs",
      "meaning": "memory, recollection, remembrance"
    },
    {
      "id": 1063,
      "kana": "きおん",
      "kanji": "気温",
      "type": "n",
      "meaning": "temperature"
    },
    {
      "id": 1064,
      "kana": "きかい",
      "kanji": "機会",
      "type": "n",
      "meaning": "chance, opportunity"
    },
    {
      "id": 1065,
      "kana": "きかい",
      "kanji": "機械",
      "type": "n",
      "meaning": "machine, mechanism"
    },
    {
      "id": 1066,
      "kana": "きかい",
      "kanji": "器械",
      "type": "n",
      "meaning": "instrument"
    },
    {
      "id": 1067,
      "kana": "ぎかい",
      "kanji": "議会",
      "type": "n",
      "meaning": "Diet, congress, parliament"
    },
    {
      "id": 1068,
      "kana": "きがえ",
      "kanji": "着替え",
      "type": "n",
      "meaning": "changing clothes, change of clothes"
    },
    {
      "id": 1069,
      "kana": "きがえる",
      "kanji": "着替える",
      "type": "v1",
      "meaning": "to change one's clothes"
    },
    {
      "id": 1070,
      "kana": "きかん",
      "kanji": "期間",
      "type": "n",
      "meaning": "period, term"
    },
    {
      "id": 1071,
      "kana": "きかん",
      "kanji": "機関",
      "type": "n",
      "meaning": "organ, mechanism, facility, engine"
    },
    {
      "id": 1072,
      "kana": "きかんしゃ",
      "kanji": "機関車",
      "type": "n",
      "meaning": "locomotive, engine"
    },
    {
      "id": 1073,
      "kana": "きぎょう",
      "kanji": "企業",
      "type": "n",
      "meaning": "enterprise, undertaking"
    },
    {
      "id": 1074,
      "kana": "ききん",
      "kanji": "飢饉",
      "type": "n",
      "meaning": "famine"
    },
    {
      "id": 1075,
      "kana": "きく",
      "kanji": "聞く",
      "type": "v5k",
      "meaning": "to hear, to listen, to ask"
    },
    {
      "id": 1076,
      "kana": "きく",
      "kanji": "効く",
      "type": "v5k",
      "meaning": "to be effective"
    },
    {
      "id": 1077,
      "kana": "きぐ",
      "kanji": "器具",
      "type": "n",
      "meaning": "utensil"
    },
    {
      "id": 1078,
      "kana": "きけん",
      "kanji": "危険",
      "type": "adj-na,n",
      "meaning": "danger, peril, hazard"
    },
    {
      "id": 1079,
      "kana": "きげん",
      "kanji": "期限",
      "type": "n",
      "meaning": "term, period"
    },
    {
      "id": 1080,
      "kana": "きげん",
      "kanji": "機嫌",
      "type": "n",
      "meaning": "humour, temper, mood"
    },
    {
      "id": 1081,
      "kana": "きこう",
      "kanji": "気候",
      "type": "n",
      "meaning": "climate"
    },
    {
      "id": 1082,
      "kana": "きごう",
      "kanji": "記号",
      "type": "n",
      "meaning": "symbol, code"
    },
    {
      "id": 1083,
      "kana": "きこえる",
      "kanji": "聞こえる",
      "type": "v1",
      "meaning": "to be heard, to be audible"
    },
    {
      "id": 1084,
      "kana": "きざむ",
      "kanji": "刻む",
      "type": "v5m",
      "meaning": "to mince, to carve, to engrave"
    },
    {
      "id": 1085,
      "kana": "きし",
      "kanji": "岸",
      "type": "n",
      "meaning": "bank, coast, shore"
    },
    {
      "id": 1086,
      "kana": "きじ",
      "kanji": "生地",
      "type": "n",
      "meaning": "cloth, material"
    },
    {
      "id": 1087,
      "kana": "きじ",
      "kanji": "記事",
      "type": "n",
      "meaning": "article, news story, report"
    },
    {
      "id": 1088,
      "kana": "ぎし",
      "kanji": "技師",
      "type": "n",
      "meaning": "engineer, technician"
    },
    {
      "id": 1089,
      "kana": "ぎしき",
      "kanji": "儀式",
      "type": "n",
      "meaning": "ceremony, rite, ritual, service"
    },
    {
      "id": 1090,
      "kana": "きしゃ",
      "kanji": "汽車",
      "type": "n",
      "meaning": "train (steam)"
    },
    {
      "id": 1091,
      "kana": "きしゃ",
      "kanji": "記者",
      "type": "n",
      "meaning": "reporter"
    },
    {
      "id": 1092,
      "kana": "ぎじゅつ",
      "kanji": "技術",
      "type": "n",
      "meaning": "art, technique, technology, skill"
    },
    {
      "id": 1093,
      "kana": "きじゅん",
      "kanji": "基準",
      "type": "n",
      "meaning": "standard, basis, criteria, norm"
    },
    {
      "id": 1094,
      "kana": "きじゅん",
      "kanji": "規準",
      "type": "n",
      "meaning": "standard, basis, criteria, norm"
    },
    {
      "id": 1095,
      "kana": "きしょう",
      "kanji": "起床",
      "type": "n",
      "meaning": "rising, getting out of bed"
    },
    {
      "id": 1096,
      "kana": "きず",
      "kanji": "傷",
      "type": "n",
      "meaning": "wound, injury, hurt, cut"
    },
    {
      "id": 1097,
      "kana": "きせつ",
      "kanji": "季節",
      "type": "n",
      "meaning": "season"
    },
    {
      "id": 1098,
      "kana": "きせる",
      "kanji": "着せる",
      "type": "v1",
      "meaning": "to put on clothes"
    },
    {
      "id": 1099,
      "kana": "きそ",
      "kanji": "基礎",
      "type": "n",
      "meaning": "foundation, basis"
    },
    {
      "id": 1100,
      "kana": "きそく",
      "kanji": "規則",
      "type": "n",
      "meaning": "rule, regulations"
    },
    {
      "id": 1101,
      "kana": "きた",
      "kanji": "北",
      "type": "",
      "meaning": "north"
    },
    {
      "id": 1102,
      "kana": "ギター",
      "kanji": "",
      "type": "n",
      "meaning": "guitar"
    },
    {
      "id": 1103,
      "kana": "きたい",
      "kanji": "期待",
      "type": "n,vs",
      "meaning": "expectation, anticipation, hope"
    },
    {
      "id": 1104,
      "kana": "きたい",
      "kanji": "気体",
      "type": "n",
      "meaning": "vapour, gas"
    },
    {
      "id": 1105,
      "kana": "きたく",
      "kanji": "帰宅",
      "type": "n,vs",
      "meaning": "returning home"
    },
    {
      "id": 1106,
      "kana": "きたない",
      "kanji": "汚い",
      "type": "adj",
      "meaning": "dirty, unclean, filthy"
    },
    {
      "id": 1107,
      "kana": "きち",
      "kanji": "基地",
      "type": "n",
      "meaning": "base"
    },
    {
      "id": 1108,
      "kana": "きちょう",
      "kanji": "貴重",
      "type": "adj-na,n",
      "meaning": "precious, valuable"
    },
    {
      "id": 1109,
      "kana": "ぎちょう",
      "kanji": "議長",
      "type": "n",
      "meaning": "chairman"
    },
    {
      "id": 1110,
      "kana": "きちんと",
      "kanji": "",
      "type": "adv",
      "meaning": "precisely, accurately"
    },
    {
      "id": 1111,
      "kana": "きつい",
      "kanji": "",
      "type": "adj",
      "meaning": "tight, close, intense"
    },
    {
      "id": 1112,
      "kana": "きっかけ",
      "kanji": "",
      "type": "n",
      "meaning": "chance, start, cue, excuse"
    },
    {
      "id": 1113,
      "kana": "きづく",
      "kanji": "気付く",
      "type": "v5k",
      "meaning": "to notice, to recognize, to become aware of"
    },
    {
      "id": 1114,
      "kana": "きっと",
      "kanji": "",
      "type": "adv,n",
      "meaning": "(1) surely, undoubtedly, certainly"
    },
    {
      "id": 1115,
      "kana": "ぎっしり",
      "kanji": "",
      "type": "adv",
      "meaning": "tightly, fully"
    },
    {
      "id": 1116,
      "kana": "きって",
      "kanji": "切手",
      "type": "",
      "meaning": "stamp"
    },
    {
      "id": 1117,
      "kana": "きっと",
      "kanji": "",
      "type": "adv,n",
      "meaning": "(1) surely, undoubtedly, without fail, (2) sternly, severely"
    },
    {
      "id": 1118,
      "kana": "きっぷ",
      "kanji": "切符",
      "type": "n",
      "meaning": "ticket"
    },
    {
      "id": 1119,
      "kana": "きにいる",
      "kanji": "気に入る",
      "type": "v5r",
      "meaning": "to be pleased with, to suit"
    },
    {
      "id": 1120,
      "kana": "きにゅう",
      "kanji": "記入",
      "type": "n,vs",
      "meaning": "entry, filling in of forms"
    },
    {
      "id": 1121,
      "kana": "きぬ",
      "kanji": "絹",
      "type": "n",
      "meaning": "silk"
    },
    {
      "id": 1122,
      "kana": "きねん",
      "kanji": "記念",
      "type": "n",
      "meaning": "commemoration, memory"
    },
    {
      "id": 1123,
      "kana": "きのう",
      "kanji": "昨日",
      "type": "n-adv,n-t",
      "meaning": "yesterday"
    },
    {
      "id": 1124,
      "kana": "きのう",
      "kanji": "機能",
      "type": "n",
      "meaning": "function, faculty"
    },
    {
      "id": 1125,
      "kana": "きのどく",
      "kanji": "気の毒",
      "type": "adj-na,n",
      "meaning": "pitiful, a pity"
    },
    {
      "id": 1126,
      "kana": "きばん",
      "kanji": "基盤",
      "type": "n",
      "meaning": "foundation, basis"
    },
    {
      "id": 1127,
      "kana": "きびしい",
      "kanji": "厳しい",
      "type": "adj",
      "meaning": "severe, strict"
    },
    {
      "id": 1128,
      "kana": "きふ",
      "kanji": "寄付",
      "type": "n,vs",
      "meaning": "contribution, donation"
    },
    {
      "id": 1129,
      "kana": "きぶん",
      "kanji": "気分",
      "type": "n",
      "meaning": "feeling, mood"
    },
    {
      "id": 1130,
      "kana": "きぼう",
      "kanji": "希望",
      "type": "n,vs",
      "meaning": "hope, wish, aspiration"
    },
    {
      "id": 1131,
      "kana": "きほん",
      "kanji": "基本",
      "type": "n",
      "meaning": "foundation, basis, standard"
    },
    {
      "id": 1132,
      "kana": "きまり",
      "kanji": "決まり",
      "type": "n",
      "meaning": "settlement, conclusion, regulation, rule"
    },
    {
      "id": 1133,
      "kana": "きまる",
      "kanji": "決る",
      "type": "io",
      "meaning": "(v5r) to be decided, to be settled, to look good in (clothes)"
    },
    {
      "id": 1134,
      "kana": "きみ",
      "kanji": "君",
      "type": "n,suf",
      "meaning": "Mr (junior), master, boy"
    },
    {
      "id": 1135,
      "kana": "きみ",
      "kanji": "気味",
      "type": "n-suf",
      "meaning": "-like, -looking, -looked"
    },
    {
      "id": 1136,
      "kana": "~ぎみ",
      "kanji": "~気味",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1137,
      "kana": "きみょう",
      "kanji": "奇妙",
      "type": "adj-na,n",
      "meaning": "strange, queer, curious"
    },
    {
      "id": 1138,
      "kana": "ぎむ",
      "kanji": "義務",
      "type": "n",
      "meaning": "duty, obligation, responsibility"
    },
    {
      "id": 1139,
      "kana": "きめる",
      "kanji": "決める",
      "type": "v1",
      "meaning": "to decide"
    },
    {
      "id": 1140,
      "kana": "きもち",
      "kanji": "気持ち",
      "type": "n",
      "meaning": "feeling, sensation, mood"
    },
    {
      "id": 1141,
      "kana": "きもの",
      "kanji": "着物",
      "type": "n",
      "meaning": "kimono"
    },
    {
      "id": 1142,
      "kana": "ぎもん",
      "kanji": "疑問",
      "type": "n",
      "meaning": "question, problem, doubt, guess"
    },
    {
      "id": 1143,
      "kana": "きゃく",
      "kanji": "客",
      "type": "n",
      "meaning": "guest, customer"
    },
    {
      "id": 1144,
      "kana": "ぎゃく",
      "kanji": "逆",
      "type": "adj-na,n",
      "meaning": "reverse, opposite"
    },
    {
      "id": 1145,
      "kana": "きゃくせき",
      "kanji": "客席",
      "type": "n",
      "meaning": "guest seating"
    },
    {
      "id": 1146,
      "kana": "きゃくま",
      "kanji": "客間",
      "type": "n",
      "meaning": "parlor, guest room"
    },
    {
      "id": 1147,
      "kana": "キャプテン",
      "kanji": "",
      "type": "n",
      "meaning": "captain"
    },
    {
      "id": 1148,
      "kana": "ギャング",
      "kanji": "",
      "type": "n",
      "meaning": "gang"
    },
    {
      "id": 1149,
      "kana": "キャンパス",
      "kanji": "",
      "type": "n",
      "meaning": "campus"
    },
    {
      "id": 1150,
      "kana": "キャンプ",
      "kanji": "",
      "type": "n",
      "meaning": "camp"
    },
    {
      "id": 1151,
      "kana": "きゅう",
      "kanji": "九",
      "type": "num",
      "meaning": "nine"
    },
    {
      "id": 1152,
      "kana": "きゅう",
      "kanji": "旧",
      "type": "n,pref",
      "meaning": "ex-"
    },
    {
      "id": 1153,
      "kana": "きゅう",
      "kanji": "級",
      "type": "n,n-suf",
      "meaning": "class, grade, rank, school class, grade"
    },
    {
      "id": 1154,
      "kana": "きゅう",
      "kanji": "球",
      "type": "n",
      "meaning": "globe, sphere, ball"
    },
    {
      "id": 1155,
      "kana": "きゅうか",
      "kanji": "休暇",
      "type": "n",
      "meaning": "holiday, day off, furlough"
    },
    {
      "id": 1156,
      "kana": "きゅうぎょう",
      "kanji": "休業",
      "type": "n",
      "meaning": "closed (e.g. store), business suspended, shutdown, holiday"
    },
    {
      "id": 1157,
      "kana": "きゅうけい",
      "kanji": "休憩",
      "type": "n,vs",
      "meaning": "rest, break, recess, intermission"
    },
    {
      "id": 1158,
      "kana": "きゅうげき",
      "kanji": "急激",
      "type": "adj-na,n",
      "meaning": "sudden, precipitous, radical"
    },
    {
      "id": 1159,
      "kana": "きゅうこう",
      "kanji": "急行",
      "type": "n",
      "meaning": "express (e.g. train that bypasses many stations)"
    },
    {
      "id": 1160,
      "kana": "きゅうこう",
      "kanji": "休講",
      "type": "n",
      "meaning": "lecture cancelled"
    },
    {
      "id": 1161,
      "kana": "きゅうしゅう",
      "kanji": "吸収",
      "type": "n,vs",
      "meaning": "absorption, suction, attraction"
    },
    {
      "id": 1162,
      "kana": "きゅうじょ",
      "kanji": "救助",
      "type": "n,vs",
      "meaning": "relief, aid, rescue"
    },
    {
      "id": 1163,
      "kana": "きゅうそく",
      "kanji": "急速",
      "type": "adj-na,n",
      "meaning": "rapid (e.g. progress)"
    },
    {
      "id": 1164,
      "kana": "きゅうそく",
      "kanji": "休息",
      "type": "n",
      "meaning": "rest, relief, relaxation"
    },
    {
      "id": 1165,
      "kana": "きゅうに",
      "kanji": "急に",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1166,
      "kana": "ぎゅうにゅう",
      "kanji": "牛乳",
      "type": "n",
      "meaning": "(cow's) milk"
    },
    {
      "id": 1167,
      "kana": "きゅうよ",
      "kanji": "給与",
      "type": "n,vs",
      "meaning": "allowance, grant, supply"
    },
    {
      "id": 1168,
      "kana": "きゅうよう",
      "kanji": "休養",
      "type": "n",
      "meaning": "rest, break, recreation"
    },
    {
      "id": 1169,
      "kana": "きゅうりょう",
      "kanji": "給料",
      "type": "n",
      "meaning": "salary, wages"
    },
    {
      "id": 1170,
      "kana": "きよい",
      "kanji": "清い",
      "type": "adj",
      "meaning": "clear, pure, noble"
    },
    {
      "id": 1171,
      "kana": "きよう",
      "kanji": "器用",
      "type": "adj-na,n",
      "meaning": "skillful, handy"
    },
    {
      "id": 1172,
      "kana": "きょう",
      "kanji": "今日",
      "type": "n-t",
      "meaning": "today, this day"
    },
    {
      "id": 1173,
      "kana": "~きょう",
      "kanji": "~教",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1174,
      "kana": "~ぎょう",
      "kanji": "~行",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1175,
      "kana": "~ぎょう",
      "kanji": "~業",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1176,
      "kana": "きょうか",
      "kanji": "強化",
      "type": "n,vs",
      "meaning": "strengthen, intensify, reinforce, solidify"
    },
    {
      "id": 1177,
      "kana": "きょうかい",
      "kanji": "教会",
      "type": "n",
      "meaning": "church"
    },
    {
      "id": 1178,
      "kana": "きょうかい",
      "kanji": "境界",
      "type": "n",
      "meaning": "boundary"
    },
    {
      "id": 1179,
      "kana": "きょうかしょ",
      "kanji": "教科書",
      "type": "n",
      "meaning": "text book"
    },
    {
      "id": 1180,
      "kana": "きょうぎ",
      "kanji": "競技",
      "type": "n",
      "meaning": "game, match, contest"
    },
    {
      "id": 1181,
      "kana": "ぎょうぎ",
      "kanji": "行儀",
      "type": "n",
      "meaning": "manners"
    },
    {
      "id": 1182,
      "kana": "きょうきゅう",
      "kanji": "供給",
      "type": "n,vs",
      "meaning": "supply, provision"
    },
    {
      "id": 1183,
      "kana": "きょうさん~",
      "kanji": "共産~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1184,
      "kana": "きょうし",
      "kanji": "教師",
      "type": "n",
      "meaning": "teacher (classroom)"
    },
    {
      "id": 1185,
      "kana": "ぎょうじ",
      "kanji": "行事",
      "type": "n",
      "meaning": "event, function"
    },
    {
      "id": 1186,
      "kana": "きょうしつ",
      "kanji": "教室",
      "type": "n",
      "meaning": "classroom"
    },
    {
      "id": 1187,
      "kana": "きょうじゅ",
      "kanji": "教授",
      "type": "n",
      "meaning": "teaching, instruction, professor"
    },
    {
      "id": 1188,
      "kana": "きょうしゅく",
      "kanji": "恐縮",
      "type": "exp,n",
      "meaning": "shame, very kind of you, sorry to trouble"
    },
    {
      "id": 1189,
      "kana": "きょうそう",
      "kanji": "競争",
      "type": "n,vs",
      "meaning": "competition, contest"
    },
    {
      "id": 1190,
      "kana": "きょうだい",
      "kanji": "兄弟",
      "type": "n",
      "meaning": "(hum) siblings"
    },
    {
      "id": 1191,
      "kana": "きょうちょう",
      "kanji": "強調",
      "type": "n,vs",
      "meaning": "emphasis, stress, stressed point"
    },
    {
      "id": 1192,
      "kana": "きょうつう",
      "kanji": "共通",
      "type": "adj-na,adj-no,n,vs",
      "meaning": "commonness, community"
    },
    {
      "id": 1193,
      "kana": "きょうどう",
      "kanji": "共同",
      "type": "n",
      "meaning": "cooperation, association, collaboration, joint"
    },
    {
      "id": 1194,
      "kana": "きょうふ",
      "kanji": "恐怖",
      "type": "n,vs",
      "meaning": "be afraid, dread, dismay, terror"
    },
    {
      "id": 1195,
      "kana": "きょうみ",
      "kanji": "興味",
      "type": "n",
      "meaning": "interest (in something)"
    },
    {
      "id": 1196,
      "kana": "きょうよう",
      "kanji": "教養",
      "type": "n",
      "meaning": "culture, education, refinement, cultivation"
    },
    {
      "id": 1197,
      "kana": "きょうりょく",
      "kanji": "協力",
      "type": "n,vs",
      "meaning": "cooperation, collaboration"
    },
    {
      "id": 1198,
      "kana": "きょうりょく",
      "kanji": "強力",
      "type": "adj-na,n",
      "meaning": "herculean strength, mountain carrier-guide"
    },
    {
      "id": 1199,
      "kana": "ぎょうれつ",
      "kanji": "行列",
      "type": "n,vs",
      "meaning": "(1) line, procession, (2) matrix (math)"
    },
    {
      "id": 1200,
      "kana": "きょか",
      "kanji": "許可",
      "type": "n,vs",
      "meaning": "permission, approval"
    },
    {
      "id": 1201,
      "kana": "ぎょぎょう",
      "kanji": "漁業",
      "type": "n",
      "meaning": "fishing (industry)"
    },
    {
      "id": 1202,
      "kana": "きょく",
      "kanji": "局",
      "type": "n",
      "meaning": "court lady, lady in waiting"
    },
    {
      "id": 1203,
      "kana": "きょくせん",
      "kanji": "曲線",
      "type": "n",
      "meaning": "curve"
    },
    {
      "id": 1204,
      "kana": "きょだい",
      "kanji": "巨大",
      "type": "adj-na,n",
      "meaning": "huge, gigantic, enormous"
    },
    {
      "id": 1205,
      "kana": "きょねん",
      "kanji": "去年",
      "type": "n-adv,n-t",
      "meaning": "last year"
    },
    {
      "id": 1206,
      "kana": "きらい",
      "kanji": "嫌い",
      "type": "adj-na,n",
      "meaning": "dislike, hate"
    },
    {
      "id": 1207,
      "kana": "きらう",
      "kanji": "嫌う",
      "type": "v5u",
      "meaning": "to hate, to dislike, to loathe"
    },
    {
      "id": 1208,
      "kana": "きり",
      "kanji": "霧",
      "type": "n",
      "meaning": "fog, mist"
    },
    {
      "id": 1209,
      "kana": "きりつ",
      "kanji": "規律",
      "type": "n",
      "meaning": "order, rules, law"
    },
    {
      "id": 1210,
      "kana": "きる",
      "kanji": "切る",
      "type": "suf,v5r",
      "meaning": "to cut, to chop, to slice"
    },
    {
      "id": 1211,
      "kana": "きる",
      "kanji": "斬る",
      "type": "oK",
      "meaning": "(v5r) to behead, to murder"
    },
    {
      "id": 1212,
      "kana": "~きる",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1213,
      "kana": "きれ",
      "kanji": "切れ",
      "type": "n",
      "meaning": "cloth, piece, cut, chop"
    },
    {
      "id": 1214,
      "kana": "~きれ",
      "kanji": "~切れ",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1215,
      "kana": "きれい",
      "kanji": "",
      "type": "adj-na",
      "meaning": "pretty, clean, nice, tidy"
    },
    {
      "id": 1216,
      "kana": "きれる",
      "kanji": "切れる",
      "type": "v1",
      "meaning": "(1) to cut well, to be sharp, (2) to break (off)"
    },
    {
      "id": 1217,
      "kana": "キロ",
      "kanji": "",
      "type": "n,pref",
      "meaning": "(abbr) kilo-, kilogram, kilometre, 10^3"
    },
    {
      "id": 1218,
      "kana": "きろく",
      "kanji": "記録",
      "type": "n,vs",
      "meaning": "record, minutes, document"
    },
    {
      "id": 1219,
      "kana": "ぎろん",
      "kanji": "議論",
      "type": "n,vs",
      "meaning": "argument, discussion, dispute"
    },
    {
      "id": 1220,
      "kana": "きをつける",
      "kanji": "気を付ける",
      "type": "exp",
      "meaning": "to be careful, to pay attention, to take care"
    },
    {
      "id": 1221,
      "kana": "きん",
      "kanji": "金",
      "type": "n,n-suf",
      "meaning": "(1) gold, (2) gold general (shogi) (abbr)"
    },
    {
      "id": 1222,
      "kana": "ぎん",
      "kanji": "銀",
      "type": "n",
      "meaning": "(1) silver, silver coin, silver paint"
    },
    {
      "id": 1223,
      "kana": "きんえん",
      "kanji": "禁煙",
      "type": "exp",
      "meaning": "No Smoking!"
    },
    {
      "id": 1224,
      "kana": "きんがく",
      "kanji": "金額",
      "type": "n",
      "meaning": "amount of money"
    },
    {
      "id": 1225,
      "kana": "きんぎょ",
      "kanji": "金魚",
      "type": "n",
      "meaning": "goldfish"
    },
    {
      "id": 1226,
      "kana": "きんこ",
      "kanji": "金庫",
      "type": "n",
      "meaning": "safe, vault, treasury, provider of funds"
    },
    {
      "id": 1227,
      "kana": "ぎんこう",
      "kanji": "銀行",
      "type": "n",
      "meaning": "bank"
    },
    {
      "id": 1228,
      "kana": "きんし",
      "kanji": "禁止",
      "type": "n,vs",
      "meaning": "prohibition, ban"
    },
    {
      "id": 1229,
      "kana": "きんじょ",
      "kanji": "近所",
      "type": "n",
      "meaning": "neighbourhood"
    },
    {
      "id": 1230,
      "kana": "きんせん",
      "kanji": "金銭",
      "type": "n",
      "meaning": "money, cash"
    },
    {
      "id": 1231,
      "kana": "きんぞく",
      "kanji": "金属",
      "type": "n",
      "meaning": "metal"
    },
    {
      "id": 1232,
      "kana": "きんだい",
      "kanji": "近代",
      "type": "n",
      "meaning": "present day"
    },
    {
      "id": 1233,
      "kana": "きんちょう",
      "kanji": "緊張",
      "type": "n,vs",
      "meaning": "tension, mental strain, nervousness"
    },
    {
      "id": 1234,
      "kana": "きんにく",
      "kanji": "筋肉",
      "type": "n",
      "meaning": "muscle, sinew"
    },
    {
      "id": 1235,
      "kana": "きんゆう",
      "kanji": "金融",
      "type": "n",
      "meaning": "monetary circulation, credit situation"
    },
    {
      "id": 1236,
      "kana": "きんよう",
      "kanji": "金曜",
      "type": "n-adv,n-t",
      "meaning": "(abbr) Friday"
    },
    {
      "id": 1237,
      "kana": "く",
      "kanji": "九",
      "type": "num",
      "meaning": "nine"
    },
    {
      "id": 1238,
      "kana": "く",
      "kanji": "句",
      "type": "",
      "meaning": "sentence"
    },
    {
      "id": 1239,
      "kana": "ぐあい",
      "kanji": "具合",
      "type": "n",
      "meaning": "condition, state, manner, health"
    },
    {
      "id": 1240,
      "kana": "くいき",
      "kanji": "区域",
      "type": "n",
      "meaning": "limits, boundary, domain, zone, sphere, territory"
    },
    {
      "id": 1241,
      "kana": "くう",
      "kanji": "食う",
      "type": "v5u",
      "meaning": "(male) (vulg) to eat"
    },
    {
      "id": 1242,
      "kana": "くう~",
      "kanji": "空~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1243,
      "kana": "くうき",
      "kanji": "空気",
      "type": "n",
      "meaning": "air, atmosphere"
    },
    {
      "id": 1244,
      "kana": "くうこう",
      "kanji": "空港",
      "type": "n",
      "meaning": "airport"
    },
    {
      "id": 1245,
      "kana": "ぐうすう",
      "kanji": "偶数",
      "type": "n",
      "meaning": "even number"
    },
    {
      "id": 1246,
      "kana": "ぐうぜん",
      "kanji": "偶然",
      "type": "adj-na,adj-no,n-adv,n",
      "meaning": "(by) chance, unexpectedly, suddenly"
    },
    {
      "id": 1247,
      "kana": "くうそう",
      "kanji": "空想",
      "type": "n",
      "meaning": "daydream, fantasy, fancy, vision"
    },
    {
      "id": 1248,
      "kana": "くうちゅう",
      "kanji": "空中",
      "type": "n",
      "meaning": "sky, air"
    },
    {
      "id": 1249,
      "kana": "クーラー",
      "kanji": "",
      "type": "n",
      "meaning": "cooler, air conditioner"
    },
    {
      "id": 1250,
      "kana": "くぎ",
      "kanji": "釘",
      "type": "n",
      "meaning": "nail"
    },
    {
      "id": 1251,
      "kana": "くぎる",
      "kanji": "区切る",
      "type": "v5r,vt",
      "meaning": "to punctuate, to cut off, to mark off, to stop, to put an end to"
    },
    {
      "id": 1252,
      "kana": "くさ",
      "kanji": "草",
      "type": "n",
      "meaning": "grass"
    },
    {
      "id": 1253,
      "kana": "くさい",
      "kanji": "臭い",
      "type": "n",
      "meaning": "odour, scent, smell, stench"
    },
    {
      "id": 1254,
      "kana": "くさり",
      "kanji": "鎖",
      "type": "n",
      "meaning": "chain"
    },
    {
      "id": 1255,
      "kana": "くさる",
      "kanji": "腐る",
      "type": "v5r",
      "meaning": "to rot, to go bad"
    },
    {
      "id": 1256,
      "kana": "くし",
      "kanji": "櫛",
      "type": "n",
      "meaning": "comb"
    },
    {
      "id": 1257,
      "kana": "くしゃみ",
      "kanji": "",
      "type": "n",
      "meaning": "sneeze"
    },
    {
      "id": 1258,
      "kana": "くじょう",
      "kanji": "苦情",
      "type": "n",
      "meaning": "complaint, troubles, objection"
    },
    {
      "id": 1259,
      "kana": "くしん",
      "kanji": "苦心",
      "type": "n,vs",
      "meaning": "pain, trouble"
    },
    {
      "id": 1260,
      "kana": "くず",
      "kanji": "屑",
      "type": "n",
      "meaning": "waste, scrap"
    },
    {
      "id": 1261,
      "kana": "くずす",
      "kanji": "崩す",
      "type": "v5s",
      "meaning": "to destroy, to pull down, to make change (money)"
    },
    {
      "id": 1262,
      "kana": "くすり",
      "kanji": "薬",
      "type": "n",
      "meaning": "medicine"
    },
    {
      "id": 1263,
      "kana": "くすりゆび",
      "kanji": "薬指",
      "type": "n",
      "meaning": "ring finger"
    },
    {
      "id": 1264,
      "kana": "くずれる",
      "kanji": "崩れる",
      "type": "v1",
      "meaning": "to collapse, to crumble"
    },
    {
      "id": 1265,
      "kana": "くせ",
      "kanji": "癖",
      "type": "n",
      "meaning": "a habit (often a bad habit), peculiarity"
    },
    {
      "id": 1266,
      "kana": "くだ",
      "kanji": "管",
      "type": "n,n-suf",
      "meaning": "pipe, tube"
    },
    {
      "id": 1267,
      "kana": "ぐたい",
      "kanji": "具体",
      "type": "n",
      "meaning": "concrete, tangible, material"
    },
    {
      "id": 1268,
      "kana": "くだく",
      "kanji": "砕く",
      "type": "v5k,vt",
      "meaning": "to break, to smash"
    },
    {
      "id": 1269,
      "kana": "くだける",
      "kanji": "砕ける",
      "type": "v1,vi",
      "meaning": "to break, to be broken"
    },
    {
      "id": 1270,
      "kana": "くださる",
      "kanji": "下さる",
      "type": "v5aru",
      "meaning": "(hon) to give, to confer"
    },
    {
      "id": 1271,
      "kana": "くたびれる",
      "kanji": "",
      "type": "v1",
      "meaning": "to get tired, to wear out"
    },
    {
      "id": 1272,
      "kana": "くだもの",
      "kanji": "果物",
      "type": "n",
      "meaning": "fruit"
    },
    {
      "id": 1273,
      "kana": "くだらない",
      "kanji": "",
      "type": "adj",
      "meaning": "good-for-nothing, stupid, trivial, worthless"
    },
    {
      "id": 1274,
      "kana": "くだり",
      "kanji": "下り",
      "type": "n,n-suf",
      "meaning": "down-train (going away from Tokyo)"
    },
    {
      "id": 1275,
      "kana": "くだる",
      "kanji": "下る",
      "type": "v5r",
      "meaning": "to get down, to descend"
    },
    {
      "id": 1276,
      "kana": "くち",
      "kanji": "口",
      "type": "n",
      "meaning": "mouth, orifice, opening"
    },
    {
      "id": 1277,
      "kana": "~くち",
      "kanji": "~口",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1278,
      "kana": "くちびる",
      "kanji": "唇",
      "type": "n",
      "meaning": "lips"
    },
    {
      "id": 1279,
      "kana": "くちべに",
      "kanji": "口紅",
      "type": "n",
      "meaning": "lipstick"
    },
    {
      "id": 1280,
      "kana": "くつ",
      "kanji": "靴",
      "type": "n",
      "meaning": "shoes, footwear"
    },
    {
      "id": 1281,
      "kana": "くつう",
      "kanji": "苦痛",
      "type": "adj-na,n",
      "meaning": "pain, agony"
    },
    {
      "id": 1282,
      "kana": "くつした",
      "kanji": "靴下",
      "type": "n",
      "meaning": "socks"
    },
    {
      "id": 1283,
      "kana": "くっつく",
      "kanji": "",
      "type": "v5k",
      "meaning": "to adhere to, to keep close to"
    },
    {
      "id": 1284,
      "kana": "くっつける",
      "kanji": "",
      "type": "v1",
      "meaning": "to attach"
    },
    {
      "id": 1285,
      "kana": "ぐっすり",
      "kanji": "",
      "type": "adv",
      "meaning": "sound asleep, fast asleep"
    },
    {
      "id": 1286,
      "kana": "くどい",
      "kanji": "",
      "type": "adj",
      "meaning": "verbose, importunate, heavy (taste)"
    },
    {
      "id": 1287,
      "kana": "くとうてん",
      "kanji": "句読点",
      "type": "n",
      "meaning": "punctuation marks"
    },
    {
      "id": 1288,
      "kana": "くに",
      "kanji": "国",
      "type": "n",
      "meaning": "country"
    },
    {
      "id": 1289,
      "kana": "くばる",
      "kanji": "配る",
      "type": "v5r",
      "meaning": "to distribute, to deliver"
    },
    {
      "id": 1290,
      "kana": "くび",
      "kanji": "首",
      "type": "n",
      "meaning": "neck"
    },
    {
      "id": 1291,
      "kana": "くふう",
      "kanji": "工夫",
      "type": "n",
      "meaning": "device, scheme"
    },
    {
      "id": 1292,
      "kana": "くぶん",
      "kanji": "区分",
      "type": "n",
      "meaning": "division, section, classification"
    },
    {
      "id": 1293,
      "kana": "くべつ",
      "kanji": "区別",
      "type": "n,vs",
      "meaning": "distinction, differentiation, classification"
    },
    {
      "id": 1294,
      "kana": "くみ",
      "kanji": "組",
      "type": "n",
      "meaning": "class, group, team, set"
    },
    {
      "id": 1295,
      "kana": "くみあい",
      "kanji": "組合",
      "type": "n",
      "meaning": "association, union"
    },
    {
      "id": 1296,
      "kana": "くみあわせ",
      "kanji": "組合せ",
      "type": "n",
      "meaning": "combination"
    },
    {
      "id": 1297,
      "kana": "くみたてる",
      "kanji": "組み立てる",
      "type": "v1",
      "meaning": "to assemble, to set up, to construct"
    },
    {
      "id": 1298,
      "kana": "くむ",
      "kanji": "組む",
      "type": "v5m",
      "meaning": "to put together"
    },
    {
      "id": 1299,
      "kana": "くむ",
      "kanji": "汲む",
      "type": "v5m",
      "meaning": "(1) to draw (water), to dip, to scoop, to pump"
    },
    {
      "id": 1300,
      "kana": "くむ",
      "kanji": "酌む",
      "type": "v5m",
      "meaning": "to serve sake"
    },
    {
      "id": 1301,
      "kana": "くも",
      "kanji": "雲",
      "type": "n",
      "meaning": "cloud"
    },
    {
      "id": 1302,
      "kana": "くもり",
      "kanji": "曇",
      "type": "n",
      "meaning": "cloudiness, cloudy weather, shadow"
    },
    {
      "id": 1303,
      "kana": "くもる",
      "kanji": "曇る",
      "type": "v5r",
      "meaning": "to become cloudy, to become dim"
    },
    {
      "id": 1304,
      "kana": "くやしい",
      "kanji": "悔しい",
      "type": "adj",
      "meaning": "regrettable, mortifying, vexing"
    },
    {
      "id": 1305,
      "kana": "くやむ",
      "kanji": "悔やむ",
      "type": "v5m",
      "meaning": "to mourn"
    },
    {
      "id": 1306,
      "kana": "くらい",
      "kanji": "位",
      "type": "n-adv,n,suf,vs",
      "meaning": "grade, rank, about"
    },
    {
      "id": 1307,
      "kana": "くらい",
      "kanji": "暗い",
      "type": "adj",
      "meaning": "dark, gloomy"
    },
    {
      "id": 1308,
      "kana": "~くらい",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1309,
      "kana": "くらし",
      "kanji": "暮らし",
      "type": "n",
      "meaning": "living, livelihood, subsistence, circumstances"
    },
    {
      "id": 1310,
      "kana": "クラシック",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "classic(s)"
    },
    {
      "id": 1311,
      "kana": "クラス",
      "kanji": "",
      "type": "n",
      "meaning": "class"
    },
    {
      "id": 1312,
      "kana": "くらす",
      "kanji": "暮らす",
      "type": "v5s",
      "meaning": "to live, to get along"
    },
    {
      "id": 1313,
      "kana": "グラス",
      "kanji": "",
      "type": "n",
      "meaning": "(1) glass, (2) grass"
    },
    {
      "id": 1314,
      "kana": "くらべる",
      "kanji": "比べる",
      "type": "v1",
      "meaning": "to compare"
    },
    {
      "id": 1315,
      "kana": "グラム",
      "kanji": "",
      "type": "n",
      "meaning": "gram"
    },
    {
      "id": 1316,
      "kana": "グランド",
      "kanji": "",
      "type": "n",
      "meaning": "gland, grand, (electrical) ground"
    },
    {
      "id": 1317,
      "kana": "クリーニング",
      "kanji": "",
      "type": "n",
      "meaning": "cleaning, dry cleaning, laundry service"
    },
    {
      "id": 1318,
      "kana": "クリーム",
      "kanji": "",
      "type": "n",
      "meaning": "cream"
    },
    {
      "id": 1319,
      "kana": "くりかえす",
      "kanji": "繰り返す",
      "type": "v5s",
      "meaning": "to repeat, to do something over again"
    },
    {
      "id": 1320,
      "kana": "クリスマス",
      "kanji": "",
      "type": "n",
      "meaning": "Christmas"
    },
    {
      "id": 1321,
      "kana": "くる",
      "kanji": "来る",
      "type": "vk",
      "meaning": "to come, to come to hand, to arrive"
    },
    {
      "id": 1322,
      "kana": "くるう",
      "kanji": "狂う",
      "type": "v5u",
      "meaning": "to go mad, to get out of order"
    },
    {
      "id": 1323,
      "kana": "グループ",
      "kanji": "",
      "type": "n",
      "meaning": "group"
    },
    {
      "id": 1324,
      "kana": "くるしい",
      "kanji": "苦しい",
      "type": "adj",
      "meaning": "painful, difficult"
    },
    {
      "id": 1325,
      "kana": "くるしむ",
      "kanji": "苦しむ",
      "type": "v5m",
      "meaning": "to suffer, to groan, to be worried"
    },
    {
      "id": 1326,
      "kana": "くるま",
      "kanji": "車",
      "type": "n",
      "meaning": "car, vehicle, wheel"
    },
    {
      "id": 1327,
      "kana": "くるむ",
      "kanji": "",
      "type": "v5m",
      "meaning": "to be engulfed in, to be enveloped by, to wrap up"
    },
    {
      "id": 1328,
      "kana": "くれ",
      "kanji": "暮れ",
      "type": "n-adv,n",
      "meaning": "year end, sunset, nightfall, end"
    },
    {
      "id": 1329,
      "kana": "くれぐれも",
      "kanji": "",
      "type": "adv",
      "meaning": "repeatedly, sincerely, earnestly"
    },
    {
      "id": 1330,
      "kana": "くれる",
      "kanji": "",
      "type": "v1",
      "meaning": "to get dark, to end, to come to an end, to close, to run out"
    },
    {
      "id": 1331,
      "kana": "くれる",
      "kanji": "暮れる",
      "type": "v1",
      "meaning": "to get dark, to end, to come to an end"
    },
    {
      "id": 1332,
      "kana": "くろ",
      "kanji": "黒",
      "type": "n",
      "meaning": "(1) black, (2) dark"
    },
    {
      "id": 1333,
      "kana": "くろい",
      "kanji": "黒い",
      "type": "adj",
      "meaning": "(1) black, (2) dark"
    },
    {
      "id": 1334,
      "kana": "くろう",
      "kanji": "苦労",
      "type": "adj-na,n,vs",
      "meaning": "troubles, hardships"
    },
    {
      "id": 1335,
      "kana": "くわえる",
      "kanji": "加える",
      "type": "v1",
      "meaning": "to append, to sum up, to add (up)"
    },
    {
      "id": 1336,
      "kana": "くわえる",
      "kanji": "咥える",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1337,
      "kana": "くわしい",
      "kanji": "詳しい",
      "type": "adj",
      "meaning": "knowing very well, detailed, full, accurate"
    },
    {
      "id": 1338,
      "kana": "くわわる",
      "kanji": "加わる",
      "type": "v5r",
      "meaning": "to join in, to accede to, to increase, to gain in (influence)"
    },
    {
      "id": 1339,
      "kana": "くん",
      "kanji": "訓",
      "type": "n",
      "meaning": "native Japanese reading of a Chinese character"
    },
    {
      "id": 1340,
      "kana": "~くん",
      "kanji": "~君",
      "type": "suffix",
      "meaning": "Mr. (used only for young men or boys)"
    },
    {
      "id": 1341,
      "kana": "ぐん",
      "kanji": "軍",
      "type": "n,n-suf",
      "meaning": "army, force, troops"
    },
    {
      "id": 1342,
      "kana": "ぐん",
      "kanji": "郡",
      "type": "n",
      "meaning": "country, district"
    },
    {
      "id": 1343,
      "kana": "ぐんたい",
      "kanji": "軍隊",
      "type": "n",
      "meaning": "army, troops"
    },
    {
      "id": 1344,
      "kana": "くんれん",
      "kanji": "訓練",
      "type": "n,vs",
      "meaning": "practice, training"
    },
    {
      "id": 1345,
      "kana": "け",
      "kanji": "毛",
      "type": "n",
      "meaning": "hair, fur"
    },
    {
      "id": 1346,
      "kana": "~け",
      "kanji": "~家",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1347,
      "kana": "げ",
      "kanji": "下",
      "type": "n",
      "meaning": "under, below, beneath"
    },
    {
      "id": 1348,
      "kana": "けい",
      "kanji": "計",
      "type": "n,n-suf",
      "meaning": "plan"
    },
    {
      "id": 1349,
      "kana": "~けい",
      "kanji": "~形",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1350,
      "kana": "~けい",
      "kanji": "~型",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1351,
      "kana": "けいい",
      "kanji": "敬意",
      "type": "n",
      "meaning": "respect, honour"
    },
    {
      "id": 1352,
      "kana": "けいえい",
      "kanji": "経営",
      "type": "n,vs",
      "meaning": "management, administration"
    },
    {
      "id": 1353,
      "kana": "けいかく",
      "kanji": "計画",
      "type": "n,vs",
      "meaning": "plan, project, schedule, scheme, program"
    },
    {
      "id": 1354,
      "kana": "けいかん",
      "kanji": "警官",
      "type": "n",
      "meaning": "policeman"
    },
    {
      "id": 1355,
      "kana": "けいき",
      "kanji": "景気",
      "type": "n",
      "meaning": "condition, state, business (condition)"
    },
    {
      "id": 1356,
      "kana": "けいけん",
      "kanji": "経験",
      "type": "n,vs",
      "meaning": "experience"
    },
    {
      "id": 1357,
      "kana": "けいこ",
      "kanji": "稽古",
      "type": "n",
      "meaning": "practice, training, study"
    },
    {
      "id": 1358,
      "kana": "けいご",
      "kanji": "敬語",
      "type": "n",
      "meaning": "honorific, term of respect"
    },
    {
      "id": 1359,
      "kana": "けいこう",
      "kanji": "傾向",
      "type": "n",
      "meaning": "tendency, trend, inclination"
    },
    {
      "id": 1360,
      "kana": "けいこうとう",
      "kanji": "蛍光灯",
      "type": "n",
      "meaning": "fluorescent lamp, person who is slow to react"
    },
    {
      "id": 1361,
      "kana": "けいこく",
      "kanji": "警告",
      "type": "n,vs",
      "meaning": "warning, advice"
    },
    {
      "id": 1362,
      "kana": "けいざい",
      "kanji": "経済",
      "type": "n",
      "meaning": "economics, business, finance, economy"
    },
    {
      "id": 1363,
      "kana": "けいさつ",
      "kanji": "警察",
      "type": "n",
      "meaning": "police"
    },
    {
      "id": 1364,
      "kana": "けいさん",
      "kanji": "計算",
      "type": "n,vs",
      "meaning": "calculation, reckoning"
    },
    {
      "id": 1365,
      "kana": "けいじ",
      "kanji": "掲示",
      "type": "n,vs",
      "meaning": "notice, bulletin"
    },
    {
      "id": 1366,
      "kana": "けいじ",
      "kanji": "刑事",
      "type": "n",
      "meaning": "criminal case, (police) detective"
    },
    {
      "id": 1367,
      "kana": "けいしき",
      "kanji": "形式",
      "type": "n",
      "meaning": "form, formality, format, math expression"
    },
    {
      "id": 1368,
      "kana": "げいじゅつ",
      "kanji": "芸術",
      "type": "n",
      "meaning": "(fine) art, the arts"
    },
    {
      "id": 1369,
      "kana": "けいぞく",
      "kanji": "継続",
      "type": "n,vs",
      "meaning": "continuation"
    },
    {
      "id": 1370,
      "kana": "けいと",
      "kanji": "毛糸",
      "type": "n",
      "meaning": "knitting wool"
    },
    {
      "id": 1371,
      "kana": "けいど",
      "kanji": "経度",
      "type": "n",
      "meaning": "longitude"
    },
    {
      "id": 1372,
      "kana": "けいとう",
      "kanji": "系統",
      "type": "n",
      "meaning": "system, family line, geological formation"
    },
    {
      "id": 1373,
      "kana": "げいのう",
      "kanji": "芸能",
      "type": "n",
      "meaning": "public entertainment, accomplishments, attainments"
    },
    {
      "id": 1374,
      "kana": "けいば",
      "kanji": "競馬",
      "type": "n",
      "meaning": "horse racing"
    },
    {
      "id": 1375,
      "kana": "けいび",
      "kanji": "警備",
      "type": "n,vs",
      "meaning": "defense, guard, policing, security"
    },
    {
      "id": 1376,
      "kana": "けいやく",
      "kanji": "契約",
      "type": "n",
      "meaning": "contract, compact, agreement"
    },
    {
      "id": 1377,
      "kana": "けいゆ",
      "kanji": "経由",
      "type": "n",
      "meaning": "go by the way, via"
    },
    {
      "id": 1378,
      "kana": "けいようし",
      "kanji": "形容詞",
      "type": "n",
      "meaning": "trueadjective"
    },
    {
      "id": 1379,
      "kana": "けいようどうし",
      "kanji": "形容動詞",
      "type": "n",
      "meaning": "adjectival noun, quasi-adjective"
    },
    {
      "id": 1380,
      "kana": "ケーキ",
      "kanji": "",
      "type": "n",
      "meaning": "cake"
    },
    {
      "id": 1381,
      "kana": "ケース",
      "kanji": "",
      "type": "n",
      "meaning": "case"
    },
    {
      "id": 1382,
      "kana": "ゲーム",
      "kanji": "",
      "type": "n",
      "meaning": "game"
    },
    {
      "id": 1383,
      "kana": "けが",
      "kanji": "怪我",
      "type": "n,vs",
      "meaning": "injury (to animate object), hurt"
    },
    {
      "id": 1384,
      "kana": "げか",
      "kanji": "外科",
      "type": "n",
      "meaning": "surgical department"
    },
    {
      "id": 1385,
      "kana": "けがわ",
      "kanji": "毛皮",
      "type": "n",
      "meaning": "fur, skin, pelt"
    },
    {
      "id": 1386,
      "kana": "げき",
      "kanji": "劇",
      "type": "n",
      "meaning": "drama, play"
    },
    {
      "id": 1387,
      "kana": "げきじょう",
      "kanji": "劇場",
      "type": "n",
      "meaning": "theatre, playhouse"
    },
    {
      "id": 1388,
      "kana": "げきぞう",
      "kanji": "激増",
      "type": "n,vs",
      "meaning": "sudden increase"
    },
    {
      "id": 1389,
      "kana": "けさ",
      "kanji": "今朝",
      "type": "ik",
      "meaning": "(n-t) this morning"
    },
    {
      "id": 1390,
      "kana": "けしき",
      "kanji": "景色",
      "type": "n",
      "meaning": "scenery, scene, landscape"
    },
    {
      "id": 1391,
      "kana": "けしゴム",
      "kanji": "消しゴム",
      "type": "n",
      "meaning": "eraser, India rubber"
    },
    {
      "id": 1392,
      "kana": "げしゃ",
      "kanji": "下車",
      "type": "n,vs",
      "meaning": "alighting, getting off"
    },
    {
      "id": 1393,
      "kana": "げしゅく",
      "kanji": "下宿",
      "type": "n,vs",
      "meaning": "boarding, lodging, boarding house"
    },
    {
      "id": 1394,
      "kana": "げじゅん",
      "kanji": "下旬",
      "type": "n-adv,n-t",
      "meaning": "month (last third of)"
    },
    {
      "id": 1395,
      "kana": "けしょう",
      "kanji": "化粧",
      "type": "n",
      "meaning": "make-up (cosmetic)"
    },
    {
      "id": 1396,
      "kana": "けす",
      "kanji": "消す",
      "type": "v5s",
      "meaning": "to erase, to delete, to turn off power"
    },
    {
      "id": 1397,
      "kana": "げすい",
      "kanji": "下水",
      "type": "n",
      "meaning": "drainage, sewage, ditch, gutter, sewerage"
    },
    {
      "id": 1398,
      "kana": "けずる",
      "kanji": "削る",
      "type": "v5r",
      "meaning": "to cut down little by little, to take a percentage"
    },
    {
      "id": 1399,
      "kana": "けた",
      "kanji": "桁",
      "type": "n",
      "meaning": "column, beam, digit"
    },
    {
      "id": 1400,
      "kana": "げた",
      "kanji": "下駄",
      "type": "n",
      "meaning": "geta (Japanese footwear), wooden clogs"
    },
    {
      "id": 1401,
      "kana": "けち",
      "kanji": "",
      "type": "adj-na,n,vs",
      "meaning": "stinginess, miser, miserliness"
    },
    {
      "id": 1402,
      "kana": "けつあつ",
      "kanji": "血圧",
      "type": "n",
      "meaning": "blood pressure"
    },
    {
      "id": 1403,
      "kana": "けつえき",
      "kanji": "血液",
      "type": "n",
      "meaning": "blood"
    },
    {
      "id": 1404,
      "kana": "けっか",
      "kanji": "結果",
      "type": "n-adv,n-t",
      "meaning": "result, consequence"
    },
    {
      "id": 1405,
      "kana": "けっかん",
      "kanji": "欠陥",
      "type": "n",
      "meaning": "defect, fault, deficiency"
    },
    {
      "id": 1406,
      "kana": "げっきゅう",
      "kanji": "月給",
      "type": "n",
      "meaning": "monthly salary"
    },
    {
      "id": 1407,
      "kana": "けっきょく",
      "kanji": "結局",
      "type": "n-adv,n",
      "meaning": "after all, eventually"
    },
    {
      "id": 1408,
      "kana": "けっこう",
      "kanji": "結構",
      "type": "adj-na,n-adv,n",
      "meaning": "(1) splendid, nice, wonderful"
    },
    {
      "id": 1409,
      "kana": "けっこん",
      "kanji": "結婚",
      "type": "adj-no,n,vs",
      "meaning": "marriage"
    },
    {
      "id": 1410,
      "kana": "けっさく",
      "kanji": "傑作",
      "type": "adj-na,n",
      "meaning": "masterpiece, best work, boner, blunder"
    },
    {
      "id": 1411,
      "kana": "けっして",
      "kanji": "決して",
      "type": "adv",
      "meaning": "never, by no means"
    },
    {
      "id": 1412,
      "kana": "けっしん",
      "kanji": "決心",
      "type": "n,vs",
      "meaning": "determination, resolution"
    },
    {
      "id": 1413,
      "kana": "けっせき",
      "kanji": "欠席",
      "type": "n,vs",
      "meaning": "absence, non-attendance"
    },
    {
      "id": 1414,
      "kana": "けってい",
      "kanji": "決定",
      "type": "n",
      "meaning": "decision, determination"
    },
    {
      "id": 1415,
      "kana": "けってん",
      "kanji": "欠点",
      "type": "n",
      "meaning": "faults, defect, weakness"
    },
    {
      "id": 1416,
      "kana": "げつまつ",
      "kanji": "月末",
      "type": "n-adv,n-t",
      "meaning": "end of the month"
    },
    {
      "id": 1417,
      "kana": "げつよう",
      "kanji": "月曜",
      "type": "n-adv,n-t",
      "meaning": "Monday"
    },
    {
      "id": 1418,
      "kana": "けつろん",
      "kanji": "結論",
      "type": "n",
      "meaning": "conclusion"
    },
    {
      "id": 1419,
      "kana": "けはい",
      "kanji": "気配",
      "type": "n",
      "meaning": "indication, market trend, worry"
    },
    {
      "id": 1420,
      "kana": "げひん",
      "kanji": "下品",
      "type": "adj-na,n",
      "meaning": "vulgarity, meanness, indecency, coarseness"
    },
    {
      "id": 1421,
      "kana": "けむい",
      "kanji": "煙い",
      "type": "adj",
      "meaning": "smoky"
    },
    {
      "id": 1422,
      "kana": "けむり",
      "kanji": "煙",
      "type": "n",
      "meaning": "smoke, fumes"
    },
    {
      "id": 1423,
      "kana": "ける",
      "kanji": "蹴る",
      "type": "v5r",
      "meaning": "to kick"
    },
    {
      "id": 1424,
      "kana": "",
      "kanji": "けれど/けれども",
      "type": "conj",
      "meaning": "but, however"
    },
    {
      "id": 1425,
      "kana": "けわしい",
      "kanji": "険しい",
      "type": "adj",
      "meaning": "inaccessible place, sharp eyes"
    },
    {
      "id": 1426,
      "kana": "けん",
      "kanji": "券",
      "type": "n,n-suf",
      "meaning": "ticket, coupon, bond, certificate"
    },
    {
      "id": 1427,
      "kana": "けん",
      "kanji": "県",
      "type": "n",
      "meaning": "prefecture"
    },
    {
      "id": 1428,
      "kana": "けん",
      "kanji": "軒",
      "type": "n",
      "meaning": "eaves"
    },
    {
      "id": 1429,
      "kana": "~けん",
      "kanji": "~権",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1430,
      "kana": "げん~",
      "kanji": "現~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1431,
      "kana": "げんいん",
      "kanji": "原因",
      "type": "n",
      "meaning": "cause, origin, source"
    },
    {
      "id": 1432,
      "kana": "けんか",
      "kanji": "喧嘩",
      "type": "n",
      "meaning": "quarrel, (drunken) brawl, failure"
    },
    {
      "id": 1433,
      "kana": "けんかい",
      "kanji": "見解",
      "type": "n",
      "meaning": "opinion, point of view"
    },
    {
      "id": 1434,
      "kana": "げんかい",
      "kanji": "限界",
      "type": "n",
      "meaning": "limit, bound"
    },
    {
      "id": 1435,
      "kana": "けんがく",
      "kanji": "見学",
      "type": "n,vs",
      "meaning": "inspection, study by observation, field trip"
    },
    {
      "id": 1436,
      "kana": "げんかん",
      "kanji": "玄関",
      "type": "n",
      "meaning": "entranceway, entry hall"
    },
    {
      "id": 1437,
      "kana": "げんき",
      "kanji": "元気",
      "type": "adj-na,n",
      "meaning": "health(y), robust, vigor, energy"
    },
    {
      "id": 1438,
      "kana": "けんきゅう",
      "kanji": "研究",
      "type": "n,vs",
      "meaning": "study, research, investigation"
    },
    {
      "id": 1439,
      "kana": "けんきょ",
      "kanji": "謙虚",
      "type": "adj-na,n",
      "meaning": "modesty, humility"
    },
    {
      "id": 1440,
      "kana": "げんきん",
      "kanji": "現金",
      "type": "adj-na,n",
      "meaning": "cash, ready money, mercenary, self-interested"
    },
    {
      "id": 1441,
      "kana": "げんご",
      "kanji": "言語",
      "type": "n",
      "meaning": "language"
    },
    {
      "id": 1442,
      "kana": "けんこう",
      "kanji": "健康",
      "type": "adj-na,n",
      "meaning": "health, sound, wholesome"
    },
    {
      "id": 1443,
      "kana": "げんこう",
      "kanji": "原稿",
      "type": "n",
      "meaning": "manuscript, copy"
    },
    {
      "id": 1444,
      "kana": "けんさ",
      "kanji": "検査",
      "type": "n,vs",
      "meaning": "inspection (e.g. customs, factory), examination"
    },
    {
      "id": 1445,
      "kana": "げんざい",
      "kanji": "現在",
      "type": "n-adv,n-t",
      "meaning": "present, up to now, nowadays, modern times, current"
    },
    {
      "id": 1446,
      "kana": "げんさん",
      "kanji": "原産",
      "type": "n",
      "meaning": "place of origin, habitat"
    },
    {
      "id": 1447,
      "kana": "げんし",
      "kanji": "原始",
      "type": "n",
      "meaning": "origin, primeval"
    },
    {
      "id": 1448,
      "kana": "げんじつ",
      "kanji": "現実",
      "type": "n",
      "meaning": "reality"
    },
    {
      "id": 1449,
      "kana": "けんしゅう",
      "kanji": "研修",
      "type": "n,vs",
      "meaning": "training"
    },
    {
      "id": 1450,
      "kana": "げんじゅう",
      "kanji": "厳重",
      "type": "adj-na,n",
      "meaning": "strict, rigour, severe, firm"
    },
    {
      "id": 1451,
      "kana": "げんしょう",
      "kanji": "現象",
      "type": "n",
      "meaning": "phenomenon"
    },
    {
      "id": 1452,
      "kana": "げんじょう",
      "kanji": "現状",
      "type": "n",
      "meaning": "present condition, existing state, status quo"
    },
    {
      "id": 1453,
      "kana": "けんせつ",
      "kanji": "建設",
      "type": "n,vs",
      "meaning": "construction, establishment"
    },
    {
      "id": 1454,
      "kana": "けんそん",
      "kanji": "謙遜",
      "type": "adj-na,n",
      "meaning": "humble, humility, modesty"
    },
    {
      "id": 1455,
      "kana": "げんだい",
      "kanji": "現代",
      "type": "adj-no,n-adv,n",
      "meaning": "nowadays, modern times, present-day"
    },
    {
      "id": 1456,
      "kana": "けんちく",
      "kanji": "建築",
      "type": "n,vs",
      "meaning": "construction, architecture"
    },
    {
      "id": 1457,
      "kana": "けんちょう",
      "kanji": "県庁",
      "type": "n",
      "meaning": "prefectural office"
    },
    {
      "id": 1458,
      "kana": "げんど",
      "kanji": "限度",
      "type": "n",
      "meaning": "limit, bounds"
    },
    {
      "id": 1459,
      "kana": "けんとう",
      "kanji": "見当",
      "type": "n",
      "meaning": "be found, aim, estimate, guess, approx"
    },
    {
      "id": 1460,
      "kana": "けんとう",
      "kanji": "検討",
      "type": "n,vs",
      "meaning": "consideration, examination, investigation, study, scrutiny"
    },
    {
      "id": 1461,
      "kana": "げんに",
      "kanji": "現に",
      "type": "adv",
      "meaning": "actually, really"
    },
    {
      "id": 1462,
      "kana": "げんば",
      "kanji": "現場",
      "type": "n",
      "meaning": "actual spot, scene, scene of the crime"
    },
    {
      "id": 1463,
      "kana": "けんびきょう",
      "kanji": "顕微鏡",
      "type": "n",
      "meaning": "microscope"
    },
    {
      "id": 1464,
      "kana": "けんぶつ",
      "kanji": "見物",
      "type": "n",
      "meaning": "a sight, an attraction, something worth seeing"
    },
    {
      "id": 1465,
      "kana": "けんぽう",
      "kanji": "憲法",
      "type": "n",
      "meaning": "constitution"
    },
    {
      "id": 1466,
      "kana": "けんめい",
      "kanji": "懸命",
      "type": "adj-na,n",
      "meaning": "eagerness, earnestness, risking one's life"
    },
    {
      "id": 1467,
      "kana": "けんり",
      "kanji": "権利",
      "type": "n",
      "meaning": "right, privilege"
    },
    {
      "id": 1468,
      "kana": "げんり",
      "kanji": "原理",
      "type": "n",
      "meaning": "principle, theory, fundamental truth"
    },
    {
      "id": 1469,
      "kana": "げんりょう",
      "kanji": "原料",
      "type": "n",
      "meaning": "raw materials"
    },
    {
      "id": 1470,
      "kana": "こ",
      "kanji": "子",
      "type": "n",
      "meaning": "first sign of Chinese zodiac (The Rat, 11pm-1am, north, November)"
    },
    {
      "id": 1471,
      "kana": "こ~",
      "kanji": "小~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1472,
      "kana": "~こ",
      "kanji": "~個",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1473,
      "kana": "~こ",
      "kanji": "~湖",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1474,
      "kana": "ご",
      "kanji": "五",
      "type": "num",
      "meaning": "five"
    },
    {
      "id": 1475,
      "kana": "ご",
      "kanji": "後",
      "type": "adj-no,n",
      "meaning": "afterwards, since then, in the future"
    },
    {
      "id": 1476,
      "kana": "ご",
      "kanji": "語",
      "type": "n,n-suf",
      "meaning": "language, word"
    },
    {
      "id": 1477,
      "kana": "ご",
      "kanji": "碁",
      "type": "n",
      "meaning": "Go (board game of capturing territory)"
    },
    {
      "id": 1478,
      "kana": "~ご",
      "kanji": "~御",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1479,
      "kana": "こい",
      "kanji": "恋",
      "type": "n,vs",
      "meaning": "love, tender passion"
    },
    {
      "id": 1480,
      "kana": "こい",
      "kanji": "濃い",
      "type": "adj",
      "meaning": "thick (as of color, liquid), dense, strong"
    },
    {
      "id": 1481,
      "kana": "こいしい",
      "kanji": "恋しい",
      "type": "adj",
      "meaning": "(1) dear, beloved, darling, (2) yearned for"
    },
    {
      "id": 1482,
      "kana": "こいびと",
      "kanji": "恋人",
      "type": "n",
      "meaning": "lover, sweetheart"
    },
    {
      "id": 1483,
      "kana": "こう",
      "kanji": "請う",
      "type": "n",
      "meaning": "to ask, to request"
    },
    {
      "id": 1484,
      "kana": "こう~",
      "kanji": "高~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1485,
      "kana": "~こう",
      "kanji": "~校",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1486,
      "kana": "~こう",
      "kanji": "~港",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1487,
      "kana": "~ごう",
      "kanji": "~号",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1488,
      "kana": "こういん",
      "kanji": "工員",
      "type": "n",
      "meaning": "factory worker"
    },
    {
      "id": 1489,
      "kana": "ごういん",
      "kanji": "強引",
      "type": "adj-na,n",
      "meaning": "overbearing, coercive, pushy, forcible, high-handed"
    },
    {
      "id": 1490,
      "kana": "こううん",
      "kanji": "幸運",
      "type": "adj-na,n",
      "meaning": "good luck, fortune"
    },
    {
      "id": 1491,
      "kana": "こうえん",
      "kanji": "公園",
      "type": "n",
      "meaning": "(public) park"
    },
    {
      "id": 1492,
      "kana": "こうえん",
      "kanji": "講演",
      "type": "n,vs",
      "meaning": "lecture, address"
    },
    {
      "id": 1493,
      "kana": "こうか",
      "kanji": "効果",
      "type": "n,adj-no",
      "meaning": "effect, effectiveness, efficacy, result"
    },
    {
      "id": 1494,
      "kana": "こうか",
      "kanji": "硬貨",
      "type": "n",
      "meaning": "coin"
    },
    {
      "id": 1495,
      "kana": "こうか",
      "kanji": "高価",
      "type": "adj-na,n",
      "meaning": "high price"
    },
    {
      "id": 1496,
      "kana": "ごうか",
      "kanji": "豪華",
      "type": "adj-na,n",
      "meaning": "wonderful, gorgeous, splendor, pomp, extravagance"
    },
    {
      "id": 1497,
      "kana": "こうがい",
      "kanji": "郊外",
      "type": "n",
      "meaning": "suburb, outskirts"
    },
    {
      "id": 1498,
      "kana": "こうがい",
      "kanji": "公害",
      "type": "n",
      "meaning": "public nuisance, pollution"
    },
    {
      "id": 1499,
      "kana": "ごうかく",
      "kanji": "合格",
      "type": "n,vs",
      "meaning": "success, passing (e.g. exam), eligibility"
    },
    {
      "id": 1500,
      "kana": "こうかん",
      "kanji": "交換",
      "type": "n,vs",
      "meaning": "exchange, interchange, reciprocity"
    },
    {
      "id": 1501,
      "kana": "こうぎ",
      "kanji": "講義",
      "type": "n,vs",
      "meaning": "lecture"
    },
    {
      "id": 1502,
      "kana": "こうきゅう",
      "kanji": "高級",
      "type": "adj-na,n",
      "meaning": "high class, high grade"
    },
    {
      "id": 1503,
      "kana": "こうきょう",
      "kanji": "公共",
      "type": "adj-no,n",
      "meaning": "public, community, public service, society, communal"
    },
    {
      "id": 1504,
      "kana": "こうぎょう",
      "kanji": "工業",
      "type": "n",
      "meaning": "(manufacturing) industry"
    },
    {
      "id": 1505,
      "kana": "こうくう",
      "kanji": "航空",
      "type": "n",
      "meaning": "aviation, flying"
    },
    {
      "id": 1506,
      "kana": "こうけい",
      "kanji": "光景",
      "type": "n",
      "meaning": "scene, spectacle"
    },
    {
      "id": 1507,
      "kana": "こうげい",
      "kanji": "工芸",
      "type": "n",
      "meaning": "industrial arts"
    },
    {
      "id": 1508,
      "kana": "ごうけい",
      "kanji": "合計",
      "type": "n,vs",
      "meaning": "sum total, total amount"
    },
    {
      "id": 1509,
      "kana": "こうげき",
      "kanji": "攻撃",
      "type": "n,vs",
      "meaning": "attack, strike, offensive, criticism, censure"
    },
    {
      "id": 1510,
      "kana": "こうけん",
      "kanji": "貢献",
      "type": "n,vs",
      "meaning": "contribution, services"
    },
    {
      "id": 1511,
      "kana": "こうこう",
      "kanji": "孝行",
      "type": "adj-na,n",
      "meaning": "filial piety"
    },
    {
      "id": 1512,
      "kana": "こうこう",
      "kanji": "高校",
      "type": "n",
      "meaning": "senior high school"
    },
    {
      "id": 1513,
      "kana": "こうこく",
      "kanji": "広告",
      "type": "n",
      "meaning": "advertisement"
    },
    {
      "id": 1514,
      "kana": "こうさ",
      "kanji": "交差",
      "type": "n,vs",
      "meaning": "cross"
    },
    {
      "id": 1515,
      "kana": "こうさい",
      "kanji": "交際",
      "type": "n,vs",
      "meaning": "company, friendship, association, society, acquaintance"
    },
    {
      "id": 1516,
      "kana": "こうさてん",
      "kanji": "交差点",
      "type": "n",
      "meaning": "crossing, intersection"
    },
    {
      "id": 1517,
      "kana": "こうし",
      "kanji": "講師",
      "type": "n",
      "meaning": "lecturer"
    },
    {
      "id": 1518,
      "kana": "こうじ",
      "kanji": "工事",
      "type": "n",
      "meaning": "construction work"
    },
    {
      "id": 1519,
      "kana": "こうしき",
      "kanji": "公式",
      "type": "adj-na,n",
      "meaning": "formula, formality, official"
    },
    {
      "id": 1520,
      "kana": "こうじつ",
      "kanji": "口実",
      "type": "n",
      "meaning": "excuse"
    },
    {
      "id": 1521,
      "kana": "こうして",
      "kanji": "こうして",
      "type": "",
      "meaning": "thus"
    },
    {
      "id": 1522,
      "kana": "こうしゃ",
      "kanji": "校舎",
      "type": "n",
      "meaning": "school building"
    },
    {
      "id": 1523,
      "kana": "こうしゃ",
      "kanji": "後者",
      "type": "n",
      "meaning": "the latter"
    },
    {
      "id": 1524,
      "kana": "こうしゅう",
      "kanji": "公衆",
      "type": "n",
      "meaning": "the public"
    },
    {
      "id": 1525,
      "kana": "こうじょう",
      "kanji": "工場",
      "type": "n",
      "meaning": "factory, plant, mill, workshop"
    },
    {
      "id": 1526,
      "kana": "こうば",
      "kanji": "工場",
      "type": "n",
      "meaning": "factory, plant, mill, workshop"
    },
    {
      "id": 1527,
      "kana": "こうすい",
      "kanji": "香水",
      "type": "n",
      "meaning": "perfume"
    },
    {
      "id": 1528,
      "kana": "こうせい",
      "kanji": "公正",
      "type": "adj-na,n",
      "meaning": "justice, fairness, impartiality"
    },
    {
      "id": 1529,
      "kana": "こうせい",
      "kanji": "構成",
      "type": "n,vs",
      "meaning": "organization, composition"
    },
    {
      "id": 1530,
      "kana": "こうせき",
      "kanji": "功績",
      "type": "n",
      "meaning": "achievements, merit, meritorious service, meritorious deed"
    },
    {
      "id": 1531,
      "kana": "こうせん",
      "kanji": "光線",
      "type": "n",
      "meaning": "beam, light ray"
    },
    {
      "id": 1532,
      "kana": "こうそう",
      "kanji": "高層",
      "type": "n",
      "meaning": "upper"
    },
    {
      "id": 1533,
      "kana": "こうぞう",
      "kanji": "構造",
      "type": "n",
      "meaning": "structure, construction"
    },
    {
      "id": 1534,
      "kana": "こうそく",
      "kanji": "高速",
      "type": "adj-na,n",
      "meaning": "high speed, high gear"
    },
    {
      "id": 1535,
      "kana": "こうたい",
      "kanji": "交替",
      "type": "n,vs",
      "meaning": "alternation, change, relief, relay, shift"
    },
    {
      "id": 1536,
      "kana": "こうち",
      "kanji": "耕地",
      "type": "n",
      "meaning": "arable land"
    },
    {
      "id": 1537,
      "kana": "こうちゃ",
      "kanji": "紅茶",
      "type": "n",
      "meaning": "black tea"
    },
    {
      "id": 1538,
      "kana": "こうつう",
      "kanji": "交通",
      "type": "n",
      "meaning": "communication, transportation, traffic, intercourse"
    },
    {
      "id": 1539,
      "kana": "こうつうきかん",
      "kanji": "交通機関",
      "type": "n",
      "meaning": "transportation facilities"
    },
    {
      "id": 1540,
      "kana": "こうてい",
      "kanji": "校庭",
      "type": "n",
      "meaning": "campus"
    },
    {
      "id": 1541,
      "kana": "こうてい",
      "kanji": "肯定",
      "type": "n",
      "meaning": "positive, affirmation"
    },
    {
      "id": 1542,
      "kana": "こうど",
      "kanji": "高度",
      "type": "adj-na,n",
      "meaning": "altitude, height, advanced"
    },
    {
      "id": 1543,
      "kana": "こうとう",
      "kanji": "高等",
      "type": "adj-na,n",
      "meaning": "high class, high grade"
    },
    {
      "id": 1544,
      "kana": "こうどう",
      "kanji": "行動",
      "type": "n,vs",
      "meaning": "action, conduct, behaviour, mobilization"
    },
    {
      "id": 1545,
      "kana": "こうどう",
      "kanji": "講堂",
      "type": "n",
      "meaning": "auditorium"
    },
    {
      "id": 1546,
      "kana": "ごうとう",
      "kanji": "強盗",
      "type": "n",
      "meaning": "robbery, burglary"
    },
    {
      "id": 1547,
      "kana": "ごうどう",
      "kanji": "合同",
      "type": "adj-na,n",
      "meaning": "combination, incorporation, union, amalgamation"
    },
    {
      "id": 1548,
      "kana": "こうとうがっこう",
      "kanji": "高等学校",
      "type": "",
      "meaning": "senior high school"
    },
    {
      "id": 1549,
      "kana": "こうはい",
      "kanji": "後輩",
      "type": "n",
      "meaning": "junior (at work or school)"
    },
    {
      "id": 1550,
      "kana": "こうばん",
      "kanji": "交番",
      "type": "n",
      "meaning": "police box"
    },
    {
      "id": 1551,
      "kana": "こうひょう",
      "kanji": "公表",
      "type": "n,vs",
      "meaning": "official announcement, proclamation"
    },
    {
      "id": 1552,
      "kana": "こうふく",
      "kanji": "幸福",
      "type": "adj-na,n",
      "meaning": "happiness, blessedness"
    },
    {
      "id": 1553,
      "kana": "こうぶつ",
      "kanji": "鉱物",
      "type": "n",
      "meaning": "mineral"
    },
    {
      "id": 1554,
      "kana": "こうへい",
      "kanji": "公平",
      "type": "adj-na,n",
      "meaning": "fairness, impartial, justice"
    },
    {
      "id": 1555,
      "kana": "こうほ",
      "kanji": "候補",
      "type": "n",
      "meaning": "candidacy"
    },
    {
      "id": 1556,
      "kana": "こうむ",
      "kanji": "公務",
      "type": "n",
      "meaning": "official business, public business"
    },
    {
      "id": 1557,
      "kana": "こうもく",
      "kanji": "項目",
      "type": "n",
      "meaning": "item"
    },
    {
      "id": 1558,
      "kana": "こうよう",
      "kanji": "紅葉",
      "type": "n",
      "meaning": "(1) (Japanese) maple"
    },
    {
      "id": 1559,
      "kana": "ごうり",
      "kanji": "合理",
      "type": "n",
      "meaning": "rational"
    },
    {
      "id": 1560,
      "kana": "こうりゅう",
      "kanji": "交流",
      "type": "n",
      "meaning": "alternating current, intercourse, (cultural) exchange, intermingling"
    },
    {
      "id": 1561,
      "kana": "ごうりゅう",
      "kanji": "合流",
      "type": "n,vs",
      "meaning": "confluence, union, linking up, merge"
    },
    {
      "id": 1562,
      "kana": "こうりょ",
      "kanji": "考慮",
      "type": "n,vs",
      "meaning": "consideration, taking into account"
    },
    {
      "id": 1563,
      "kana": "こうりょく",
      "kanji": "効力",
      "type": "n",
      "meaning": "effect, efficacy, validity, potency"
    },
    {
      "id": 1564,
      "kana": "こえ",
      "kanji": "声",
      "type": "n",
      "meaning": "voice"
    },
    {
      "id": 1565,
      "kana": "こえる",
      "kanji": "越える",
      "type": "v1",
      "meaning": "to exceed, to cross over, to cross"
    },
    {
      "id": 1566,
      "kana": "こえる",
      "kanji": "超える",
      "type": "v1",
      "meaning": "to exceed, to cross over, to cross"
    },
    {
      "id": 1567,
      "kana": "コース",
      "kanji": "",
      "type": "n",
      "meaning": "course"
    },
    {
      "id": 1568,
      "kana": "コーチ",
      "kanji": "",
      "type": "n",
      "meaning": "coach"
    },
    {
      "id": 1569,
      "kana": "コート",
      "kanji": "",
      "type": "n",
      "meaning": "coat, tennis court"
    },
    {
      "id": 1570,
      "kana": "コード",
      "kanji": "",
      "type": "n",
      "meaning": "code, cord, chord"
    },
    {
      "id": 1571,
      "kana": "コーヒー",
      "kanji": "",
      "type": "n",
      "meaning": "coffee"
    },
    {
      "id": 1572,
      "kana": "コーラス",
      "kanji": "",
      "type": "n",
      "meaning": "chorus"
    },
    {
      "id": 1573,
      "kana": "こおり",
      "kanji": "氷",
      "type": "",
      "meaning": "ice, hail"
    },
    {
      "id": 1574,
      "kana": "こおる",
      "kanji": "凍る",
      "type": "v5r",
      "meaning": "to freeze, to be frozen over, to congeal"
    },
    {
      "id": 1575,
      "kana": "ゴール",
      "kanji": "",
      "type": "n",
      "meaning": "goal"
    },
    {
      "id": 1576,
      "kana": "ごかい",
      "kanji": "誤解",
      "type": "n,vs",
      "meaning": "misunderstanding"
    },
    {
      "id": 1577,
      "kana": "ごがく",
      "kanji": "語学",
      "type": "n",
      "meaning": "language study"
    },
    {
      "id": 1578,
      "kana": "こがす",
      "kanji": "焦がす",
      "type": "v5s",
      "meaning": "to burn, to scorch, to singe, to char"
    },
    {
      "id": 1579,
      "kana": "こきゅう",
      "kanji": "呼吸",
      "type": "n,vs",
      "meaning": "breath, respiration"
    },
    {
      "id": 1580,
      "kana": "こきょう",
      "kanji": "故郷",
      "type": "n",
      "meaning": "home town, birthplace, old village, historic village"
    },
    {
      "id": 1581,
      "kana": "~こく",
      "kanji": "~国",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1582,
      "kana": "ごく",
      "kanji": "極",
      "type": "adv,n",
      "meaning": "quite, very"
    },
    {
      "id": 1583,
      "kana": "こくおう",
      "kanji": "国王",
      "type": "n",
      "meaning": "king"
    },
    {
      "id": 1584,
      "kana": "こくご",
      "kanji": "国語",
      "type": "n",
      "meaning": "national language"
    },
    {
      "id": 1585,
      "kana": "こくさい",
      "kanji": "国際",
      "type": "n",
      "meaning": "international"
    },
    {
      "id": 1586,
      "kana": "こくせき",
      "kanji": "国籍",
      "type": "n",
      "meaning": "nationality"
    },
    {
      "id": 1587,
      "kana": "こくばん",
      "kanji": "黒板",
      "type": "n",
      "meaning": "blackboard"
    },
    {
      "id": 1588,
      "kana": "こくふく",
      "kanji": "克服",
      "type": "n",
      "meaning": "subjugation, conquest"
    },
    {
      "id": 1589,
      "kana": "こくみん",
      "kanji": "国民",
      "type": "n",
      "meaning": "national, people, citizen"
    },
    {
      "id": 1590,
      "kana": "こくもつ",
      "kanji": "穀物",
      "type": "n",
      "meaning": "grain, cereal, corn"
    },
    {
      "id": 1591,
      "kana": "こくりつ",
      "kanji": "国立",
      "type": "n",
      "meaning": "national"
    },
    {
      "id": 1592,
      "kana": "ごくろうさま",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "Thank you very much for your...."
    },
    {
      "id": 1593,
      "kana": "こげる",
      "kanji": "焦げる",
      "type": "v1,vi",
      "meaning": "to burn, to be burned"
    },
    {
      "id": 1594,
      "kana": "ここ",
      "kanji": "ここ",
      "type": "n",
      "meaning": "here"
    },
    {
      "id": 1595,
      "kana": "ごご",
      "kanji": "午後",
      "type": "n-adv,n-t",
      "meaning": "afternoon, p.m., pm"
    },
    {
      "id": 1596,
      "kana": "こごえる",
      "kanji": "凍える",
      "type": "v1",
      "meaning": "to freeze, to be chilled, to be frozen"
    },
    {
      "id": 1597,
      "kana": "ここのか",
      "kanji": "九日",
      "type": "",
      "meaning": "nine days, the ninth day (of the month)"
    },
    {
      "id": 1598,
      "kana": "ここのつ",
      "kanji": "九つ",
      "type": "n",
      "meaning": "nine"
    },
    {
      "id": 1599,
      "kana": "こころ",
      "kanji": "心",
      "type": "n",
      "meaning": "core, heart, wick, marrow"
    },
    {
      "id": 1600,
      "kana": "こころあたり",
      "kanji": "心当たり",
      "type": "n",
      "meaning": "having some knowledge of, happening to know"
    },
    {
      "id": 1601,
      "kana": "こころえる",
      "kanji": "心得る",
      "type": "v1",
      "meaning": "to be informed, to have thorough knowledge"
    },
    {
      "id": 1602,
      "kana": "こし",
      "kanji": "腰",
      "type": "n",
      "meaning": "hip"
    },
    {
      "id": 1603,
      "kana": "こしかけ",
      "kanji": "腰掛け",
      "type": "n",
      "meaning": "seat, bench"
    },
    {
      "id": 1604,
      "kana": "こしかける",
      "kanji": "腰掛ける",
      "type": "v1",
      "meaning": "to sit (down)"
    },
    {
      "id": 1605,
      "kana": "ごじゅうおん",
      "kanji": "五十音",
      "type": "n",
      "meaning": "the Japanese syllabary"
    },
    {
      "id": 1606,
      "kana": "こしょう",
      "kanji": "故障",
      "type": "n,vs",
      "meaning": "break-down, failure, accident, out of order"
    },
    {
      "id": 1607,
      "kana": "こしょう",
      "kanji": "胡椒",
      "type": "n",
      "meaning": "pepper"
    },
    {
      "id": 1608,
      "kana": "こしらえる",
      "kanji": "",
      "type": "v1",
      "meaning": "to make, to manufacture"
    },
    {
      "id": 1609,
      "kana": "こじん",
      "kanji": "個人",
      "type": "adj-no,n",
      "meaning": "individual, private person, personal, private"
    },
    {
      "id": 1610,
      "kana": "こす",
      "kanji": "越す",
      "type": "v5s",
      "meaning": "to go over (e.g. with audience)"
    },
    {
      "id": 1611,
      "kana": "こす",
      "kanji": "超す",
      "type": "v5s",
      "meaning": "to cross, to pass, to tide over"
    },
    {
      "id": 1612,
      "kana": "こする",
      "kanji": "擦る",
      "type": "v5r",
      "meaning": "to rub, to chafe, to file, to frost (glass), to strike (match)"
    },
    {
      "id": 1613,
      "kana": "ごぜん",
      "kanji": "午前",
      "type": "n-adv,n-t",
      "meaning": "morning, A.M."
    },
    {
      "id": 1614,
      "kana": "ごぞんじですか",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1615,
      "kana": "こたい",
      "kanji": "個体",
      "type": "n",
      "meaning": "an individual"
    },
    {
      "id": 1616,
      "kana": "こたえ",
      "kanji": "答",
      "type": "n",
      "meaning": "answer, response"
    },
    {
      "id": 1617,
      "kana": "こたえる",
      "kanji": "答える",
      "type": "v1",
      "meaning": "to answer, to reply"
    },
    {
      "id": 1618,
      "kana": "ごちそう",
      "kanji": "御馳走",
      "type": "n,vs",
      "meaning": "feast, treating (someone)"
    },
    {
      "id": 1619,
      "kana": "ごちそうさま",
      "kanji": "",
      "type": "int",
      "meaning": "feast"
    },
    {
      "id": 1620,
      "kana": "こちら",
      "kanji": "",
      "type": "n",
      "meaning": "(1) this person, (2) this direction, (3) this side"
    },
    {
      "id": 1621,
      "kana": "こっち",
      "kanji": "",
      "type": "n",
      "meaning": "(1) this person, (2) this direction, (3) this side"
    },
    {
      "id": 1622,
      "kana": "こちらこそ",
      "kanji": "",
      "type": "exp",
      "meaning": "it is I who should say so"
    },
    {
      "id": 1623,
      "kana": "こっか",
      "kanji": "国家",
      "type": "n",
      "meaning": "state, country, nation"
    },
    {
      "id": 1624,
      "kana": "こっかい",
      "kanji": "国会",
      "type": "n",
      "meaning": "National Diet, parliament, congress"
    },
    {
      "id": 1625,
      "kana": "こづかい",
      "kanji": "小遣い",
      "type": "n",
      "meaning": "personal expenses, pocket money, spending money, incidental expenses, allowance"
    },
    {
      "id": 1626,
      "kana": "こっきょう",
      "kanji": "国境",
      "type": "n",
      "meaning": "national or state border"
    },
    {
      "id": 1627,
      "kana": "コック",
      "kanji": "",
      "type": "n",
      "meaning": "(1) cook (nl:), (2) tap, spigot, faucet, cock"
    },
    {
      "id": 1628,
      "kana": "こっせつ",
      "kanji": "骨折",
      "type": "n",
      "meaning": "bone fracture"
    },
    {
      "id": 1629,
      "kana": "こっそり",
      "kanji": "",
      "type": "adv",
      "meaning": "stealthily, secretly"
    },
    {
      "id": 1630,
      "kana": "こづつみ",
      "kanji": "小包",
      "type": "n",
      "meaning": "parcel, package"
    },
    {
      "id": 1631,
      "kana": "コップ",
      "kanji": "",
      "type": "n",
      "meaning": "(a) glass (nl: Kop)"
    },
    {
      "id": 1632,
      "kana": "こてん",
      "kanji": "古典",
      "type": "n",
      "meaning": "old book, classics, classic"
    },
    {
      "id": 1633,
      "kana": "こと",
      "kanji": "事",
      "type": "n",
      "meaning": "thing, matter, fact, circumstances"
    },
    {
      "id": 1634,
      "kana": "こと",
      "kanji": "琴",
      "type": "n",
      "meaning": "Koto (Japanese harp)"
    },
    {
      "id": 1635,
      "kana": "~ごと",
      "kanji": "~毎",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1636,
      "kana": "（まるごと）",
      "kanji": "~ごと",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1637,
      "kana": "ことし",
      "kanji": "今年",
      "type": "n-adv,n-t",
      "meaning": "this year"
    },
    {
      "id": 1638,
      "kana": "ことづける",
      "kanji": "言付ける",
      "type": "v1",
      "meaning": "to send word, to send a message"
    },
    {
      "id": 1639,
      "kana": "ことなる",
      "kanji": "異なる",
      "type": "v5r",
      "meaning": "to differ, to vary, to disagree"
    },
    {
      "id": 1640,
      "kana": "ことば",
      "kanji": "言葉",
      "type": "n",
      "meaning": "word(s), language, speech"
    },
    {
      "id": 1641,
      "kana": "ことばづかい",
      "kanji": "言葉遣い",
      "type": "n",
      "meaning": "speech, expression, wording"
    },
    {
      "id": 1642,
      "kana": "こども",
      "kanji": "子供",
      "type": "n",
      "meaning": "child, children"
    },
    {
      "id": 1643,
      "kana": "ことり",
      "kanji": "小鳥",
      "type": "n",
      "meaning": "small bird"
    },
    {
      "id": 1644,
      "kana": "ことわざ",
      "kanji": "諺",
      "type": "n",
      "meaning": "proverb, maxim"
    },
    {
      "id": 1645,
      "kana": "ことわる",
      "kanji": "断る",
      "type": "v5r",
      "meaning": "to refuse, to decline, to dismiss"
    },
    {
      "id": 1646,
      "kana": "こな",
      "kanji": "粉",
      "type": "n",
      "meaning": "flour, meal, powder"
    },
    {
      "id": 1647,
      "kana": "この",
      "kanji": "この",
      "type": "adj-pn,int",
      "meaning": "this"
    },
    {
      "id": 1648,
      "kana": "このあいだ",
      "kanji": "",
      "type": "n-adv",
      "meaning": "the other day, recently, lately, during this period"
    },
    {
      "id": 1649,
      "kana": "こないだ",
      "kanji": "",
      "type": "n-adv,n-t",
      "meaning": "the other day, lately, recently"
    },
    {
      "id": 1650,
      "kana": "このごろ",
      "kanji": "",
      "type": "",
      "meaning": "these days, nowadays"
    },
    {
      "id": 1651,
      "kana": "このみ",
      "kanji": "好み",
      "type": "n",
      "meaning": "liking, taste, choice"
    },
    {
      "id": 1652,
      "kana": "このむ",
      "kanji": "好む",
      "type": "v5m",
      "meaning": "to like, to prefer"
    },
    {
      "id": 1653,
      "kana": "ごはん",
      "kanji": "御飯",
      "type": "n",
      "meaning": "rice (cooked), meal"
    },
    {
      "id": 1654,
      "kana": "コピー",
      "kanji": "",
      "type": "n",
      "meaning": "(1) a (photo)copy, (2) blurb on a book jacket"
    },
    {
      "id": 1655,
      "kana": "ごぶさた",
      "kanji": "御無沙汰",
      "type": "n,vs",
      "meaning": "not writing or contacting for a while"
    },
    {
      "id": 1656,
      "kana": "こぼす",
      "kanji": "",
      "type": "v5s,vt",
      "meaning": "to spill"
    },
    {
      "id": 1657,
      "kana": "こぼれる",
      "kanji": "",
      "type": "v1,vi",
      "meaning": "to overflow, to spill"
    },
    {
      "id": 1658,
      "kana": "こまかい",
      "kanji": "細かい",
      "type": "adj",
      "meaning": "(1) small, (2) fine, minute"
    },
    {
      "id": 1659,
      "kana": "こまる",
      "kanji": "困る",
      "type": "v5r",
      "meaning": "to be worried, to be bothered"
    },
    {
      "id": 1660,
      "kana": "ごみ",
      "kanji": "塵",
      "type": "n",
      "meaning": "dust, dirt"
    },
    {
      "id": 1661,
      "kana": "ごみ",
      "kanji": "塵芥",
      "type": "n",
      "meaning": "trash, rubbish"
    },
    {
      "id": 1662,
      "kana": "~こむ",
      "kanji": "~込む",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1663,
      "kana": "ゴム",
      "kanji": "",
      "type": "n",
      "meaning": "gum, rubber, eraser"
    },
    {
      "id": 1664,
      "kana": "こむぎ",
      "kanji": "小麦",
      "type": "n",
      "meaning": "wheat"
    },
    {
      "id": 1665,
      "kana": "こめ",
      "kanji": "米",
      "type": "n",
      "meaning": "uncooked rice"
    },
    {
      "id": 1666,
      "kana": "ごめん",
      "kanji": "御免",
      "type": "int,n",
      "meaning": "your pardon, declining (something), dismissal, permission"
    },
    {
      "id": 1667,
      "kana": "ごめんください",
      "kanji": "",
      "type": "exp,int",
      "meaning": "May I come in?"
    },
    {
      "id": 1668,
      "kana": "ごめんなさい",
      "kanji": "",
      "type": "exp,int",
      "meaning": "I beg your pardon, excuse me"
    },
    {
      "id": 1669,
      "kana": "こや",
      "kanji": "小屋",
      "type": "n",
      "meaning": "hut, cabin, shed, (animal) pen"
    },
    {
      "id": 1670,
      "kana": "こゆび",
      "kanji": "小指",
      "type": "n",
      "meaning": "little finger"
    },
    {
      "id": 1671,
      "kana": "こらえる",
      "kanji": "",
      "type": "v1",
      "meaning": "to bear, to stand, to endure, to put up with"
    },
    {
      "id": 1672,
      "kana": "ごらく",
      "kanji": "娯楽",
      "type": "n",
      "meaning": "pleasure, amusement"
    },
    {
      "id": 1673,
      "kana": "ごらん",
      "kanji": "御覧",
      "type": "int,n",
      "meaning": "(hon) look, inspection, try"
    },
    {
      "id": 1674,
      "kana": "これ",
      "kanji": "",
      "type": "int,n",
      "meaning": "this"
    },
    {
      "id": 1675,
      "kana": "コレクション",
      "kanji": "",
      "type": "n",
      "meaning": "(1) collection, (2) correction"
    },
    {
      "id": 1676,
      "kana": "これら",
      "kanji": "",
      "type": "uk",
      "meaning": "these"
    },
    {
      "id": 1677,
      "kana": "ころ",
      "kanji": "頃",
      "type": "n-adv,n,n-suf",
      "meaning": "time, about, toward, approximately (time)"
    },
    {
      "id": 1678,
      "kana": "ころがす",
      "kanji": "転がす",
      "type": "v5s,vt",
      "meaning": "to roll"
    },
    {
      "id": 1679,
      "kana": "ころがる",
      "kanji": "転がる",
      "type": "v5r",
      "meaning": "to roll, to tumble"
    },
    {
      "id": 1680,
      "kana": "ころす",
      "kanji": "殺す",
      "type": "v5s",
      "meaning": "to kill"
    },
    {
      "id": 1681,
      "kana": "ころぶ",
      "kanji": "転ぶ",
      "type": "v5b",
      "meaning": "to fall down, to fall over"
    },
    {
      "id": 1682,
      "kana": "こわい",
      "kanji": "怖い",
      "type": "adj",
      "meaning": "scary, frightening, eerie, dreadful"
    },
    {
      "id": 1683,
      "kana": "こわす",
      "kanji": "壊す",
      "type": "v5s,vt",
      "meaning": "to break, to break down"
    },
    {
      "id": 1684,
      "kana": "こわれる",
      "kanji": "壊れる",
      "type": "v1,vi",
      "meaning": "to be broken, to break"
    },
    {
      "id": 1685,
      "kana": "こん",
      "kanji": "紺",
      "type": "n",
      "meaning": "navy blue, deep blue"
    },
    {
      "id": 1686,
      "kana": "こん~",
      "kanji": "今~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1687,
      "kana": "こんかい",
      "kanji": "今回",
      "type": "n-adv,n-t",
      "meaning": "now, this time, lately"
    },
    {
      "id": 1688,
      "kana": "コンクール",
      "kanji": "",
      "type": "n",
      "meaning": "contest (fr: concours)"
    },
    {
      "id": 1689,
      "kana": "コンクリート",
      "kanji": "",
      "type": "n",
      "meaning": "concrete"
    },
    {
      "id": 1690,
      "kana": "こんご",
      "kanji": "今後",
      "type": "n-adv,n-t",
      "meaning": "from now on, hereafter"
    },
    {
      "id": 1691,
      "kana": "こんごう",
      "kanji": "混合",
      "type": "n,vs",
      "meaning": "mixing, mixture"
    },
    {
      "id": 1692,
      "kana": "コンサート",
      "kanji": "",
      "type": "n",
      "meaning": "concert"
    },
    {
      "id": 1693,
      "kana": "こんざつ",
      "kanji": "混雑",
      "type": "n,vs",
      "meaning": "confusion, congestion"
    },
    {
      "id": 1694,
      "kana": "コンセント",
      "kanji": "",
      "type": "n",
      "meaning": "(1) consent, (2) concentric"
    },
    {
      "id": 1695,
      "kana": "こんだて",
      "kanji": "献立",
      "type": "n",
      "meaning": "menu, program, schedule"
    },
    {
      "id": 1696,
      "kana": "こんど",
      "kanji": "今度",
      "type": "n-adv,n-t",
      "meaning": "now, this time, next time, another time"
    },
    {
      "id": 1697,
      "kana": "こんな",
      "kanji": "",
      "type": "adj-na,adj-pn,adv,n",
      "meaning": "such, like this"
    },
    {
      "id": 1698,
      "kana": "こんなに",
      "kanji": "",
      "type": "n",
      "meaning": "so, like this, in this way"
    },
    {
      "id": 1699,
      "kana": "こんなん",
      "kanji": "困難",
      "type": "adj-na,n",
      "meaning": "difficulty, distress"
    },
    {
      "id": 1700,
      "kana": "こんにち",
      "kanji": "今日",
      "type": "n-t",
      "meaning": "today, this day"
    },
    {
      "id": 1701,
      "kana": "こんにちは",
      "kanji": "",
      "type": "int",
      "meaning": "hello, good day (daytime greeting, id)"
    },
    {
      "id": 1702,
      "kana": "こんばんは",
      "kanji": "",
      "type": "exp,int",
      "meaning": "good evening"
    },
    {
      "id": 1703,
      "kana": "コンピューター",
      "kanji": "",
      "type": "n",
      "meaning": "computer"
    },
    {
      "id": 1704,
      "kana": "こんやく",
      "kanji": "婚約",
      "type": "n,vs",
      "meaning": "engagement, betrothal"
    },
    {
      "id": 1705,
      "kana": "こんらん",
      "kanji": "混乱",
      "type": "n,vs",
      "meaning": "disorder, chaos, confusion, mayhem"
    },
    {
      "id": 1706,
      "kana": "さ",
      "kanji": "差",
      "type": "n,n-suf",
      "meaning": "difference, variation"
    },
    {
      "id": 1707,
      "kana": "さあ",
      "kanji": "",
      "type": "conj,int",
      "meaning": "come (int), come now"
    },
    {
      "id": 1708,
      "kana": "サークル",
      "kanji": "",
      "type": "n",
      "meaning": "circle, sports club (i.e. at a company)"
    },
    {
      "id": 1709,
      "kana": "サービス",
      "kanji": "",
      "type": "n",
      "meaning": "(1) service, support system, (2) goods or services without charge"
    },
    {
      "id": 1710,
      "kana": "さい",
      "kanji": "際",
      "type": "n-adv,n",
      "meaning": "on the occasion of, circumstances"
    },
    {
      "id": 1711,
      "kana": "さい~",
      "kanji": "再~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1712,
      "kana": "さい~",
      "kanji": "最~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1713,
      "kana": "~さい",
      "kanji": "~歳",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1714,
      "kana": "~さい",
      "kanji": "~祭",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1715,
      "kana": "ざいがく",
      "kanji": "在学",
      "type": "n",
      "meaning": "(enrolled) in school"
    },
    {
      "id": 1716,
      "kana": "さいきん",
      "kanji": "最近",
      "type": "adj-no,n-adv,n-t",
      "meaning": "latest, most recent, nowadays"
    },
    {
      "id": 1717,
      "kana": "さいご",
      "kanji": "最後",
      "type": "n",
      "meaning": "last, end, conclusion"
    },
    {
      "id": 1718,
      "kana": "さいこう",
      "kanji": "最高",
      "type": "adj-na,n",
      "meaning": "highest, supreme, the most"
    },
    {
      "id": 1719,
      "kana": "さいさん",
      "kanji": "再三",
      "type": "adv,n",
      "meaning": "again and again, repeatedly"
    },
    {
      "id": 1720,
      "kana": "ざいさん",
      "kanji": "財産",
      "type": "n",
      "meaning": "property, fortune, assets"
    },
    {
      "id": 1721,
      "kana": "さいじつ",
      "kanji": "祭日",
      "type": "n",
      "meaning": "national holiday, festival day"
    },
    {
      "id": 1722,
      "kana": "さいしゅう",
      "kanji": "最終",
      "type": "n",
      "meaning": "last, final, closing"
    },
    {
      "id": 1723,
      "kana": "さいしょ",
      "kanji": "最初",
      "type": "adj-no,n-adv,n-t",
      "meaning": "beginning, outset, first, onset"
    },
    {
      "id": 1724,
      "kana": "さいそく",
      "kanji": "催促",
      "type": "n,vs",
      "meaning": "request, demand, claim, urge (action), press for"
    },
    {
      "id": 1725,
      "kana": "さいちゅう",
      "kanji": "最中",
      "type": "n-adv,n",
      "meaning": "in the middle of"
    },
    {
      "id": 1726,
      "kana": "さいてい",
      "kanji": "最低",
      "type": "adj-na,adv,n",
      "meaning": "least, lowest, worst"
    },
    {
      "id": 1727,
      "kana": "さいてん",
      "kanji": "採点",
      "type": "n,vs",
      "meaning": "marking, grading, looking over"
    },
    {
      "id": 1728,
      "kana": "さいなん",
      "kanji": "災難",
      "type": "n",
      "meaning": "calamity, misfortune"
    },
    {
      "id": 1729,
      "kana": "さいのう",
      "kanji": "才能",
      "type": "n",
      "meaning": "talent, ability"
    },
    {
      "id": 1730,
      "kana": "さいばん",
      "kanji": "裁判",
      "type": "n,vs",
      "meaning": "trial, judgement"
    },
    {
      "id": 1731,
      "kana": "さいふ",
      "kanji": "財布",
      "type": "n",
      "meaning": "purse, wallet"
    },
    {
      "id": 1732,
      "kana": "さいほう",
      "kanji": "裁縫",
      "type": "n",
      "meaning": "sewing"
    },
    {
      "id": 1733,
      "kana": "ざいもく",
      "kanji": "材木",
      "type": "n",
      "meaning": "lumber, timber"
    },
    {
      "id": 1734,
      "kana": "ざいりょう",
      "kanji": "材料",
      "type": "n",
      "meaning": "ingredients, material"
    },
    {
      "id": 1735,
      "kana": "サイレン",
      "kanji": "",
      "type": "n",
      "meaning": "siren"
    },
    {
      "id": 1736,
      "kana": "さいわい",
      "kanji": "幸い",
      "type": "adj-na,adv,n",
      "meaning": "happiness, blessedness"
    },
    {
      "id": 1737,
      "kana": "サイン",
      "kanji": "",
      "type": "n",
      "meaning": "(1) autograph, (2) sign, (3) sine"
    },
    {
      "id": 1738,
      "kana": "さか",
      "kanji": "坂",
      "type": "n",
      "meaning": "slope, hill"
    },
    {
      "id": 1739,
      "kana": "さかい",
      "kanji": "境",
      "type": "n",
      "meaning": "border, boundary, mental state"
    },
    {
      "id": 1740,
      "kana": "さかさ",
      "kanji": "逆さ",
      "type": "n",
      "meaning": "reverse, inversion, upside down"
    },
    {
      "id": 1741,
      "kana": "さかさま",
      "kanji": "逆様",
      "type": "adj-na,adj-no,n",
      "meaning": "inversion, upside down"
    },
    {
      "id": 1742,
      "kana": "さがす",
      "kanji": "捜す",
      "type": "v5s",
      "meaning": "to search, to seek, to look for"
    },
    {
      "id": 1743,
      "kana": "さがす",
      "kanji": "探す",
      "type": "v5s",
      "meaning": "to search, to seek, to look for"
    },
    {
      "id": 1744,
      "kana": "さかな",
      "kanji": "魚",
      "type": "n",
      "meaning": "fish"
    },
    {
      "id": 1745,
      "kana": "さかのぼる",
      "kanji": "遡る",
      "type": "v5r",
      "meaning": "to go back, to go upstream, to make retroactive"
    },
    {
      "id": 1746,
      "kana": "さかば",
      "kanji": "酒場",
      "type": "n",
      "meaning": "bar, bar-room"
    },
    {
      "id": 1747,
      "kana": "さからう",
      "kanji": "逆らう",
      "type": "v5u",
      "meaning": "to go against, to oppose, to disobey, to defy"
    },
    {
      "id": 1748,
      "kana": "さかり",
      "kanji": "盛り",
      "type": "n",
      "meaning": "helping, serving"
    },
    {
      "id": 1749,
      "kana": "さがる",
      "kanji": "下る",
      "type": "v5r",
      "meaning": "to get down, to descend"
    },
    {
      "id": 1750,
      "kana": "さかん",
      "kanji": "盛ん",
      "type": "adj-na,n",
      "meaning": "popularity, prosperous"
    },
    {
      "id": 1751,
      "kana": "さき",
      "kanji": "先",
      "type": "adj-no,n",
      "meaning": "the future, priority, precedence, former, previous"
    },
    {
      "id": 1752,
      "kana": "さきおととい",
      "kanji": "",
      "type": "n-adv,n-t",
      "meaning": "two days before yesterday"
    },
    {
      "id": 1753,
      "kana": "さきほど",
      "kanji": "先程",
      "type": "n-adv,n-t",
      "meaning": "some time ago"
    },
    {
      "id": 1754,
      "kana": "さぎょう",
      "kanji": "作業",
      "type": "n",
      "meaning": "work, operation, manufacturing, fatigue duty"
    },
    {
      "id": 1755,
      "kana": "さく",
      "kanji": "咲く",
      "type": "v5k",
      "meaning": "to bloom"
    },
    {
      "id": 1756,
      "kana": "さく",
      "kanji": "裂く",
      "type": "v5k",
      "meaning": "to tear, to split"
    },
    {
      "id": 1757,
      "kana": "さく",
      "kanji": "昨",
      "type": "pref",
      "meaning": "last (year), yesterday"
    },
    {
      "id": 1758,
      "kana": "さくいん",
      "kanji": "索引",
      "type": "n",
      "meaning": "index, indices"
    },
    {
      "id": 1759,
      "kana": "さくしゃ",
      "kanji": "作者",
      "type": "n",
      "meaning": "author, authoress"
    },
    {
      "id": 1760,
      "kana": "さくじょ",
      "kanji": "削除",
      "type": "n,vs",
      "meaning": "elimination, cancellation, deletion, erasure"
    },
    {
      "id": 1761,
      "kana": "さくせい",
      "kanji": "作成",
      "type": "n,vs",
      "meaning": "frame, draw up, make, producing"
    },
    {
      "id": 1762,
      "kana": "さくせい",
      "kanji": "作製",
      "type": "n,vs",
      "meaning": "manufacture"
    },
    {
      "id": 1763,
      "kana": "さくひん",
      "kanji": "作品",
      "type": "n",
      "meaning": "work, opus, performance, production"
    },
    {
      "id": 1764,
      "kana": "さくぶん",
      "kanji": "作文",
      "type": "n",
      "meaning": "composition, writing"
    },
    {
      "id": 1765,
      "kana": "さくもつ",
      "kanji": "作物",
      "type": "n",
      "meaning": "produce (e.g. agricultural), crops"
    },
    {
      "id": 1766,
      "kana": "さくら",
      "kanji": "桜",
      "type": "n",
      "meaning": "cherry blossom, cherry tree"
    },
    {
      "id": 1767,
      "kana": "さぐる",
      "kanji": "探る",
      "type": "v5r",
      "meaning": "to search, to look for, to sound out"
    },
    {
      "id": 1768,
      "kana": "さけ",
      "kanji": "酒",
      "type": "n",
      "meaning": "alcohol, sake"
    },
    {
      "id": 1769,
      "kana": "さけぶ",
      "kanji": "叫ぶ",
      "type": "v5b",
      "meaning": "to shout, to cry"
    },
    {
      "id": 1770,
      "kana": "さける",
      "kanji": "避ける",
      "type": "v1",
      "meaning": "(1) to avoid (physical contact ), (2) to ward off, to avert"
    },
    {
      "id": 1771,
      "kana": "さげる",
      "kanji": "下げる",
      "type": "v1",
      "meaning": "to hang, to lower, to move back"
    },
    {
      "id": 1772,
      "kana": "ささえる",
      "kanji": "支える",
      "type": "v1",
      "meaning": "to be blocked, to choke, to be obstructed"
    },
    {
      "id": 1773,
      "kana": "ささやく",
      "kanji": "囁く",
      "type": "v5k",
      "meaning": "to whisper, to murmur"
    },
    {
      "id": 1774,
      "kana": "ささる",
      "kanji": "刺さる",
      "type": "v5r",
      "meaning": "to stick, to be stuck"
    },
    {
      "id": 1775,
      "kana": "さじ",
      "kanji": "匙",
      "type": "n",
      "meaning": "spoon"
    },
    {
      "id": 1776,
      "kana": "さしあげる",
      "kanji": "差し上げる",
      "type": "v1",
      "meaning": "(pol) to give, to hold up, to lift up, to offer"
    },
    {
      "id": 1777,
      "kana": "ざしき",
      "kanji": "座敷",
      "type": "n",
      "meaning": "tatami room"
    },
    {
      "id": 1778,
      "kana": "さしつかえ",
      "kanji": "差し支え",
      "type": "n",
      "meaning": "hindrance, impediment"
    },
    {
      "id": 1779,
      "kana": "さしひき",
      "kanji": "差し引き",
      "type": "n",
      "meaning": "deduction, subtraction, balance, ebb and flow, rise and fall"
    },
    {
      "id": 1780,
      "kana": "さしみ",
      "kanji": "刺身",
      "type": "n",
      "meaning": "sliced raw fish"
    },
    {
      "id": 1781,
      "kana": "さす",
      "kanji": "刺す",
      "type": "v5s",
      "meaning": "to pierce, to stab, to prick, to thrust"
    },
    {
      "id": 1782,
      "kana": "さす",
      "kanji": "差す",
      "type": "v5s",
      "meaning": "to raise (stretch out) hands, to raise umbrella"
    },
    {
      "id": 1783,
      "kana": "さす",
      "kanji": "指す",
      "type": "v5s",
      "meaning": "to point, to put up umbrella, to play"
    },
    {
      "id": 1784,
      "kana": "さす",
      "kanji": "挿す",
      "type": "v5s",
      "meaning": "to insert, to put in, to graft, to wear in belt"
    },
    {
      "id": 1785,
      "kana": "さす",
      "kanji": "注す",
      "type": "v5s",
      "meaning": "to pour (drink), to serve (drinks)"
    },
    {
      "id": 1786,
      "kana": "さす",
      "kanji": "射す",
      "type": "v5s",
      "meaning": "to shine, to strike"
    },
    {
      "id": 1787,
      "kana": "（傘を~）",
      "kanji": "さす",
      "type": "v5s",
      "meaning": "to light (a fire), to apply moxa cautery"
    },
    {
      "id": 1788,
      "kana": "さすが",
      "kanji": "流石",
      "type": "adj-na,adv,n",
      "meaning": "clever, adept, good, expectations, as one would expect"
    },
    {
      "id": 1789,
      "kana": "ざせき",
      "kanji": "座席",
      "type": "n",
      "meaning": "seat"
    },
    {
      "id": 1790,
      "kana": "さそう",
      "kanji": "誘う",
      "type": "v5u",
      "meaning": "(1) to invite, to ask, (2) to tempt, to lure, to induce"
    },
    {
      "id": 1791,
      "kana": "さつ",
      "kanji": "札",
      "type": "n",
      "meaning": "(1) token, label, (2) ticket, (3) charm"
    },
    {
      "id": 1792,
      "kana": "~さつ",
      "kanji": "~冊",
      "type": "",
      "meaning": "counter for volumes"
    },
    {
      "id": 1793,
      "kana": "さつえい",
      "kanji": "撮影",
      "type": "n,vs",
      "meaning": "photographing"
    },
    {
      "id": 1794,
      "kana": "ざつおん",
      "kanji": "雑音",
      "type": "n",
      "meaning": "noise (jarring, grating)"
    },
    {
      "id": 1795,
      "kana": "さっか",
      "kanji": "作家",
      "type": "n",
      "meaning": "author, writer, novelist, artist"
    },
    {
      "id": 1796,
      "kana": "さっき",
      "kanji": "",
      "type": "n",
      "meaning": "thirst for blood"
    },
    {
      "id": 1797,
      "kana": "さっきょく",
      "kanji": "作曲",
      "type": "n,vs",
      "meaning": "composition, setting (of music)"
    },
    {
      "id": 1798,
      "kana": "さっさと",
      "kanji": "",
      "type": "adv,n",
      "meaning": "quickly"
    },
    {
      "id": 1799,
      "kana": "ざっし",
      "kanji": "雑誌",
      "type": "n",
      "meaning": "journal, magazine"
    },
    {
      "id": 1800,
      "kana": "さっそく",
      "kanji": "早速",
      "type": "adv",
      "meaning": "at once, immediately, without delay, promptly"
    },
    {
      "id": 1801,
      "kana": "ざっと",
      "kanji": "",
      "type": "adv",
      "meaning": "roughly, in round numbers"
    },
    {
      "id": 1802,
      "kana": "さっぱり",
      "kanji": "",
      "type": "adj-na,adv,n,vs",
      "meaning": "feeling refreshed, feeling relieved, neat, trimmed"
    },
    {
      "id": 1803,
      "kana": "さて",
      "kanji": "",
      "type": "conj,int (uk)",
      "meaning": "well, now, then"
    },
    {
      "id": 1804,
      "kana": "さとう",
      "kanji": "砂糖",
      "type": "n",
      "meaning": "sugar"
    },
    {
      "id": 1805,
      "kana": "さばく",
      "kanji": "砂漠",
      "type": "n",
      "meaning": "desert"
    },
    {
      "id": 1806,
      "kana": "さび",
      "kanji": "錆",
      "type": "n",
      "meaning": "rust (colour)"
    },
    {
      "id": 1807,
      "kana": "さびしい",
      "kanji": "寂しい",
      "type": "adj",
      "meaning": "lonely, lonesome, solitary, desolate"
    },
    {
      "id": 1808,
      "kana": "さびる",
      "kanji": "錆びる",
      "type": "v1,vi",
      "meaning": "to rust, to become rusty"
    },
    {
      "id": 1809,
      "kana": "ざぶとん",
      "kanji": "座布団",
      "type": "n",
      "meaning": "cushion (Japanese)"
    },
    {
      "id": 1810,
      "kana": "さべつ",
      "kanji": "差別",
      "type": "n,vs",
      "meaning": "discrimination, distinction, differentiation"
    },
    {
      "id": 1811,
      "kana": "さほう",
      "kanji": "作法",
      "type": "n",
      "meaning": "manners, etiquette, propriety"
    },
    {
      "id": 1812,
      "kana": "~さま",
      "kanji": "~様",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1813,
      "kana": "さまざま",
      "kanji": "様々",
      "type": "adj-na,n",
      "meaning": "varied, various"
    },
    {
      "id": 1814,
      "kana": "さます",
      "kanji": "冷ます",
      "type": "v5s,vt",
      "meaning": "to cool, to dampen, to let cool"
    },
    {
      "id": 1815,
      "kana": "さます",
      "kanji": "覚ます",
      "type": "v5s",
      "meaning": "to awaken"
    },
    {
      "id": 1816,
      "kana": "さまたげる",
      "kanji": "妨げる",
      "type": "v1",
      "meaning": "to disturb, to prevent"
    },
    {
      "id": 1817,
      "kana": "さむい",
      "kanji": "寒い",
      "type": "adj",
      "meaning": "cold (e.g. weather)"
    },
    {
      "id": 1818,
      "kana": "さめる",
      "kanji": "冷める",
      "type": "v1",
      "meaning": "to become cool, to wear off, to abate, to subside"
    },
    {
      "id": 1819,
      "kana": "さめる",
      "kanji": "覚める",
      "type": "v1",
      "meaning": "to wake, to wake up"
    },
    {
      "id": 1820,
      "kana": "さゆう",
      "kanji": "左右",
      "type": "n,vs",
      "meaning": "(1) left and right, (2) influence, control, domination"
    },
    {
      "id": 1821,
      "kana": "さようなら",
      "kanji": "",
      "type": "conj,int",
      "meaning": "good-bye"
    },
    {
      "id": 1822,
      "kana": "さら",
      "kanji": "皿",
      "type": "n",
      "meaning": "plate, dish"
    },
    {
      "id": 1823,
      "kana": "さらいげつ",
      "kanji": "再来月",
      "type": "n-adv,n-t",
      "meaning": "month after next"
    },
    {
      "id": 1824,
      "kana": "さらいしゅう",
      "kanji": "再来週",
      "type": "n-adv,n-t",
      "meaning": "week after next"
    },
    {
      "id": 1825,
      "kana": "さらいねん",
      "kanji": "再来年",
      "type": "n-adv,n-t",
      "meaning": "year after next"
    },
    {
      "id": 1826,
      "kana": "サラダ",
      "kanji": "",
      "type": "n",
      "meaning": "salad"
    },
    {
      "id": 1827,
      "kana": "さらに",
      "kanji": "更に",
      "type": "adv,conj",
      "meaning": "furthermore, again, after all, more and more, moreover"
    },
    {
      "id": 1828,
      "kana": "サラリーマン",
      "kanji": "",
      "type": "n",
      "meaning": "salary man, company employee"
    },
    {
      "id": 1829,
      "kana": "さる",
      "kanji": "去る",
      "type": "v5r",
      "meaning": "to leave, to go away"
    },
    {
      "id": 1830,
      "kana": "さる",
      "kanji": "猿",
      "type": "n",
      "meaning": "monkey"
    },
    {
      "id": 1831,
      "kana": "さわがしい",
      "kanji": "騒がしい",
      "type": "adj",
      "meaning": "noisy"
    },
    {
      "id": 1832,
      "kana": "さわぎ",
      "kanji": "騒ぎ",
      "type": "n,n-suf",
      "meaning": "uproar, disturbance"
    },
    {
      "id": 1833,
      "kana": "さわぐ",
      "kanji": "騒ぐ",
      "type": "v5g",
      "meaning": "to make noise, to clamor, to be excited, to be alarmed"
    },
    {
      "id": 1834,
      "kana": "さわやか",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "fresh, refreshing, invigorating"
    },
    {
      "id": 1835,
      "kana": "さわる",
      "kanji": "触る",
      "type": "v5r",
      "meaning": "to touch, to feel"
    },
    {
      "id": 1836,
      "kana": "さん",
      "kanji": "三",
      "type": "num",
      "meaning": "three"
    },
    {
      "id": 1837,
      "kana": "~さん",
      "kanji": "~山",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1838,
      "kana": "~さん",
      "kanji": "~産",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1839,
      "kana": "~さん",
      "kanji": "~様",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1840,
      "kana": "さんか",
      "kanji": "参加",
      "type": "n,vs",
      "meaning": "participation"
    },
    {
      "id": 1841,
      "kana": "さんかく",
      "kanji": "三角",
      "type": "n",
      "meaning": "triangle, triangular"
    },
    {
      "id": 1842,
      "kana": "さんぎょう",
      "kanji": "産業",
      "type": "n",
      "meaning": "industry"
    },
    {
      "id": 1843,
      "kana": "さんこう",
      "kanji": "参考",
      "type": "n,vs",
      "meaning": "reference, consultation"
    },
    {
      "id": 1844,
      "kana": "さんすう",
      "kanji": "算数",
      "type": "n",
      "meaning": "arithmetic"
    },
    {
      "id": 1845,
      "kana": "Uӣ[い",
      "kanji": "賛成",
      "type": "n,vs",
      "meaning": "approval, agreement, support, favour"
    },
    {
      "id": 1846,
      "kana": "さんせい",
      "kanji": "酸性",
      "type": "n",
      "meaning": "acidity"
    },
    {
      "id": 1847,
      "kana": "さんそ",
      "kanji": "酸素",
      "type": "n",
      "meaning": "oxygen"
    },
    {
      "id": 1848,
      "kana": "サンダル",
      "kanji": "",
      "type": "n",
      "meaning": "sandal"
    },
    {
      "id": 1849,
      "kana": "さんち",
      "kanji": "産地",
      "type": "n",
      "meaning": "producing area"
    },
    {
      "id": 1850,
      "kana": "サンドイッチ",
      "kanji": "",
      "type": "n",
      "meaning": "sandwich"
    },
    {
      "id": 1851,
      "kana": "ざんねん",
      "kanji": "残念",
      "type": "adj-na,n",
      "meaning": "deplorable, bad luck, regret, disappointment"
    },
    {
      "id": 1852,
      "kana": "サンプル",
      "kanji": "",
      "type": "n",
      "meaning": "sample"
    },
    {
      "id": 1853,
      "kana": "さんぽ",
      "kanji": "散歩",
      "type": "n,vs",
      "meaning": "walk, stroll"
    },
    {
      "id": 1854,
      "kana": "さんりん",
      "kanji": "山林",
      "type": "n",
      "meaning": "mountain forest, mountains and forest"
    },
    {
      "id": 1855,
      "kana": "し",
      "kanji": "四",
      "type": "num",
      "meaning": "four"
    },
    {
      "id": 1856,
      "kana": "し",
      "kanji": "市",
      "type": "n-suf",
      "meaning": "city"
    },
    {
      "id": 1857,
      "kana": "し",
      "kanji": "氏",
      "type": "n",
      "meaning": "family name, lineage, birth"
    },
    {
      "id": 1858,
      "kana": "し",
      "kanji": "詩",
      "type": "n",
      "meaning": "poem, verse of poetry"
    },
    {
      "id": 1859,
      "kana": "~し",
      "kanji": "~史",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1860,
      "kana": "~し",
      "kanji": "~紙",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1861,
      "kana": "~じ",
      "kanji": "~時",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1862,
      "kana": "~じ",
      "kanji": "~寺",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1863,
      "kana": "しあい",
      "kanji": "試合",
      "type": "n",
      "meaning": "match, game, bout, contest"
    },
    {
      "id": 1864,
      "kana": "しあがる",
      "kanji": "仕上がる",
      "type": "v5r,vi",
      "meaning": "to be finished"
    },
    {
      "id": 1865,
      "kana": "しあさって",
      "kanji": "",
      "type": "n-adv,n-t",
      "meaning": "two days after tomorrow"
    },
    {
      "id": 1866,
      "kana": "しあわせ",
      "kanji": "幸せ",
      "type": "adj-na,n",
      "meaning": "happiness, good fortune, luck, blessing"
    },
    {
      "id": 1867,
      "kana": "シーズン",
      "kanji": "",
      "type": "n",
      "meaning": "season (sporting)"
    },
    {
      "id": 1868,
      "kana": "シーツ",
      "kanji": "",
      "type": "n",
      "meaning": "sheet"
    },
    {
      "id": 1869,
      "kana": "じいん",
      "kanji": "寺院",
      "type": "n",
      "meaning": "temple"
    },
    {
      "id": 1870,
      "kana": "（する）",
      "kanji": "しいんと",
      "type": "adv,n",
      "meaning": "silent (as the grave), (deathly) quiet"
    },
    {
      "id": 1871,
      "kana": "ジーンズ",
      "kanji": "",
      "type": "n",
      "meaning": "jeans"
    },
    {
      "id": 1872,
      "kana": "じえい",
      "kanji": "自衛",
      "type": "n",
      "meaning": "self-defense"
    },
    {
      "id": 1873,
      "kana": "ジェットき",
      "kanji": "ジェット機",
      "type": "n",
      "meaning": "jet aeroplane"
    },
    {
      "id": 1874,
      "kana": "しお",
      "kanji": "塩",
      "type": "n",
      "meaning": "salt"
    },
    {
      "id": 1875,
      "kana": "しおからい",
      "kanji": "塩辛い",
      "type": "adj",
      "meaning": "salty (taste)"
    },
    {
      "id": 1876,
      "kana": "しかい",
      "kanji": "司会",
      "type": "n,vs",
      "meaning": "chairmanship"
    },
    {
      "id": 1877,
      "kana": "しかく",
      "kanji": "四角",
      "type": "adj-na,n",
      "meaning": "square"
    },
    {
      "id": 1878,
      "kana": "しかくい",
      "kanji": "四角い",
      "type": "adj",
      "meaning": "square"
    },
    {
      "id": 1879,
      "kana": "しかし",
      "kanji": "",
      "type": "conj",
      "meaning": "however, but"
    },
    {
      "id": 1880,
      "kana": "しかた",
      "kanji": "仕方",
      "type": "n",
      "meaning": "way, method, means"
    },
    {
      "id": 1881,
      "kana": "しかたがない",
      "kanji": "",
      "type": "adj,exp",
      "meaning": "it can't be helped, it's inevitable"
    },
    {
      "id": 1882,
      "kana": "じかに",
      "kanji": "直に",
      "type": "adv",
      "meaning": "immediately, readily, directly"
    },
    {
      "id": 1883,
      "kana": "しかも",
      "kanji": "",
      "type": "conj",
      "meaning": "moreover, furthermore, nevertheless, and yet"
    },
    {
      "id": 1884,
      "kana": "しかる",
      "kanji": "叱る",
      "type": "v5r",
      "meaning": "to scold"
    },
    {
      "id": 1885,
      "kana": "じかん",
      "kanji": "時間",
      "type": "n-adv,n",
      "meaning": "time"
    },
    {
      "id": 1886,
      "kana": "~じかん",
      "kanji": "~時間",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1887,
      "kana": "~じかんめ",
      "kanji": "~時間目",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1888,
      "kana": "じかんわり",
      "kanji": "時間割",
      "type": "n",
      "meaning": "timetable, schedule"
    },
    {
      "id": 1889,
      "kana": "しき",
      "kanji": "式",
      "type": "n,n-suf",
      "meaning": "equation, formula, ceremony"
    },
    {
      "id": 1890,
      "kana": "（日本式）",
      "kanji": "~しき",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1891,
      "kana": "しき",
      "kanji": "四季",
      "type": "n",
      "meaning": "four seasons"
    },
    {
      "id": 1892,
      "kana": "じき",
      "kanji": "直",
      "type": "",
      "meaning": "earnestly, immediately, exactly"
    },
    {
      "id": 1893,
      "kana": "じき",
      "kanji": "時期",
      "type": "n-adv,n-t",
      "meaning": "time, season, period"
    },
    {
      "id": 1894,
      "kana": "しきち",
      "kanji": "敷地",
      "type": "n",
      "meaning": "site"
    },
    {
      "id": 1895,
      "kana": "しきゅう",
      "kanji": "支給",
      "type": "n",
      "meaning": "payment, allowance"
    },
    {
      "id": 1896,
      "kana": "しきゅう",
      "kanji": "至急",
      "type": "adv,n",
      "meaning": "urgent, pressing"
    },
    {
      "id": 1897,
      "kana": "しきりに",
      "kanji": "",
      "type": "adv",
      "meaning": "frequently, repeatedly, incessantly, eagerly"
    },
    {
      "id": 1898,
      "kana": "しく",
      "kanji": "敷く",
      "type": "v5k",
      "meaning": "to spread out, to lay out"
    },
    {
      "id": 1899,
      "kana": "しげき",
      "kanji": "刺激",
      "type": "n,vs",
      "meaning": "stimulus, impetus, incentive"
    },
    {
      "id": 1900,
      "kana": "しげる",
      "kanji": "茂る",
      "type": "v5r",
      "meaning": "to grow thick, to luxuriate, to be luxurious"
    },
    {
      "id": 1901,
      "kana": "しけん",
      "kanji": "試験",
      "type": "n,vs",
      "meaning": "examination, test, study"
    },
    {
      "id": 1902,
      "kana": "しげん",
      "kanji": "資源",
      "type": "n",
      "meaning": "resources"
    },
    {
      "id": 1903,
      "kana": "じけん",
      "kanji": "事件",
      "type": "n",
      "meaning": "event, affair, incident"
    },
    {
      "id": 1904,
      "kana": "じこ",
      "kanji": "事故",
      "type": "n",
      "meaning": "accident, incident"
    },
    {
      "id": 1905,
      "kana": "じこく",
      "kanji": "時刻",
      "type": "n-adv,n-t",
      "meaning": "instant, time, moment"
    },
    {
      "id": 1906,
      "kana": "しごと",
      "kanji": "仕事",
      "type": "adj-no,n",
      "meaning": "work, occupation, employment"
    },
    {
      "id": 1907,
      "kana": "じさつ",
      "kanji": "自殺",
      "type": "n,vs",
      "meaning": "suicide"
    },
    {
      "id": 1908,
      "kana": "じさん",
      "kanji": "持参",
      "type": "n,vs",
      "meaning": "bringing, taking, carrying"
    },
    {
      "id": 1909,
      "kana": "じじつ",
      "kanji": "事実",
      "type": "n-adv,n",
      "meaning": "fact, truth, reality"
    },
    {
      "id": 1910,
      "kana": "じしゃく",
      "kanji": "磁石",
      "type": "n",
      "meaning": "magnet"
    },
    {
      "id": 1911,
      "kana": "ししゃごにゅう",
      "kanji": "四捨五入",
      "type": "n",
      "meaning": "rounding up (fractions)"
    },
    {
      "id": 1912,
      "kana": "しじゅう",
      "kanji": "始終",
      "type": "adv,n",
      "meaning": "continuously, from beginning to end"
    },
    {
      "id": 1913,
      "kana": "じしゅう",
      "kanji": "自習",
      "type": "n,vs",
      "meaning": "self-study"
    },
    {
      "id": 1914,
      "kana": "ししゅつ",
      "kanji": "支出",
      "type": "n",
      "meaning": "expenditure, expenses"
    },
    {
      "id": 1915,
      "kana": "じしょ",
      "kanji": "辞書",
      "type": "n",
      "meaning": "dictionary, lexicon"
    },
    {
      "id": 1916,
      "kana": "じじょう",
      "kanji": "事情",
      "type": "n",
      "meaning": "circumstances, consideration, conditions, situation, reasons"
    },
    {
      "id": 1917,
      "kana": "しじん",
      "kanji": "詩人",
      "type": "n",
      "meaning": "poet"
    },
    {
      "id": 1918,
      "kana": "じしん",
      "kanji": "自身",
      "type": "n",
      "meaning": "by oneself, personally"
    },
    {
      "id": 1919,
      "kana": "じしん",
      "kanji": "地震",
      "type": "n",
      "meaning": "earthquake"
    },
    {
      "id": 1920,
      "kana": "しずか",
      "kanji": "静か",
      "type": "adj-na",
      "meaning": "quiet, peaceful"
    },
    {
      "id": 1921,
      "kana": "しずまる",
      "kanji": "静まる",
      "type": "v5r",
      "meaning": "to quieten down, to calm down, to subside"
    },
    {
      "id": 1922,
      "kana": "しずむ",
      "kanji": "沈む",
      "type": "v5m",
      "meaning": "to sink, to feel depressed"
    },
    {
      "id": 1923,
      "kana": "しせい",
      "kanji": "姿勢",
      "type": "n",
      "meaning": "attitude, posture"
    },
    {
      "id": 1924,
      "kana": "しぜんかがく",
      "kanji": "自然科学",
      "type": "",
      "meaning": "natural science"
    },
    {
      "id": 1925,
      "kana": "しぜん",
      "kanji": "自然",
      "type": "adj-na,adv,n",
      "meaning": "nature, spontaneous"
    },
    {
      "id": 1926,
      "kana": "しそう",
      "kanji": "思想",
      "type": "n",
      "meaning": "thought, idea"
    },
    {
      "id": 1927,
      "kana": "じそく",
      "kanji": "時速",
      "type": "n",
      "meaning": "speed (per hour)"
    },
    {
      "id": 1928,
      "kana": "しそん",
      "kanji": "子孫",
      "type": "n",
      "meaning": "descendants, posterity, offspring"
    },
    {
      "id": 1929,
      "kana": "した",
      "kanji": "下",
      "type": "n",
      "meaning": "under, below, beneath"
    },
    {
      "id": 1930,
      "kana": "した",
      "kanji": "舌",
      "type": "n",
      "meaning": "tongue"
    },
    {
      "id": 1931,
      "kana": "したい",
      "kanji": "死体",
      "type": "n",
      "meaning": "corpse"
    },
    {
      "id": 1932,
      "kana": "しだい",
      "kanji": "次第",
      "type": "n-adv,n",
      "meaning": "(1) order, precedence, (2) circumstances, (3) immediate(ly)"
    },
    {
      "id": 1933,
      "kana": "じたい",
      "kanji": "事態",
      "type": "n",
      "meaning": "situation, present state of affairs"
    },
    {
      "id": 1934,
      "kana": "じだい",
      "kanji": "時代",
      "type": "n-t",
      "meaning": "period, epoch, era"
    },
    {
      "id": 1935,
      "kana": "したがう",
      "kanji": "従う",
      "type": "v5u",
      "meaning": "to abide (by the rules), to obey, to follow, to accompany"
    },
    {
      "id": 1936,
      "kana": "したがき",
      "kanji": "下書き",
      "type": "n",
      "meaning": "rough copy, draft"
    },
    {
      "id": 1937,
      "kana": "したがって",
      "kanji": "したがって",
      "type": "conj",
      "meaning": "therefore, consequently, in accordance with"
    },
    {
      "id": 1938,
      "kana": "したぎ",
      "kanji": "下着",
      "type": "n",
      "meaning": "underwear"
    },
    {
      "id": 1939,
      "kana": "したく",
      "kanji": "支度",
      "type": "n,vs",
      "meaning": "preparation"
    },
    {
      "id": 1940,
      "kana": "じたく",
      "kanji": "自宅",
      "type": "n",
      "meaning": "one's home"
    },
    {
      "id": 1941,
      "kana": "したしい",
      "kanji": "親しい",
      "type": "adj",
      "meaning": "intimate, close (e.g. friend)"
    },
    {
      "id": 1942,
      "kana": "したまち",
      "kanji": "下町",
      "type": "n",
      "meaning": "Shitamachi, lower parts of town"
    },
    {
      "id": 1943,
      "kana": "しち",
      "kanji": "七",
      "type": "num",
      "meaning": "seven"
    },
    {
      "id": 1944,
      "kana": "じち",
      "kanji": "自治",
      "type": "n",
      "meaning": "self-government, autonomy"
    },
    {
      "id": 1945,
      "kana": "しつ",
      "kanji": "質",
      "type": "n",
      "meaning": "quality, nature (of person)"
    },
    {
      "id": 1946,
      "kana": "しつ~",
      "kanji": "室~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1947,
      "kana": "~しつ",
      "kanji": "~室",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1948,
      "kana": "~じつ",
      "kanji": "~日",
      "type": "",
      "meaning": ""
    },
    {
      "id": 1949,
      "kana": "しっかり",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "firmly, tightly, reliable, level-headed, steady"
    },
    {
      "id": 1950,
      "kana": "じっかん",
      "kanji": "実感",
      "type": "n",
      "meaning": "feelings (actual, true)"
    },
    {
      "id": 1951,
      "kana": "しつぎょう",
      "kanji": "失業",
      "type": "n,vs",
      "meaning": "unemployment"
    },
    {
      "id": 1952,
      "kana": "しっけ",
      "kanji": "湿気",
      "type": "n",
      "meaning": "moisture, humidity, dampness"
    },
    {
      "id": 1953,
      "kana": "しっき",
      "kanji": "湿気",
      "type": "n",
      "meaning": "moisture, humidity, dampness"
    },
    {
      "id": 1954,
      "kana": "じっけん",
      "kanji": "実験",
      "type": "n",
      "meaning": "experiment"
    },
    {
      "id": 1955,
      "kana": "じつげん",
      "kanji": "実現",
      "type": "n,vs",
      "meaning": "implementation, materialization, realization"
    },
    {
      "id": 1956,
      "kana": "しつこい",
      "kanji": "",
      "type": "adj",
      "meaning": "insistent, obstinate"
    },
    {
      "id": 1957,
      "kana": "じっこう",
      "kanji": "実行",
      "type": "n,vs",
      "meaning": "practice, performance, execution (e.g. program), realization"
    },
    {
      "id": 1958,
      "kana": "じっさい",
      "kanji": "実際",
      "type": "adj-no,adv,n",
      "meaning": "practical, actual condition, status quo"
    },
    {
      "id": 1959,
      "kana": "じっし",
      "kanji": "実施",
      "type": "n,vs",
      "meaning": "enforcement, enact, put into practice, carry out, operation"
    },
    {
      "id": 1960,
      "kana": "じっしゅう",
      "kanji": "実習",
      "type": "n,vs",
      "meaning": "practice, training"
    },
    {
      "id": 1961,
      "kana": "じっせき",
      "kanji": "実績",
      "type": "n",
      "meaning": "achievements, actual results"
    },
    {
      "id": 1962,
      "kana": "しつど",
      "kanji": "湿度",
      "type": "n",
      "meaning": "level of humidity"
    },
    {
      "id": 1963,
      "kana": "じっと",
      "kanji": "",
      "type": "adv,n",
      "meaning": "fixedly, firmly, patiently, quietly"
    },
    {
      "id": 1964,
      "kana": "じつに",
      "kanji": "実に",
      "type": "adv",
      "meaning": "indeed, truly, surely"
    },
    {
      "id": 1965,
      "kana": "じつは",
      "kanji": "実は",
      "type": "adv,exp",
      "meaning": "as a matter of fact, by the way"
    },
    {
      "id": 1966,
      "kana": "しっぱい",
      "kanji": "失敗",
      "type": "adj-no,n,vs",
      "meaning": "failure, mistake, blunder"
    },
    {
      "id": 1967,
      "kana": "しっぴつ",
      "kanji": "執筆",
      "type": "n",
      "meaning": "writing"
    },
    {
      "id": 1968,
      "kana": "じつぶつ",
      "kanji": "実物",
      "type": "n",
      "meaning": "real thing, original"
    },
    {
      "id": 1969,
      "kana": "しっぽ",
      "kanji": "",
      "type": "n",
      "meaning": "tail (animal)"
    },
    {
      "id": 1970,
      "kana": "しつぼう",
      "kanji": "失望",
      "type": "n,vs",
      "meaning": "disappointment, despair"
    },
    {
      "id": 1971,
      "kana": "しつもん",
      "kanji": "質問",
      "type": "n,vs",
      "meaning": "question, inquiry"
    },
    {
      "id": 1972,
      "kana": "じつよう",
      "kanji": "実用",
      "type": "n",
      "meaning": "practical use, utility"
    },
    {
      "id": 1973,
      "kana": "じつりょく",
      "kanji": "実力",
      "type": "n",
      "meaning": "merit, efficiency, arms, force"
    },
    {
      "id": 1974,
      "kana": "しつれい",
      "kanji": "失礼",
      "type": "adj-na,int,n,vs,exp",
      "meaning": "(1) discourtesy, impoliteness, (2) Excuse me, Goodbye"
    },
    {
      "id": 1975,
      "kana": "（感）",
      "kanji": "しつれいしました",
      "type": "",
      "meaning": "Excuse me., I'm sorry."
    },
    {
      "id": 1976,
      "kana": "じつれい",
      "kanji": "実例",
      "type": "n",
      "meaning": "example, illustration"
    },
    {
      "id": 1977,
      "kana": "しつれん",
      "kanji": "失恋",
      "type": "n,vs",
      "meaning": "disappointed love, broken heart, unrequited love, be lovelorn"
    },
    {
      "id": 1978,
      "kana": "してい",
      "kanji": "指定",
      "type": "n,vs",
      "meaning": "designation, specification, assignment, pointing at"
    },
    {
      "id": 1979,
      "kana": "してつ",
      "kanji": "私鉄",
      "type": "n",
      "meaning": "private railway"
    },
    {
      "id": 1980,
      "kana": "してん",
      "kanji": "支店",
      "type": "n",
      "meaning": "branch store (office)"
    },
    {
      "id": 1981,
      "kana": "じてん",
      "kanji": "辞典",
      "type": "n",
      "meaning": "dictionary"
    },
    {
      "id": 1982,
      "kana": "じてんしゃ",
      "kanji": "自転車",
      "type": "n",
      "meaning": "bicycle"
    },
    {
      "id": 1983,
      "kana": "しどう",
      "kanji": "指導",
      "type": "n,vs",
      "meaning": "leadership, guidance, coaching"
    },
    {
      "id": 1984,
      "kana": "じどう",
      "kanji": "自動",
      "type": "adj-na,n",
      "meaning": "automatic, self-motion"
    },
    {
      "id": 1985,
      "kana": "じどう",
      "kanji": "児童",
      "type": "n",
      "meaning": "children, juvenile"
    },
    {
      "id": 1986,
      "kana": "じどうしゃ",
      "kanji": "自動車",
      "type": "n",
      "meaning": "automobile"
    },
    {
      "id": 1987,
      "kana": "しな",
      "kanji": "品",
      "type": "n",
      "meaning": "thing, article, goods, dignity, article (goods), counter for meal courses"
    },
    {
      "id": 1988,
      "kana": "しなもの",
      "kanji": "品物",
      "type": "n",
      "meaning": "goods, article, thing"
    },
    {
      "id": 1989,
      "kana": "しぬ",
      "kanji": "死ぬ",
      "type": "v5n",
      "meaning": "to die"
    },
    {
      "id": 1990,
      "kana": "しはい",
      "kanji": "支配",
      "type": "n,vs",
      "meaning": "rule, control, direction"
    },
    {
      "id": 1991,
      "kana": "しばい",
      "kanji": "芝居",
      "type": "n",
      "meaning": "play, drama"
    },
    {
      "id": 1992,
      "kana": "しばしば",
      "kanji": "",
      "type": "adv",
      "meaning": "often, again and again, frequently"
    },
    {
      "id": 1993,
      "kana": "しばふ",
      "kanji": "芝生",
      "type": "n",
      "meaning": "lawn"
    },
    {
      "id": 1994,
      "kana": "しはらい",
      "kanji": "支払",
      "type": "n",
      "meaning": "payment"
    },
    {
      "id": 1995,
      "kana": "しはらう",
      "kanji": "支払う",
      "type": "v5u",
      "meaning": "to pay"
    },
    {
      "id": 1996,
      "kana": "しばらく",
      "kanji": "",
      "type": "adv,int",
      "meaning": "little while"
    },
    {
      "id": 1997,
      "kana": "しばる",
      "kanji": "縛る",
      "type": "v5r",
      "meaning": "to tie, to bind"
    },
    {
      "id": 1998,
      "kana": "じばん",
      "kanji": "地盤",
      "type": "n",
      "meaning": "(the) ground"
    },
    {
      "id": 1999,
      "kana": "じびき",
      "kanji": "字引",
      "type": "n",
      "meaning": "dictionary"
    },
    {
      "id": 2000,
      "kana": "しびれる",
      "kanji": "",
      "type": "v1",
      "meaning": "to become numb, to go to sleep (i.e., a limb)"
    },
    {
      "id": 2001,
      "kana": "じぶん",
      "kanji": "自分",
      "type": "n",
      "meaning": "myself, oneself"
    },
    {
      "id": 2002,
      "kana": "しへい",
      "kanji": "紙幣",
      "type": "n",
      "meaning": "paper money, notes, bills"
    },
    {
      "id": 2003,
      "kana": "しぼう",
      "kanji": "死亡",
      "type": "n,vs",
      "meaning": "death, mortality"
    },
    {
      "id": 2004,
      "kana": "しぼむ",
      "kanji": "",
      "type": "v5m",
      "meaning": "to wither, to fade (away), to shrivel, to wilt"
    },
    {
      "id": 2005,
      "kana": "しぼる",
      "kanji": "絞る",
      "type": "v5r",
      "meaning": "to press, to wring, to squeeze"
    },
    {
      "id": 2006,
      "kana": "しほん",
      "kanji": "資本",
      "type": "n",
      "meaning": "funds, capital"
    },
    {
      "id": 2007,
      "kana": "しま",
      "kanji": "島",
      "type": "n",
      "meaning": "island"
    },
    {
      "id": 2008,
      "kana": "しま",
      "kanji": "縞",
      "type": "n",
      "meaning": "stripe"
    },
    {
      "id": 2009,
      "kana": "（終わり）",
      "kanji": "しまい",
      "type": "n",
      "meaning": "sisters"
    },
    {
      "id": 2010,
      "kana": "しまい",
      "kanji": "姉妹",
      "type": "n",
      "meaning": "sisters"
    },
    {
      "id": 2011,
      "kana": "（終わる）",
      "kanji": "しまう",
      "type": "v5u",
      "meaning": "to finish, to close, to do something completely"
    },
    {
      "id": 2012,
      "kana": "（感）",
      "kanji": "しまった",
      "type": "exp,int",
      "meaning": "Damn it!"
    },
    {
      "id": 2013,
      "kana": "しまる",
      "kanji": "閉まる",
      "type": "v5r,vi",
      "meaning": "to close, to be closed"
    },
    {
      "id": 2014,
      "kana": "じまん",
      "kanji": "自慢",
      "type": "n,vs",
      "meaning": "pride, boast"
    },
    {
      "id": 2015,
      "kana": "じみ",
      "kanji": "地味",
      "type": "n",
      "meaning": "plain, simple"
    },
    {
      "id": 2016,
      "kana": "しみじみ",
      "kanji": "",
      "type": "",
      "meaning": "keenly, deeply, heartily"
    },
    {
      "id": 2017,
      "kana": "しみん",
      "kanji": "市民",
      "type": "n",
      "meaning": "citizen, townspeople"
    },
    {
      "id": 2018,
      "kana": "じむ",
      "kanji": "事務",
      "type": "n",
      "meaning": "business, office work"
    },
    {
      "id": 2019,
      "kana": "しめい",
      "kanji": "氏名",
      "type": "n",
      "meaning": "full name, identity"
    },
    {
      "id": 2020,
      "kana": "しめきり",
      "kanji": "締切",
      "type": "n",
      "meaning": "closing, cut-off, end, deadline, Closed, No Entrance"
    },
    {
      "id": 2021,
      "kana": "しめきる",
      "kanji": "締め切る",
      "type": "v5r",
      "meaning": "to shut up"
    },
    {
      "id": 2022,
      "kana": "しめす",
      "kanji": "示す",
      "type": "v5s",
      "meaning": "to denote, to show, to point out, to indicate"
    },
    {
      "id": 2023,
      "kana": "（感）",
      "kanji": "しめた",
      "type": "",
      "meaning": "I've got it, all right, fine"
    },
    {
      "id": 2024,
      "kana": "しめる",
      "kanji": "占める",
      "type": "v1",
      "meaning": "(1) to comprise, to account for, to make up (of), (2) to hold, to occupy"
    },
    {
      "id": 2025,
      "kana": "しめる",
      "kanji": "湿る",
      "type": "v5r",
      "meaning": "to be wet, to become wet, to be damp"
    },
    {
      "id": 2026,
      "kana": "しめる",
      "kanji": "閉める",
      "type": "v1,vt",
      "meaning": "to close, to shut"
    },
    {
      "id": 2027,
      "kana": "しめる",
      "kanji": "締める",
      "type": "v1",
      "meaning": "to tie, to fasten"
    },
    {
      "id": 2028,
      "kana": "じめん",
      "kanji": "地面",
      "type": "n",
      "meaning": "ground, earth's surface"
    },
    {
      "id": 2029,
      "kana": "しも",
      "kanji": "下",
      "type": "n",
      "meaning": "under, below, beneath"
    },
    {
      "id": 2030,
      "kana": "しも",
      "kanji": "霜",
      "type": "n",
      "meaning": "frost"
    },
    {
      "id": 2031,
      "kana": "~しゃ",
      "kanji": "~車",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2032,
      "kana": "~しゃ",
      "kanji": "~者",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2033,
      "kana": "~しゃ",
      "kanji": "~社",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2034,
      "kana": "じゃあ",
      "kanji": "",
      "type": "conj,int",
      "meaning": "well, well then"
    },
    {
      "id": 2035,
      "kana": "ジャーナリスト",
      "kanji": "",
      "type": "n",
      "meaning": "journalist"
    },
    {
      "id": 2036,
      "kana": "しゃかい",
      "kanji": "社会",
      "type": "n",
      "meaning": "society, public"
    },
    {
      "id": 2037,
      "kana": "しゃかいかがく",
      "kanji": "社会科学",
      "type": "",
      "meaning": "social science"
    },
    {
      "id": 2038,
      "kana": "しゃがむ",
      "kanji": "",
      "type": "v5m",
      "meaning": "to squat"
    },
    {
      "id": 2039,
      "kana": "じゃぐち",
      "kanji": "蛇口",
      "type": "n",
      "meaning": "faucet, tap"
    },
    {
      "id": 2040,
      "kana": "じゃくてん",
      "kanji": "弱点",
      "type": "n",
      "meaning": "weak point, weakness"
    },
    {
      "id": 2041,
      "kana": "しゃこ",
      "kanji": "車庫",
      "type": "n",
      "meaning": "garage, car shed"
    },
    {
      "id": 2042,
      "kana": "しゃしょう",
      "kanji": "車掌",
      "type": "n",
      "meaning": "(train) conductor"
    },
    {
      "id": 2043,
      "kana": "しゃしん",
      "kanji": "写真",
      "type": "n",
      "meaning": "photograph"
    },
    {
      "id": 2044,
      "kana": "しゃせい",
      "kanji": "写生",
      "type": "n,vs",
      "meaning": "sketching, drawing from nature"
    },
    {
      "id": 2045,
      "kana": "しゃせつ",
      "kanji": "社説",
      "type": "n",
      "meaning": "editorial, leading article"
    },
    {
      "id": 2046,
      "kana": "シャツ",
      "kanji": "",
      "type": "n",
      "meaning": "shirt, singlet"
    },
    {
      "id": 2047,
      "kana": "しゃっきん",
      "kanji": "借金",
      "type": "n",
      "meaning": "debt, loan, liabilities"
    },
    {
      "id": 2048,
      "kana": "しゃっくり",
      "kanji": "",
      "type": "n",
      "meaning": "hiccough, hiccup"
    },
    {
      "id": 2049,
      "kana": "シャッター",
      "kanji": "",
      "type": "n",
      "meaning": "shutter"
    },
    {
      "id": 2050,
      "kana": "しゃぶる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to suck, to chew"
    },
    {
      "id": 2051,
      "kana": "しゃべる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to talk, to chat, to chatter"
    },
    {
      "id": 2052,
      "kana": "じゃま",
      "kanji": "邪魔",
      "type": "adj-na,n,vs",
      "meaning": "hindrance, intrusion"
    },
    {
      "id": 2053,
      "kana": "しゃりん",
      "kanji": "車輪",
      "type": "n",
      "meaning": "(car) wheel"
    },
    {
      "id": 2054,
      "kana": "しゃれ",
      "kanji": "洒落",
      "type": "adj-na,n",
      "meaning": "joke, pun, witticism"
    },
    {
      "id": 2055,
      "kana": "ジャム",
      "kanji": "",
      "type": "n",
      "meaning": "jam"
    },
    {
      "id": 2056,
      "kana": "シャワー",
      "kanji": "",
      "type": "n",
      "meaning": "shower"
    },
    {
      "id": 2057,
      "kana": "じゃんけん",
      "kanji": "",
      "type": "iK",
      "meaning": "(n) rock-scissors-paper game"
    },
    {
      "id": 2058,
      "kana": "~しゅ",
      "kanji": "~手",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2059,
      "kana": "~しゅ",
      "kanji": "~酒",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2060,
      "kana": "しゅう",
      "kanji": "週",
      "type": "n,n-suf",
      "meaning": "week"
    },
    {
      "id": 2061,
      "kana": "しゅう",
      "kanji": "州",
      "type": "n",
      "meaning": "sandbank"
    },
    {
      "id": 2062,
      "kana": "~しゅう",
      "kanji": "~集",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2063,
      "kana": "じゆう",
      "kanji": "自由",
      "type": "adj-na,exp,n",
      "meaning": "freedom, liberty, as it pleases you"
    },
    {
      "id": 2064,
      "kana": "じゅう",
      "kanji": "十",
      "type": "num",
      "meaning": "10, ten"
    },
    {
      "id": 2065,
      "kana": "じゅう",
      "kanji": "銃",
      "type": "",
      "meaning": "gun"
    },
    {
      "id": 2066,
      "kana": "じゅう~",
      "kanji": "重~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2067,
      "kana": "~じゅう",
      "kanji": "~重",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2068,
      "kana": "~じゅう",
      "kanji": "~中",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2069,
      "kana": "しゅうい",
      "kanji": "周囲",
      "type": "n",
      "meaning": "surroundings, circumference, environs"
    },
    {
      "id": 2070,
      "kana": "しゅうかい",
      "kanji": "集会",
      "type": "n",
      "meaning": "meeting, assembly"
    },
    {
      "id": 2071,
      "kana": "しゅうかく",
      "kanji": "収穫",
      "type": "n,vs",
      "meaning": "harvest, crop, ingathering"
    },
    {
      "id": 2072,
      "kana": "しゅうかん",
      "kanji": "週間",
      "type": "n",
      "meaning": "week, weekly"
    },
    {
      "id": 2073,
      "kana": "しゅうかん",
      "kanji": "習慣",
      "type": "n",
      "meaning": "custom, habit, manners"
    },
    {
      "id": 2074,
      "kana": "じゅうきょ",
      "kanji": "住居",
      "type": "n",
      "meaning": "dwelling, house, residence, address"
    },
    {
      "id": 2075,
      "kana": "しゅうきょう",
      "kanji": "宗教",
      "type": "n",
      "meaning": "religion"
    },
    {
      "id": 2076,
      "kana": "しゅうきん",
      "kanji": "集金",
      "type": "n,vs",
      "meaning": "money collection"
    },
    {
      "id": 2077,
      "kana": "しゅうごう",
      "kanji": "集合",
      "type": "n,vs",
      "meaning": "gathering, assembly, meeting, set (math)"
    },
    {
      "id": 2078,
      "kana": "しゅうじ",
      "kanji": "習字",
      "type": "n",
      "meaning": "penmanship"
    },
    {
      "id": 2079,
      "kana": "じゅうし",
      "kanji": "重視",
      "type": "n,vs",
      "meaning": "importance, stress, serious consideration"
    },
    {
      "id": 2080,
      "kana": "じゅうしょ",
      "kanji": "住所",
      "type": "n",
      "meaning": "address (e.g. of house), residence, domicile"
    },
    {
      "id": 2081,
      "kana": "しゅうしょく",
      "kanji": "就職",
      "type": "n,vs",
      "meaning": "finding employment, inauguration"
    },
    {
      "id": 2082,
      "kana": "ジュース",
      "kanji": "",
      "type": "n",
      "meaning": "juice, soft drink, deuce"
    },
    {
      "id": 2083,
      "kana": "しゅうせい",
      "kanji": "修正",
      "type": "n,vs",
      "meaning": "amendment, correction, revision, modification"
    },
    {
      "id": 2084,
      "kana": "しゅうぜん",
      "kanji": "修繕",
      "type": "n,vs",
      "meaning": "repair, mending"
    },
    {
      "id": 2085,
      "kana": "じゅうたい",
      "kanji": "重体",
      "type": "adj-na,n",
      "meaning": "seriously ill, serious condition, critical state"
    },
    {
      "id": 2086,
      "kana": "じゅうたい",
      "kanji": "渋滞",
      "type": "n,vs",
      "meaning": "congestion (e.g. traffic), delay, stagnation"
    },
    {
      "id": 2087,
      "kana": "じゅうだい",
      "kanji": "重大",
      "type": "adj-na,n",
      "meaning": "serious, important, grave, weighty"
    },
    {
      "id": 2088,
      "kana": "じゅうたく",
      "kanji": "住宅",
      "type": "n",
      "meaning": "resident, housing"
    },
    {
      "id": 2089,
      "kana": "しゅうだん",
      "kanji": "集団",
      "type": "n",
      "meaning": "group, mass"
    },
    {
      "id": 2090,
      "kana": "（カーペット）",
      "kanji": "じゅうたん",
      "type": "n",
      "meaning": "carpet"
    },
    {
      "id": 2091,
      "kana": "しゅうちゅう",
      "kanji": "集中",
      "type": "n,vs",
      "meaning": "concentration, focusing the mind"
    },
    {
      "id": 2092,
      "kana": "しゅうてん",
      "kanji": "終点",
      "type": "n",
      "meaning": "terminus, last stop (e.g train)"
    },
    {
      "id": 2093,
      "kana": "じゅうてん",
      "kanji": "重点",
      "type": "n",
      "meaning": "important point, lay stress on, colon, emphasis"
    },
    {
      "id": 2094,
      "kana": "しゅうにゅう",
      "kanji": "収入",
      "type": "n",
      "meaning": "income, receipts, revenue"
    },
    {
      "id": 2095,
      "kana": "しゅうにん",
      "kanji": "就任",
      "type": "n,vs",
      "meaning": "inauguration, assumption of office"
    },
    {
      "id": 2096,
      "kana": "じゅうぶん",
      "kanji": "十分",
      "type": "",
      "meaning": "10 minutes"
    },
    {
      "id": 2097,
      "kana": "しゅうへん",
      "kanji": "周辺",
      "type": "n",
      "meaning": "circumference, outskirts, environs, (computer) peripheral"
    },
    {
      "id": 2098,
      "kana": "じゅうみん",
      "kanji": "住民",
      "type": "n",
      "meaning": "citizens, inhabitants, residents, population"
    },
    {
      "id": 2099,
      "kana": "じゅうやく",
      "kanji": "重役",
      "type": "n",
      "meaning": "director, high executive"
    },
    {
      "id": 2100,
      "kana": "じゅうよう",
      "kanji": "重要",
      "type": "adj-na,n",
      "meaning": "important, momentous, essential, principal, major"
    },
    {
      "id": 2101,
      "kana": "しゅうり",
      "kanji": "修理",
      "type": "n,vs",
      "meaning": "repairing, mending"
    },
    {
      "id": 2102,
      "kana": "しゅうりょう",
      "kanji": "終了",
      "type": "n,vs",
      "meaning": "end, close, termination"
    },
    {
      "id": 2103,
      "kana": "じゅうりょう",
      "kanji": "重量",
      "type": "n",
      "meaning": "(1) weight, (2) heavyweight boxer"
    },
    {
      "id": 2104,
      "kana": "じゅうりょく",
      "kanji": "重力",
      "type": "n",
      "meaning": "gravity"
    },
    {
      "id": 2105,
      "kana": "しゅぎ",
      "kanji": "主義",
      "type": "n",
      "meaning": "doctrine, rule, principle"
    },
    {
      "id": 2106,
      "kana": "じゅぎょう",
      "kanji": "授業",
      "type": "n,vs",
      "meaning": "lesson, class work"
    },
    {
      "id": 2107,
      "kana": "じゅくご",
      "kanji": "熟語",
      "type": "n",
      "meaning": "idiom, idiomatic phrase, kanji compound"
    },
    {
      "id": 2108,
      "kana": "しゅくじつ",
      "kanji": "祝日",
      "type": "n",
      "meaning": "national holiday"
    },
    {
      "id": 2109,
      "kana": "しゅくしょう",
      "kanji": "縮小",
      "type": "n,vs",
      "meaning": "reduction, curtailment"
    },
    {
      "id": 2110,
      "kana": "しゅくだい",
      "kanji": "宿題",
      "type": "n",
      "meaning": "homework"
    },
    {
      "id": 2111,
      "kana": "しゅくはく",
      "kanji": "宿泊",
      "type": "n",
      "meaning": "lodging"
    },
    {
      "id": 2112,
      "kana": "じゅけん",
      "kanji": "受験",
      "type": "n,vs",
      "meaning": "taking an examination"
    },
    {
      "id": 2113,
      "kana": "しゅご",
      "kanji": "主語",
      "type": "n",
      "meaning": "(gram) subject"
    },
    {
      "id": 2114,
      "kana": "しゅじゅつ",
      "kanji": "手術",
      "type": "n",
      "meaning": "surgical operation"
    },
    {
      "id": 2115,
      "kana": "しゅしょう",
      "kanji": "首相",
      "type": "n",
      "meaning": "Prime Minister"
    },
    {
      "id": 2116,
      "kana": "しゅじん",
      "kanji": "主人",
      "type": "n",
      "meaning": "master, head (of a household), landlord, one's husband"
    },
    {
      "id": 2117,
      "kana": "しゅだん",
      "kanji": "手段",
      "type": "n",
      "meaning": "means, way, measure"
    },
    {
      "id": 2118,
      "kana": "しゅちょう",
      "kanji": "主張",
      "type": "n,vs",
      "meaning": "claim, request, insistence, assertion"
    },
    {
      "id": 2119,
      "kana": "しゅっきん",
      "kanji": "出勤",
      "type": "n,vs",
      "meaning": "going to work, at work"
    },
    {
      "id": 2120,
      "kana": "じゅつご",
      "kanji": "述語",
      "type": "n",
      "meaning": "predicate"
    },
    {
      "id": 2121,
      "kana": "しゅつじょう",
      "kanji": "出場",
      "type": "n",
      "meaning": "one's turn, place of projection, production center"
    },
    {
      "id": 2122,
      "kana": "しゅっしん",
      "kanji": "出身",
      "type": "n",
      "meaning": "graduate from, come from"
    },
    {
      "id": 2123,
      "kana": "しゅっせき",
      "kanji": "出席",
      "type": "n,vs",
      "meaning": "attendance, presence"
    },
    {
      "id": 2124,
      "kana": "しゅっちょう",
      "kanji": "出張",
      "type": "n,vs",
      "meaning": "official tour, business trip"
    },
    {
      "id": 2125,
      "kana": "しゅっぱつ",
      "kanji": "出発",
      "type": "n,vs",
      "meaning": "departure"
    },
    {
      "id": 2126,
      "kana": "しゅっぱん",
      "kanji": "出版",
      "type": "n,vs",
      "meaning": "publication"
    },
    {
      "id": 2127,
      "kana": "しゅと",
      "kanji": "首都",
      "type": "n",
      "meaning": "capital city"
    },
    {
      "id": 2128,
      "kana": "しゅふ",
      "kanji": "主婦",
      "type": "n",
      "meaning": "housewife, mistress"
    },
    {
      "id": 2129,
      "kana": "しゅみ",
      "kanji": "趣味",
      "type": "n",
      "meaning": "hobby, tastes, preference"
    },
    {
      "id": 2130,
      "kana": "じゅみょう",
      "kanji": "寿命",
      "type": "n",
      "meaning": "life span"
    },
    {
      "id": 2131,
      "kana": "しゅやく",
      "kanji": "主役",
      "type": "n",
      "meaning": "leading part, leading actor (actress)"
    },
    {
      "id": 2132,
      "kana": "しゅよう",
      "kanji": "主要",
      "type": "adj-na,n",
      "meaning": "chief, main, principal, major"
    },
    {
      "id": 2133,
      "kana": "じゅよう",
      "kanji": "需要",
      "type": "n",
      "meaning": "demand, request"
    },
    {
      "id": 2134,
      "kana": "しゅるい",
      "kanji": "種類",
      "type": "n",
      "meaning": "variety, kind, type"
    },
    {
      "id": 2135,
      "kana": "じゅわき",
      "kanji": "受話器",
      "type": "n",
      "meaning": "(telephone) receiver"
    },
    {
      "id": 2136,
      "kana": "じゅん",
      "kanji": "順",
      "type": "adj-na,n,n-suf",
      "meaning": "order, turn"
    },
    {
      "id": 2137,
      "kana": "しゅんかん",
      "kanji": "瞬間",
      "type": "n-adv,n-t",
      "meaning": "moment, second, instant"
    },
    {
      "id": 2138,
      "kana": "じゅんかん",
      "kanji": "循環",
      "type": "n",
      "meaning": "circulation, rotation, cycle"
    },
    {
      "id": 2139,
      "kana": "じゅんさ",
      "kanji": "巡査",
      "type": "n",
      "meaning": "police, policeman"
    },
    {
      "id": 2140,
      "kana": "じゅんじゅん",
      "kanji": "順々",
      "type": "adv,n",
      "meaning": "in order, in turn"
    },
    {
      "id": 2141,
      "kana": "じゅんじょ",
      "kanji": "順序",
      "type": "n",
      "meaning": "order, sequence, procedure"
    },
    {
      "id": 2142,
      "kana": "じゅんじょう",
      "kanji": "純情",
      "type": "adj-na,n",
      "meaning": "pure heart, naivete, self-sacrificing devotion"
    },
    {
      "id": 2143,
      "kana": "じゅんすい",
      "kanji": "純粋",
      "type": "adj-na,n",
      "meaning": "pure, true, genuine, unmixed"
    },
    {
      "id": 2144,
      "kana": "じゅんちょう",
      "kanji": "順調",
      "type": "adj-na,n",
      "meaning": "favourable, doing well, O.K., all right"
    },
    {
      "id": 2145,
      "kana": "じゅんばん",
      "kanji": "順番",
      "type": "n",
      "meaning": "turn (in line), order of things"
    },
    {
      "id": 2146,
      "kana": "じゅんび",
      "kanji": "準備",
      "type": "n,vs",
      "meaning": "preparation, arrangements, provision, reserve"
    },
    {
      "id": 2147,
      "kana": "しょ~",
      "kanji": "初~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2148,
      "kana": "しょ~",
      "kanji": "諸~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2149,
      "kana": "~しょ",
      "kanji": "~所",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2150,
      "kana": "~じょ",
      "kanji": "~所",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2151,
      "kana": "じょ~",
      "kanji": "女~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2152,
      "kana": "~じょ",
      "kanji": "~女",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2153,
      "kana": "しよう",
      "kanji": "使用",
      "type": "n,vs",
      "meaning": "use, application, employment, utilization"
    },
    {
      "id": 2154,
      "kana": "しょう",
      "kanji": "小",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2155,
      "kana": "しょう",
      "kanji": "章",
      "type": "n",
      "meaning": "(1) chapter, section, (2) medal"
    },
    {
      "id": 2156,
      "kana": "しょう",
      "kanji": "賞",
      "type": "n,n-suf",
      "meaning": "prize, award"
    },
    {
      "id": 2157,
      "kana": "しょう~",
      "kanji": "省~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2158,
      "kana": "~しょう",
      "kanji": "~省",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2159,
      "kana": "~しょう",
      "kanji": "~商",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2160,
      "kana": "~しょう",
      "kanji": "~勝",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2161,
      "kana": "じょう",
      "kanji": "上",
      "type": "n,pref,suf",
      "meaning": "(1) first volume, (2) superior quality, (3) governmental"
    },
    {
      "id": 2162,
      "kana": "~じょう",
      "kanji": "~状",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2163,
      "kana": "~じょう",
      "kanji": "~場",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2164,
      "kana": "~じょう",
      "kanji": "~畳",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2165,
      "kana": "しょうか",
      "kanji": "消化",
      "type": "n,vs",
      "meaning": "digestion"
    },
    {
      "id": 2166,
      "kana": "しょうかい",
      "kanji": "紹介",
      "type": "n,vs",
      "meaning": "introduction"
    },
    {
      "id": 2167,
      "kana": "しょうがい",
      "kanji": "障害",
      "type": "n",
      "meaning": "obstacle, impediment (fault), damage"
    },
    {
      "id": 2168,
      "kana": "しょうがくきん",
      "kanji": "奨学金",
      "type": "n",
      "meaning": "scholarship"
    },
    {
      "id": 2169,
      "kana": "しょうがくせい",
      "kanji": "小学生",
      "type": "n",
      "meaning": "grade school student"
    },
    {
      "id": 2170,
      "kana": "しょうがつ",
      "kanji": "正月",
      "type": "n",
      "meaning": "New Year, New Year's Day, the first month, January"
    },
    {
      "id": 2171,
      "kana": "しょうがっこう",
      "kanji": "小学校",
      "type": "n",
      "meaning": "primary school, elementary school"
    },
    {
      "id": 2172,
      "kana": "しょうがない",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2173,
      "kana": "しょうぎ",
      "kanji": "将棋",
      "type": "n",
      "meaning": "Japanese chess"
    },
    {
      "id": 2174,
      "kana": "じょうき",
      "kanji": "蒸気",
      "type": "n",
      "meaning": "steam, vapour"
    },
    {
      "id": 2175,
      "kana": "じょうぎ",
      "kanji": "定規",
      "type": "n",
      "meaning": "(measuring) ruler"
    },
    {
      "id": 2176,
      "kana": "じょうきゃく",
      "kanji": "乗客",
      "type": "n",
      "meaning": "passenger"
    },
    {
      "id": 2177,
      "kana": "じょうきゅう",
      "kanji": "上級",
      "type": "n",
      "meaning": "advanced level, high grade, senior"
    },
    {
      "id": 2178,
      "kana": "しょうぎょう",
      "kanji": "商業",
      "type": "n",
      "meaning": "commerce, trade, business"
    },
    {
      "id": 2179,
      "kana": "じょうきょう",
      "kanji": "上京",
      "type": "n,vs",
      "meaning": "proceeding to the capital (Tokyo)"
    },
    {
      "id": 2180,
      "kana": "じょうきょう",
      "kanji": "状況",
      "type": "n",
      "meaning": "state of affairs, situation, circumstances"
    },
    {
      "id": 2181,
      "kana": "しょうきょくてき",
      "kanji": "消極的",
      "type": "adj-na",
      "meaning": "passive"
    },
    {
      "id": 2182,
      "kana": "しょうきん",
      "kanji": "賞金",
      "type": "n",
      "meaning": "prize, monetary award"
    },
    {
      "id": 2183,
      "kana": "じょうげ",
      "kanji": "上下",
      "type": "n,vs",
      "meaning": "high and low, up and down, unloading and loading, praising and blaming"
    },
    {
      "id": 2184,
      "kana": "じょうけん",
      "kanji": "条件",
      "type": "n",
      "meaning": "conditions, terms"
    },
    {
      "id": 2185,
      "kana": "しょうご",
      "kanji": "正午",
      "type": "n-adv,n-t",
      "meaning": "noon, mid-day"
    },
    {
      "id": 2186,
      "kana": "しょうじ",
      "kanji": "障子",
      "type": "n",
      "meaning": "paper sliding door"
    },
    {
      "id": 2187,
      "kana": "しょうじき",
      "kanji": "正直",
      "type": "adj-na,adv,n",
      "meaning": "honesty, integrity, frankness"
    },
    {
      "id": 2188,
      "kana": "じょうしき",
      "kanji": "常識",
      "type": "n",
      "meaning": "common sense"
    },
    {
      "id": 2189,
      "kana": "しょうしゃ",
      "kanji": "商社",
      "type": "n",
      "meaning": "trading company, firm"
    },
    {
      "id": 2190,
      "kana": "じょうしゃ",
      "kanji": "乗車",
      "type": "n,vs",
      "meaning": "taking a train, entraining"
    },
    {
      "id": 2191,
      "kana": "じょうじゅん",
      "kanji": "上旬",
      "type": "n-adv,n-t",
      "meaning": "first 10 days of month"
    },
    {
      "id": 2192,
      "kana": "しょうじょ",
      "kanji": "少女",
      "type": "n",
      "meaning": "daughter, young lady, virgin, maiden, little girl"
    },
    {
      "id": 2193,
      "kana": "しょうしょう",
      "kanji": "少々",
      "type": "adv,exp,n-adv,n",
      "meaning": "just a minute, small quantity"
    },
    {
      "id": 2194,
      "kana": "しょうじょう",
      "kanji": "症状",
      "type": "n",
      "meaning": "symptoms, condition"
    },
    {
      "id": 2195,
      "kana": "しょうじる",
      "kanji": "生じる",
      "type": "v1",
      "meaning": "to produce, to yield, to result from, to arise, to be generated"
    },
    {
      "id": 2196,
      "kana": "しょうずる",
      "kanji": "生ずる",
      "type": "v5z",
      "meaning": "to cause, to arise, to be generated"
    },
    {
      "id": 2197,
      "kana": "じょうず",
      "kanji": "上手",
      "type": "adj-na,n",
      "meaning": "skill, skillful, dexterity"
    },
    {
      "id": 2198,
      "kana": "しょうすう",
      "kanji": "小数",
      "type": "n",
      "meaning": "fraction (part of), decimal"
    },
    {
      "id": 2199,
      "kana": "しょうせつ",
      "kanji": "小説",
      "type": "n",
      "meaning": "novel, story"
    },
    {
      "id": 2200,
      "kana": "しょうたい",
      "kanji": "招待",
      "type": "n,vs",
      "meaning": "invitation"
    },
    {
      "id": 2201,
      "kana": "じょうたい",
      "kanji": "状態",
      "type": "n",
      "meaning": "condition, situation, circumstances, state"
    },
    {
      "id": 2202,
      "kana": "じょうたつ",
      "kanji": "上達",
      "type": "n,vs",
      "meaning": "improvement, advance, progress"
    },
    {
      "id": 2203,
      "kana": "じょうだん",
      "kanji": "冗談",
      "type": "n",
      "meaning": "jest, joke"
    },
    {
      "id": 2204,
      "kana": "しょうち",
      "kanji": "承知",
      "type": "n,vs",
      "meaning": "consent, acceptance, assent, admitting"
    },
    {
      "id": 2205,
      "kana": "しょうてん",
      "kanji": "商店",
      "type": "n",
      "meaning": "shop, business firm"
    },
    {
      "id": 2206,
      "kana": "しょうてん",
      "kanji": "焦点",
      "type": "n",
      "meaning": "focus, point"
    },
    {
      "id": 2207,
      "kana": "じょうとう",
      "kanji": "上等",
      "type": "adj-na,n",
      "meaning": "superiority, first class, very good"
    },
    {
      "id": 2208,
      "kana": "しょうどく",
      "kanji": "消毒",
      "type": "n,vs",
      "meaning": "disinfection, sterilization"
    },
    {
      "id": 2209,
      "kana": "しょうとつ",
      "kanji": "衝突",
      "type": "n,vs",
      "meaning": "collision, conflict"
    },
    {
      "id": 2210,
      "kana": "しょうにん",
      "kanji": "商人",
      "type": "n",
      "meaning": "trader, shopkeeper, merchant"
    },
    {
      "id": 2211,
      "kana": "しょうにん",
      "kanji": "承認",
      "type": "n",
      "meaning": "recognition, acknowledgement, approval, consent, agreement"
    },
    {
      "id": 2212,
      "kana": "しょうねん",
      "kanji": "少年",
      "type": "n",
      "meaning": "boys, juveniles"
    },
    {
      "id": 2213,
      "kana": "しょうはい",
      "kanji": "勝敗",
      "type": "n",
      "meaning": "victory or defeat, issue (of battle)"
    },
    {
      "id": 2214,
      "kana": "しょうばい",
      "kanji": "商売",
      "type": "n,vs",
      "meaning": "trade, business, commerce, transaction, occupation"
    },
    {
      "id": 2215,
      "kana": "じょうはつ",
      "kanji": "蒸発",
      "type": "n,vs",
      "meaning": "evaporation, unexplained disappearance"
    },
    {
      "id": 2216,
      "kana": "しょうひ",
      "kanji": "消費",
      "type": "n,vs",
      "meaning": "consumption, expenditure"
    },
    {
      "id": 2217,
      "kana": "しょうひん",
      "kanji": "商品",
      "type": "n",
      "meaning": "commodity, article of commerce, goods, stock, merchandise"
    },
    {
      "id": 2218,
      "kana": "しょうひん",
      "kanji": "賞品",
      "type": "n",
      "meaning": "prize, trophy"
    },
    {
      "id": 2219,
      "kana": "じょうひん",
      "kanji": "上品",
      "type": "n",
      "meaning": "Buddhism's highest paradise"
    },
    {
      "id": 2220,
      "kana": "しょうぶ",
      "kanji": "勝負",
      "type": "n,vs",
      "meaning": "victory or defeat, match, contest, game, bout"
    },
    {
      "id": 2221,
      "kana": "じょうぶ",
      "kanji": "丈夫",
      "type": "adj-na,n",
      "meaning": "(1) hero, gentleman, (2) good health, robustness, strong"
    },
    {
      "id": 2222,
      "kana": "しょうべん",
      "kanji": "小便",
      "type": "n",
      "meaning": "(col) urine, piss"
    },
    {
      "id": 2223,
      "kana": "しょうぼう",
      "kanji": "消防",
      "type": "n",
      "meaning": "fire fighting, fire department"
    },
    {
      "id": 2224,
      "kana": "しょうぼうしょ",
      "kanji": "消防署",
      "type": "n",
      "meaning": "fire station"
    },
    {
      "id": 2225,
      "kana": "じょうほう",
      "kanji": "情報",
      "type": "n",
      "meaning": "information, (military) intelligence"
    },
    {
      "id": 2226,
      "kana": "しょうみ",
      "kanji": "正味",
      "type": "n",
      "meaning": "net (weight)"
    },
    {
      "id": 2227,
      "kana": "しょうめい",
      "kanji": "証明",
      "type": "n,vs",
      "meaning": "proof, verification"
    },
    {
      "id": 2228,
      "kana": "しょうめん",
      "kanji": "正面",
      "type": "adj-na,n",
      "meaning": "the front, honesty"
    },
    {
      "id": 2229,
      "kana": "しょうもう",
      "kanji": "消耗",
      "type": "n,vs",
      "meaning": "exhaustion, consumption"
    },
    {
      "id": 2230,
      "kana": "しょうゆ",
      "kanji": "醤油",
      "type": "n",
      "meaning": "soy sauce"
    },
    {
      "id": 2231,
      "kana": "しょうらい",
      "kanji": "将来",
      "type": "n-adv,n-t",
      "meaning": "future, prospects"
    },
    {
      "id": 2232,
      "kana": "しょうりゃく",
      "kanji": "省略",
      "type": "n,vs",
      "meaning": "omission, abbreviation, abridgment"
    },
    {
      "id": 2233,
      "kana": "じょおう",
      "kanji": "女王",
      "type": "n",
      "meaning": "queen"
    },
    {
      "id": 2234,
      "kana": "しょきゅう",
      "kanji": "初級",
      "type": "n",
      "meaning": "elementary level"
    },
    {
      "id": 2235,
      "kana": "じょきょうじゅ",
      "kanji": "助教授",
      "type": "n",
      "meaning": "assistant professor"
    },
    {
      "id": 2236,
      "kana": "しょく",
      "kanji": "職",
      "type": "n,n-suf",
      "meaning": "employment"
    },
    {
      "id": 2237,
      "kana": "~しょく",
      "kanji": "~色",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2238,
      "kana": "しょくえん",
      "kanji": "食塩",
      "type": "n",
      "meaning": "table salt"
    },
    {
      "id": 2239,
      "kana": "しょくぎょう",
      "kanji": "職業",
      "type": "n",
      "meaning": "occupation, business"
    },
    {
      "id": 2240,
      "kana": "しょくじ",
      "kanji": "食事",
      "type": "n,vs",
      "meaning": "meal"
    },
    {
      "id": 2241,
      "kana": "しょくたく",
      "kanji": "食卓",
      "type": "n",
      "meaning": "dining table"
    },
    {
      "id": 2242,
      "kana": "しょくどう",
      "kanji": "食堂",
      "type": "n",
      "meaning": "cafeteria, dining hall"
    },
    {
      "id": 2243,
      "kana": "しょくにん",
      "kanji": "職人",
      "type": "n",
      "meaning": "worker, mechanic, artisan, craftsman"
    },
    {
      "id": 2244,
      "kana": "しょくば",
      "kanji": "職場",
      "type": "n",
      "meaning": "one's post, place of work, workplace"
    },
    {
      "id": 2245,
      "kana": "しょくひん",
      "kanji": "食品",
      "type": "n",
      "meaning": "commodity, foodstuff"
    },
    {
      "id": 2246,
      "kana": "しょくぶつ",
      "kanji": "植物",
      "type": "n",
      "meaning": "plant, vegetation"
    },
    {
      "id": 2247,
      "kana": "しょくもつ",
      "kanji": "食物",
      "type": "n",
      "meaning": "food, foodstuff"
    },
    {
      "id": 2248,
      "kana": "しょくよく",
      "kanji": "食欲",
      "type": "n",
      "meaning": "appetite (for food)"
    },
    {
      "id": 2249,
      "kana": "しょくりょう",
      "kanji": "食料",
      "type": "n",
      "meaning": "food"
    },
    {
      "id": 2250,
      "kana": "しょくりょう",
      "kanji": "食糧",
      "type": "n",
      "meaning": "provisions, rations"
    },
    {
      "id": 2251,
      "kana": "しょさい",
      "kanji": "書斎",
      "type": "n",
      "meaning": "study"
    },
    {
      "id": 2252,
      "kana": "じょし",
      "kanji": "女子",
      "type": "n",
      "meaning": "woman, girl"
    },
    {
      "id": 2253,
      "kana": "じょしゅ",
      "kanji": "助手",
      "type": "n",
      "meaning": "helper, helpmeet, assistant, tutor"
    },
    {
      "id": 2254,
      "kana": "しょじゅん",
      "kanji": "初旬",
      "type": "n-adv,n",
      "meaning": "first 10 days of the month"
    },
    {
      "id": 2255,
      "kana": "じょじょに",
      "kanji": "徐々に",
      "type": "adv",
      "meaning": "slowly, little by little, gradually, steadily, quietly"
    },
    {
      "id": 2256,
      "kana": "じょせい",
      "kanji": "女性",
      "type": "n",
      "meaning": "woman"
    },
    {
      "id": 2257,
      "kana": "しょせき",
      "kanji": "書籍",
      "type": "n",
      "meaning": "book, publication"
    },
    {
      "id": 2258,
      "kana": "しょっき",
      "kanji": "食器",
      "type": "n",
      "meaning": "tableware"
    },
    {
      "id": 2259,
      "kana": "ショップ",
      "kanji": "",
      "type": "n",
      "meaning": "a shop"
    },
    {
      "id": 2260,
      "kana": "しょてん",
      "kanji": "書店",
      "type": "n",
      "meaning": "bookshop"
    },
    {
      "id": 2261,
      "kana": "しょどう",
      "kanji": "書道",
      "type": "n",
      "meaning": "calligraphy"
    },
    {
      "id": 2262,
      "kana": "しょほ",
      "kanji": "初歩",
      "type": "adj-no,n",
      "meaning": "elements, rudiments, ABC's of.."
    },
    {
      "id": 2263,
      "kana": "しょめい",
      "kanji": "署名",
      "type": "n,vs",
      "meaning": "signature"
    },
    {
      "id": 2264,
      "kana": "しょもつ",
      "kanji": "書物",
      "type": "n",
      "meaning": "books"
    },
    {
      "id": 2265,
      "kana": "じょゆう",
      "kanji": "女優",
      "type": "n",
      "meaning": "actress"
    },
    {
      "id": 2266,
      "kana": "しょり",
      "kanji": "処理",
      "type": "n,vs",
      "meaning": "processing, dealing with, treatment, disposition, disposal"
    },
    {
      "id": 2267,
      "kana": "しょるい",
      "kanji": "書類",
      "type": "n",
      "meaning": "documents, official papers"
    },
    {
      "id": 2268,
      "kana": "しらが",
      "kanji": "白髪",
      "type": "n",
      "meaning": "white or grey hair, trendy hair bleaching"
    },
    {
      "id": 2269,
      "kana": "しらせ",
      "kanji": "知らせ",
      "type": "n",
      "meaning": "notice"
    },
    {
      "id": 2270,
      "kana": "しらせる",
      "kanji": "知らせる",
      "type": "v1",
      "meaning": "to notify, to advise"
    },
    {
      "id": 2271,
      "kana": "しらべる",
      "kanji": "調べる",
      "type": "v1,vt",
      "meaning": "to investigate, to check up"
    },
    {
      "id": 2272,
      "kana": "しり",
      "kanji": "尻",
      "type": "n",
      "meaning": "buttocks, bottom"
    },
    {
      "id": 2273,
      "kana": "しりあい",
      "kanji": "知合い",
      "type": "n",
      "meaning": "acquaintance"
    },
    {
      "id": 2274,
      "kana": "シリーズ",
      "kanji": "",
      "type": "n,n-suf",
      "meaning": "series"
    },
    {
      "id": 2275,
      "kana": "しりつ",
      "kanji": "私立",
      "type": "n",
      "meaning": "private (establishment)"
    },
    {
      "id": 2276,
      "kana": "しりょう",
      "kanji": "資料",
      "type": "n",
      "meaning": "materials, data"
    },
    {
      "id": 2277,
      "kana": "しる",
      "kanji": "汁",
      "type": "n",
      "meaning": "juice, sap, soup, broth"
    },
    {
      "id": 2278,
      "kana": "しる",
      "kanji": "知る",
      "type": "v5r",
      "meaning": "to know, to understand, to be acquainted with"
    },
    {
      "id": 2279,
      "kana": "しるし",
      "kanji": "印",
      "type": "n",
      "meaning": "(1) mark, (2) symbol, (3) evidence"
    },
    {
      "id": 2280,
      "kana": "しろ",
      "kanji": "白",
      "type": "n",
      "meaning": "white"
    },
    {
      "id": 2281,
      "kana": "しろ",
      "kanji": "城",
      "type": "n",
      "meaning": "castle"
    },
    {
      "id": 2282,
      "kana": "しろい",
      "kanji": "白い",
      "type": "adj",
      "meaning": "white"
    },
    {
      "id": 2283,
      "kana": "しろうと",
      "kanji": "素人",
      "type": "n",
      "meaning": "amateur, novice"
    },
    {
      "id": 2284,
      "kana": "（顔を~）",
      "kanji": "しわ",
      "type": "n",
      "meaning": "wrinkles, creases"
    },
    {
      "id": 2285,
      "kana": "しん",
      "kanji": "芯",
      "type": "n",
      "meaning": "core, heart, wick, marrow"
    },
    {
      "id": 2286,
      "kana": "~じん",
      "kanji": "~人",
      "type": "",
      "meaning": "~ person"
    },
    {
      "id": 2287,
      "kana": "しんがく",
      "kanji": "進学",
      "type": "n,vs",
      "meaning": "going on to university"
    },
    {
      "id": 2288,
      "kana": "しんかんせん",
      "kanji": "新幹線",
      "type": "n",
      "meaning": "bullet train (very high speed), shinkansen"
    },
    {
      "id": 2289,
      "kana": "しんくう",
      "kanji": "真空",
      "type": "adj-na,n",
      "meaning": "vacuum, hollow, empty"
    },
    {
      "id": 2290,
      "kana": "しんけい",
      "kanji": "神経",
      "type": "n",
      "meaning": "nerve, sensitivity"
    },
    {
      "id": 2291,
      "kana": "しんけん",
      "kanji": "真剣",
      "type": "adj-na,n",
      "meaning": "seriousness, earnestness"
    },
    {
      "id": 2292,
      "kana": "しんこう",
      "kanji": "信仰",
      "type": "n,vs",
      "meaning": "(religious) faith, belief, creed"
    },
    {
      "id": 2293,
      "kana": "しんごう",
      "kanji": "信号",
      "type": "n,vs",
      "meaning": "traffic lights, signal, semaphore"
    },
    {
      "id": 2294,
      "kana": "じんこう",
      "kanji": "人工",
      "type": "n",
      "meaning": "artificial, manmade, human work, human skill, artificiality"
    },
    {
      "id": 2295,
      "kana": "しんこく",
      "kanji": "深刻",
      "type": "adj-na,n",
      "meaning": "serious"
    },
    {
      "id": 2296,
      "kana": "しんさつ",
      "kanji": "診察",
      "type": "n,vs",
      "meaning": "medical examination"
    },
    {
      "id": 2297,
      "kana": "じんじ",
      "kanji": "人事",
      "type": "n",
      "meaning": "other's affairs"
    },
    {
      "id": 2298,
      "kana": "じんじゃ",
      "kanji": "神社",
      "type": "n",
      "meaning": "Shinto shrine"
    },
    {
      "id": 2299,
      "kana": "じんしゅ",
      "kanji": "人種",
      "type": "n",
      "meaning": "race (of people)"
    },
    {
      "id": 2300,
      "kana": "しんじる",
      "kanji": "信じる",
      "type": "v1",
      "meaning": "to believe, to place trust in"
    },
    {
      "id": 2301,
      "kana": "しんずる",
      "kanji": "信ずる",
      "type": "v5z",
      "meaning": "to believe, to believe in, to place trust in"
    },
    {
      "id": 2302,
      "kana": "しんしん",
      "kanji": "心身",
      "type": "n",
      "meaning": "mind and body"
    },
    {
      "id": 2303,
      "kana": "しんせい",
      "kanji": "申請",
      "type": "n,vs",
      "meaning": "application, request, petition"
    },
    {
      "id": 2304,
      "kana": "じんせい",
      "kanji": "人生",
      "type": "n",
      "meaning": "(human) life (i.e. conception to death)"
    },
    {
      "id": 2305,
      "kana": "しんせき",
      "kanji": "親戚",
      "type": "n",
      "meaning": "relative"
    },
    {
      "id": 2306,
      "kana": "しんせつ",
      "kanji": "親切",
      "type": "adj-na,n",
      "meaning": "kindness, gentleness"
    },
    {
      "id": 2307,
      "kana": "しんせん",
      "kanji": "新鮮",
      "type": "adj-na",
      "meaning": "fresh"
    },
    {
      "id": 2308,
      "kana": "しんぞう",
      "kanji": "心臓",
      "type": "n",
      "meaning": "heart"
    },
    {
      "id": 2309,
      "kana": "じんぞう",
      "kanji": "人造",
      "type": "n",
      "meaning": "man-made, synthetic, artificial"
    },
    {
      "id": 2310,
      "kana": "しんたい",
      "kanji": "身体",
      "type": "n",
      "meaning": "the body"
    },
    {
      "id": 2311,
      "kana": "しんだい",
      "kanji": "寝台",
      "type": "n",
      "meaning": "bed, couch"
    },
    {
      "id": 2312,
      "kana": "しんだん",
      "kanji": "診断",
      "type": "n,vs",
      "meaning": "diagnosis"
    },
    {
      "id": 2313,
      "kana": "しんちょう",
      "kanji": "身長",
      "type": "n",
      "meaning": "height (of body), stature"
    },
    {
      "id": 2314,
      "kana": "しんちょう",
      "kanji": "慎重",
      "type": "adj-na,n",
      "meaning": "discretion, prudence"
    },
    {
      "id": 2315,
      "kana": "しんにゅう",
      "kanji": "侵入",
      "type": "n,vs",
      "meaning": "penetration, invasion, raid, aggression, trespass"
    },
    {
      "id": 2316,
      "kana": "しんぱい",
      "kanji": "心配",
      "type": "adj-na,n,vs",
      "meaning": "worry, concern, anxiety, care"
    },
    {
      "id": 2317,
      "kana": "しんぱん",
      "kanji": "審判",
      "type": "n,vs",
      "meaning": "refereeing, trial, judgement, umpire, referee"
    },
    {
      "id": 2318,
      "kana": "じんぶつ",
      "kanji": "人物",
      "type": "n",
      "meaning": "character, personality, person, man, personage, talented man"
    },
    {
      "id": 2319,
      "kana": "しんぶん",
      "kanji": "新聞",
      "type": "n",
      "meaning": "newspaper"
    },
    {
      "id": 2320,
      "kana": "じんぶんかがく",
      "kanji": "人文科学",
      "type": "",
      "meaning": "social sciences, humanities"
    },
    {
      "id": 2321,
      "kana": "しんぽ",
      "kanji": "進歩",
      "type": "n,vs",
      "meaning": "progress, development"
    },
    {
      "id": 2322,
      "kana": "じんめい",
      "kanji": "人命",
      "type": "n",
      "meaning": "(human) life"
    },
    {
      "id": 2323,
      "kana": "しんや",
      "kanji": "深夜",
      "type": "n-adv,n-t",
      "meaning": "late at night"
    },
    {
      "id": 2324,
      "kana": "しんゆう",
      "kanji": "親友",
      "type": "n",
      "meaning": "close friend, buddy"
    },
    {
      "id": 2325,
      "kana": "しんよう",
      "kanji": "信用",
      "type": "n,vs",
      "meaning": "confidence, dependence, credit, faith"
    },
    {
      "id": 2326,
      "kana": "しんらい",
      "kanji": "信頼",
      "type": "n,vs",
      "meaning": "reliance, trust, confidence"
    },
    {
      "id": 2327,
      "kana": "しんり",
      "kanji": "心理",
      "type": "n",
      "meaning": "mentality"
    },
    {
      "id": 2328,
      "kana": "しんりん",
      "kanji": "森林",
      "type": "n",
      "meaning": "forest, woods"
    },
    {
      "id": 2329,
      "kana": "しんるい",
      "kanji": "親類",
      "type": "n",
      "meaning": "relation, kin"
    },
    {
      "id": 2330,
      "kana": "じんるい",
      "kanji": "人類",
      "type": "n",
      "meaning": "mankind, humanity"
    },
    {
      "id": 2331,
      "kana": "しんろ",
      "kanji": "針路",
      "type": "n",
      "meaning": "course, direction, compass bearing"
    },
    {
      "id": 2332,
      "kana": "しんわ",
      "kanji": "神話",
      "type": "n",
      "meaning": "myth, legend"
    },
    {
      "id": 2333,
      "kana": "す",
      "kanji": "巣",
      "type": "n",
      "meaning": "nest, rookery, breeding place, beehive, cobweb"
    },
    {
      "id": 2334,
      "kana": "す",
      "kanji": "酢",
      "type": "n",
      "meaning": "vinegar"
    },
    {
      "id": 2335,
      "kana": "ず",
      "kanji": "図",
      "type": "n,n-suf",
      "meaning": "figure (e.g. Fig 1), drawing, picture, illustration"
    },
    {
      "id": 2336,
      "kana": "すいえい",
      "kanji": "水泳",
      "type": "n,vs",
      "meaning": "swimming"
    },
    {
      "id": 2337,
      "kana": "すいさん",
      "kanji": "水産",
      "type": "n",
      "meaning": "marine products, fisheries"
    },
    {
      "id": 2338,
      "kana": "すいじ",
      "kanji": "炊事",
      "type": "n",
      "meaning": "cooking, culinary arts"
    },
    {
      "id": 2339,
      "kana": "すいじゅん",
      "kanji": "水準",
      "type": "n",
      "meaning": "(1) water level, (2) level, standard"
    },
    {
      "id": 2340,
      "kana": "すいじょうき",
      "kanji": "水蒸気",
      "type": "n",
      "meaning": "water vapour, steam"
    },
    {
      "id": 2341,
      "kana": "すいせん",
      "kanji": "推薦",
      "type": "n,vs",
      "meaning": "recommendation"
    },
    {
      "id": 2342,
      "kana": "すいそ",
      "kanji": "水素",
      "type": "n",
      "meaning": "hydrogen"
    },
    {
      "id": 2343,
      "kana": "すいちょく",
      "kanji": "垂直",
      "type": "adj-na,n",
      "meaning": "vertical, perpendicular"
    },
    {
      "id": 2344,
      "kana": "スイッチ",
      "kanji": "",
      "type": "n",
      "meaning": "switch"
    },
    {
      "id": 2345,
      "kana": "すいてい",
      "kanji": "推定",
      "type": "n,vs",
      "meaning": "presumption, assumption, estimation"
    },
    {
      "id": 2346,
      "kana": "すいてき",
      "kanji": "水滴",
      "type": "n",
      "meaning": "drop of water"
    },
    {
      "id": 2347,
      "kana": "すいとう",
      "kanji": "水筒",
      "type": "n",
      "meaning": "canteen, flask, water bottle"
    },
    {
      "id": 2348,
      "kana": "すいどう",
      "kanji": "水道",
      "type": "n",
      "meaning": "water service, water supply"
    },
    {
      "id": 2349,
      "kana": "ずいひつ",
      "kanji": "随筆",
      "type": "n",
      "meaning": "essays, miscellaneous writings"
    },
    {
      "id": 2350,
      "kana": "すいぶん",
      "kanji": "水分",
      "type": "n",
      "meaning": "moisture"
    },
    {
      "id": 2351,
      "kana": "ずいぶん",
      "kanji": "随分",
      "type": "adj-na,n-adv",
      "meaning": "extremely"
    },
    {
      "id": 2352,
      "kana": "すいへい",
      "kanji": "水平",
      "type": "adj-na,n",
      "meaning": "water level, horizon"
    },
    {
      "id": 2353,
      "kana": "すいへいせん",
      "kanji": "水平線",
      "type": "n",
      "meaning": "horizon"
    },
    {
      "id": 2354,
      "kana": "すいみん",
      "kanji": "睡眠",
      "type": "n",
      "meaning": "sleep"
    },
    {
      "id": 2355,
      "kana": "すいめん",
      "kanji": "水面",
      "type": "n",
      "meaning": "water's surface"
    },
    {
      "id": 2356,
      "kana": "すいよう",
      "kanji": "水曜",
      "type": "n-adv,n",
      "meaning": "Wednesday"
    },
    {
      "id": 2357,
      "kana": "すう",
      "kanji": "吸う",
      "type": "v5u",
      "meaning": "to smoke, to breathe in, to suck"
    },
    {
      "id": 2358,
      "kana": "すう",
      "kanji": "数",
      "type": "n,n-suf",
      "meaning": "number, figure"
    },
    {
      "id": 2359,
      "kana": "すうがく",
      "kanji": "数学",
      "type": "n",
      "meaning": "mathematics, arithmetic"
    },
    {
      "id": 2360,
      "kana": "すうじ",
      "kanji": "数字",
      "type": "n",
      "meaning": "numeral, figure"
    },
    {
      "id": 2361,
      "kana": "ずうずうしい",
      "kanji": "",
      "type": "adj",
      "meaning": "impudent, shameless"
    },
    {
      "id": 2362,
      "kana": "スーツ",
      "kanji": "",
      "type": "n",
      "meaning": "suit"
    },
    {
      "id": 2363,
      "kana": "スーツケース",
      "kanji": "",
      "type": "n",
      "meaning": "suitcase"
    },
    {
      "id": 2364,
      "kana": "ずうっと",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2365,
      "kana": "スーパー",
      "kanji": "",
      "type": "n",
      "meaning": "(1) super, (2) supermarket (abbr)"
    },
    {
      "id": 2366,
      "kana": "スープ",
      "kanji": "",
      "type": "n",
      "meaning": "(Western) soup"
    },
    {
      "id": 2367,
      "kana": "すえ",
      "kanji": "末",
      "type": "n-adv,n",
      "meaning": "the end of, powder"
    },
    {
      "id": 2368,
      "kana": "すえっこ",
      "kanji": "末っ子",
      "type": "n",
      "meaning": "youngest child"
    },
    {
      "id": 2369,
      "kana": "すがた",
      "kanji": "姿",
      "type": "n",
      "meaning": "figure, shape, appearance"
    },
    {
      "id": 2370,
      "kana": "スカート",
      "kanji": "",
      "type": "n",
      "meaning": "skirt"
    },
    {
      "id": 2371,
      "kana": "スカーフ",
      "kanji": "",
      "type": "n",
      "meaning": "scarf"
    },
    {
      "id": 2372,
      "kana": "ずかん",
      "kanji": "図鑑",
      "type": "n",
      "meaning": "picture book"
    },
    {
      "id": 2373,
      "kana": "すき",
      "kanji": "好き",
      "type": "adj-na,n",
      "meaning": "liking, fondness, love"
    },
    {
      "id": 2374,
      "kana": "すき",
      "kanji": "隙",
      "type": "n",
      "meaning": "(1) chance or opportunity, chink (in one's armor), (2) interval, gap"
    },
    {
      "id": 2375,
      "kana": "すぎ",
      "kanji": "杉",
      "type": "n",
      "meaning": "Japanese cedar"
    },
    {
      "id": 2376,
      "kana": "~すぎ",
      "kanji": "~過ぎ",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2377,
      "kana": "スキー",
      "kanji": "",
      "type": "n",
      "meaning": "skiing"
    },
    {
      "id": 2378,
      "kana": "すききらい",
      "kanji": "好き嫌い",
      "type": "n",
      "meaning": "likes and dislikes, taste"
    },
    {
      "id": 2379,
      "kana": "すきずき",
      "kanji": "好き好き",
      "type": "n",
      "meaning": "matter of taste"
    },
    {
      "id": 2380,
      "kana": "すきとおる",
      "kanji": "透き通る",
      "type": "v5r",
      "meaning": "to be(come) transparent"
    },
    {
      "id": 2381,
      "kana": "すきま",
      "kanji": "隙間",
      "type": "n",
      "meaning": "crevice, crack, gap, opening"
    },
    {
      "id": 2382,
      "kana": "すぎる",
      "kanji": "過ぎる",
      "type": "v1,vi",
      "meaning": "to pass, to go beyond, to elapse, to exceed"
    },
    {
      "id": 2383,
      "kana": "~すぎる",
      "kanji": "~過ぎる",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2384,
      "kana": "すく",
      "kanji": "空く",
      "type": "v5k,vi",
      "meaning": "(1) to open, to become empty, (2) to be less crowded"
    },
    {
      "id": 2385,
      "kana": "すぐに",
      "kanji": "",
      "type": "adv",
      "meaning": "instantly"
    },
    {
      "id": 2386,
      "kana": "すくう",
      "kanji": "救う",
      "type": "v5u",
      "meaning": "to rescue from, to help out of"
    },
    {
      "id": 2387,
      "kana": "スクール",
      "kanji": "",
      "type": "n",
      "meaning": "school"
    },
    {
      "id": 2388,
      "kana": "すくない",
      "kanji": "少ない",
      "type": "adj",
      "meaning": "few, a little, scarce, insufficient, seldom"
    },
    {
      "id": 2389,
      "kana": "すくなくとも",
      "kanji": "",
      "type": "adv",
      "meaning": "at least"
    },
    {
      "id": 2390,
      "kana": "すぐれる",
      "kanji": "優れる",
      "type": "v1",
      "meaning": "to surpass, to outstrip, to excel"
    },
    {
      "id": 2391,
      "kana": "ずけい",
      "kanji": "図形",
      "type": "n",
      "meaning": "figure"
    },
    {
      "id": 2392,
      "kana": "スケート",
      "kanji": "",
      "type": "n,vs",
      "meaning": "skate(s), skating"
    },
    {
      "id": 2393,
      "kana": "スケジュール",
      "kanji": "",
      "type": "n",
      "meaning": "schedule"
    },
    {
      "id": 2394,
      "kana": "すごい",
      "kanji": "",
      "type": "adj",
      "meaning": "terrible, dreadful, terrific, amazing, great"
    },
    {
      "id": 2395,
      "kana": "すこし",
      "kanji": "少し",
      "type": "adv,n",
      "meaning": "(1) small quantity, little, (2) little while, (3) short distance"
    },
    {
      "id": 2396,
      "kana": "すこしも",
      "kanji": "少しも",
      "type": "adv,exp",
      "meaning": "anything of, not one bit"
    },
    {
      "id": 2397,
      "kana": "すごす",
      "kanji": "過ごす",
      "type": "v5s,vt",
      "meaning": "to pass, to spend, to go through, to tide over"
    },
    {
      "id": 2398,
      "kana": "すじ",
      "kanji": "筋",
      "type": "n",
      "meaning": "muscle, string, line"
    },
    {
      "id": 2399,
      "kana": "すず",
      "kanji": "鈴",
      "type": "n",
      "meaning": "bell"
    },
    {
      "id": 2400,
      "kana": "すずしい",
      "kanji": "涼しい",
      "type": "adj",
      "meaning": "cool, refreshing"
    },
    {
      "id": 2401,
      "kana": "すすむ",
      "kanji": "進む",
      "type": "v5m,vi",
      "meaning": "to make progress, to advance, to improve"
    },
    {
      "id": 2402,
      "kana": "すずむ",
      "kanji": "涼む",
      "type": "v5m",
      "meaning": "to cool oneself, to cool off, to enjoy evening cool"
    },
    {
      "id": 2403,
      "kana": "すすめる",
      "kanji": "進める",
      "type": "v1,vt",
      "meaning": "to advance, to promote, to hasten"
    },
    {
      "id": 2404,
      "kana": "すすめる",
      "kanji": "勧める",
      "type": "v1",
      "meaning": "to recommend, to advise, to encourage, to offer (wine)"
    },
    {
      "id": 2405,
      "kana": "スター",
      "kanji": "",
      "type": "n",
      "meaning": "star"
    },
    {
      "id": 2406,
      "kana": "スタート",
      "kanji": "",
      "type": "n",
      "meaning": "start"
    },
    {
      "id": 2407,
      "kana": "スタイル",
      "kanji": "",
      "type": "n",
      "meaning": "style"
    },
    {
      "id": 2408,
      "kana": "スタンド",
      "kanji": "",
      "type": "n",
      "meaning": "stand"
    },
    {
      "id": 2409,
      "kana": "スチュワーデス",
      "kanji": "",
      "type": "n",
      "meaning": "stewardess"
    },
    {
      "id": 2410,
      "kana": "~ずつ",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2411,
      "kana": "ずつう",
      "kanji": "頭痛",
      "type": "n",
      "meaning": "headache"
    },
    {
      "id": 2412,
      "kana": "すっかり",
      "kanji": "",
      "type": "adv",
      "meaning": "all, completely, thoroughly"
    },
    {
      "id": 2413,
      "kana": "すっきり",
      "kanji": "",
      "type": "adv,n",
      "meaning": "shapely, clear, neat"
    },
    {
      "id": 2414,
      "kana": "すっと",
      "kanji": "",
      "type": "adv,n",
      "meaning": "straight, quickly, directly"
    },
    {
      "id": 2415,
      "kana": "ずっと",
      "kanji": "",
      "type": "adv",
      "meaning": "consecutively, throughout, a lot"
    },
    {
      "id": 2416,
      "kana": "すっぱい",
      "kanji": "",
      "type": "adj",
      "meaning": "sour, acid"
    },
    {
      "id": 2417,
      "kana": "ステージ",
      "kanji": "",
      "type": "n",
      "meaning": "(1) stage, (2) performance"
    },
    {
      "id": 2418,
      "kana": "すてき",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "lovely, dreamy, beautiful, great"
    },
    {
      "id": 2419,
      "kana": "すでに",
      "kanji": "既に",
      "type": "adv",
      "meaning": "already, too late"
    },
    {
      "id": 2420,
      "kana": "すてる",
      "kanji": "捨てる",
      "type": "v1",
      "meaning": "to throw away, to cast aside, to abandon, to resign"
    },
    {
      "id": 2421,
      "kana": "すてる",
      "kanji": "棄てる",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2422,
      "kana": "ステレオ",
      "kanji": "",
      "type": "n",
      "meaning": "stereo"
    },
    {
      "id": 2423,
      "kana": "ストッキング",
      "kanji": "",
      "type": "n",
      "meaning": "stockings"
    },
    {
      "id": 2424,
      "kana": "ストップ",
      "kanji": "",
      "type": "n",
      "meaning": "stop"
    },
    {
      "id": 2425,
      "kana": "すな",
      "kanji": "砂",
      "type": "n",
      "meaning": "sand, grit"
    },
    {
      "id": 2426,
      "kana": "すなお",
      "kanji": "素直",
      "type": "adj-na,n",
      "meaning": "obedient, meek, docile, unaffected"
    },
    {
      "id": 2427,
      "kana": "すなわち",
      "kanji": "",
      "type": "adv,conj",
      "meaning": "that is, namely, i.e."
    },
    {
      "id": 2428,
      "kana": "ずのう",
      "kanji": "頭脳",
      "type": "n",
      "meaning": "head, brains, intellect"
    },
    {
      "id": 2429,
      "kana": "すばらしい",
      "kanji": "素晴らしい",
      "type": "adj",
      "meaning": "wonderful, splendid, magnificent"
    },
    {
      "id": 2430,
      "kana": "スピーカー",
      "kanji": "",
      "type": "n",
      "meaning": "speaker"
    },
    {
      "id": 2431,
      "kana": "スピーチ",
      "kanji": "",
      "type": "n",
      "meaning": "speech"
    },
    {
      "id": 2432,
      "kana": "ずひょう",
      "kanji": "図表",
      "type": "n",
      "meaning": "chart, diagram, graph"
    },
    {
      "id": 2433,
      "kana": "スプーン",
      "kanji": "",
      "type": "n",
      "meaning": "spoon"
    },
    {
      "id": 2434,
      "kana": "すべて",
      "kanji": "全て",
      "type": "adj-no,n-adv,n",
      "meaning": "all, the whole, entirely, in general, wholly"
    },
    {
      "id": 2435,
      "kana": "すべる",
      "kanji": "滑る",
      "type": "v5r",
      "meaning": "to glide, to slide, to slip"
    },
    {
      "id": 2436,
      "kana": "スポーツ",
      "kanji": "",
      "type": "n",
      "meaning": "sport"
    },
    {
      "id": 2437,
      "kana": "スマート",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "smart, stylish, slim"
    },
    {
      "id": 2438,
      "kana": "すまい",
      "kanji": "住まい",
      "type": "n",
      "meaning": "dwelling, house, residence, address"
    },
    {
      "id": 2439,
      "kana": "すませる",
      "kanji": "済ませる",
      "type": "v1",
      "meaning": "to be finished"
    },
    {
      "id": 2440,
      "kana": "すまない",
      "kanji": "",
      "type": "adj,exp",
      "meaning": "sorry (phrase)"
    },
    {
      "id": 2441,
      "kana": "すみ",
      "kanji": "隅",
      "type": "n,n-suf",
      "meaning": "corner, nook"
    },
    {
      "id": 2442,
      "kana": "すみ",
      "kanji": "角",
      "type": "n",
      "meaning": "horn"
    },
    {
      "id": 2443,
      "kana": "すみ",
      "kanji": "墨",
      "type": "n",
      "meaning": "ink"
    },
    {
      "id": 2444,
      "kana": "~ずみ",
      "kanji": "~済",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2445,
      "kana": "（感）",
      "kanji": "すみません",
      "type": "exp,int",
      "meaning": "sorry, excuse me"
    },
    {
      "id": 2446,
      "kana": "すむ",
      "kanji": "住む",
      "type": "v5m",
      "meaning": "to abide, to reside, to live in, to inhabit, to dwell"
    },
    {
      "id": 2447,
      "kana": "すむ",
      "kanji": "澄む",
      "type": "v5m",
      "meaning": "to clear (e.g. weather), to become transparent"
    },
    {
      "id": 2448,
      "kana": "すむ",
      "kanji": "清む",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2449,
      "kana": "すむ",
      "kanji": "済む",
      "type": "v5m",
      "meaning": "to finish, to end, to be completed"
    },
    {
      "id": 2450,
      "kana": "すもう",
      "kanji": "相撲",
      "type": "n",
      "meaning": "sumo wrestling"
    },
    {
      "id": 2451,
      "kana": "スライド",
      "kanji": "",
      "type": "n",
      "meaning": "slide"
    },
    {
      "id": 2452,
      "kana": "ずらす",
      "kanji": "",
      "type": "v5s",
      "meaning": "to put off, to delay"
    },
    {
      "id": 2453,
      "kana": "ずらり",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2454,
      "kana": "すり",
      "kanji": "掏摸",
      "type": "n",
      "meaning": "pickpocket"
    },
    {
      "id": 2455,
      "kana": "スリッパ",
      "kanji": "",
      "type": "n",
      "meaning": "slippers"
    },
    {
      "id": 2456,
      "kana": "する",
      "kanji": "刷る",
      "type": "v5r",
      "meaning": "to print"
    },
    {
      "id": 2457,
      "kana": "する",
      "kanji": "為る",
      "type": "v5r",
      "meaning": "to change, to be of use, to reach to"
    },
    {
      "id": 2458,
      "kana": "ずるい",
      "kanji": "狡い",
      "type": "adj",
      "meaning": "sly, cunning"
    },
    {
      "id": 2459,
      "kana": "すると",
      "kanji": "すると",
      "type": "conj",
      "meaning": "thereupon, hereupon"
    },
    {
      "id": 2460,
      "kana": "するどい",
      "kanji": "鋭い",
      "type": "adj",
      "meaning": "pointed, sharp"
    },
    {
      "id": 2461,
      "kana": "すれちがう",
      "kanji": "",
      "type": "v5u",
      "meaning": "to pass by one another, to disagree"
    },
    {
      "id": 2462,
      "kana": "ずれる",
      "kanji": "滑れる",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2463,
      "kana": "すわる",
      "kanji": "座る",
      "type": "v5r",
      "meaning": "to sit"
    },
    {
      "id": 2464,
      "kana": "すんぽう",
      "kanji": "寸法",
      "type": "n",
      "meaning": "measurement, size, dimension"
    },
    {
      "id": 2465,
      "kana": "せ",
      "kanji": "背",
      "type": "n",
      "meaning": "height, stature"
    },
    {
      "id": 2466,
      "kana": "せい",
      "kanji": "背",
      "type": "n",
      "meaning": "height, stature"
    },
    {
      "id": 2467,
      "kana": "せい",
      "kanji": "正",
      "type": "n",
      "meaning": "(logical) true, regular"
    },
    {
      "id": 2468,
      "kana": "せい",
      "kanji": "生",
      "type": "adj-na,adj-no,n",
      "meaning": "(1) draft (beer), (2) raw, unprocessed"
    },
    {
      "id": 2469,
      "kana": "せい",
      "kanji": "性",
      "type": "n,n-suf",
      "meaning": "sex, gender"
    },
    {
      "id": 2470,
      "kana": "せい",
      "kanji": "姓",
      "type": "n",
      "meaning": "surname, family name"
    },
    {
      "id": 2471,
      "kana": "せい",
      "kanji": "所為",
      "type": "n",
      "meaning": "cause, reason, fault"
    },
    {
      "id": 2472,
      "kana": "~せい",
      "kanji": "~製",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2473,
      "kana": "~せい",
      "kanji": "~性",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2474,
      "kana": "ぜい",
      "kanji": "税",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2475,
      "kana": "せいかく",
      "kanji": "性格",
      "type": "n",
      "meaning": "character, personality"
    },
    {
      "id": 2476,
      "kana": "せいかく",
      "kanji": "正確",
      "type": "adj-na,n",
      "meaning": "accurate, punctuality, exactness, authenticity, veracity"
    },
    {
      "id": 2477,
      "kana": "せいかつ",
      "kanji": "生活",
      "type": "n,vs",
      "meaning": "living, life (one's daily existence), livelihood"
    },
    {
      "id": 2478,
      "kana": "ぜいかん",
      "kanji": "税関",
      "type": "n",
      "meaning": "customs house"
    },
    {
      "id": 2479,
      "kana": "せいき",
      "kanji": "世紀",
      "type": "n",
      "meaning": "century, era"
    },
    {
      "id": 2480,
      "kana": "せいきゅう",
      "kanji": "請求",
      "type": "n,vs",
      "meaning": "claim, demand, application, request"
    },
    {
      "id": 2481,
      "kana": "ぜいきん",
      "kanji": "税金",
      "type": "n",
      "meaning": "tax, duty"
    },
    {
      "id": 2482,
      "kana": "せいけつ",
      "kanji": "清潔",
      "type": "adj-na,n",
      "meaning": "clean"
    },
    {
      "id": 2483,
      "kana": "せいげん",
      "kanji": "制限",
      "type": "n,vs",
      "meaning": "restriction, restraint, limitation"
    },
    {
      "id": 2484,
      "kana": "せいこう",
      "kanji": "成功",
      "type": "n,vs",
      "meaning": "success, hit"
    },
    {
      "id": 2485,
      "kana": "せいさく",
      "kanji": "製作",
      "type": "n,vs",
      "meaning": "manufacture, production"
    },
    {
      "id": 2486,
      "kana": "せいさく",
      "kanji": "制作",
      "type": "n,vs",
      "meaning": "work (film, book)"
    },
    {
      "id": 2487,
      "kana": "せいさん",
      "kanji": "生産",
      "type": "n,vs",
      "meaning": "production, manufacture"
    },
    {
      "id": 2488,
      "kana": "せいじ",
      "kanji": "政治",
      "type": "n",
      "meaning": "politics, government"
    },
    {
      "id": 2489,
      "kana": "せいしき",
      "kanji": "正式",
      "type": "adj-na,n",
      "meaning": "due form, official, formality"
    },
    {
      "id": 2490,
      "kana": "せいしつ",
      "kanji": "性質",
      "type": "n",
      "meaning": "nature, property, disposition"
    },
    {
      "id": 2491,
      "kana": "せいしょ",
      "kanji": "清書",
      "type": "n",
      "meaning": "clean copy"
    },
    {
      "id": 2492,
      "kana": "せいしょうねん",
      "kanji": "青少年",
      "type": "n",
      "meaning": "youth, young person"
    },
    {
      "id": 2493,
      "kana": "せいしん",
      "kanji": "精神",
      "type": "n",
      "meaning": "mind, soul, heart, spirit, intention"
    },
    {
      "id": 2494,
      "kana": "せいじん",
      "kanji": "成人",
      "type": "n",
      "meaning": "adult"
    },
    {
      "id": 2495,
      "kana": "せいすう",
      "kanji": "整数",
      "type": "n",
      "meaning": "integer"
    },
    {
      "id": 2496,
      "kana": "せいぜい",
      "kanji": "精々",
      "type": "adv",
      "meaning": "at the most, at best, to the utmost, as much (far) as possible"
    },
    {
      "id": 2497,
      "kana": "せいせき",
      "kanji": "成績",
      "type": "n",
      "meaning": "results, record"
    },
    {
      "id": 2498,
      "kana": "せいそう",
      "kanji": "清掃",
      "type": "n,vs",
      "meaning": "cleaning"
    },
    {
      "id": 2499,
      "kana": "せいぞう",
      "kanji": "製造",
      "type": "n,vs",
      "meaning": "manufacture, production"
    },
    {
      "id": 2500,
      "kana": "せいぞん",
      "kanji": "生存",
      "type": "n",
      "meaning": "existence, being, survival"
    },
    {
      "id": 2501,
      "kana": "ぜいたく",
      "kanji": "贅沢",
      "type": "adj-na,n",
      "meaning": "luxury, extravagance"
    },
    {
      "id": 2502,
      "kana": "せいちょう",
      "kanji": "成長",
      "type": "n,vs",
      "meaning": "growth, grow to adulthood"
    },
    {
      "id": 2503,
      "kana": "せいちょう",
      "kanji": "生長",
      "type": "n",
      "meaning": "growth, increment"
    },
    {
      "id": 2504,
      "kana": "せいと",
      "kanji": "生徒",
      "type": "n",
      "meaning": "pupil"
    },
    {
      "id": 2505,
      "kana": "せいど",
      "kanji": "制度",
      "type": "n",
      "meaning": "system, institution, organization"
    },
    {
      "id": 2506,
      "kana": "せいとう",
      "kanji": "政党",
      "type": "n",
      "meaning": "(member of) political party"
    },
    {
      "id": 2507,
      "kana": "せいねん",
      "kanji": "青年",
      "type": "n",
      "meaning": "youth, young man"
    },
    {
      "id": 2508,
      "kana": "せいねんがっぴ",
      "kanji": "生年月日",
      "type": "n",
      "meaning": "birth date"
    },
    {
      "id": 2509,
      "kana": "せいのう",
      "kanji": "性能",
      "type": "n",
      "meaning": "ability, efficiency"
    },
    {
      "id": 2510,
      "kana": "せいび",
      "kanji": "整備",
      "type": "n,vs",
      "meaning": "adjustment, completion, consolidation"
    },
    {
      "id": 2511,
      "kana": "せいひん",
      "kanji": "製品",
      "type": "n",
      "meaning": "manufactured goods, finished goods"
    },
    {
      "id": 2512,
      "kana": "せいふ",
      "kanji": "政府",
      "type": "n",
      "meaning": "government, administration"
    },
    {
      "id": 2513,
      "kana": "せいぶつ",
      "kanji": "生物",
      "type": "n",
      "meaning": "raw food"
    },
    {
      "id": 2514,
      "kana": "せいぶん",
      "kanji": "成分",
      "type": "n",
      "meaning": "ingredient, component, composition"
    },
    {
      "id": 2515,
      "kana": "せいべつ",
      "kanji": "性別",
      "type": "n",
      "meaning": "distinction by sex, sex, gender"
    },
    {
      "id": 2516,
      "kana": "せいほうけい",
      "kanji": "正方形",
      "type": "n",
      "meaning": "square"
    },
    {
      "id": 2517,
      "kana": "せいめい",
      "kanji": "生命",
      "type": "n",
      "meaning": "life, existence"
    },
    {
      "id": 2518,
      "kana": "せいもん",
      "kanji": "正門",
      "type": "n",
      "meaning": "main gate, main entrance"
    },
    {
      "id": 2519,
      "kana": "せいよう",
      "kanji": "西洋",
      "type": "n",
      "meaning": "the west, Western countries"
    },
    {
      "id": 2520,
      "kana": "せいり",
      "kanji": "整理",
      "type": "n,vs",
      "meaning": "sorting, arrangement, adjustment, regulation"
    },
    {
      "id": 2521,
      "kana": "せいりつ",
      "kanji": "成立",
      "type": "n,vs",
      "meaning": "coming into existence, arrangements, establishment, completion"
    },
    {
      "id": 2522,
      "kana": "せいれき",
      "kanji": "西暦",
      "type": "n",
      "meaning": "Christian Era, anno domini (A.D.)"
    },
    {
      "id": 2523,
      "kana": "セーター",
      "kanji": "",
      "type": "n",
      "meaning": "sweater, jumper"
    },
    {
      "id": 2524,
      "kana": "せおう",
      "kanji": "背負う",
      "type": "v5u",
      "meaning": "to be burdened with, to carry on back or shoulder"
    },
    {
      "id": 2525,
      "kana": "せかい",
      "kanji": "世界",
      "type": "n",
      "meaning": "the world, society, the universe"
    },
    {
      "id": 2526,
      "kana": "せき",
      "kanji": "席",
      "type": "n",
      "meaning": "seat"
    },
    {
      "id": 2527,
      "kana": "せき",
      "kanji": "咳",
      "type": "n",
      "meaning": "cough"
    },
    {
      "id": 2528,
      "kana": "~せき",
      "kanji": "~隻",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2529,
      "kana": "せきたん",
      "kanji": "石炭",
      "type": "n",
      "meaning": "coal"
    },
    {
      "id": 2530,
      "kana": "せきどう",
      "kanji": "赤道",
      "type": "n",
      "meaning": "equator"
    },
    {
      "id": 2531,
      "kana": "せきにん",
      "kanji": "責任",
      "type": "n",
      "meaning": "duty, responsibility"
    },
    {
      "id": 2532,
      "kana": "せきゆ",
      "kanji": "石油",
      "type": "n",
      "meaning": "oil, petroleum, kerosene"
    },
    {
      "id": 2533,
      "kana": "せけん",
      "kanji": "世間",
      "type": "n",
      "meaning": "world, society"
    },
    {
      "id": 2534,
      "kana": "せつ",
      "kanji": "説",
      "type": "n,n-suf",
      "meaning": "theory"
    },
    {
      "id": 2535,
      "kana": "せっかく",
      "kanji": "折角",
      "type": "adv,n",
      "meaning": "with trouble, at great pains, long-awaited"
    },
    {
      "id": 2536,
      "kana": "せっきょくてき",
      "kanji": "積極的",
      "type": "adj-na",
      "meaning": "positive, active, proactive"
    },
    {
      "id": 2537,
      "kana": "せっきん",
      "kanji": "接近",
      "type": "n,vs",
      "meaning": "getting closer, drawing nearer, approaching"
    },
    {
      "id": 2538,
      "kana": "せっけい",
      "kanji": "設計",
      "type": "n,vs",
      "meaning": "plan, design"
    },
    {
      "id": 2539,
      "kana": "せっけん",
      "kanji": "石鹸",
      "type": "n",
      "meaning": "soap"
    },
    {
      "id": 2540,
      "kana": "せっする",
      "kanji": "接する",
      "type": "vs-s",
      "meaning": "to come in contact with, to connect, to attend, to receive"
    },
    {
      "id": 2541,
      "kana": "せっせと",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2542,
      "kana": "せつぞく",
      "kanji": "接続",
      "type": "n,vs",
      "meaning": "(1) connection, union, join, link, (2) changing trains"
    },
    {
      "id": 2543,
      "kana": "ぜったい",
      "kanji": "絶対",
      "type": "adj-na,n-adv,n",
      "meaning": "absolute, unconditional, absoluteness"
    },
    {
      "id": 2544,
      "kana": "セット",
      "kanji": "",
      "type": "n",
      "meaning": "set"
    },
    {
      "id": 2545,
      "kana": "せつび",
      "kanji": "設備",
      "type": "n,vs",
      "meaning": "equipment, device, facilities, installation"
    },
    {
      "id": 2546,
      "kana": "せつめい",
      "kanji": "説明",
      "type": "n,vs",
      "meaning": "explanation, exposition"
    },
    {
      "id": 2547,
      "kana": "ぜつめつ",
      "kanji": "絶滅",
      "type": "n",
      "meaning": "destruction, extinction"
    },
    {
      "id": 2548,
      "kana": "せつやく",
      "kanji": "節約",
      "type": "n,vs",
      "meaning": "economising, saving"
    },
    {
      "id": 2549,
      "kana": "せともの",
      "kanji": "瀬戸物",
      "type": "n",
      "meaning": "earthenware, crockery, china"
    },
    {
      "id": 2550,
      "kana": "せなか",
      "kanji": "背中",
      "type": "n",
      "meaning": "back (of body)"
    },
    {
      "id": 2551,
      "kana": "ぜひ",
      "kanji": "是非",
      "type": "adv,n",
      "meaning": "certainly, without fail"
    },
    {
      "id": 2552,
      "kana": "ぜひとも",
      "kanji": "",
      "type": "adv",
      "meaning": "by all means (with sense of not taking \"no\" for an answer)"
    },
    {
      "id": 2553,
      "kana": "せびろ",
      "kanji": "背広",
      "type": "n",
      "meaning": "business suit"
    },
    {
      "id": 2554,
      "kana": "せまい",
      "kanji": "狭い",
      "type": "adj",
      "meaning": "narrow, confined, small"
    },
    {
      "id": 2555,
      "kana": "せまる",
      "kanji": "迫る",
      "type": "v5r",
      "meaning": "to draw near, to press"
    },
    {
      "id": 2556,
      "kana": "ゼミ",
      "kanji": "",
      "type": "de:",
      "meaning": "(n) seminar"
    },
    {
      "id": 2557,
      "kana": "せめて",
      "kanji": "",
      "type": "n",
      "meaning": "offense, method of attack"
    },
    {
      "id": 2558,
      "kana": "せめる",
      "kanji": "攻める",
      "type": "v1",
      "meaning": "to attack, to assault"
    },
    {
      "id": 2559,
      "kana": "せめる",
      "kanji": "責める",
      "type": "v1",
      "meaning": "to condemn, to blame, to criticize"
    },
    {
      "id": 2560,
      "kana": "セメント",
      "kanji": "",
      "type": "n",
      "meaning": "cement"
    },
    {
      "id": 2561,
      "kana": "せりふ",
      "kanji": "台詞",
      "type": "n",
      "meaning": "speech, words, one's lines, remarks"
    },
    {
      "id": 2562,
      "kana": "ゼロ",
      "kanji": "",
      "type": "n",
      "meaning": "zero"
    },
    {
      "id": 2563,
      "kana": "せわ",
      "kanji": "世話",
      "type": "n,vs",
      "meaning": "looking after, help, aid, assistance"
    },
    {
      "id": 2564,
      "kana": "せん",
      "kanji": "千",
      "type": "",
      "meaning": "thousand, many"
    },
    {
      "id": 2565,
      "kana": "せん",
      "kanji": "栓",
      "type": "n",
      "meaning": "stopper, cork, stopcock"
    },
    {
      "id": 2566,
      "kana": "せん",
      "kanji": "線",
      "type": "n,n-suf",
      "meaning": "line (also telephone, railway), wire, beam"
    },
    {
      "id": 2567,
      "kana": "~せん",
      "kanji": "~船",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2568,
      "kana": "~せん",
      "kanji": "~戦",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2569,
      "kana": "ぜん",
      "kanji": "善",
      "type": "n",
      "meaning": "good, goodness, right, virtue"
    },
    {
      "id": 2570,
      "kana": "ぜん",
      "kanji": "全",
      "type": "n,pref",
      "meaning": "all, whole, entire, complete, overall, pan"
    },
    {
      "id": 2571,
      "kana": "ぜん~",
      "kanji": "前~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2572,
      "kana": "~ぜん",
      "kanji": "~前",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2573,
      "kana": "ぜんいん",
      "kanji": "全員",
      "type": "n-adv,n",
      "meaning": "all members (unanimity), all hands, the whole crew"
    },
    {
      "id": 2574,
      "kana": "ぜんご",
      "kanji": "前後",
      "type": "n-adv,suf",
      "meaning": "around, throughout, front and back, before and behind, before and after"
    },
    {
      "id": 2575,
      "kana": "せんこう",
      "kanji": "専攻",
      "type": "n,vs",
      "meaning": "major subject, special study"
    },
    {
      "id": 2576,
      "kana": "ぜんこく",
      "kanji": "全国",
      "type": "n",
      "meaning": "country-wide, nation-wide, whole country, national"
    },
    {
      "id": 2577,
      "kana": "せんざい",
      "kanji": "洗剤",
      "type": "n",
      "meaning": "detergent, washing material"
    },
    {
      "id": 2578,
      "kana": "せんじつ",
      "kanji": "先日",
      "type": "n-adv,n-t",
      "meaning": "the other day, a few days ago"
    },
    {
      "id": 2579,
      "kana": "ぜんしゃ",
      "kanji": "前者",
      "type": "n",
      "meaning": "the former"
    },
    {
      "id": 2580,
      "kana": "せんしゅ",
      "kanji": "選手",
      "type": "n",
      "meaning": "(1) player (in game), (2) team"
    },
    {
      "id": 2581,
      "kana": "ぜんしゅう",
      "kanji": "全集",
      "type": "n",
      "meaning": "complete works"
    },
    {
      "id": 2582,
      "kana": "ぜんしん",
      "kanji": "全身",
      "type": "n",
      "meaning": "the whole body, full-length (portrait)"
    },
    {
      "id": 2583,
      "kana": "ぜんしん",
      "kanji": "前進",
      "type": "n",
      "meaning": "advance, drive, progress"
    },
    {
      "id": 2584,
      "kana": "せんす",
      "kanji": "扇子",
      "type": "n",
      "meaning": "folding fan"
    },
    {
      "id": 2585,
      "kana": "せんせい",
      "kanji": "先生",
      "type": "n",
      "meaning": "teacher, master, doctor"
    },
    {
      "id": 2586,
      "kana": "せんせい",
      "kanji": "専制",
      "type": "n",
      "meaning": "despotism, autocracy"
    },
    {
      "id": 2587,
      "kana": "ぜんぜん",
      "kanji": "全然",
      "type": "adv",
      "meaning": "(1) wholly, entirely, completely, (2) not at all (with neg. verb)"
    },
    {
      "id": 2588,
      "kana": "せんせんげつ",
      "kanji": "先々月",
      "type": "n-t",
      "meaning": "month before last"
    },
    {
      "id": 2589,
      "kana": "せんせんしゅう",
      "kanji": "先々週",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2590,
      "kana": "せんぞ",
      "kanji": "先祖",
      "type": "n",
      "meaning": "ancestor"
    },
    {
      "id": 2591,
      "kana": "せんそう",
      "kanji": "戦争",
      "type": "n,vs",
      "meaning": "war"
    },
    {
      "id": 2592,
      "kana": "センター",
      "kanji": "",
      "type": "n",
      "meaning": "a center"
    },
    {
      "id": 2593,
      "kana": "ぜんたい",
      "kanji": "全体",
      "type": "n-adv,n-t",
      "meaning": "whole, entirety, whatever (is the matter)"
    },
    {
      "id": 2594,
      "kana": "せんたく",
      "kanji": "洗濯",
      "type": "n,vs",
      "meaning": "washing, laundry"
    },
    {
      "id": 2595,
      "kana": "せんたく",
      "kanji": "選択",
      "type": "n,vs",
      "meaning": "selection, choice"
    },
    {
      "id": 2596,
      "kana": "せんたん",
      "kanji": "先端",
      "type": "n",
      "meaning": "pointed end, tip, fine point"
    },
    {
      "id": 2597,
      "kana": "センチ",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "centimeter, centi-, 10^-2"
    },
    {
      "id": 2598,
      "kana": "せんでん",
      "kanji": "宣伝",
      "type": "n,vs",
      "meaning": "propaganda, publicity"
    },
    {
      "id": 2599,
      "kana": "せんとう",
      "kanji": "先頭",
      "type": "n",
      "meaning": "head, lead, vanguard, first"
    },
    {
      "id": 2600,
      "kana": "せんぱい",
      "kanji": "先輩",
      "type": "n",
      "meaning": "senior (at work or school), superior, elder"
    },
    {
      "id": 2601,
      "kana": "ぜんぱん",
      "kanji": "全般",
      "type": "adj-no,n",
      "meaning": "(the) whole, universal, wholly, general"
    },
    {
      "id": 2602,
      "kana": "ぜんぶ",
      "kanji": "全部",
      "type": "n-adv,n-t",
      "meaning": "all, entire, whole, altogether"
    },
    {
      "id": 2603,
      "kana": "せんぷうき",
      "kanji": "扇風機",
      "type": "n",
      "meaning": "electric fan"
    },
    {
      "id": 2604,
      "kana": "せんめん",
      "kanji": "洗面",
      "type": "n,vs",
      "meaning": "wash up (one's face), have a wash"
    },
    {
      "id": 2605,
      "kana": "せんもん",
      "kanji": "専門",
      "type": "n",
      "meaning": "speciality, subject of study, expert"
    },
    {
      "id": 2606,
      "kana": "ぜんりょく",
      "kanji": "全力",
      "type": "n",
      "meaning": "all one's power, whole energy"
    },
    {
      "id": 2607,
      "kana": "せんろ",
      "kanji": "線路",
      "type": "n",
      "meaning": "line, track, roadbed"
    },
    {
      "id": 2608,
      "kana": "~そい",
      "kanji": "~沿い",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2609,
      "kana": "そう",
      "kanji": "そう",
      "type": "",
      "meaning": "so"
    },
    {
      "id": 2610,
      "kana": "そう~",
      "kanji": "総~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2611,
      "kana": "~そう",
      "kanji": "~艘",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2612,
      "kana": "ぞう",
      "kanji": "象",
      "type": "n",
      "meaning": "elephant"
    },
    {
      "id": 2613,
      "kana": "そうい",
      "kanji": "相違",
      "type": "n,vs",
      "meaning": "difference, discrepancy, variation"
    },
    {
      "id": 2614,
      "kana": "そういえば",
      "kanji": "",
      "type": "exp",
      "meaning": "which reminds me .."
    },
    {
      "id": 2615,
      "kana": "そうおん",
      "kanji": "騒音",
      "type": "n",
      "meaning": "noise"
    },
    {
      "id": 2616,
      "kana": "ぞうか",
      "kanji": "増加",
      "type": "n,vs",
      "meaning": "increase, addition"
    },
    {
      "id": 2617,
      "kana": "ぞうきん",
      "kanji": "雑巾",
      "type": "n",
      "meaning": "house-cloth, dust cloth"
    },
    {
      "id": 2618,
      "kana": "ぞうげん",
      "kanji": "増減",
      "type": "n,vs",
      "meaning": "increase and decrease, fluctuation"
    },
    {
      "id": 2619,
      "kana": "そうこ",
      "kanji": "倉庫",
      "type": "n",
      "meaning": "storehouse, warehouse, godown"
    },
    {
      "id": 2620,
      "kana": "そうご",
      "kanji": "相互",
      "type": "n",
      "meaning": "mutual, reciprocal"
    },
    {
      "id": 2621,
      "kana": "そうさ",
      "kanji": "操作",
      "type": "n,vs",
      "meaning": "operation, management, processing"
    },
    {
      "id": 2622,
      "kana": "そうさく",
      "kanji": "創作",
      "type": "n",
      "meaning": "production, literary creation, work"
    },
    {
      "id": 2623,
      "kana": "そうじ",
      "kanji": "掃除",
      "type": "n,vs",
      "meaning": "cleaning, sweeping"
    },
    {
      "id": 2624,
      "kana": "そうしき",
      "kanji": "葬式",
      "type": "n",
      "meaning": "funeral"
    },
    {
      "id": 2625,
      "kana": "そうして",
      "kanji": "",
      "type": "conj",
      "meaning": "and, like that"
    },
    {
      "id": 2626,
      "kana": "ぞうせん",
      "kanji": "造船",
      "type": "n",
      "meaning": "shipbuilding"
    },
    {
      "id": 2627,
      "kana": "そうぞう",
      "kanji": "想像",
      "type": "n,vs",
      "meaning": "imagination, guess"
    },
    {
      "id": 2628,
      "kana": "そうぞうしい",
      "kanji": "騒々しい",
      "type": "adj",
      "meaning": "noisy, boisterous"
    },
    {
      "id": 2629,
      "kana": "そうぞく",
      "kanji": "相続",
      "type": "n",
      "meaning": "succession, inheritance"
    },
    {
      "id": 2630,
      "kana": "ぞうだい",
      "kanji": "増大",
      "type": "n,vs",
      "meaning": "enlargement"
    },
    {
      "id": 2631,
      "kana": "そうだん",
      "kanji": "相談",
      "type": "n,vs",
      "meaning": "consultation, discussion"
    },
    {
      "id": 2632,
      "kana": "そうち",
      "kanji": "装置",
      "type": "n,vs",
      "meaning": "equipment, installation, apparatus"
    },
    {
      "id": 2633,
      "kana": "そうっと",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2634,
      "kana": "そうとう",
      "kanji": "相当",
      "type": "adj-na,n-adv",
      "meaning": "suitable, fair, tolerable, proper"
    },
    {
      "id": 2635,
      "kana": "そうべつ",
      "kanji": "送別",
      "type": "n",
      "meaning": "farewell, send-off"
    },
    {
      "id": 2636,
      "kana": "ぞうり",
      "kanji": "草履",
      "type": "n",
      "meaning": "zoori (Japanese footwear), sandals"
    },
    {
      "id": 2637,
      "kana": "そうりだいじん",
      "kanji": "総理大臣",
      "type": "",
      "meaning": "Prime Minister"
    },
    {
      "id": 2638,
      "kana": "そうりょう",
      "kanji": "送料",
      "type": "n",
      "meaning": "postage, carriage"
    },
    {
      "id": 2639,
      "kana": "~そく",
      "kanji": "~足",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2640,
      "kana": "ぞくする",
      "kanji": "属する",
      "type": "vs-s",
      "meaning": "to belong to, to come under, to be affiliated with, to be subject to"
    },
    {
      "id": 2641,
      "kana": "ぞくぞく",
      "kanji": "続々",
      "type": "n-adv",
      "meaning": "successively, one after another"
    },
    {
      "id": 2642,
      "kana": "そくたつ",
      "kanji": "速達",
      "type": "n",
      "meaning": "express, special delivery"
    },
    {
      "id": 2643,
      "kana": "そくてい",
      "kanji": "測定",
      "type": "n,vs",
      "meaning": "measurement"
    },
    {
      "id": 2644,
      "kana": "そくど",
      "kanji": "速度",
      "type": "n",
      "meaning": "speed, velocity, rate"
    },
    {
      "id": 2645,
      "kana": "そくりょう",
      "kanji": "測量",
      "type": "n,vs",
      "meaning": "measurement, surveying"
    },
    {
      "id": 2646,
      "kana": "そくりょく",
      "kanji": "速力",
      "type": "n",
      "meaning": "speed"
    },
    {
      "id": 2647,
      "kana": "そこ",
      "kanji": "そこ",
      "type": "n",
      "meaning": "bottom, sole"
    },
    {
      "id": 2648,
      "kana": "そこ",
      "kanji": "底",
      "type": "n",
      "meaning": "bottom, sole"
    },
    {
      "id": 2649,
      "kana": "そこで",
      "kanji": "",
      "type": "conj",
      "meaning": "so (conj), accordingly, now, then, thereupon"
    },
    {
      "id": 2650,
      "kana": "そしき",
      "kanji": "組織",
      "type": "n,vs",
      "meaning": "(1) organization, (2) structure, construction, (3) tissue, (4) system"
    },
    {
      "id": 2651,
      "kana": "そしつ",
      "kanji": "素質",
      "type": "n",
      "meaning": "character, qualities, genius"
    },
    {
      "id": 2652,
      "kana": "そして",
      "kanji": "",
      "type": "conj",
      "meaning": "and"
    },
    {
      "id": 2653,
      "kana": "そせん",
      "kanji": "祖先",
      "type": "n",
      "meaning": "ancestor"
    },
    {
      "id": 2654,
      "kana": "そそぐ",
      "kanji": "注ぐ",
      "type": "v5g",
      "meaning": "to pour (into), to irrigate, to pay, to fill, to feed (e.g. a fire)"
    },
    {
      "id": 2655,
      "kana": "そそっかしい",
      "kanji": "",
      "type": "adj",
      "meaning": "careless, thoughtless"
    },
    {
      "id": 2656,
      "kana": "そだつ",
      "kanji": "育つ",
      "type": "v5t,vi",
      "meaning": "to raise (child), to be brought up, to grow (up)"
    },
    {
      "id": 2657,
      "kana": "そだてる",
      "kanji": "育てる",
      "type": "v1,vt",
      "meaning": "to raise, to rear, to bring up"
    },
    {
      "id": 2658,
      "kana": "そちら",
      "kanji": "",
      "type": "n",
      "meaning": "over there, the other"
    },
    {
      "id": 2659,
      "kana": "そっち",
      "kanji": "",
      "type": "n",
      "meaning": "your (that) place, the other"
    },
    {
      "id": 2660,
      "kana": "そつぎょう",
      "kanji": "卒業",
      "type": "n,vs",
      "meaning": "graduation"
    },
    {
      "id": 2661,
      "kana": "そっくり",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "all, altogether, entirely, be just like, the splitting image of"
    },
    {
      "id": 2662,
      "kana": "そっちょく",
      "kanji": "卒直",
      "type": "adj-na,n",
      "meaning": "frankness, candour, openheartedness"
    },
    {
      "id": 2663,
      "kana": "そっと",
      "kanji": "",
      "type": "n",
      "meaning": "face of the earth"
    },
    {
      "id": 2664,
      "kana": "そで",
      "kanji": "袖",
      "type": "n",
      "meaning": "sleeve"
    },
    {
      "id": 2665,
      "kana": "そと",
      "kanji": "外",
      "type": "n",
      "meaning": "other place, the rest"
    },
    {
      "id": 2666,
      "kana": "そなえる",
      "kanji": "備える",
      "type": "v1",
      "meaning": "to furnish, to provide for, to equip, to install"
    },
    {
      "id": 2667,
      "kana": "そなえる",
      "kanji": "具える",
      "type": "v1",
      "meaning": "to be furnished with"
    },
    {
      "id": 2668,
      "kana": "その",
      "kanji": "その",
      "type": "adj-pn",
      "meaning": "the, that"
    },
    {
      "id": 2669,
      "kana": "そのうえ",
      "kanji": "",
      "type": "conj",
      "meaning": "in addition, furthermore"
    },
    {
      "id": 2670,
      "kana": "そのうち",
      "kanji": "",
      "type": "adv",
      "meaning": "eventually, sooner or later, of the previously mentioned"
    },
    {
      "id": 2671,
      "kana": "そのころ",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2672,
      "kana": "そのため",
      "kanji": "",
      "type": "",
      "meaning": "hence, for that reason"
    },
    {
      "id": 2673,
      "kana": "そのほか",
      "kanji": "",
      "type": "conj,n",
      "meaning": "otherwise"
    },
    {
      "id": 2674,
      "kana": "そのまま",
      "kanji": "",
      "type": "adj-no",
      "meaning": "without change, as it is (i.e. now)"
    },
    {
      "id": 2675,
      "kana": "そば",
      "kanji": "側",
      "type": "n",
      "meaning": "side, edge, third person"
    },
    {
      "id": 2676,
      "kana": "そば",
      "kanji": "蕎麦",
      "type": "n",
      "meaning": "soba (buckwheat noodles)"
    },
    {
      "id": 2677,
      "kana": "そふ",
      "kanji": "祖父",
      "type": "n",
      "meaning": "grandfather"
    },
    {
      "id": 2678,
      "kana": "ソファー",
      "kanji": "",
      "type": "n",
      "meaning": "sofa, couch"
    },
    {
      "id": 2679,
      "kana": "そぼ",
      "kanji": "祖母",
      "type": "n",
      "meaning": "grandmother"
    },
    {
      "id": 2680,
      "kana": "そまつ",
      "kanji": "粗末",
      "type": "adj-na,n",
      "meaning": "crude, rough, plain, humble"
    },
    {
      "id": 2681,
      "kana": "そら",
      "kanji": "空",
      "type": "n",
      "meaning": "sky"
    },
    {
      "id": 2682,
      "kana": "そる",
      "kanji": "剃る",
      "type": "v5r",
      "meaning": "to shave"
    },
    {
      "id": 2683,
      "kana": "（接。感）",
      "kanji": "それ",
      "type": "n",
      "meaning": "it, that"
    },
    {
      "id": 2684,
      "kana": "それから",
      "kanji": "",
      "type": "uk",
      "meaning": "and then, after that"
    },
    {
      "id": 2685,
      "kana": "それぞれ",
      "kanji": "",
      "type": "",
      "meaning": "each, every, either, respectively, severally"
    },
    {
      "id": 2686,
      "kana": "それで",
      "kanji": "",
      "type": "uk",
      "meaning": "and (conj), thereupon, because of that"
    },
    {
      "id": 2687,
      "kana": "それでは",
      "kanji": "",
      "type": "exp",
      "meaning": "in that situation, well then..."
    },
    {
      "id": 2688,
      "kana": "それでも",
      "kanji": "",
      "type": "uk",
      "meaning": "but (still), and yet, nevertheless, even so, notwithstanding"
    },
    {
      "id": 2689,
      "kana": "それと",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2690,
      "kana": "それとも",
      "kanji": "",
      "type": "conj",
      "meaning": "or, or else"
    },
    {
      "id": 2691,
      "kana": "それなのに",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2692,
      "kana": "それなら",
      "kanji": "",
      "type": "uk",
      "meaning": "If that's the case..., If so..., That being the case..."
    },
    {
      "id": 2693,
      "kana": "それに",
      "kanji": "",
      "type": "uk",
      "meaning": "besides, moreover"
    },
    {
      "id": 2694,
      "kana": "それほど",
      "kanji": "",
      "type": "adv",
      "meaning": "to that degree, extent"
    },
    {
      "id": 2695,
      "kana": "それる",
      "kanji": "逸れる",
      "type": "v1",
      "meaning": "to stray (turn) from subject, to get lost, to go astray"
    },
    {
      "id": 2696,
      "kana": "そろう",
      "kanji": "揃う",
      "type": "v5u",
      "meaning": "to become complete, to be equal, to be all present, to gather"
    },
    {
      "id": 2697,
      "kana": "そろえる",
      "kanji": "揃える",
      "type": "v1",
      "meaning": "to put things in order, to arrange, to make uniform, to get something ready"
    },
    {
      "id": 2698,
      "kana": "そろそろ",
      "kanji": "",
      "type": "n",
      "meaning": "gradually, steadily, quietly, slowly, soon"
    },
    {
      "id": 2699,
      "kana": "そろばん",
      "kanji": "算盤",
      "type": "n",
      "meaning": "abacus"
    },
    {
      "id": 2700,
      "kana": "そん",
      "kanji": "損",
      "type": "adj-na,n,n-suf,vs",
      "meaning": "loss, disadvantage"
    },
    {
      "id": 2701,
      "kana": "そんがい",
      "kanji": "損害",
      "type": "n",
      "meaning": "damage, injury, loss"
    },
    {
      "id": 2702,
      "kana": "そんけい",
      "kanji": "尊敬",
      "type": "n,vs",
      "meaning": "respect, esteem, reverence, honour"
    },
    {
      "id": 2703,
      "kana": "そんざい",
      "kanji": "存在",
      "type": "n,vs",
      "meaning": "existence, being"
    },
    {
      "id": 2704,
      "kana": "ぞんじる",
      "kanji": "存じる",
      "type": "v1",
      "meaning": "(hum) to know"
    },
    {
      "id": 2705,
      "kana": "ぞんずる",
      "kanji": "存ずる",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2706,
      "kana": "そんちょう",
      "kanji": "尊重",
      "type": "n",
      "meaning": "respect, esteem, regard"
    },
    {
      "id": 2707,
      "kana": "そんとく",
      "kanji": "損得",
      "type": "n",
      "meaning": "loss and gain, advantage and disadvantage"
    },
    {
      "id": 2708,
      "kana": "そんな",
      "kanji": "",
      "type": "adj-na,adj-pn,adv,n",
      "meaning": "such, like that, that sort of"
    },
    {
      "id": 2709,
      "kana": "そんなに",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2710,
      "kana": "た",
      "kanji": "田",
      "type": "n",
      "meaning": "rice field"
    },
    {
      "id": 2711,
      "kana": "たんぼ",
      "kanji": "田ぼ",
      "type": "n",
      "meaning": "paddy field, farm"
    },
    {
      "id": 2712,
      "kana": "た",
      "kanji": "他",
      "type": "n-adv,n",
      "meaning": "other (esp. places and things)"
    },
    {
      "id": 2713,
      "kana": "たい",
      "kanji": "対",
      "type": "n",
      "meaning": "pair, couple, set"
    },
    {
      "id": 2714,
      "kana": "だい",
      "kanji": "大",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2715,
      "kana": "だい",
      "kanji": "台",
      "type": "n,n-suf",
      "meaning": "stand, rack, table, support"
    },
    {
      "id": 2716,
      "kana": "だい",
      "kanji": "題",
      "type": "n,vs",
      "meaning": "title, subject, theme, topic"
    },
    {
      "id": 2717,
      "kana": "だい~",
      "kanji": "第~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2718,
      "kana": "~だい",
      "kanji": "~代",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2719,
      "kana": "たいいく",
      "kanji": "体育",
      "type": "n",
      "meaning": "physical education, gymnastics, athletics"
    },
    {
      "id": 2720,
      "kana": "（副）",
      "kanji": "だいいち",
      "type": "adv,n",
      "meaning": "first, foremost, # 1"
    },
    {
      "id": 2721,
      "kana": "たいいん",
      "kanji": "退院",
      "type": "n,vs",
      "meaning": "leaving hospital"
    },
    {
      "id": 2722,
      "kana": "たいおん",
      "kanji": "体温",
      "type": "n",
      "meaning": "temperature (body)"
    },
    {
      "id": 2723,
      "kana": "たいかい",
      "kanji": "大会",
      "type": "n",
      "meaning": "convention, tournament, mass meeting, rally"
    },
    {
      "id": 2724,
      "kana": "だいがく",
      "kanji": "大学",
      "type": "n",
      "meaning": "university"
    },
    {
      "id": 2725,
      "kana": "だいがくいん",
      "kanji": "大学院",
      "type": "n",
      "meaning": "graduate school"
    },
    {
      "id": 2726,
      "kana": "たいき",
      "kanji": "大気",
      "type": "n",
      "meaning": "atmosphere"
    },
    {
      "id": 2727,
      "kana": "だいきん",
      "kanji": "代金",
      "type": "n",
      "meaning": "price, payment, cost, charge"
    },
    {
      "id": 2728,
      "kana": "だいく",
      "kanji": "大工",
      "type": "n",
      "meaning": "carpenter"
    },
    {
      "id": 2729,
      "kana": "たいくつ",
      "kanji": "退屈",
      "type": "adj-na,n,vs",
      "meaning": "tedium, boredom"
    },
    {
      "id": 2730,
      "kana": "たいけい",
      "kanji": "体系",
      "type": "n",
      "meaning": "system, organization"
    },
    {
      "id": 2731,
      "kana": "たいこ",
      "kanji": "太鼓",
      "type": "n",
      "meaning": "drum, tambourine"
    },
    {
      "id": 2732,
      "kana": "たいざい",
      "kanji": "滞在",
      "type": "n,vs",
      "meaning": "stay, sojourn"
    },
    {
      "id": 2733,
      "kana": "たいさく",
      "kanji": "対策",
      "type": "n",
      "meaning": "counter-plan, counter-measure"
    },
    {
      "id": 2734,
      "kana": "たいし",
      "kanji": "大使",
      "type": "n",
      "meaning": "ambassador"
    },
    {
      "id": 2735,
      "kana": "だいじ",
      "kanji": "大事",
      "type": "adj-na,n",
      "meaning": "important, valuable, serious matter"
    },
    {
      "id": 2736,
      "kana": "たいした",
      "kanji": "大した",
      "type": "adj-pn",
      "meaning": "considerable, great, important, significant, a big deal"
    },
    {
      "id": 2737,
      "kana": "たいして",
      "kanji": "大して",
      "type": "adv",
      "meaning": "(not so) much, (not) very"
    },
    {
      "id": 2738,
      "kana": "たいじゅう",
      "kanji": "体重",
      "type": "n",
      "meaning": "one's body weight"
    },
    {
      "id": 2739,
      "kana": "たいしょう",
      "kanji": "対象",
      "type": "n",
      "meaning": "target, object (of worship, study, etc), subject (of taxation, etc)"
    },
    {
      "id": 2740,
      "kana": "たいしょう",
      "kanji": "対照",
      "type": "n",
      "meaning": "contrast, antithesis, comparison"
    },
    {
      "id": 2741,
      "kana": "だいしょう",
      "kanji": "大小",
      "type": "n",
      "meaning": "size"
    },
    {
      "id": 2742,
      "kana": "だいじょうぶ",
      "kanji": "大丈夫",
      "type": "adj-na,adv,n",
      "meaning": "safe, all right, O.K."
    },
    {
      "id": 2743,
      "kana": "だいじん",
      "kanji": "大臣",
      "type": "n",
      "meaning": "cabinet minister"
    },
    {
      "id": 2744,
      "kana": "たいする",
      "kanji": "対する",
      "type": "vs-s",
      "meaning": "to face, to confront, to oppose"
    },
    {
      "id": 2745,
      "kana": "たいせい",
      "kanji": "体制",
      "type": "n",
      "meaning": "order, system, structure, set-up, organization"
    },
    {
      "id": 2746,
      "kana": "たいせき",
      "kanji": "体積",
      "type": "n",
      "meaning": "capacity, volume"
    },
    {
      "id": 2747,
      "kana": "たいせつ",
      "kanji": "大切",
      "type": "adj-na,n",
      "meaning": "important"
    },
    {
      "id": 2748,
      "kana": "たいせん",
      "kanji": "大戦",
      "type": "n",
      "meaning": "great war, great battle"
    },
    {
      "id": 2749,
      "kana": "たいそう",
      "kanji": "大層",
      "type": "adj-na,adv,n",
      "meaning": "very much, exaggerated, very fine"
    },
    {
      "id": 2750,
      "kana": "たいそう",
      "kanji": "体操",
      "type": "n,vs",
      "meaning": "gymnastics, physical exercises, calisthenics"
    },
    {
      "id": 2751,
      "kana": "だいたい",
      "kanji": "大体",
      "type": "n-adv,n-t",
      "meaning": "general, substantially, outline, main point"
    },
    {
      "id": 2752,
      "kana": "たいてい",
      "kanji": "大抵",
      "type": "adj-na,adv,n",
      "meaning": "usually, generally"
    },
    {
      "id": 2753,
      "kana": "たいど",
      "kanji": "態度",
      "type": "n",
      "meaning": "attitude, manner"
    },
    {
      "id": 2754,
      "kana": "だいとうりょう",
      "kanji": "大統領",
      "type": "n",
      "meaning": "president, chief executive"
    },
    {
      "id": 2755,
      "kana": "だいどころ",
      "kanji": "台所",
      "type": "n",
      "meaning": "kitchen"
    },
    {
      "id": 2756,
      "kana": "たいはん",
      "kanji": "大半",
      "type": "n",
      "meaning": "majority, mostly, generally"
    },
    {
      "id": 2757,
      "kana": "だいひょう",
      "kanji": "代表",
      "type": "n,vs",
      "meaning": "representative, representation, delegation, type, example, model"
    },
    {
      "id": 2758,
      "kana": "タイプ",
      "kanji": "",
      "type": "n",
      "meaning": "type, style, typing"
    },
    {
      "id": 2759,
      "kana": "だいぶ",
      "kanji": "大分",
      "type": "adv",
      "meaning": "considerably, greatly, a lot"
    },
    {
      "id": 2760,
      "kana": "だいぶん",
      "kanji": "大分",
      "type": "adv",
      "meaning": "considerably, greatly, a lot"
    },
    {
      "id": 2761,
      "kana": "たいふう",
      "kanji": "台風",
      "type": "n",
      "meaning": "typhoon"
    },
    {
      "id": 2762,
      "kana": "だいぶぶん",
      "kanji": "大部分",
      "type": "n-t",
      "meaning": "most part, greater part, majority"
    },
    {
      "id": 2763,
      "kana": "タイプライター",
      "kanji": "",
      "type": "n",
      "meaning": "typewriter"
    },
    {
      "id": 2764,
      "kana": "たいへん",
      "kanji": "大変",
      "type": "adj-na,adv,n",
      "meaning": "awful, dreadful, terrible, very"
    },
    {
      "id": 2765,
      "kana": "たいほ",
      "kanji": "逮捕",
      "type": "n,vs",
      "meaning": "arrest, apprehension, capture"
    },
    {
      "id": 2766,
      "kana": "たいぼく",
      "kanji": "大木",
      "type": "n",
      "meaning": "large tree"
    },
    {
      "id": 2767,
      "kana": "だいめい",
      "kanji": "題名",
      "type": "n",
      "meaning": "title"
    },
    {
      "id": 2768,
      "kana": "だいめいし",
      "kanji": "代名詞",
      "type": "n",
      "meaning": "pronoun"
    },
    {
      "id": 2769,
      "kana": "タイア",
      "kanji": "",
      "type": "",
      "meaning": "tire, tyre"
    },
    {
      "id": 2770,
      "kana": "ダイヤ",
      "kanji": "",
      "type": "n",
      "meaning": "(1) dyer, (2) diagram, (3) (railway) schedule, (4) diamond"
    },
    {
      "id": 2771,
      "kana": "ダイヤグラム",
      "kanji": "",
      "type": "n",
      "meaning": "diagram"
    },
    {
      "id": 2772,
      "kana": "ダイヤモンド",
      "kanji": "",
      "type": "n",
      "meaning": "diamond"
    },
    {
      "id": 2773,
      "kana": "ダイヤル",
      "kanji": "",
      "type": "n",
      "meaning": "dial"
    },
    {
      "id": 2774,
      "kana": "たいよう",
      "kanji": "太陽",
      "type": "n",
      "meaning": "sun, solar"
    },
    {
      "id": 2775,
      "kana": "たいら",
      "kanji": "平ら",
      "type": "adj-na,n",
      "meaning": "flatness, level, smooth, calm, plain"
    },
    {
      "id": 2776,
      "kana": "だいり",
      "kanji": "代理",
      "type": "n",
      "meaning": "representation, agency, proxy, deputy, agent"
    },
    {
      "id": 2777,
      "kana": "たいりく",
      "kanji": "大陸",
      "type": "n",
      "meaning": "continent"
    },
    {
      "id": 2778,
      "kana": "たいりつ",
      "kanji": "Ͼ立",
      "type": "n",
      "meaning": "confrontation, opposition, antagonism"
    },
    {
      "id": 2779,
      "kana": "たうえ",
      "kanji": "田植え",
      "type": "n",
      "meaning": "rice planting"
    },
    {
      "id": 2780,
      "kana": "たえず",
      "kanji": "絶えず",
      "type": "adv",
      "meaning": "constantly"
    },
    {
      "id": 2781,
      "kana": "だえん",
      "kanji": "楕円",
      "type": "n",
      "meaning": "ellipse"
    },
    {
      "id": 2782,
      "kana": "たおす",
      "kanji": "倒す",
      "type": "v5s,vt",
      "meaning": "to throw down, to beat, to bring down, to blow down"
    },
    {
      "id": 2783,
      "kana": "タオル",
      "kanji": "",
      "type": "n",
      "meaning": "(hand) towel"
    },
    {
      "id": 2784,
      "kana": "たおれる",
      "kanji": "倒れる",
      "type": "v1,vi",
      "meaning": "to collapse, to break down, to go bankrupt, to fall"
    },
    {
      "id": 2785,
      "kana": "だが",
      "kanji": "だが",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2786,
      "kana": "たかい",
      "kanji": "高い",
      "type": "adj",
      "meaning": "tall, high, expensive"
    },
    {
      "id": 2787,
      "kana": "たがい",
      "kanji": "互い",
      "type": "n",
      "meaning": "mutual, reciprocal"
    },
    {
      "id": 2788,
      "kana": "たかめる",
      "kanji": "高める",
      "type": "v1",
      "meaning": "to raise, to lift, to boost"
    },
    {
      "id": 2789,
      "kana": "たがやす",
      "kanji": "耕す",
      "type": "v5s",
      "meaning": "to till, to plow, to cultivate"
    },
    {
      "id": 2790,
      "kana": "たから",
      "kanji": "宝",
      "type": "n",
      "meaning": "treasure"
    },
    {
      "id": 2791,
      "kana": "だから",
      "kanji": "だから",
      "type": "conj,n",
      "meaning": "so, therefore"
    },
    {
      "id": 2792,
      "kana": "たき",
      "kanji": "滝",
      "type": "n",
      "meaning": "waterfall"
    },
    {
      "id": 2793,
      "kana": "たく",
      "kanji": "宅",
      "type": "n",
      "meaning": "house, home, husband"
    },
    {
      "id": 2794,
      "kana": "たく",
      "kanji": "炊く",
      "type": "v5k",
      "meaning": "to boil, to cook"
    },
    {
      "id": 2795,
      "kana": "たく",
      "kanji": "焚く",
      "type": "v5k",
      "meaning": "to burn, to kindle, to build a fire"
    },
    {
      "id": 2796,
      "kana": "だく",
      "kanji": "抱く",
      "type": "v5k",
      "meaning": "(sl) to embrace, to hug, to harbour, to entertain, `to sleep with'"
    },
    {
      "id": 2797,
      "kana": "たくさん",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "many, a lot, much"
    },
    {
      "id": 2798,
      "kana": "タクシー",
      "kanji": "",
      "type": "n",
      "meaning": "taxi"
    },
    {
      "id": 2799,
      "kana": "たくわえる",
      "kanji": "蓄える",
      "type": "v1",
      "meaning": "to store, to lay in stock"
    },
    {
      "id": 2800,
      "kana": "たけ",
      "kanji": "竹",
      "type": "n",
      "meaning": "bamboo, middle (of a three-tier ranking system)"
    },
    {
      "id": 2801,
      "kana": "だけど",
      "kanji": "",
      "type": "conj",
      "meaning": "however"
    },
    {
      "id": 2802,
      "kana": "（~３年前だった）",
      "kanji": "たしか",
      "type": "adj-na,adv,exp,n",
      "meaning": "certain, sure, definite, if I'm not mistaken, if I remember correctly"
    },
    {
      "id": 2803,
      "kana": "たしか",
      "kanji": "確か",
      "type": "adj-na,adv,exp,n",
      "meaning": "certain, sure, definite, if I remember correctly"
    },
    {
      "id": 2804,
      "kana": "たしかめる",
      "kanji": "確かめる",
      "type": "v1",
      "meaning": "to ascertain"
    },
    {
      "id": 2805,
      "kana": "たしょう",
      "kanji": "多少",
      "type": "adj-no,adv,n",
      "meaning": "more or less, somewhat, a little, some"
    },
    {
      "id": 2806,
      "kana": "たす",
      "kanji": "足す",
      "type": "v5s",
      "meaning": "to add (numbers), to do (e.g. one's business)"
    },
    {
      "id": 2807,
      "kana": "だす",
      "kanji": "出す",
      "type": "v5s",
      "meaning": "to put out, to send"
    },
    {
      "id": 2808,
      "kana": "~だす",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2809,
      "kana": "たすかる",
      "kanji": "助かる",
      "type": "v5r",
      "meaning": "to be saved, to be rescued, to survive, to be helpful"
    },
    {
      "id": 2810,
      "kana": "たすける",
      "kanji": "助ける",
      "type": "v1",
      "meaning": "to help, to save, to rescue"
    },
    {
      "id": 2811,
      "kana": "たずねる",
      "kanji": "訪ねる",
      "type": "v1",
      "meaning": "to visit"
    },
    {
      "id": 2812,
      "kana": "たずねる",
      "kanji": "尋ねる",
      "type": "v1",
      "meaning": "to ask, to enquire"
    },
    {
      "id": 2813,
      "kana": "ただ",
      "kanji": "",
      "type": "adj-pn,adv,conj",
      "meaning": "free of charge, mere, only"
    },
    {
      "id": 2814,
      "kana": "ただ",
      "kanji": "只",
      "type": "adj-pn,adv,conj",
      "meaning": "free of charge, mere, sole, only, usual, common"
    },
    {
      "id": 2815,
      "kana": "ただ",
      "kanji": "唯",
      "type": "adj-pn,adv,conj",
      "meaning": "free of charge, mere, sole, only, usual, common"
    },
    {
      "id": 2816,
      "kana": "ただいま",
      "kanji": "",
      "type": "adv,int,n",
      "meaning": "Here I am, I'm home!"
    },
    {
      "id": 2817,
      "kana": "たたかい",
      "kanji": "戦い",
      "type": "n",
      "meaning": "battle, fight, struggle, conflict"
    },
    {
      "id": 2818,
      "kana": "たたかう",
      "kanji": "戦う",
      "type": "v5u",
      "meaning": "to fight, to battle, to combat"
    },
    {
      "id": 2819,
      "kana": "たたく",
      "kanji": "叩く",
      "type": "v5k",
      "meaning": "to strike, to clap, to dust, to beat"
    },
    {
      "id": 2820,
      "kana": "ただし",
      "kanji": "但し",
      "type": "conj",
      "meaning": "but, however, provided that"
    },
    {
      "id": 2821,
      "kana": "ただしい",
      "kanji": "正しい",
      "type": "adj",
      "meaning": "right, just, correct"
    },
    {
      "id": 2822,
      "kana": "ただちに",
      "kanji": "直ちに",
      "type": "adv",
      "meaning": "at once, immediately, directly, in person"
    },
    {
      "id": 2823,
      "kana": "たたみ",
      "kanji": "畳",
      "type": "n",
      "meaning": "tatami mat (Japanese straw floor coverings)"
    },
    {
      "id": 2824,
      "kana": "たたむ",
      "kanji": "畳む",
      "type": "v5m",
      "meaning": "to fold (clothes)"
    },
    {
      "id": 2825,
      "kana": "~たち",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2826,
      "kana": "たちあがる",
      "kanji": "立ち上がる",
      "type": "v5r",
      "meaning": "to stand up"
    },
    {
      "id": 2827,
      "kana": "たちどまる",
      "kanji": "立ち止まる",
      "type": "v5r",
      "meaning": "to stop, to halt, to stand still"
    },
    {
      "id": 2828,
      "kana": "たちば",
      "kanji": "立場",
      "type": "n",
      "meaning": "standpoint, position, situation"
    },
    {
      "id": 2829,
      "kana": "たちまち",
      "kanji": "",
      "type": "adv",
      "meaning": "at once, in a moment, suddenly, all at once"
    },
    {
      "id": 2830,
      "kana": "たつ",
      "kanji": "立つ",
      "type": "v5t",
      "meaning": "to stand"
    },
    {
      "id": 2831,
      "kana": "たつ",
      "kanji": "建つ",
      "type": "v5t",
      "meaning": "to erect, to be erected, to be built"
    },
    {
      "id": 2832,
      "kana": "たつ",
      "kanji": "発つ",
      "type": "v5t",
      "meaning": "to depart (on a plane, train, etc.)"
    },
    {
      "id": 2833,
      "kana": "たつ",
      "kanji": "経つ",
      "type": "v5t",
      "meaning": "to pass, to lapse"
    },
    {
      "id": 2834,
      "kana": "たっする",
      "kanji": "達する",
      "type": "vs-s",
      "meaning": "to reach, to get to"
    },
    {
      "id": 2835,
      "kana": "だっせん",
      "kanji": "脱線",
      "type": "n,vs",
      "meaning": "derailment, digression"
    },
    {
      "id": 2836,
      "kana": "たった",
      "kanji": "唯",
      "type": "adj-pn,adv,conj",
      "meaning": "free of charge, mere, sole, only, usual, common"
    },
    {
      "id": 2837,
      "kana": "だって",
      "kanji": "",
      "type": "conj",
      "meaning": "but, because, even, also, too"
    },
    {
      "id": 2838,
      "kana": "たっぷり",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "full, in plenty, ample"
    },
    {
      "id": 2839,
      "kana": "たて",
      "kanji": "縦",
      "type": "n",
      "meaning": "length, height"
    },
    {
      "id": 2840,
      "kana": "たてもの",
      "kanji": "建物",
      "type": "n",
      "meaning": "building"
    },
    {
      "id": 2841,
      "kana": "たてる",
      "kanji": "立てる",
      "type": "v1",
      "meaning": "to stand (something) up, to erect (something)"
    },
    {
      "id": 2842,
      "kana": "たてる",
      "kanji": "建てる",
      "type": "v1",
      "meaning": "to build, to construct"
    },
    {
      "id": 2843,
      "kana": "だとう",
      "kanji": "妥当",
      "type": "adj-na,n",
      "meaning": "valid, proper, right, appropriate"
    },
    {
      "id": 2844,
      "kana": "たとえ",
      "kanji": "",
      "type": "n",
      "meaning": "simile, metaphor, allegory"
    },
    {
      "id": 2845,
      "kana": "たとえば",
      "kanji": "例えば",
      "type": "adv",
      "meaning": "for example, e.g."
    },
    {
      "id": 2846,
      "kana": "たとえる",
      "kanji": "例える",
      "type": "v1",
      "meaning": "to compare, to liken, to speak figuratively, to illustrate, to use a simile"
    },
    {
      "id": 2847,
      "kana": "たな",
      "kanji": "棚",
      "type": "n",
      "meaning": "shelves, rack"
    },
    {
      "id": 2848,
      "kana": "たに",
      "kanji": "谷",
      "type": "n",
      "meaning": "valley"
    },
    {
      "id": 2849,
      "kana": "たにん",
      "kanji": "他人",
      "type": "n",
      "meaning": "another person, unrelated person, outsider, stranger"
    },
    {
      "id": 2850,
      "kana": "たね",
      "kanji": "種",
      "type": "n",
      "meaning": "(1) seed, (2) material, (3) cause, source"
    },
    {
      "id": 2851,
      "kana": "たのしい",
      "kanji": "楽しい",
      "type": "adj",
      "meaning": "enjoyable, fun"
    },
    {
      "id": 2852,
      "kana": "たのしみ",
      "kanji": "楽しみ",
      "type": "adj-na,n",
      "meaning": "enjoyment, pleasure"
    },
    {
      "id": 2853,
      "kana": "たのしむ",
      "kanji": "楽む",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2854,
      "kana": "たのむ",
      "kanji": "頼む",
      "type": "v5m",
      "meaning": "to request, to beg, to ask"
    },
    {
      "id": 2855,
      "kana": "たのもしい",
      "kanji": "頼もしい",
      "type": "adj",
      "meaning": "reliable, trustworthy, hopeful, promising"
    },
    {
      "id": 2856,
      "kana": "たば",
      "kanji": "束",
      "type": "n",
      "meaning": "handbreadth, bundle"
    },
    {
      "id": 2857,
      "kana": "たばこ",
      "kanji": "",
      "type": "n",
      "meaning": "tobacco (pt: tabaco), cigarettes"
    },
    {
      "id": 2858,
      "kana": "たび",
      "kanji": "足袋",
      "type": "n",
      "meaning": "tabi, Japanese socks (with split toe)"
    },
    {
      "id": 2859,
      "kana": "たび",
      "kanji": "度",
      "type": "n,n-suf",
      "meaning": "counter for occurrences"
    },
    {
      "id": 2860,
      "kana": "たび",
      "kanji": "旅",
      "type": "n,vs",
      "meaning": "travel, trip, journey"
    },
    {
      "id": 2861,
      "kana": "たびたび",
      "kanji": "",
      "type": "adv",
      "meaning": "often, repeatedly, frequently"
    },
    {
      "id": 2862,
      "kana": "ダブル",
      "kanji": "",
      "type": "n",
      "meaning": "double"
    },
    {
      "id": 2863,
      "kana": "たぶん",
      "kanji": "多分",
      "type": "adv,n",
      "meaning": "perhaps, probably"
    },
    {
      "id": 2864,
      "kana": "たべる",
      "kanji": "食べる",
      "type": "v1",
      "meaning": "to eat"
    },
    {
      "id": 2865,
      "kana": "たま",
      "kanji": "玉",
      "type": "n,n-suf",
      "meaning": "ball, sphere, coin"
    },
    {
      "id": 2866,
      "kana": "たま",
      "kanji": "球",
      "type": "n",
      "meaning": "globe, sphere, ball"
    },
    {
      "id": 2867,
      "kana": "たま",
      "kanji": "弾",
      "type": "n",
      "meaning": "bullet, shot, shell"
    },
    {
      "id": 2868,
      "kana": "たま",
      "kanji": "偶",
      "type": "adj-no,n",
      "meaning": "even number, couple, man and wife, friend"
    },
    {
      "id": 2869,
      "kana": "たまご",
      "kanji": "卵",
      "type": "n",
      "meaning": "(1) egg(s), spawn, roe, (2) (an expert) in the making"
    },
    {
      "id": 2870,
      "kana": "だます",
      "kanji": "",
      "type": "v5s",
      "meaning": "to trick, to cheat, to deceive"
    },
    {
      "id": 2871,
      "kana": "たまたま",
      "kanji": "偶々",
      "type": "adv",
      "meaning": "casually, unexpectedly, accidentally, by chance"
    },
    {
      "id": 2872,
      "kana": "たまに",
      "kanji": "",
      "type": "adv,suf",
      "meaning": "occasionally, once in a while"
    },
    {
      "id": 2873,
      "kana": "たまらない",
      "kanji": "",
      "type": "adj,exp",
      "meaning": "intolerable, unbearable, unendurable"
    },
    {
      "id": 2874,
      "kana": "たまる",
      "kanji": "溜まる",
      "type": "v5r",
      "meaning": "to collect, to gather, to save"
    },
    {
      "id": 2875,
      "kana": "だまる",
      "kanji": "黙る",
      "type": "v5r",
      "meaning": "to be silent"
    },
    {
      "id": 2876,
      "kana": "ダム",
      "kanji": "",
      "type": "n",
      "meaning": "dumb"
    },
    {
      "id": 2877,
      "kana": "ため",
      "kanji": "為",
      "type": "n",
      "meaning": "good, advantage, benefit, welfare"
    },
    {
      "id": 2878,
      "kana": "だめ",
      "kanji": "駄目",
      "type": "adj-na,n",
      "meaning": "useless, no good, hopeless"
    },
    {
      "id": 2879,
      "kana": "ためいき",
      "kanji": "溜息",
      "type": "n",
      "meaning": "a sigh"
    },
    {
      "id": 2880,
      "kana": "ためし",
      "kanji": "試し",
      "type": "n",
      "meaning": "trial, test"
    },
    {
      "id": 2881,
      "kana": "ためす",
      "kanji": "試す",
      "type": "v5s",
      "meaning": "to attempt, to test"
    },
    {
      "id": 2882,
      "kana": "ためらう",
      "kanji": "",
      "type": "v5u",
      "meaning": "to hesitate"
    },
    {
      "id": 2883,
      "kana": "ためる",
      "kanji": "溜める",
      "type": "v1",
      "meaning": "to amass, to accumulate"
    },
    {
      "id": 2884,
      "kana": "たより",
      "kanji": "便り",
      "type": "n",
      "meaning": "news, tidings, information, correspondence, letter"
    },
    {
      "id": 2885,
      "kana": "たよる",
      "kanji": "頼る",
      "type": "v5r",
      "meaning": "to rely on, to have recourse to, to depend on"
    },
    {
      "id": 2886,
      "kana": "~だらけ",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2887,
      "kana": "だらしない",
      "kanji": "",
      "type": "adj",
      "meaning": "slovenly, loose, a slut"
    },
    {
      "id": 2888,
      "kana": "たりる",
      "kanji": "足りる",
      "type": "v1",
      "meaning": "to be sufficient, to be enough"
    },
    {
      "id": 2889,
      "kana": "たる",
      "kanji": "足る",
      "type": "v5r",
      "meaning": "to be sufficient, to be enough"
    },
    {
      "id": 2890,
      "kana": "だれ",
      "kanji": "誰",
      "type": "n",
      "meaning": "who"
    },
    {
      "id": 2891,
      "kana": "だれか",
      "kanji": "誰か",
      "type": "n",
      "meaning": "someone, somebody"
    },
    {
      "id": 2892,
      "kana": "たん~",
      "kanji": "短~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2893,
      "kana": "だん",
      "kanji": "段",
      "type": "n",
      "meaning": "step, stair, flight of steps, grade, rank, level"
    },
    {
      "id": 2894,
      "kana": "~だん",
      "kanji": "~団",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2895,
      "kana": "たんい",
      "kanji": "単位",
      "type": "n",
      "meaning": "unit, denomination, credit (in school)"
    },
    {
      "id": 2896,
      "kana": "だんかい",
      "kanji": "段階",
      "type": "n",
      "meaning": "gradation, grade, stage"
    },
    {
      "id": 2897,
      "kana": "たんき",
      "kanji": "短期",
      "type": "n",
      "meaning": "short term"
    },
    {
      "id": 2898,
      "kana": "たんご",
      "kanji": "単語",
      "type": "n",
      "meaning": "word, vocabulary, (usually) single-character word"
    },
    {
      "id": 2899,
      "kana": "たんこう",
      "kanji": "炭鉱",
      "type": "n",
      "meaning": "coal mine, coal pit"
    },
    {
      "id": 2900,
      "kana": "だんし",
      "kanji": "男子",
      "type": "n",
      "meaning": "youth, young man"
    },
    {
      "id": 2901,
      "kana": "たんじゅん",
      "kanji": "単純",
      "type": "adj-na,n",
      "meaning": "simplicity"
    },
    {
      "id": 2902,
      "kana": "たんしょ",
      "kanji": "短所",
      "type": "n",
      "meaning": "(1) defect, demerit, weak point, (2) disadvantage"
    },
    {
      "id": 2903,
      "kana": "たんじょう",
      "kanji": "誕生",
      "type": "n,vs",
      "meaning": "birth"
    },
    {
      "id": 2904,
      "kana": "たんす",
      "kanji": "",
      "type": "n",
      "meaning": "chest of drawers"
    },
    {
      "id": 2905,
      "kana": "ダンス",
      "kanji": "",
      "type": "n,vs",
      "meaning": "dance"
    },
    {
      "id": 2906,
      "kana": "たんすい",
      "kanji": "淡水",
      "type": "n",
      "meaning": "fresh water"
    },
    {
      "id": 2907,
      "kana": "だんすい",
      "kanji": "断水",
      "type": "n",
      "meaning": "water outage"
    },
    {
      "id": 2908,
      "kana": "たんすう",
      "kanji": "単数",
      "type": "n",
      "meaning": "singular (number)"
    },
    {
      "id": 2909,
      "kana": "だんせい",
      "kanji": "男性",
      "type": "n",
      "meaning": "male, man"
    },
    {
      "id": 2910,
      "kana": "だんたい",
      "kanji": "団体",
      "type": "n",
      "meaning": "organization, association"
    },
    {
      "id": 2911,
      "kana": "だんだん",
      "kanji": "",
      "type": "adv,n",
      "meaning": "gradually, by degrees"
    },
    {
      "id": 2912,
      "kana": "だんち",
      "kanji": "団地",
      "type": "n",
      "meaning": "multi-unit apartments"
    },
    {
      "id": 2913,
      "kana": "だんてい",
      "kanji": "断定",
      "type": "n,vs",
      "meaning": "conclusion, decision"
    },
    {
      "id": 2914,
      "kana": "たんとう",
      "kanji": "担当",
      "type": "n,vs",
      "meaning": "(in) charge"
    },
    {
      "id": 2915,
      "kana": "たんなる",
      "kanji": "単なる",
      "type": "adj-pn",
      "meaning": "mere, simple, sheer"
    },
    {
      "id": 2916,
      "kana": "たんに",
      "kanji": "単に",
      "type": "adv,n",
      "meaning": "simply, merely, only, solely"
    },
    {
      "id": 2917,
      "kana": "たんぺん",
      "kanji": "短編",
      "type": "n",
      "meaning": "short (e.g. story, film)"
    },
    {
      "id": 2918,
      "kana": "だんぼう",
      "kanji": "暖房",
      "type": "n",
      "meaning": "heating"
    },
    {
      "id": 2919,
      "kana": "ち",
      "kanji": "血",
      "type": "n",
      "meaning": "blood, consanguinity"
    },
    {
      "id": 2920,
      "kana": "ち",
      "kanji": "地",
      "type": "n,n-suf",
      "meaning": "earth"
    },
    {
      "id": 2921,
      "kana": "ちい",
      "kanji": "地位",
      "type": "n",
      "meaning": "(social) position, status"
    },
    {
      "id": 2922,
      "kana": "ちいき",
      "kanji": "地域",
      "type": "n",
      "meaning": "area, region"
    },
    {
      "id": 2923,
      "kana": "ちいさい",
      "kanji": "小さい",
      "type": "adj",
      "meaning": "small, little, tiny"
    },
    {
      "id": 2924,
      "kana": "チーズ",
      "kanji": "",
      "type": "n",
      "meaning": "cheese"
    },
    {
      "id": 2925,
      "kana": "チーム",
      "kanji": "",
      "type": "n",
      "meaning": "team"
    },
    {
      "id": 2926,
      "kana": "ちえ",
      "kanji": "知恵",
      "type": "n",
      "meaning": "wisdom, wit, sagacity, sense, intelligence, advice"
    },
    {
      "id": 2927,
      "kana": "ちか",
      "kanji": "地下",
      "type": "n",
      "meaning": "basement, underground"
    },
    {
      "id": 2928,
      "kana": "ちかい",
      "kanji": "近い",
      "type": "adj,suf",
      "meaning": "near, close by, short"
    },
    {
      "id": 2929,
      "kana": "ちがい",
      "kanji": "違い",
      "type": "n,n-suf",
      "meaning": "difference, discrepancy"
    },
    {
      "id": 2930,
      "kana": "ちがいない",
      "kanji": "違いない",
      "type": "adj,exp",
      "meaning": "(phrase) sure, no mistaking it, for certain"
    },
    {
      "id": 2931,
      "kana": "ちかう",
      "kanji": "誓う",
      "type": "v5u",
      "meaning": "to swear, to vow, to take an oath, to pledge"
    },
    {
      "id": 2932,
      "kana": "ちがう",
      "kanji": "違う",
      "type": "v5u",
      "meaning": "to differ (from)"
    },
    {
      "id": 2933,
      "kana": "ちかく",
      "kanji": "近く",
      "type": "n-adv,n",
      "meaning": "near, neighbourhood, vicinity"
    },
    {
      "id": 2934,
      "kana": "ちかごろ",
      "kanji": "近頃",
      "type": "n-adv,n-t",
      "meaning": "lately, recently, nowadays"
    },
    {
      "id": 2935,
      "kana": "ちかすい",
      "kanji": "地下水",
      "type": "n",
      "meaning": "underground water"
    },
    {
      "id": 2936,
      "kana": "ちかぢか",
      "kanji": "近々",
      "type": "adv",
      "meaning": "nearness, before long"
    },
    {
      "id": 2937,
      "kana": "ちかづける",
      "kanji": "近付ける",
      "type": "v1",
      "meaning": "to bring near, to put close, to let come near, to associate with"
    },
    {
      "id": 2938,
      "kana": "ちかてつ",
      "kanji": "地下鉄",
      "type": "n",
      "meaning": "underground train, subway"
    },
    {
      "id": 2939,
      "kana": "ちかよる",
      "kanji": "近寄る",
      "type": "v5r",
      "meaning": "to approach, to draw near"
    },
    {
      "id": 2940,
      "kana": "ちから",
      "kanji": "力",
      "type": "n-suf",
      "meaning": "strength, power"
    },
    {
      "id": 2941,
      "kana": "ちからづよい",
      "kanji": "力強い",
      "type": "adj",
      "meaning": "reassuring, emboldened"
    },
    {
      "id": 2942,
      "kana": "ちきゅう",
      "kanji": "地球",
      "type": "adj-no,n",
      "meaning": "the earth"
    },
    {
      "id": 2943,
      "kana": "ちぎる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to cut up fine, to pick (fruit)"
    },
    {
      "id": 2944,
      "kana": "ちく",
      "kanji": "地区",
      "type": "n",
      "meaning": "district, section, sector"
    },
    {
      "id": 2945,
      "kana": "ちこく",
      "kanji": "遅刻",
      "type": "n,vs",
      "meaning": "lateness, late coming"
    },
    {
      "id": 2946,
      "kana": "ちじ",
      "kanji": "知事",
      "type": "n",
      "meaning": "prefectural governor"
    },
    {
      "id": 2947,
      "kana": "ちしき",
      "kanji": "知識",
      "type": "n",
      "meaning": "knowledge, information"
    },
    {
      "id": 2948,
      "kana": "ちしつ",
      "kanji": "地質",
      "type": "n",
      "meaning": "geological features"
    },
    {
      "id": 2949,
      "kana": "ちじん",
      "kanji": "知人",
      "type": "n",
      "meaning": "friend, acquaintance"
    },
    {
      "id": 2950,
      "kana": "ちず",
      "kanji": "地図",
      "type": "n",
      "meaning": "map"
    },
    {
      "id": 2951,
      "kana": "ちたい",
      "kanji": "地帯",
      "type": "n",
      "meaning": "area, zone"
    },
    {
      "id": 2952,
      "kana": "ちち",
      "kanji": "父",
      "type": "n",
      "meaning": "(hum) father"
    },
    {
      "id": 2953,
      "kana": "ちちおや",
      "kanji": "父親",
      "type": "n",
      "meaning": "father"
    },
    {
      "id": 2954,
      "kana": "ちぢむ",
      "kanji": "縮む",
      "type": "v5m",
      "meaning": "to shrink, to be contracted"
    },
    {
      "id": 2955,
      "kana": "ちぢめる",
      "kanji": "縮める",
      "type": "v1",
      "meaning": "to shorten, to reduce, to boil down, to shrink"
    },
    {
      "id": 2956,
      "kana": "ちぢれる",
      "kanji": "縮れる",
      "type": "v1",
      "meaning": "to be wavy, to be curled"
    },
    {
      "id": 2957,
      "kana": "ちっとも",
      "kanji": "",
      "type": "adv",
      "meaning": "not at all (neg. verb)"
    },
    {
      "id": 2958,
      "kana": "チップ",
      "kanji": "",
      "type": "n",
      "meaning": "(1) gratuity, tip, (2) chip"
    },
    {
      "id": 2959,
      "kana": "ちてん",
      "kanji": "地点",
      "type": "n",
      "meaning": "site, point on a map"
    },
    {
      "id": 2960,
      "kana": "ちのう",
      "kanji": "知能",
      "type": "n",
      "meaning": "intelligence, brains"
    },
    {
      "id": 2961,
      "kana": "ちへいせん",
      "kanji": "地平線",
      "type": "n",
      "meaning": "horizon"
    },
    {
      "id": 2962,
      "kana": "ちほう",
      "kanji": "地方",
      "type": "n",
      "meaning": "area, locality, district, region, the coast"
    },
    {
      "id": 2963,
      "kana": "ちめい",
      "kanji": "地名",
      "type": "n",
      "meaning": "place name"
    },
    {
      "id": 2964,
      "kana": "ちゃ",
      "kanji": "茶",
      "type": "n",
      "meaning": "tea"
    },
    {
      "id": 2965,
      "kana": "ちゃいろ",
      "kanji": "茶色",
      "type": "n",
      "meaning": "light brown, tawny"
    },
    {
      "id": 2966,
      "kana": "ちゃいろい",
      "kanji": "茶色い",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2967,
      "kana": "~ちゃく",
      "kanji": "~着",
      "type": "",
      "meaning": "counter for suits of clothing"
    },
    {
      "id": 2968,
      "kana": "ちゃくちゃく",
      "kanji": "着々",
      "type": "adv",
      "meaning": "steadily"
    },
    {
      "id": 2969,
      "kana": "ちゃわん",
      "kanji": "茶碗",
      "type": "n",
      "meaning": "rice bowl, tea cup, teacup"
    },
    {
      "id": 2970,
      "kana": "~ちゃん",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2971,
      "kana": "チャンス",
      "kanji": "",
      "type": "n",
      "meaning": "chance, opportunity"
    },
    {
      "id": 2972,
      "kana": "ちゃんと",
      "kanji": "",
      "type": "adv,n",
      "meaning": "perfectly, properly, exactly"
    },
    {
      "id": 2973,
      "kana": "ちゅう",
      "kanji": "中",
      "type": "n",
      "meaning": "inside, middle, among"
    },
    {
      "id": 2974,
      "kana": "ちゅう",
      "kanji": "注",
      "type": "n,vs",
      "meaning": "annotation, explanatory note"
    },
    {
      "id": 2975,
      "kana": "~ちゅう",
      "kanji": "~中",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2976,
      "kana": "~じゅう",
      "kanji": "~中",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2977,
      "kana": "ちゅうい",
      "kanji": "注意",
      "type": "n,vs",
      "meaning": "caution, being careful, attention (heed), warning, advice"
    },
    {
      "id": 2978,
      "kana": "ちゅうおう",
      "kanji": "中央",
      "type": "n",
      "meaning": "centre, central, center, middle"
    },
    {
      "id": 2979,
      "kana": "ちゅうがく",
      "kanji": "中学",
      "type": "n",
      "meaning": "middle school, junior high school"
    },
    {
      "id": 2980,
      "kana": "ちゅうかん",
      "kanji": "中間",
      "type": "n-adv,n",
      "meaning": "middle, midway, interim"
    },
    {
      "id": 2981,
      "kana": "ちゅうこ",
      "kanji": "中古",
      "type": "n-t",
      "meaning": "(1) used, second-hand, old"
    },
    {
      "id": 2982,
      "kana": "ちゅうし",
      "kanji": "中止",
      "type": "n,vs",
      "meaning": "suspension, stoppage, discontinuance, interruption"
    },
    {
      "id": 2983,
      "kana": "ちゅうしゃ",
      "kanji": "注射",
      "type": "n,vs",
      "meaning": "injection"
    },
    {
      "id": 2984,
      "kana": "ちゅうしゃ",
      "kanji": "駐車",
      "type": "n",
      "meaning": "parking (e.g. car)"
    },
    {
      "id": 2985,
      "kana": "ちゅうじゅん",
      "kanji": "中旬",
      "type": "n-adv,n-t",
      "meaning": "second third of a month"
    },
    {
      "id": 2986,
      "kana": "ちゅうしょう",
      "kanji": "抽象",
      "type": "adj-na,n",
      "meaning": "abstract"
    },
    {
      "id": 2987,
      "kana": "ちゅうしょく",
      "kanji": "昼食",
      "type": "n",
      "meaning": "lunch, midday meal"
    },
    {
      "id": 2988,
      "kana": "ちゅうしん",
      "kanji": "中心",
      "type": "n",
      "meaning": "center, core, heart, pivot, emphasis, balance"
    },
    {
      "id": 2989,
      "kana": "ちゅうせい",
      "kanji": "中世",
      "type": "n-adv,n-t",
      "meaning": "Middle Ages, mediaeval times"
    },
    {
      "id": 2990,
      "kana": "ちゅうせい",
      "kanji": "中性",
      "type": "n",
      "meaning": "neuter gender, neutral (chem.), indifference, sterility"
    },
    {
      "id": 2991,
      "kana": "ちゅうと",
      "kanji": "中途",
      "type": "n",
      "meaning": "in the middle, half-way"
    },
    {
      "id": 2992,
      "kana": "ちゅうねん",
      "kanji": "中年",
      "type": "n",
      "meaning": "middle-aged"
    },
    {
      "id": 2993,
      "kana": "ちゅうもく",
      "kanji": "注目",
      "type": "n,vs",
      "meaning": "notice, attention, observation"
    },
    {
      "id": 2994,
      "kana": "ちゅうもん",
      "kanji": "注文",
      "type": "n,vs",
      "meaning": "order, request"
    },
    {
      "id": 2995,
      "kana": "ちょう~",
      "kanji": "長~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2996,
      "kana": "~ちょう",
      "kanji": "~庁",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2997,
      "kana": "~ちょう",
      "kanji": "~兆",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2998,
      "kana": "~ちょう",
      "kanji": "~町",
      "type": "",
      "meaning": ""
    },
    {
      "id": 2999,
      "kana": "~ちょう",
      "kanji": "~長",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3000,
      "kana": "~ちょう",
      "kanji": "~帳",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3001,
      "kana": "ちょうか",
      "kanji": "超過",
      "type": "n,vs",
      "meaning": "excess, being more than"
    },
    {
      "id": 3002,
      "kana": "ちょうき",
      "kanji": "長期",
      "type": "n-adv,n-t",
      "meaning": "long time period"
    },
    {
      "id": 3003,
      "kana": "ちょうこく",
      "kanji": "彫刻",
      "type": "n",
      "meaning": "carving, engraving, sculpture"
    },
    {
      "id": 3004,
      "kana": "ちょうさ",
      "kanji": "調査",
      "type": "n,vs",
      "meaning": "investigation, examination, inquiry, survey"
    },
    {
      "id": 3005,
      "kana": "ちょうし",
      "kanji": "調子",
      "type": "n",
      "meaning": "tune, tone, key"
    },
    {
      "id": 3006,
      "kana": "ちょうしょ",
      "kanji": "長所",
      "type": "n",
      "meaning": "(1) strong point, merit, (2) advantage"
    },
    {
      "id": 3007,
      "kana": "ちょうじょ",
      "kanji": "長女",
      "type": "n",
      "meaning": "eldest daughter"
    },
    {
      "id": 3008,
      "kana": "ちょうじょう",
      "kanji": "頂上",
      "type": "n",
      "meaning": "top, summit, peak"
    },
    {
      "id": 3009,
      "kana": "ちょうせい",
      "kanji": "調整",
      "type": "n,vs",
      "meaning": "regulation, adjustment, tuning"
    },
    {
      "id": 3010,
      "kana": "ちょうせつ",
      "kanji": "調節",
      "type": "n,vs",
      "meaning": "regulation, adjustment, control"
    },
    {
      "id": 3011,
      "kana": "ちょうだい",
      "kanji": "",
      "type": "int,vs",
      "meaning": "(1) please do for me (preceded by -te), (2) reception, being given, get"
    },
    {
      "id": 3012,
      "kana": "ちょうたん",
      "kanji": "長短",
      "type": "n",
      "meaning": "length, long and short, +-"
    },
    {
      "id": 3013,
      "kana": "ちょうてん",
      "kanji": "頂点",
      "type": "n",
      "meaning": "top, summit"
    },
    {
      "id": 3014,
      "kana": "ちょうど",
      "kanji": "",
      "type": "n",
      "meaning": "supplies, furniture, fixtures"
    },
    {
      "id": 3015,
      "kana": "ちょうなん",
      "kanji": "長男",
      "type": "n",
      "meaning": "eldest son"
    },
    {
      "id": 3016,
      "kana": "ちょうほうけい",
      "kanji": "長方形",
      "type": "n",
      "meaning": "rectangle, oblong"
    },
    {
      "id": 3017,
      "kana": "ちょうみりょう",
      "kanji": "調味料",
      "type": "n",
      "meaning": "condiment, seasoning"
    },
    {
      "id": 3018,
      "kana": "~ちょうめ",
      "kanji": "~丁目",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3019,
      "kana": "チョーク",
      "kanji": "",
      "type": "n",
      "meaning": "chock, chalk"
    },
    {
      "id": 3020,
      "kana": "ちょきん",
      "kanji": "貯金",
      "type": "n,vs",
      "meaning": "(bank) savings"
    },
    {
      "id": 3021,
      "kana": "ちょくご",
      "kanji": "直後",
      "type": "n-adv,n-t",
      "meaning": "immediately following"
    },
    {
      "id": 3022,
      "kana": "ちょくせつ",
      "kanji": "直接",
      "type": "adj-na,n-adv",
      "meaning": "direct, immediate, personal, firsthand"
    },
    {
      "id": 3023,
      "kana": "ちょくせん",
      "kanji": "直線",
      "type": "n",
      "meaning": "straight line"
    },
    {
      "id": 3024,
      "kana": "ちょくぜん",
      "kanji": "直前",
      "type": "n-adv,n-t",
      "meaning": "just before"
    },
    {
      "id": 3025,
      "kana": "ちょくつう",
      "kanji": "直通",
      "type": "n",
      "meaning": "direct communication"
    },
    {
      "id": 3026,
      "kana": "ちょくりゅう",
      "kanji": "直流",
      "type": "n",
      "meaning": "direct current"
    },
    {
      "id": 3027,
      "kana": "ちょしゃ",
      "kanji": "著者",
      "type": "n",
      "meaning": "author, writer"
    },
    {
      "id": 3028,
      "kana": "ちょぞう",
      "kanji": "貯蔵",
      "type": "n",
      "meaning": "storage, preservation"
    },
    {
      "id": 3029,
      "kana": "ちょっかく",
      "kanji": "直角",
      "type": "n",
      "meaning": "right angle"
    },
    {
      "id": 3030,
      "kana": "ちょっけい",
      "kanji": "直径",
      "type": "n",
      "meaning": "diameter"
    },
    {
      "id": 3031,
      "kana": "ちょっと",
      "kanji": "",
      "type": "adv,int",
      "meaning": "just a minute, a short time, a while, just a little"
    },
    {
      "id": 3032,
      "kana": "ちらかす",
      "kanji": "散らかす",
      "type": "v5s",
      "meaning": "to scatter around, to leave untidy"
    },
    {
      "id": 3033,
      "kana": "ちらかる",
      "kanji": "散らかる",
      "type": "v5r",
      "meaning": "to be in disorder, to lie scattered around"
    },
    {
      "id": 3034,
      "kana": "ちらす",
      "kanji": "散らす",
      "type": "v5s",
      "meaning": "to scatter, to disperse, to distribute"
    },
    {
      "id": 3035,
      "kana": "ちり",
      "kanji": "地理",
      "type": "n",
      "meaning": "geography"
    },
    {
      "id": 3036,
      "kana": "ちりがみ",
      "kanji": "塵紙",
      "type": "n",
      "meaning": "tissue paper, toilet paper"
    },
    {
      "id": 3037,
      "kana": "ちる",
      "kanji": "散る",
      "type": "v5r",
      "meaning": "to fall, to scatter (e.g. blossoms)"
    },
    {
      "id": 3038,
      "kana": "ついに",
      "kanji": "遂に",
      "type": "adv",
      "meaning": "finally, at last"
    },
    {
      "id": 3039,
      "kana": "ついか",
      "kanji": "追加",
      "type": "n,vs",
      "meaning": "addition, supplement, appendix"
    },
    {
      "id": 3040,
      "kana": "ついたち",
      "kanji": "一日",
      "type": "n",
      "meaning": "(1) first of month"
    },
    {
      "id": 3041,
      "kana": "~ついて",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3042,
      "kana": "ついで",
      "kanji": "",
      "type": "n",
      "meaning": "opportunity, occasion"
    },
    {
      "id": 3043,
      "kana": "~つう",
      "kanji": "~通",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3044,
      "kana": "つうか",
      "kanji": "通過",
      "type": "n,vs",
      "meaning": "passage through, passing"
    },
    {
      "id": 3045,
      "kana": "つうか",
      "kanji": "通貨",
      "type": "n",
      "meaning": "currency"
    },
    {
      "id": 3046,
      "kana": "つうがく",
      "kanji": "通学",
      "type": "n",
      "meaning": "commuting to school"
    },
    {
      "id": 3047,
      "kana": "つうきん",
      "kanji": "通勤",
      "type": "n,vs",
      "meaning": "commuting to work"
    },
    {
      "id": 3048,
      "kana": "つうこう",
      "kanji": "通行",
      "type": "n,vs",
      "meaning": "passage, passing"
    },
    {
      "id": 3049,
      "kana": "つうじる",
      "kanji": "通じる",
      "type": "v1",
      "meaning": "to run to, to lead to, to communicate, to understand"
    },
    {
      "id": 3050,
      "kana": "つうずる",
      "kanji": "通ずる",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3051,
      "kana": "つうしん",
      "kanji": "通信",
      "type": "n,vs",
      "meaning": "correspondence, communication, news, signal"
    },
    {
      "id": 3052,
      "kana": "つうち",
      "kanji": "通知",
      "type": "n,vs",
      "meaning": "notice, notification"
    },
    {
      "id": 3053,
      "kana": "つうちょう",
      "kanji": "通帳",
      "type": "n",
      "meaning": "passbook"
    },
    {
      "id": 3054,
      "kana": "つうやく",
      "kanji": "通訳",
      "type": "n,vs",
      "meaning": "interpretation"
    },
    {
      "id": 3055,
      "kana": "つうよう",
      "kanji": "通用",
      "type": "n,vs",
      "meaning": "popular use, circulation"
    },
    {
      "id": 3056,
      "kana": "つうろ",
      "kanji": "通路",
      "type": "n",
      "meaning": "passage, pathway"
    },
    {
      "id": 3057,
      "kana": "~づかい",
      "kanji": "~遣い",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3058,
      "kana": "つかう",
      "kanji": "使う",
      "type": "v5u",
      "meaning": "to use, to handle, to manipulate, to employ"
    },
    {
      "id": 3059,
      "kana": "つかまえる",
      "kanji": "捕まえる",
      "type": "v1",
      "meaning": "to catch, to arrest, to seize"
    },
    {
      "id": 3060,
      "kana": "つかまる",
      "kanji": "捕まる",
      "type": "v5r",
      "meaning": "to be caught, to be arrested"
    },
    {
      "id": 3061,
      "kana": "つかむ",
      "kanji": "掴む",
      "type": "v5m",
      "meaning": "to seize, to catch, to grasp"
    },
    {
      "id": 3062,
      "kana": "つかれ",
      "kanji": "疲れ",
      "type": "n",
      "meaning": "tiredness, fatigue"
    },
    {
      "id": 3063,
      "kana": "つかれる",
      "kanji": "疲れる",
      "type": "v1",
      "meaning": "to get tired, to tire"
    },
    {
      "id": 3064,
      "kana": "つき",
      "kanji": "月",
      "type": "n-t",
      "meaning": "moon, month"
    },
    {
      "id": 3065,
      "kana": "つき",
      "kanji": "月",
      "type": "n-t",
      "meaning": "moon, month"
    },
    {
      "id": 3066,
      "kana": "~つき",
      "kanji": "~付",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3067,
      "kana": "つぎ",
      "kanji": "次",
      "type": "n",
      "meaning": "next, stage station, stage, subsequent"
    },
    {
      "id": 3068,
      "kana": "つきあい",
      "kanji": "付き合い",
      "type": "n",
      "meaning": "association, socializing, fellowship"
    },
    {
      "id": 3069,
      "kana": "つきあう",
      "kanji": "付合う",
      "type": "v5u",
      "meaning": "to associate with, to keep company with, to get on with"
    },
    {
      "id": 3070,
      "kana": "つきあたり",
      "kanji": "突き当たり",
      "type": "n",
      "meaning": "end (e.g. of street)"
    },
    {
      "id": 3071,
      "kana": "つきあたる",
      "kanji": "突き当たる",
      "type": "v5r",
      "meaning": "to run into, to collide with"
    },
    {
      "id": 3072,
      "kana": "つぎつぎ",
      "kanji": "次々",
      "type": "adv,n",
      "meaning": "in succession, one by one"
    },
    {
      "id": 3073,
      "kana": "つきひ",
      "kanji": "月日",
      "type": "n",
      "meaning": "time, years, days"
    },
    {
      "id": 3074,
      "kana": "つく",
      "kanji": "付く",
      "type": "v5k,vi",
      "meaning": "to adjoin, to be attached, to adhere"
    },
    {
      "id": 3075,
      "kana": "つく",
      "kanji": "着く",
      "type": "v5k",
      "meaning": "to arrive at, to reach"
    },
    {
      "id": 3076,
      "kana": "つく",
      "kanji": "就く",
      "type": "v5k",
      "meaning": "to settle in (place), to take (seat, position), to study (under teacher)"
    },
    {
      "id": 3077,
      "kana": "つく",
      "kanji": "点く",
      "type": "v5k",
      "meaning": "to catch fire, (electricity) comes on"
    },
    {
      "id": 3078,
      "kana": "つく",
      "kanji": "突く",
      "type": "v5k",
      "meaning": "(1) to thrust, to strike, (2) to poke"
    },
    {
      "id": 3079,
      "kana": "つぐ",
      "kanji": "次ぐ",
      "type": "v5g",
      "meaning": "to rank next to, to come after"
    },
    {
      "id": 3080,
      "kana": "つぐ",
      "kanji": "注ぐ",
      "type": "v5g",
      "meaning": "to pour (into), to irrigate, to pay"
    },
    {
      "id": 3081,
      "kana": "つくえ",
      "kanji": "机",
      "type": "n",
      "meaning": "desk"
    },
    {
      "id": 3082,
      "kana": "つくる",
      "kanji": "作る/造る",
      "type": "v5r",
      "meaning": "to make, to create"
    },
    {
      "id": 3083,
      "kana": "つける",
      "kanji": "付ける",
      "type": "v1,vt",
      "meaning": "to attach, to join, to add, to append"
    },
    {
      "id": 3084,
      "kana": "つける",
      "kanji": "着ける",
      "type": "v1,vt",
      "meaning": "(1) to attach, to join, to add, to append"
    },
    {
      "id": 3085,
      "kana": "つける",
      "kanji": "点ける",
      "type": "v1",
      "meaning": "to turn on, to switch on, to light up"
    },
    {
      "id": 3086,
      "kana": "つける",
      "kanji": "浸ける",
      "type": "v1",
      "meaning": "to dip in, to soak"
    },
    {
      "id": 3087,
      "kana": "つける",
      "kanji": "漬ける",
      "type": "v1",
      "meaning": "to soak, to moisten, to pickle"
    },
    {
      "id": 3088,
      "kana": "つごう",
      "kanji": "都合",
      "type": "adv,n",
      "meaning": "circumstances, condition, convenience"
    },
    {
      "id": 3089,
      "kana": "つたえる",
      "kanji": "伝える",
      "type": "v1",
      "meaning": "to convey, to report, to transmit, to communicate"
    },
    {
      "id": 3090,
      "kana": "つたわる",
      "kanji": "伝わる",
      "type": "v5r",
      "meaning": "to be handed down, to be introduced, to be transmitted"
    },
    {
      "id": 3091,
      "kana": "つち",
      "kanji": "土",
      "type": "n",
      "meaning": "earth, soil"
    },
    {
      "id": 3092,
      "kana": "つづき",
      "kanji": "続き",
      "type": "n,n-suf",
      "meaning": "sequel, continuation"
    },
    {
      "id": 3093,
      "kana": "つづく",
      "kanji": "続く",
      "type": "v5k,vi",
      "meaning": "to be continued"
    },
    {
      "id": 3094,
      "kana": "~つづく",
      "kanji": "~続く",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3095,
      "kana": "つづける",
      "kanji": "続ける",
      "type": "v1,vt",
      "meaning": "to continue, to keep up, to keep on"
    },
    {
      "id": 3096,
      "kana": "~つづける",
      "kanji": "~続ける",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3097,
      "kana": "つっこむ",
      "kanji": "突っ込む",
      "type": "v5m",
      "meaning": "to plunge into, to go into deeply"
    },
    {
      "id": 3098,
      "kana": "つつみ",
      "kanji": "包み",
      "type": "n",
      "meaning": "bundle, package, parcel, bale"
    },
    {
      "id": 3099,
      "kana": "つつむ",
      "kanji": "包む",
      "type": "v5m",
      "meaning": "to be engulfed in, to be enveloped by, to wrap up"
    },
    {
      "id": 3100,
      "kana": "つとめ",
      "kanji": "勤め",
      "type": "n",
      "meaning": "(1) service, duty, business, responsibility, task, (2) Buddhist religious services"
    },
    {
      "id": 3101,
      "kana": "つとめ",
      "kanji": "務め",
      "type": "n",
      "meaning": "(1) service, duty, (2) Buddhist religious services"
    },
    {
      "id": 3102,
      "kana": "つとめる",
      "kanji": "勤める",
      "type": "v1",
      "meaning": "to serve, to fill a post, to serve under, to work (for)"
    },
    {
      "id": 3103,
      "kana": "つとめる",
      "kanji": "務める",
      "type": "v1",
      "meaning": "(1) to serve, to fill a post, to serve under, to work (for)"
    },
    {
      "id": 3104,
      "kana": "つとめる",
      "kanji": "努める",
      "type": "v1",
      "meaning": "(1) to serve, to fill a post, to serve under, to work (for)"
    },
    {
      "id": 3105,
      "kana": "つな",
      "kanji": "綱",
      "type": "n",
      "meaning": "rope"
    },
    {
      "id": 3106,
      "kana": "つながり",
      "kanji": "繋がり",
      "type": "n",
      "meaning": "connection, link, relationship"
    },
    {
      "id": 3107,
      "kana": "つながる",
      "kanji": "繋がる",
      "type": "v5r",
      "meaning": "to be tied together, to be connected to, to be related to"
    },
    {
      "id": 3108,
      "kana": "つなぐ",
      "kanji": "繋ぐ",
      "type": "v5g",
      "meaning": "to tie, to fasten, to connect, to transfer (phone call)"
    },
    {
      "id": 3109,
      "kana": "つなげる",
      "kanji": "繋げる",
      "type": "v1",
      "meaning": "to connect"
    },
    {
      "id": 3110,
      "kana": "つねに",
      "kanji": "常に",
      "type": "adv",
      "meaning": "always, constantly"
    },
    {
      "id": 3111,
      "kana": "つばさ",
      "kanji": "翼",
      "type": "n",
      "meaning": "wings"
    },
    {
      "id": 3112,
      "kana": "つぶ",
      "kanji": "粒",
      "type": "n,n-suf",
      "meaning": "grain"
    },
    {
      "id": 3113,
      "kana": "つぶす",
      "kanji": "潰す",
      "type": "v5s",
      "meaning": "to smash, to waste"
    },
    {
      "id": 3114,
      "kana": "つぶれる",
      "kanji": "潰れる",
      "type": "v1",
      "meaning": "to be smashed, to go bankrupt"
    },
    {
      "id": 3115,
      "kana": "つま",
      "kanji": "妻",
      "type": "n",
      "meaning": "(hum) wife"
    },
    {
      "id": 3116,
      "kana": "つまずく",
      "kanji": "",
      "type": "v5k",
      "meaning": "to stumble, to trip"
    },
    {
      "id": 3117,
      "kana": "つまらない",
      "kanji": "",
      "type": "adj",
      "meaning": "insignificant, boring, trifling"
    },
    {
      "id": 3118,
      "kana": "つまり",
      "kanji": "",
      "type": "adv,n",
      "meaning": "in short, in brief, in other words"
    },
    {
      "id": 3119,
      "kana": "つまる",
      "kanji": "詰まる",
      "type": "v5r",
      "meaning": "to be blocked, to be packed"
    },
    {
      "id": 3120,
      "kana": "つみ",
      "kanji": "罪",
      "type": "adj-na,n",
      "meaning": "crime, fault, indiscretion"
    },
    {
      "id": 3121,
      "kana": "つむ",
      "kanji": "積む",
      "type": "v5m",
      "meaning": "to pile up, to stack"
    },
    {
      "id": 3122,
      "kana": "つめ",
      "kanji": "爪",
      "type": "n",
      "meaning": "fingernail or toenail, claw, talon, hoof"
    },
    {
      "id": 3123,
      "kana": "つめたい",
      "kanji": "冷たい",
      "type": "adj",
      "meaning": "cold (to the touch), chilly, icy, freezing, coldhearted"
    },
    {
      "id": 3124,
      "kana": "つめる",
      "kanji": "詰める",
      "type": "v1",
      "meaning": "to pack, to shorten, to work out (details)"
    },
    {
      "id": 3125,
      "kana": "つもり",
      "kanji": "",
      "type": "n",
      "meaning": "intention, plan"
    },
    {
      "id": 3126,
      "kana": "つもる",
      "kanji": "積もる",
      "type": "v5r",
      "meaning": "to pile up"
    },
    {
      "id": 3127,
      "kana": "つや",
      "kanji": "艶",
      "type": "n",
      "meaning": "gloss, glaze"
    },
    {
      "id": 3128,
      "kana": "つゆ",
      "kanji": "梅雨",
      "type": "n",
      "meaning": "rainy season, rain during the rainy season"
    },
    {
      "id": 3129,
      "kana": "つよい",
      "kanji": "強い",
      "type": "adj",
      "meaning": "strong, powerful, mighty, potent"
    },
    {
      "id": 3130,
      "kana": "つよき",
      "kanji": "強気",
      "type": "adj-na,n",
      "meaning": "firm, strong"
    },
    {
      "id": 3131,
      "kana": "つらい",
      "kanji": "辛い",
      "type": "adj",
      "meaning": "painful, heart-breaking"
    },
    {
      "id": 3132,
      "kana": "~づらい",
      "kanji": "~辛い",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3133,
      "kana": "つり",
      "kanji": "釣",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3134,
      "kana": "つり",
      "kanji": "釣",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3135,
      "kana": "つりあう",
      "kanji": "釣り合う",
      "type": "v5u",
      "meaning": "to balance, to be in harmony, to suit"
    },
    {
      "id": 3136,
      "kana": "つる",
      "kanji": "釣る",
      "type": "v5r",
      "meaning": "to fish"
    },
    {
      "id": 3137,
      "kana": "つる",
      "kanji": "吊る",
      "type": "v5r",
      "meaning": "to hang"
    },
    {
      "id": 3138,
      "kana": "つるす",
      "kanji": "吊す",
      "type": "v5s",
      "meaning": "to hang"
    },
    {
      "id": 3139,
      "kana": "つれ",
      "kanji": "連れ",
      "type": "n,vs",
      "meaning": "companion, company"
    },
    {
      "id": 3140,
      "kana": "つれる",
      "kanji": "連れる",
      "type": "v1",
      "meaning": "to lead, to take (a person)"
    },
    {
      "id": 3141,
      "kana": "て",
      "kanji": "手",
      "type": "n",
      "meaning": "hand"
    },
    {
      "id": 3142,
      "kana": "で",
      "kanji": "",
      "type": "n,n-suf",
      "meaning": "outflow, coming (going) out, graduate (of)"
    },
    {
      "id": 3143,
      "kana": "であい",
      "kanji": "出会い",
      "type": "n",
      "meaning": "meeting, rendezvous, encounter"
    },
    {
      "id": 3144,
      "kana": "であい",
      "kanji": "出合い",
      "type": "n",
      "meaning": "an encounter"
    },
    {
      "id": 3145,
      "kana": "であう",
      "kanji": "出会う",
      "type": "v5u",
      "meaning": "to meet by chance, to come across, to happen to encounter"
    },
    {
      "id": 3146,
      "kana": "てあらい",
      "kanji": "手洗い",
      "type": "n",
      "meaning": "restroom, lavatory, hand-washing"
    },
    {
      "id": 3147,
      "kana": "てい~",
      "kanji": "低~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3148,
      "kana": "ていあん",
      "kanji": "提案",
      "type": "n,vs",
      "meaning": "proposal, proposition, suggestion"
    },
    {
      "id": 3149,
      "kana": "ていいん",
      "kanji": "定員",
      "type": "n",
      "meaning": "fixed number of regular personnel, capacity (of boat, etc.)"
    },
    {
      "id": 3150,
      "kana": "ていか",
      "kanji": "定価",
      "type": "n",
      "meaning": "established price"
    },
    {
      "id": 3151,
      "kana": "ていか",
      "kanji": "低下",
      "type": "n,vs",
      "meaning": "fall, decline, lowering, deterioration"
    },
    {
      "id": 3152,
      "kana": "ていき",
      "kanji": "定期",
      "type": "n",
      "meaning": "fixed term"
    },
    {
      "id": 3153,
      "kana": "ていきけん",
      "kanji": "定期券",
      "type": "n",
      "meaning": "commuter pass, season ticket"
    },
    {
      "id": 3154,
      "kana": "ていきゅうび",
      "kanji": "定休日",
      "type": "n",
      "meaning": "regular holiday"
    },
    {
      "id": 3155,
      "kana": "ていこう",
      "kanji": "抵抗",
      "type": "n,vs",
      "meaning": "electrical resistance, resistance, opposition"
    },
    {
      "id": 3156,
      "kana": "ていし",
      "kanji": "停止",
      "type": "n,vs",
      "meaning": "suspension, interruption, stoppage, ban"
    },
    {
      "id": 3157,
      "kana": "ていしゃ",
      "kanji": "停車",
      "type": "n,vs",
      "meaning": "stopping (e.g. train)"
    },
    {
      "id": 3158,
      "kana": "ていしゅつ",
      "kanji": "提出",
      "type": "n,vs",
      "meaning": "presentation, submission, filing"
    },
    {
      "id": 3159,
      "kana": "ていでん",
      "kanji": "停電",
      "type": "n,vs",
      "meaning": "failure of electricity"
    },
    {
      "id": 3160,
      "kana": "ていど",
      "kanji": "程度",
      "type": "n-adv,n,n-suf",
      "meaning": "degree, amount, grade, standard, of the order of (following a number)"
    },
    {
      "id": 3161,
      "kana": "ていねい",
      "kanji": "丁寧",
      "type": "adj-na,n",
      "meaning": "polite, courteous"
    },
    {
      "id": 3162,
      "kana": "でいり",
      "kanji": "出入り",
      "type": "n",
      "meaning": "in and out, coming and going, free association, income and expenditure, debits and credit"
    },
    {
      "id": 3163,
      "kana": "でいりぐち",
      "kanji": "出入口",
      "type": "n",
      "meaning": "exit and entrance"
    },
    {
      "id": 3164,
      "kana": "ていりゅうじょ",
      "kanji": "停留所",
      "type": "n",
      "meaning": "bus or tram stop"
    },
    {
      "id": 3165,
      "kana": "ていれ",
      "kanji": "手入れ",
      "type": "n",
      "meaning": "repairs, maintenance"
    },
    {
      "id": 3166,
      "kana": "デート",
      "kanji": "",
      "type": "n,vs",
      "meaning": "date, go on a date"
    },
    {
      "id": 3167,
      "kana": "テープ",
      "kanji": "",
      "type": "n",
      "meaning": "tape"
    },
    {
      "id": 3168,
      "kana": "テーブル",
      "kanji": "",
      "type": "n",
      "meaning": "table"
    },
    {
      "id": 3169,
      "kana": "テープレコーダー",
      "kanji": "",
      "type": "n",
      "meaning": "tape recorder"
    },
    {
      "id": 3170,
      "kana": "テーマ",
      "kanji": "",
      "type": "de:",
      "meaning": "(n) theme, project, topic (de: Thema)"
    },
    {
      "id": 3171,
      "kana": "でかける",
      "kanji": "出掛ける",
      "type": "v1",
      "meaning": "to depart, to set out, to start, to be going out"
    },
    {
      "id": 3172,
      "kana": "てき",
      "kanji": "敵",
      "type": "n",
      "meaning": "enemy, rival"
    },
    {
      "id": 3173,
      "kana": "~てき",
      "kanji": "~的",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3174,
      "kana": "できあがり",
      "kanji": "出来上がり",
      "type": "n",
      "meaning": "be finished, ready, made for, cut out"
    },
    {
      "id": 3175,
      "kana": "できあがる",
      "kanji": "出来上がる",
      "type": "v5r",
      "meaning": "(1) to be finished, to be ready, by definition, (2) to be very drunk"
    },
    {
      "id": 3176,
      "kana": "てきかく",
      "kanji": "的確",
      "type": "adj-na,n",
      "meaning": "precise, accurate"
    },
    {
      "id": 3177,
      "kana": "てきかく",
      "kanji": "適確",
      "type": "adj-na,n",
      "meaning": "precise, accurate"
    },
    {
      "id": 3178,
      "kana": "できごと",
      "kanji": "出来事",
      "type": "n",
      "meaning": "incident, affair, happening, event"
    },
    {
      "id": 3179,
      "kana": "テキスト",
      "kanji": "",
      "type": "n",
      "meaning": "(1) text, (2) text book"
    },
    {
      "id": 3180,
      "kana": "てきする",
      "kanji": "適する",
      "type": "vs-s",
      "meaning": "to fit, to suit"
    },
    {
      "id": 3181,
      "kana": "てきせつ",
      "kanji": "適切",
      "type": "adj-na,n",
      "meaning": "pertinent, appropriate, adequate, relevance"
    },
    {
      "id": 3182,
      "kana": "てきど",
      "kanji": "適度",
      "type": "adj-na,n",
      "meaning": "moderate"
    },
    {
      "id": 3183,
      "kana": "てきとう",
      "kanji": "適当",
      "type": "adj-na,n",
      "meaning": "fitness, suitability, adequacy, relevance"
    },
    {
      "id": 3184,
      "kana": "てきよう",
      "kanji": "適用",
      "type": "n",
      "meaning": "applying"
    },
    {
      "id": 3185,
      "kana": "（可能。出現。発生）",
      "kanji": "できる",
      "type": "v1",
      "meaning": "to be able to, to be ready, to occur"
    },
    {
      "id": 3186,
      "kana": "できるだけ",
      "kanji": "出来るだけ",
      "type": "",
      "meaning": "if at all possible"
    },
    {
      "id": 3187,
      "kana": "できれば",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3188,
      "kana": "でぐち",
      "kanji": "出口",
      "type": "n",
      "meaning": "exit, gateway, way out, outlet, leak, vent"
    },
    {
      "id": 3189,
      "kana": "てくび",
      "kanji": "手首",
      "type": "n",
      "meaning": "wrist"
    },
    {
      "id": 3190,
      "kana": "でこぼこ",
      "kanji": "凸凹",
      "type": "adj-na,n",
      "meaning": "unevenness, roughness, ruggedness"
    },
    {
      "id": 3191,
      "kana": "てごろ",
      "kanji": "手頃",
      "type": "adj-na,n",
      "meaning": "moderate, handy"
    },
    {
      "id": 3192,
      "kana": "でし",
      "kanji": "弟子",
      "type": "n",
      "meaning": "pupil, disciple, adherent, follower, apprentice, young person, teacher's student-helper"
    },
    {
      "id": 3193,
      "kana": "てじな",
      "kanji": "手品",
      "type": "n",
      "meaning": "sleight of hand, conjuring trick, magic, juggling"
    },
    {
      "id": 3194,
      "kana": "ですから",
      "kanji": "",
      "type": "conj",
      "meaning": "therefore"
    },
    {
      "id": 3195,
      "kana": "テスト",
      "kanji": "",
      "type": "n,vs",
      "meaning": "test"
    },
    {
      "id": 3196,
      "kana": "でたらめ",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "(1) irresponsible utterance, nonsense, nonsensical, (2) random"
    },
    {
      "id": 3197,
      "kana": "てちょう",
      "kanji": "手帳",
      "type": "n",
      "meaning": "notebook"
    },
    {
      "id": 3198,
      "kana": "てつ",
      "kanji": "鉄",
      "type": "n",
      "meaning": "iron"
    },
    {
      "id": 3199,
      "kana": "てつがく",
      "kanji": "哲学",
      "type": "n",
      "meaning": "philosophy"
    },
    {
      "id": 3200,
      "kana": "てっきょう",
      "kanji": "鉄橋",
      "type": "n",
      "meaning": "railway bridge, iron bridge"
    },
    {
      "id": 3201,
      "kana": "てつだい",
      "kanji": "手伝い",
      "type": "n",
      "meaning": "help, helper, assistant"
    },
    {
      "id": 3202,
      "kana": "てつだう",
      "kanji": "手伝う",
      "type": "v5u",
      "meaning": "to help, to assist, to take part in"
    },
    {
      "id": 3203,
      "kana": "てつづき",
      "kanji": "手続き",
      "type": "n,vs",
      "meaning": "procedure, (legal) process, formalities"
    },
    {
      "id": 3204,
      "kana": "てってい",
      "kanji": "徹底",
      "type": "n",
      "meaning": "thoroughness, completeness"
    },
    {
      "id": 3205,
      "kana": "てつどう",
      "kanji": "鉄道",
      "type": "n",
      "meaning": "railroad"
    },
    {
      "id": 3206,
      "kana": "てっぽう",
      "kanji": "鉄砲",
      "type": "n",
      "meaning": "gun"
    },
    {
      "id": 3207,
      "kana": "てつや",
      "kanji": "徹夜",
      "type": "n",
      "meaning": "all night, all night vigil, sleepless night"
    },
    {
      "id": 3208,
      "kana": "テニス",
      "kanji": "",
      "type": "n",
      "meaning": "tennis"
    },
    {
      "id": 3209,
      "kana": "テニスコート",
      "kanji": "",
      "type": "",
      "meaning": "tennis court"
    },
    {
      "id": 3210,
      "kana": "てぬぐい",
      "kanji": "手拭い",
      "type": "n",
      "meaning": "(hand) towel"
    },
    {
      "id": 3211,
      "kana": "では",
      "kanji": "では",
      "type": "n",
      "meaning": "chance of going out, opportunity (to succeed), moment of departure, beginning of work"
    },
    {
      "id": 3212,
      "kana": "デパート",
      "kanji": "",
      "type": "n",
      "meaning": "(abbr) department store"
    },
    {
      "id": 3213,
      "kana": "てぶくろ",
      "kanji": "手袋",
      "type": "n",
      "meaning": "glove"
    },
    {
      "id": 3214,
      "kana": "てま",
      "kanji": "手間",
      "type": "n",
      "meaning": "time, labour"
    },
    {
      "id": 3215,
      "kana": "てまえ",
      "kanji": "手前",
      "type": "n",
      "meaning": "before, this side, we, you"
    },
    {
      "id": 3216,
      "kana": "でむかえ",
      "kanji": "出迎え",
      "type": "n",
      "meaning": "meeting, reception"
    },
    {
      "id": 3217,
      "kana": "でむかえる",
      "kanji": "出迎える",
      "type": "v1",
      "meaning": "to meet, to greet"
    },
    {
      "id": 3218,
      "kana": "でも",
      "kanji": "でも",
      "type": "conj,prt",
      "meaning": "but, however"
    },
    {
      "id": 3219,
      "kana": "デモ",
      "kanji": "",
      "type": "n",
      "meaning": "(abbr) demo, demonstration"
    },
    {
      "id": 3220,
      "kana": "てら",
      "kanji": "寺",
      "type": "n",
      "meaning": "temple"
    },
    {
      "id": 3221,
      "kana": "てらす",
      "kanji": "照らす",
      "type": "v5s",
      "meaning": "to shine on, to illuminate"
    },
    {
      "id": 3222,
      "kana": "てる",
      "kanji": "照る",
      "type": "v5r,vi",
      "meaning": "to shine"
    },
    {
      "id": 3223,
      "kana": "でる",
      "kanji": "出る",
      "type": "v1",
      "meaning": "to appear, to come forth, to leave"
    },
    {
      "id": 3224,
      "kana": "テレビ",
      "kanji": "",
      "type": "n",
      "meaning": "television, TV"
    },
    {
      "id": 3225,
      "kana": "てん",
      "kanji": "点",
      "type": "n,n-suf",
      "meaning": "spot, mark, point, dot"
    },
    {
      "id": 3226,
      "kana": "~てん",
      "kanji": "~点",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3227,
      "kana": "てんいん",
      "kanji": "店員",
      "type": "n",
      "meaning": "shop assistant, employee, clerk, salesperson"
    },
    {
      "id": 3228,
      "kana": "てんかい",
      "kanji": "展開",
      "type": "n,vs",
      "meaning": "develop, expansion (opposite of compression)"
    },
    {
      "id": 3229,
      "kana": "てんき",
      "kanji": "天気",
      "type": "n",
      "meaning": "weather, the elements, fine weather"
    },
    {
      "id": 3230,
      "kana": "でんき",
      "kanji": "電気",
      "type": "n",
      "meaning": "electricity, (electric) light"
    },
    {
      "id": 3231,
      "kana": "でんき",
      "kanji": "伝記",
      "type": "n",
      "meaning": "biography, life story"
    },
    {
      "id": 3232,
      "kana": "でんきゅう",
      "kanji": "電球",
      "type": "n",
      "meaning": "light bulb"
    },
    {
      "id": 3233,
      "kana": "てんけい",
      "kanji": "典型",
      "type": "adj-no,n",
      "meaning": "type, pattern, archetypal"
    },
    {
      "id": 3234,
      "kana": "てんこう",
      "kanji": "天候",
      "type": "n",
      "meaning": "weather"
    },
    {
      "id": 3235,
      "kana": "でんし",
      "kanji": "電子",
      "type": "n",
      "meaning": "electron"
    },
    {
      "id": 3236,
      "kana": "でんしゃ",
      "kanji": "電車",
      "type": "n",
      "meaning": "electric train"
    },
    {
      "id": 3237,
      "kana": "てんすう",
      "kanji": "点数",
      "type": "n",
      "meaning": "marks, points, score, runs"
    },
    {
      "id": 3238,
      "kana": "でんせん",
      "kanji": "伝染",
      "type": "n",
      "meaning": "contagion"
    },
    {
      "id": 3239,
      "kana": "でんち",
      "kanji": "電池",
      "type": "n",
      "meaning": "battery"
    },
    {
      "id": 3240,
      "kana": "てんてん",
      "kanji": "点々",
      "type": "adv,n",
      "meaning": "here and there, little by little"
    },
    {
      "id": 3241,
      "kana": "てんてん",
      "kanji": "転々",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3242,
      "kana": "でんちゅう",
      "kanji": "電柱",
      "type": "n",
      "meaning": "telephone pole, telegraph pole, lightpole"
    },
    {
      "id": 3243,
      "kana": "テント",
      "kanji": "",
      "type": "n",
      "meaning": "tent"
    },
    {
      "id": 3244,
      "kana": "でんとう",
      "kanji": "電灯",
      "type": "n",
      "meaning": "electric light"
    },
    {
      "id": 3245,
      "kana": "でんとう",
      "kanji": "伝統",
      "type": "n",
      "meaning": "tradition, convention"
    },
    {
      "id": 3246,
      "kana": "てんねん",
      "kanji": "天然",
      "type": "n",
      "meaning": "nature, spontaneity"
    },
    {
      "id": 3247,
      "kana": "てんのう",
      "kanji": "天皇",
      "type": "n",
      "meaning": "Emperor of Japan"
    },
    {
      "id": 3248,
      "kana": "でんぱ",
      "kanji": "電波",
      "type": "n",
      "meaning": "electro-magnetic wave"
    },
    {
      "id": 3249,
      "kana": "テンポ",
      "kanji": "",
      "type": "n",
      "meaning": "tempo"
    },
    {
      "id": 3250,
      "kana": "でんぽう",
      "kanji": "電報",
      "type": "n",
      "meaning": "telegram"
    },
    {
      "id": 3251,
      "kana": "てんらんかい",
      "kanji": "展覧会",
      "type": "n",
      "meaning": "exhibition"
    },
    {
      "id": 3252,
      "kana": "でんりゅう",
      "kanji": "電流",
      "type": "n",
      "meaning": "electric current"
    },
    {
      "id": 3253,
      "kana": "でんりょく",
      "kanji": "電力",
      "type": "n",
      "meaning": "electric power"
    },
    {
      "id": 3254,
      "kana": "でんわ",
      "kanji": "電話",
      "type": "n,vs",
      "meaning": "telephone"
    },
    {
      "id": 3255,
      "kana": "と",
      "kanji": "戸",
      "type": "n",
      "meaning": "door (Japanese style)"
    },
    {
      "id": 3256,
      "kana": "と",
      "kanji": "都",
      "type": "n",
      "meaning": "capital"
    },
    {
      "id": 3257,
      "kana": "ど",
      "kanji": "度",
      "type": "n,n-suf",
      "meaning": "counter for occurrences"
    },
    {
      "id": 3258,
      "kana": "ドア",
      "kanji": "",
      "type": "n",
      "meaning": "door (Western style)"
    },
    {
      "id": 3259,
      "kana": "とい",
      "kanji": "問い",
      "type": "n",
      "meaning": "question, query"
    },
    {
      "id": 3260,
      "kana": "といあわせ",
      "kanji": "問い合わせ",
      "type": "n",
      "meaning": "enquiry, ENQ"
    },
    {
      "id": 3261,
      "kana": "トイレ",
      "kanji": "",
      "type": "n",
      "meaning": "toilet, restroom, bathroom, lavatory"
    },
    {
      "id": 3262,
      "kana": "とう",
      "kanji": "党",
      "type": "n,n-suf",
      "meaning": "party (political)"
    },
    {
      "id": 3263,
      "kana": "とう",
      "kanji": "塔",
      "type": "n,n-suf",
      "meaning": "tower, pagoda"
    },
    {
      "id": 3264,
      "kana": "~とう",
      "kanji": "~頭",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3265,
      "kana": "~とう",
      "kanji": "~等",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3266,
      "kana": "~とう",
      "kanji": "~島",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3267,
      "kana": "（接。副）",
      "kanji": "どう",
      "type": "",
      "meaning": "child, servant, foolishness"
    },
    {
      "id": 3268,
      "kana": "どう",
      "kanji": "銅",
      "type": "n",
      "meaning": "copper"
    },
    {
      "id": 3269,
      "kana": "どう~",
      "kanji": "同~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3270,
      "kana": "~どう",
      "kanji": "~道",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3271,
      "kana": "とうあん",
      "kanji": "答案",
      "type": "n",
      "meaning": "examination paper, examination script"
    },
    {
      "id": 3272,
      "kana": "（感）",
      "kanji": "どういたしまして",
      "type": "int",
      "meaning": "you are welcome, don't mention it"
    },
    {
      "id": 3273,
      "kana": "とういつ",
      "kanji": "統一",
      "type": "n,vs",
      "meaning": "unity, consolidation, uniformity"
    },
    {
      "id": 3274,
      "kana": "どういつ",
      "kanji": "同一",
      "type": "adj-na,adj-no,n",
      "meaning": "identity, sameness, similarity"
    },
    {
      "id": 3275,
      "kana": "どうか",
      "kanji": "",
      "type": "n",
      "meaning": "copper coin"
    },
    {
      "id": 3276,
      "kana": "どうかく",
      "kanji": "同格",
      "type": "adj-na,n",
      "meaning": "the same rank, equality, apposition"
    },
    {
      "id": 3277,
      "kana": "どうぐ",
      "kanji": "道具",
      "type": "n",
      "meaning": "implement, tool, means"
    },
    {
      "id": 3278,
      "kana": "とうげ",
      "kanji": "峠",
      "type": "n,n-suf",
      "meaning": "ridge, (mountain) pass, difficult part"
    },
    {
      "id": 3279,
      "kana": "とうけい",
      "kanji": "統計",
      "type": "",
      "meaning": "scattering, a scatter, dispersion"
    },
    {
      "id": 3280,
      "kana": "どうさ",
      "kanji": "動作",
      "type": "n",
      "meaning": "action, movements, motions, bearing, behaviour, manners"
    },
    {
      "id": 3281,
      "kana": "とうざい",
      "kanji": "東西",
      "type": "n",
      "meaning": "East and West, whole country"
    },
    {
      "id": 3282,
      "kana": "とうじ",
      "kanji": "当時",
      "type": "n-adv,n-t",
      "meaning": "at that time, in those days"
    },
    {
      "id": 3283,
      "kana": "どうし",
      "kanji": "動詞",
      "type": "n",
      "meaning": "verb"
    },
    {
      "id": 3284,
      "kana": "どうじ",
      "kanji": "同時",
      "type": "adj-no,n",
      "meaning": "simultaneous(ly), concurrent, same time, synchronous"
    },
    {
      "id": 3285,
      "kana": "とうじつ",
      "kanji": "当日",
      "type": "n-adv,n-t",
      "meaning": "appointed day, very day"
    },
    {
      "id": 3286,
      "kana": "どうして",
      "kanji": "",
      "type": "adv,int",
      "meaning": "why?, for what reason, how"
    },
    {
      "id": 3287,
      "kana": "どうしても",
      "kanji": "",
      "type": "adv",
      "meaning": "by all means, at any cost, no matter what"
    },
    {
      "id": 3288,
      "kana": "とうしょ",
      "kanji": "投書",
      "type": "n",
      "meaning": "letter to the editor, letter from a reader, contribution"
    },
    {
      "id": 3289,
      "kana": "とうじょう",
      "kanji": "登場",
      "type": "n,vs",
      "meaning": "entry (on stage)"
    },
    {
      "id": 3290,
      "kana": "どうせ",
      "kanji": "",
      "type": "adv",
      "meaning": "anyhow, in any case, at any rate"
    },
    {
      "id": 3291,
      "kana": "どうぞ",
      "kanji": "",
      "type": "adv",
      "meaning": "please, kindly, by all means"
    },
    {
      "id": 3292,
      "kana": "どうぞよろしく",
      "kanji": "",
      "type": "exp",
      "meaning": "pleased to meet you"
    },
    {
      "id": 3293,
      "kana": "とうだい",
      "kanji": "灯台",
      "type": "n",
      "meaning": "lighthouse"
    },
    {
      "id": 3294,
      "kana": "とうちゃく",
      "kanji": "到着",
      "type": "n,vs",
      "meaning": "arrival"
    },
    {
      "id": 3295,
      "kana": "とうとう",
      "kanji": "",
      "type": "",
      "meaning": "vast, calm"
    },
    {
      "id": 3296,
      "kana": "どうとく",
      "kanji": "道徳",
      "type": "n",
      "meaning": "morals"
    },
    {
      "id": 3297,
      "kana": "とうなん",
      "kanji": "盗難",
      "type": "n",
      "meaning": "theft, robbery"
    },
    {
      "id": 3298,
      "kana": "とうばん",
      "kanji": "当番",
      "type": "n",
      "meaning": "being on duty"
    },
    {
      "id": 3299,
      "kana": "とうひょう",
      "kanji": "投票",
      "type": "n,vs",
      "meaning": "voting, poll"
    },
    {
      "id": 3300,
      "kana": "どうぶつ",
      "kanji": "動物",
      "type": "n",
      "meaning": "animal"
    },
    {
      "id": 3301,
      "kana": "とうぶん",
      "kanji": "等分",
      "type": "n,vs",
      "meaning": "division into equal parts"
    },
    {
      "id": 3302,
      "kana": "とうめい",
      "kanji": "透明",
      "type": "adj-na,n",
      "meaning": "transparency, cleanness"
    },
    {
      "id": 3303,
      "kana": "どうも",
      "kanji": "",
      "type": "adv,int",
      "meaning": "(abbr) thanks, how, (very) much"
    },
    {
      "id": 3304,
      "kana": "とうゆ",
      "kanji": "灯油",
      "type": "n",
      "meaning": "lamp oil, kerosene"
    },
    {
      "id": 3305,
      "kana": "とうよう",
      "kanji": "東洋",
      "type": "n",
      "meaning": "Orient"
    },
    {
      "id": 3306,
      "kana": "どうよう",
      "kanji": "同様",
      "type": "adj-na,n",
      "meaning": "identical, equal to, same (kind), like"
    },
    {
      "id": 3307,
      "kana": "どうよう",
      "kanji": "童謡",
      "type": "n",
      "meaning": "children's song, nursery rhyme"
    },
    {
      "id": 3308,
      "kana": "どうりょう",
      "kanji": "同僚",
      "type": "n",
      "meaning": "coworker, colleague, associate"
    },
    {
      "id": 3309,
      "kana": "どうろ",
      "kanji": "道路",
      "type": "n",
      "meaning": "road, highway"
    },
    {
      "id": 3310,
      "kana": "どうわ",
      "kanji": "童話",
      "type": "n",
      "meaning": "fairy tale"
    },
    {
      "id": 3311,
      "kana": "とお",
      "kanji": "十",
      "type": "num",
      "meaning": "10, ten"
    },
    {
      "id": 3312,
      "kana": "とおい",
      "kanji": "遠い",
      "type": "adj",
      "meaning": "far, distant"
    },
    {
      "id": 3313,
      "kana": "とおす",
      "kanji": "通す",
      "type": "v5s",
      "meaning": "to let pass, to overlook, to continue"
    },
    {
      "id": 3314,
      "kana": "とおり",
      "kanji": "通り",
      "type": "n-suf",
      "meaning": "in accordance with ~, following ~, ~ Street, ~ Avenue"
    },
    {
      "id": 3315,
      "kana": "とおり",
      "kanji": "通り",
      "type": "n-suf",
      "meaning": "in accordance with ~, following ~, ~ Street, ~ Avenue"
    },
    {
      "id": 3316,
      "kana": "~とおり",
      "kanji": "~通り",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3317,
      "kana": "とおりかかる",
      "kanji": "通り掛かる",
      "type": "v5r",
      "meaning": "to happen to pass by"
    },
    {
      "id": 3318,
      "kana": "とおりすぎる",
      "kanji": "通り過ぎる",
      "type": "v1",
      "meaning": "to pass, to pass through"
    },
    {
      "id": 3319,
      "kana": "とおる",
      "kanji": "通る",
      "type": "v5r",
      "meaning": "to pass (by), to go through, to walk along, to pass exams"
    },
    {
      "id": 3320,
      "kana": "とかい",
      "kanji": "都会",
      "type": "n",
      "meaning": "city"
    },
    {
      "id": 3321,
      "kana": "とかす",
      "kanji": "溶かす",
      "type": "v5s,vt",
      "meaning": "to melt, to dissolve"
    },
    {
      "id": 3322,
      "kana": "とがる",
      "kanji": "尖る",
      "type": "v5r",
      "meaning": "to taper to a point, to become sharp, to be sour, to look displeased"
    },
    {
      "id": 3323,
      "kana": "とき",
      "kanji": "時",
      "type": "n-adv,n",
      "meaning": "(1) time, hour, (2) occasion, moment"
    },
    {
      "id": 3324,
      "kana": "ときどき",
      "kanji": "時々",
      "type": "adv,n",
      "meaning": "sometimes"
    },
    {
      "id": 3325,
      "kana": "どきどき",
      "kanji": "",
      "type": "adv,n,vs",
      "meaning": "throb, beat (fast)"
    },
    {
      "id": 3326,
      "kana": "とく",
      "kanji": "溶く",
      "type": "v5k,vt",
      "meaning": "to dissolve (paint)"
    },
    {
      "id": 3327,
      "kana": "とく",
      "kanji": "解く",
      "type": "v5k",
      "meaning": "to unfasten"
    },
    {
      "id": 3328,
      "kana": "どく",
      "kanji": "退く",
      "type": "v5k,vi",
      "meaning": "(1) to retreat, to recede, to withdraw"
    },
    {
      "id": 3329,
      "kana": "どく",
      "kanji": "毒",
      "type": "n",
      "meaning": "poison, toxicant"
    },
    {
      "id": 3330,
      "kana": "とくい",
      "kanji": "得意",
      "type": "adj-na,n",
      "meaning": "pride, triumph, prosperity"
    },
    {
      "id": 3331,
      "kana": "とくしゅ",
      "kanji": "特殊",
      "type": "adj-na,n",
      "meaning": "special, unique"
    },
    {
      "id": 3332,
      "kana": "どくしょ",
      "kanji": "読書",
      "type": "n",
      "meaning": "reading"
    },
    {
      "id": 3333,
      "kana": "とくしょく",
      "kanji": "特色",
      "type": "n",
      "meaning": "characteristic, feature"
    },
    {
      "id": 3334,
      "kana": "どくしん",
      "kanji": "独身",
      "type": "n",
      "meaning": "bachelorhood, single, unmarried, celibate"
    },
    {
      "id": 3335,
      "kana": "とくちょう",
      "kanji": "特徴",
      "type": "n",
      "meaning": "feature, characteristic"
    },
    {
      "id": 3336,
      "kana": "とくちょう",
      "kanji": "特長",
      "type": "n",
      "meaning": "forte, merit"
    },
    {
      "id": 3337,
      "kana": "とくてい",
      "kanji": "特定",
      "type": "adj-na,n,vs",
      "meaning": "specific, special, particular"
    },
    {
      "id": 3338,
      "kana": "どくとく",
      "kanji": "独特",
      "type": "adj-na,n",
      "meaning": "peculiarity, uniqueness, characteristic"
    },
    {
      "id": 3339,
      "kana": "とくに",
      "kanji": "特に",
      "type": "adv",
      "meaning": "particularly, especially"
    },
    {
      "id": 3340,
      "kana": "とくばい",
      "kanji": "特売",
      "type": "n,vs",
      "meaning": "special sale"
    },
    {
      "id": 3341,
      "kana": "とくべつ",
      "kanji": "特別",
      "type": "adj-na,adv,n",
      "meaning": "special"
    },
    {
      "id": 3342,
      "kana": "どくりつ",
      "kanji": "独立",
      "type": "adj-na,n",
      "meaning": "independence (e.g. Ind. Day), self-support"
    },
    {
      "id": 3343,
      "kana": "とけい",
      "kanji": "時計",
      "type": "n",
      "meaning": "watch, clock"
    },
    {
      "id": 3344,
      "kana": "とけこむ",
      "kanji": "溶け込む",
      "type": "v5m",
      "meaning": "to melt into"
    },
    {
      "id": 3345,
      "kana": "とける",
      "kanji": "溶ける",
      "type": "v1,vi",
      "meaning": "to melt, to thaw, to fuse, to dissolve"
    },
    {
      "id": 3346,
      "kana": "とける",
      "kanji": "解ける",
      "type": "v1",
      "meaning": "to come untied, to come apart"
    },
    {
      "id": 3347,
      "kana": "どける",
      "kanji": "退ける",
      "type": "v1",
      "meaning": "to remove, to take away, to dislodge, to put something out of the way"
    },
    {
      "id": 3348,
      "kana": "どこ",
      "kanji": "",
      "type": "n",
      "meaning": "where, what place"
    },
    {
      "id": 3349,
      "kana": "どこか",
      "kanji": "",
      "type": "adv,exp,n",
      "meaning": "somewhere, anywhere"
    },
    {
      "id": 3350,
      "kana": "とこのま",
      "kanji": "床の間",
      "type": "n",
      "meaning": "alcove"
    },
    {
      "id": 3351,
      "kana": "とこや",
      "kanji": "床屋",
      "type": "n",
      "meaning": "barber"
    },
    {
      "id": 3352,
      "kana": "ところ",
      "kanji": "所",
      "type": "n",
      "meaning": "place"
    },
    {
      "id": 3353,
      "kana": "~ところ",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3354,
      "kana": "ところが",
      "kanji": "",
      "type": "conj",
      "meaning": "however, while, even if"
    },
    {
      "id": 3355,
      "kana": "ところで",
      "kanji": "",
      "type": "",
      "meaning": "by the way, even if, no matter what"
    },
    {
      "id": 3356,
      "kana": "ところどころ",
      "kanji": "所々",
      "type": "n-adv,n-t",
      "meaning": "here and there, some parts (of something)"
    },
    {
      "id": 3357,
      "kana": "とざん",
      "kanji": "登山",
      "type": "n,vs",
      "meaning": "mountain-climbing"
    },
    {
      "id": 3358,
      "kana": "とし",
      "kanji": "年",
      "type": "n-adv,n",
      "meaning": "year, age"
    },
    {
      "id": 3359,
      "kana": "とし",
      "kanji": "都市",
      "type": "n",
      "meaning": "town, city, municipal, urban"
    },
    {
      "id": 3360,
      "kana": "としつき",
      "kanji": "年月",
      "type": "n",
      "meaning": "months and years"
    },
    {
      "id": 3361,
      "kana": "としょ",
      "kanji": "図書",
      "type": "n",
      "meaning": "books"
    },
    {
      "id": 3362,
      "kana": "としより",
      "kanji": "年寄",
      "type": "n",
      "meaning": "old people, the aged"
    },
    {
      "id": 3363,
      "kana": "とじる",
      "kanji": "閉じる",
      "type": "v1",
      "meaning": "to close (e.g. book, eyes), to shut"
    },
    {
      "id": 3364,
      "kana": "としん",
      "kanji": "都心",
      "type": "n",
      "meaning": "heart (of city)"
    },
    {
      "id": 3365,
      "kana": "とだな",
      "kanji": "戸棚",
      "type": "n",
      "meaning": "cupboard, locker, closet, wardrobe"
    },
    {
      "id": 3366,
      "kana": "とたん",
      "kanji": "途端",
      "type": "n",
      "meaning": "just (now, at the moment, etc.)"
    },
    {
      "id": 3367,
      "kana": "とち",
      "kanji": "土地",
      "type": "n",
      "meaning": "plot of land, lot, soil"
    },
    {
      "id": 3368,
      "kana": "とちゅう",
      "kanji": "途中",
      "type": "n-adv,n-t",
      "meaning": "on the way, en route, midway"
    },
    {
      "id": 3369,
      "kana": "どちら",
      "kanji": "",
      "type": "n",
      "meaning": "which (of two), who"
    },
    {
      "id": 3370,
      "kana": "どっち",
      "kanji": "",
      "type": "n",
      "meaning": "which way, which one"
    },
    {
      "id": 3371,
      "kana": "とっきゅう",
      "kanji": "特急",
      "type": "n",
      "meaning": "limited express (train, faster than an express)"
    },
    {
      "id": 3372,
      "kana": "とっくに",
      "kanji": "",
      "type": "adv",
      "meaning": "long ago, already, a long time ago"
    },
    {
      "id": 3373,
      "kana": "とつぜん",
      "kanji": "突然",
      "type": "adj-na,adj-no,adv",
      "meaning": "abruptly, suddenly, unexpectedly, all at once"
    },
    {
      "id": 3374,
      "kana": "どっと",
      "kanji": "",
      "type": "adv",
      "meaning": "suddenly"
    },
    {
      "id": 3375,
      "kana": "トップ",
      "kanji": "",
      "type": "n",
      "meaning": "top"
    },
    {
      "id": 3376,
      "kana": "とても",
      "kanji": "",
      "type": "adv",
      "meaning": "very, awfully, exceedingly"
    },
    {
      "id": 3377,
      "kana": "とどく",
      "kanji": "届く",
      "type": "v5k,vi",
      "meaning": "to reach"
    },
    {
      "id": 3378,
      "kana": "とどける",
      "kanji": "届ける",
      "type": "v1",
      "meaning": "to deliver, to forward, to send, to report"
    },
    {
      "id": 3379,
      "kana": "ととのう",
      "kanji": "整う",
      "type": "v5u",
      "meaning": "to be prepared, to be in order, to be put in order, to be arranged"
    },
    {
      "id": 3380,
      "kana": "とどまる",
      "kanji": "留まる",
      "type": "v5r",
      "meaning": "(1) to be fixed, (2) to abide, to stay (in the one place)"
    },
    {
      "id": 3381,
      "kana": "どなた",
      "kanji": "",
      "type": "n",
      "meaning": "who?"
    },
    {
      "id": 3382,
      "kana": "となり",
      "kanji": "隣",
      "type": "n",
      "meaning": "next to, next door to"
    },
    {
      "id": 3383,
      "kana": "どなる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to shout, to yell"
    },
    {
      "id": 3384,
      "kana": "とにかく",
      "kanji": "",
      "type": "adv",
      "meaning": "anyhow, at any rate, anyway, somehow or other, generally speaking, in any case"
    },
    {
      "id": 3385,
      "kana": "どの",
      "kanji": "",
      "type": "pol",
      "meaning": "Mister (mostly in addressing someone on an envelope)"
    },
    {
      "id": 3386,
      "kana": "~どの",
      "kanji": "~殿",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3387,
      "kana": "とばす",
      "kanji": "飛ばす",
      "type": "v5s",
      "meaning": "to skip over, to omit"
    },
    {
      "id": 3388,
      "kana": "とびこむ",
      "kanji": "飛び込む",
      "type": "v5m",
      "meaning": "to jump in, to leap in, to plunge into, to dive"
    },
    {
      "id": 3389,
      "kana": "とびだす",
      "kanji": "飛び出す",
      "type": "v5s",
      "meaning": "to jump out, to rush out, to fly out"
    },
    {
      "id": 3390,
      "kana": "とぶ",
      "kanji": "飛ぶ",
      "type": "v5b",
      "meaning": "to jump, to fly, to leap"
    },
    {
      "id": 3391,
      "kana": "とぶ",
      "kanji": "跳ぶ",
      "type": "v5b",
      "meaning": "to jump, to fly, to leap"
    },
    {
      "id": 3392,
      "kana": "とまる",
      "kanji": "止まる",
      "type": "v5r",
      "meaning": "to come to a halt"
    },
    {
      "id": 3393,
      "kana": "とまる",
      "kanji": "留まる",
      "type": "v5r",
      "meaning": "(1) to be fixed, (2) to abide, to stay (in the one place)"
    },
    {
      "id": 3394,
      "kana": "とまる",
      "kanji": "泊まる",
      "type": "v5r",
      "meaning": "to stay at (e.g. hotel)"
    },
    {
      "id": 3395,
      "kana": "とめる",
      "kanji": "止める",
      "type": "v1,vt",
      "meaning": "to end, to stop, to cease, to resign"
    },
    {
      "id": 3396,
      "kana": "とめる",
      "kanji": "留める",
      "type": "v1",
      "meaning": "to fasten, to turn off, to detain"
    },
    {
      "id": 3397,
      "kana": "とめる",
      "kanji": "泊める",
      "type": "v1",
      "meaning": "to give shelter to, to lodge"
    },
    {
      "id": 3398,
      "kana": "とも",
      "kanji": "友",
      "type": "n",
      "meaning": "friend, companion, pal"
    },
    {
      "id": 3399,
      "kana": "ともかく",
      "kanji": "",
      "type": "adv",
      "meaning": "anyhow, anyway, somehow or other, generally speaking, in any case"
    },
    {
      "id": 3400,
      "kana": "ともだち",
      "kanji": "友達",
      "type": "n",
      "meaning": "friend"
    },
    {
      "id": 3401,
      "kana": "ともに",
      "kanji": "共に",
      "type": "adv,vs",
      "meaning": "sharing with, participate in"
    },
    {
      "id": 3402,
      "kana": "どよう",
      "kanji": "土曜",
      "type": "n-adv,n",
      "meaning": "Saturday"
    },
    {
      "id": 3403,
      "kana": "とら",
      "kanji": "虎",
      "type": "n",
      "meaning": "tiger"
    },
    {
      "id": 3404,
      "kana": "ドライブ",
      "kanji": "",
      "type": "n",
      "meaning": "drive, trip by car, driving"
    },
    {
      "id": 3405,
      "kana": "とらえる",
      "kanji": "捕える",
      "type": "v1",
      "meaning": "to seize, to grasp, to capture, to arrest"
    },
    {
      "id": 3406,
      "kana": "トラック",
      "kanji": "",
      "type": "n",
      "meaning": "(1) truck, (2) (running) track"
    },
    {
      "id": 3407,
      "kana": "ドラマ",
      "kanji": "",
      "type": "n",
      "meaning": "drama"
    },
    {
      "id": 3408,
      "kana": "トランプ",
      "kanji": "",
      "type": "n",
      "meaning": "playing cards (lit: trump)"
    },
    {
      "id": 3409,
      "kana": "とり",
      "kanji": "鳥",
      "type": "n",
      "meaning": "bird, fowl, poultry"
    },
    {
      "id": 3410,
      "kana": "とりあげる",
      "kanji": "取り上げる",
      "type": "v1",
      "meaning": "to take up, to pick up, to disqualify, to confiscate, to deprive"
    },
    {
      "id": 3411,
      "kana": "とりいれる",
      "kanji": "取り入れる",
      "type": "v1",
      "meaning": "to harvest, to take in, to adopt"
    },
    {
      "id": 3412,
      "kana": "とりかえる",
      "kanji": "取り替える",
      "type": "v1",
      "meaning": "to exchange, to replace"
    },
    {
      "id": 3413,
      "kana": "とりけす",
      "kanji": "取り消す",
      "type": "v5s",
      "meaning": "to cancel"
    },
    {
      "id": 3414,
      "kana": "とりだす",
      "kanji": "取り出す",
      "type": "v5s",
      "meaning": "to take out, to produce, to pick out"
    },
    {
      "id": 3415,
      "kana": "どりょく",
      "kanji": "努力",
      "type": "n,vs",
      "meaning": "great effort, exertion, endeavour, effort"
    },
    {
      "id": 3416,
      "kana": "とる",
      "kanji": "取る",
      "type": "v5r",
      "meaning": "to take, to pick up, to harvest, to earn, to choose"
    },
    {
      "id": 3417,
      "kana": "とる",
      "kanji": "採る",
      "type": "v5r",
      "meaning": "(1) to adopt (measure, proposal), (2) to pick (fruit)"
    },
    {
      "id": 3418,
      "kana": "とる",
      "kanji": "捕る",
      "type": "v5r",
      "meaning": "to take, to catch (fish), to capture"
    },
    {
      "id": 3419,
      "kana": "とる",
      "kanji": "撮る",
      "type": "v5r",
      "meaning": "to take (a photo), to make (a film)"
    },
    {
      "id": 3420,
      "kana": "どれ",
      "kanji": "どれ",
      "type": "n",
      "meaning": "well, now, let me see, which (of three or more)"
    },
    {
      "id": 3421,
      "kana": "トレーニング",
      "kanji": "",
      "type": "n",
      "meaning": "training"
    },
    {
      "id": 3422,
      "kana": "ドレス",
      "kanji": "",
      "type": "n",
      "meaning": "dress"
    },
    {
      "id": 3423,
      "kana": "とれる",
      "kanji": "取れる",
      "type": "v1",
      "meaning": "to come off, to be taken off, to be removed"
    },
    {
      "id": 3424,
      "kana": "どろ",
      "kanji": "泥",
      "type": "n",
      "meaning": "mud"
    },
    {
      "id": 3425,
      "kana": "どろぼう",
      "kanji": "泥棒",
      "type": "n,vs",
      "meaning": "thief, burglar, robber, theft"
    },
    {
      "id": 3426,
      "kana": "（1000",
      "kanji": "とん",
      "type": "n",
      "meaning": "ton"
    },
    {
      "id": 3427,
      "kana": "とんでもない",
      "kanji": "",
      "type": "adj,exp",
      "meaning": "unexpected, offensive, What a thing to say!, No way!"
    },
    {
      "id": 3428,
      "kana": "どんどん",
      "kanji": "",
      "type": "adv",
      "meaning": "rapidly, steadily"
    },
    {
      "id": 3429,
      "kana": "どんな",
      "kanji": "",
      "type": "adj-na,adj-pn,n",
      "meaning": "what, what kind of"
    },
    {
      "id": 3430,
      "kana": "どんなに",
      "kanji": "",
      "type": "adv",
      "meaning": "how, how much"
    },
    {
      "id": 3431,
      "kana": "トンネル",
      "kanji": "",
      "type": "n",
      "meaning": "tunnel"
    },
    {
      "id": 3432,
      "kana": "どんぶり",
      "kanji": "丼",
      "type": "n",
      "meaning": "porcelain bowl, bowl of rice with food on top"
    },
    {
      "id": 3433,
      "kana": "な",
      "kanji": "名",
      "type": "n",
      "meaning": "name, reputation"
    },
    {
      "id": 3434,
      "kana": "ない",
      "kanji": "無い",
      "type": "adj",
      "meaning": "there isn't, doesn't have"
    },
    {
      "id": 3435,
      "kana": "~ない",
      "kanji": "~内",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3436,
      "kana": "ないか",
      "kanji": "内科",
      "type": "n",
      "meaning": "internist clinic, internal medicine"
    },
    {
      "id": 3437,
      "kana": "ないせん",
      "kanji": "内線",
      "type": "n",
      "meaning": "phone extension, indoor wiring, inner line"
    },
    {
      "id": 3438,
      "kana": "ナイフ",
      "kanji": "",
      "type": "n",
      "meaning": "knife"
    },
    {
      "id": 3439,
      "kana": "ないよう",
      "kanji": "内容",
      "type": "n",
      "meaning": "subject, contents, matter, substance, detail, import"
    },
    {
      "id": 3440,
      "kana": "ナイロン",
      "kanji": "",
      "type": "n",
      "meaning": "nylon"
    },
    {
      "id": 3441,
      "kana": "なお",
      "kanji": "",
      "type": "adj-na",
      "meaning": "straight, mischief, ordinary, common"
    },
    {
      "id": 3442,
      "kana": "なおす",
      "kanji": "直す",
      "type": "v5s,vt",
      "meaning": "to cure, to heal, to fix, to correct, to repair"
    },
    {
      "id": 3443,
      "kana": "なおす",
      "kanji": "治す",
      "type": "v5s,vt",
      "meaning": "to cure, to heal, to fix, to correct, to repair"
    },
    {
      "id": 3444,
      "kana": "~なおす",
      "kanji": "~直す",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3445,
      "kana": "なか",
      "kanji": "中",
      "type": "n",
      "meaning": "inside, middle, among"
    },
    {
      "id": 3446,
      "kana": "なか",
      "kanji": "仲",
      "type": "n",
      "meaning": "relation, relationship"
    },
    {
      "id": 3447,
      "kana": "なが~",
      "kanji": "長~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3448,
      "kana": "ながい",
      "kanji": "長い",
      "type": "adj",
      "meaning": "long"
    },
    {
      "id": 3449,
      "kana": "ながい",
      "kanji": "永い",
      "type": "adj",
      "meaning": "long, lengthy"
    },
    {
      "id": 3450,
      "kana": "ながす",
      "kanji": "流す",
      "type": "v5s",
      "meaning": "to drain, to float, to shed (blood, tears), to cruise (e.g. taxi)"
    },
    {
      "id": 3451,
      "kana": "なかなおり",
      "kanji": "仲直り",
      "type": "n,vs",
      "meaning": "reconciliation, make peace with"
    },
    {
      "id": 3452,
      "kana": "なかなか",
      "kanji": "",
      "type": "adv",
      "meaning": "very, considerably, easily"
    },
    {
      "id": 3453,
      "kana": "なかば",
      "kanji": "半ば",
      "type": "n-adv,n",
      "meaning": "middle, half, semi, halfway, partly"
    },
    {
      "id": 3454,
      "kana": "ながびく",
      "kanji": "長引く",
      "type": "v5k",
      "meaning": "to be prolonged, to drag on"
    },
    {
      "id": 3455,
      "kana": "なかま",
      "kanji": "仲間",
      "type": "n",
      "meaning": "company, fellow, colleague, associate"
    },
    {
      "id": 3456,
      "kana": "なかみ",
      "kanji": "中身",
      "type": "n",
      "meaning": "contents, interior, substance, filling, (sword) blade"
    },
    {
      "id": 3457,
      "kana": "なかみ",
      "kanji": "中味",
      "type": "n",
      "meaning": "contents, interior, substance, filling, (sword) blade"
    },
    {
      "id": 3458,
      "kana": "ながめ",
      "kanji": "眺め",
      "type": "n",
      "meaning": "scene, view, prospect, outlook"
    },
    {
      "id": 3459,
      "kana": "ながめる",
      "kanji": "眺める",
      "type": "v1",
      "meaning": "to view, to gaze at"
    },
    {
      "id": 3460,
      "kana": "なかゆび",
      "kanji": "中指",
      "type": "n",
      "meaning": "middle finger"
    },
    {
      "id": 3461,
      "kana": "なかよし",
      "kanji": "仲良し",
      "type": "n",
      "meaning": "intimate friend, bosom buddy, chum"
    },
    {
      "id": 3462,
      "kana": "ながれ",
      "kanji": "流れ",
      "type": "n",
      "meaning": "stream, current"
    },
    {
      "id": 3463,
      "kana": "ながれる",
      "kanji": "流れる",
      "type": "v1",
      "meaning": "to stream, to flow, to run (ink), to be washed away"
    },
    {
      "id": 3464,
      "kana": "なく",
      "kanji": "泣く",
      "type": "v5k",
      "meaning": "to cry, to weep"
    },
    {
      "id": 3465,
      "kana": "なく",
      "kanji": "鳴く",
      "type": "v5k",
      "meaning": "to sing (bird), to make sound (animal)"
    },
    {
      "id": 3466,
      "kana": "なぐさめる",
      "kanji": "慰める",
      "type": "v1",
      "meaning": "to comfort, to console"
    },
    {
      "id": 3467,
      "kana": "なくす",
      "kanji": "無くす",
      "type": "v5s",
      "meaning": "to lose something, to get rid of"
    },
    {
      "id": 3468,
      "kana": "なくす",
      "kanji": "亡くす",
      "type": "v5s",
      "meaning": "to lose someone, wife, child, etc"
    },
    {
      "id": 3469,
      "kana": "なくなる",
      "kanji": "無くなる",
      "type": "v5r",
      "meaning": "to disappear, to get lost"
    },
    {
      "id": 3470,
      "kana": "なくなる",
      "kanji": "亡くなる",
      "type": "v5r",
      "meaning": "to die"
    },
    {
      "id": 3471,
      "kana": "なぐる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to strike, to hit"
    },
    {
      "id": 3472,
      "kana": "なげる",
      "kanji": "投げる",
      "type": "v1",
      "meaning": "to throw, to cast away"
    },
    {
      "id": 3473,
      "kana": "なさる",
      "kanji": "",
      "type": "v5aru",
      "meaning": "(hon) to do"
    },
    {
      "id": 3474,
      "kana": "なし",
      "kanji": "無し",
      "type": "n,n-suf",
      "meaning": "without"
    },
    {
      "id": 3475,
      "kana": "なす",
      "kanji": "為す",
      "type": "v5s",
      "meaning": "to accomplish, to do"
    },
    {
      "id": 3476,
      "kana": "なぜ",
      "kanji": "",
      "type": "adv",
      "meaning": "why, how"
    },
    {
      "id": 3477,
      "kana": "なぜなら",
      "kanji": "",
      "type": "conj",
      "meaning": "because"
    },
    {
      "id": 3478,
      "kana": "なぞ",
      "kanji": "謎",
      "type": "n",
      "meaning": "riddle, puzzle, enigma"
    },
    {
      "id": 3479,
      "kana": "なぞなぞ",
      "kanji": "謎謎",
      "type": "n",
      "meaning": "riddle, puzzle, enigma"
    },
    {
      "id": 3480,
      "kana": "なだらか",
      "kanji": "傾らか",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3481,
      "kana": "なつ",
      "kanji": "夏",
      "type": "n-adv,n-t",
      "meaning": "summer"
    },
    {
      "id": 3482,
      "kana": "なつかしい",
      "kanji": "懐かしい",
      "type": "adj",
      "meaning": "dear, desired, missed"
    },
    {
      "id": 3483,
      "kana": "なっとく",
      "kanji": "納得",
      "type": "n,vs",
      "meaning": "consent, assent, understanding"
    },
    {
      "id": 3484,
      "kana": "なでる",
      "kanji": "撫でる",
      "type": "v1",
      "meaning": "to brush gently, to stroke"
    },
    {
      "id": 3485,
      "kana": "など",
      "kanji": "等",
      "type": "n,n-suf,prt",
      "meaning": "et cetera, etc., and the like"
    },
    {
      "id": 3486,
      "kana": "なな",
      "kanji": "七",
      "type": "num",
      "meaning": "seven"
    },
    {
      "id": 3487,
      "kana": "ななつ",
      "kanji": "七つ",
      "type": "n",
      "meaning": "seven"
    },
    {
      "id": 3488,
      "kana": "ななめ",
      "kanji": "斜め",
      "type": "adj-na,n",
      "meaning": "obliqueness"
    },
    {
      "id": 3489,
      "kana": "なに",
      "kanji": "何",
      "type": "int,n",
      "meaning": "what"
    },
    {
      "id": 3490,
      "kana": "なん",
      "kanji": "何",
      "type": "int,n",
      "meaning": "what"
    },
    {
      "id": 3491,
      "kana": "なにか",
      "kanji": "何か",
      "type": "exp",
      "meaning": "something"
    },
    {
      "id": 3492,
      "kana": "なにしろ",
      "kanji": "",
      "type": "adv,conj",
      "meaning": "at any rate, anyhow, anyway, in any case"
    },
    {
      "id": 3493,
      "kana": "なになに",
      "kanji": "何々",
      "type": "int,n",
      "meaning": "such and such, What?"
    },
    {
      "id": 3494,
      "kana": "なにぶん",
      "kanji": "何分",
      "type": "n",
      "meaning": "what minute?, how many minutes?"
    },
    {
      "id": 3495,
      "kana": "なにも",
      "kanji": "",
      "type": "adv,exp",
      "meaning": "nothing"
    },
    {
      "id": 3496,
      "kana": "なのか",
      "kanji": "七日",
      "type": "n-adv",
      "meaning": "seven days, the seventh day (of the month)"
    },
    {
      "id": 3497,
      "kana": "なべ",
      "kanji": "鍋",
      "type": "n,n-suf",
      "meaning": "saucepan, pot"
    },
    {
      "id": 3498,
      "kana": "なま",
      "kanji": "生",
      "type": "adj-na,adj-no,n",
      "meaning": "(1) draft (beer), (2) raw, unprocessed"
    },
    {
      "id": 3499,
      "kana": "なまいき",
      "kanji": "生意気",
      "type": "adj-na,n",
      "meaning": "impertinent, saucy, cheeky, conceit, audacious, brazen"
    },
    {
      "id": 3500,
      "kana": "なまえ",
      "kanji": "名前",
      "type": "n",
      "meaning": "name"
    },
    {
      "id": 3501,
      "kana": "なまける",
      "kanji": "怠ける",
      "type": "v1",
      "meaning": "to be idle, to neglect"
    },
    {
      "id": 3502,
      "kana": "なみ",
      "kanji": "波",
      "type": "n",
      "meaning": "wave"
    },
    {
      "id": 3503,
      "kana": "なみき",
      "kanji": "並木",
      "type": "n",
      "meaning": "roadside tree, row of trees"
    },
    {
      "id": 3504,
      "kana": "なみだ",
      "kanji": "涙",
      "type": "n",
      "meaning": "tear"
    },
    {
      "id": 3505,
      "kana": "なやむ",
      "kanji": "悩む",
      "type": "v5m",
      "meaning": "to be worried, to be troubled"
    },
    {
      "id": 3506,
      "kana": "ならう",
      "kanji": "習う",
      "type": "v5u",
      "meaning": "to learn"
    },
    {
      "id": 3507,
      "kana": "ならう",
      "kanji": "倣う",
      "type": "v5u",
      "meaning": "to imitate, to follow, to emulate"
    },
    {
      "id": 3508,
      "kana": "ならす",
      "kanji": "鳴らす",
      "type": "v5s,vt",
      "meaning": "to ring, to sound, to chime, to beat, to snort (nose)"
    },
    {
      "id": 3509,
      "kana": "ならぶ",
      "kanji": "並ぶ",
      "type": "v5b,vi",
      "meaning": "to line up, to stand in a line"
    },
    {
      "id": 3510,
      "kana": "ならべる",
      "kanji": "並べる",
      "type": "v1,vt",
      "meaning": "to line up, to set up"
    },
    {
      "id": 3511,
      "kana": "なる",
      "kanji": "為る",
      "type": "v5r",
      "meaning": "to change, to be of use, to reach to"
    },
    {
      "id": 3512,
      "kana": "なる",
      "kanji": "成る",
      "type": "v5r",
      "meaning": "to become"
    },
    {
      "id": 3513,
      "kana": "なる",
      "kanji": "生る",
      "type": "v5r",
      "meaning": "to bear fruit"
    },
    {
      "id": 3514,
      "kana": "なる",
      "kanji": "鳴る",
      "type": "v5r",
      "meaning": "to sound, to ring"
    },
    {
      "id": 3515,
      "kana": "なるべく",
      "kanji": "",
      "type": "adv",
      "meaning": "as much as possible"
    },
    {
      "id": 3516,
      "kana": "なるほど",
      "kanji": "成程",
      "type": "adv,exp",
      "meaning": "I see, That's right!, indeed"
    },
    {
      "id": 3517,
      "kana": "なれる",
      "kanji": "慣れる",
      "type": "v1",
      "meaning": "to grow accustomed to"
    },
    {
      "id": 3518,
      "kana": "なれる",
      "kanji": "馴れる",
      "type": "v1",
      "meaning": "to become domesticated, to become tame"
    },
    {
      "id": 3519,
      "kana": "なわ",
      "kanji": "縄",
      "type": "n",
      "meaning": "rope, hemp"
    },
    {
      "id": 3520,
      "kana": "なん~",
      "kanji": "何~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3521,
      "kana": "なんきょく",
      "kanji": "南極",
      "type": "n",
      "meaning": "south pole, Antarctic"
    },
    {
      "id": 3522,
      "kana": "~なんて",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3523,
      "kana": "なんで",
      "kanji": "何で",
      "type": "adv",
      "meaning": "Why?, What for?"
    },
    {
      "id": 3524,
      "kana": "なんでも",
      "kanji": "何でも",
      "type": "adv,exp",
      "meaning": "by all means, everything"
    },
    {
      "id": 3525,
      "kana": "なんとか",
      "kanji": "何とか",
      "type": "adv,exp,n",
      "meaning": "somehow, anyhow, one way or another"
    },
    {
      "id": 3526,
      "kana": "なんとなく",
      "kanji": "",
      "type": "adv",
      "meaning": "somehow or other, for some reason or another"
    },
    {
      "id": 3527,
      "kana": "なんとも",
      "kanji": "",
      "type": "adv",
      "meaning": "nothing (with neg. verb), quite, not a bit"
    },
    {
      "id": 3528,
      "kana": "ナンバー",
      "kanji": "",
      "type": "n",
      "meaning": "number"
    },
    {
      "id": 3529,
      "kana": "なんべい",
      "kanji": "南米",
      "type": "n",
      "meaning": "South America"
    },
    {
      "id": 3530,
      "kana": "なんぼく",
      "kanji": "南北",
      "type": "n",
      "meaning": "south and north"
    },
    {
      "id": 3531,
      "kana": "に",
      "kanji": "二",
      "type": "num",
      "meaning": "two"
    },
    {
      "id": 3532,
      "kana": "にあう",
      "kanji": "似合う",
      "type": "v5u",
      "meaning": "to suit, to match, to become, to be like"
    },
    {
      "id": 3533,
      "kana": "にえる",
      "kanji": "煮える",
      "type": "v1,vi",
      "meaning": "to boil, to cook, to be cooked"
    },
    {
      "id": 3534,
      "kana": "におい",
      "kanji": "匂い",
      "type": "n",
      "meaning": "odour, scent, smell, stench"
    },
    {
      "id": 3535,
      "kana": "におう",
      "kanji": "匂う",
      "type": "v5u,vi",
      "meaning": "to be fragrant, to smell, to stink"
    },
    {
      "id": 3536,
      "kana": "にがい",
      "kanji": "苦い",
      "type": "adj",
      "meaning": "bitter"
    },
    {
      "id": 3537,
      "kana": "にがす",
      "kanji": "逃がす",
      "type": "v5s",
      "meaning": "to let loose, to set free, to let escape"
    },
    {
      "id": 3538,
      "kana": "にがて",
      "kanji": "苦手",
      "type": "adj-na,n",
      "meaning": "poor (at), weak (in), dislike (of)"
    },
    {
      "id": 3539,
      "kana": "にぎやか",
      "kanji": "賑やか",
      "type": "adj-na,n",
      "meaning": "bustling, busy"
    },
    {
      "id": 3540,
      "kana": "にぎる",
      "kanji": "握る",
      "type": "v5r",
      "meaning": "to grasp, to seize, to mould sushi"
    },
    {
      "id": 3541,
      "kana": "にく",
      "kanji": "肉",
      "type": "n",
      "meaning": "meat"
    },
    {
      "id": 3542,
      "kana": "にくい",
      "kanji": "憎い",
      "type": "adj",
      "meaning": "hateful, abominable, poor-looking, detestable"
    },
    {
      "id": 3543,
      "kana": "~にくい",
      "kanji": "~難い",
      "type": "aux-adj",
      "meaning": "difficult, harde"
    },
    {
      "id": 3544,
      "kana": "にくむ",
      "kanji": "憎む",
      "type": "v5m",
      "meaning": "to hate, to detest"
    },
    {
      "id": 3545,
      "kana": "にくらしい",
      "kanji": "憎らしい",
      "type": "adj",
      "meaning": "odious, hateful"
    },
    {
      "id": 3546,
      "kana": "にげる",
      "kanji": "逃げる",
      "type": "v1",
      "meaning": "to escape, to run away"
    },
    {
      "id": 3547,
      "kana": "にこにこ",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3548,
      "kana": "にごる",
      "kanji": "濁る",
      "type": "v5r",
      "meaning": "to become muddy, to get impure"
    },
    {
      "id": 3549,
      "kana": "にし",
      "kanji": "西",
      "type": "n",
      "meaning": "west"
    },
    {
      "id": 3550,
      "kana": "にじ",
      "kanji": "虹",
      "type": "n",
      "meaning": "rainbow"
    },
    {
      "id": 3551,
      "kana": "~にち",
      "kanji": "~日",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3552,
      "kana": "にち",
      "kanji": "日",
      "type": "n-adv,n-t",
      "meaning": "sun, sunshine, day"
    },
    {
      "id": 3553,
      "kana": "にちじ",
      "kanji": "日時",
      "type": "n",
      "meaning": "date and time"
    },
    {
      "id": 3554,
      "kana": "にちじょう",
      "kanji": "日常",
      "type": "adj-no,n-adv,n-t",
      "meaning": "ordinary, regular, everyday, usual"
    },
    {
      "id": 3555,
      "kana": "にちよう",
      "kanji": "日曜",
      "type": "n-adv,n",
      "meaning": "Sunday"
    },
    {
      "id": 3556,
      "kana": "にちようひん",
      "kanji": "日用品",
      "type": "n",
      "meaning": "daily necessities"
    },
    {
      "id": 3557,
      "kana": "にっか",
      "kanji": "日課",
      "type": "n",
      "meaning": "daily lesson, daily work, daily routine"
    },
    {
      "id": 3558,
      "kana": "にっき",
      "kanji": "日記",
      "type": "n",
      "meaning": "diary, journal"
    },
    {
      "id": 3559,
      "kana": "にっこう",
      "kanji": "日光",
      "type": "n",
      "meaning": "sunlight"
    },
    {
      "id": 3560,
      "kana": "にっちゅう",
      "kanji": "日中",
      "type": "n-t",
      "meaning": "daytime, broad daylight"
    },
    {
      "id": 3561,
      "kana": "にっこり",
      "kanji": "",
      "type": "adv,n",
      "meaning": "smile sweetly, smile, grin"
    },
    {
      "id": 3562,
      "kana": "にってい",
      "kanji": "日程",
      "type": "n",
      "meaning": "agenda"
    },
    {
      "id": 3563,
      "kana": "にっぽん",
      "kanji": "日本",
      "type": "",
      "meaning": "Japan"
    },
    {
      "id": 3564,
      "kana": "にほん",
      "kanji": "日本",
      "type": "",
      "meaning": "Japan"
    },
    {
      "id": 3565,
      "kana": "にぶい",
      "kanji": "鈍い",
      "type": "adj",
      "meaning": "dull (e.g. a knife), thickheaded, slow (opposite of fast), stupid"
    },
    {
      "id": 3566,
      "kana": "にもつ",
      "kanji": "荷物",
      "type": "n",
      "meaning": "luggage"
    },
    {
      "id": 3567,
      "kana": "にゅういん",
      "kanji": "入院",
      "type": "n,vs",
      "meaning": "hospitalization"
    },
    {
      "id": 3568,
      "kana": "にゅうがく",
      "kanji": "入学",
      "type": "n",
      "meaning": "entry to school or university, matriculation"
    },
    {
      "id": 3569,
      "kana": "にゅうしゃ",
      "kanji": "入社",
      "type": "n,vs",
      "meaning": "entry to a company"
    },
    {
      "id": 3570,
      "kana": "にゅうじょう",
      "kanji": "入場",
      "type": "n",
      "meaning": "entrance, admission, entering"
    },
    {
      "id": 3571,
      "kana": "ニュース",
      "kanji": "",
      "type": "n",
      "meaning": "news"
    },
    {
      "id": 3572,
      "kana": "にょうぼう",
      "kanji": "女房",
      "type": "n",
      "meaning": "wife"
    },
    {
      "id": 3573,
      "kana": "にらむ",
      "kanji": "睨む",
      "type": "v5m",
      "meaning": "to glare at, to scowl at, to keep an eye on"
    },
    {
      "id": 3574,
      "kana": "にる",
      "kanji": "似る",
      "type": "v1",
      "meaning": "to resemble, to be similar"
    },
    {
      "id": 3575,
      "kana": "にる",
      "kanji": "煮る",
      "type": "v1,vt",
      "meaning": "to boil, to cook"
    },
    {
      "id": 3576,
      "kana": "にわ",
      "kanji": "庭",
      "type": "n",
      "meaning": "garden"
    },
    {
      "id": 3577,
      "kana": "にわか",
      "kanji": "俄",
      "type": "adj-na,n",
      "meaning": "sudden, abrupt, unexpected, improvised, offhand"
    },
    {
      "id": 3578,
      "kana": "~にん",
      "kanji": "~人",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3579,
      "kana": "にんき",
      "kanji": "人気",
      "type": "n",
      "meaning": "sign of life"
    },
    {
      "id": 3580,
      "kana": "にんぎょう",
      "kanji": "人形",
      "type": "n",
      "meaning": "doll, puppet, figure"
    },
    {
      "id": 3581,
      "kana": "にんげん",
      "kanji": "人間",
      "type": "n",
      "meaning": "human being, man, person"
    },
    {
      "id": 3582,
      "kana": "ぬう",
      "kanji": "縫う",
      "type": "v5u",
      "meaning": "to sew"
    },
    {
      "id": 3583,
      "kana": "ぬく",
      "kanji": "抜く",
      "type": "v5k",
      "meaning": "to extract, to omit, to surpass, to draw out, to unplug"
    },
    {
      "id": 3584,
      "kana": "ぬぐ",
      "kanji": "脱ぐ",
      "type": "v5g",
      "meaning": "to take off clothes"
    },
    {
      "id": 3585,
      "kana": "ぬける",
      "kanji": "抜ける",
      "type": "v1",
      "meaning": "to come out, to fall out, to be omitted"
    },
    {
      "id": 3586,
      "kana": "ぬすむ",
      "kanji": "盗む",
      "type": "v5m",
      "meaning": "to steal"
    },
    {
      "id": 3587,
      "kana": "ぬの",
      "kanji": "布",
      "type": "n",
      "meaning": "cloth"
    },
    {
      "id": 3588,
      "kana": "ぬらす",
      "kanji": "濡らす",
      "type": "v5s",
      "meaning": "to wet, to soak, to dip"
    },
    {
      "id": 3589,
      "kana": "ぬる",
      "kanji": "塗る",
      "type": "v5r",
      "meaning": "to paint, to plaster, to lacquer, to varnish"
    },
    {
      "id": 3590,
      "kana": "ぬるい",
      "kanji": "温い",
      "type": "adj",
      "meaning": "lukewarm, tepid"
    },
    {
      "id": 3591,
      "kana": "ぬれる",
      "kanji": "濡れる",
      "type": "v1",
      "meaning": "to get wet"
    },
    {
      "id": 3592,
      "kana": "（感）",
      "kanji": "ね",
      "type": "adj-no,n,vs",
      "meaning": "value, price, cost, worth, merit"
    },
    {
      "id": 3593,
      "kana": "ね",
      "kanji": "根",
      "type": "n",
      "meaning": "root"
    },
    {
      "id": 3594,
      "kana": "ね",
      "kanji": "値",
      "type": "adj-no,n,vs",
      "meaning": "value, price, cost"
    },
    {
      "id": 3595,
      "kana": "ねがい",
      "kanji": "願い",
      "type": "n",
      "meaning": "desire, wish, request"
    },
    {
      "id": 3596,
      "kana": "ねがう",
      "kanji": "願う",
      "type": "v5u",
      "meaning": "to desire, to wish, to request"
    },
    {
      "id": 3597,
      "kana": "ネクタイ",
      "kanji": "",
      "type": "n",
      "meaning": "tie, necktie"
    },
    {
      "id": 3598,
      "kana": "ねこ",
      "kanji": "猫",
      "type": "n",
      "meaning": "cat"
    },
    {
      "id": 3599,
      "kana": "ねじ",
      "kanji": "",
      "type": "n",
      "meaning": "(a) screw"
    },
    {
      "id": 3600,
      "kana": "ねじる",
      "kanji": "捩る",
      "type": "v5r",
      "meaning": "to twist"
    },
    {
      "id": 3601,
      "kana": "ねずみ",
      "kanji": "鼠",
      "type": "n",
      "meaning": "(1) mouse, rat, (2) dark gray, slate color"
    },
    {
      "id": 3602,
      "kana": "ねだん",
      "kanji": "値段",
      "type": "n",
      "meaning": "price, cost"
    },
    {
      "id": 3603,
      "kana": "ねつ",
      "kanji": "熱",
      "type": "n,n-suf",
      "meaning": "fever, temperature"
    },
    {
      "id": 3604,
      "kana": "ネックレス",
      "kanji": "",
      "type": "n",
      "meaning": "necklace"
    },
    {
      "id": 3605,
      "kana": "ねっしん",
      "kanji": "熱心",
      "type": "adj-na,n",
      "meaning": "zeal, enthusiasm"
    },
    {
      "id": 3606,
      "kana": "ねっする",
      "kanji": "熱する",
      "type": "vs-s",
      "meaning": "to heat"
    },
    {
      "id": 3607,
      "kana": "ねったい",
      "kanji": "熱帯",
      "type": "n",
      "meaning": "tropics"
    },
    {
      "id": 3608,
      "kana": "ねっちゅう",
      "kanji": "熱中",
      "type": "n",
      "meaning": "nuts!, enthusiasm, zeal, mania"
    },
    {
      "id": 3609,
      "kana": "ねぼう",
      "kanji": "寝坊",
      "type": "n,vs",
      "meaning": "sleeping in late"
    },
    {
      "id": 3610,
      "kana": "ねまき",
      "kanji": "寝間着",
      "type": "n",
      "meaning": "sleep-wear, nightclothes, pyjamas, nightgown, nightdress"
    },
    {
      "id": 3611,
      "kana": "ねまき",
      "kanji": "寝巻",
      "type": "n",
      "meaning": "sleep-wear, nightclothes, pyjamas, nightgown, nightdress"
    },
    {
      "id": 3612,
      "kana": "ねむい",
      "kanji": "眠い",
      "type": "adj",
      "meaning": "(1) aestivation, estivation, (2) sleepy, drowsy"
    },
    {
      "id": 3613,
      "kana": "ねむる",
      "kanji": "眠る",
      "type": "v5r",
      "meaning": "to sleep"
    },
    {
      "id": 3614,
      "kana": "ねらい",
      "kanji": "狙い",
      "type": "n",
      "meaning": "aim"
    },
    {
      "id": 3615,
      "kana": "ねらう",
      "kanji": "狙う",
      "type": "v5u",
      "meaning": "to aim at"
    },
    {
      "id": 3616,
      "kana": "ねる",
      "kanji": "寝る",
      "type": "v1",
      "meaning": "to go to bed, to lie down, to sleep"
    },
    {
      "id": 3617,
      "kana": "~ねん",
      "kanji": "~年",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3618,
      "kana": "ねんかん",
      "kanji": "年間",
      "type": "n-t",
      "meaning": "year"
    },
    {
      "id": 3619,
      "kana": "ねんげつ",
      "kanji": "年月",
      "type": "n",
      "meaning": "months and years"
    },
    {
      "id": 3620,
      "kana": "ねんじゅう",
      "kanji": "年中",
      "type": "n-adv,n",
      "meaning": "whole year, always, everyday"
    },
    {
      "id": 3621,
      "kana": "~ねんせい",
      "kanji": "~年生",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3622,
      "kana": "ねんだい",
      "kanji": "年代",
      "type": "n",
      "meaning": "age, era, period, date"
    },
    {
      "id": 3623,
      "kana": "ねんど",
      "kanji": "年度",
      "type": "n,n-suf",
      "meaning": "year, fiscal year, school year, term"
    },
    {
      "id": 3624,
      "kana": "ねんれい",
      "kanji": "年齢",
      "type": "n",
      "meaning": "age, years"
    },
    {
      "id": 3625,
      "kana": "の",
      "kanji": "野",
      "type": "n",
      "meaning": "field"
    },
    {
      "id": 3626,
      "kana": "のう",
      "kanji": "能",
      "type": "adv,n,vs",
      "meaning": "being skilled in, nicely, properly"
    },
    {
      "id": 3627,
      "kana": "のうか",
      "kanji": "農家",
      "type": "n",
      "meaning": "farmer, farm family"
    },
    {
      "id": 3628,
      "kana": "のうぎょう",
      "kanji": "農業",
      "type": "n",
      "meaning": "agriculture"
    },
    {
      "id": 3629,
      "kana": "のうさんぶつ",
      "kanji": "農産物",
      "type": "n",
      "meaning": "agricultural produce"
    },
    {
      "id": 3630,
      "kana": "のうそん",
      "kanji": "農村",
      "type": "n",
      "meaning": "agricultural community, farm village, rural"
    },
    {
      "id": 3631,
      "kana": "のうど",
      "kanji": "濃度",
      "type": "n",
      "meaning": "concentration, brightness"
    },
    {
      "id": 3632,
      "kana": "のうみん",
      "kanji": "農民",
      "type": "n",
      "meaning": "farmers, peasants"
    },
    {
      "id": 3633,
      "kana": "のうやく",
      "kanji": "農薬",
      "type": "n",
      "meaning": "agricultural chemicals"
    },
    {
      "id": 3634,
      "kana": "のうりつ",
      "kanji": "能率",
      "type": "n",
      "meaning": "efficiency"
    },
    {
      "id": 3635,
      "kana": "のうりょく",
      "kanji": "能力",
      "type": "n",
      "meaning": "ability, faculty"
    },
    {
      "id": 3636,
      "kana": "（no）",
      "kanji": "ノー",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3637,
      "kana": "ノート",
      "kanji": "",
      "type": "n",
      "meaning": "notebook, copy-book, exercise book"
    },
    {
      "id": 3638,
      "kana": "のき",
      "kanji": "軒",
      "type": "n",
      "meaning": "eaves"
    },
    {
      "id": 3639,
      "kana": "のこぎり",
      "kanji": "",
      "type": "n",
      "meaning": "saw"
    },
    {
      "id": 3640,
      "kana": "のこす",
      "kanji": "残す",
      "type": "v5s",
      "meaning": "to leave (behind, over), to bequeath, to save, to reserve"
    },
    {
      "id": 3641,
      "kana": "のこらず",
      "kanji": "残らず",
      "type": "adv",
      "meaning": "all, entirely, completely, without exception"
    },
    {
      "id": 3642,
      "kana": "のこり",
      "kanji": "残り",
      "type": "adj-no,n",
      "meaning": "remnant, residue, remaining, left-over"
    },
    {
      "id": 3643,
      "kana": "のこる",
      "kanji": "残る",
      "type": "v5r",
      "meaning": "to remain, to be left"
    },
    {
      "id": 3644,
      "kana": "のせる",
      "kanji": "乗せる",
      "type": "v1",
      "meaning": "to place on (something), to take on board, to give a ride"
    },
    {
      "id": 3645,
      "kana": "のせる",
      "kanji": "載せる",
      "type": "v1",
      "meaning": "to place on (something), to take on board, to give a ride"
    },
    {
      "id": 3646,
      "kana": "のぞく",
      "kanji": "覗く",
      "type": "v5k",
      "meaning": "to peep in, to look in, to peek in, to stick out"
    },
    {
      "id": 3647,
      "kana": "のぞく",
      "kanji": "除く",
      "type": "v5k",
      "meaning": "to remove, to exclude, to except"
    },
    {
      "id": 3648,
      "kana": "のぞみ",
      "kanji": "望み",
      "type": "n",
      "meaning": "wish, desire, (a) hope"
    },
    {
      "id": 3649,
      "kana": "のぞむ",
      "kanji": "望む",
      "type": "v5m",
      "meaning": "to desire, to wish for, to see, to command (a view of)"
    },
    {
      "id": 3650,
      "kana": "のち",
      "kanji": "後",
      "type": "adj-no,n",
      "meaning": "afterwards, since then, in the future"
    },
    {
      "id": 3651,
      "kana": "ノック",
      "kanji": "",
      "type": "n",
      "meaning": "(1) knock, (2) fungo (baseball)"
    },
    {
      "id": 3652,
      "kana": "のど",
      "kanji": "喉",
      "type": "n",
      "meaning": "throat"
    },
    {
      "id": 3653,
      "kana": "のばす",
      "kanji": "伸ばす",
      "type": "v5s,vt",
      "meaning": "to lengthen, to stretch, to reach out, to grow (beard)"
    },
    {
      "id": 3654,
      "kana": "のばす",
      "kanji": "延ばす",
      "type": "v5s,vt",
      "meaning": "to lengthen, to stretch, to reach out, to grow (beard)"
    },
    {
      "id": 3655,
      "kana": "のびる",
      "kanji": "伸びる",
      "type": "v1,vi",
      "meaning": "to stretch, to extend, to make progress, to grow (beard, body height)"
    },
    {
      "id": 3656,
      "kana": "のびる",
      "kanji": "延びる",
      "type": "v1",
      "meaning": "to be prolonged"
    },
    {
      "id": 3657,
      "kana": "のべる",
      "kanji": "述べる",
      "type": "v1",
      "meaning": "to state, to express, to mention"
    },
    {
      "id": 3658,
      "kana": "のぼり",
      "kanji": "上り",
      "type": "n",
      "meaning": "up-train (going to Tokyo), ascent"
    },
    {
      "id": 3659,
      "kana": "のぼる",
      "kanji": "上る",
      "type": "v5r",
      "meaning": "to ascend, to go up, to climb"
    },
    {
      "id": 3660,
      "kana": "のぼる",
      "kanji": "昇る",
      "type": "v5r",
      "meaning": "to arise, to ascend, to go up"
    },
    {
      "id": 3661,
      "kana": "のぼる",
      "kanji": "登る",
      "type": "v5r",
      "meaning": "to climb"
    },
    {
      "id": 3662,
      "kana": "のむ",
      "kanji": "飲む",
      "type": "v5m",
      "meaning": "to drink"
    },
    {
      "id": 3663,
      "kana": "のり",
      "kanji": "糊",
      "type": "n",
      "meaning": "paste, starch"
    },
    {
      "id": 3664,
      "kana": "のりかえ",
      "kanji": "乗換",
      "type": "io",
      "meaning": "(n) transfer (trains, buses, etc.)"
    },
    {
      "id": 3665,
      "kana": "のりかえる",
      "kanji": "乗り換える",
      "type": "v1",
      "meaning": "to transfer (trains), to change (bus, train)"
    },
    {
      "id": 3666,
      "kana": "のりこし",
      "kanji": "乗り越し",
      "type": "n",
      "meaning": "riding past (one's station)"
    },
    {
      "id": 3667,
      "kana": "のる",
      "kanji": "乗る",
      "type": "v5r",
      "meaning": "to get on, to ride in, to board"
    },
    {
      "id": 3668,
      "kana": "のる",
      "kanji": "載る",
      "type": "v5r",
      "meaning": "to appear (in print), to be recorded"
    },
    {
      "id": 3669,
      "kana": "のろい",
      "kanji": "鈍い",
      "type": "adj",
      "meaning": "dull (e.g. a knife), thickheaded, slow (opposite of fast), stupid"
    },
    {
      "id": 3670,
      "kana": "のろのろ",
      "kanji": "",
      "type": "adv,n",
      "meaning": "slowly, sluggishly"
    },
    {
      "id": 3671,
      "kana": "のんき",
      "kanji": "呑気",
      "type": "adj-na,n",
      "meaning": "carefree, optimistic, careless, reckless, heedless"
    },
    {
      "id": 3672,
      "kana": "のんびり",
      "kanji": "",
      "type": "adv,n",
      "meaning": "carefree, at leisure"
    },
    {
      "id": 3673,
      "kana": "は",
      "kanji": "歯",
      "type": "n",
      "meaning": "tooth"
    },
    {
      "id": 3674,
      "kana": "は",
      "kanji": "葉",
      "type": "n",
      "meaning": "leaf"
    },
    {
      "id": 3675,
      "kana": "ば",
      "kanji": "場",
      "type": "n",
      "meaning": "place, field (physics)"
    },
    {
      "id": 3676,
      "kana": "（感）",
      "kanji": "はあ",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3677,
      "kana": "ばあい",
      "kanji": "場合",
      "type": "n-adv,n",
      "meaning": "case, situation"
    },
    {
      "id": 3678,
      "kana": "パーセント",
      "kanji": "",
      "type": "n",
      "meaning": "percent"
    },
    {
      "id": 3679,
      "kana": "パーティー",
      "kanji": "",
      "type": "n",
      "meaning": "party"
    },
    {
      "id": 3680,
      "kana": "（感）",
      "kanji": "はい",
      "type": "",
      "meaning": "wear, put on (sword)"
    },
    {
      "id": 3681,
      "kana": "はい",
      "kanji": "灰",
      "type": "n",
      "meaning": "ash"
    },
    {
      "id": 3682,
      "kana": "ばい",
      "kanji": "倍",
      "type": "n,vi,vs,vt",
      "meaning": "twice, times, -fold, double, be doubled, increase"
    },
    {
      "id": 3683,
      "kana": "はいいろ",
      "kanji": "灰色",
      "type": "n",
      "meaning": "grey, gray, ashen"
    },
    {
      "id": 3684,
      "kana": "ばいう",
      "kanji": "梅雨",
      "type": "n",
      "meaning": "rainy season, rain during the rainy season"
    },
    {
      "id": 3685,
      "kana": "バイオリン",
      "kanji": "",
      "type": "n",
      "meaning": "violin"
    },
    {
      "id": 3686,
      "kana": "ハイキング",
      "kanji": "",
      "type": "n",
      "meaning": "hiking"
    },
    {
      "id": 3687,
      "kana": "はいく",
      "kanji": "俳句",
      "type": "n",
      "meaning": "haiku poetry"
    },
    {
      "id": 3688,
      "kana": "はいけん",
      "kanji": "拝見",
      "type": "n,vs",
      "meaning": "(hum) (pol) seeing, look at"
    },
    {
      "id": 3689,
      "kana": "はいざら",
      "kanji": "灰皿",
      "type": "n",
      "meaning": "ashtray"
    },
    {
      "id": 3690,
      "kana": "はいたつ",
      "kanji": "配達",
      "type": "n,vs",
      "meaning": "delivery, distribution"
    },
    {
      "id": 3691,
      "kana": "ばいてん",
      "kanji": "売店",
      "type": "n",
      "meaning": "shop, stand"
    },
    {
      "id": 3692,
      "kana": "バイバイ",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3693,
      "kana": "ばいばい",
      "kanji": "売買",
      "type": "n",
      "meaning": "trade, buying and selling"
    },
    {
      "id": 3694,
      "kana": "パイプ",
      "kanji": "",
      "type": "n",
      "meaning": "(1) pipe, tube, (2) channels, official or otherwise"
    },
    {
      "id": 3695,
      "kana": "はいゆう",
      "kanji": "俳優",
      "type": "n",
      "meaning": "actor, actress, player, performer"
    },
    {
      "id": 3696,
      "kana": "はいる",
      "kanji": "入る",
      "type": "v5r",
      "meaning": "to enter, to join"
    },
    {
      "id": 3697,
      "kana": "パイロット",
      "kanji": "",
      "type": "n",
      "meaning": "pilot"
    },
    {
      "id": 3698,
      "kana": "はう",
      "kanji": "這う",
      "type": "v5u",
      "meaning": "to creep, to crawl"
    },
    {
      "id": 3699,
      "kana": "はえる",
      "kanji": "生える",
      "type": "v1",
      "meaning": "(1) to grow, to spring up, (2) to cut (teeth)"
    },
    {
      "id": 3700,
      "kana": "はか",
      "kanji": "墓",
      "type": "n",
      "meaning": "grave, tomb"
    },
    {
      "id": 3701,
      "kana": "ばか",
      "kanji": "馬鹿",
      "type": "adj-na,exp,n",
      "meaning": "fool, idiot, trivial matter, folly"
    },
    {
      "id": 3702,
      "kana": "はがき",
      "kanji": "葉書",
      "type": "n",
      "meaning": "postcard"
    },
    {
      "id": 3703,
      "kana": "はがす",
      "kanji": "剥す",
      "type": "io",
      "meaning": "(v5s) to tear off, to peel off, to rip off"
    },
    {
      "id": 3704,
      "kana": "はかせ",
      "kanji": "博士",
      "type": "n",
      "meaning": "doctorate, PhD"
    },
    {
      "id": 3705,
      "kana": "ばからしい",
      "kanji": "",
      "type": "adj",
      "meaning": "absurd"
    },
    {
      "id": 3706,
      "kana": "はかり",
      "kanji": "秤",
      "type": "n",
      "meaning": "scales, weighing machine"
    },
    {
      "id": 3707,
      "kana": "はかる",
      "kanji": "計る",
      "type": "v5r",
      "meaning": "to measure, to weigh, to survey"
    },
    {
      "id": 3708,
      "kana": "はかる",
      "kanji": "量る",
      "type": "v5r",
      "meaning": "to measure, to weigh, to survey"
    },
    {
      "id": 3709,
      "kana": "はかる",
      "kanji": "測る",
      "type": "v5r",
      "meaning": "to measure, to weigh, to survey"
    },
    {
      "id": 3710,
      "kana": "はきけ",
      "kanji": "吐き気",
      "type": "n",
      "meaning": "nausea, sickness in the stomach"
    },
    {
      "id": 3711,
      "kana": "はきはき",
      "kanji": "",
      "type": "adv,n",
      "meaning": "lucidly"
    },
    {
      "id": 3712,
      "kana": "（ズボンを~）",
      "kanji": "はく",
      "type": "v5k",
      "meaning": "to wear, to put on (a sword)"
    },
    {
      "id": 3713,
      "kana": "はく",
      "kanji": "履く",
      "type": "v5k",
      "meaning": "to wear, to put on (lower body)"
    },
    {
      "id": 3714,
      "kana": "はく",
      "kanji": "掃く",
      "type": "v5k",
      "meaning": "to sweep, to brush, to gather up"
    },
    {
      "id": 3715,
      "kana": "はく",
      "kanji": "吐く",
      "type": "v5k",
      "meaning": "(1) to breathe, (2) to tell (lies), (3) to vomit, to disgorge"
    },
    {
      "id": 3716,
      "kana": "~はく",
      "kanji": "~泊",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3717,
      "kana": "はくしゅ",
      "kanji": "拍手",
      "type": "n",
      "meaning": "clapping hands, applause"
    },
    {
      "id": 3718,
      "kana": "ばくだい",
      "kanji": "莫大",
      "type": "adj-na,n",
      "meaning": "enormous, vast"
    },
    {
      "id": 3719,
      "kana": "ばくはつ",
      "kanji": "爆発",
      "type": "n,vs",
      "meaning": "explosion, detonation, eruption"
    },
    {
      "id": 3720,
      "kana": "はくぶつかん",
      "kanji": "博物館",
      "type": "n",
      "meaning": "museum"
    },
    {
      "id": 3721,
      "kana": "はぐるま",
      "kanji": "歯車",
      "type": "n",
      "meaning": "gear, cog-wheel"
    },
    {
      "id": 3722,
      "kana": "はげしい",
      "kanji": "激しい",
      "type": "adj",
      "meaning": "violent, vehement, intense"
    },
    {
      "id": 3723,
      "kana": "バケツ",
      "kanji": "",
      "type": "n",
      "meaning": "bucket, pail"
    },
    {
      "id": 3724,
      "kana": "はこ",
      "kanji": "箱",
      "type": "n",
      "meaning": "box"
    },
    {
      "id": 3725,
      "kana": "はこぶ",
      "kanji": "運ぶ",
      "type": "v5b",
      "meaning": "to transport"
    },
    {
      "id": 3726,
      "kana": "はさまる",
      "kanji": "挟まる",
      "type": "v5r",
      "meaning": "to get between, to be caught in"
    },
    {
      "id": 3727,
      "kana": "はさみ",
      "kanji": "",
      "type": "n",
      "meaning": "scissors"
    },
    {
      "id": 3728,
      "kana": "はさむ",
      "kanji": "挟む",
      "type": "v5m",
      "meaning": "to interpose, to hold between, to insert"
    },
    {
      "id": 3729,
      "kana": "はさん",
      "kanji": "破産",
      "type": "n,vs",
      "meaning": "(personal) bankruptcy"
    },
    {
      "id": 3730,
      "kana": "はし",
      "kanji": "橋",
      "type": "n",
      "meaning": "bridge"
    },
    {
      "id": 3731,
      "kana": "はし",
      "kanji": "端",
      "type": "n",
      "meaning": "end (e.g. of street), edge, tip, margin, point"
    },
    {
      "id": 3732,
      "kana": "はし",
      "kanji": "箸",
      "type": "n",
      "meaning": "chopsticks"
    },
    {
      "id": 3733,
      "kana": "はしご",
      "kanji": "梯子",
      "type": "n",
      "meaning": "ladder, stairs"
    },
    {
      "id": 3734,
      "kana": "はじまり",
      "kanji": "始まり",
      "type": "n",
      "meaning": "origin, beginning"
    },
    {
      "id": 3735,
      "kana": "はじまる",
      "kanji": "始まる",
      "type": "v5r,vi",
      "meaning": "to begin"
    },
    {
      "id": 3736,
      "kana": "はじめに",
      "kanji": "始めに",
      "type": "",
      "meaning": "to begin with, first of all"
    },
    {
      "id": 3737,
      "kana": "はじめに",
      "kanji": "初めに",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3738,
      "kana": "はじめて",
      "kanji": "初めて",
      "type": "adv,n",
      "meaning": "for the first time"
    },
    {
      "id": 3739,
      "kana": "はじめまして",
      "kanji": "",
      "type": "exp,int",
      "meaning": "How do you do?, I am glad to meet you"
    },
    {
      "id": 3740,
      "kana": "はじめる",
      "kanji": "始める",
      "type": "v1,vt",
      "meaning": "to start, to begin"
    },
    {
      "id": 3741,
      "kana": "~はじめる",
      "kanji": "~始める",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3742,
      "kana": "ばしょ",
      "kanji": "場所",
      "type": "n",
      "meaning": "place, location"
    },
    {
      "id": 3743,
      "kana": "はしる",
      "kanji": "走る",
      "type": "v5r",
      "meaning": "to run"
    },
    {
      "id": 3744,
      "kana": "はす",
      "kanji": "斜",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3745,
      "kana": "はず",
      "kanji": "",
      "type": "n",
      "meaning": "it should be so"
    },
    {
      "id": 3746,
      "kana": "バス",
      "kanji": "",
      "type": "n",
      "meaning": "(1) bus, (2) bath, (3) bass"
    },
    {
      "id": 3747,
      "kana": "パス",
      "kanji": "",
      "type": "n,vs",
      "meaning": "path, pass (in games)"
    },
    {
      "id": 3748,
      "kana": "はずかしい",
      "kanji": "恥ずかしい",
      "type": "adj",
      "meaning": "shy, ashamed, embarrassed"
    },
    {
      "id": 3749,
      "kana": "はずす",
      "kanji": "外す",
      "type": "v5s",
      "meaning": "to unfasten, to remove"
    },
    {
      "id": 3750,
      "kana": "パスポート",
      "kanji": "",
      "type": "n",
      "meaning": "passport"
    },
    {
      "id": 3751,
      "kana": "はずれる",
      "kanji": "外れる",
      "type": "v1,vi",
      "meaning": "to be disconnected, to get out of place, to be off, to be out (e.g. of gear)"
    },
    {
      "id": 3752,
      "kana": "はた",
      "kanji": "旗",
      "type": "n",
      "meaning": "flag"
    },
    {
      "id": 3753,
      "kana": "はだ",
      "kanji": "肌",
      "type": "n",
      "meaning": "skin"
    },
    {
      "id": 3754,
      "kana": "バター",
      "kanji": "",
      "type": "n",
      "meaning": "butter"
    },
    {
      "id": 3755,
      "kana": "パターン",
      "kanji": "",
      "type": "n",
      "meaning": "pattern"
    },
    {
      "id": 3756,
      "kana": "はだか",
      "kanji": "裸",
      "type": "adj-no,n",
      "meaning": "naked, nude"
    },
    {
      "id": 3757,
      "kana": "はだぎ",
      "kanji": "肌着",
      "type": "n",
      "meaning": "underwear"
    },
    {
      "id": 3758,
      "kana": "はたけ",
      "kanji": "畑",
      "type": "n",
      "meaning": "field"
    },
    {
      "id": 3759,
      "kana": "はたして",
      "kanji": "果して",
      "type": "adv",
      "meaning": "as was expected, really"
    },
    {
      "id": 3760,
      "kana": "はたち",
      "kanji": "二十歳",
      "type": "n",
      "meaning": "20 years old, 20th year"
    },
    {
      "id": 3761,
      "kana": "はたち",
      "kanji": "二十",
      "type": "n",
      "meaning": "20 years old, 20th year"
    },
    {
      "id": 3762,
      "kana": "はたらき",
      "kanji": "働き",
      "type": "n",
      "meaning": "work, labor"
    },
    {
      "id": 3763,
      "kana": "はたらく",
      "kanji": "働く",
      "type": "v5k",
      "meaning": "to work, to labor"
    },
    {
      "id": 3764,
      "kana": "はち",
      "kanji": "八",
      "type": "num",
      "meaning": "eight"
    },
    {
      "id": 3765,
      "kana": "はち",
      "kanji": "鉢",
      "type": "n",
      "meaning": "a bowl, a pot"
    },
    {
      "id": 3766,
      "kana": "~はつ",
      "kanji": "~発",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3767,
      "kana": "ばつ",
      "kanji": "×",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3768,
      "kana": "はつおん",
      "kanji": "発音",
      "type": "n,vs",
      "meaning": "pronunciation"
    },
    {
      "id": 3769,
      "kana": "はつか",
      "kanji": "二十日",
      "type": "n",
      "meaning": "twenty days, twentieth (day of the month)"
    },
    {
      "id": 3770,
      "kana": "はっき",
      "kanji": "発揮",
      "type": "n,vs",
      "meaning": "exhibition, demonstration, utilization, display"
    },
    {
      "id": 3771,
      "kana": "はっきり",
      "kanji": "",
      "type": "adv,n",
      "meaning": "clearly, plainly, distinctly"
    },
    {
      "id": 3772,
      "kana": "バック",
      "kanji": "",
      "type": "n",
      "meaning": "back"
    },
    {
      "id": 3773,
      "kana": "バッグ",
      "kanji": "",
      "type": "n",
      "meaning": "bag, bug"
    },
    {
      "id": 3774,
      "kana": "はっけん",
      "kanji": "発見",
      "type": "n,vs",
      "meaning": "discovery, detection, finding"
    },
    {
      "id": 3775,
      "kana": "はっこう",
      "kanji": "発行",
      "type": "n",
      "meaning": "issue (publications)"
    },
    {
      "id": 3776,
      "kana": "はっしゃ",
      "kanji": "発車",
      "type": "n",
      "meaning": "departure of a vehicle"
    },
    {
      "id": 3777,
      "kana": "はっしゃ",
      "kanji": "発射",
      "type": "n,vs",
      "meaning": "firing, shooting, discharge, catapult"
    },
    {
      "id": 3778,
      "kana": "ばっする",
      "kanji": "罰する",
      "type": "vs-s",
      "meaning": "to punish, to penalize"
    },
    {
      "id": 3779,
      "kana": "はっそう",
      "kanji": "発想",
      "type": "n",
      "meaning": "expression (music), conceptualization"
    },
    {
      "id": 3780,
      "kana": "はったつ",
      "kanji": "発達",
      "type": "n,vs",
      "meaning": "development, growth"
    },
    {
      "id": 3781,
      "kana": "ばったり",
      "kanji": "",
      "type": "adv",
      "meaning": "with a clash (thud), with a bang, plump, flop"
    },
    {
      "id": 3782,
      "kana": "はってん",
      "kanji": "発展",
      "type": "n,vs",
      "meaning": "development, growth"
    },
    {
      "id": 3783,
      "kana": "はつでん",
      "kanji": "発電",
      "type": "n,vs",
      "meaning": "generation (e.g. power)"
    },
    {
      "id": 3784,
      "kana": "はつばい",
      "kanji": "発売",
      "type": "n,vs",
      "meaning": "sale"
    },
    {
      "id": 3785,
      "kana": "はっぴょう",
      "kanji": "発表",
      "type": "n,vs",
      "meaning": "announcement, publication"
    },
    {
      "id": 3786,
      "kana": "はつめい",
      "kanji": "発明",
      "type": "n,vs",
      "meaning": "invention"
    },
    {
      "id": 3787,
      "kana": "はで",
      "kanji": "派手",
      "type": "adj-na,n",
      "meaning": "showy, loud, gay, flashy, gaudy"
    },
    {
      "id": 3788,
      "kana": "はな",
      "kanji": "花",
      "type": "n",
      "meaning": "flower"
    },
    {
      "id": 3789,
      "kana": "はな",
      "kanji": "鼻",
      "type": "n",
      "meaning": "nose"
    },
    {
      "id": 3790,
      "kana": "はなし",
      "kanji": "話",
      "type": "io",
      "meaning": "(n) talk, speech, chat, story, conversation"
    },
    {
      "id": 3791,
      "kana": "はなしあい",
      "kanji": "話合い",
      "type": "n",
      "meaning": "discussion, conference"
    },
    {
      "id": 3792,
      "kana": "はなしあう",
      "kanji": "話し合う",
      "type": "v5u",
      "meaning": "to discuss, to talk together"
    },
    {
      "id": 3793,
      "kana": "はなしかける",
      "kanji": "話し掛ける",
      "type": "v1",
      "meaning": "to accost a person, to talk (to someone)"
    },
    {
      "id": 3794,
      "kana": "はなしちゅう",
      "kanji": "話中",
      "type": "n",
      "meaning": "while talking, the line is busy"
    },
    {
      "id": 3795,
      "kana": "はなす",
      "kanji": "話す",
      "type": "v5s",
      "meaning": "to speak"
    },
    {
      "id": 3796,
      "kana": "はなす",
      "kanji": "離す",
      "type": "v5s",
      "meaning": "to part, divide, separate"
    },
    {
      "id": 3797,
      "kana": "はなす",
      "kanji": "放す",
      "type": "v5s",
      "meaning": "to separate, to set free"
    },
    {
      "id": 3798,
      "kana": "はなはだしい",
      "kanji": "甚だしい",
      "type": "adj",
      "meaning": "extreme, excessive, terrible"
    },
    {
      "id": 3799,
      "kana": "はなび",
      "kanji": "花火",
      "type": "n",
      "meaning": "fireworks"
    },
    {
      "id": 3800,
      "kana": "はなみ",
      "kanji": "花見",
      "type": "n,vs",
      "meaning": "cherry-blossom viewing, flower viewing"
    },
    {
      "id": 3801,
      "kana": "はなよめ",
      "kanji": "花嫁",
      "type": "n",
      "meaning": "bride"
    },
    {
      "id": 3802,
      "kana": "はなれる",
      "kanji": "離れる",
      "type": "v1",
      "meaning": "to be separated from, to leave, to go away, to be a long way off"
    },
    {
      "id": 3803,
      "kana": "はなれる",
      "kanji": "放れる",
      "type": "v1",
      "meaning": "to leave, to get free, to cut oneself off"
    },
    {
      "id": 3804,
      "kana": "はね",
      "kanji": "羽",
      "type": "n-suf",
      "meaning": "counter for birds, counter for rabbits"
    },
    {
      "id": 3805,
      "kana": "はね",
      "kanji": "羽根",
      "type": "n",
      "meaning": "shuttlecock"
    },
    {
      "id": 3806,
      "kana": "ばね",
      "kanji": "",
      "type": "n",
      "meaning": "spring (e.g. coil, leaf)"
    },
    {
      "id": 3807,
      "kana": "はねる",
      "kanji": "跳ねる",
      "type": "v1",
      "meaning": "to jump, to leap"
    },
    {
      "id": 3808,
      "kana": "はは",
      "kanji": "母",
      "type": "n",
      "meaning": "(hum) mother"
    },
    {
      "id": 3809,
      "kana": "はば",
      "kanji": "幅",
      "type": "n",
      "meaning": "width, breadth"
    },
    {
      "id": 3810,
      "kana": "ははおや",
      "kanji": "母親",
      "type": "n",
      "meaning": "mother"
    },
    {
      "id": 3811,
      "kana": "はぶく",
      "kanji": "省く",
      "type": "v5k",
      "meaning": "to omit, to eliminate, to curtail, to economize"
    },
    {
      "id": 3812,
      "kana": "はへん",
      "kanji": "破片",
      "type": "n",
      "meaning": "fragment, splinter, broken piece"
    },
    {
      "id": 3813,
      "kana": "はみがき",
      "kanji": "歯磨き",
      "type": "n",
      "meaning": "dentifrice, toothpaste"
    },
    {
      "id": 3814,
      "kana": "はめる",
      "kanji": "",
      "type": "v1",
      "meaning": "(col) to get in, to insert, to put on, to make love"
    },
    {
      "id": 3815,
      "kana": "ばめん",
      "kanji": "場面",
      "type": "n",
      "meaning": "scene, setting (e.g. of novel)"
    },
    {
      "id": 3816,
      "kana": "はやい",
      "kanji": "早い",
      "type": "adj",
      "meaning": "early"
    },
    {
      "id": 3817,
      "kana": "はやい",
      "kanji": "速い",
      "type": "adj",
      "meaning": "quick, fast, swift"
    },
    {
      "id": 3818,
      "kana": "はやくち",
      "kanji": "早口",
      "type": "n",
      "meaning": "fast-talking"
    },
    {
      "id": 3819,
      "kana": "はやし",
      "kanji": "林",
      "type": "n",
      "meaning": "woods, forest"
    },
    {
      "id": 3820,
      "kana": "はやる",
      "kanji": "流行る",
      "type": "v5r",
      "meaning": "to flourish, to thrive, to be popular, to come into fashion"
    },
    {
      "id": 3821,
      "kana": "はら",
      "kanji": "腹",
      "type": "n",
      "meaning": "abdomen, belly, stomach"
    },
    {
      "id": 3822,
      "kana": "はら",
      "kanji": "原",
      "type": "n",
      "meaning": "field, plain, prairie, tundra, moor, wilderness"
    },
    {
      "id": 3823,
      "kana": "はらいこむ",
      "kanji": "払い込む",
      "type": "v5m",
      "meaning": "to deposit, to pay in"
    },
    {
      "id": 3824,
      "kana": "はらいもどす",
      "kanji": "払い戻す",
      "type": "v5s",
      "meaning": "to repay, to pay back"
    },
    {
      "id": 3825,
      "kana": "はらう",
      "kanji": "払う",
      "type": "v5u",
      "meaning": "(1) to pay, (2) to brush, to wipe"
    },
    {
      "id": 3826,
      "kana": "バランス",
      "kanji": "",
      "type": "n",
      "meaning": "balance"
    },
    {
      "id": 3827,
      "kana": "はり",
      "kanji": "針",
      "type": "n",
      "meaning": "needle, hand (e.g. clock)"
    },
    {
      "id": 3828,
      "kana": "はりがね",
      "kanji": "針金",
      "type": "n",
      "meaning": "wire"
    },
    {
      "id": 3829,
      "kana": "はりきる",
      "kanji": "張り切る",
      "type": "v5r",
      "meaning": "to be in high spirits, to be full of vigor"
    },
    {
      "id": 3830,
      "kana": "はる",
      "kanji": "春",
      "type": "n-adv,n-t",
      "meaning": "spring"
    },
    {
      "id": 3831,
      "kana": "はる",
      "kanji": "貼る",
      "type": "v5r",
      "meaning": "to stick, to paste"
    },
    {
      "id": 3832,
      "kana": "はる",
      "kanji": "張る",
      "type": "v5r,vi,vt",
      "meaning": "to stick, to paste"
    },
    {
      "id": 3833,
      "kana": "はれ",
      "kanji": "晴れ",
      "type": "n",
      "meaning": "clear weather"
    },
    {
      "id": 3834,
      "kana": "はれる",
      "kanji": "晴れる",
      "type": "v1",
      "meaning": "to be sunny, to clear away, to stop raining"
    },
    {
      "id": 3835,
      "kana": "はん",
      "kanji": "半",
      "type": "n-adv,n,n-suf",
      "meaning": "half"
    },
    {
      "id": 3836,
      "kana": "はん~",
      "kanji": "反~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3837,
      "kana": "ばん",
      "kanji": "晩",
      "type": "n-adv,n-t",
      "meaning": "evening"
    },
    {
      "id": 3838,
      "kana": "ばん",
      "kanji": "番",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3839,
      "kana": "バン",
      "kanji": "",
      "type": "n",
      "meaning": "bun, van (caravan), VAN (value-added network)"
    },
    {
      "id": 3840,
      "kana": "はんい",
      "kanji": "範囲",
      "type": "n",
      "meaning": "extent, scope, sphere, range"
    },
    {
      "id": 3841,
      "kana": "はんえい",
      "kanji": "反映",
      "type": "n,vs",
      "meaning": "reflection, influence"
    },
    {
      "id": 3842,
      "kana": "ハンカチ",
      "kanji": "",
      "type": "n",
      "meaning": "handkerchief"
    },
    {
      "id": 3843,
      "kana": "ばんぐみ",
      "kanji": "番組",
      "type": "n",
      "meaning": "program (e.g. TV)"
    },
    {
      "id": 3844,
      "kana": "はんけい",
      "kanji": "半径",
      "type": "n",
      "meaning": "radius"
    },
    {
      "id": 3845,
      "kana": "はんこ",
      "kanji": "判子",
      "type": "n",
      "meaning": "seal (used for signature)"
    },
    {
      "id": 3846,
      "kana": "はんこう",
      "kanji": "反抗",
      "type": "n",
      "meaning": "opposition, resistance"
    },
    {
      "id": 3847,
      "kana": "ばんごう",
      "kanji": "番号",
      "type": "n",
      "meaning": "number, series of digits"
    },
    {
      "id": 3848,
      "kana": "はんざい",
      "kanji": "犯罪",
      "type": "n",
      "meaning": "crime"
    },
    {
      "id": 3849,
      "kana": "ばんざい",
      "kanji": "万歳",
      "type": "",
      "meaning": "hurrah, cheers"
    },
    {
      "id": 3850,
      "kana": "ハンサム",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "handsome"
    },
    {
      "id": 3851,
      "kana": "はんじ",
      "kanji": "判事",
      "type": "n",
      "meaning": "judge, judiciary"
    },
    {
      "id": 3852,
      "kana": "はんせい",
      "kanji": "反省",
      "type": "n,vs",
      "meaning": "reflection, reconsideration, introspection, meditation, contemplation"
    },
    {
      "id": 3853,
      "kana": "はんたい",
      "kanji": "反対",
      "type": "adj-na,n,vs",
      "meaning": "opposition, resistance"
    },
    {
      "id": 3854,
      "kana": "はんだん",
      "kanji": "判断",
      "type": "n,vs",
      "meaning": "judgement, decision"
    },
    {
      "id": 3855,
      "kana": "ばんち",
      "kanji": "番地",
      "type": "n",
      "meaning": "house number, address"
    },
    {
      "id": 3856,
      "kana": "パンツ",
      "kanji": "",
      "type": "n",
      "meaning": "underpants"
    },
    {
      "id": 3857,
      "kana": "バンド",
      "kanji": "",
      "type": "n",
      "meaning": "band"
    },
    {
      "id": 3858,
      "kana": "はんとう",
      "kanji": "半島",
      "type": "n",
      "meaning": "peninsula"
    },
    {
      "id": 3859,
      "kana": "ハンドバッグ",
      "kanji": "",
      "type": "n",
      "meaning": "handbag"
    },
    {
      "id": 3860,
      "kana": "ハンドル",
      "kanji": "",
      "type": "n",
      "meaning": "handle, steering wheel"
    },
    {
      "id": 3861,
      "kana": "はんにん",
      "kanji": "犯人",
      "type": "n",
      "meaning": "offender, criminal"
    },
    {
      "id": 3862,
      "kana": "はんばい",
      "kanji": "販売",
      "type": "n,vs",
      "meaning": "sale, selling, marketing"
    },
    {
      "id": 3863,
      "kana": "はんぶん",
      "kanji": "半分",
      "type": "",
      "meaning": "half minute"
    },
    {
      "id": 3864,
      "kana": "~ばんめ",
      "kanji": "~番目",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3865,
      "kana": "ひ",
      "kanji": "日",
      "type": "n-adv,n-t",
      "meaning": "sun, sunshine, day"
    },
    {
      "id": 3866,
      "kana": "ひ",
      "kanji": "火",
      "type": "n,n-suf",
      "meaning": "fire, flame, blaze"
    },
    {
      "id": 3867,
      "kana": "ひ",
      "kanji": "灯",
      "type": "n",
      "meaning": "light"
    },
    {
      "id": 3868,
      "kana": "ひ~",
      "kanji": "非~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3869,
      "kana": "~ひ",
      "kanji": "~費",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3870,
      "kana": "ひあたり",
      "kanji": "日当たり",
      "type": "n",
      "meaning": "exposure to the sun, sunny place"
    },
    {
      "id": 3871,
      "kana": "ピアノ",
      "kanji": "",
      "type": "n",
      "meaning": "piano"
    },
    {
      "id": 3872,
      "kana": "ビール",
      "kanji": "",
      "type": "n",
      "meaning": "beer"
    },
    {
      "id": 3873,
      "kana": "ひえる",
      "kanji": "冷える",
      "type": "v1,vi",
      "meaning": "to grow cold, to get chilly, to cool down"
    },
    {
      "id": 3874,
      "kana": "ひがい",
      "kanji": "被害",
      "type": "n",
      "meaning": "damage"
    },
    {
      "id": 3875,
      "kana": "ひがえり",
      "kanji": "日帰り",
      "type": "n",
      "meaning": "day trip"
    },
    {
      "id": 3876,
      "kana": "ひかく",
      "kanji": "比較",
      "type": "n,vs",
      "meaning": "comparison"
    },
    {
      "id": 3877,
      "kana": "ひかくてき",
      "kanji": "比較的",
      "type": "adv",
      "meaning": "comparatively, relatively"
    },
    {
      "id": 3878,
      "kana": "ひかげ",
      "kanji": "日陰",
      "type": "n",
      "meaning": "shadow"
    },
    {
      "id": 3879,
      "kana": "ひがし",
      "kanji": "東",
      "type": "n",
      "meaning": "east"
    },
    {
      "id": 3880,
      "kana": "ぴかぴか",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "glitter, sparkle"
    },
    {
      "id": 3881,
      "kana": "ひかり",
      "kanji": "光",
      "type": "n",
      "meaning": "light"
    },
    {
      "id": 3882,
      "kana": "ひかる",
      "kanji": "光る",
      "type": "v5r",
      "meaning": "to shine, to glitter, to be bright"
    },
    {
      "id": 3883,
      "kana": "~ひき",
      "kanji": "~匹",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3884,
      "kana": "ひきうける",
      "kanji": "引受る",
      "type": "v1",
      "meaning": "to undertake, to take up, to take over"
    },
    {
      "id": 3885,
      "kana": "ひきかえす",
      "kanji": "引返す",
      "type": "v5s",
      "meaning": "to repeat, to send back, to bring back"
    },
    {
      "id": 3886,
      "kana": "ひきざん",
      "kanji": "引算",
      "type": "n",
      "meaning": "subtraction"
    },
    {
      "id": 3887,
      "kana": "ひきだし",
      "kanji": "引き出し",
      "type": "n",
      "meaning": "drawer, drawing out"
    },
    {
      "id": 3888,
      "kana": "ひきだす",
      "kanji": "引出す",
      "type": "v5s",
      "meaning": "to pull out, to take out, to draw out, to withdraw"
    },
    {
      "id": 3889,
      "kana": "ひきとめる",
      "kanji": "引き止める",
      "type": "v1",
      "meaning": "to detain, to check, to restrain"
    },
    {
      "id": 3890,
      "kana": "ひきょう",
      "kanji": "卑怯",
      "type": "adj-na,n",
      "meaning": "cowardice, meanness, unfairness"
    },
    {
      "id": 3891,
      "kana": "ひきわけ",
      "kanji": "引分け",
      "type": "n",
      "meaning": "a draw (in competition), tie game"
    },
    {
      "id": 3892,
      "kana": "ひく",
      "kanji": "引く",
      "type": "v5k",
      "meaning": "minus, to pull, to play (string instr.)"
    },
    {
      "id": 3893,
      "kana": "ひく",
      "kanji": "弾く",
      "type": "v5k",
      "meaning": "to play (piano, guitar)"
    },
    {
      "id": 3894,
      "kana": "ひく",
      "kanji": "轢く",
      "type": "v5k",
      "meaning": "to run somebody over (with vehicle), to knock someone down"
    },
    {
      "id": 3895,
      "kana": "ひくい",
      "kanji": "低い",
      "type": "adj",
      "meaning": "short, low, humble, low (voice)"
    },
    {
      "id": 3896,
      "kana": "ピクニック",
      "kanji": "",
      "type": "n",
      "meaning": "picnic"
    },
    {
      "id": 3897,
      "kana": "ひげ",
      "kanji": "髭",
      "type": "n",
      "meaning": "moustache, beard, whiskers"
    },
    {
      "id": 3898,
      "kana": "ひげき",
      "kanji": "悲劇",
      "type": "n",
      "meaning": "tragedy"
    },
    {
      "id": 3899,
      "kana": "ひこう",
      "kanji": "飛行",
      "type": "n",
      "meaning": "aviation"
    },
    {
      "id": 3900,
      "kana": "ひこうじょう",
      "kanji": "飛行場",
      "type": "n",
      "meaning": "airport"
    },
    {
      "id": 3901,
      "kana": "ひざ",
      "kanji": "膝",
      "type": "n",
      "meaning": "knee, lap"
    },
    {
      "id": 3902,
      "kana": "ひざし",
      "kanji": "陽射",
      "type": "n",
      "meaning": "sunlight, rays of the sun"
    },
    {
      "id": 3903,
      "kana": "ひさしぶり",
      "kanji": "久しぶり",
      "type": "exp",
      "meaning": "after a long time"
    },
    {
      "id": 3904,
      "kana": "ひじ",
      "kanji": "肘",
      "type": "n",
      "meaning": "elbow"
    },
    {
      "id": 3905,
      "kana": "ひじょう",
      "kanji": "非常",
      "type": "adj-na,n",
      "meaning": "emergency, extraordinary, unusual"
    },
    {
      "id": 3906,
      "kana": "ひじょうに",
      "kanji": "非常に",
      "type": "adv",
      "meaning": "very, extremely, exceedingly"
    },
    {
      "id": 3907,
      "kana": "びじん",
      "kanji": "美人",
      "type": "n",
      "meaning": "beautiful person (woman)"
    },
    {
      "id": 3908,
      "kana": "ピストル",
      "kanji": "",
      "type": "n",
      "meaning": "pistol"
    },
    {
      "id": 3909,
      "kana": "ひたい",
      "kanji": "額",
      "type": "n",
      "meaning": "forehead, brow"
    },
    {
      "id": 3910,
      "kana": "ビタミン",
      "kanji": "",
      "type": "n",
      "meaning": "vitamin"
    },
    {
      "id": 3911,
      "kana": "ぴたり",
      "kanji": "",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3912,
      "kana": "ひだり",
      "kanji": "左",
      "type": "n",
      "meaning": "left hand side"
    },
    {
      "id": 3913,
      "kana": "ひっかかる",
      "kanji": "引っ掛かる",
      "type": "v5r",
      "meaning": "to be caught in, to be stuck in, to be cheated"
    },
    {
      "id": 3914,
      "kana": "ひっき",
      "kanji": "筆記",
      "type": "n,vs",
      "meaning": "(taking) notes, copying"
    },
    {
      "id": 3915,
      "kana": "びっくり",
      "kanji": "",
      "type": "adv,n,vs",
      "meaning": "be surprised, be amazed, be frightened, astonishment"
    },
    {
      "id": 3916,
      "kana": "ひっくりかえす",
      "kanji": "引っ繰り返す",
      "type": "v5s",
      "meaning": "to turn over, to overturn, to knock over, to upset, to turn inside out"
    },
    {
      "id": 3917,
      "kana": "ひっくりかえる",
      "kanji": "引っ繰り返る",
      "type": "v5r",
      "meaning": "to be overturned, to be upset, to topple over, to be reversed"
    },
    {
      "id": 3918,
      "kana": "ひづけ",
      "kanji": "日付",
      "type": "n",
      "meaning": "date, dating"
    },
    {
      "id": 3919,
      "kana": "ひっこし",
      "kanji": "引越し",
      "type": "n",
      "meaning": "moving (dwelling etc.), changing residence"
    },
    {
      "id": 3920,
      "kana": "ひっこす",
      "kanji": "引っ越す",
      "type": "v5s",
      "meaning": "to move, to change residence"
    },
    {
      "id": 3921,
      "kana": "ひっこむ",
      "kanji": "引っ込む",
      "type": "v5m",
      "meaning": "to draw back, to sink, to cave in"
    },
    {
      "id": 3922,
      "kana": "ひっし",
      "kanji": "必死",
      "type": "adj-na,adj-no,n",
      "meaning": "inevitable death, desperation, frantic, inevitable result"
    },
    {
      "id": 3923,
      "kana": "ひっしゃ",
      "kanji": "筆者",
      "type": "n",
      "meaning": "writer, author"
    },
    {
      "id": 3924,
      "kana": "ひつじゅひん",
      "kanji": "必需品",
      "type": "n",
      "meaning": "necessities, necessary article, requisite, essential"
    },
    {
      "id": 3925,
      "kana": "ぴったり",
      "kanji": "",
      "type": "adj-na,adv,n",
      "meaning": "exactly, neatly, sharp"
    },
    {
      "id": 3926,
      "kana": "ひっぱる",
      "kanji": "引っ張る",
      "type": "v5r",
      "meaning": "(1) to pull, to draw, to stretch, to drag, (2) to pull the ball (baseball)"
    },
    {
      "id": 3927,
      "kana": "ひつよう",
      "kanji": "必要",
      "type": "adj-na,n",
      "meaning": "necessary, essential, indispensable"
    },
    {
      "id": 3928,
      "kana": "ひてい",
      "kanji": "否定",
      "type": "n,vs",
      "meaning": "negation, denial, repudiation"
    },
    {
      "id": 3929,
      "kana": "ビデオ",
      "kanji": "",
      "type": "n",
      "meaning": "video"
    },
    {
      "id": 3930,
      "kana": "ひと",
      "kanji": "人",
      "type": "n",
      "meaning": "man, person"
    },
    {
      "id": 3931,
      "kana": "ひと~",
      "kanji": "一~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3932,
      "kana": "ひどい",
      "kanji": "",
      "type": "adj",
      "meaning": "cruel, awful"
    },
    {
      "id": 3933,
      "kana": "ひとこと",
      "kanji": "一言",
      "type": "n",
      "meaning": "single word"
    },
    {
      "id": 3934,
      "kana": "ひとごみ",
      "kanji": "人込み",
      "type": "n",
      "meaning": "crowd of people"
    },
    {
      "id": 3935,
      "kana": "ひとさしゆび",
      "kanji": "人差指",
      "type": "n",
      "meaning": "index finger"
    },
    {
      "id": 3936,
      "kana": "ひとしい",
      "kanji": "等しい",
      "type": "adj",
      "meaning": "equal"
    },
    {
      "id": 3937,
      "kana": "ひとつ",
      "kanji": "一つ",
      "type": "n",
      "meaning": "one"
    },
    {
      "id": 3938,
      "kana": "ひととおり",
      "kanji": "一通り",
      "type": "adj-no,n",
      "meaning": "ordinary, usual, in general, briefly"
    },
    {
      "id": 3939,
      "kana": "ひとどおり",
      "kanji": "人通り",
      "type": "n",
      "meaning": "pedestrian traffic"
    },
    {
      "id": 3940,
      "kana": "ひとまず",
      "kanji": "",
      "type": "adv",
      "meaning": "for the present, once, in outline"
    },
    {
      "id": 3941,
      "kana": "ひとみ",
      "kanji": "瞳",
      "type": "n",
      "meaning": "pupil (of eye)"
    },
    {
      "id": 3942,
      "kana": "ひとやすみ",
      "kanji": "一休み",
      "type": "n",
      "meaning": "a rest"
    },
    {
      "id": 3943,
      "kana": "ひとり",
      "kanji": "一人",
      "type": "n",
      "meaning": "one person"
    },
    {
      "id": 3944,
      "kana": "ひとり",
      "kanji": "独り",
      "type": "adv,n",
      "meaning": "alone, unmarried"
    },
    {
      "id": 3945,
      "kana": "ひとりごと",
      "kanji": "独り言",
      "type": "n",
      "meaning": "a soliloquy, a monologue, speaking to oneself"
    },
    {
      "id": 3946,
      "kana": "ひとりでに",
      "kanji": "",
      "type": "adv",
      "meaning": "by itself, automatically, naturally"
    },
    {
      "id": 3947,
      "kana": "ひとりひとり",
      "kanji": "一人一人",
      "type": "n-t",
      "meaning": "one by one, each, one at a time"
    },
    {
      "id": 3948,
      "kana": "ビニール",
      "kanji": "",
      "type": "n",
      "meaning": "vinyl"
    },
    {
      "id": 3949,
      "kana": "ひにく",
      "kanji": "皮肉",
      "type": "adj-na,n",
      "meaning": "cynicism, sarcasm"
    },
    {
      "id": 3950,
      "kana": "ひにち",
      "kanji": "日日",
      "type": "n-adv,n-t",
      "meaning": "every day, daily, day after day"
    },
    {
      "id": 3951,
      "kana": "ひねる",
      "kanji": "捻る",
      "type": "v5r",
      "meaning": "to turn (a switch) on or off, to twist, to puzzle over"
    },
    {
      "id": 3952,
      "kana": "ひのいり",
      "kanji": "日の入り",
      "type": "n",
      "meaning": "sunset"
    },
    {
      "id": 3953,
      "kana": "ひので",
      "kanji": "日の出",
      "type": "n",
      "meaning": "sunrise"
    },
    {
      "id": 3954,
      "kana": "ひはん",
      "kanji": "批判",
      "type": "n",
      "meaning": "criticism, judgement, comment"
    },
    {
      "id": 3955,
      "kana": "ひびき",
      "kanji": "響き",
      "type": "n",
      "meaning": "echo, sound, reverberation, noise"
    },
    {
      "id": 3956,
      "kana": "ひびく",
      "kanji": "響く",
      "type": "v5k",
      "meaning": "to resound"
    },
    {
      "id": 3957,
      "kana": "ひひょう",
      "kanji": "批評",
      "type": "n,vs",
      "meaning": "criticism, review, commentary"
    },
    {
      "id": 3958,
      "kana": "ひふ",
      "kanji": "皮膚",
      "type": "n",
      "meaning": "skin"
    },
    {
      "id": 3959,
      "kana": "ひま",
      "kanji": "暇",
      "type": "adj-na,n",
      "meaning": "(1) free time, leisure, leave, spare time, (2) farewell"
    },
    {
      "id": 3960,
      "kana": "ひみつ",
      "kanji": "秘密",
      "type": "adj-na,n",
      "meaning": "secret, secrecy"
    },
    {
      "id": 3961,
      "kana": "びみょう",
      "kanji": "微妙",
      "type": "adj-na,n",
      "meaning": "delicate, subtle"
    },
    {
      "id": 3962,
      "kana": "ひも",
      "kanji": "紐",
      "type": "n",
      "meaning": "(1) string, cord, (2) pimp"
    },
    {
      "id": 3963,
      "kana": "ひゃく",
      "kanji": "百",
      "type": "num",
      "meaning": "100, hundred"
    },
    {
      "id": 3964,
      "kana": "ひやす",
      "kanji": "冷やす",
      "type": "v5s,vt",
      "meaning": "to cool, to refrigerate"
    },
    {
      "id": 3965,
      "kana": "ひゃっかじてん",
      "kanji": "",
      "type": "n",
      "meaning": "encyclopedia"
    },
    {
      "id": 3966,
      "kana": "ひよう",
      "kanji": "費用",
      "type": "n",
      "meaning": "cost, expense"
    },
    {
      "id": 3967,
      "kana": "ひょう",
      "kanji": "表",
      "type": "n,n-suf",
      "meaning": "table (e.g. Tab 1), chart, list"
    },
    {
      "id": 3968,
      "kana": "びよう",
      "kanji": "美容",
      "type": "n",
      "meaning": "beauty of figure or form"
    },
    {
      "id": 3969,
      "kana": "びょう",
      "kanji": "秒",
      "type": "n",
      "meaning": "second (60th min)"
    },
    {
      "id": 3970,
      "kana": "~びょう",
      "kanji": "~病",
      "type": "",
      "meaning": ""
    },
    {
      "id": 3971,
      "kana": "びょういん",
      "kanji": "病院",
      "type": "n",
      "meaning": "hospital"
    },
    {
      "id": 3972,
      "kana": "ひょうか",
      "kanji": "評価",
      "type": "n,vs",
      "meaning": "valuation, estimation, assessment, evaluation"
    },
    {
      "id": 3973,
      "kana": "びょうき",
      "kanji": "病気",
      "type": "n",
      "meaning": "illness, disease, sickness"
    },
    {
      "id": 3974,
      "kana": "ひょうげん",
      "kanji": "表現",
      "type": "n,vs",
      "meaning": "expression, presentation, representation (math)"
    },
    {
      "id": 3975,
      "kana": "ひょうし",
      "kanji": "表紙",
      "type": "n",
      "meaning": "front cover, binding"
    },
    {
      "id": 3976,
      "kana": "ひょうしき",
      "kanji": "標識",
      "type": "n",
      "meaning": "sign, mark"
    },
    {
      "id": 3977,
      "kana": "ひょうじゅん",
      "kanji": "標準",
      "type": "n",
      "meaning": "standard, level"
    },
    {
      "id": 3978,
      "kana": "ひょうじょう",
      "kanji": "表情",
      "type": "n",
      "meaning": "facial expression"
    },
    {
      "id": 3979,
      "kana": "びょうどう",
      "kanji": "平等",
      "type": "adj-na,n",
      "meaning": "equality (a), impartiality, evenness"
    },
    {
      "id": 3980,
      "kana": "ひょうばん",
      "kanji": "評判",
      "type": "adj-no,n",
      "meaning": "fame, reputation, popularity, arrant"
    },
    {
      "id": 3981,
      "kana": "ひょうほん",
      "kanji": "標本",
      "type": "n",
      "meaning": "example, specimen"
    },
    {
      "id": 3982,
      "kana": "ひょうめん",
      "kanji": "表面",
      "type": "n",
      "meaning": "surface, outside, face, appearance"
    },
    {
      "id": 3983,
      "kana": "ひょうろん",
      "kanji": "評論",
      "type": "n",
      "meaning": "criticism, critique"
    },
    {
      "id": 3984,
      "kana": "ひらがな",
      "kanji": "平仮名",
      "type": "n",
      "meaning": "hiragana, 47 syllables, the cursive syllabary"
    },
    {
      "id": 3985,
      "kana": "ひらく",
      "kanji": "開く",
      "type": "v5k",
      "meaning": "to open (e.g. a festival)"
    },
    {
      "id": 3986,
      "kana": "ビル",
      "kanji": "",
      "type": "n",
      "meaning": "(abbr) building, bill"
    },
    {
      "id": 3987,
      "kana": "ひる",
      "kanji": "昼",
      "type": "n-adv,n-t",
      "meaning": "noon, daytime"
    },
    {
      "id": 3988,
      "kana": "ビルディング",
      "kanji": "",
      "type": "n",
      "meaning": "building"
    },
    {
      "id": 3989,
      "kana": "ひるね",
      "kanji": "昼寝",
      "type": "n,vs",
      "meaning": "nap (at home), siesta"
    },
    {
      "id": 3990,
      "kana": "ひるま",
      "kanji": "昼間",
      "type": "n-adv,n-t",
      "meaning": "daytime, during the day"
    },
    {
      "id": 3991,
      "kana": "ひろい",
      "kanji": "広い",
      "type": "adj",
      "meaning": "spacious, vast, wide"
    },
    {
      "id": 3992,
      "kana": "ひろう",
      "kanji": "拾う",
      "type": "v5u",
      "meaning": "to pick up, to find, to gather"
    },
    {
      "id": 3993,
      "kana": "ひろがる",
      "kanji": "広がる",
      "type": "v5r",
      "meaning": "to spread (out), to extend, to stretch, to reach to, to get around"
    },
    {
      "id": 3994,
      "kana": "ひろげる",
      "kanji": "広げる",
      "type": "v1",
      "meaning": "to spread, to extend, to expand, to enlarge"
    },
    {
      "id": 3995,
      "kana": "ひろさ",
      "kanji": "広さ",
      "type": "n",
      "meaning": "extent"
    },
    {
      "id": 3996,
      "kana": "ひろば",
      "kanji": "広場",
      "type": "n",
      "meaning": "plaza"
    },
    {
      "id": 3997,
      "kana": "ひろびろ",
      "kanji": "広々",
      "type": "adj-na,adv,n",
      "meaning": "extensive, spacious"
    },
    {
      "id": 3998,
      "kana": "ひろめる",
      "kanji": "広める",
      "type": "v1",
      "meaning": "to broaden, to propagate"
    },
    {
      "id": 3999,
      "kana": "ひん",
      "kanji": "品",
      "type": "n",
      "meaning": "thing, article, goods, dignity, article (goods), counter for meal courses"
    },
    {
      "id": 4000,
      "kana": "びん",
      "kanji": "瓶",
      "type": "n",
      "meaning": "bottle"
    },
    {
      "id": 4001,
      "kana": "びん",
      "kanji": "便",
      "type": "",
      "meaning": "way, means"
    },
    {
      "id": 4002,
      "kana": "ピン",
      "kanji": "",
      "type": "n",
      "meaning": "pin"
    },
    {
      "id": 4003,
      "kana": "ピンク",
      "kanji": "",
      "type": "adj-no,n",
      "meaning": "pink"
    },
    {
      "id": 4004,
      "kana": "びんせん",
      "kanji": "便箋",
      "type": "n",
      "meaning": "writing paper, stationery"
    },
    {
      "id": 4005,
      "kana": "びんづめ",
      "kanji": "瓶詰",
      "type": "adj-no,n,vs",
      "meaning": "bottling, bottled"
    },
    {
      "id": 4006,
      "kana": "ふ",
      "kanji": "不",
      "type": "",
      "meaning": "un, non, negative prefix"
    },
    {
      "id": 4007,
      "kana": "ぶ",
      "kanji": "不",
      "type": "",
      "meaning": "un, non, negative prefix"
    },
    {
      "id": 4008,
      "kana": "ぶ",
      "kanji": "無",
      "type": "n",
      "meaning": "nothing, naught, nil, zero"
    },
    {
      "id": 4009,
      "kana": "ぶ",
      "kanji": "分",
      "type": "n,n-suf,pref",
      "meaning": "dividing, part"
    },
    {
      "id": 4010,
      "kana": "~ぶ",
      "kanji": "~部",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4011,
      "kana": "ファスナー",
      "kanji": "",
      "type": "n",
      "meaning": "fastener, zipper"
    },
    {
      "id": 4012,
      "kana": "ふあん",
      "kanji": "不安",
      "type": "adj-na,n",
      "meaning": "anxiety, uneasiness, insecurity, suspense"
    },
    {
      "id": 4013,
      "kana": "フィルム",
      "kanji": "",
      "type": "n",
      "meaning": "film (roll of)"
    },
    {
      "id": 4014,
      "kana": "~ふう",
      "kanji": "~風",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4015,
      "kana": "ふうけい",
      "kanji": "風景",
      "type": "n",
      "meaning": "scenery"
    },
    {
      "id": 4016,
      "kana": "ふうせん",
      "kanji": "風船",
      "type": "n",
      "meaning": "balloon"
    },
    {
      "id": 4017,
      "kana": "ふうとう",
      "kanji": "封筒",
      "type": "n",
      "meaning": "envelope"
    },
    {
      "id": 4018,
      "kana": "ふうふ",
      "kanji": "夫婦",
      "type": "n",
      "meaning": "married couple, husband and wife"
    },
    {
      "id": 4019,
      "kana": "プール",
      "kanji": "",
      "type": "n",
      "meaning": "swimming pool"
    },
    {
      "id": 4020,
      "kana": "ふうん",
      "kanji": "不運",
      "type": "adj-na,n",
      "meaning": "unlucky, misfortune, bad luck, fate"
    },
    {
      "id": 4021,
      "kana": "ふえ",
      "kanji": "笛",
      "type": "n",
      "meaning": "flute, pipe"
    },
    {
      "id": 4022,
      "kana": "ふえる",
      "kanji": "増える",
      "type": "v1,vi",
      "meaning": "to increase, to multiply"
    },
    {
      "id": 4023,
      "kana": "ふえる",
      "kanji": "殖える",
      "type": "v1,vi",
      "meaning": "to increase, to multiply"
    },
    {
      "id": 4024,
      "kana": "フォーク",
      "kanji": "",
      "type": "n",
      "meaning": "folk, fork"
    },
    {
      "id": 4025,
      "kana": "ふか",
      "kanji": "不可",
      "type": "n,n-suf",
      "meaning": "wrong, bad, improper, unjustifiable, inadvisable"
    },
    {
      "id": 4026,
      "kana": "ふかい",
      "kanji": "深い",
      "type": "adj",
      "meaning": "deep, profound, thick, close"
    },
    {
      "id": 4027,
      "kana": "ふかまる",
      "kanji": "深まる",
      "type": "v5r,vi",
      "meaning": "to deepen, to heighten, to intensify"
    },
    {
      "id": 4028,
      "kana": "ぶき",
      "kanji": "武器",
      "type": "n",
      "meaning": "weapon, arms, ordinance"
    },
    {
      "id": 4029,
      "kana": "ふきそく",
      "kanji": "不規則",
      "type": "adj-na,n",
      "meaning": "irregularity, unsteadiness, disorderly"
    },
    {
      "id": 4030,
      "kana": "ふきゅう",
      "kanji": "普及",
      "type": "n,vs",
      "meaning": "diffusion, spread"
    },
    {
      "id": 4031,
      "kana": "ふきん",
      "kanji": "付近",
      "type": "n,n-suf",
      "meaning": "neighbourhood, vicinity, environs"
    },
    {
      "id": 4032,
      "kana": "ふく",
      "kanji": "吹く",
      "type": "v5k",
      "meaning": "(1) to blow (wind, etc), (2) to emit, to spout"
    },
    {
      "id": 4033,
      "kana": "ふく",
      "kanji": "拭く",
      "type": "v5k",
      "meaning": "to wipe, to dry"
    },
    {
      "id": 4034,
      "kana": "ふく",
      "kanji": "服",
      "type": "n,n-suf",
      "meaning": "clothes"
    },
    {
      "id": 4035,
      "kana": "ふく~",
      "kanji": "副~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4036,
      "kana": "ふくざつ",
      "kanji": "複雑",
      "type": "adj-na,n",
      "meaning": "complexity, complication"
    },
    {
      "id": 4037,
      "kana": "ふくし",
      "kanji": "副詞",
      "type": "n",
      "meaning": "adverb"
    },
    {
      "id": 4038,
      "kana": "ふくしゃ",
      "kanji": "複写",
      "type": "n",
      "meaning": "copy, duplicate"
    },
    {
      "id": 4039,
      "kana": "ふくしゅう",
      "kanji": "復習",
      "type": "n,vs",
      "meaning": "review, revision"
    },
    {
      "id": 4040,
      "kana": "ふくすう",
      "kanji": "複数",
      "type": "n",
      "meaning": "plural, multiple"
    },
    {
      "id": 4041,
      "kana": "ふくそう",
      "kanji": "服装",
      "type": "n",
      "meaning": "garments"
    },
    {
      "id": 4042,
      "kana": "ふくむ",
      "kanji": "含む",
      "type": "v5m",
      "meaning": "to hold in the mouth, to bear in mind"
    },
    {
      "id": 4043,
      "kana": "ふくめる",
      "kanji": "含める",
      "type": "v1,vt",
      "meaning": "to include, to instruct, to make one understand"
    },
    {
      "id": 4044,
      "kana": "ふくらます",
      "kanji": "膨らます",
      "type": "v5s",
      "meaning": "to swell, to expand, to inflate, to bulge"
    },
    {
      "id": 4045,
      "kana": "ふくらむ",
      "kanji": "膨らむ",
      "type": "v5m",
      "meaning": "to expand, to swell (out), to get big, to become inflated"
    },
    {
      "id": 4046,
      "kana": "ふくろ",
      "kanji": "袋",
      "type": "n",
      "meaning": "bag, sack"
    },
    {
      "id": 4047,
      "kana": "ふけつ",
      "kanji": "不潔",
      "type": "adj-na,n",
      "meaning": "unclean, dirty, filthy, impure"
    },
    {
      "id": 4048,
      "kana": "ふける",
      "kanji": "更ける",
      "type": "v1",
      "meaning": "to get late, to advance, to wear on"
    },
    {
      "id": 4049,
      "kana": "ふこう",
      "kanji": "不幸",
      "type": "adj-na,n",
      "meaning": "unhappiness, sorrow, misfortune, disaster, accident, death"
    },
    {
      "id": 4050,
      "kana": "ふごう",
      "kanji": "符号",
      "type": "n",
      "meaning": "sign, mark, symbol"
    },
    {
      "id": 4051,
      "kana": "ふさい",
      "kanji": "夫妻",
      "type": "n",
      "meaning": "man and wife, married couple"
    },
    {
      "id": 4052,
      "kana": "ふさがる",
      "kanji": "塞がる",
      "type": "v5r,vi",
      "meaning": "to be plugged up, to be shut up"
    },
    {
      "id": 4053,
      "kana": "ふさぐ",
      "kanji": "塞ぐ",
      "type": "v5g,vt",
      "meaning": "to stop up, to close up, to block (up)"
    },
    {
      "id": 4054,
      "kana": "ふざける",
      "kanji": "",
      "type": "v1",
      "meaning": "to romp, to gambol, to frolic, to joke"
    },
    {
      "id": 4055,
      "kana": "ぶさた",
      "kanji": "無沙汰",
      "type": "n",
      "meaning": "neglecting to stay in contact"
    },
    {
      "id": 4056,
      "kana": "ふし",
      "kanji": "節",
      "type": "n-suf",
      "meaning": "tune, tone, knot, knob, point"
    },
    {
      "id": 4057,
      "kana": "ぶし",
      "kanji": "武士",
      "type": "n",
      "meaning": "warrior, samurai"
    },
    {
      "id": 4058,
      "kana": "ぶじ",
      "kanji": "無事",
      "type": "adj-na,n",
      "meaning": "safety, peace, quietness"
    },
    {
      "id": 4059,
      "kana": "ふしぎ",
      "kanji": "不思議",
      "type": "adj-na,n",
      "meaning": "mystery, curiosity"
    },
    {
      "id": 4060,
      "kana": "ぶしゅ",
      "kanji": "部首",
      "type": "n",
      "meaning": "radical (of a kanji character)"
    },
    {
      "id": 4061,
      "kana": "ふじゆう",
      "kanji": "不自由",
      "type": "adj-na,n",
      "meaning": "discomfort, disability, inconvenience, destitution"
    },
    {
      "id": 4062,
      "kana": "ふじん",
      "kanji": "夫人",
      "type": "n",
      "meaning": "wife, Mrs, madam"
    },
    {
      "id": 4063,
      "kana": "ふじん",
      "kanji": "婦人",
      "type": "n",
      "meaning": "woman, female"
    },
    {
      "id": 4064,
      "kana": "ふすま",
      "kanji": "襖",
      "type": "n",
      "meaning": "sliding screen"
    },
    {
      "id": 4065,
      "kana": "ふせい",
      "kanji": "不正",
      "type": "adj-na,n",
      "meaning": "injustice, unfairness"
    },
    {
      "id": 4066,
      "kana": "ふせぐ",
      "kanji": "防ぐ",
      "type": "v5g,vt",
      "meaning": "to defend (against), to protect, to prevent"
    },
    {
      "id": 4067,
      "kana": "ふそく",
      "kanji": "不足",
      "type": "adj-na,n",
      "meaning": "insufficiency, shortage, deficiency, lack, dearth"
    },
    {
      "id": 4068,
      "kana": "ふぞく",
      "kanji": "附属",
      "type": "n,vs",
      "meaning": "attached, belonging, affiliated"
    },
    {
      "id": 4069,
      "kana": "ふた",
      "kanji": "蓋",
      "type": "n",
      "meaning": "cover, lid, cap"
    },
    {
      "id": 4070,
      "kana": "ぶたい",
      "kanji": "舞台",
      "type": "n",
      "meaning": "stage (theatre)"
    },
    {
      "id": 4071,
      "kana": "ふたご",
      "kanji": "双子",
      "type": "n",
      "meaning": "twins, a twin"
    },
    {
      "id": 4072,
      "kana": "ふたたび",
      "kanji": "再び",
      "type": "adv",
      "meaning": "again, once more, a second time"
    },
    {
      "id": 4073,
      "kana": "ふたつ",
      "kanji": "二つ",
      "type": "n",
      "meaning": "two"
    },
    {
      "id": 4074,
      "kana": "ふたり",
      "kanji": "二人",
      "type": "n",
      "meaning": "two persons, two people, pair, couple"
    },
    {
      "id": 4075,
      "kana": "ふだん",
      "kanji": "普段",
      "type": "adj-no,adj-na,n-adv,n",
      "meaning": "usually, habitually, ordinarily, always"
    },
    {
      "id": 4076,
      "kana": "ふち",
      "kanji": "縁",
      "type": "n",
      "meaning": "a means, e.g. of living"
    },
    {
      "id": 4077,
      "kana": "ぶつ",
      "kanji": "打つ",
      "type": "v5t",
      "meaning": "to hit, to strike"
    },
    {
      "id": 4078,
      "kana": "~ぶつ",
      "kanji": "~物",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4079,
      "kana": "ふつう",
      "kanji": "普通",
      "type": "adj-na,adj-no,adv,n",
      "meaning": "(1) generally, ordinarily, usually, (2) train that stops at every station"
    },
    {
      "id": 4080,
      "kana": "ふつう",
      "kanji": "不通",
      "type": "n",
      "meaning": "suspension, interruption, stoppage, tie-up, cessation"
    },
    {
      "id": 4081,
      "kana": "ふつか",
      "kanji": "二日",
      "type": "n",
      "meaning": "second day of the month, two days"
    },
    {
      "id": 4082,
      "kana": "ぶっか",
      "kanji": "物価",
      "type": "n",
      "meaning": "prices of commodities, prices (in general)"
    },
    {
      "id": 4083,
      "kana": "ぶつかる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to strike, to collide with"
    },
    {
      "id": 4084,
      "kana": "ぶつける",
      "kanji": "",
      "type": "v1",
      "meaning": "to knock, to run into, to nail on, to strike hard, to hit and attack"
    },
    {
      "id": 4085,
      "kana": "ぶっしつ",
      "kanji": "物質",
      "type": "n",
      "meaning": "material, substance"
    },
    {
      "id": 4086,
      "kana": "ぶっそう",
      "kanji": "物騒",
      "type": "adj-na,n",
      "meaning": "dangerous, disturbed, insecure"
    },
    {
      "id": 4087,
      "kana": "ぶつぶつ",
      "kanji": "",
      "type": "adv,n",
      "meaning": "grumbling, complaining in a small voice"
    },
    {
      "id": 4088,
      "kana": "ぶつり",
      "kanji": "物理",
      "type": "n",
      "meaning": "physics"
    },
    {
      "id": 4089,
      "kana": "ふで",
      "kanji": "筆",
      "type": "n",
      "meaning": "writing brush"
    },
    {
      "id": 4090,
      "kana": "（副）",
      "kanji": "ふと",
      "type": "adv",
      "meaning": "suddenly, casually, accidentally, incidentally, unexpectedly, unintentionally"
    },
    {
      "id": 4091,
      "kana": "ふとい",
      "kanji": "太い",
      "type": "adj",
      "meaning": "fat, thick"
    },
    {
      "id": 4092,
      "kana": "ふとる",
      "kanji": "太る",
      "type": "v5r",
      "meaning": "to grow fat (stout, plump), to become fat"
    },
    {
      "id": 4093,
      "kana": "ふとん",
      "kanji": "布団",
      "type": "n",
      "meaning": "bedding (Japanese style), futon"
    },
    {
      "id": 4094,
      "kana": "ふなびん",
      "kanji": "船便",
      "type": "n",
      "meaning": "surface mail (ship)"
    },
    {
      "id": 4095,
      "kana": "ふね",
      "kanji": "舟",
      "type": "n",
      "meaning": "ship, boat, watercraft, shipping, vessel, steamship"
    },
    {
      "id": 4096,
      "kana": "ふね",
      "kanji": "船",
      "type": "n",
      "meaning": "ship, boat, watercraft, shipping, vessel, steamship"
    },
    {
      "id": 4097,
      "kana": "ぶひん",
      "kanji": "部品",
      "type": "n",
      "meaning": "parts, accessories"
    },
    {
      "id": 4098,
      "kana": "ふぶき",
      "kanji": "吹雪",
      "type": "n",
      "meaning": "snow storm"
    },
    {
      "id": 4099,
      "kana": "ぶぶん",
      "kanji": "部分",
      "type": "n",
      "meaning": "portion, section, part"
    },
    {
      "id": 4100,
      "kana": "ふへい",
      "kanji": "不平",
      "type": "adj-na,n",
      "meaning": "complaint, discontent, dissatisfaction"
    },
    {
      "id": 4101,
      "kana": "ふべん",
      "kanji": "不便",
      "type": "adj-na,n",
      "meaning": "inconvenience, inexpediency, unhandiness"
    },
    {
      "id": 4102,
      "kana": "ふぼ",
      "kanji": "父母",
      "type": "n",
      "meaning": "father and mother, parents"
    },
    {
      "id": 4103,
      "kana": "ふまん",
      "kanji": "不満",
      "type": "adj-na,n",
      "meaning": "dissatisfaction, displeasure, discontent, complaints, unhappiness"
    },
    {
      "id": 4104,
      "kana": "ふみきり",
      "kanji": "踏切",
      "type": "n",
      "meaning": "railway crossing, level crossing, starting line, scratch, crossover"
    },
    {
      "id": 4105,
      "kana": "ふむ",
      "kanji": "踏む",
      "type": "v5m",
      "meaning": "to step on, to tread on"
    },
    {
      "id": 4106,
      "kana": "ふもと",
      "kanji": "麓",
      "type": "n",
      "meaning": "the foot, the bottom, the base (of a mountain)"
    },
    {
      "id": 4107,
      "kana": "ふやす",
      "kanji": "増やす",
      "type": "v5s,vt",
      "meaning": "to increase, to add to, to augment"
    },
    {
      "id": 4108,
      "kana": "ふやす",
      "kanji": "殖やす",
      "type": "v5s,vt",
      "meaning": "to increase, to add to, to augment"
    },
    {
      "id": 4109,
      "kana": "ふゆ",
      "kanji": "冬",
      "type": "n-adv,n-t",
      "meaning": "winter"
    },
    {
      "id": 4110,
      "kana": "フライパン",
      "kanji": "",
      "type": "n",
      "meaning": "fry pan, frying pan"
    },
    {
      "id": 4111,
      "kana": "ブラウス",
      "kanji": "",
      "type": "n",
      "meaning": "blouse"
    },
    {
      "id": 4112,
      "kana": "ぶらさげる",
      "kanji": "",
      "type": "v1",
      "meaning": "to hang, to suspend, to dangle, to swing"
    },
    {
      "id": 4113,
      "kana": "ブラシ",
      "kanji": "",
      "type": "n",
      "meaning": "brushy, brush"
    },
    {
      "id": 4114,
      "kana": "プラス",
      "kanji": "",
      "type": "n",
      "meaning": "plus"
    },
    {
      "id": 4115,
      "kana": "プラスチック",
      "kanji": "",
      "type": "n",
      "meaning": "plastic"
    },
    {
      "id": 4116,
      "kana": "プラットホーム",
      "kanji": "",
      "type": "n",
      "meaning": "platform"
    },
    {
      "id": 4117,
      "kana": "プラン",
      "kanji": "",
      "type": "n",
      "meaning": "plan"
    },
    {
      "id": 4118,
      "kana": "ふり",
      "kanji": "不利",
      "type": "adj-na,n",
      "meaning": "disadvantage, handicap, unfavorable, drawback"
    },
    {
      "id": 4119,
      "kana": "~ぶり",
      "kanji": "~振り",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4120,
      "kana": "フリー",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "free"
    },
    {
      "id": 4121,
      "kana": "ふりがな",
      "kanji": "振り仮名",
      "type": "hiragana over kanji",
      "meaning": ", pronunciation key"
    },
    {
      "id": 4122,
      "kana": "ふりむく",
      "kanji": "振り向く",
      "type": "v5k",
      "meaning": "to turn one's face, to turn around"
    },
    {
      "id": 4123,
      "kana": "プリント",
      "kanji": "",
      "type": "n",
      "meaning": "print, handout"
    },
    {
      "id": 4124,
      "kana": "ふる",
      "kanji": "降る",
      "type": "v5r",
      "meaning": "to precipitate, to fall (e.g. rain)"
    },
    {
      "id": 4125,
      "kana": "ふる",
      "kanji": "振る",
      "type": "v5r,vt",
      "meaning": "(1) to wave, to shake, to swing, (2) to sprinkle, (3) to cast (actor), to allocate (work)"
    },
    {
      "id": 4126,
      "kana": "ふる~",
      "kanji": "古~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4127,
      "kana": "ふるい",
      "kanji": "古い",
      "type": "adj",
      "meaning": "old (not person), aged, ancient, antiquated, stale, threadbare, outmoded, obsolete article"
    },
    {
      "id": 4128,
      "kana": "ふるえる",
      "kanji": "震える",
      "type": "v1",
      "meaning": "to shiver, to shake, to quake"
    },
    {
      "id": 4129,
      "kana": "ふるさと",
      "kanji": "故郷",
      "type": "n",
      "meaning": "home town, birthplace, old village, historic village, native place, one's old home"
    },
    {
      "id": 4130,
      "kana": "ふるさと",
      "kanji": "古里",
      "type": "n",
      "meaning": "home town, birthplace, old village, historic village, native place, one's old home"
    },
    {
      "id": 4131,
      "kana": "ふるまう",
      "kanji": "振舞う",
      "type": "v5u",
      "meaning": "to behave, to conduct oneself, to entertain (vt)"
    },
    {
      "id": 4132,
      "kana": "ブレーキ",
      "kanji": "",
      "type": "n",
      "meaning": "a brake"
    },
    {
      "id": 4133,
      "kana": "プレゼント",
      "kanji": "",
      "type": "n",
      "meaning": "present, gift"
    },
    {
      "id": 4134,
      "kana": "ふれる",
      "kanji": "触れる",
      "type": "v1",
      "meaning": "to touch, to be touched, to touch on a subject, to feel, to violate (law, copyright, etc.), to perceive, to be emotionally moved"
    },
    {
      "id": 4135,
      "kana": "ふろ",
      "kanji": "風呂",
      "type": "n",
      "meaning": "bath"
    },
    {
      "id": 4136,
      "kana": "プロ",
      "kanji": "",
      "type": "n",
      "meaning": "professional"
    },
    {
      "id": 4137,
      "kana": "ブローチ",
      "kanji": "",
      "type": "n",
      "meaning": "brooch"
    },
    {
      "id": 4138,
      "kana": "プログラム",
      "kanji": "",
      "type": "n",
      "meaning": "program"
    },
    {
      "id": 4139,
      "kana": "ふろしき",
      "kanji": "風呂敷",
      "type": "n",
      "meaning": "wrapping cloth, cloth wrapper"
    },
    {
      "id": 4140,
      "kana": "ふわふわ",
      "kanji": "",
      "type": "",
      "meaning": "light, soft"
    },
    {
      "id": 4141,
      "kana": "~ふん",
      "kanji": "~分",
      "type": "",
      "meaning": "~ minutes"
    },
    {
      "id": 4142,
      "kana": "ぶん",
      "kanji": "分",
      "type": "n,n-suf,pref",
      "meaning": "dividing, part, segment"
    },
    {
      "id": 4143,
      "kana": "ぶん",
      "kanji": "文",
      "type": "n",
      "meaning": "sentence"
    },
    {
      "id": 4144,
      "kana": "ふんいき",
      "kanji": "雰囲気",
      "type": "n",
      "meaning": "atmosphere (e.g. musical), mood, ambience"
    },
    {
      "id": 4145,
      "kana": "ふんか",
      "kanji": "噴火",
      "type": "n,vs",
      "meaning": "eruption"
    },
    {
      "id": 4146,
      "kana": "ぶんか",
      "kanji": "文化",
      "type": "n",
      "meaning": "culture, civilization"
    },
    {
      "id": 4147,
      "kana": "ぶんかい",
      "kanji": "分解",
      "type": "n",
      "meaning": "analysis, disassembly"
    },
    {
      "id": 4148,
      "kana": "ぶんがく",
      "kanji": "文学",
      "type": "n",
      "meaning": "literature"
    },
    {
      "id": 4149,
      "kana": "ぶんげい",
      "kanji": "文芸",
      "type": "n",
      "meaning": "literature, art and literature, belles-lettres"
    },
    {
      "id": 4150,
      "kana": "ぶんけん",
      "kanji": "文献",
      "type": "n",
      "meaning": "literature, books (reference)"
    },
    {
      "id": 4151,
      "kana": "ぶんしょう",
      "kanji": "文章",
      "type": "n",
      "meaning": "sentence, article"
    },
    {
      "id": 4152,
      "kana": "ふんすい",
      "kanji": "噴水",
      "type": "n",
      "meaning": "water fountain"
    },
    {
      "id": 4153,
      "kana": "ぶんすう",
      "kanji": "分数",
      "type": "n",
      "meaning": "fraction (in math)"
    },
    {
      "id": 4154,
      "kana": "ぶんせき",
      "kanji": "分析",
      "type": "n,vs",
      "meaning": "analysis"
    },
    {
      "id": 4155,
      "kana": "ぶんたい",
      "kanji": "文体",
      "type": "n",
      "meaning": "literary style"
    },
    {
      "id": 4156,
      "kana": "ぶんぷ",
      "kanji": "分布",
      "type": "n",
      "meaning": "distribution"
    },
    {
      "id": 4157,
      "kana": "ぶんぽう",
      "kanji": "文法",
      "type": "n",
      "meaning": "grammar"
    },
    {
      "id": 4158,
      "kana": "ぶんぼうぐ",
      "kanji": "文房具",
      "type": "n",
      "meaning": "stationery"
    },
    {
      "id": 4159,
      "kana": "ぶんみゃく",
      "kanji": "文脈",
      "type": "n",
      "meaning": "context"
    },
    {
      "id": 4160,
      "kana": "ぶんめい",
      "kanji": "文明",
      "type": "n",
      "meaning": "civilization, culture"
    },
    {
      "id": 4161,
      "kana": "ぶんや",
      "kanji": "分野",
      "type": "n",
      "meaning": "field, sphere, realm, division, branch"
    },
    {
      "id": 4162,
      "kana": "ぶんりょう",
      "kanji": "分量",
      "type": "n",
      "meaning": "amount, quantity"
    },
    {
      "id": 4163,
      "kana": "ぶんるい",
      "kanji": "分類",
      "type": "n,vs",
      "meaning": "classification"
    },
    {
      "id": 4164,
      "kana": "へい",
      "kanji": "塀",
      "type": "n",
      "meaning": "wall, fence"
    },
    {
      "id": 4165,
      "kana": "へいかい",
      "kanji": "閉会",
      "type": "n,vs",
      "meaning": "closure"
    },
    {
      "id": 4166,
      "kana": "へいき",
      "kanji": "平気",
      "type": "adj-na,n",
      "meaning": "coolness, calmness, composure, unconcern"
    },
    {
      "id": 4167,
      "kana": "へいきん",
      "kanji": "平均",
      "type": "n",
      "meaning": "equilibrium, balance, average, mean"
    },
    {
      "id": 4168,
      "kana": "へいこう",
      "kanji": "並行",
      "type": "adj-na,n,vs",
      "meaning": "(going) side by side, concurrent, abreast, at the same time"
    },
    {
      "id": 4169,
      "kana": "へいじつ",
      "kanji": "平日",
      "type": "n-adv,n",
      "meaning": "weekday, ordinary days"
    },
    {
      "id": 4170,
      "kana": "へいたい",
      "kanji": "兵隊",
      "type": "n",
      "meaning": "soldier, sailor"
    },
    {
      "id": 4171,
      "kana": "へいぼん",
      "kanji": "平凡",
      "type": "adj-na,n",
      "meaning": "common, commonplace, ordinary, mediocre"
    },
    {
      "id": 4172,
      "kana": "へいや",
      "kanji": "平野",
      "type": "n",
      "meaning": "plain, open field"
    },
    {
      "id": 4173,
      "kana": "へいわ",
      "kanji": "平和",
      "type": "adj-na,n",
      "meaning": "peace, harmony"
    },
    {
      "id": 4174,
      "kana": "ページ",
      "kanji": "",
      "type": "n",
      "meaning": "page"
    },
    {
      "id": 4175,
      "kana": "へこむ",
      "kanji": "凹む",
      "type": "v5m",
      "meaning": "to be dented, to be indented, to yield to, to give, to sink, to collapse, to cave in, to be snubbed"
    },
    {
      "id": 4176,
      "kana": "へそ",
      "kanji": "",
      "type": "n",
      "meaning": "navel, belly-button"
    },
    {
      "id": 4177,
      "kana": "へた",
      "kanji": "下手",
      "type": "adj-na,n",
      "meaning": "unskillful, poor, awkward"
    },
    {
      "id": 4178,
      "kana": "へだてる",
      "kanji": "隔てる",
      "type": "v1",
      "meaning": "to be shut out"
    },
    {
      "id": 4179,
      "kana": "べつ",
      "kanji": "別",
      "type": "adj-na,n,n-suf",
      "meaning": "distinction, difference, different, another, particular, separate, extra, exception"
    },
    {
      "id": 4180,
      "kana": "べっそう",
      "kanji": "別荘",
      "type": "n",
      "meaning": "holiday house, villa"
    },
    {
      "id": 4181,
      "kana": "ベッド",
      "kanji": "",
      "type": "n",
      "meaning": "bed"
    },
    {
      "id": 4182,
      "kana": "べつに",
      "kanji": "別に",
      "type": "adv",
      "meaning": "(not) particularly, nothing"
    },
    {
      "id": 4183,
      "kana": "べつべつ",
      "kanji": "別々",
      "type": "adj-na,n",
      "meaning": "separately, individually"
    },
    {
      "id": 4184,
      "kana": "ベテラン",
      "kanji": "",
      "type": "n",
      "meaning": "veteran"
    },
    {
      "id": 4185,
      "kana": "へや",
      "kanji": "部屋",
      "type": "n",
      "meaning": "room"
    },
    {
      "id": 4186,
      "kana": "へらす",
      "kanji": "減らす",
      "type": "v5s,vt",
      "meaning": "to abate, to decrease, to diminish, to shorten"
    },
    {
      "id": 4187,
      "kana": "ヘリコプター",
      "kanji": "",
      "type": "n",
      "meaning": "helicopter"
    },
    {
      "id": 4188,
      "kana": "へる",
      "kanji": "減る",
      "type": "v5r,vi",
      "meaning": "to decrease (in size or number), to diminish, to abate"
    },
    {
      "id": 4189,
      "kana": "へる",
      "kanji": "",
      "type": "v5r,vi",
      "meaning": "to decrease (in size or number), to diminish, to abate"
    },
    {
      "id": 4190,
      "kana": "ベル",
      "kanji": "",
      "type": "n",
      "meaning": "bell (BEL)"
    },
    {
      "id": 4191,
      "kana": "ベルト",
      "kanji": "",
      "type": "n",
      "meaning": "Belt for western clothes"
    },
    {
      "id": 4192,
      "kana": "へん",
      "kanji": "変",
      "type": "adj-na,n",
      "meaning": "change, incident, disturbance, strange"
    },
    {
      "id": 4193,
      "kana": "へん",
      "kanji": "辺",
      "type": "n",
      "meaning": "area, vicinity"
    },
    {
      "id": 4194,
      "kana": "~へん",
      "kanji": "~編",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4195,
      "kana": "~へん",
      "kanji": "~遍",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4196,
      "kana": "ぺん",
      "kanji": "",
      "type": "",
      "meaning": "pen"
    },
    {
      "id": 4197,
      "kana": "~べん",
      "kanji": "~弁",
      "type": "n",
      "meaning": "speech, dialect"
    },
    {
      "id": 4198,
      "kana": "へんか",
      "kanji": "変化",
      "type": "n,p-suru",
      "meaning": "change, variation, alteration"
    },
    {
      "id": 4199,
      "kana": "ペンキ",
      "kanji": "",
      "type": "n",
      "meaning": "paint"
    },
    {
      "id": 4200,
      "kana": "べんきょう",
      "kanji": "勉強",
      "type": "n,vs",
      "meaning": "study, diligence, discount, reduction"
    },
    {
      "id": 4201,
      "kana": "へんこう",
      "kanji": "変更",
      "type": "n,vs",
      "meaning": "change, modification, alteration"
    },
    {
      "id": 4202,
      "kana": "へんじ",
      "kanji": "返事",
      "type": "n,vs",
      "meaning": "reply, answer"
    },
    {
      "id": 4203,
      "kana": "へんしゅう",
      "kanji": "編集",
      "type": "n,vs",
      "meaning": "editing, compilation, editorial (e.g. committee)"
    },
    {
      "id": 4204,
      "kana": "べんじょ",
      "kanji": "便所",
      "type": "n",
      "meaning": "toilet, lavatory, rest room, latrine, comfort station"
    },
    {
      "id": 4205,
      "kana": "ペンチ",
      "kanji": "",
      "type": "n",
      "meaning": "(abbr) pliers (lit: pinchers)"
    },
    {
      "id": 4206,
      "kana": "ベンチ",
      "kanji": "",
      "type": "n",
      "meaning": "bench"
    },
    {
      "id": 4207,
      "kana": "べんとう",
      "kanji": "弁当",
      "type": "n",
      "meaning": "box lunch"
    },
    {
      "id": 4208,
      "kana": "べんり",
      "kanji": "便利",
      "type": "adj-na,n",
      "meaning": "convenient, handy, useful"
    },
    {
      "id": 4209,
      "kana": "~ほ",
      "kanji": "~歩",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4210,
      "kana": "~ぽい",
      "kanji": "",
      "type": "",
      "meaning": "~ish"
    },
    {
      "id": 4211,
      "kana": "ほう",
      "kanji": "方",
      "type": "n-adv,n",
      "meaning": "side"
    },
    {
      "id": 4212,
      "kana": "ほう",
      "kanji": "法",
      "type": "n,n-suf",
      "meaning": "Act (law: the X Act)"
    },
    {
      "id": 4213,
      "kana": "（比較）",
      "kanji": "~ほう",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4214,
      "kana": "ぼう",
      "kanji": "棒",
      "type": "n",
      "meaning": "pole, rod, stick"
    },
    {
      "id": 4215,
      "kana": "ぼう~",
      "kanji": "防~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4216,
      "kana": "ぼうえき",
      "kanji": "貿易",
      "type": "n",
      "meaning": "trade (foreign)"
    },
    {
      "id": 4217,
      "kana": "ぼうえんきょう",
      "kanji": "望遠鏡",
      "type": "n",
      "meaning": "telescope"
    },
    {
      "id": 4218,
      "kana": "ほうがく",
      "kanji": "方角",
      "type": "n",
      "meaning": "direction, way, compass point"
    },
    {
      "id": 4219,
      "kana": "ほうき",
      "kanji": "箒",
      "type": "oK",
      "meaning": "(n) broom"
    },
    {
      "id": 4220,
      "kana": "ほうげん",
      "kanji": "方言",
      "type": "n",
      "meaning": "dialect"
    },
    {
      "id": 4221,
      "kana": "ぼうけん",
      "kanji": "冒険",
      "type": "n",
      "meaning": "risk, venture, adventure"
    },
    {
      "id": 4222,
      "kana": "ほうこう",
      "kanji": "方向",
      "type": "n",
      "meaning": "direction, course, way"
    },
    {
      "id": 4223,
      "kana": "ほうこく",
      "kanji": "報告",
      "type": "n,vs",
      "meaning": "report, information"
    },
    {
      "id": 4224,
      "kana": "ぼうさん",
      "kanji": "坊さん",
      "type": "n",
      "meaning": "Buddhist priest, monk"
    },
    {
      "id": 4225,
      "kana": "ぼうし",
      "kanji": "帽子",
      "type": "n",
      "meaning": "hat"
    },
    {
      "id": 4226,
      "kana": "ぼうし",
      "kanji": "防止",
      "type": "n",
      "meaning": "prevention, check"
    },
    {
      "id": 4227,
      "kana": "ほうしん",
      "kanji": "方針",
      "type": "n",
      "meaning": "objective, plan, policy"
    },
    {
      "id": 4228,
      "kana": "ほうせき",
      "kanji": "宝石",
      "type": "n",
      "meaning": "gem, jewel"
    },
    {
      "id": 4229,
      "kana": "ほうそう",
      "kanji": "放送",
      "type": "n,vs",
      "meaning": "broadcast, broadcasting"
    },
    {
      "id": 4230,
      "kana": "ほうそう",
      "kanji": "包装",
      "type": "n,vs",
      "meaning": "packing, wrapping"
    },
    {
      "id": 4231,
      "kana": "ほうそく",
      "kanji": "法則",
      "type": "n",
      "meaning": "law, rule"
    },
    {
      "id": 4232,
      "kana": "ほうたい",
      "kanji": "包帯",
      "type": "n,vs",
      "meaning": "bandage, dressing"
    },
    {
      "id": 4233,
      "kana": "ぼうだい",
      "kanji": "膨大",
      "type": "adj-na,n",
      "meaning": "huge, bulky, enormous, extensive, swelling, expansion"
    },
    {
      "id": 4234,
      "kana": "ほうちょう",
      "kanji": "庖丁",
      "type": "n",
      "meaning": "kitchen knife, carving knife"
    },
    {
      "id": 4235,
      "kana": "ほうていしき",
      "kanji": "方程式",
      "type": "n",
      "meaning": "equation"
    },
    {
      "id": 4236,
      "kana": "ぼうはん",
      "kanji": "防犯",
      "type": "n",
      "meaning": "prevention of crime"
    },
    {
      "id": 4237,
      "kana": "ほうふ",
      "kanji": "豊富",
      "type": "adj-na,n",
      "meaning": "abundance, wealth, plenty, bounty"
    },
    {
      "id": 4238,
      "kana": "ほうほう",
      "kanji": "方法",
      "type": "n",
      "meaning": "method, manner, way, means, technique"
    },
    {
      "id": 4239,
      "kana": "ほうぼう",
      "kanji": "方々",
      "type": "adv,conj,n-adv,n-t",
      "meaning": "persons, this and that, here and there, everywhere"
    },
    {
      "id": 4240,
      "kana": "ほうめん",
      "kanji": "方面",
      "type": "n",
      "meaning": "direction, district, field (e.g., of study)"
    },
    {
      "id": 4241,
      "kana": "ほうもん",
      "kanji": "訪問",
      "type": "n,vs",
      "meaning": "call, visit"
    },
    {
      "id": 4242,
      "kana": "ぼうや",
      "kanji": "坊や",
      "type": "n",
      "meaning": "boy"
    },
    {
      "id": 4243,
      "kana": "ほうりつ",
      "kanji": "法律",
      "type": "n",
      "meaning": "law"
    },
    {
      "id": 4244,
      "kana": "ほうる",
      "kanji": "放る",
      "type": "v5r",
      "meaning": "to let go"
    },
    {
      "id": 4245,
      "kana": "ほえる",
      "kanji": "吠える",
      "type": "v1",
      "meaning": "to bark, to bay, to howl, to bellow, to roar, to cry"
    },
    {
      "id": 4246,
      "kana": "ボーイ",
      "kanji": "",
      "type": "n",
      "meaning": "boy"
    },
    {
      "id": 4247,
      "kana": "ボート",
      "kanji": "",
      "type": "n",
      "meaning": "rowing boat"
    },
    {
      "id": 4248,
      "kana": "ボーナス",
      "kanji": "",
      "type": "n",
      "meaning": "bonus"
    },
    {
      "id": 4249,
      "kana": "ホーム",
      "kanji": "",
      "type": "n",
      "meaning": "(1) platform, (2) home"
    },
    {
      "id": 4250,
      "kana": "ボール",
      "kanji": "",
      "type": "n",
      "meaning": "ball, bowl"
    },
    {
      "id": 4251,
      "kana": "ボールペン",
      "kanji": "",
      "type": "n",
      "meaning": "ball-point pen"
    },
    {
      "id": 4252,
      "kana": "ほか",
      "kanji": "他",
      "type": "n-adv,n",
      "meaning": "other (esp. places and things)"
    },
    {
      "id": 4253,
      "kana": "ほか",
      "kanji": "外",
      "type": "n",
      "meaning": "other place, the rest"
    },
    {
      "id": 4254,
      "kana": "ほがらか",
      "kanji": "朗らか",
      "type": "adj-na,n",
      "meaning": "brightness, cheerfulness, melodious"
    },
    {
      "id": 4255,
      "kana": "ぼく",
      "kanji": "僕",
      "type": "n",
      "meaning": "(male) I, manservant"
    },
    {
      "id": 4256,
      "kana": "ぼくじょう",
      "kanji": "牧場",
      "type": "n",
      "meaning": "(1) farm (livestock), (2) pasture land, meadow, grazing land"
    },
    {
      "id": 4257,
      "kana": "ぼくちく",
      "kanji": "牧畜",
      "type": "n",
      "meaning": "stock-farming"
    },
    {
      "id": 4258,
      "kana": "ポケット",
      "kanji": "",
      "type": "n",
      "meaning": "pocket"
    },
    {
      "id": 4259,
      "kana": "ほけん",
      "kanji": "保健",
      "type": "n",
      "meaning": "health preservation, hygiene, sanitation"
    },
    {
      "id": 4260,
      "kana": "ほこり",
      "kanji": "誇り",
      "type": "n",
      "meaning": "pride"
    },
    {
      "id": 4261,
      "kana": "ほこり",
      "kanji": "埃",
      "type": "n",
      "meaning": "dust"
    },
    {
      "id": 4262,
      "kana": "ほし",
      "kanji": "星",
      "type": "n",
      "meaning": "star"
    },
    {
      "id": 4263,
      "kana": "ほしい",
      "kanji": "欲しい",
      "type": "adj",
      "meaning": "wanted, wished for, in need of, desired"
    },
    {
      "id": 4264,
      "kana": "ぼしゅう",
      "kanji": "募集",
      "type": "n,vs",
      "meaning": "recruiting, taking applications"
    },
    {
      "id": 4265,
      "kana": "ほしょう",
      "kanji": "保証",
      "type": "n,vs",
      "meaning": "guarantee, security, assurance, pledge, warranty"
    },
    {
      "id": 4266,
      "kana": "ほす",
      "kanji": "干す",
      "type": "v5s,vt",
      "meaning": "to air, to dry, to desiccate, to drain (off), to drink up"
    },
    {
      "id": 4267,
      "kana": "ポスター",
      "kanji": "",
      "type": "n",
      "meaning": "poster"
    },
    {
      "id": 4268,
      "kana": "ポスト",
      "kanji": "",
      "type": "n",
      "meaning": "post, post-, mail box"
    },
    {
      "id": 4269,
      "kana": "ほそい",
      "kanji": "細い",
      "type": "adj",
      "meaning": "thin, slender, fine"
    },
    {
      "id": 4270,
      "kana": "ほぞん",
      "kanji": "保存",
      "type": "n,vs",
      "meaning": "preservation, conservation, storage, maintenance"
    },
    {
      "id": 4271,
      "kana": "ボタン",
      "kanji": "",
      "type": "pt:",
      "meaning": "(n) button (pt: bota~o)"
    },
    {
      "id": 4272,
      "kana": "ほっきょく",
      "kanji": "北極",
      "type": "n",
      "meaning": "North Pole"
    },
    {
      "id": 4273,
      "kana": "ぼっちゃん",
      "kanji": "坊っちゃん",
      "type": "n",
      "meaning": "son (of others)"
    },
    {
      "id": 4274,
      "kana": "ホテル",
      "kanji": "",
      "type": "n",
      "meaning": "hotel"
    },
    {
      "id": 4275,
      "kana": "ほど",
      "kanji": "程",
      "type": "n-adv,n",
      "meaning": "degree, extent, bounds, limit"
    },
    {
      "id": 4276,
      "kana": "ほどう",
      "kanji": "歩道",
      "type": "n",
      "meaning": "footpath, walkway, sidewalk"
    },
    {
      "id": 4277,
      "kana": "ほどく",
      "kanji": "",
      "type": "v5k",
      "meaning": "to unfasten"
    },
    {
      "id": 4278,
      "kana": "ほとけ",
      "kanji": "仏",
      "type": "n",
      "meaning": "Buddha, merciful person, Buddhist image, the dead"
    },
    {
      "id": 4279,
      "kana": "ほとんど",
      "kanji": "",
      "type": "n-adv,n-t",
      "meaning": "mostly, almost"
    },
    {
      "id": 4280,
      "kana": "ほね",
      "kanji": "骨",
      "type": "adj-na,n",
      "meaning": "bone"
    },
    {
      "id": 4281,
      "kana": "ほのお",
      "kanji": "炎",
      "type": "n",
      "meaning": "flame"
    },
    {
      "id": 4282,
      "kana": "ほほ",
      "kanji": "頬",
      "type": "n",
      "meaning": "cheek (of face)"
    },
    {
      "id": 4283,
      "kana": "ほお",
      "kanji": "頬",
      "type": "n",
      "meaning": "cheek (of face)"
    },
    {
      "id": 4284,
      "kana": "ほぼ",
      "kanji": "",
      "type": "adv",
      "meaning": "almost, roughly, approximately"
    },
    {
      "id": 4285,
      "kana": "ほほえむ",
      "kanji": "微笑む",
      "type": "v5m",
      "meaning": "to smile"
    },
    {
      "id": 4286,
      "kana": "ほめる",
      "kanji": "褒める",
      "type": "v1",
      "meaning": "to praise, to admire, to speak well"
    },
    {
      "id": 4287,
      "kana": "ほり",
      "kanji": "堀",
      "type": "n",
      "meaning": "moat, canal"
    },
    {
      "id": 4288,
      "kana": "ほり",
      "kanji": "濠",
      "type": "n",
      "meaning": "moat"
    },
    {
      "id": 4289,
      "kana": "ほる",
      "kanji": "掘る",
      "type": "v5r",
      "meaning": "to dig, to excavate"
    },
    {
      "id": 4290,
      "kana": "ほる",
      "kanji": "彫る",
      "type": "v5r",
      "meaning": "to carve, to engrave, to sculpture, to chisel"
    },
    {
      "id": 4291,
      "kana": "ぼろ",
      "kanji": "",
      "type": "n,pref",
      "meaning": "rag, scrap, tattered clothes, fault (esp. in a pretense)"
    },
    {
      "id": 4292,
      "kana": "ほん",
      "kanji": "本",
      "type": "n,n-suf,n-t",
      "meaning": "(1) origin, original"
    },
    {
      "id": 4293,
      "kana": "ほん~",
      "kanji": "本~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4294,
      "kana": "~ほん",
      "kanji": "~本",
      "type": "",
      "meaning": "counter for cylindrical things"
    },
    {
      "id": 4295,
      "kana": "ぼん",
      "kanji": "盆",
      "type": "n",
      "meaning": "Lantern Festival, Festival of the Dead, tray"
    },
    {
      "id": 4296,
      "kana": "ぼんち",
      "kanji": "盆地",
      "type": "n",
      "meaning": "basin (e.g. between mountains)"
    },
    {
      "id": 4297,
      "kana": "ほんと",
      "kanji": "本当",
      "type": "adj-na,n",
      "meaning": "truth, reality"
    },
    {
      "id": 4298,
      "kana": "ほんとう",
      "kanji": "本当",
      "type": "adj-na,n",
      "meaning": "truth, reality"
    },
    {
      "id": 4299,
      "kana": "ほんにん",
      "kanji": "本人",
      "type": "n",
      "meaning": "the person himself"
    },
    {
      "id": 4300,
      "kana": "ほんの~",
      "kanji": "",
      "type": "",
      "meaning": "original ~"
    },
    {
      "id": 4301,
      "kana": "ほんぶ",
      "kanji": "本部",
      "type": "n",
      "meaning": "headquarters"
    },
    {
      "id": 4302,
      "kana": "ほんもの",
      "kanji": "本物",
      "type": "n",
      "meaning": "genuine article"
    },
    {
      "id": 4303,
      "kana": "ほんやく",
      "kanji": "翻訳",
      "type": "n,vs",
      "meaning": "translation, de-encryption, deciphering"
    },
    {
      "id": 4304,
      "kana": "ぼんやり",
      "kanji": "",
      "type": "adv,n,vs",
      "meaning": "absent-minded, block-head, dim, faint, vague"
    },
    {
      "id": 4305,
      "kana": "ほんらい",
      "kanji": "本来",
      "type": "n-adv,n-t,adj-no",
      "meaning": "essentially, naturally, by nature"
    },
    {
      "id": 4306,
      "kana": "ま",
      "kanji": "間",
      "type": "n",
      "meaning": "space, room, time, pause"
    },
    {
      "id": 4307,
      "kana": "まあ",
      "kanji": "",
      "type": "adv,conj,int,n",
      "meaning": "(female) you might say"
    },
    {
      "id": 4308,
      "kana": "マーケット",
      "kanji": "",
      "type": "n",
      "meaning": "market"
    },
    {
      "id": 4309,
      "kana": "まあまあ",
      "kanji": "",
      "type": "adj-na,adv,int",
      "meaning": "so-so"
    },
    {
      "id": 4310,
      "kana": "まい~",
      "kanji": "毎~",
      "type": "",
      "meaning": "every ~"
    },
    {
      "id": 4311,
      "kana": "マイク",
      "kanji": "",
      "type": "n",
      "meaning": "mike"
    },
    {
      "id": 4312,
      "kana": "まいご",
      "kanji": "迷子",
      "type": "n",
      "meaning": "lost (stray) child"
    },
    {
      "id": 4313,
      "kana": "まいすう",
      "kanji": "枚数",
      "type": "n",
      "meaning": "the number of flat things"
    },
    {
      "id": 4314,
      "kana": "まいど",
      "kanji": "毎度",
      "type": "n-adv,n-t",
      "meaning": "each time, common service-sector greeting"
    },
    {
      "id": 4315,
      "kana": "マイナス",
      "kanji": "",
      "type": "n",
      "meaning": "minus"
    },
    {
      "id": 4316,
      "kana": "まいる",
      "kanji": "参る",
      "type": "v5r",
      "meaning": "(1) (hum) to go, to come"
    },
    {
      "id": 4317,
      "kana": "まえ",
      "kanji": "前",
      "type": "n-adv,n-t,suf",
      "meaning": "(1) before, previously"
    },
    {
      "id": 4318,
      "kana": "~まえ",
      "kanji": "~前",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4319,
      "kana": "まかせる",
      "kanji": "任せる",
      "type": "v1",
      "meaning": "to entrust to another, to leave to"
    },
    {
      "id": 4320,
      "kana": "まがる",
      "kanji": "曲る",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4321,
      "kana": "まく",
      "kanji": "巻く",
      "type": "v5k",
      "meaning": "to wind, to coil, to roll"
    },
    {
      "id": 4322,
      "kana": "まく",
      "kanji": "蒔く",
      "type": "v5k",
      "meaning": "to sow (seeds)"
    },
    {
      "id": 4323,
      "kana": "まく",
      "kanji": "撒く",
      "type": "v5k",
      "meaning": "to scatter, to sprinkle, to sow"
    },
    {
      "id": 4324,
      "kana": "まく",
      "kanji": "幕",
      "type": "",
      "meaning": "curtain, bunting, act (in play)"
    },
    {
      "id": 4325,
      "kana": "まくら",
      "kanji": "枕",
      "type": "n,n-suf",
      "meaning": "pillow, bolster"
    },
    {
      "id": 4326,
      "kana": "まけ",
      "kanji": "負け",
      "type": "n",
      "meaning": "defeat, loss, losing (a game)"
    },
    {
      "id": 4327,
      "kana": "まける",
      "kanji": "負ける",
      "type": "v1",
      "meaning": "to lose, to be defeated"
    },
    {
      "id": 4328,
      "kana": "まげる",
      "kanji": "曲げる",
      "type": "v1",
      "meaning": "to bend, to crook, to lean"
    },
    {
      "id": 4329,
      "kana": "まご",
      "kanji": "孫",
      "type": "n",
      "meaning": "grandchild"
    },
    {
      "id": 4330,
      "kana": "まごまご",
      "kanji": "",
      "type": "adv,n",
      "meaning": "confused"
    },
    {
      "id": 4331,
      "kana": "まさか",
      "kanji": "",
      "type": "adv",
      "meaning": "by no means"
    },
    {
      "id": 4332,
      "kana": "まさつ",
      "kanji": "摩擦",
      "type": "n,vs",
      "meaning": "friction, rubbing, rubdown, chafe"
    },
    {
      "id": 4333,
      "kana": "まさに",
      "kanji": "",
      "type": "adv",
      "meaning": "correctly, surely"
    },
    {
      "id": 4334,
      "kana": "まざる",
      "kanji": "混ざる",
      "type": "v5r,vi",
      "meaning": "to be mixed, to be blended with, to associate with, to mingle with, to join"
    },
    {
      "id": 4335,
      "kana": "まざる",
      "kanji": "交ざる",
      "type": "v5r,vi",
      "meaning": "to be mixed, to be blended with, to associate with, to mingle with, to join"
    },
    {
      "id": 4336,
      "kana": "まじめ",
      "kanji": "真面目",
      "type": "adj-na,n",
      "meaning": "diligent, serious, honest"
    },
    {
      "id": 4337,
      "kana": "まじる",
      "kanji": "混じる",
      "type": "v5r,vi",
      "meaning": "to be mixed, to be blended with, to associate with"
    },
    {
      "id": 4338,
      "kana": "まじる",
      "kanji": "交じる",
      "type": "v5r,vi",
      "meaning": "to be mixed, to be blended with, to associate with"
    },
    {
      "id": 4339,
      "kana": "ます",
      "kanji": "増す",
      "type": "v5s,vi",
      "meaning": "to increase, to grow"
    },
    {
      "id": 4340,
      "kana": "まず",
      "kanji": "先ず",
      "type": "adv",
      "meaning": "first (of all), to start with, about, almost, hardly (with neg. verb)"
    },
    {
      "id": 4341,
      "kana": "まずい",
      "kanji": "",
      "type": "adj",
      "meaning": "unappetising, unpleasant (taste, appearance, situation), ugly"
    },
    {
      "id": 4342,
      "kana": "マスク",
      "kanji": "",
      "type": "n",
      "meaning": "mask"
    },
    {
      "id": 4343,
      "kana": "まずしい",
      "kanji": "貧しい",
      "type": "adj",
      "meaning": "poor, needy"
    },
    {
      "id": 4344,
      "kana": "マスター",
      "kanji": "",
      "type": "n",
      "meaning": "proprietor, manager, barowner, master (e.g. arts, science)"
    },
    {
      "id": 4345,
      "kana": "ますます",
      "kanji": "",
      "type": "adv",
      "meaning": "increasingly, more and more"
    },
    {
      "id": 4346,
      "kana": "まぜる",
      "kanji": "混ぜる",
      "type": "v1",
      "meaning": "to mix, to stir"
    },
    {
      "id": 4347,
      "kana": "まぜる",
      "kanji": "交ぜる",
      "type": "v1,vi",
      "meaning": "to be mixed, to be blended with"
    },
    {
      "id": 4348,
      "kana": "また",
      "kanji": "又",
      "type": "adv,conj,n",
      "meaning": "again, and"
    },
    {
      "id": 4349,
      "kana": "まだ",
      "kanji": "未だ",
      "type": "adv",
      "meaning": "yet, still, more, besides"
    },
    {
      "id": 4350,
      "kana": "またぐ",
      "kanji": "",
      "type": "v5g",
      "meaning": "to straddle"
    },
    {
      "id": 4351,
      "kana": "または",
      "kanji": "又は",
      "type": "conj,exp",
      "meaning": "or, otherwise"
    },
    {
      "id": 4352,
      "kana": "まち",
      "kanji": "町",
      "type": "n",
      "meaning": "(1) town, (2) street, road"
    },
    {
      "id": 4353,
      "kana": "まち",
      "kanji": "街",
      "type": "n",
      "meaning": "(1) town, (2) street, road"
    },
    {
      "id": 4354,
      "kana": "まちあいしつ",
      "kanji": "待合室",
      "type": "n",
      "meaning": "waiting room"
    },
    {
      "id": 4355,
      "kana": "まちあわせる",
      "kanji": "待ち合わせる",
      "type": "v1",
      "meaning": "to rendezvous, to meet at a prearranged place and time"
    },
    {
      "id": 4356,
      "kana": "まちがい",
      "kanji": "間違い",
      "type": "n",
      "meaning": "mistake"
    },
    {
      "id": 4357,
      "kana": "まちがえる",
      "kanji": "間違える",
      "type": "v1",
      "meaning": "to err, to make a mistake"
    },
    {
      "id": 4358,
      "kana": "まちかど",
      "kanji": "街角",
      "type": "n",
      "meaning": "street corner"
    },
    {
      "id": 4359,
      "kana": "まつ",
      "kanji": "待つ",
      "type": "v5t",
      "meaning": "to wait"
    },
    {
      "id": 4360,
      "kana": "まつ",
      "kanji": "松",
      "type": "n",
      "meaning": "(1) pine tree, (2) highest (of a three-tier ranking system)"
    },
    {
      "id": 4361,
      "kana": "まっか",
      "kanji": "真っ赤",
      "type": "adj-na,n",
      "meaning": "deep red, flushed (of face)"
    },
    {
      "id": 4362,
      "kana": "まっくら",
      "kanji": "真っ暗",
      "type": "adj-na,n",
      "meaning": "total darkness, shortsightedness, pitch dark"
    },
    {
      "id": 4363,
      "kana": "まっくろ",
      "kanji": "真っ黒",
      "type": "adj-na,n",
      "meaning": "pitch black"
    },
    {
      "id": 4364,
      "kana": "まっさお",
      "kanji": "真っ青",
      "type": "adj-na,n",
      "meaning": "deep blue, ghastly pale"
    },
    {
      "id": 4365,
      "kana": "まっさき",
      "kanji": "真っ先",
      "type": "n",
      "meaning": "the head, the foremost, beginning"
    },
    {
      "id": 4366,
      "kana": "まっしろ",
      "kanji": "真っ白",
      "type": "adj-na,n",
      "meaning": "pure white"
    },
    {
      "id": 4367,
      "kana": "まっすぐ",
      "kanji": "真っ直ぐ",
      "type": "adj-na,adv,n",
      "meaning": "straight (ahead), direct, upright"
    },
    {
      "id": 4368,
      "kana": "まったく",
      "kanji": "全く",
      "type": "adv",
      "meaning": "really, truly, entirely, completely"
    },
    {
      "id": 4369,
      "kana": "マッチ",
      "kanji": "",
      "type": "n",
      "meaning": "match"
    },
    {
      "id": 4370,
      "kana": "まつり",
      "kanji": "祭",
      "type": "n",
      "meaning": "festival, feast"
    },
    {
      "id": 4371,
      "kana": "まつる",
      "kanji": "祭る",
      "type": "v5r",
      "meaning": "to deify, to enshrine"
    },
    {
      "id": 4372,
      "kana": "まど",
      "kanji": "窓",
      "type": "n",
      "meaning": "window"
    },
    {
      "id": 4373,
      "kana": "まどぐち",
      "kanji": "窓口",
      "type": "n",
      "meaning": "ticket window"
    },
    {
      "id": 4374,
      "kana": "まとまる",
      "kanji": "纏まる",
      "type": "v5r",
      "meaning": "to be collected, to be settled, to be in order"
    },
    {
      "id": 4375,
      "kana": "まとめる",
      "kanji": "纏める",
      "type": "v1,vt",
      "meaning": "to put in order, to collect, to bring to a conclusion"
    },
    {
      "id": 4376,
      "kana": "まなぶ",
      "kanji": "学ぶ",
      "type": "v5b",
      "meaning": "to study (in depth), to learn, to take lessons in"
    },
    {
      "id": 4377,
      "kana": "まにあう",
      "kanji": "間に合う",
      "type": "v5u",
      "meaning": "(1) to be in time for, (2) to serve (suit, meet) the purpose, to be good enough"
    },
    {
      "id": 4378,
      "kana": "まね",
      "kanji": "真似",
      "type": "n",
      "meaning": "mimicry, imitation, behavior, pretense"
    },
    {
      "id": 4379,
      "kana": "まねく",
      "kanji": "招く",
      "type": "v5k",
      "meaning": "to invite"
    },
    {
      "id": 4380,
      "kana": "まねる",
      "kanji": "真似る",
      "type": "v1",
      "meaning": "to mimic, to imitate"
    },
    {
      "id": 4381,
      "kana": "まぶしい",
      "kanji": "",
      "type": "adj",
      "meaning": "dazzling, radiant"
    },
    {
      "id": 4382,
      "kana": "まぶた",
      "kanji": "",
      "type": "n",
      "meaning": "eyelids"
    },
    {
      "id": 4383,
      "kana": "マフラー",
      "kanji": "",
      "type": "n",
      "meaning": "muffler, scarf"
    },
    {
      "id": 4384,
      "kana": "ママ",
      "kanji": "",
      "type": "n",
      "meaning": "Mama"
    },
    {
      "id": 4385,
      "kana": "~まま",
      "kanji": "",
      "type": "",
      "meaning": "keep ~ as it is"
    },
    {
      "id": 4386,
      "kana": "まめ",
      "kanji": "豆",
      "type": "n",
      "meaning": "beans, peas"
    },
    {
      "id": 4387,
      "kana": "まもなく",
      "kanji": "間も無く",
      "type": "adv",
      "meaning": "soon, before long, in a short time"
    },
    {
      "id": 4388,
      "kana": "まもる",
      "kanji": "守る",
      "type": "v5r",
      "meaning": "to protect, to obey, to guard, to abide (by the rules)"
    },
    {
      "id": 4389,
      "kana": "まよう",
      "kanji": "迷う",
      "type": "v5u",
      "meaning": "to be puzzled, to be perplexed, to lose one's way"
    },
    {
      "id": 4390,
      "kana": "マラソン",
      "kanji": "",
      "type": "n",
      "meaning": "marathon"
    },
    {
      "id": 4391,
      "kana": "まる",
      "kanji": "丸",
      "type": "n",
      "meaning": "circle, full (month), perfection, purity, suffix for ship names"
    },
    {
      "id": 4392,
      "kana": "まる",
      "kanji": "円",
      "type": "n",
      "meaning": "circle, money"
    },
    {
      "id": 4393,
      "kana": "まるい",
      "kanji": "丸い",
      "type": "adj",
      "meaning": "round, circular, spherical"
    },
    {
      "id": 4394,
      "kana": "まるい",
      "kanji": "円い",
      "type": "adj",
      "meaning": "round, circular, spherical"
    },
    {
      "id": 4395,
      "kana": "まるで",
      "kanji": "",
      "type": "adv",
      "meaning": "quite, entirely, completely"
    },
    {
      "id": 4396,
      "kana": "まれ",
      "kanji": "稀",
      "type": "adj-na,n",
      "meaning": "rare, seldom"
    },
    {
      "id": 4397,
      "kana": "まわす",
      "kanji": "回す",
      "type": "v5s",
      "meaning": "to turn, to revolve"
    },
    {
      "id": 4398,
      "kana": "まわり",
      "kanji": "回り",
      "type": "n,n-suf",
      "meaning": "circumference, surroundings, circulation"
    },
    {
      "id": 4399,
      "kana": "まわり",
      "kanji": "周り",
      "type": "n,n-suf",
      "meaning": "circumference, surroundings, circulation"
    },
    {
      "id": 4400,
      "kana": "まわりみち",
      "kanji": "回り道",
      "type": "n",
      "meaning": "detour"
    },
    {
      "id": 4401,
      "kana": "まん",
      "kanji": "万",
      "type": "adv,num",
      "meaning": "10,000, ten thousand, myriads, all, everything"
    },
    {
      "id": 4402,
      "kana": "まんいち",
      "kanji": "万一",
      "type": "adv,n",
      "meaning": "by some chance, by some possibility, if by any chance, 10E4:1 odds"
    },
    {
      "id": 4403,
      "kana": "まんいん",
      "kanji": "満員",
      "type": "n",
      "meaning": "full house, no vacancy, sold out"
    },
    {
      "id": 4404,
      "kana": "まんが",
      "kanji": "漫画",
      "type": "n",
      "meaning": "comic, cartoon"
    },
    {
      "id": 4405,
      "kana": "マンション",
      "kanji": "",
      "type": "n",
      "meaning": "large apartment, apartment house"
    },
    {
      "id": 4406,
      "kana": "まんぞく",
      "kanji": "満足",
      "type": "adj-na,n,vs",
      "meaning": "satisfaction"
    },
    {
      "id": 4407,
      "kana": "まんてん",
      "kanji": "満点",
      "type": "n",
      "meaning": "perfect score"
    },
    {
      "id": 4408,
      "kana": "まんなか",
      "kanji": "真中",
      "type": "n",
      "meaning": "middle, centre, mid-way"
    },
    {
      "id": 4409,
      "kana": "まんねんひつ",
      "kanji": "万年筆",
      "type": "n",
      "meaning": "fountain pen"
    },
    {
      "id": 4410,
      "kana": "み",
      "kanji": "身",
      "type": "n",
      "meaning": "body, main part, oneself, sword"
    },
    {
      "id": 4411,
      "kana": "み",
      "kanji": "実",
      "type": "n",
      "meaning": "fruit, nut, seed, content, good result"
    },
    {
      "id": 4412,
      "kana": "み~",
      "kanji": "未~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4413,
      "kana": "（接尾）",
      "kanji": "~み",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4414,
      "kana": "みあげる",
      "kanji": "見上げる",
      "type": "v1",
      "meaning": "to look up at, to raise one's eyes, to admire"
    },
    {
      "id": 4415,
      "kana": "みえる",
      "kanji": "見える",
      "type": "v1",
      "meaning": "to be seen, to be in sight, to look, to seem, to appear"
    },
    {
      "id": 4416,
      "kana": "みおくり",
      "kanji": "見送り",
      "type": "n",
      "meaning": "seeing one off, farewell, escort"
    },
    {
      "id": 4417,
      "kana": "みおくる",
      "kanji": "見送る",
      "type": "v5r",
      "meaning": "(1) to see off, to farewell, (2) to escort, (3) to let pass"
    },
    {
      "id": 4418,
      "kana": "みおろす",
      "kanji": "見下ろす",
      "type": "v5s",
      "meaning": "to overlook, to command a view of, to look down on something"
    },
    {
      "id": 4419,
      "kana": "みがく",
      "kanji": "磨く",
      "type": "v5k",
      "meaning": "to polish, to shine, to brush, to refine, to improve"
    },
    {
      "id": 4420,
      "kana": "みかけ",
      "kanji": "見掛け",
      "type": "n",
      "meaning": "outward appearance"
    },
    {
      "id": 4421,
      "kana": "みかた",
      "kanji": "味方",
      "type": "n",
      "meaning": "friend, ally, supporter"
    },
    {
      "id": 4422,
      "kana": "みかづき",
      "kanji": "三日月",
      "type": "n",
      "meaning": "new moon, crescent moon"
    },
    {
      "id": 4423,
      "kana": "みぎ",
      "kanji": "右",
      "type": "n",
      "meaning": "right hand side"
    },
    {
      "id": 4424,
      "kana": "みごと",
      "kanji": "見事",
      "type": "adj-na,n",
      "meaning": "splendid, magnificent, beautiful, admirable"
    },
    {
      "id": 4425,
      "kana": "みさき",
      "kanji": "岬",
      "type": "n",
      "meaning": "cape (on coast)"
    },
    {
      "id": 4426,
      "kana": "みじかい",
      "kanji": "短い",
      "type": "adj",
      "meaning": "short"
    },
    {
      "id": 4427,
      "kana": "みじめ",
      "kanji": "",
      "type": "adj-na",
      "meaning": "(arch) sad, pitiful, wretched"
    },
    {
      "id": 4428,
      "kana": "ミシン",
      "kanji": "",
      "type": "n",
      "meaning": "sewing machine"
    },
    {
      "id": 4429,
      "kana": "ミス",
      "kanji": "",
      "type": "n",
      "meaning": "miss (mistake, error, failure), Miss"
    },
    {
      "id": 4430,
      "kana": "みず",
      "kanji": "水",
      "type": "n",
      "meaning": "water"
    },
    {
      "id": 4431,
      "kana": "みずうみ",
      "kanji": "湖",
      "type": "n",
      "meaning": "lake"
    },
    {
      "id": 4432,
      "kana": "みずから",
      "kanji": "自ら",
      "type": "n-adv,n",
      "meaning": "for one's self, personally"
    },
    {
      "id": 4433,
      "kana": "みずぎ",
      "kanji": "水着",
      "type": "n",
      "meaning": "bathing suit (woman's)"
    },
    {
      "id": 4434,
      "kana": "みせ",
      "kanji": "店",
      "type": "n,n-suf",
      "meaning": "store, shop, establishment"
    },
    {
      "id": 4435,
      "kana": "みせや",
      "kanji": "店屋",
      "type": "n",
      "meaning": "store, shop"
    },
    {
      "id": 4436,
      "kana": "みせる",
      "kanji": "見せる",
      "type": "v1",
      "meaning": "to show, to display"
    },
    {
      "id": 4437,
      "kana": "みそ",
      "kanji": "味噌",
      "type": "n",
      "meaning": "(1) miso, bean paste, (2) key (main) point"
    },
    {
      "id": 4438,
      "kana": "~みたい",
      "kanji": "",
      "type": "",
      "meaning": "looks like ~"
    },
    {
      "id": 4439,
      "kana": "みだし",
      "kanji": "見出し",
      "type": "n",
      "meaning": "heading, caption, subtitle, index"
    },
    {
      "id": 4440,
      "kana": "みち",
      "kanji": "道",
      "type": "n",
      "meaning": "road, street, way, method"
    },
    {
      "id": 4441,
      "kana": "みちじゅん",
      "kanji": "道順",
      "type": "n",
      "meaning": "itinerary, route"
    },
    {
      "id": 4442,
      "kana": "みちる",
      "kanji": "満ちる",
      "type": "v1",
      "meaning": "to be full, to rise (tide), to mature, to expire"
    },
    {
      "id": 4443,
      "kana": "みつ",
      "kanji": "密",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4444,
      "kana": "みっか",
      "kanji": "三日",
      "type": "n",
      "meaning": "three days, the third day (of the month)"
    },
    {
      "id": 4445,
      "kana": "みつかる",
      "kanji": "見付かる",
      "type": "v5r",
      "meaning": "to be found, to be discovered"
    },
    {
      "id": 4446,
      "kana": "みつける",
      "kanji": "見付ける",
      "type": "v1",
      "meaning": "to be familiar, to discover, to detect"
    },
    {
      "id": 4447,
      "kana": "みっつ",
      "kanji": "三つ",
      "type": "n",
      "meaning": "three"
    },
    {
      "id": 4448,
      "kana": "みっともない",
      "kanji": "",
      "type": "uk",
      "meaning": "shameful, indecent"
    },
    {
      "id": 4449,
      "kana": "みつめる",
      "kanji": "見詰める",
      "type": "v1",
      "meaning": "to stare at, to gaze at, to look hard at, to watch intently, to fix one's eyes on"
    },
    {
      "id": 4450,
      "kana": "みとめる",
      "kanji": "認める",
      "type": "v1,vt",
      "meaning": "to recognize, to appreciate, to approve, to admit, to notice"
    },
    {
      "id": 4451,
      "kana": "みどり",
      "kanji": "緑",
      "type": "n",
      "meaning": "greenery"
    },
    {
      "id": 4452,
      "kana": "みな",
      "kanji": "皆",
      "type": "adv,n",
      "meaning": "all, everyone, everybody"
    },
    {
      "id": 4453,
      "kana": "みんな",
      "kanji": "皆",
      "type": "adv,n",
      "meaning": "all, everyone, everybody"
    },
    {
      "id": 4454,
      "kana": "みなおす",
      "kanji": "見直す",
      "type": "v5s",
      "meaning": "to look again, to get a better opinion of"
    },
    {
      "id": 4455,
      "kana": "みなと",
      "kanji": "港",
      "type": "n",
      "meaning": "harbour, port"
    },
    {
      "id": 4456,
      "kana": "みなみ",
      "kanji": "南",
      "type": "n,vs",
      "meaning": "South, proceeding south"
    },
    {
      "id": 4457,
      "kana": "みなれる",
      "kanji": "見慣れる",
      "type": "v1",
      "meaning": "to become used to seeing, to be familiar with"
    },
    {
      "id": 4458,
      "kana": "みにくい",
      "kanji": "醜い",
      "type": "adj",
      "meaning": "ugly"
    },
    {
      "id": 4459,
      "kana": "みのる",
      "kanji": "実る",
      "type": "v5r",
      "meaning": "to bear fruit, to ripen"
    },
    {
      "id": 4460,
      "kana": "みぶん",
      "kanji": "身分",
      "type": "n",
      "meaning": "social position, social status"
    },
    {
      "id": 4461,
      "kana": "みほん",
      "kanji": "見本",
      "type": "n",
      "meaning": "sample"
    },
    {
      "id": 4462,
      "kana": "みまい",
      "kanji": "見舞い",
      "type": "n",
      "meaning": "enquiry, expression of sympathy, expression of concern"
    },
    {
      "id": 4463,
      "kana": "みまう",
      "kanji": "見舞う",
      "type": "v5u",
      "meaning": "to ask after (health), to visit"
    },
    {
      "id": 4464,
      "kana": "みまん",
      "kanji": "未満",
      "type": "n-suf",
      "meaning": "less than, insufficient"
    },
    {
      "id": 4465,
      "kana": "みみ",
      "kanji": "耳",
      "type": "n",
      "meaning": "ear"
    },
    {
      "id": 4466,
      "kana": "みやげ",
      "kanji": "土産",
      "type": "n",
      "meaning": "present, souvenir"
    },
    {
      "id": 4467,
      "kana": "みやこ",
      "kanji": "都",
      "type": "n",
      "meaning": "capital"
    },
    {
      "id": 4468,
      "kana": "みょう",
      "kanji": "妙",
      "type": "adj-na,n",
      "meaning": "strange, unusual"
    },
    {
      "id": 4469,
      "kana": "みょう~",
      "kanji": "明~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4470,
      "kana": "みょうごにち",
      "kanji": "明後日",
      "type": "n-adv,n-t",
      "meaning": "day after tomorrow"
    },
    {
      "id": 4471,
      "kana": "みょうじ",
      "kanji": "名字",
      "type": "n",
      "meaning": "surname, family name"
    },
    {
      "id": 4472,
      "kana": "みらい",
      "kanji": "未来",
      "type": "n",
      "meaning": "future (life, tense)"
    },
    {
      "id": 4473,
      "kana": "（メートル）",
      "kanji": "ミリ",
      "type": "n",
      "meaning": "milli-, 10^-3"
    },
    {
      "id": 4474,
      "kana": "みりょく",
      "kanji": "魅力",
      "type": "n",
      "meaning": "charm, fascination, glamour"
    },
    {
      "id": 4475,
      "kana": "みる",
      "kanji": "見る",
      "type": "v1",
      "meaning": "to see, to watch"
    },
    {
      "id": 4476,
      "kana": "みる",
      "kanji": "診る",
      "type": "v1",
      "meaning": "to examine (medical)"
    },
    {
      "id": 4477,
      "kana": "ミルク",
      "kanji": "",
      "type": "n",
      "meaning": "milk"
    },
    {
      "id": 4478,
      "kana": "みんかん",
      "kanji": "民間",
      "type": "adj-no,n",
      "meaning": "private, civilian, civil, popular, folk, unofficial"
    },
    {
      "id": 4479,
      "kana": "みんしゅ~",
      "kanji": "民主~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4480,
      "kana": "みんよう",
      "kanji": "民謡",
      "type": "n",
      "meaning": "folk song, popular song"
    },
    {
      "id": 4481,
      "kana": "む",
      "kanji": "無",
      "type": "n",
      "meaning": "nothing, naught, nil, zero"
    },
    {
      "id": 4482,
      "kana": "むいか",
      "kanji": "六日",
      "type": "",
      "meaning": "six days, sixth (day of month)"
    },
    {
      "id": 4483,
      "kana": "むかい",
      "kanji": "向かい",
      "type": "adj-no,n",
      "meaning": "facing, opposite, across the street, other side"
    },
    {
      "id": 4484,
      "kana": "むかう",
      "kanji": "向う",
      "type": "io",
      "meaning": "(v5u) to face, to go towards"
    },
    {
      "id": 4485,
      "kana": "むかえ",
      "kanji": "迎え",
      "type": "n",
      "meaning": "meeting, person sent to pick up an arrival"
    },
    {
      "id": 4486,
      "kana": "むかえる",
      "kanji": "迎える",
      "type": "v1",
      "meaning": "to go out to meet"
    },
    {
      "id": 4487,
      "kana": "むかし",
      "kanji": "昔",
      "type": "adj-no,n-adv,n-t",
      "meaning": "olden days, former"
    },
    {
      "id": 4488,
      "kana": "むく",
      "kanji": "向く",
      "type": "v5k",
      "meaning": "to face"
    },
    {
      "id": 4489,
      "kana": "むく",
      "kanji": "剥く",
      "type": "v5k",
      "meaning": "to peel, to skin, to pare, to hull"
    },
    {
      "id": 4490,
      "kana": "~むけ",
      "kanji": "~向け",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4491,
      "kana": "むける",
      "kanji": "向ける",
      "type": "v1",
      "meaning": "to turn towards, to point"
    },
    {
      "id": 4492,
      "kana": "むげん",
      "kanji": "無限",
      "type": "adj-na,n",
      "meaning": "infinite"
    },
    {
      "id": 4493,
      "kana": "むこう",
      "kanji": "向こう",
      "type": "n",
      "meaning": "beyond, over there, opposite direction, the other party"
    },
    {
      "id": 4494,
      "kana": "むし",
      "kanji": "虫",
      "type": "n",
      "meaning": "insect"
    },
    {
      "id": 4495,
      "kana": "むし",
      "kanji": "無視",
      "type": "n,vs",
      "meaning": "disregard, ignore"
    },
    {
      "id": 4496,
      "kana": "むじ",
      "kanji": "無地",
      "type": "n",
      "meaning": "plain, unfigured"
    },
    {
      "id": 4497,
      "kana": "むしあつい",
      "kanji": "蒸し暑い",
      "type": "adj",
      "meaning": "humid, sultry"
    },
    {
      "id": 4498,
      "kana": "むしば",
      "kanji": "虫歯",
      "type": "n",
      "meaning": "cavity, tooth decay, decayed tooth, caries"
    },
    {
      "id": 4499,
      "kana": "むじゅん",
      "kanji": "矛盾",
      "type": "n,vs",
      "meaning": "contradiction, inconsistency"
    },
    {
      "id": 4500,
      "kana": "むしろ",
      "kanji": "寧ろ",
      "type": "adv",
      "meaning": "rather, better, instead"
    },
    {
      "id": 4501,
      "kana": "むす",
      "kanji": "蒸す",
      "type": "v5s",
      "meaning": "to steam, to poultice, to be sultry"
    },
    {
      "id": 4502,
      "kana": "むすう",
      "kanji": "無数",
      "type": "adj-na,n",
      "meaning": "countless number, infinite number"
    },
    {
      "id": 4503,
      "kana": "むずかしい",
      "kanji": "難しい",
      "type": "adj",
      "meaning": "difficult"
    },
    {
      "id": 4504,
      "kana": "むすこ",
      "kanji": "息子",
      "type": "n",
      "meaning": "(hum) son"
    },
    {
      "id": 4505,
      "kana": "むすぶ",
      "kanji": "結ぶ",
      "type": "v5b",
      "meaning": "to tie, to bind, to link"
    },
    {
      "id": 4506,
      "kana": "むすめ",
      "kanji": "娘",
      "type": "n",
      "meaning": "(hum) daughter"
    },
    {
      "id": 4507,
      "kana": "むだ",
      "kanji": "無駄",
      "type": "adj-na,n",
      "meaning": "futility, uselessness"
    },
    {
      "id": 4508,
      "kana": "むちゅう",
      "kanji": "夢中",
      "type": "adj-na,n",
      "meaning": "daze, (in a) trance, ecstasy, delirium, engrossment"
    },
    {
      "id": 4509,
      "kana": "むっつ",
      "kanji": "六つ",
      "type": "num",
      "meaning": "six"
    },
    {
      "id": 4510,
      "kana": "むね",
      "kanji": "胸",
      "type": "n",
      "meaning": "breast, chest"
    },
    {
      "id": 4511,
      "kana": "むら",
      "kanji": "村",
      "type": "n",
      "meaning": "village"
    },
    {
      "id": 4512,
      "kana": "むらさき",
      "kanji": "紫",
      "type": "n",
      "meaning": "purple colour, violet"
    },
    {
      "id": 4513,
      "kana": "むり",
      "kanji": "無理",
      "type": "adj-na,n,vs",
      "meaning": "unreasonable, impossible, overdoing"
    },
    {
      "id": 4514,
      "kana": "むりょう",
      "kanji": "無料",
      "type": "adj-no,n",
      "meaning": "free, no charge"
    },
    {
      "id": 4515,
      "kana": "むれ",
      "kanji": "群れ",
      "type": "n",
      "meaning": "group, crowd, flock, herd"
    },
    {
      "id": 4516,
      "kana": "め",
      "kanji": "目",
      "type": "n",
      "meaning": "eye, eyeball"
    },
    {
      "id": 4517,
      "kana": "め",
      "kanji": "芽",
      "type": "n",
      "meaning": "sprout"
    },
    {
      "id": 4518,
      "kana": "~め",
      "kanji": "~目",
      "type": "",
      "meaning": "~nd,~th (sequence)"
    },
    {
      "id": 4519,
      "kana": "めい",
      "kanji": "姪",
      "type": "n",
      "meaning": "niece"
    },
    {
      "id": 4520,
      "kana": "めい~",
      "kanji": "名~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4521,
      "kana": "~めい",
      "kanji": "~名",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4522,
      "kana": "めいかく",
      "kanji": "明確",
      "type": "adj-na,n",
      "meaning": "clear up, clarify, define"
    },
    {
      "id": 4523,
      "kana": "めいさく",
      "kanji": "名作",
      "type": "n",
      "meaning": "masterpiece"
    },
    {
      "id": 4524,
      "kana": "めいし",
      "kanji": "名刺",
      "type": "n",
      "meaning": "business card"
    },
    {
      "id": 4525,
      "kana": "めいし",
      "kanji": "名詞",
      "type": "n",
      "meaning": "noun"
    },
    {
      "id": 4526,
      "kana": "めいしょ",
      "kanji": "名所",
      "type": "n",
      "meaning": "famous place"
    },
    {
      "id": 4527,
      "kana": "めいじる",
      "kanji": "命じる",
      "type": "v1",
      "meaning": "to order, to command, to appoint"
    },
    {
      "id": 4528,
      "kana": "めいずる",
      "kanji": "命ずる",
      "type": "v5z",
      "meaning": "to command, to appoint"
    },
    {
      "id": 4529,
      "kana": "めいしん",
      "kanji": "迷信",
      "type": "n",
      "meaning": "superstition"
    },
    {
      "id": 4530,
      "kana": "めいじん",
      "kanji": "名人",
      "type": "n",
      "meaning": "master, expert"
    },
    {
      "id": 4531,
      "kana": "めいぶつ",
      "kanji": "名物",
      "type": "n",
      "meaning": "famous product, special product, speciality"
    },
    {
      "id": 4532,
      "kana": "めいめい",
      "kanji": "銘々",
      "type": "n",
      "meaning": "each, individual"
    },
    {
      "id": 4533,
      "kana": "めいれい",
      "kanji": "命令",
      "type": "n,vs",
      "meaning": "order, command, decree, directive, (software) instruction"
    },
    {
      "id": 4534,
      "kana": "めいわく",
      "kanji": "迷惑",
      "type": "adj-na,n",
      "meaning": "trouble, bother, annoyance"
    },
    {
      "id": 4535,
      "kana": "めうえ",
      "kanji": "目上",
      "type": "n",
      "meaning": "superior(s), senior"
    },
    {
      "id": 4536,
      "kana": "メーター",
      "kanji": "",
      "type": "n",
      "meaning": "meter (clock)"
    },
    {
      "id": 4537,
      "kana": "メートル",
      "kanji": "",
      "type": "meter",
      "meaning": "(old form)"
    },
    {
      "id": 4538,
      "kana": "めがね",
      "kanji": "眼鏡",
      "type": "n",
      "meaning": "spectacles, glasses"
    },
    {
      "id": 4539,
      "kana": "めぐまれる",
      "kanji": "恵まれる",
      "type": "v1",
      "meaning": "to be blessed with, to be rich in"
    },
    {
      "id": 4540,
      "kana": "めぐる",
      "kanji": "巡る",
      "type": "v5r",
      "meaning": "to go around"
    },
    {
      "id": 4541,
      "kana": "めざす",
      "kanji": "目指す",
      "type": "v5s",
      "meaning": "to aim at, to have an eye on"
    },
    {
      "id": 4542,
      "kana": "めざまし",
      "kanji": "目覚し",
      "type": "n",
      "meaning": "(abbr) alarm-clock"
    },
    {
      "id": 4543,
      "kana": "めし",
      "kanji": "飯",
      "type": "n",
      "meaning": "(sl) meals, food"
    },
    {
      "id": 4544,
      "kana": "めしあがる",
      "kanji": "召し上がる",
      "type": "v5r",
      "meaning": "(pol) to eat"
    },
    {
      "id": 4545,
      "kana": "めした",
      "kanji": "目下",
      "type": "n-adv,n-t",
      "meaning": "at present, now"
    },
    {
      "id": 4546,
      "kana": "めじるし",
      "kanji": "目印",
      "type": "n,vs",
      "meaning": "mark, sign, landmark"
    },
    {
      "id": 4547,
      "kana": "めずらしい",
      "kanji": "珍しい",
      "type": "adj",
      "meaning": "unusual, rare"
    },
    {
      "id": 4548,
      "kana": "めだつ",
      "kanji": "目立つ",
      "type": "v5t",
      "meaning": "to be conspicuous, to stand out"
    },
    {
      "id": 4549,
      "kana": "めちゃくちゃ",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "absurd, unreasonable, excessive, messed up, spoiled, wreaked"
    },
    {
      "id": 4550,
      "kana": "めっきり",
      "kanji": "",
      "type": "adv",
      "meaning": "remarkably"
    },
    {
      "id": 4551,
      "kana": "めったに",
      "kanji": "滅多に",
      "type": "adv",
      "meaning": "rarely (with neg. verb), seldom"
    },
    {
      "id": 4552,
      "kana": "めでたい",
      "kanji": "",
      "type": "adj",
      "meaning": "happy, simple soul, propitious, joyous"
    },
    {
      "id": 4553,
      "kana": "メニュー",
      "kanji": "",
      "type": "n",
      "meaning": "menu"
    },
    {
      "id": 4554,
      "kana": "めまい",
      "kanji": "",
      "type": "n,vs",
      "meaning": "dizziness, giddiness"
    },
    {
      "id": 4555,
      "kana": "メモ",
      "kanji": "",
      "type": "n",
      "meaning": "memorandum"
    },
    {
      "id": 4556,
      "kana": "めやす",
      "kanji": "目安",
      "type": "n",
      "meaning": "criterion, aim"
    },
    {
      "id": 4557,
      "kana": "めん",
      "kanji": "面",
      "type": "n,n-suf",
      "meaning": "face, mug, surface, facial features, mask, face guard, side or facet, corner, page"
    },
    {
      "id": 4558,
      "kana": "めん",
      "kanji": "綿",
      "type": "n",
      "meaning": "cotton, padding"
    },
    {
      "id": 4559,
      "kana": "めんきょ",
      "kanji": "免許",
      "type": "n",
      "meaning": "license, permit, licence, certificate"
    },
    {
      "id": 4560,
      "kana": "めんぜい",
      "kanji": "免税",
      "type": "n,vs",
      "meaning": "tax exemption, duty exemption"
    },
    {
      "id": 4561,
      "kana": "めんせき",
      "kanji": "面積",
      "type": "n",
      "meaning": "area"
    },
    {
      "id": 4562,
      "kana": "めんせつ",
      "kanji": "面接",
      "type": "n",
      "meaning": "interview"
    },
    {
      "id": 4563,
      "kana": "めんどう",
      "kanji": "面倒",
      "type": "adj-na,n",
      "meaning": "trouble, difficulty, care, attention"
    },
    {
      "id": 4564,
      "kana": "めんどうくさい",
      "kanji": "面倒臭い",
      "type": "adj",
      "meaning": "bother to do, tiresome"
    },
    {
      "id": 4565,
      "kana": "メンバー",
      "kanji": "",
      "type": "n",
      "meaning": "member"
    },
    {
      "id": 4566,
      "kana": "もう",
      "kanji": "",
      "type": "",
      "meaning": "net"
    },
    {
      "id": 4567,
      "kana": "もうかる",
      "kanji": "儲かる",
      "type": "v5r",
      "meaning": "to be profitable, to yield a profit"
    },
    {
      "id": 4568,
      "kana": "もうける",
      "kanji": "儲ける",
      "type": "v1",
      "meaning": "to get, to earn, to gain, to have (bear, beget) a child"
    },
    {
      "id": 4569,
      "kana": "もうしあげる",
      "kanji": "申し上げる",
      "type": "v1",
      "meaning": "to say, to tell, to state"
    },
    {
      "id": 4570,
      "kana": "もうしこむ",
      "kanji": "申し込む",
      "type": "v5m",
      "meaning": "to apply for, to make an application"
    },
    {
      "id": 4571,
      "kana": "もうしわけ",
      "kanji": "申し訳",
      "type": "n",
      "meaning": "apology, excuse"
    },
    {
      "id": 4572,
      "kana": "もうしわけない",
      "kanji": "申し訳ない",
      "type": "adj-na",
      "meaning": "inexcusable"
    },
    {
      "id": 4573,
      "kana": "もうす",
      "kanji": "申す",
      "type": "v5s",
      "meaning": "(hum) to be called, to say"
    },
    {
      "id": 4574,
      "kana": "もうふ",
      "kanji": "毛布",
      "type": "n",
      "meaning": "blanket"
    },
    {
      "id": 4575,
      "kana": "もえる",
      "kanji": "燃える",
      "type": "v1",
      "meaning": "to burn"
    },
    {
      "id": 4576,
      "kana": "モーター",
      "kanji": "",
      "type": "n",
      "meaning": "motor"
    },
    {
      "id": 4577,
      "kana": "もくざい",
      "kanji": "木材",
      "type": "n",
      "meaning": "lumber, timber, wood"
    },
    {
      "id": 4578,
      "kana": "もくじ",
      "kanji": "目次",
      "type": "n",
      "meaning": "table of contents"
    },
    {
      "id": 4579,
      "kana": "もくてき",
      "kanji": "目的",
      "type": "n",
      "meaning": "purpose, goal, aim, objective, intention"
    },
    {
      "id": 4580,
      "kana": "もくひょう",
      "kanji": "目標",
      "type": "n",
      "meaning": "mark, objective, target"
    },
    {
      "id": 4581,
      "kana": "もくよう",
      "kanji": "木曜",
      "type": "n-adv,n",
      "meaning": "Thursday"
    },
    {
      "id": 4582,
      "kana": "もぐる",
      "kanji": "潜る",
      "type": "v5r",
      "meaning": "(1) to drive, to pass through, (2) to evade, to hide, (3) to dive (into or under water), to go underground"
    },
    {
      "id": 4583,
      "kana": "もし",
      "kanji": "",
      "type": "n",
      "meaning": "sham examination"
    },
    {
      "id": 4584,
      "kana": "もじ",
      "kanji": "文字",
      "type": "n",
      "meaning": "letter (of alphabet), character"
    },
    {
      "id": 4585,
      "kana": "もんじ",
      "kanji": "文字",
      "type": "n",
      "meaning": "letter (of alphabet), character"
    },
    {
      "id": 4586,
      "kana": "もしかしたら",
      "kanji": "",
      "type": "adv,exp",
      "meaning": "perhaps, maybe, by some chance"
    },
    {
      "id": 4587,
      "kana": "もしかすると",
      "kanji": "",
      "type": "adv,exp",
      "meaning": "perhaps, maybe, by some chance"
    },
    {
      "id": 4588,
      "kana": "もしも",
      "kanji": "",
      "type": "adv",
      "meaning": "if"
    },
    {
      "id": 4589,
      "kana": "もしもし",
      "kanji": "",
      "type": "conj,int",
      "meaning": "hello (on phone)"
    },
    {
      "id": 4590,
      "kana": "もたれる",
      "kanji": "",
      "type": "v1",
      "meaning": "to lean against, to lean on, to recline on, to lie heavy (on the stomach)"
    },
    {
      "id": 4591,
      "kana": "モダン",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "modern"
    },
    {
      "id": 4592,
      "kana": "もち",
      "kanji": "餅",
      "type": "n",
      "meaning": "sticky rice cake"
    },
    {
      "id": 4593,
      "kana": "~もち",
      "kanji": "~持ち",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4594,
      "kana": "もちあげる",
      "kanji": "持ち上げる",
      "type": "v1",
      "meaning": "to raise, to lift up, to flatter"
    },
    {
      "id": 4595,
      "kana": "もちいる",
      "kanji": "用いる",
      "type": "v1",
      "meaning": "to use, to make use of"
    },
    {
      "id": 4596,
      "kana": "もちろん",
      "kanji": "勿論",
      "type": "adv",
      "meaning": "of course, certainly, naturally"
    },
    {
      "id": 4597,
      "kana": "もつ",
      "kanji": "持つ",
      "type": "v5t",
      "meaning": "(1) to hold, to carry, (2) to possess"
    },
    {
      "id": 4598,
      "kana": "もったいない",
      "kanji": "",
      "type": "adj",
      "meaning": "too good, more than one deserves, wasteful, sacrilegious, unworthy of"
    },
    {
      "id": 4599,
      "kana": "もっと",
      "kanji": "",
      "type": "adv",
      "meaning": "more, longer, farther"
    },
    {
      "id": 4600,
      "kana": "もっとも",
      "kanji": "最も",
      "type": "adv",
      "meaning": "most, extremely"
    },
    {
      "id": 4601,
      "kana": "もっとも",
      "kanji": "尤も",
      "type": "adj-na,adv,conj,n",
      "meaning": "quite right, plausible, natural, but then, although"
    },
    {
      "id": 4602,
      "kana": "モデル",
      "kanji": "",
      "type": "n",
      "meaning": "model"
    },
    {
      "id": 4603,
      "kana": "もと",
      "kanji": "元",
      "type": "n,n-suf,n-t",
      "meaning": "(1) origin, original, (2) former"
    },
    {
      "id": 4604,
      "kana": "もと",
      "kanji": "基",
      "type": "n",
      "meaning": "basis"
    },
    {
      "id": 4605,
      "kana": "もと",
      "kanji": "素",
      "type": "adj-na,adj-no,n",
      "meaning": "prime"
    },
    {
      "id": 4606,
      "kana": "もどす",
      "kanji": "戻す",
      "type": "v5s",
      "meaning": "to restore, to put back, to return"
    },
    {
      "id": 4607,
      "kana": "もとづく",
      "kanji": "基づく",
      "type": "v5k",
      "meaning": "to be grounded on, to be based on, to be due to, to originate from"
    },
    {
      "id": 4608,
      "kana": "もとめる",
      "kanji": "求める",
      "type": "v1",
      "meaning": "to seek, to request, to demand, to want, to wish for, to search for, to pursue (pleasure), to hunt (a job), to buy"
    },
    {
      "id": 4609,
      "kana": "もともと",
      "kanji": "元々",
      "type": "adv,adj-no",
      "meaning": "originally, by nature, from the start"
    },
    {
      "id": 4610,
      "kana": "もどる",
      "kanji": "戻る",
      "type": "v5r",
      "meaning": "to turn back, to return"
    },
    {
      "id": 4611,
      "kana": "もの",
      "kanji": "物",
      "type": "n",
      "meaning": "thing, object"
    },
    {
      "id": 4612,
      "kana": "もの",
      "kanji": "者",
      "type": "n",
      "meaning": "person"
    },
    {
      "id": 4613,
      "kana": "ものおき",
      "kanji": "物置",
      "type": "n",
      "meaning": "storage room"
    },
    {
      "id": 4614,
      "kana": "ものおと",
      "kanji": "物音",
      "type": "n",
      "meaning": "sounds"
    },
    {
      "id": 4615,
      "kana": "ものがたり",
      "kanji": "物語",
      "type": "n",
      "meaning": "tale, story, legend"
    },
    {
      "id": 4616,
      "kana": "ものがたる",
      "kanji": "物語る",
      "type": "v5r",
      "meaning": "to tell, to indicate"
    },
    {
      "id": 4617,
      "kana": "ものごと",
      "kanji": "物事",
      "type": "n",
      "meaning": "things, everything"
    },
    {
      "id": 4618,
      "kana": "ものさし",
      "kanji": "物差し",
      "type": "n",
      "meaning": "ruler, measure"
    },
    {
      "id": 4619,
      "kana": "ものすごい",
      "kanji": "物凄い",
      "type": "adj",
      "meaning": "earth-shattering, staggering, to a very great extent"
    },
    {
      "id": 4620,
      "kana": "モノレール",
      "kanji": "",
      "type": "n",
      "meaning": "monorail"
    },
    {
      "id": 4621,
      "kana": "もみじ",
      "kanji": "紅葉",
      "type": "n",
      "meaning": "(1) (Japanese) maple"
    },
    {
      "id": 4622,
      "kana": "もむ",
      "kanji": "揉む",
      "type": "v5m",
      "meaning": "to rub, to crumple (up), to wrinkle"
    },
    {
      "id": 4623,
      "kana": "もめん",
      "kanji": "木綿",
      "type": "n",
      "meaning": "cotton"
    },
    {
      "id": 4624,
      "kana": "もやす",
      "kanji": "燃やす",
      "type": "v5s",
      "meaning": "to burn"
    },
    {
      "id": 4625,
      "kana": "もよう",
      "kanji": "模様",
      "type": "n",
      "meaning": "pattern, figure, design"
    },
    {
      "id": 4626,
      "kana": "もよおし",
      "kanji": "催し",
      "type": "n",
      "meaning": "event, festivities, function"
    },
    {
      "id": 4627,
      "kana": "もらう",
      "kanji": "貰う",
      "type": "v5u",
      "meaning": "to receive"
    },
    {
      "id": 4628,
      "kana": "もり",
      "kanji": "森",
      "type": "n",
      "meaning": "forest"
    },
    {
      "id": 4629,
      "kana": "もる",
      "kanji": "盛る",
      "type": "v5r",
      "meaning": "(1) to serve (food, etc.), (2) to fill up, (3) to prescribe"
    },
    {
      "id": 4630,
      "kana": "もん",
      "kanji": "門",
      "type": "n,n-suf",
      "meaning": "gate"
    },
    {
      "id": 4631,
      "kana": "~もん",
      "kanji": "~問",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4632,
      "kana": "もんく",
      "kanji": "文句",
      "type": "n",
      "meaning": "phrase, complaint"
    },
    {
      "id": 4633,
      "kana": "もんだい",
      "kanji": "問題",
      "type": "n",
      "meaning": "problem, question"
    },
    {
      "id": 4634,
      "kana": "もんどう",
      "kanji": "問答",
      "type": "n",
      "meaning": "questions and answers, dialogue"
    },
    {
      "id": 4635,
      "kana": "~や",
      "kanji": "~屋",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4636,
      "kana": "~や",
      "kanji": "~夜",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4637,
      "kana": "やおや",
      "kanji": "八百屋",
      "type": "n",
      "meaning": "greengrocer"
    },
    {
      "id": 4638,
      "kana": "やがて",
      "kanji": "",
      "type": "adv",
      "meaning": "before long, soon, at length"
    },
    {
      "id": 4639,
      "kana": "やかましい",
      "kanji": "",
      "type": "adj",
      "meaning": "(1) noisy, (2) strict, fussy"
    },
    {
      "id": 4640,
      "kana": "やかん",
      "kanji": "夜間",
      "type": "n-adv,n-t",
      "meaning": "at night, nighttime"
    },
    {
      "id": 4641,
      "kana": "やかん",
      "kanji": "薬缶",
      "type": "n",
      "meaning": "kettle"
    },
    {
      "id": 4642,
      "kana": "やく",
      "kanji": "焼く",
      "type": "v5k",
      "meaning": "to bake, to grill"
    },
    {
      "id": 4643,
      "kana": "やく",
      "kanji": "役",
      "type": "n,n-suf",
      "meaning": "use, service, role, position"
    },
    {
      "id": 4644,
      "kana": "やく",
      "kanji": "約",
      "type": "adv,n",
      "meaning": "approximately, about, some"
    },
    {
      "id": 4645,
      "kana": "やく",
      "kanji": "訳",
      "type": "n",
      "meaning": "meaning, reason, circumstances, can be deduced, situation"
    },
    {
      "id": 4646,
      "kana": "やくしゃ",
      "kanji": "役者",
      "type": "n",
      "meaning": "actor, actress"
    },
    {
      "id": 4647,
      "kana": "やくしょ",
      "kanji": "役所",
      "type": "n",
      "meaning": "government office, public office"
    },
    {
      "id": 4648,
      "kana": "やくす",
      "kanji": "訳す",
      "type": "v5s",
      "meaning": "to translate"
    },
    {
      "id": 4649,
      "kana": "やくそく",
      "kanji": "約束",
      "type": "n,vs",
      "meaning": "arrangement, promise"
    },
    {
      "id": 4650,
      "kana": "やくにん",
      "kanji": "役人",
      "type": "n",
      "meaning": "government official"
    },
    {
      "id": 4651,
      "kana": "やくひん",
      "kanji": "薬品",
      "type": "n",
      "meaning": "medicine(s), chemical(s)"
    },
    {
      "id": 4652,
      "kana": "やくめ",
      "kanji": "役目",
      "type": "n",
      "meaning": "duty, business"
    },
    {
      "id": 4653,
      "kana": "やくわり",
      "kanji": "役割",
      "type": "n",
      "meaning": "part, assigning (allotment of) parts, role, duties"
    },
    {
      "id": 4654,
      "kana": "やけど",
      "kanji": "火傷",
      "type": "n",
      "meaning": "burn, scald"
    },
    {
      "id": 4655,
      "kana": "やける",
      "kanji": "焼ける",
      "type": "v1,vi",
      "meaning": "to burn, to be roasted, to be sunburnt"
    },
    {
      "id": 4656,
      "kana": "やこう",
      "kanji": "夜行",
      "type": "n",
      "meaning": "walking around at night, night train, night travel"
    },
    {
      "id": 4657,
      "kana": "やさい",
      "kanji": "野菜",
      "type": "n",
      "meaning": "vegetable"
    },
    {
      "id": 4658,
      "kana": "やさしい",
      "kanji": "易しい",
      "type": "adj",
      "meaning": "easy, plain, simple"
    },
    {
      "id": 4659,
      "kana": "やさしい",
      "kanji": "優しい",
      "type": "adj",
      "meaning": "tender, kind, gentle, graceful, affectionate, amiable, suave"
    },
    {
      "id": 4660,
      "kana": "やじるし",
      "kanji": "矢印",
      "type": "n",
      "meaning": "directing arrow"
    },
    {
      "id": 4661,
      "kana": "やすい",
      "kanji": "安い",
      "type": "adj",
      "meaning": "cheap, inexpensive, peaceful, quiet"
    },
    {
      "id": 4662,
      "kana": "~やすい",
      "kanji": "",
      "type": "",
      "meaning": "easy to ~"
    },
    {
      "id": 4663,
      "kana": "やすみ",
      "kanji": "休み",
      "type": "n",
      "meaning": "(1) rest, recess, respite, (2) vacation, holiday, absence"
    },
    {
      "id": 4664,
      "kana": "やすむ",
      "kanji": "休む",
      "type": "v5m,vi",
      "meaning": "to rest, to have a break, to take a day off"
    },
    {
      "id": 4665,
      "kana": "やせる",
      "kanji": "痩せる",
      "type": "io",
      "meaning": "(v1) to become thin, to lose weight, to reduce (one's) weight, to slim"
    },
    {
      "id": 4666,
      "kana": "やたらに",
      "kanji": "",
      "type": "",
      "meaning": "randomly, recklessly, blindly"
    },
    {
      "id": 4667,
      "kana": "やちん",
      "kanji": "家賃",
      "type": "n",
      "meaning": "rent"
    },
    {
      "id": 4668,
      "kana": "やっかい",
      "kanji": "厄介",
      "type": "adj-na,n",
      "meaning": "trouble, burden, care, bother"
    },
    {
      "id": 4669,
      "kana": "やっきょく",
      "kanji": "薬局",
      "type": "n",
      "meaning": "pharmacy, drugstore"
    },
    {
      "id": 4670,
      "kana": "やっつ",
      "kanji": "八つ",
      "type": "num",
      "meaning": "eight"
    },
    {
      "id": 4671,
      "kana": "やっつける",
      "kanji": "",
      "type": "v1",
      "meaning": "to attack (an enemy), to beat, to do away with, to finish off"
    },
    {
      "id": 4672,
      "kana": "やっと",
      "kanji": "",
      "type": "adv",
      "meaning": "at last, at length"
    },
    {
      "id": 4673,
      "kana": "やど",
      "kanji": "宿",
      "type": "n",
      "meaning": "inn, lodging"
    },
    {
      "id": 4674,
      "kana": "やとう",
      "kanji": "雇う",
      "type": "v5u",
      "meaning": "to employ, to hire"
    },
    {
      "id": 4675,
      "kana": "やぬし",
      "kanji": "家主",
      "type": "n",
      "meaning": "landlord"
    },
    {
      "id": 4676,
      "kana": "やね",
      "kanji": "屋根",
      "type": "n",
      "meaning": "roof"
    },
    {
      "id": 4677,
      "kana": "やはり",
      "kanji": "",
      "type": "adv,exp",
      "meaning": "also, as I thought, still, in spite of, absolutely, of course"
    },
    {
      "id": 4678,
      "kana": "やっぱり",
      "kanji": "",
      "type": "adv,exp",
      "meaning": "also, as I thought, still, in spite of, absolutely, of course"
    },
    {
      "id": 4679,
      "kana": "やぶく",
      "kanji": "破く",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4680,
      "kana": "やぶる",
      "kanji": "破る",
      "type": "v5r,vt",
      "meaning": "to tear, to violate, to defeat, to smash, to destroy"
    },
    {
      "id": 4681,
      "kana": "やぶれる",
      "kanji": "破れる",
      "type": "v1",
      "meaning": "to get torn, to wear out"
    },
    {
      "id": 4682,
      "kana": "やま",
      "kanji": "山",
      "type": "n",
      "meaning": "(1) mountain, (2) pile, heap, (3) climax, critical point"
    },
    {
      "id": 4683,
      "kana": "やむ",
      "kanji": "止む",
      "type": "v5m,vi",
      "meaning": "to cease, to stop, to be over"
    },
    {
      "id": 4684,
      "kana": "やむをえない",
      "kanji": "",
      "type": "exp",
      "meaning": "cannot be helped, unavoidable"
    },
    {
      "id": 4685,
      "kana": "やめる",
      "kanji": "止める",
      "type": "v1,vt",
      "meaning": "to end, to stop, to cease, to resign"
    },
    {
      "id": 4686,
      "kana": "やめる",
      "kanji": "辞める",
      "type": "v1",
      "meaning": "to retire"
    },
    {
      "id": 4687,
      "kana": "やや",
      "kanji": "",
      "type": "adv",
      "meaning": "a little, partially, somewhat, a short time, a while"
    },
    {
      "id": 4688,
      "kana": "やる",
      "kanji": "",
      "type": "v5r",
      "meaning": "to do"
    },
    {
      "id": 4689,
      "kana": "やわらかい",
      "kanji": "柔らかい",
      "type": "adj",
      "meaning": "soft, tender, limp"
    },
    {
      "id": 4690,
      "kana": "やわらかい",
      "kanji": "軟らかい",
      "type": "adj",
      "meaning": "soft, tender, limp"
    },
    {
      "id": 4691,
      "kana": "ゆ",
      "kanji": "湯",
      "type": "n",
      "meaning": "hot water"
    },
    {
      "id": 4692,
      "kana": "ゆいいつ",
      "kanji": "唯一",
      "type": "adv,n",
      "meaning": "only, sole, unique"
    },
    {
      "id": 4693,
      "kana": "ゆうえんち",
      "kanji": "遊園地",
      "type": "n",
      "meaning": "amusement park"
    },
    {
      "id": 4694,
      "kana": "ゆうがた",
      "kanji": "夕方",
      "type": "n-adv,n-t",
      "meaning": "evening"
    },
    {
      "id": 4695,
      "kana": "ゆうかん",
      "kanji": "夕刊",
      "type": "n",
      "meaning": "evening paper"
    },
    {
      "id": 4696,
      "kana": "ゆうき",
      "kanji": "勇気",
      "type": "n",
      "meaning": "courage, bravery, valour, nerve, boldness"
    },
    {
      "id": 4697,
      "kana": "ゆうこう",
      "kanji": "友好",
      "type": "n",
      "meaning": "friendship"
    },
    {
      "id": 4698,
      "kana": "ゆうこう",
      "kanji": "有効",
      "type": "adj-na,n",
      "meaning": "validity, availability, effectiveness"
    },
    {
      "id": 4699,
      "kana": "ゆうしゅう",
      "kanji": "優秀",
      "type": "adj-na,n",
      "meaning": "superiority, excellence"
    },
    {
      "id": 4700,
      "kana": "ゆうしょう",
      "kanji": "優勝",
      "type": "n,vs",
      "meaning": "overall victory, championship"
    },
    {
      "id": 4701,
      "kana": "ゆうじょう",
      "kanji": "友情",
      "type": "n",
      "meaning": "friendship, fellowship"
    },
    {
      "id": 4702,
      "kana": "ゆうじん",
      "kanji": "友人",
      "type": "n",
      "meaning": "friend"
    },
    {
      "id": 4703,
      "kana": "ゆうそう",
      "kanji": "郵送",
      "type": "n,vs",
      "meaning": "mailing"
    },
    {
      "id": 4704,
      "kana": "ゆうだち",
      "kanji": "夕立",
      "type": "n",
      "meaning": "(sudden) evening shower (rain)"
    },
    {
      "id": 4705,
      "kana": "ゆうのう",
      "kanji": "有能",
      "type": "adj-na,n",
      "meaning": "able, capable, efficient, skill"
    },
    {
      "id": 4706,
      "kana": "ゆうひ",
      "kanji": "夕日",
      "type": "n",
      "meaning": "(in) the evening sun, setting sun"
    },
    {
      "id": 4707,
      "kana": "ゆうびん",
      "kanji": "郵便",
      "type": "n",
      "meaning": "mail, postal service"
    },
    {
      "id": 4708,
      "kana": "ゆうべ",
      "kanji": "夕べ",
      "type": "n",
      "meaning": "evening"
    },
    {
      "id": 4709,
      "kana": "ゆうめい",
      "kanji": "有名",
      "type": "adj-na,n",
      "meaning": "fame"
    },
    {
      "id": 4710,
      "kana": "ユーモア",
      "kanji": "",
      "type": "n",
      "meaning": "humor"
    },
    {
      "id": 4711,
      "kana": "ゆうゆう",
      "kanji": "悠々",
      "type": "adj-na,n",
      "meaning": "quiet, calm, leisurely"
    },
    {
      "id": 4712,
      "kana": "ゆうり",
      "kanji": "有利",
      "type": "adj-na,n",
      "meaning": "advantageous, better, profitable, lucrative"
    },
    {
      "id": 4713,
      "kana": "ゆうりょう",
      "kanji": "有料",
      "type": "n",
      "meaning": "admission-paid, toll"
    },
    {
      "id": 4714,
      "kana": "ゆか",
      "kanji": "床",
      "type": "n",
      "meaning": "floor"
    },
    {
      "id": 4715,
      "kana": "ゆかい",
      "kanji": "愉快",
      "type": "adj-na,n",
      "meaning": "pleasant, happy"
    },
    {
      "id": 4716,
      "kana": "ゆかた",
      "kanji": "浴衣",
      "type": "n",
      "meaning": "bathrobe, informal summer kimono, yukata"
    },
    {
      "id": 4717,
      "kana": "ゆき",
      "kanji": "雪",
      "type": "n",
      "meaning": "snow"
    },
    {
      "id": 4718,
      "kana": "ゆくえ",
      "kanji": "行方",
      "type": "n",
      "meaning": "one's whereabouts"
    },
    {
      "id": 4719,
      "kana": "ゆげ",
      "kanji": "湯気",
      "type": "n",
      "meaning": "steam, vapour"
    },
    {
      "id": 4720,
      "kana": "ゆけつ",
      "kanji": "輸血",
      "type": "n,vs",
      "meaning": "blood transfusion"
    },
    {
      "id": 4721,
      "kana": "ゆしゅつ",
      "kanji": "輸出",
      "type": "n,vs",
      "meaning": "export"
    },
    {
      "id": 4722,
      "kana": "ゆずる",
      "kanji": "譲る",
      "type": "v5r",
      "meaning": "to turn over, to assign, to hand over"
    },
    {
      "id": 4723,
      "kana": "ゆそう",
      "kanji": "輸送",
      "type": "n,vs",
      "meaning": "transport, transportation"
    },
    {
      "id": 4724,
      "kana": "ゆたか",
      "kanji": "豊か",
      "type": "adj-na,n",
      "meaning": "abundant, wealthy, plentiful, rich"
    },
    {
      "id": 4725,
      "kana": "ゆだん",
      "kanji": "油断",
      "type": "n,vs",
      "meaning": "negligence, unpreparedness"
    },
    {
      "id": 4726,
      "kana": "ゆっくり",
      "kanji": "",
      "type": "adv,n",
      "meaning": "slowly, at ease"
    },
    {
      "id": 4727,
      "kana": "ゆでる",
      "kanji": "",
      "type": "v1",
      "meaning": "to boil"
    },
    {
      "id": 4728,
      "kana": "ゆしゅつ・する",
      "kanji": "輸入",
      "type": "n,vs",
      "meaning": "importation, import, introduction"
    },
    {
      "id": 4729,
      "kana": "ゆのみ",
      "kanji": "湯飲み",
      "type": "n",
      "meaning": "teacup"
    },
    {
      "id": 4730,
      "kana": "ゆび",
      "kanji": "指",
      "type": "n",
      "meaning": "finger"
    },
    {
      "id": 4731,
      "kana": "ゆびわ",
      "kanji": "指輪",
      "type": "n",
      "meaning": "(finger) ring"
    },
    {
      "id": 4732,
      "kana": "ゆめ",
      "kanji": "夢",
      "type": "n",
      "meaning": "dream"
    },
    {
      "id": 4733,
      "kana": "ゆるい",
      "kanji": "緩い",
      "type": "adj",
      "meaning": "loose, lenient, slow"
    },
    {
      "id": 4734,
      "kana": "ゆるす",
      "kanji": "許す",
      "type": "v5s",
      "meaning": "to permit, to allow, to approve"
    },
    {
      "id": 4735,
      "kana": "ゆれる",
      "kanji": "揺れる",
      "type": "v1",
      "meaning": "to shake, to sway"
    },
    {
      "id": 4736,
      "kana": "よ",
      "kanji": "夜",
      "type": "n-adv,n-t",
      "meaning": "evening, night"
    },
    {
      "id": 4737,
      "kana": "よあけ",
      "kanji": "夜明け",
      "type": "n",
      "meaning": "dawn, daybreak"
    },
    {
      "id": 4738,
      "kana": "よう",
      "kanji": "用",
      "type": "n,n-suf",
      "meaning": "task, business, use"
    },
    {
      "id": 4739,
      "kana": "よう",
      "kanji": "様",
      "type": "adj-na,n-adv,n",
      "meaning": "way, manner, kind"
    },
    {
      "id": 4740,
      "kana": "よう",
      "kanji": "酔う",
      "type": "v5u",
      "meaning": "to get drunk, to become intoxicated"
    },
    {
      "id": 4741,
      "kana": "ようい",
      "kanji": "用意",
      "type": "n,vs",
      "meaning": "preparation"
    },
    {
      "id": 4742,
      "kana": "ようい",
      "kanji": "容易",
      "type": "adj-na,n",
      "meaning": "easy, simple, plain"
    },
    {
      "id": 4743,
      "kana": "ようか",
      "kanji": "八日",
      "type": "n",
      "meaning": "eight days, the eighth (day of the month)"
    },
    {
      "id": 4744,
      "kana": "ようがん",
      "kanji": "溶岩",
      "type": "n",
      "meaning": "lava"
    },
    {
      "id": 4745,
      "kana": "ようき",
      "kanji": "容器",
      "type": "n",
      "meaning": "container, vessel"
    },
    {
      "id": 4746,
      "kana": "ようき",
      "kanji": "陽気",
      "type": "adj-na,n",
      "meaning": "season, weather, cheerfulness"
    },
    {
      "id": 4747,
      "kana": "ようきゅう",
      "kanji": "要求",
      "type": "n,vs",
      "meaning": "request, demand, requisition"
    },
    {
      "id": 4748,
      "kana": "ようご",
      "kanji": "用語",
      "type": "n",
      "meaning": "term, terminology"
    },
    {
      "id": 4749,
      "kana": "ようし",
      "kanji": "要旨",
      "type": "n",
      "meaning": "gist, essentials, summary, fundamentals"
    },
    {
      "id": 4750,
      "kana": "ようじ",
      "kanji": "用事",
      "type": "n",
      "meaning": "tasks, things to do"
    },
    {
      "id": 4751,
      "kana": "ようじ",
      "kanji": "幼児",
      "type": "n",
      "meaning": "infant, baby, child"
    },
    {
      "id": 4752,
      "kana": "ようじん",
      "kanji": "用心",
      "type": "n,vs",
      "meaning": "care, precaution, guarding, caution"
    },
    {
      "id": 4753,
      "kana": "ようす",
      "kanji": "様子",
      "type": "n",
      "meaning": "aspect, state, appearance"
    },
    {
      "id": 4754,
      "kana": "ようするに",
      "kanji": "要するに",
      "type": "adv,exp",
      "meaning": "in a word, after all, the point is .., in short .."
    },
    {
      "id": 4755,
      "kana": "ようせき",
      "kanji": "容積",
      "type": "n",
      "meaning": "capacity, volume"
    },
    {
      "id": 4756,
      "kana": "ようそ",
      "kanji": "要素",
      "type": "n",
      "meaning": "element"
    },
    {
      "id": 4757,
      "kana": "ようち",
      "kanji": "幼稚",
      "type": "adj-na,n",
      "meaning": "infancy, childish, infantile"
    },
    {
      "id": 4758,
      "kana": "ようちえん",
      "kanji": "幼稚園",
      "type": "n",
      "meaning": "kindergarten"
    },
    {
      "id": 4759,
      "kana": "ようてん",
      "kanji": "要点",
      "type": "n",
      "meaning": "gist, main point"
    },
    {
      "id": 4760,
      "kana": "ようと",
      "kanji": "用途",
      "type": "n",
      "meaning": "use, usefulness"
    },
    {
      "id": 4761,
      "kana": "ようび",
      "kanji": "曜日",
      "type": "n",
      "meaning": "day of the week"
    },
    {
      "id": 4762,
      "kana": "ようひんてん",
      "kanji": "洋品店",
      "type": "n",
      "meaning": "shop which handles Western-style apparel and accessories"
    },
    {
      "id": 4763,
      "kana": "ようふく",
      "kanji": "洋服",
      "type": "n",
      "meaning": "Western-style clothes"
    },
    {
      "id": 4764,
      "kana": "ようぶん",
      "kanji": "養分",
      "type": "n",
      "meaning": "nourishment, nutrient"
    },
    {
      "id": 4765,
      "kana": "ようもう",
      "kanji": "羊毛",
      "type": "n",
      "meaning": "wool"
    },
    {
      "id": 4766,
      "kana": "ようやく",
      "kanji": "漸く",
      "type": "adv",
      "meaning": "gradually, finally, hardly"
    },
    {
      "id": 4767,
      "kana": "ようりょう",
      "kanji": "要領",
      "type": "n",
      "meaning": "point, gist, essentials, outline"
    },
    {
      "id": 4768,
      "kana": "ヨーロッパ",
      "kanji": "",
      "type": "",
      "meaning": "Europe"
    },
    {
      "id": 4769,
      "kana": "よき",
      "kanji": "予期",
      "type": "n,vs",
      "meaning": "expectation, assume will happen, forecast"
    },
    {
      "id": 4770,
      "kana": "よく",
      "kanji": "",
      "type": "adv,n,vs",
      "meaning": "nicely, properly, well, skilled in"
    },
    {
      "id": 4771,
      "kana": "よく~",
      "kanji": "翌~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4772,
      "kana": "よくばり",
      "kanji": "欲張り",
      "type": "adj-na,n",
      "meaning": "avarice, covetousness, greed"
    },
    {
      "id": 4773,
      "kana": "よけい",
      "kanji": "余計",
      "type": "adj-na,adv,n",
      "meaning": "too much, unnecessary, abundance, surplus, excess, superfluity"
    },
    {
      "id": 4774,
      "kana": "よこ",
      "kanji": "横",
      "type": "n",
      "meaning": "beside, side, width"
    },
    {
      "id": 4775,
      "kana": "よこぎる",
      "kanji": "横切る",
      "type": "v5r",
      "meaning": "to cross (e.g. arms), to traverse"
    },
    {
      "id": 4776,
      "kana": "よこす",
      "kanji": "",
      "type": "v5s",
      "meaning": "(1) to send, to forward, (2) to hand over (e.g. money)"
    },
    {
      "id": 4777,
      "kana": "よごす",
      "kanji": "汚す",
      "type": "v5s",
      "meaning": "(1) to disgrace, to dishonour, (2) to pollute, to contaminate, to soil, to make dirty, to stain"
    },
    {
      "id": 4778,
      "kana": "よごれる",
      "kanji": "汚れる",
      "type": "v1",
      "meaning": "to get dirty, to become dirty"
    },
    {
      "id": 4779,
      "kana": "よさん",
      "kanji": "予算",
      "type": "n",
      "meaning": "estimate, budget"
    },
    {
      "id": 4780,
      "kana": "よしゅう",
      "kanji": "予習",
      "type": "n,vs",
      "meaning": "preparation for a lesson"
    },
    {
      "id": 4781,
      "kana": "よす",
      "kanji": "止す",
      "type": "v5s",
      "meaning": "to cease, to abolish, to resign, to give up"
    },
    {
      "id": 4782,
      "kana": "よせる",
      "kanji": "寄せる",
      "type": "v1",
      "meaning": "to collect, to gather, to add, to put aside"
    },
    {
      "id": 4783,
      "kana": "よそ",
      "kanji": "余所",
      "type": "n",
      "meaning": "another place, somewhere else, strange parts"
    },
    {
      "id": 4784,
      "kana": "よそく",
      "kanji": "予測",
      "type": "n,vs",
      "meaning": "prediction, estimation"
    },
    {
      "id": 4785,
      "kana": "よっか",
      "kanji": "四日",
      "type": "n",
      "meaning": "(1) 4th day of month, (2) four days"
    },
    {
      "id": 4786,
      "kana": "よつかど",
      "kanji": "四つ角",
      "type": "n",
      "meaning": "four corners, crossroads"
    },
    {
      "id": 4787,
      "kana": "よっつ",
      "kanji": "四つ",
      "type": "n",
      "meaning": "four"
    },
    {
      "id": 4788,
      "kana": "ヨット",
      "kanji": "",
      "type": "n",
      "meaning": "yacht"
    },
    {
      "id": 4789,
      "kana": "よっぱらい",
      "kanji": "酔っ払い",
      "type": "n",
      "meaning": "drunkard"
    },
    {
      "id": 4790,
      "kana": "よてい",
      "kanji": "予定",
      "type": "n,vs",
      "meaning": "plans, arrangement, schedule, program, expectation, estimate"
    },
    {
      "id": 4791,
      "kana": "よなか",
      "kanji": "夜中",
      "type": "n-adv,n-t",
      "meaning": "midnight, dead of night"
    },
    {
      "id": 4792,
      "kana": "よのなか",
      "kanji": "世の中",
      "type": "n",
      "meaning": "society, the world, the times"
    },
    {
      "id": 4793,
      "kana": "よび",
      "kanji": "予備",
      "type": "n",
      "meaning": "preparation, preliminaries, reserve, spare"
    },
    {
      "id": 4794,
      "kana": "よびかける",
      "kanji": "呼び掛ける",
      "type": "v1",
      "meaning": "to call out to, to accost, to address (crowd), to appeal"
    },
    {
      "id": 4795,
      "kana": "よびだす",
      "kanji": "呼び出す",
      "type": "v5s",
      "meaning": "to summon, to call (e.g. phone)"
    },
    {
      "id": 4796,
      "kana": "よぶ",
      "kanji": "呼ぶ",
      "type": "v5b",
      "meaning": "to call out, to invite"
    },
    {
      "id": 4797,
      "kana": "よぶん",
      "kanji": "余分",
      "type": "adj-na,n",
      "meaning": "extra, excess, surplus"
    },
    {
      "id": 4798,
      "kana": "よほう",
      "kanji": "予報",
      "type": "n,vs",
      "meaning": "forecast, prediction"
    },
    {
      "id": 4799,
      "kana": "よぼう",
      "kanji": "予防",
      "type": "n,vs",
      "meaning": "prevention, precaution, protection against"
    },
    {
      "id": 4800,
      "kana": "よみ",
      "kanji": "読み",
      "type": "n",
      "meaning": "reading"
    },
    {
      "id": 4801,
      "kana": "よみがえる",
      "kanji": "蘇る",
      "type": "v5r",
      "meaning": "to be resurrected, to be revived, to be resuscitated, to be rehabilitated"
    },
    {
      "id": 4802,
      "kana": "よむ",
      "kanji": "読む",
      "type": "v5m",
      "meaning": "to read"
    },
    {
      "id": 4803,
      "kana": "よめ",
      "kanji": "嫁",
      "type": "n",
      "meaning": "bride, daughter-in-law"
    },
    {
      "id": 4804,
      "kana": "よやく",
      "kanji": "予約",
      "type": "n,vs",
      "meaning": "reservation, contract, subscription, booking, pledge, advance order"
    },
    {
      "id": 4805,
      "kana": "よゆう",
      "kanji": "余裕",
      "type": "n",
      "meaning": "surplus, composure, margin, room, time, allowance, scope, rope"
    },
    {
      "id": 4806,
      "kana": "より",
      "kanji": "",
      "type": "n",
      "meaning": "twist, ply"
    },
    {
      "id": 4807,
      "kana": "よる",
      "kanji": "夜",
      "type": "n-adv,n-t",
      "meaning": "evening, night"
    },
    {
      "id": 4808,
      "kana": "よる",
      "kanji": "寄る",
      "type": "v5r",
      "meaning": "to visit, to drop in, to approach"
    },
    {
      "id": 4809,
      "kana": "よる",
      "kanji": "因る",
      "type": "v5r",
      "meaning": "to come from"
    },
    {
      "id": 4810,
      "kana": "よると",
      "kanji": "",
      "type": "expr",
      "meaning": "according to"
    },
    {
      "id": 4811,
      "kana": "よろこび",
      "kanji": "慶び",
      "type": "oK",
      "meaning": "(n) (a) joy, (a) delight, rapture, pleasure, gratification, rejoicing, congratulations, felicitations"
    },
    {
      "id": 4812,
      "kana": "よろこび",
      "kanji": "喜び",
      "type": "n",
      "meaning": "(a) joy, (a) delight, rapture, pleasure, gratification, rejoicing, congratulations, felicitations"
    },
    {
      "id": 4813,
      "kana": "よろこぶ",
      "kanji": "慶ぶ",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4814,
      "kana": "よろこぶ",
      "kanji": "喜ぶ",
      "type": "v5b",
      "meaning": "to be delighted, to be glad"
    },
    {
      "id": 4815,
      "kana": "よろしい",
      "kanji": "宜しい",
      "type": "adj",
      "meaning": "(hon) good, OK, all right, fine, very well, will do, may, can"
    },
    {
      "id": 4816,
      "kana": "（感）",
      "kanji": "よろしく",
      "type": "adv,exp,int",
      "meaning": "well, properly, suitably, best regards, please remember me"
    },
    {
      "id": 4817,
      "kana": "よわい",
      "kanji": "弱い",
      "type": "adj",
      "meaning": "weak, frail, delicate, tender, unskilled, weak (wine)"
    },
    {
      "id": 4818,
      "kana": "よん",
      "kanji": "四",
      "type": "num",
      "meaning": "four"
    },
    {
      "id": 4819,
      "kana": "~ら",
      "kanji": "~等",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4820,
      "kana": "らい",
      "kanji": "来",
      "type": "last month",
      "meaning": ", for (10 days), next (year)"
    },
    {
      "id": 4821,
      "kana": "ライター",
      "kanji": "",
      "type": "n",
      "meaning": "lighter, rider, writer"
    },
    {
      "id": 4822,
      "kana": "らいにち",
      "kanji": "来日",
      "type": "n",
      "meaning": "arrival in Japan, coming to Japan, visit to Japan"
    },
    {
      "id": 4823,
      "kana": "らく",
      "kanji": "楽",
      "type": "adj-na,n,n-suf",
      "meaning": "comfort, ease"
    },
    {
      "id": 4824,
      "kana": "らくだい",
      "kanji": "落第",
      "type": "n,vs",
      "meaning": "failure, dropping out of a class"
    },
    {
      "id": 4825,
      "kana": "ラケット",
      "kanji": "",
      "type": "n",
      "meaning": "paddle, racket"
    },
    {
      "id": 4826,
      "kana": "ラジオ",
      "kanji": "",
      "type": "n",
      "meaning": "radio"
    },
    {
      "id": 4827,
      "kana": "ラッシュアワー",
      "kanji": "",
      "type": "n",
      "meaning": "rush hour"
    },
    {
      "id": 4828,
      "kana": "らん",
      "kanji": "欄",
      "type": "n",
      "meaning": "column of text (e.g. as in a newspaper)"
    },
    {
      "id": 4829,
      "kana": "ランチ",
      "kanji": "",
      "type": "n",
      "meaning": "launch, lunch"
    },
    {
      "id": 4830,
      "kana": "ランニング",
      "kanji": "",
      "type": "n",
      "meaning": "(1) running, (2) tank top"
    },
    {
      "id": 4831,
      "kana": "らんぼう",
      "kanji": "乱暴",
      "type": "adj-na,n,vs",
      "meaning": "rude, violent, rough, lawless, unreasonable, reckless"
    },
    {
      "id": 4832,
      "kana": "りえき",
      "kanji": "利益",
      "type": "n",
      "meaning": "profits, gains, (political, economic) interest"
    },
    {
      "id": 4833,
      "kana": "りか",
      "kanji": "理科",
      "type": "n",
      "meaning": "science"
    },
    {
      "id": 4834,
      "kana": "りかい",
      "kanji": "理解",
      "type": "n,vs",
      "meaning": "understanding, comprehension"
    },
    {
      "id": 4835,
      "kana": "りがい",
      "kanji": "利害",
      "type": "n",
      "meaning": "advantages and disadvantages, interest"
    },
    {
      "id": 4836,
      "kana": "りく",
      "kanji": "陸",
      "type": "adj-na,n",
      "meaning": "six (used in legal documents)"
    },
    {
      "id": 4837,
      "kana": "りこう",
      "kanji": "利口",
      "type": "adj-na,n",
      "meaning": "clever, shrewd, bright, sharp, wise, intelligent"
    },
    {
      "id": 4838,
      "kana": "りこん",
      "kanji": "離婚",
      "type": "n,vs",
      "meaning": "divorce"
    },
    {
      "id": 4839,
      "kana": "リズム",
      "kanji": "",
      "type": "n",
      "meaning": "rhythm"
    },
    {
      "id": 4840,
      "kana": "りそう",
      "kanji": "理想",
      "type": "n",
      "meaning": "ideal"
    },
    {
      "id": 4841,
      "kana": "りつ",
      "kanji": "率",
      "type": "n,n-suf",
      "meaning": "rate, ratio, proportion, percentage"
    },
    {
      "id": 4842,
      "kana": "リットル",
      "kanji": "",
      "type": "n",
      "meaning": "litre"
    },
    {
      "id": 4843,
      "kana": "りっぱ",
      "kanji": "立派",
      "type": "adj-na,n",
      "meaning": "splendid, fine, handsome, elegant, imposing, prominent, legal, legitimate"
    },
    {
      "id": 4844,
      "kana": "リボン",
      "kanji": "",
      "type": "n",
      "meaning": "ribbon"
    },
    {
      "id": 4845,
      "kana": "りゃくす",
      "kanji": "略す",
      "type": "v5s",
      "meaning": "to abbreviate"
    },
    {
      "id": 4846,
      "kana": "りゆう",
      "kanji": "理由",
      "type": "n",
      "meaning": "reason, pretext, motive"
    },
    {
      "id": 4847,
      "kana": "~りゅう",
      "kanji": "~流",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4848,
      "kana": "りゅういき",
      "kanji": "流域",
      "type": "n",
      "meaning": "(river) basin"
    },
    {
      "id": 4849,
      "kana": "りゅうがく",
      "kanji": "留学",
      "type": "n",
      "meaning": "studying abroad"
    },
    {
      "id": 4850,
      "kana": "りゅうこう",
      "kanji": "流行",
      "type": "n,vs",
      "meaning": "fashionable, fad, in vogue, prevailing"
    },
    {
      "id": 4851,
      "kana": "りよう",
      "kanji": "利用",
      "type": "n,vs",
      "meaning": "use, utilization, application"
    },
    {
      "id": 4852,
      "kana": "りょう",
      "kanji": "量",
      "type": "n,n-suf",
      "meaning": "quantity, amount, volume, portion (of food)"
    },
    {
      "id": 4853,
      "kana": "りょう",
      "kanji": "寮",
      "type": "n",
      "meaning": "hostel, dormitory"
    },
    {
      "id": 4854,
      "kana": "りょう~",
      "kanji": "両~",
      "type": "",
      "meaning": "both ~"
    },
    {
      "id": 4855,
      "kana": "~りょう",
      "kanji": "~料",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4856,
      "kana": "~りょう",
      "kanji": "~領",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4857,
      "kana": "りょうがえ",
      "kanji": "両替",
      "type": "n,vs",
      "meaning": "change, money exchange"
    },
    {
      "id": 4858,
      "kana": "りょうがわ",
      "kanji": "両側",
      "type": "n",
      "meaning": "both sides"
    },
    {
      "id": 4859,
      "kana": "りょうきん",
      "kanji": "料金",
      "type": "n",
      "meaning": "fee, charge, fare"
    },
    {
      "id": 4860,
      "kana": "りょうし",
      "kanji": "漁師",
      "type": "n",
      "meaning": "fisherman"
    },
    {
      "id": 4861,
      "kana": "りょうじ",
      "kanji": "領事",
      "type": "n",
      "meaning": "consul"
    },
    {
      "id": 4862,
      "kana": "りょうしゅう",
      "kanji": "領収",
      "type": "n",
      "meaning": "receipt, voucher"
    },
    {
      "id": 4863,
      "kana": "りょうり",
      "kanji": "料理",
      "type": "n,vs",
      "meaning": "cooking, cookery, cuisine"
    },
    {
      "id": 4864,
      "kana": "りょかん",
      "kanji": "旅館",
      "type": "n",
      "meaning": "Japanese hotel, inn"
    },
    {
      "id": 4865,
      "kana": "~りょく",
      "kanji": "~力",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4866,
      "kana": "りょこう",
      "kanji": "旅行",
      "type": "n,vs",
      "meaning": "travel, trip"
    },
    {
      "id": 4867,
      "kana": "りんじ",
      "kanji": "臨時",
      "type": "n",
      "meaning": "temporary, special, extraordinary"
    },
    {
      "id": 4868,
      "kana": "るす",
      "kanji": "留守",
      "type": "n",
      "meaning": "absence, being away from home"
    },
    {
      "id": 4869,
      "kana": "るすばん",
      "kanji": "留守番",
      "type": "n,vs",
      "meaning": "care-taking, caretaker, house-watching"
    },
    {
      "id": 4870,
      "kana": "れい",
      "kanji": "例",
      "type": "n",
      "meaning": "instance, example, case"
    },
    {
      "id": 4871,
      "kana": "れい",
      "kanji": "礼",
      "type": "n",
      "meaning": "expression of gratitude"
    },
    {
      "id": 4872,
      "kana": "れい",
      "kanji": "零",
      "type": "n",
      "meaning": "zero, nought"
    },
    {
      "id": 4873,
      "kana": "れいがい",
      "kanji": "例外",
      "type": "adj-no,n",
      "meaning": "exception"
    },
    {
      "id": 4874,
      "kana": "れいぎ",
      "kanji": "礼儀",
      "type": "n",
      "meaning": "manners, courtesy, etiquette"
    },
    {
      "id": 4875,
      "kana": "れいせい",
      "kanji": "冷静",
      "type": "adj-na,n",
      "meaning": "calm, composure, coolness, serenity"
    },
    {
      "id": 4876,
      "kana": "れいぞうこ",
      "kanji": "冷蔵庫",
      "type": "n",
      "meaning": "refrigerator"
    },
    {
      "id": 4877,
      "kana": "れいてん",
      "kanji": "零点",
      "type": "n",
      "meaning": "zero, no marks"
    },
    {
      "id": 4878,
      "kana": "れいとう",
      "kanji": "冷凍",
      "type": "n",
      "meaning": "freezing, cold storage, refrigeration"
    },
    {
      "id": 4879,
      "kana": "れいぼう",
      "kanji": "冷房",
      "type": "n",
      "meaning": "cooling, air-conditioning"
    },
    {
      "id": 4880,
      "kana": "レインコート",
      "kanji": "",
      "type": "n",
      "meaning": "raincoat"
    },
    {
      "id": 4881,
      "kana": "れきし",
      "kanji": "歴史",
      "type": "n",
      "meaning": "history"
    },
    {
      "id": 4882,
      "kana": "レクリェーション",
      "kanji": "",
      "type": "n",
      "meaning": "recreation"
    },
    {
      "id": 4883,
      "kana": "レコード",
      "kanji": "",
      "type": "n",
      "meaning": "record"
    },
    {
      "id": 4884,
      "kana": "レジャー",
      "kanji": "",
      "type": "n",
      "meaning": "leisure"
    },
    {
      "id": 4885,
      "kana": "レストラン",
      "kanji": "",
      "type": "n",
      "meaning": "restaurant"
    },
    {
      "id": 4886,
      "kana": "れつ",
      "kanji": "列",
      "type": "n",
      "meaning": "queue, line, row"
    },
    {
      "id": 4887,
      "kana": "れっしゃ",
      "kanji": "列車",
      "type": "n",
      "meaning": "train (ordinary)"
    },
    {
      "id": 4888,
      "kana": "れっとう",
      "kanji": "列島",
      "type": "n",
      "meaning": "chain of islands"
    },
    {
      "id": 4889,
      "kana": "レベル",
      "kanji": "",
      "type": "n",
      "meaning": "level"
    },
    {
      "id": 4890,
      "kana": "レポート",
      "kanji": "",
      "type": "n",
      "meaning": "report, paper"
    },
    {
      "id": 4891,
      "kana": "リポート",
      "kanji": "",
      "type": "n",
      "meaning": "report, paper"
    },
    {
      "id": 4892,
      "kana": "れんが",
      "kanji": "煉瓦",
      "type": "n",
      "meaning": "brick"
    },
    {
      "id": 4893,
      "kana": "れんごう",
      "kanji": "連合",
      "type": "n",
      "meaning": "union, alliance"
    },
    {
      "id": 4894,
      "kana": "れんしゅう",
      "kanji": "練習",
      "type": "n,vs",
      "meaning": "practice"
    },
    {
      "id": 4895,
      "kana": "レンズ",
      "kanji": "",
      "type": "n",
      "meaning": "lens"
    },
    {
      "id": 4896,
      "kana": "れんそう",
      "kanji": "連想",
      "type": "n,vs",
      "meaning": "association (of ideas), suggestion"
    },
    {
      "id": 4897,
      "kana": "れんぞく",
      "kanji": "連続",
      "type": "n,vs",
      "meaning": "serial, consecutive, continuity, continuing"
    },
    {
      "id": 4898,
      "kana": "れんらく",
      "kanji": "連絡",
      "type": "n,vs",
      "meaning": "junction, communication, contact, connection"
    },
    {
      "id": 4899,
      "kana": "ろうか",
      "kanji": "廊下",
      "type": "n",
      "meaning": "corridor"
    },
    {
      "id": 4900,
      "kana": "ろうじん",
      "kanji": "老人",
      "type": "n",
      "meaning": "the aged, old person"
    },
    {
      "id": 4901,
      "kana": "ろうそく",
      "kanji": "蝋燭",
      "type": "n",
      "meaning": "candle"
    },
    {
      "id": 4902,
      "kana": "ろうどう",
      "kanji": "労働",
      "type": "n",
      "meaning": "manual labor, toil, work"
    },
    {
      "id": 4903,
      "kana": "ローマじ",
      "kanji": "",
      "type": "n",
      "meaning": "romanization, Roman letters"
    },
    {
      "id": 4904,
      "kana": "ろく",
      "kanji": "六",
      "type": "num",
      "meaning": "six"
    },
    {
      "id": 4905,
      "kana": "ろくおん",
      "kanji": "録音",
      "type": "n,vs",
      "meaning": "(audio) recording"
    },
    {
      "id": 4906,
      "kana": "ロケット",
      "kanji": "",
      "type": "n",
      "meaning": "locket, rocket"
    },
    {
      "id": 4907,
      "kana": "ロッカー",
      "kanji": "",
      "type": "n",
      "meaning": "locker"
    },
    {
      "id": 4908,
      "kana": "ロビー",
      "kanji": "",
      "type": "n",
      "meaning": "lobby"
    },
    {
      "id": 4909,
      "kana": "~ろん",
      "kanji": "~論",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4910,
      "kana": "ろんじる",
      "kanji": "論じる",
      "type": "v1",
      "meaning": "to argue, to discuss, to debate"
    },
    {
      "id": 4911,
      "kana": "ろんずる",
      "kanji": "論ずる",
      "type": "v5z",
      "meaning": "to argue, to discuss, to debate"
    },
    {
      "id": 4912,
      "kana": "ろんそう",
      "kanji": "論争",
      "type": "n",
      "meaning": "controversy, dispute"
    },
    {
      "id": 4913,
      "kana": "ろんぶん",
      "kanji": "論文",
      "type": "n",
      "meaning": "thesis, essay, treatise, paper"
    },
    {
      "id": 4914,
      "kana": "わ",
      "kanji": "輪",
      "type": "n",
      "meaning": "ring, hoop, circle"
    },
    {
      "id": 4915,
      "kana": "わ~",
      "kanji": "和~",
      "type": "",
      "meaning": "Japanese style"
    },
    {
      "id": 4916,
      "kana": "~わ",
      "kanji": "~羽",
      "type": "",
      "meaning": "counter for rabbits/birds"
    },
    {
      "id": 4917,
      "kana": "ワイシャツ",
      "kanji": "",
      "type": "n",
      "meaning": "shirt (lit: white shirt), business shirt"
    },
    {
      "id": 4918,
      "kana": "ワイン",
      "kanji": "",
      "type": "n",
      "meaning": "wine"
    },
    {
      "id": 4919,
      "kana": "わえい",
      "kanji": "和英",
      "type": "n",
      "meaning": "Japanese-English"
    },
    {
      "id": 4920,
      "kana": "わが~",
      "kanji": "我~",
      "type": "",
      "meaning": ""
    },
    {
      "id": 4921,
      "kana": "わかい",
      "kanji": "若い",
      "type": "adj",
      "meaning": "young"
    },
    {
      "id": 4922,
      "kana": "わかす",
      "kanji": "沸かす",
      "type": "v5s,vt",
      "meaning": "to boil, to heat"
    },
    {
      "id": 4923,
      "kana": "わがまま",
      "kanji": "",
      "type": "adj-na,n",
      "meaning": "selfishness, egoism, wilfulness, disobedience, whim"
    },
    {
      "id": 4924,
      "kana": "わかる",
      "kanji": "分る",
      "type": "v5r",
      "meaning": "to be understood"
    },
    {
      "id": 4925,
      "kana": "わかれ",
      "kanji": "別れ",
      "type": "n",
      "meaning": "parting, separation, farewell"
    },
    {
      "id": 4926,
      "kana": "わかれる",
      "kanji": "別れる",
      "type": "v1",
      "meaning": "to be divided, to part from, to separate, to bid farewell"
    },
    {
      "id": 4927,
      "kana": "わかれる",
      "kanji": "分かれる",
      "type": "v1",
      "meaning": "to branch off, to diverge from, to fork, to split, to dispense, to scatter, to divide into"
    },
    {
      "id": 4928,
      "kana": "わかわかしい",
      "kanji": "若々しい",
      "type": "adj",
      "meaning": "youthful, young"
    },
    {
      "id": 4929,
      "kana": "わき",
      "kanji": "脇",
      "type": "n",
      "meaning": "side"
    },
    {
      "id": 4930,
      "kana": "わく",
      "kanji": "沸く",
      "type": "v5k",
      "meaning": "to boil, to grow hot, to get excited, to gush forth"
    },
    {
      "id": 4931,
      "kana": "わく",
      "kanji": "湧く",
      "type": "v5k",
      "meaning": "to boil, to grow hot, to get excited, to gush forth"
    },
    {
      "id": 4932,
      "kana": "わけ",
      "kanji": "訳",
      "type": "n",
      "meaning": "meaning, reason, circumstances, can be deduced, situation"
    },
    {
      "id": 4933,
      "kana": "わける",
      "kanji": "分ける",
      "type": "v1",
      "meaning": "to divide, to separate"
    },
    {
      "id": 4934,
      "kana": "わざと",
      "kanji": "",
      "type": "adv",
      "meaning": "on purpose"
    },
    {
      "id": 4935,
      "kana": "わずか",
      "kanji": "僅か",
      "type": "adj-na,adv,n",
      "meaning": "only, merely, a little, small quantity"
    },
    {
      "id": 4936,
      "kana": "わすれもの",
      "kanji": "忘れ物",
      "type": "n",
      "meaning": "lost article, something forgotten"
    },
    {
      "id": 4937,
      "kana": "わすれる",
      "kanji": "忘れる",
      "type": "v1",
      "meaning": "to forget, to leave carelessly, to be forgetful of, to forget about, to forget (an article)"
    },
    {
      "id": 4938,
      "kana": "わた",
      "kanji": "綿",
      "type": "n",
      "meaning": "cotton, padding"
    },
    {
      "id": 4939,
      "kana": "わだい",
      "kanji": "話題",
      "type": "n",
      "meaning": "topic, subject"
    },
    {
      "id": 4940,
      "kana": "わたくし",
      "kanji": "私",
      "type": "adj-no,n",
      "meaning": "I, myself, private affairs"
    },
    {
      "id": 4941,
      "kana": "わたし",
      "kanji": "私",
      "type": "adj-no,n",
      "meaning": "I, myself, private affairs"
    },
    {
      "id": 4942,
      "kana": "わたす",
      "kanji": "渡す",
      "type": "v5s",
      "meaning": "to pass over, to hand over"
    },
    {
      "id": 4943,
      "kana": "わびる",
      "kanji": "詫びる",
      "type": "v1",
      "meaning": "to apologize"
    },
    {
      "id": 4944,
      "kana": "わふく",
      "kanji": "和服",
      "type": "n",
      "meaning": "Japanese clothes"
    },
    {
      "id": 4945,
      "kana": "わらい",
      "kanji": "笑い",
      "type": "n",
      "meaning": "laugh, laughter, smile"
    },
    {
      "id": 4946,
      "kana": "わらう",
      "kanji": "笑う",
      "type": "v5u",
      "meaning": "to laugh, to smile"
    },
    {
      "id": 4947,
      "kana": "わりあい",
      "kanji": "割合",
      "type": "adv,n",
      "meaning": "(1) rate, ratio, percentage, proportion, (2) comparatively, (3) contrary to expectations"
    },
    {
      "id": 4948,
      "kana": "わりあいに",
      "kanji": "",
      "type": "adv",
      "meaning": "comparatively"
    },
    {
      "id": 4949,
      "kana": "わりざん",
      "kanji": "割算",
      "type": "n",
      "meaning": "division (math)"
    },
    {
      "id": 4950,
      "kana": "わりと",
      "kanji": "割と",
      "type": "",
      "meaning": "relatively, comparitively"
    },
    {
      "id": 4951,
      "kana": "わりびき",
      "kanji": "割引",
      "type": "n,suf",
      "meaning": "discount, reduction, rebate"
    },
    {
      "id": 4952,
      "kana": "わる",
      "kanji": "割る",
      "type": "v5r",
      "meaning": "to divide, to cut, to break, to halve"
    },
    {
      "id": 4953,
      "kana": "わるい",
      "kanji": "悪い",
      "type": "adj",
      "meaning": "bad, inferior"
    },
    {
      "id": 4954,
      "kana": "わるくち",
      "kanji": "悪口",
      "type": "n",
      "meaning": "abuse, insult, slander, evil speaking"
    },
    {
      "id": 4955,
      "kana": "われる",
      "kanji": "割れる",
      "type": "v1,vi",
      "meaning": "to break, to split"
    },
    {
      "id": 4956,
      "kana": "われわれ",
      "kanji": "我々",
      "type": "n",
      "meaning": "we"
    },
    {
      "id": 4957,
      "kana": "わん",
      "kanji": "湾",
      "type": "n,n-suf",
      "meaning": "bay, gulf, inlet"
    },
    {
      "id": 4958,
      "kana": "わん",
      "kanji": "椀",
      "type": "n",
      "meaning": "Japanese soup bowl, wooden bowl"
    },
    {
      "id": 4959,
      "kana": "わん",
      "kanji": "碗",
      "type": "n",
      "meaning": "bowl"
    },
    {
      "id": 4960,
      "kana": "ワンピース",
      "kanji": "",
      "type": "n",
      "meaning": "one-piece dress"
    }
  ]