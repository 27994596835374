import React, {useEffect, useState} from 'react'
import {useParams, Link, Navigate} from 'react-router-dom'
import { collection, query, where, getDocs} from "firebase/firestore"; 
import {db} from '../Firebase/firebase'
import styled from 'styled-components'
import { doc, deleteDoc } from "firebase/firestore";
const YOUTUBEAPIKEY = "IzaSyCg7K_HX1o_WHxkpn1M7yL-E9YJBql4Lcw"

const WeighedParagraphs = styled.p`
line-height: 1.6;`

const HeroImage = styled.img`
object-fit: cover;
width: 100%;
height: 70vh;
border-radius: 3px;
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,9,9,1) 35%, rgba(0,212,255,1) 100%);`


const FullVideoPage = () => {

  const {channelId, videoId} = useParams();  
  const [fetchedVideo, setFetchedVideo] = useState({title: "Looking for the post in the database.", description: "If it takes too long, then the post doesn't exist or the connection is unstable, sorry.", slug: null, author: null, urlToImage: null, publishedAt: null, body: null})
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  
  useEffect(() => {
    const getPost = async () => {
      await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${YOUTUBEAPIKEY}`)
      .then(res => res.json())
      .then(data => setFetchedVideo(data))
    }
    getPost()
  }, [])


  return (
    <div>
      <h1>{fetchedVideo.items ? fetchedVideo.items[0].snippet.title : "Looking for video"}</h1>
      <iframe id="videoBox" width="100%" height={document.getElementById("videoBox") ? document.getElementById("videoBox").clientWidth*0.5625 : "100%"} src={"https://www.youtube.com/embed/"+videoId} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <p>{fetchedVideo.items ? fetchedVideo.items[0].snippet.description : "Looking for video"}</p>
      
      







    </div>
  )
}

export default FullVideoPage