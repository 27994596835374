import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import styled from 'styled-components'
import discord from '../media/icons/discord.svg'
import instagram from '../media/icons/instagram.svg'
import medium from '../media/icons/medium.svg'
import facebook from '../media/icons/facebook.svg'
import telegram from '../media/icons/telegram.svg'
import twitter from '../media/icons/twitter.svg'
import youtube from '../media/icons/youtube.svg'

import { devices } from '../styles/mediaQueries'


const FooterBox = styled.footer`
    background-color: #f1f1f1;
    padding: 50px 0px;
    margin-top: 75px;`

const Title = styled.h2`
    font-size: 26px;
    color: red;
    font-weight: 400;
    margin-right: 25px;`

const TitleBox = styled.div`

    @media ${devices.mobileS} {
            dsplay: flex;
            flex-direction: column;
            justify-content: flex-start;}

      @media ${devices.tablet} {
           display: flex;
           flex-direction: row;
           align-items: center;
           padding-bottom: 32px;}`

const TitleText = styled.p`
    font-size: 16px;`

const ColumnWrapper = styled.div`

@media ${devices.mobileS} {
    flex-direction: column;}
  @media ${devices.mobileM} {
    flex-direction: column;}  
    @media ${devices.mobileL} {
      flex-direction: column;
    align-items: flex-start;
    line-height: 2;}
  @media ${devices.laptop} {
       flex-direction: row;
       line-height: 1;}
    }


    display: flex;
    justify-content: space-between;
    align-items: flex-start;    
    flex-direction: row;
    flex-wrap: no-wrap;
    padding: 0px;
    margin: 0px;`

const Column = styled.ul`
    display: flex;
    flex: 1;
    flex-direction: column;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    line-height: 1.5;`

const IconWrapper = styled.div`
    margin-top: 16px;`

const Icon = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 3px;
    margin-top: 3px;`

const Wrapper = styled.div`
@media ${devices.mobileM} {
    padding: 0px 10px;}
  @media ${devices.mobileS} {
    padding: 0px 10px;}  
    @media ${devices.mobileL} {
      padding: 0px 20px;}
  @media ${devices.laptop} {
       padding: 0px 200px;}
    display: flex;
    flex-direction: column;`

const Copyright = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;`

const customLinkStyle = ({ isActive }) => isActive ? { color: 'black', textDecoration: "red solid underline", textDecorationThickness: "3px" } : { color: "black", textDecoration: "none" }

const Footer = () => {
    return (
        <FooterBox>
            <Wrapper>
                <TitleBox>
                    {/* <Title>⛩ Japanible</Title> */}
                    <p>Japanible is a community platform for people who love Japan. You can find curated articles about culture, study methodology for Japanese language learners, job offers and more here. With love from Japan.</p>
                </TitleBox>
                <ColumnWrapper>
                    <Column>
                        <li><u>Content menu</u></li>
                        {/* <NavLink style={customLinkStyle} to="/originals"><li>Original Articles</li></NavLink> */}
                        <NavLink style={customLinkStyle} to="/community"><li>Community Articles</li></NavLink>
                        {/* <NavLink style={customLinkStyle} to="/videos"><li>Original Videos</li></NavLink> */}
                        {/* <NavLink style={customLinkStyle} to="/news"><li>News 🔗</li></NavLink> */}
                        <NavLink style={customLinkStyle} to="/jobs"><li>Jobs in Japan</li></NavLink>

                    </Column>

                    <Column>
                        <li><u>Connect with Us</u></li>
                        {/* <NavLink style={customLinkStyle} to="/share">Share Your Story</NavLink> */}
                        {/* <NavLink style={customLinkStyle} to="/postjoboffer"><li>Post a job listing</li></NavLink> */}
                        <NavLink style={customLinkStyle} to="/requestinterview"><li>Request an interview</li></NavLink>

                        <IconWrapper>
                            <a href="https://discord.gg/XxW9p9uX"><Icon alt="Discord icon" src={discord} /></a>
                            <a href="https://www.youtube.com/channel/UCzqE9CzagDZhqCVDdC-WV9g/featured"><Icon alt="YouTube icon" src={youtube} /></a>
                            <a href="https://instagram.com/japanible"><Icon alt="Instagram Icon" src={instagram} /></a>
                            <a href="https://twitter.com/japanible"><Icon alt="Twitter Icon" src={twitter} /></a>
                            {/* <a href="#"><Icon src={medium} /></a>
                            <a href="#"><Icon src={telegram} /></a>
                            <a href="#"><Icon src={facebook} /></a> */}
                        </IconWrapper>

                    </Column>
                    <Column>
                        <li><u>Hello!
                        </u></li>
                        <NavLink style={customLinkStyle} to="/aboutjapanible"><li>About Japanible</li></NavLink>
                        <NavLink style={customLinkStyle} to="/roadmap"><li>Roadmap</li></NavLink>
                        {/* <NavLink style={customLinkStyle} to="/support"><li>Support Japanible</li></NavLink> */}
                        <NavLink style={customLinkStyle} to="/privacy"><li>Privacy Policy</li></NavLink>


                    </Column>
                </ColumnWrapper>
                <Copyright>&copy; ⛩ Japanible, 2023.</Copyright>

            </Wrapper>
        </FooterBox>
    )
}

export default Footer