import React, { useState, useEffect } from 'react'
import { collection, getDocs } from "firebase/firestore"; 
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import {devices} from '../styles/mediaQueries'
import {db} from '../Firebase/firebase'

import ArticleCard from '../components/ArticleCard'
import ArticleCardFiller from '../components/ArticleCardFiller'

const ArticlesWrapper = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
`

const FilterBoard = styled.div`
line-height: 2;
border-radius: 3px;
padding: 20px 20px;
background-color: #f1f1f1;
display: flex;
flex-direction: row;
margin-bottom: 42px;
justify-content: flex-start;`

const FilterColumn = styled.div`
padding: 0px 20px;
display: flex;
flex-direction: column;`


const Originals = () => {

  const [articles, setArticles] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const getData = async () => {
      let events = []
      const postsSnapshot = await getDocs(collection(db, "posts"));
      await postsSnapshot.forEach((doc) => {
        events.push(doc.data());
      }
      )
      setArticles(events.filter((e)=>(e.type=="community")))
    }
    getData()
  }, [])


  return (
    <>
<Helmet>
        <title>All Original Articles | Japanible</title>

        <meta name="description" content="Explore articles about Japanese culture, language, and lifestyle on Japanible. We cover everything from traditional festivals to modern pop culture, and include study methodology for Japanese language learners, travel tips, and more. Start your journey of discovery with Japanible today." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="index, follow"/>
        <link rel="canonical" href="https://japanible.com/originals"/>

        <meta property="og:title" content="Discover all articles on Japanible."/>
        <meta property="og:description" content="Discover articles on Japanese culture, language, and lifestyle. Explore festivals, study tips, travel advice, and more on Japanible."/>
        <meta property="og:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta property="og:url" content="https://japanible.com/originals"/>
        <meta property="og:type" content="website"/>

        <meta name="twitter:title" content="Discover all articles on Japanible."/>
        <meta name="twitter:description" content="Discover articles on Japanese culture, language, and lifestyle. Explore festivals, study tips, travel advice, and more on Japanible."/>
        <meta name="twitter:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@japanible"/>

        <meta name="author" content="Japanible"/>

      </Helmet>


    {articles && articles[0] ? <script> window.prerenderReady=true</script> : <script> window.prerenderReady=false</script>}
    
      <h1>Read them all.</h1>
      <ArticlesWrapper>
        {articles.length%2==0 || articles.length%5==0 ?
        <ArticlesWrapper>{articles.map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}<ArticleCardFiller /></ArticlesWrapper> :
        <ArticlesWrapper>{articles.map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}</ArticlesWrapper>}
      </ArticlesWrapper>
    </>
  )
}

export default Originals