import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const CookiePopUp = styled.div`
display: flex;
flex-direction: column;

color: black;
backdrop-filter: blur(8px);


background-color: rgb(255,255,255); /* Fallback color */
background-color: rgba(255,255,255, 0.7); /* Black w/opacity/see-through */

-webkit-backdrop-filter: blur(8px);
position: fixed;
bottom: 0;
left: 0;
right: 0;
// transform: translate(-50%, -50%);
// width: 600px;
z-index: 5000;
padding: 32px;

span {
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 32px;
}

ul {
  display: flex;
  flex-direction: column;
}
`

const GreenButton = styled.button`
font-size: 18px;
width: 49%;
padding: 12px;
border-radius: 5px;
font-weight: 500;
cursor: pointer;
  background-color: white;
  border: 3px black solid;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer`

const BlackButton = styled(GreenButton)`
  background-color: white ;
  border: 3px solid white;`

export default function Cookies() {
  const handleAccepting = () => {
    window.localStorage.setItem('JAPANIBLE_POLICY', 'accepted');
    setIsConfirmed(!isConfirmed);
    // console.log(window.localStorage.getItem('JAPANIBLE_POLICY'));

  };
  const handleRefusal = () => {
    window.localStorage.setItem('JAPANIBLE_POLICY', 'refused');
    setIsConfirmed(!isConfirmed);
    // console.log(window.localStorage.getItem('JAPANIBLE_POLICY'));
  };

  const [isConfirmed, setIsConfirmed] = useState(false)

  useEffect(function() {
    console.info('Checking LocalStorage for Users Cookies Preferences.')
    const getCookiesSettings = window.localStorage.getItem('JAPANIBLE_POLICY');
    if (getCookiesSettings !== null) {
      setIsConfirmed(true)
      console.info('Cookies Preferences found - Blocking Google Analytics & Not Showing a Message.')
    } else {
      console.info('Letting User Decide on Cookies Preferences.')
  }
},[]);

  


  if (!isConfirmed) return (
    <CookiePopUp>
      <span onClick={()=>{setIsConfirmed(!isConfirmed)}}>X</span>
      <h2>Hello there!</h2>
      <div style={{marginBottom: '32px'}}>
        We value your privacy and want to make sure you understand how we handle your personal information. By clicking 'accept' on this pop-up, you're agreeing to our Privacy Policy. Don't hesitate to reach out to us if you have any questions or concerns. Thanks for choosing to engage with us!
      </div>
      <br/>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <GreenButton onClick={handleAccepting}>Yes, I Agree</GreenButton>
      <BlackButton onClick={handleRefusal}>No, I Disagree</BlackButton>
      </div>
    </CookiePopUp>
  );
  if (isConfirmed) return
}
