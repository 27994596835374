import React, {useState, useEffect, Fragment} from 'react'
import styled from 'styled-components'
import { collection, doc, updateDoc, getDocs, query, where } from "firebase/firestore";
import { db } from '../Firebase/firebase'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { UserAuth } from '../context/AuthContext';
import uuid from 'react-uuid';
import TipTapRTE from '../components/TipTapRTE';
import sanitizeHtml from 'sanitize-html-react';
import {useHref, useParams} from 'react-router-dom'



//Take SLUG
//Load Doc from DRAFT DB OR Regular Collection if not found
//Save response into state
//Drill state into Form
//Rewrite POST on SEND OR
  //Make new DRAFT COLLECTION entry 


const FormRow = styled.div`
    margin: 20px 0px;`

  const FormTextArea = styled.textarea`
    margin-top: 5px;
    width: 100%;
    height: 50px;
    overflow-y: scroll;
    resize: none;
    font-size: 16px;
    padding: 5px 5px;
    `
  const FormInput = styled.input`
    margin-top: 5px;
    padding: 5px 5px;
    width: 100%;
    font-size: 16px;`

  const CTA = styled.button`
    background-color: red;
    color: white;
    border-radius: 3px;
    border: transparent 0px solid;
    padding: 9px;
    font-size: 16px;`

  const Highlight = styled.div`
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 3px;
    text-align: center;`

    const UploadPhotoFormRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      background-color: red;
      color: white;
      border-radius: 3px;
      border: transparent 0px solid;
      font-size: 16px;
      margin-left: 9px;
    }`

    const UploadPhotoFormRowSub = styled.div`
    display: flex;
    flex-direction: column;
    
    margin-top: 12px;

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3px;
    }

    div > button {
      background-color: red;
      color: white;
      border-radius: 3px;
      border: transparent 0px solid;
      font-size: 16px;
      cursor: pointer;
      padding: 6px;
    }

        div > label > input[type=file] {
      display:none;    
    }

    div > label {
      background-color: white;
      color: black;
      border-radius: 3px;
      border: red 3px solid;
      font-size: 16px;
      padding: 6px;
      cursor: pointer;
    }

    div > span {
      background-color: white;
      color: red;
      border-radius: 3px;
      font-size: 16px;
      padding: 6px;
      cursor: pointer;
      text-align: center;
      text-decoration: underline;
    }
    `

const ShareEdit = () => {

  useEffect(() => {window.scrollTo(0, 0)}, [])

  const {slug, draftId} = useParams();
  const {user} = UserAuth();
  const storage = getStorage();

  const [dataState, setDataState] = useState('loading')

  const [post, setPost] = useState({title: "Error", description: "Error"})
  
  const [RTEBody, setRTEBody] = useState("")
  const [postTitle, setPostTitle] = useState('')
  const [slugToSet, setSlug] = useState('')
  const [postUrlToImage, setPostUrlToImage] = useState('')
  const [postOriginalPostUrl, setPostOriginalUrl] = useState('')
  const [postDescription, setPostDescription] = useState('')
  const [checkboxIAmAuthor, setCheckboxIAmAuthor] = useState(false)
  const [checkboxIsOriginal, setCheckboxIsOriginal] = useState(false)
  const [fileToBeUploaded, setFileToBeUploaded] = useState([])
  const [percent, setPercent] = useState(0);
  const [imageUploaded, setImageUploaded] = useState(false)
  const [disableUrlInput, setDisableUrlInput] = useState(false)


  const [retrievedPostId, setRetrievedPostId] = useState('')

  const sanitizationRules = {
    allowedTags: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
    'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
    'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img' ],
    allowedAttributes: {
      a: [ 'href', 'name', 'target' ],
      // We don't currently allow img itself by default, but this
      // would make sense if we did
      img: [ 'src' ]
  },
  // Lots of these won't come up by default because we don't allow them
  selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
  // URL schemes we permit
  allowedSchemes: [ 'http', 'https', 'ftp', 'mailto' ],
  allowedSchemesByTag: {}
  }

  // Get Post ID for deleting
  const getPostId = async () => {
    const q = query(collection(db, "posts"), where("slug", "==", slug))
    const querySnapshot = await getDocs(q);
    
    if(!querySnapshot.empty){querySnapshot.forEach((doc) => {setRetrievedPostId(doc.id)});
              //Get Data from Retrieved Post
              const getPostValues = async () => {
                // console.log('Fetch start by ' + user.uid)
                const currentPost = await getDocs(query(collection(db, "posts"), where("slug", "==", slug), where("author", "==", user.uid)))
                if(!currentPost.empty){
                await currentPost.forEach(async (doc) => {
                  const DATA = doc.data()
                  const load = async () => {
                    setPostTitle(DATA.title)
                    setPostUrlToImage(DATA.urlToImage)
                    setPostOriginalUrl(DATA.postOriginalPostUrl)
                    setPostDescription(DATA.description)
                    setFileToBeUploaded(DATA.urlToImage)
                    setImageUploaded(true)
                    setRTEBody(DATA.body)
                    setPercent(100)
                    setPost(DATA)
                    }
                    load();
                    setDataState('success');
                })} else {setDataState('error')}

              }
   getPostValues()
  } else {setDataState('error')}};

  // Get content of the post by quering by slug
  useEffect(() => {
    getPostId()
  }, [user])

  //Handler to Post States to DB
  const sharePostAction = async () => {
    if (slug && postTitle && postUrlToImage && postDescription && RTEBody.length>100&& checkboxIAmAuthor) {
      // const docRef = doc(collection(db, "posts"))
      const docRef = doc(db, "posts", retrievedPostId);
      const insertDataValue = {
        author: user.uid.toString(),
        slug: slug.toString(),
        title: sanitizeHtml(postTitle.toString(), sanitizationRules),
        urlToImage: sanitizeHtml(postUrlToImage.toString(), sanitizationRules),
        body: sanitizeHtml(RTEBody, sanitizationRules),
        description: sanitizeHtml(postDescription, sanitizationRules),
        publishedAt: new Date().toISOString(),
        type: "community",
        originalUrl: sanitizeHtml(postOriginalPostUrl, sanitizationRules) || "",
        updatedAt: new Date().toISOString(),
      }
    await updateDoc(docRef, insertDataValue)
    alert('Successfully Published!')

    setPostTitle('')
    setPostUrlToImage('')
    setPostDescription('')

    window.location.href = `https://japanible.com/originals/${slug}`
  }
  if (postTitle.length<5) {
    alert("Make title at least 5 characters long.")}
  if (!postUrlToImage) {
      alert("Set up an image by entering an URL or uploading data.")} 
  if (!postDescription) {
        alert("Write a Description.")}   
  if (RTEBody.length<100) {
    alert("Write a post longer than 100 characters.")}   

}


const handleChange = event => {setPostTitle(event.target.value)}

useEffect(()=>{
    const sluggedTitle = `${postTitle.trim().replace(/\W+/g, '-').toLowerCase()}-${uuid().split("-")[0]}`;
    // console.log(sluggedTitle)
    setSlug(sluggedTitle)},
    [dataState])

const handleFileSelection = (e) => {
      setFileToBeUploaded(e.target.files[0]);
      setDisableUrlInput(!disableUrlInput)
     }

const handleUploadFile = () => {
      if (fileToBeUploaded.length < 1) {
          alert('Please select a file.');
      }
      const storageRef = ref(storage, `/${user.uid}/upload/${fileToBeUploaded.name}`)
      const uploadTask = uploadBytesResumable(storageRef, fileToBeUploaded);
      uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                 setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  setPostUrlToImage(url);
                });
            }
        );
        setImageUploaded(true)
    }

const handlerDeleteUploadedImage = () => {
      setPostUrlToImage("")
      setFileToBeUploaded([])
      setImageUploaded(false)
      setDisableUrlInput(false)
    }

  if (dataState=='loading') return (
    <>
      <h1>Loading the editor and the posts data.</h1>
      <p>Please wait for a while. Speed depends on the speed of your network. Usually takes 5 seconds on average.</p>
    </>)
  if (dataState=='error') return (
    <>
      <h1>We couldn't reach the post.</h1>
      <p>Please check your connection to the internet and the URL you are trying to access. Are you logged with the right account?</p>
    </>)
  if (dataState=='success') return (
    <div>
      <h1>Share Your Story!</h1>
      <p>You can write and share an article! *Your post might be quickly reviewed before publishing.</p>
      
      
      <h2>Title</h2>
      <FormRow key="wow">
        <label key="wow2" htmlFor="">Please write at least five characters long title.</label>
        <FormInput onChange={handleChange} value={postTitle} type="text"/>
      </FormRow>

      <FormRow>
        <h2>Introduction</h2>
        <label htmlFor="">Short description of the post, that will be displayed in the posts list. *If you leave it empty, first paragraph of your article might be used.</label>
        <FormTextArea type="text" onChange={event => {setPostDescription(event.target.value)}} value={postDescription}/>
      </FormRow>

      <FormRow>
        <h2>Featured image</h2>
        <label htmlFor="">You can add main picture by pasting an URL or uploading a picture from your device. We advice to choose an attractive related photo. Please upload a picture, you have a legal right to post.</label>
        
      <section style={{backgroundColor: "#f1f1f1", borderRadius: "3px", padding: "24px", display: "flex"}}>
        
        <UploadPhotoFormRowSub>
            {/* Input */}
            {!imageUploaded && (
              <>
                <h3>Set an URL</h3>
                <UploadPhotoFormRow>
                  <FormInput type="url" disabled={disableUrlInput} placeholder="https://example.com" pattern="https://.*" onChange={event => { setPostUrlToImage(event.target.value) }} value={postUrlToImage} />
                  <button onClick={handleUploadFile}>Confirm the URL</button>
                </UploadPhotoFormRow>

                <div><h1>Or</h1></div>

                <div><h3>Upload a File</h3></div>
                <div>
                  <label class="custom-file-upload">
                    <input type="file" name="" id="" onChange={handleFileSelection} />
                    {!fileToBeUploaded.name && "Select a File and then Click on Upload"}
                    {fileToBeUploaded.name && <span>Selected File: <b>{fileToBeUploaded.name.toString()}</b></span>}
                  </label>
                  <button onClick={handleUploadFile}>Confirm the Upload</button></div>
              </>)}

            {/* Preview */}
            {imageUploaded && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {percent < 100 ? <p>Done {percent}%.</p> :
                  <>
                    <h2>Preview</h2>
                    <img src={postUrlToImage} style={{ width: "300px", height: "200px", objectFit: "cover", borderRadius: "3px" }} alt="Preview of the photo." srcSet="" />
                  </>}
                <br /><span onClick={handlerDeleteUploadedImage}>Remove the Image</span>
              </div>
            )}
      </UploadPhotoFormRowSub>

      </section>
      </FormRow>


        <FormRow>
          <h2>Article's Main Text</h2>
          {post.body ? <TipTapRTE setRTEBody = {setRTEBody} textOnMount={post.body} /> : 'Loading.'}
        </FormRow>

      <Highlight>
      <FormRow>
          <input type="checkbox" name="author" id="author" checked={checkboxIAmAuthor} onChange={e => setCheckboxIAmAuthor(e.target.checked)} />
          <label htmlFor="author">I am an author of this text (or I have a written permission to repost the article).</label>
        </FormRow>


        {checkboxIsOriginal ? <FormRow>
        <label htmlFor="">Link to original article.</label>
        <FormInput type="url"  placeholder="https://example.com" pattern="https://.*" onChange={event => {setPostOriginalUrl(event.target.value)}} value={postOriginalPostUrl}/>
      </FormRow>: ""}
        <CTA onClick={sharePostAction}>Publish Updated Article</CTA>
      </Highlight>
    </div>


  )
}

export default ShareEdit