import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import NewsCard from '../components/NewsCard';
import axios from 'axios';
import { collection, getDocs } from "firebase/firestore"; 
import {db} from '../Firebase/firebase'

const ArticlesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    `
const fillerCard = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;
`

const ShowArticlesList = () => {
  const [articles, setArticles] = useState([]);
  const q = `"japan"AND"japanese"AND"culture"`

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=>{
    const getArticles = async () => {
      const currentDate = new Date();
      const lastFourWeeks = new Date(currentDate-(86400000*28))
      const res = await axios.get(`https://newsapi.org/v2/everything?q=${q}&from=${lastFourWeeks.getYear()}-${lastFourWeeks.getMonth()}}-${lastFourWeeks.getDate()}&sortBy=popularity&apiKey=7eb09d2d429f4dd2a1afbf536eadfcb2`, {})
      await setArticles(res.data.articles)};
      // console.log(articles)
      getArticles();
  },[]);

  return (
    <>
    <h1>Popular News from External Sources</h1>
    <ArticlesWrapper>
        {articles.length % 3 === 0 ?
        articles.map((e, i)=>{return <NewsCard key={i} number={e.url} article={e}/>}) :
        <>{articles.map((e, i)=>{return <NewsCard key={i} number={e.url} article={e}/>})} <NewsCard style={{backgroundColor: 'red'}}></NewsCard></>}
    </ArticlesWrapper>
    </>
  )
}

export default ShowArticlesList