import React, { useState, useEffect } from 'react'
import ArticleCard from '../components/ArticleCard'
import ArticleCardFiller from '../components/ArticleCardFiller'
import ArticleCardPlaceholder from '../components/ArticleCardPlaceholder'
import styled from 'styled-components';
import { collection, getDocs, where, query } from "firebase/firestore"; 
import {db} from '../Firebase/firebase'
import {useLocation} from 'react-router-dom'
import Helmet from 'react-helmet'
import dayjs from 'dayjs'

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

// import articleData from '../data/articleData'

const ArticlesWrapper = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
min-width: 100%;
`

const Travel = () => {

  const { isLoading, error, data, isFetching, isFetched } = useQuery({
    queryKey: ["allarticles"],
    cacheTime: 15 * (60 * 1000), // 15 mins 
    queryFn: async () =>{
      let _resultArray = []
      const postsSnapshot = await getDocs(collection(db, "posts"));
      await postsSnapshot.forEach((doc) => {
        _resultArray.push(doc.data());
    })
      return _resultArray.sort((a, b)=>(dayjs(a.publishedAt) > (dayjs(b.publishedAt)) ? -1 : 1))
    }
  });

  const location = useLocation().pathname;
  const [articles, setArticles] = useState([]);
  const [dataLoadingState, setDataLoadingState] = useState('loading')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // useEffect(() => {
  //   const getData = async () => {
  //       let events = []
  //         const q = query(collection(db, "posts"), where("hashtags", "array-contains", "travel"))
  //         const postsSnapshot = await getDocs(q);
  //         await postsSnapshot.forEach((doc) => {
  //           events.push(doc.data());
  //         }
  //         )
  //            setArticles(events.sort((a, b)=>(dayjs(a.publishedAt) > (dayjs(b.publishedAt)) ? -1 : 1)))
  //       setDataLoadingState('loaded')

  //   }
  //   getData()
  // }, [])


  
  if (isLoading) return (<>
    <h1>All Travel Articles</h1>
    <ArticlesWrapper>
      <ArticleCardPlaceholder/>
      <ArticleCardPlaceholder/>
      <ArticleCardPlaceholder/>
      </ArticlesWrapper></>)
    if (isFetched) return (
      <>
        <h1>All Travel Articles</h1>
        <Helmet>
        <title>All Travel Articles | Japanible</title>

        <meta name="description" content="Explore Japan's many wonders with Japanible's travel articles. Discover hidden gems and must-see destinations, along with tips for getting around and making the most of your trip. Let's travel together." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="index, follow"/>
        <link rel="canonical" href="https://japanible.com/travel"/>

        <meta property="og:title" content="Discover all articles on Japanible."/>
        <meta property="og:description" content="Discover must-see destinations and insider tips for getting around"/>
        <meta property="og:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta property="og:url" content="https://japanible.com/travel"/>
        <meta property="og:type" content="website"/>

        <meta name="twitter:title" content="Discover all articles on Japanible."/>
        <meta name="twitter:description" content="Discover must-see destinations and insider tips for getting around"/>
        <meta name="twitter:image" content="https://japanible.com/shinto-shrine.png"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@japanible"/>

        <meta name="author" content="Japanible"/>

      </Helmet>
      {data.filter(e=>(e.hashtags.includes('travel'))) && data.filter(e=>(e.hashtags.includes('travel')))[0] ? <script> window.prerenderReady=true</script> : <script> window.prerenderReady=false</script>}


        {/* <h2>{location.slice(1)}</h2> */}
        {data.filter(e=>(e.hashtags.includes('travel'))).length === 0 ? "No articles written yet or haven't loaded yet." : 
        <ArticlesWrapper>
        {data.filter(e=>(e.hashtags.includes('travel'))).length%3==1 && <ArticlesWrapper>{data.filter(e=>(e.hashtags.includes('travel'))).map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}<ArticleCardFiller /><ArticleCardFiller /></ArticlesWrapper>}
        {data.filter(e=>(e.hashtags.includes('travel'))).length%3==2 && <ArticlesWrapper>{data.filter(e=>(e.hashtags.includes('travel'))).map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}<ArticleCardFiller /></ArticlesWrapper>}
        {data.filter(e=>(e.hashtags.includes('travel'))).length%3==0 && <ArticlesWrapper>{data.filter(e=>(e.hashtags.includes('travel'))).map((e, i) => { return <ArticleCard key={i} number={e.slug} article={e} /> })}</ArticlesWrapper>}
      </ArticlesWrapper>}
      </>
    )
  }

export default Travel