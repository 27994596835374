import React, {Fragment} from 'react'
import Helmet from 'react-helmet'

const Roadmap = () => {
  return (
    <>
      <Helmet>
        <title>Roadmap | Platform for people who love Japan</title>

        <meta name="description" content="Japanible is a platform for people who love Japan. Sharing Awesomeness from Japan. Writing articles about Japan, japanese culture and language." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="noindex"/>
      </Helmet>

    <h1>Roadmap</h1>
    <p>What are current plans?</p>
    <h2>In Progress</h2>
    <ul> 
      <li>Create back-end to secure APIs</li>
      <blockquote>
          Apparently Firebase Config files are supposed to be visible. Still need to secure DB queries(?).
        </blockquote>
      <li>Make user database slightly more complex. User database is needed, so we can have a list of articles by a selected user, to set custom nicknames and more cool stuff.<br/>

      <blockquote>
      <input type="checkbox" checked disabled /> Added list of articles by an user in June 2022.<br/>


        </blockquote>
      </li>
      <li> WYSWIG editor for articles
        <blockquote>
          <input type="checkbox" checked disabled /> Implemented TipTap RTE in July 2022.<br/>
          <input type="checkbox" disabled />  Add Image Support
        </blockquote>
      </li>
      <li> Make images uploadable
        <blockquote>
          <input type="checkbox" checked disabled /> Implemented Basic Firebase Storage in July 2022.<br/>
          <input type="checkbox" disabled />  Make the decision between pasting URL and file upload more UX friendly.
        </blockquote>
      </li>
      <li>Comment Session</li>
      <blockquote>
          <input type="checkbox" disabled />  Basic functionality added in July 2022.
        </blockquote>
    </ul>

    <h2>2022</h2>
    <ul>

      <li>Bilingual articles</li>
      <li>Japanese Sweets Sets</li>
      <li>Japanese localization</li>
      <li>English-Japanese Language Exchange Board</li>
    </ul>
    <h2>2023</h2>
    <ul>
      <li>Recording for bilingual posts</li>
      <li>Monthly sweets set</li>
      <li>Pick up personal vocabulary from posts</li>
      <li>Pick up personal sentences</li>
      <li>Flashcards</li>
    </ul>
    <h2>2024</h2>
    <ul>
      <li>Point system</li>
      <li>Public scoreboard</li>
      <li>Native Android and iOS application</li>
    </ul>
    <h2>2025</h2>
    <ul>
      <li>List up Japanese educational institutes, that accept foreign student</li>
      <li>Find partner universities</li></ul>
    <h2>Haven't found what you were looking for?</h2>
    <a href="#">Share your idea with us!</a>
    </>
  )
}

export default Roadmap