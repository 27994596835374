import { getModularInstance } from '@firebase/util'
//import Placeholder from '@tiptap/extension-placeholder'
import Image from '@tiptap/extension-image'
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, {useState, useEffect, useCallback} from 'react'
import {FaBold} from 'react-icons/fa'
import GlobalStyle from '../globals/GlobalStyles'
import styled from 'styled-components'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { UserAuth } from '../context/AuthContext';
import {ImUndo, ImRedo, ImQuotesRight} from 'react-icons/im'








const MenuBar = ({ editor, addImage, addImageViaUpload }) => {
  if (!editor) {
    return null
  }
  return (
    <div className="ProseMirrorMenu">
      <div>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
       Bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        Italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        Bigger Heading
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        Smaller Heading
      </button>
      
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        Bullet List
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        Ordered List
      </button>
      
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        <ImQuotesRight/>
      </button>
      </div>
      <div>
      <button onClick={() => editor.chain().focus().undo().run()}>
        <ImUndo/>
      </button>
      <button onClick={() => editor.chain().focus().redo().run()}>
        <ImRedo/>
      </button>
      <button
        onClick={addImage}
      >
        Img from URL
      </button>
      <button
        onClick={addImageViaUpload}
      >
        Img from PC
      </button>
      </div>
    </div>
  )
}
const BubbleMenuDesign = styled.div`
background-color: #ececec;
padding: 6px;
border-radius: 3px;
width: 100%;
button {
  background-color: lightgray;
  border-radius: 3px;
  border: none;
  height: 23px;
  margin-right: 3px;
  margin-bottom: 3px;
};
`
const TipTapRTE = ({setRTEBody, textOnMount}) => {
  const {user} = UserAuth();
  const [LMAO, SETLMAO] = useState('')
  const [percent, setPercent] = useState(0)
  const storage = getStorage();
  
  const editor = useEditor({
    extensions: [
      StarterKit,
      Image,
    ],
    content: `${textOnMount ? textOnMount.toString() : '<p>Try clicking <b>here</b> and <em>editing</em> this text!</p>'}`,
    onUpdate: ({editor}) => {
      const html = editor.getHTML();
      setRTEBody(html)}
  })

        Image.configure({
        inline: true,
        })

  const addImage = useCallback(() => {
            const url = window.prompt('URL')
              if (url) {
                editor.chain().focus().setImage({ src: url }).run()
              }
            }, [editor])
            if (!editor) {
              return null
  }
  const addImageViaUpload = async() => {
    var INPUT = document.createElement('input');
    var file = ""
    INPUT.type = 'file';
    INPUT.click();
    INPUT.onchange = (e) => { 
      var file = e.target.files[0];
      handleUploadFileAndReturnURL(file);
   }
    const handleUploadFileAndReturnURL = async (file) => {
      const storageRef = ref(storage, `/${user.uid}/upload/${file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, file);
      if (!file) {alert('No file!')}
      // console.log(file)
      uploadTask.on("state_changed",
      (snapshot) => {
        setPercent(0)
        const calcPERCENT = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPercent(calcPERCENT);
        // console.log(percent)
      },
      (err) => console.log(err),
      () => {getDownloadURL(uploadTask.snapshot.ref)
                  .then((url) => {editor.chain().focus().setImage({ src: url }).run()})
                  .then(()=>{setPercent(0)})
            }
        );
        
    }
  }
// const uploadAndAddImage = async () => {
//   const handleFileSelection = (e) => {
//     setFileToBeUploaded(e.target.files[0]);
//     setDisableUrlInput(!disableUrlInput)
//    }
//   const handleUploadFile = () => {
//     if (fileToBeUploaded.length < 1) {
//         alert('Please select a file.');
//     }
//     // const storageRef = ref(storage, `/files/${fileToBeUploaded.name}`)
//     const storageRef = ref(storage, `/${user.uid}/upload/${fileToBeUploaded.name}`)
//     const uploadTask = uploadBytesResumable(storageRef, fileToBeUploaded);
//     uploadTask.on("state_changed", (snapshot) => {
//               const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
//               setPercent(percent);},
//           (err) => console.log(err),
//           () => {
//               // download url
//               getDownloadURL(uploadTask.snapshot.ref).then((url) => {
//                 setPostUrlToImage(url);
//               });
//           }
//       );
//       setImageUploaded(true)
//   }
// }
  
  return (
    <div>
      
      <MenuBar editor={editor} addImage={addImage} addImageViaUpload={addImageViaUpload}/>
      {editor && <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
      <BubbleMenuDesign>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
       Bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        Italic
      </button>
      
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        Bigger Heading
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        Smaller Heading
      </button>
      <br/>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        Bullet List
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        Ordered List
      </button>
      
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        Quote
      </button>
      <br/>
      <button onClick={() => editor.chain().focus().undo().run()}>
        Undo
      </button>
      <button onClick={() => editor.chain().focus().redo().run()}>
        Redo
      </button>
      <button
        onClick={addImage}
      >
        Img from URL
      </button>
      <button
        onClick={addImageViaUpload}
      >
        Img from PC
      </button>
      </BubbleMenuDesign>
      </BubbleMenu>}
      <GlobalStyle/>
      <EditorContent editor={editor} />
    </div>
  )
}
export default TipTapRTE