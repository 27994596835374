import React, {Fragment, useState, useEffect} from 'react'
import { UserAuth } from '../context/AuthContext';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { db } from '../Firebase/firebase'
import LoadingPlaceholderItem from '../components/LoadingPlaceholderItem'

import { doc, updateDoc, getDoc } from "firebase/firestore";

const LogOutButton = styled.button`
background-color: red;
color: white;
border-radius: 3px;
border: transparent 0px solid;
padding: 9px;
font-size: 16px;
margin: 50px 0px;
cursor: pointer;
`


const SecondaryButton = styled(LogOutButton)`
background-color: black;
color: white;
`

const Account = (props) => {
  const {user, logOut} = UserAuth();

  const [accountData, setAccountData] = useState([]);
  const [accountDataUsername, setAccountDataUsername] = useState('');
  const [dataLoadingState, setDataLoadingState] = useState('loading')
  const [sendDataState, setSendDataState] = useState(false)

  const lastLogged = Date(user?.metadata?.createdAt)

  useEffect(()=>{
    getAccountData()
  },[user, sendDataState])


const getAccountData = async () => {
      const docSnap = await getDoc(doc(db, "users", user.uid))
      
      if (docSnap.exists()) {
        const fetchedData = await docSnap.data()
        await setAccountData(fetchedData)
        await setAccountDataUsername(fetchedData.userName)
        setDataLoadingState('loaded')
        // console.log(user.uid + ' = ' + accountDataUsername)
      } else {
        setDataLoadingState('error')
    }
    }
    
  const updateAccount = async () => {
    if ( window.confirm('Are you sure?') === true ) {
    const currentAccount = doc(db, "users", user.uid);
      await updateDoc(currentAccount, {
        ...accountData,
        userName: accountDataUsername
      });
      setSendDataState(!sendDataState)
      setDataLoadingState('loading')
    } else {return}
  };
    
  const handleSignOut = async () => {
    try {
        await logOut();
    } catch (error) {
        console.error(error)
    }}

  if (dataLoadingState === 'loading') {return (
  
    <>
    <div>

    <h1 style={{display: 'flex', alignItems: 'flex-end'}}>
      <LoadingPlaceholderItem width="150px" height="38px"/>'s Account Details</h1>
    <h4>Profile Picture</h4>
    <LoadingPlaceholderItem width="96px" height="96px"/>
    <h4>Username</h4>
    
    <div>
    <LoadingPlaceholderItem width="150px" height="22px"/>
    </div>

    <h4>Associated Email</h4>
    <LoadingPlaceholderItem width="320px" height="22px"/>
    <h4>Last Sign In Time</h4>
    <LoadingPlaceholderItem width="450px" height="22px"/>

    <br/>
    <LoadingPlaceholderItem width="120px" height="37px" style={{marginRight: "6px"}}/>
    <LoadingPlaceholderItem width="80px" height="37px"/>


    <h1>Public Profile</h1>
    <LoadingPlaceholderItem width="420px" height="22px"/>
          {/* <h1>My Articles</h1>
      <LoadingPlaceholderItem width="320px" height="22px"/> 
           <h1>My Article Drafts</h1>
      <LoadingPlaceholderItem width="320px" height="22px"/> */}
    

    </div>
    </>
    )}
  if (dataLoadingState === 'error') {return (<>
    <div>
      <h1>Error</h1></div></>)}
  if (dataLoadingState === 'loaded') return (
    <>
    <div>
    <Helmet>
        <title>My Account | Platform for people who love Japan</title>

        <meta name="description" content="Japanible is a platform for people who love Japan. Sharing Awesomeness from Japan. Writing articles about Japan, japanese culture and language." />
        <meta name="keywords" content="Japan, community, culture, Japanese language, job offers, articles" />
        <meta name="robots" content="noindex"/>
      </Helmet>

    <h1>{accountData.userName}'s Account Details</h1>
    <h4>Profile Picture</h4>
    <img src={accountData.photoURL} alt={`${accountData.userName}'s Profile picture.`}/>

    <h4>Username</h4>
    
    <div>
         {<input value={accountDataUsername} onChange={(e)=>{setAccountDataUsername(e.target.value)}}></input>} 
    </div>

    <h4>Associated Email</h4>
    {user?.email}
    <h4>Last Sign In Time</h4>
    {user?.metadata?.lastSignInTime?.toString()}
    <br/>
    <LogOutButton onClick={updateAccount} style={{marginRight: 6}}>Save Changes</LogOutButton>
    <LogOutButton onClick={handleSignOut}>Sign Out</LogOutButton>


    <h1>Profile Page</h1>
  

      <Link to={"/user/"+user.uid}>Go to Your Profile to See Your Articles and Drafts</Link>
      {/* <h1>My Articles</h1>
      <Link to={"/user/"+user.uid}>Go to Your Articles</Link>
      <h1>My Article Drafts</h1>
      <Link to={"/user/"+user.uid}>Go to Your Article Drafts</Link> */}

    

    </div>
    </>
  )
}

export default Account




